import { SET_PREFERENCES } from "../actions/preferencesActons";
import initialState from "../initialState";

const userReducer = (state = { ...initialState.preferences }, action: any) => {
  switch (action.type) {
    case SET_PREFERENCES:
      return { ...action.payload };

    default:
      return { ...state };
  }
};

export default userReducer;
