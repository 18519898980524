import {
  Paper,
  Collapse,
  Box,
  Stack,
  Divider,
  Button,
  Typography,
  styled,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { sortSkillsByCategory } from "../../Utils";
import RatingItem from "../skills/RatingItem";
import SkillsCategoryGroup from "../skills/SkillsCategoryGroup";
import WorkSkillsHeader from "./WorkSkillsHeader";
import { useSelector } from "react-redux";

const CustomStack: any = styled(Stack, {
  shouldForwardProp: (props) => props !== "whiteShadow",
})(({ whiteShadow }: any) =>
  whiteShadow
    ? {
        "&:after": {
          position: "relative",
          background: "linear-gradient(hsla(0,0%,100%,0),#fff)",
          height: "50px",
          bottom: "50px",
          content: "''",
          left: 0,
          right: 0,
          width: "100%",
        },
      }
    : {}
);

const WorkSkillsCompare: React.FC<any> = ({
  skillsWork,
  type,
  handleSkillsWorkChange,
  noCard,
}) => {
  const context = useSelector((state: any) => state.auth.context);
  const [expand, setExpand] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const [collapseHeight, setCollapseHeight] = useState(250);
  const [topSkills, setTopSkills] = useState<any>([]);
  const { t } = useTranslation("common");
  const [modified, setModified] = useState<boolean>(false);
  const [skillsWorkCopy, setSkillsWorkCopy] = useState<any>({});

  const [skillsToImprove, setSkillsToImprove] = useState<any>([]);
  const [skillsToAcquire, setSkillsToAcquire] = useState<any>([]);
  const [skillsAcquired, setSkillsAcquired] = useState<any>([]);

  const list = [
    {
      key: type === "technical" ? "acquired" : "acquired_aptitudes",
      array: skillsAcquired,
    },
    {
      key: type === "technical" ? "to_improve" : "to_improve_aptitudes",
      array: skillsToImprove,
    },
    {
      key: type === "technical" ? "to_acquire" : "to_acquire_aptitudes",
      array: skillsToAcquire,
    },
  ];

  const dispatchSkill = useCallback((skillsList: typeof skillsWork) => {
    let all: any = [];
    for (let key in skillsList) {
      all = all.concat(skillsList[key]);
    }

    all.sort((a: any, b: any) => b?.skillLevel - a?.skillLevel);

    setTopSkills(all.splice(0, 5));

    if (skillsList?.to_improve?.length > 0) {
      setSkillsToImprove(sortSkillsByCategory(skillsList.to_improve));
    }

    if (skillsList?.to_acquire?.length > 0) {
      setSkillsToAcquire(sortSkillsByCategory(skillsList.to_acquire));
    }

    if (skillsList?.acquired?.length > 0) {
      setSkillsAcquired(sortSkillsByCategory(skillsList.acquired));
    }
  }, []);

  useEffect(() => {
    if (!modified && skillsWork) {
      dispatchSkill(skillsWork);
      setSkillsWorkCopy(skillsWork);
    } else if (skillsWorkCopy) {
      dispatchSkill(skillsWorkCopy);
    }
  }, [skillsWork, dispatchSkill, modified, skillsWorkCopy]);

  useLayoutEffect(() => {
    if (topRef?.current) {
      const el = topRef.current;
      setCollapseHeight(el.scrollHeight - 20);
    }
  }, [topRef, topSkills]);

  const onExpand = () => {
    setExpand(!expand);
    if (expand && ref?.current) {
      const bodyRectTop = document.body.getBoundingClientRect().top;
      const elemRectTop = ref.current.getBoundingClientRect().top;
      const offset = elemRectTop - bodyRectTop;
      window.scrollTo({ top: offset });
    }
  };

  const handleSubmitSkill = (skills: any) => {
    setModified(true);

    const newSkillsWork = JSON.parse(JSON.stringify(skillsWorkCopy));

    skills.forEach((skill: any) => {
      const skillToAddIndex = newSkillsWork.to_acquire.findIndex(
        (el: any) => el.skill.id === skill.data.skill.id
      );
      const skillToUpdateIndex = newSkillsWork.to_improve.findIndex(
        (el: any) => el.skill.id === skill.data.skill.id
      );

      let skillToChange = (
        skillToAddIndex >= 0
          ? newSkillsWork.to_acquire.splice(skillToAddIndex, 1)
          : skillToUpdateIndex >= 0 &&
            newSkillsWork.to_improve.splice(skillToUpdateIndex, 1)
      )[0];
      if (skillToChange) {
        skillToChange.userSkillLevel = skill.data.level.level;
        skillToChange.userSkillLevelData = skill.data.level["@id"];
        if (skillToChange.userSkillLevel >= skillToChange.skillLevel) {
          newSkillsWork.acquired.push(skillToChange);
        } else {
          newSkillsWork.to_improve.push(skillToChange);
        }
      }
    });
    handleSkillsWorkChange();
    setSkillsWorkCopy(newSkillsWork);
  };

  return (
    <Paper
      ref={ref}
      elevation={noCard ? 0 : 3}
      sx={{ padding: 2, borderRadius: "23px" }}
    >
      <WorkSkillsHeader
        type={type}
        compare={true}
        context={context}
        expand={expand}
      />

      <Collapse in={expand} collapsedSize={collapseHeight}>
        {!expand && (
          <Box sx={{ display: "flex", justifyContent: "center" }} ref={topRef}>
            <CustomStack spacing={1} whiteShadow={!expand}>
              {topSkills.map((skill: any, i: number) => (
                <RatingItem
                  compare={true}
                  key={skill.id + i}
                  identifier={skill.id + i}
                  skill={skill}
                  type={type}
                  workSkill
                  handleSubmitSkill={handleSubmitSkill}
                />
              ))}
            </CustomStack>
          </Box>
        )}
        <br />
        <br />
        {list.map((e: any, index: number) =>
          e.array.length > 0 ? (
            <div key={index}>
              <Typography variant="h5" textAlign="center" sx={{ mb: 3 }}>
                {t(`skills.${e.key}`)}
              </Typography>
              <Box display="flex" alignItems="center" flexDirection="column">
                <Box>
                  {e.array.map((category: any, i: number) => (
                    <SkillsCategoryGroup
                      key={i}
                      category={category}
                      compare={true}
                      type={type}
                      typeKey={e.key}
                      handleSubmitSkill={handleSubmitSkill}
                      flex
                    />
                  ))}
                </Box>
              </Box>
              {index + 1 < list.length && <Divider sx={{ mb: 3, mt: 2 }} />}
            </div>
          ) : null
        )}
      </Collapse>

      <Box sx={{ textAlign: "center", mb: 5 }}>
        <Button
          onClick={onExpand}
          sx={{ p: 2 }}
          variant={expand ? "outlined" : "contained"}
        >
          <Typography variant="body2">
            {expand
              ? t("actions.show_less")
              : type === "technical"
              ? t("work_sheet.show_all_skills")
              : t("work_sheet.show_all_aptitudes")}
          </Typography>
        </Button>
      </Box>
    </Paper>
  );
};

export default WorkSkillsCompare;
