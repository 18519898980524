import { Button, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ButtonRoot = styled(Button)(({ theme }: any) => ({
  color: theme.palette.secondary[400],
  borderColor: theme.palette.secondary[400],
  width: "100%",
  height: "60px",
  borderRadius: "10px",
  fontSize: "14px",
  "&:hover": {
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
  },
}));

const NextStepButton: React.FC<{ onClick: any; text?: string | undefined }> = ({
  onClick,
  text,
}) => {
  const { t } = useTranslation("common");
  return (
    <ButtonRoot variant="outlined" onClick={() => onClick && onClick()}>
      {text ? text : t("btn.pass_step")}
    </ButtonRoot>
  );
};

export default NextStepButton;
