export const setIcon = (label: string) => {
  switch (label.toLocaleLowerCase()) {
    case "skills":
      return "/static/icons/skills_abilities_logo.svg";
    case "big5":
      return "/static/icons/b5_logo.svg";
    case "culturefit":
      return "/static/icons/cf_logo.svg";
    case "inc":
      return "/static/icons/inc_logo.svg";
    case "gamified":
      return "/static/logo/Logogamefinal.png";
    case "mood":
      return "/static/icons/mood_logo.svg";

    default:
      return "/static/icons/b5_logo.svg";
  }
};
