import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const SharesPartners: React.FC<any> = ({ control, name }) => {
  const { t } = useTranslation("common");
  return (
    <Controller
      control={control}
      name={name ?? "sharePartners"}
      rules={{ required: true }}
      render={({ field: { value, onChange }, formState: { errors } }) => (
        <FormControl error={Boolean(errors["sharePartners"])}>
          <FormLabel
            sx={{ color: "inherit" }}
            id="demo-row-radio-buttons-group-label"
          >
            <span
              dangerouslySetInnerHTML={{ __html: t("sign_up.partners") }}
            ></span>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={value}
            onChange={(e: any, val: any) => onChange(val)}
          >
            <FormControlLabel
              value="accept"
              control={<Radio />}
              label={t("btn.i_accept")}
            />
            <FormControlLabel
              value="refuse"
              control={<Radio />}
              label={t("btn.i_refuse")}
            />
          </RadioGroup>
          {Boolean(errors["sharePartners"]) && (
            <FormHelperText
              sx={{ mt: "-4px !important", ml: "25px !important" }}
              error={true}
              id="my-helper-text"
            >
              {t("errors.required")}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default SharesPartners;
