import React from "react";
import { Typography, Box } from "@mui/material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

const DemoError = () => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ReportGmailerrorredIcon sx={{ fontSize: "60px" }} />
      </Box>

      <Typography>
        Une erreur s'est produite lors de votre demande, veuillez prendre
        contact sur contact@monkey-tie.com en nous indiquant votre problème.
      </Typography>
    </div>
  );
};

export default DemoError;
