import { Box, Button, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const CustomBox = styled(Box)(({ theme }: any) => ({
  textAlign: "center",
  borderRadius: "10px",
  padding: theme.spacing(2),
}));

const PassTest: React.FC<any> = ({ url }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  return (
    <Box display="flex" justifyContent="center" marginTop={2}>
      <CustomBox>
        <Button
          onClick={() => navigate(url)}
          variant="contained"
          sx={{ padding: 2, pl: 3, pr: 3 }}
        >
          <Typography>{t("tests.pass")}</Typography>
        </Button>
      </CustomBox>
    </Box>
  );
};

export default PassTest;
