import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import CustomTableToolbar from "./CustomTableToolbar";
import CustomTableHead from "./CustomTableHead";
import { useTranslation } from "react-i18next";
import { Skeleton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setTableItemsPerPagePreferences } from "../../../redux/actions/preferencesActons";
import { getClosestNumber } from "../../../Utils";

type TableProps = {
  data: any;
  headers: TableHeader[];
  name?: string;
  totalItems?: number;
  page?: number;
  onPageChange?: (page: number) => void;
  itemsPerPage?: number;
  onItemsPerPageChange?: (change: number) => void;
  rowsPerPageOptions?: number[];
  onSelect?: (selection: any) => void;
  onSelectObject?: (object: any) => void;
  actions?: any[];
  loading?: boolean;
  onRowClick?: (row: any) => void;
  selected?: string[];
  onSort?: (sort: any) => void;
  order?: "asc" | "desc";
  orderBy?: string;
  background?: string;
  permanentActions?: any[];
  hideToolbar?: boolean;
};

type TableHeader = {
  id: string;
  numeric?: boolean;
  disablePadding?: boolean;
  label: string;
  sortable?: boolean;
  sortableKey?: string;
  align?: string;
};

const CustomTable: React.FC<TableProps> = ({
  name,
  data,
  headers,
  totalItems = 0,
  page,
  onPageChange,
  itemsPerPage = 10,
  onItemsPerPageChange,
  rowsPerPageOptions = [5, 10, 25],
  onSelect,
  onSelectObject,
  actions,
  loading = false,
  onRowClick,
  selected,
  onSort,
  order,
  orderBy,
  background,
  permanentActions = [],
  hideToolbar = false,
}) => {
  const { t } = useTranslation("common");
  const rowKeys = headers.map((el: any) => el.id);
  const dispatch = useDispatch();
  const loaderData = React.useMemo(
    () => getFakeData(headers, itemsPerPage),
    [headers, itemsPerPage]
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    onSort?.(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n: any) => n.id);
      onSelect?.(newSelected);
      onSelectObject?.(data);
      return;
    }
    onSelect?.([]);
    onSelectObject?.([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: number,
    obj?: any
  ) => {
    onSelect?.((current: any) => {
      const newSelected = [...current];
      isSelected(id)
        ? newSelected.splice(newSelected.indexOf(id), 1)
        : newSelected.push(id);
      return newSelected;
    });

    onSelectObject?.((current: any) => {
      const newSelected = [...current];
      const index = newSelected.map((el: any) => el.id).indexOf(obj.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(obj);
      }
      return newSelected;
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange?.(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10);
    dispatch(setTableItemsPerPagePreferences(value));
    onItemsPerPageChange?.(value);
  };

  const isSelected = (id: any) =>
    Boolean(selected) ? selected?.indexOf(id) !== -1 : false;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = itemsPerPage - data.length;

  const closestItemsPerPage = getClosestNumber(
    rowsPerPageOptions,
    itemsPerPage
  );

  const ref = React.useRef<any>(null);
  const headerHeight = ref?.current?.getBoundingClientRect?.()?.height ?? 42;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, ...(background && { background }) }}>
        {!hideToolbar && (
          <CustomTableToolbar
            numSelected={selected?.length ?? 0}
            tableName={name}
            actions={actions}
            permanentActions={permanentActions}
          />
        )}
        <TableContainer sx={{ position: "relative" }}>
          {!loading && data?.length === 0 && (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: `${headerHeight}px`,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ mt: 3 }}>{t("table.no_data")}</Typography>
            </Box>
          )}
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <CustomTableHead
              canSelect={Boolean(selected)}
              headCells={headers}
              numSelected={selected?.length ?? 0}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              loading={loading}
              reference={ref}
            />

            <Body
              data={loading ? loaderData : data}
              {...{
                loading,
                isSelected,
                handleClick,
                rowKeys,
                headers,
                emptyRows,
                onRowClick,
                canSelect: Boolean(selected),
              }}
            />
          </Table>
        </TableContainer>
        {!loading && typeof page === "number" && (
          <TablePagination
            labelRowsPerPage={t("table.pagination.lines_per_page")}
            labelDisplayedRows={({ from, to, count }) =>
              t("table.pagination.counter", { from, to, count })
            }
            SelectProps={{
              disabled: loading,
            }}
            backIconButtonProps={
              loading
                ? {
                    disabled: loading,
                  }
                : undefined
            }
            nextIconButtonProps={
              loading
                ? {
                    disabled: loading,
                  }
                : undefined
            }
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalItems}
            rowsPerPage={closestItemsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
};

export default CustomTable;

const getFakeData = (headers: any, itemsPerPage: number) => {
  const templateObject: any = {};
  headers.forEach(
    (header: any) =>
      (templateObject[header.id] = header.skeleton ?? (
        <Skeleton variant="rectangular" />
      ))
  );
  return Array.from(Array(itemsPerPage).keys()).map((el) => ({
    id: el,
    ...templateObject,
  }));
};

const Body: React.FC<any> = ({
  data,
  isSelected,
  handleClick,
  rowKeys,
  headers,
  emptyRows,
  loading,
  onRowClick,
  canSelect,
}) => {
  const handleRowClick = (row: any) => {
    !loading && onRowClick?.(row);
  };

  return (
    <TableBody>
      {data.map((row: any, index: number) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            sx={{ cursor: onRowClick ? "pointer" : "auto" }}
            onClick={() => handleRowClick(row)}
          >
            {canSelect && (
              <TableCell padding="checkbox">
                {loading ? (
                  <LoaderCheckbox />
                ) : (
                  <Checkbox
                    onClick={(event) => event.stopPropagation()}
                    onChange={(event) => handleClick(event, row.id, row)}
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                )}
              </TableCell>
            )}

            <TableCell
              component="th"
              id={labelId}
              scope="row"
              align={headers[0].align ?? "left"}
              padding={headers[0].disablePadding ? "none" : "normal"}
            >
              {row[rowKeys[0]]}
            </TableCell>
            {headers
              .filter((el: any) => !el.hide)
              .slice(1, headers.length)
              .map((header: any) => (
                <TableCell
                  key={header.id}
                  align={header.align ?? "left"}
                  padding={header.disablePadding ? "none" : "normal"}
                >
                  {row[header.id]}
                </TableCell>
              ))}
          </TableRow>
        );
      })}
      {!loading && emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows,
          }}
        >
          <TableCell colSpan={headers.length} />
        </TableRow>
      )}
    </TableBody>
  );
};

const LoaderCheckbox = () => {
  return (
    <Box
      sx={{
        width: 42,
        height: 42,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Skeleton variant="rectangular" width={20} height={20} />
    </Box>
  );
};
