import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayConnect: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");
  return (
    <div>
      <Typography variant="h4">{t("admin.params.connect.title")}</Typography>
      <FormGroup>
        <FormControlLabel
          value={param}
          onChange={(e: any) => handleChanges(e.target.checked)}
          control={<Checkbox checked={param} />}
          label={t("admin.params.connect.display")}
        />
      </FormGroup>

      <Divider sx={{ mt: 2 }} />
    </div>
  );
};

export default AdminDisplayConnect;
