import React from "react";
import AdminAdvisorBenefsList from "./benefsList/AdminAdvisorBenefsList";

interface AdminAdvisorBenefsProps {
  setHasChanged: (hasChanged: boolean) => void;
}

const AdminAdvisorBenefs: React.FC<AdminAdvisorBenefsProps> = ({
  setHasChanged,
}) => {
  return (
    <div>
      <AdminAdvisorBenefsList setHasChanged={setHasChanged} />
    </div>
  );
};

export default AdminAdvisorBenefs;
