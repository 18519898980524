import React from "react";
import { Box, Skeleton } from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const PaginationSkeleton = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 2, gap: 1 }}
    >
      <ArrowBackIosRoundedIcon
        sx={{ fontSize: "13px", opacity: 0.5, fontWeight: "bold" }}
      />
      <Skeleton variant="circular" width="32px" height="32px" />
      <Skeleton
        variant="circular"
        width="32px"
        height="32px"
        sx={{ animationDelay: "0.75s" }}
      />
      <Skeleton
        variant="circular"
        width="32px"
        height="32px"
        sx={{ animationDelay: "1s" }}
      />
      <ArrowForwardIosRoundedIcon
        sx={{ fontSize: "13px", opacity: 0.5, fontWeight: "bold" }}
      />
    </Box>
  );
};

export default PaginationSkeleton;
