import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TagType } from "../../../@types/tagType";
import { AdminParamsContext } from "../../../contexts/AdminParamsContext";
import useFetch from "../../../hooks/useFetch";
import tagsAPI from "../../../_api/_tagsAPI";

const AdminRecruiterDisplay: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");
  const [tags, setTags] = useState<TagType[]>([]);
  const { data } = useFetch(tagsAPI.get());
  const { triggerCall } = useContext(AdminParamsContext);

  useEffect(() => {
    // ! Hid tags
    // data?.["hydra:member"] && setTags(data?.["hydra:member"]);
  }, [data]);

  const handleTags = (tag: TagType, event: any) => {
    const newTags = [...tags];
    newTags[tags.findIndex((item: TagType) => tag.id === item.id)].label =
      event?.target?.value;
    setTags(newTags);
  };

  const saveTags = () => {
    const calls: any[] = [];
    tags.map((tag: TagType, index: number) =>
      calls.push(tagsAPI.put(tag.id, tag))
    );
    Promise.all(calls)
      .then((res: any) => {})
      .catch((err) => {
        toast.error(t("errors.error_append"));
      });
  };

  useEffect(() => {
    saveTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCall]);

  return (
    <div>
      {param && (
        <>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {t("admin.params.recruiter_display.title")}
          </Typography>
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
            {t("admin.params.recruiter_display.pdf")}
          </Typography>

          <FormGroup>
            <FormControlLabel
              sx={{ mb: 1 }}
              value={param.DISPLAY_PDF}
              onChange={(e: any) =>
                handleChanges({ ...param, DISPLAY_PDF: e.target.checked })
              }
              control={<Checkbox checked={param.DISPLAY_PDF} />}
              label={t("admin.params.recruiter_display.active_pdf")}
            />
            <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
              {t("admin.params.recruiter_display.lang_pdf")}
            </Typography>
            <Typography variant="caption">
              {t("admin.params.recruiter_display.text_pdf")}
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="FR"
                onChange={(e: any) => handleChanges({ ...param, PDF: "FR" })}
                control={<Radio checked={param.PDF === "FR"} />}
                label={t("admin.params.recruiter_display.french")}
              />
              <FormControlLabel
                value="EN"
                onChange={(e: any) => handleChanges({ ...param, PDF: "EN" })}
                control={<Radio checked={param.PDF === "EN"} />}
                label={t("admin.params.recruiter_display.english")}
              />
              <FormControlLabel
                value="FR/EN"
                onChange={(e: any) => handleChanges({ ...param, PDF: "FR/EN" })}
                control={<Radio checked={param.PDF === "FR/EN"} />}
                label={t("admin.params.recruiter_display.both")}
              />
            </RadioGroup>
            {tags?.length > 0 && (
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ mt: 3, mb: 1 }}
              >
                {t("admin.params.recruiter_display.tags")}
              </Typography>
            )}
            {tags?.map((tag: TagType) => (
              <TextField
                key={tag?.id}
                sx={{
                  width: { xs: "100%", sm: "90%", md: "40%", lg: "30%" },
                  m: 1,
                  ml: 0,
                }}
                value={tag.label}
                onChange={(event: any) => handleTags(tag, event)}
                variant="outlined"
                error={!tag.label}
                inputProps={{ maxLength: 30 }}
                helperText={!tag.label ? t("errors.required") : ""}
              />
            ))}
          </FormGroup>
        </>
      )}
    </div>
  );
};

export default AdminRecruiterDisplay;
