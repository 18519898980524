export const SET_MENU = "SET_MENU";

const setMenu = (payload: any) => ({
  type: SET_MENU,
  payload,
});

export const handleMenu = () => {
  return (dispatch: any, getState: any) => {
    const { user } = getState();
    const { auth } = getState();

    const menu = user?.menu[auth?.context];

    // console.log(`MENU ${auth?.context}`, menu);

    if (menu) dispatch(setMenu(menu));
  };
};
