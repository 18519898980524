import { styled } from "@mui/material";
import React from "react";
import ActionBlock from "./MoodResultsActionBlock";
import List from "./MoodResultsList";

const SRoot = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  box-shadow: 0 8px 16px 0 rgb(145, 158, 171, 24%);
  border-radius: 24px;
  @media screen and (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MoodResults: React.FC<any> = ({
  results,
  redo,
  onboarding,
  nextStep,
  mainUser = true,
}) => {
  return (
    <SRoot
      style={{
        flexDirection: mainUser ? "row" : "column",
        alignItems: mainUser ? "left" : "center",
      }}
    >
      <div style={{ flex: 1, marginTop: "20px", marginBottom: "20px" }}>
        <ActionBlock
          mainUser={mainUser}
          redo={redo}
          {...{ onboarding, nextStep }}
        />
      </div>
      <div style={{ flex: 1, marginTop: "20px", marginBottom: "20px" }}>
        <List items={results} />
      </div>
    </SRoot>
  );
};

export default MoodResults;
