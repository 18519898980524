import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AdminLocationModal from "./AdminLocationModal";

const AdminLocation: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");
  const [openModal, setOpenModal] = useState(false);
  return (
    <div>
      <Typography variant="h4">{t("admin.params.location.title")}</Typography>
      <FormGroup>
        <FormControlLabel
          value={param.LOCATION_ETP}
          onChange={(e: any) =>
            handleChanges({ ...param, LOCATION_ETP: e.target.checked })
          }
          control={<Checkbox checked={param.LOCATION_ETP} />}
          label={t("admin.params.location.display")}
        />
        <FormControlLabel
          value={param.GEO_LOCATION}
          onChange={(e: any) =>
            handleChanges({ ...param, GEO_LOCATION: e.target.checked })
          }
          control={<Checkbox checked={param.GEO_LOCATION} />}
          label={t("admin.params.location.geo")}
        />
        <Button
          variant="outlined"
          sx={{ width: "200px", height: "35px" }}
          onClick={() => setOpenModal(true)}
        >
          <Typography variant="body2">
            {t("admin.params.location.handle")}
          </Typography>
        </Button>
      </FormGroup>

      <Typography sx={{ mt: 1 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
      <AdminLocationModal
        open={openModal}
        close={() => setOpenModal(false)}
        alreadyAdded={param.PLACES}
        handleSave={(list: any) => {
          handleChanges({ ...param, PLACES: list });
        }}
      />
    </div>
  );
};

export default AdminLocation;
