import PropTypes from "prop-types";
import { useMemo } from "react";
// material
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
//
import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import GlobalStyles from "./globalStyles";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import { useSelector, shallowEqual } from "react-redux";

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default function ThemeConfig({ children }: any) {
  const home = useSelector((state: any) => state.home, shallowEqual);

  const themeOptions: any = useMemo(
    () => ({
      palette: { ...palette, ...(home?.paramjson?.theme?.css ?? {}) },
      shape,
      typography,
      breakpoints,
      shadows,
      customShadows,
    }),
    [home]
  );

  const theme: any = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
