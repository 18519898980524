import React from "react";
import { Box } from "@mui/system";
import IncSoftSkills from "./IncSoftSkills";
import IncMotivationProfile from "./IncMotivationProfile";

const IncMotivations: React.FC<any> = ({ result }) => {
  const [hasSoftSkills, setHasSoftskills] = React.useState(false);

  React.useEffect(() => {
    const softSkills = result?.dynamic_motivation?.soft_skills;
    if (softSkills) {
      setHasSoftskills(
        [
          ...(softSkills.gold ?? []),
          ...(softSkills.silver ?? []),
          ...(softSkills.bronze ?? []),
        ].length > 0
      );
    }
  }, [result]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      <IncMotivationProfile result={result} />

      {hasSoftSkills && (
        <Box>
          <IncSoftSkills result={result} />
        </Box>
      )}
    </Box>
  );
};

export default IncMotivations;
