import * as React from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Button, Box, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@mui/material";

// eslint-disable-next-line no-empty-pattern
const MessageDeleteValidationModal: React.FC<{
  open: boolean;
  setOpen: any;
  onAccept: any;
  msgData: any;
}> = ({ open, setOpen, onAccept, msgData }) => {
  const { t }: any = useTranslation("common");
  return (
    <Box sx={{ display: "flex" }}>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="responsive-dialog-title">
        <DialogTitle>
          <span style={{ fontSize: "18px" }}>{t("chat.Delete_this_message")}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "2em", textAlign: "center" }}>
            {t("chat.user_delete_warning")}
          </DialogContentText>

          <Box
            sx={{
              display: "flex",
              wordBreak: "break-all",
              justifyContent: "center",
            }}
          >
            <Box sx={{ color: "#fff", backgroundColor: "#4C6FFF", borderRadius: "6px", padding: "10px", boxShadow: 6 }}>
              {msgData?.content}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex" }}>
          <Button autoFocus onClick={() => setOpen(false)} sx={{ fontSize: "15px" }} color="secondary">
            {t("chat.Cancel")}
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              onAccept(msgData);
            }}
            autoFocus
            sx={{ fontSize: "15px" }}
            variant="contained"
            color="error"
          >
            {t("chat.Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MessageDeleteValidationModal;
