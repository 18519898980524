import { Box, Button, Chip, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import tagEntitiesAPI from "../../_api/_tagEntitiesAPI";
import ColorModal from "./ColorModal";
import tagsAPI from "../../_api/_tagsAPI";
import { toastError } from "../../_utils/toastError";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import EditTagModal from "../EditTagModal";

const TagList = ({
  isEdit,
  tagEntities,
  removeTagEntity,
  updateTagColor,
  type,
  needModal,
  workId,
  setTagEntities,
}: any) => {
  const { t } = useTranslation("common");

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [colorState, setColorState] = useState<any>("#708CFF");
  const [thisTag, setThisTag] = useState<any>();
  const [openAddModal, setOpenAddModal] = useState(false);

  const isValidColor = (color: string) => {
    return color && color.startsWith("#");
  };

  const handleDeleteTag = async (tag: string) => {
    const parts = tag.split("/api/tag_entities/");
    const tagEntity = parts[1];

    if (tagEntity) {
      try {
        await tagEntitiesAPI.delete(tagEntity);
        removeTagEntity(tag);
      } catch (error) {
        toastError();
        console.error("Erreur lors de la suppression du tag:", error);
      }
    }
  };

  const handleModal = (event: any, tag: any) => {
    event.stopPropagation();
    setOpenModal(true);
    setThisTag(tag);
  };

  const handleSaveColor = async (tag: any) => {
    try {
      await tagsAPI.put(tag.tag.id, {
        color: colorState,
      });
      updateTagColor(tag.tag.id, colorState);
    } catch (err) {
      toastError();
    }

    setOpenModal(false);
  };

  return (
    <div>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          justifyContent={needModal === true ? "flex-end" : ""}
        >
          {tagEntities?.length > 0 ? (
            <Fragment
            // style={{ justifyContent: "right", alignItems: "baseline" }}
            >
              {tagEntities?.map((tagEntity: any, index: number) => (
                <Chip
                  key={index}
                  label={tagEntity.tag.label}
                  sx={{
                    m: 0.5,
                    fontSize: "12px",
                    height: "24px",
                    backgroundColor: isEdit
                      ? ""
                      : isValidColor(tagEntity?.tag?.color)
                      ? tagEntity?.tag?.color
                      : "#708CFF",
                    color: isEdit
                      ? isValidColor(tagEntity?.tag?.color)
                        ? tagEntity?.tag?.color
                        : "#708CFF"
                      : "white",
                  }}
                  onDelete={
                    isEdit ? () => handleDeleteTag(tagEntity["@id"]) : undefined
                  }
                  onClick={
                    isEdit ? (e: any) => handleModal(e, tagEntity) : undefined
                  }
                />
              ))}
            </Fragment>
          ) : (
            <Typography
              sx={{
                ml: 1,
                opacity: "0.7",
                color: needModal === true ? "white" : "",
              }}
              variant="body2"
            >
              {t("recruiter.candidate.no_tag")}
            </Typography>
          )}

          {!isEdit ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              onClick={() => (needModal ? setOpenAddModal(true) : undefined)}
              sx={{
                m: 0,
                cursor: "pointer",
              }}
            >
              <Button
                aria-label="edit tag"
                sx={{
                  m: 0,
                  color: needModal ? "white" : "",
                  p: 0,
                }}
              >
                <AddCircleRoundedIcon fontSize="medium" />
              </Button>
            </Box>
          ) : null}
        </Box>

        <ColorModal
          open={openModal}
          close={() => setOpenModal(false)}
          colorState={colorState}
          setColorState={setColorState}
          thisTag={thisTag}
          handleSaveColor={handleSaveColor}
        />
        <EditTagModal
          open={openAddModal}
          close={() => setOpenAddModal(false)}
          tagEntities={tagEntities}
          isValidColor={isValidColor}
          handleDeleteTag={handleDeleteTag}
          handleModal={handleModal}
          workId={workId}
          type={type}
          setTagEntities={setTagEntities}
        />
      </Box>
    </div>
  );
};

export default TagList;
