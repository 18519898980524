import { Cancel, Done, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { requestUpdateConversation } from "../../redux/actions/chatActions";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-GB";
import { formatDistance } from "date-fns";
import { useTranslation } from "react-i18next";

const ConvSummary = styled(Box)(({ theme }: any) => ({
  textAlign: "left",
  marginTop: theme.spacing(3),
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
}));

const EditInputTitle = styled(TextField)({
  maxWidth: "140px",
  '&:[class$="MuiFilledInput-input"]': {
    padding: "0px !important",
    backgroundColor: "#fff",
  },
});

const ConversationEditSummary: React.FC<{ conversation: any }> = ({
  conversation,
}) => {
  const [newTitleValue, setNewTitleValue] = useState<any>(conversation?.name);
  const [convTitleEdit, setConvTitleEdit] = useState<boolean>(false);
  const [lastMessageDate, setLastMessageDate] = useState<any>("");
  const dispatch = useDispatch();
  const { t }: any = useTranslation("common");

  const getIconsTitleNode = () => {
    return (
      <>
        {!convTitleEdit ? (
          <>
            <IconButton
              onClick={(event: any) => {
                setConvTitleEdit(true);
              }}
            >
              <Edit sx={{ fontSize: "15px" }} />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              onClick={(event: any) => {
                setConvTitleEdit(false);
                newTitleValue !== "" &&
                  dispatch(
                    requestUpdateConversation(
                      { name: newTitleValue },
                      conversation
                    )
                  );
              }}
            >
              <Done sx={{ fontSize: "15px" }} />
            </IconButton>
            <IconButton
              onClick={(event: any) => {
                setConvTitleEdit(false);
              }}
            >
              <Cancel sx={{ fontSize: "15px" }} />
            </IconButton>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    const locale = localStorage.getItem("lang") || "fr";

    let fnsDate = !conversation?.messages.length
      ? t("chat.no_message")
      : formatDistance(
          new Date(
            conversation?.messages[conversation?.messages.length - 1]?.createdAt
          ),
          Date.now(),
          {
            includeSeconds: true,
            addSuffix: true,
            locale: locale === "fr" ? frLocale : enLocale,
          }
        );

    setLastMessageDate(fnsDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation?.messages]);

  return (
    <ConvSummary>
      <Box>
        <Box display="flex" alignItems="center">
          {!convTitleEdit ? (
            <>
              <Tooltip title={t("chat.only_owner_can_edit_title")}>
                <Typography component="p" sx={{ fontWeight: 700 }}>
                  {conversation?.name ?? t("chat.default_conv_title")}
                </Typography>
              </Tooltip>
            </>
          ) : (
            <>
              <EditInputTitle
                required
                label=""
                value={newTitleValue}
                onChange={(e) => setNewTitleValue(e?.target?.value)}
                variant="filled"
                InputProps={{ disableUnderline: true, hiddenLabel: true }}
              />
            </>
          )}

          {conversation?.admin && getIconsTitleNode()}
        </Box>

        <Typography variant="body2" sx={{ color: "#AFBBC6" }}>
          {(conversation?.members?.length ?? 0) + " " + t("chat.members")}
        </Typography>

        <Typography variant="body2" sx={{ color: "#7B8793" }}>
          {t("chat.last_message") + lastMessageDate}
        </Typography>
      </Box>
    </ConvSummary>
  );
};

export default ConversationEditSummary;
