import { Card, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { catchError, forkJoin, of, tap } from "rxjs";
import useFetch from "../../../../hooks/useFetch";
import Loader from "../../../../Loader";
import http from "../../../../services/http/http";
import peopleAPI from "../../../../_api/_people";
import SharesCollabForm from "./SharesCollabForm";

const Root = styled(Card)`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SharesCollab = () => {
  const { t } = useTranslation("common");
  const personId = useSelector((state: any) => state.user.person_id);
  const { loading, data } = useFetch(peopleAPI.getById(`${personId}/shares`));
  const [loadingObservables, setLoading] = useState(false);

  const people = useFetch(peopleAPI.getById(personId));

  const onSubmit = (data: any) => {
    setLoading(true);

    const addManager = http
      .put(peopleAPI.getById(`${personId}/add_manager`), {
        manager: data.email,
      })
      .pipe(
        tap({
          next: (res) => {
            // console.log(res);
          },
          error: (err) => {
            console.log("err", err);
            if (err?.status !== 409) {
              toast.error(t("errors.error_append"));
            }
          },
        }),
        catchError((err) => of(err))
      );

    const editShares = http
      .put(peopleAPI.getById(personId), {
        shares: { manager: data.manager, rh: data.rh },
      })
      .pipe(
        tap({
          next: (res) => {
            toast.success("Vos partages ont été mis à jour");
          },
          error: (error) => {
            toast.error(t("errors.error_append"));
          },
        }),
        catchError((err) => of(err))
      );

    forkJoin({ edit: editShares, add: addManager }).subscribe((val: any) =>
      setLoading(false)
    );
  };

  return (
    <div>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t("account.data.my_shares")}
      </Typography>
      <Root>
        <Typography variant="subtitle2" sx={{ mb: 1, maxWidth: "75ch" }}>
          Vos projets et vos tests seront visibles (ou non selon votre choix de
          partage) dans l'accès RH pour tous les RH de l’entreprise. Votre
          autoévaluation sur les compétences est visible automatiquement par
          tous les RH de l’entreprise.
        </Typography>

        {!loading && !people.loading && data?.shares && people?.data && (
          <SharesCollabForm
            loading={loadingObservables}
            handler={onSubmit}
            form={data.shares}
            managerInfo={people.data?.managerInfo}
          />
        )}

        {(loading || people.loading) && <Loader noBackground={true} />}
      </Root>
    </div>
  );
};

export default SharesCollab;
