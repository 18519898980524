import { Box, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import GestureIcon from "@mui/icons-material/Gesture";
import NetworkPingIcon from "@mui/icons-material/NetworkPing";
import CommitIcon from "@mui/icons-material/Commit";
import { IncSoftskillsType } from "../../../@types/incSoftskillsType";

const gradients = [
  {
    key: 1,
    value:
      "#FF0000,#FF0000 17%,#ff7373 34%,#ff7373 51%,#FF0000 68%,#FF0000 85%,#ff7373",
  },
  {
    key: 2,
    value:
      "#FFC257,#FFC257 17%,#ffa200 34%,#ffa200 51%,#FFC257 68%,#FFC257 85%,#ffa200",
  },
  {
    key: 3,
    value:
      "#FFC257,#FFC257 17%,#FF0000 34%,#FF0000 51%,#FFC257 68%,#FFC257 85%,#FF0000",
  },
  {
    key: 4,
    value:
      "#3ECE80,#3ECE80 17%,#7affb6 34%,#7affb6 51%,#3ECE80 68%,#3ECE80 85%,#7affb6",
  },
  {
    key: 6,
    value:
      "#3ECE80,#3ECE80 17%,#FFC257 34%,#FFC257 51%,#3ECE80 68%,#3ECE80 85%,#FFC257",
  },
  {
    key: 7,
    value:
      "#FF0000,#FFC257 17%,#3ECE80 34%,#3ECE80 51%,#FF0000 68%,#FFC257 85%,#3ECE80",
  },
];

const colors = [
  {
    key: 1,
    value: "#F24444",
  },
  {
    key: 2,
    value: "#FFA300",
  },
  {
    key: 3,
    value: "#FFA300",
  },
  {
    key: 4,
    value: "#2DD500",
  },
  {
    key: 6,
    value: "#2DD500",
  },
  {
    key: 7,
    value: "#F24444",
  },
];

const texts = [
  {
    key: 1,
    value: "inc.softskills.demotivation",
  },
  {
    key: 2,
    value: "inc.softskills.unstable",
  },
  {
    key: 3,
    value: "inc.softskills.instable",
  },
  {
    key: 4,
    value: "inc.softskills.very_stable",
  },
  {
    key: 6,
    value: "inc.softskills.stable",
  },
  {
    key: 7,
    value: "inc.softskills.very_instable",
  },
];

const icons = [
  {
    key: 1,
    value: <GestureIcon color="error" />,
  },
  {
    key: 2,
    value: <NetworkPingIcon color="warning" />,
  },
  {
    key: 3,
    value: <NetworkPingIcon color="warning" />,
  },
  {
    key: 4,
    value: <CommitIcon color="success" />,
  },
  {
    key: 6,
    value: <CommitIcon color="success" />,
  },
  {
    key: 7,
    value: <GestureIcon color="error" />,
  },
];

const backgrounds = [
  {
    key: 1,
    value: "rgba(242, 68, 68, 0.09)",
  },
  {
    key: 2,
    value: "rgba(239, 182, 81, 0.11)",
  },
  {
    key: 3,
    value: "rgba(239, 182, 81, 0.11)",
  },
  {
    key: 4,
    value: "rgba(103, 219, 73, 0.11)",
  },
  {
    key: 6,
    value: "rgba(103, 219, 73, 0.11)",
  },
  {
    key: 7,
    value: "rgba(242, 68, 68, 0.09)",
  },
];

const MedalImg = styled("img")({
  position: "absolute",
  top: 0,
  right: "-10px",
  width: "25px",
});

const IncCompareProgress: React.FC<{ trait: IncSoftskillsType }> = ({
  trait,
}) => {
  const { t } = useTranslation("common");

  const ProgressTrack = styled(Box)(({ theme }: any) => ({
    width: "100%",
    height: "9px",
    background: "#D6DBE3",
    position: "relative",
    borderRadius: "11px",
    [theme.breakpoints.down("md")]: {
      height: "6px",
    },
  }));

  const ProgressThumb = styled(Box)(({ theme }: any) => ({
    "@keyframes pulse": {
      "0%": {
        backgroundPosition: "100%",
      },
      "100%": {
        backgroundPosition: "0%",
      },
    },
    height: "18px",
    bottom: "-5px",
    position: "absolute",
    border: "3px solid white",
    borderRadius: "9px",
    opacity: "0.7",
    left: `calc(${trait.min === 10 ? "96" : (trait.min + 10) * 5}%)`,
    width: `${
      trait.max === trait.min ? "4.2" : ((trait.max - trait.min) / 20) * 100
    }%`,
    background: `linear-gradient(90deg, ${
      gradients?.filter((el: any) => el.key === trait?.key)[0]?.value
    })`,
    [theme.breakpoints.down("md")]: {
      height: "16px",
      bottom: "-5px",
    },
    display: "block",
    backgroundSize: "300% 100%",
    animation: "pulse 2.5s linear infinite",
  }));

  const ProgressPointer = styled(Box)(({ theme }: any) => ({
    height: "26px",
    width: "26px",
    background: "#333539",
    border: "3px solid white",
    borderRadius: "50px",
    position: "absolute",
    bottom: "-9px",
    left: `calc(${(trait.vpf + 10) * 5}% - 13px)`,
    [theme.breakpoints.down("md")]: {
      height: "20px",
      width: "20px",
      left: `calc(${(trait.vpf + 10) * 5}% - 10px)`,
      bottom: "-7px",
    },
  }));

  const StableBox = styled(Box)({
    height: "31px",
    width: "93px",
    borderRadius: "7px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: backgrounds?.filter((el: any) => el.key === trait?.key)[0]
      ?.value,
    color: colors?.filter((el: any) => el.key === trait?.key)[0]?.value,
  });

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={trait?.texts?.text}>
        <Box marginRight={"32px"} sx={{ position: "relative" }}>
          {(trait.medal === "gold" || trait.medal === "silver") && (
            <MedalImg
              alt="medal"
              src={
                trait.medal === "gold"
                  ? "/static/icons/inc/gold.png"
                  : trait.medal === "silver"
                  ? "/static/icons/inc/silver.png"
                  : ""
              }
            />
          )}
          <img
            style={{
              width: "90px",
              background: "#FFF7E8",
              borderRadius: "34px",
              padding: "20%",
            }}
            alt="icon"
            src={`/static/icons/Icons-irittants/${trait.var}.svg`}
          />
        </Box>
      </Tooltip>
      <Box width="100%">
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          {trait.texts.title}
        </Typography>
        <Box className="progress-ctn" sx={{ width: "100%" }}>
          <ProgressTrack>
            <ProgressThumb />
            <ProgressPointer />
          </ProgressTrack>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          marginTop={2}
        >
          <Typography sx={{ width: "auto", color: "#333539" }} variant="body2">
            {t("inc.softskills.-10")}
          </Typography>
          <Typography variant="body2" sx={{ color: "#333539" }}>
            0
          </Typography>
          <Typography sx={{ width: "auto", color: "#333539" }} variant="body2">
            {t("inc.softskills.+10")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ml: 4 }}>
        <Box display="flex" justifyContent="center" sx={{ mb: 1 }}>
          {icons?.filter((el: any) => el.key === trait?.key)[0]?.value}
        </Box>
        <StableBox>
          <Typography
            variant="body1"
            sx={{
              color: colors?.filter((el: any) => el.key === trait?.key)[0]
                ?.value,
            }}
          >
            {t(
              `${texts?.filter((el: any) => el.key === trait?.key)[0]?.value}`
            )}
          </Typography>
        </StableBox>
      </Box>
    </Box>
  );
};

export default IncCompareProgress;
