import {
  Container,
  Stack,
  Box,
  Button,
  Typography,
  FormControl,
} from "@mui/material";
import React from "react";
import { autocompleteTypes } from "../../../../_utils/autocompleteTypes";
import AutocompleteComponent from "../../../common/AutocompleteComponent";
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ControlledTextField from "../../../forms/ControlledTextField";
import ControlledQuill from "../../../forms/ControlledQuill";

interface IFormInput {
  label: string;
  description: string;
  skillCategory: string;
}

const AdminCreateSkillsForm: React.FC<{
  form: any;
  close: () => void;
  submit: (e: any) => void;
  deleteSkill: () => void;
  skill: any;
  type?: string;
  loading?: boolean;
}> = ({ submit, loading, close, skill, deleteSkill, type, form }) => {
  const { t } = useTranslation("common");

  const { handleSubmit, control, clearErrors } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: form,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Container sx={{ pt: 5, pb: 5 }}>
        {/* <FormGroup> */}
        <Stack spacing={2}>
          <ControlledTextField
            name="label"
            label="Titre *"
            control={control}
            required
          />
          {type === "skill" && (
            <>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="skillCategory"
                  control={control}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <AutocompleteComponent
                      label={t("admin.params.skills.category")}
                      type={autocompleteTypes.skillCategory}
                      sendDataToParent={onChange}
                      onBlur={onBlur}
                      baseValue={value}
                      onFocus={() => clearErrors("skillCategory")}
                    />
                  )}
                />
              </FormControl>
            </>
          )}
          <Typography variant="body1" fontWeight="bold">
            {t("admin.params.skills.description")}
          </Typography>
          <ControlledQuill
            name="description"
            placeholder={t("admin.params.skills.description")}
            control={control}
          />
        </Stack>
        {skill && (
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            sx={{ mt: 5, mb: 2 }}
          >
            <Button
              type="button"
              color="error"
              onClick={deleteSkill}
              variant="contained"
              sx={{ width: "250px", height: "45px" }}
            >
              <Typography variant="body2">{t("btn.delete")}</Typography>
            </Button>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="space-evenly"
          width="100%"
          sx={{ mb: -4, mt: skill ? 0 : 5 }}
        >
          <Button
            type="button"
            color="secondary"
            onClick={close}
            variant="outlined"
            sx={{ width: "200px", height: "45px" }}
          >
            <Typography variant="body2">{t("btn.cancel")}</Typography>
          </Button>
          <Button
            type="submit"
            disabled={loading}
            variant="contained"
            sx={{ width: "200px", height: "45px" }}
          >
            <Typography variant="body2">{t("btn.save")}</Typography>
          </Button>
        </Box>
        {/* </FormGroup> */}
      </Container>
    </form>
  );
};

export default AdminCreateSkillsForm;
