import {
  SET_CONTEXT,
  SET_LOGGED,
  SET_REDIRECTLOGINURL,
} from "../actions/authActions";
import initialState from "../initialState";

const authReducer = (state = { ...initialState.auth }, action: any) => {
  switch (action.type) {
    case SET_LOGGED:
      return { ...state, isLogged: action.payload };
    case SET_CONTEXT:
      return {
        ...state,
        context: action.payload,
        isCollab:
          action.payload === "collab" ||
          action.payload === "benef" ||
          action.payload === "candidate",
        recruiterOrAdvisor:
          action.payload === "recruiter" || action.payload === "advisor",
        isAdvisor: action.payload === "advisor",
        isRecruiter: action.payload === "recruiter",
        isThirdParty: action.payload === "third_party",
      };
    case SET_REDIRECTLOGINURL:
      return { ...state, redirectUrl: action.payload };
    default:
      return { ...state };
  }
};

export default authReducer;
