import { Collapse, Zoom } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";

const DonutChartTest: React.FC<{
  data: number[];
  labels: string[];
  legendPosition?: string;
  width?: number;
  colors?: any;
  options?: any;
}> = ({ data, labels, legendPosition, width, colors, options = {} }) => {
  const chartParams: any = {
    options: {
      chart: {
        type: "donut",
      },
      colors: colors,
      ...options,
      dataLabels: {
        enabled: false,
        formatter: function (val: number) {
          return Math.round(val) + " %";
        },
      },
      legend: {
        show: false,
        position: legendPosition ? legendPosition : "bottom",
      },
      yaxis: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 300,
            },
          },
        },
      ],
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
          },
          spokes: {
            strokeWidth: 0,
          },
        },
      },
    },
  };
  const chart = {
    ...chartParams,
    options: {
      ...chartParams.options,
      labels: labels,
    },
    series: data,
  };
  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <Zoom in={true}>
        <Box>
          <ReactApexChart
            series={chart.series}
            options={chart.options}
            height={500}
            width={width}
            type="polarArea"
          />
        </Box>
      </Zoom>
    </Collapse>
  );
};

export default DonutChartTest;
