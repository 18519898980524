import { HighlightOff } from "@mui/icons-material";
import {
  Modal,
  Card,
  CardContent,
  Box,
  IconButton,
  Typography,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../_utils/modalStyle";
import AutocompleteTag from "./AutocompleteTag";

const EditTagModal: React.FC<{
  open: boolean;
  close: any;
  tagEntities: any;
  isValidColor: any;
  handleDeleteTag: any;
  handleModal: any;
  workId: any;
  type: any;
  setTagEntities: any;
}> = ({
  close,
  open,
  tagEntities,
  isValidColor,
  handleDeleteTag,
  handleModal,
  workId,
  type,
  setTagEntities,
}) => {
  const { t }: any = useTranslation("common");

  const handleClickModal = (e: any) => {
    e.stopPropagation();
  };

  return (
    <Modal open={open} onClick={handleClickModal}>
      <Card sx={{ ...modalStyle, width: 300 }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{ mt: -2, mr: -2 }}
            >
              <HighlightOff />
            </IconButton>
          </Box>
          <Box sx={{ paddingInline: "16px" }}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {t("tag.edit_add_tag")}
            </Typography>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
              {tagEntities?.length > 0 ? (
                <div>
                  {tagEntities?.map((tagEntity: any, index: number) => (
                    <Chip
                      key={index}
                      label={tagEntity.tag.label}
                      sx={{
                        m: 0.5,
                        fontSize: "12px",
                        height: "24px",
                        backgroundColor: "",
                        color: isValidColor(tagEntity?.tag?.color)
                          ? tagEntity?.tag?.color
                          : "#708CFF",
                      }}
                      onDelete={() => handleDeleteTag(tagEntity["@id"])}
                      onClick={(e: any) => handleModal(e, tagEntity)}
                    />
                  ))}
                </div>
              ) : (
                <Typography
                  sx={{
                    ml: 1,
                    opacity: "0.7",
                  }}
                  variant="body2"
                >
                  {t("recruiter.candidate.no_tag")}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <AutocompleteTag
              type={type}
              workId={workId}
              tagEntities={tagEntities}
              setTagEntities={setTagEntities}
            />
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default EditTagModal;
