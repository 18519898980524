import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Popover,
} from "@mui/material";
import { Utils } from "../../../Utils";
import BottomFilterContent from "../chip_filter/BottomFilterContent";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const TestsFilters: React.FC<any> = ({ preselected, submit }) => {
  const { isCollab, context } = useSelector((state: any) => state.auth);
  const person_id = useSelector((state: any) => state.user.person_id);
  const { t } = useTranslation("common");
  const params = useParams();

  const { data: filtersData, loading: filtersLoading } = useFetch(
    `filters/all${!isCollab && params.id ? `?person.id=${params.id}` : ""}`
  );
  const [filters, setFilters] = useState([]);
  const [chipLabel, setChipLabel] = useState("Loading...");
  const active =
    filters.filter((el: any) => el.canSelect && el.checked).length > 0;

  const [anchorEl, setAnchorEl] = useState<null>(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "testsFilters" : undefined;

  useEffect(() => {
    const savedFilters = Utils.getCookie(
      `${person_id}${
        context === "advisor" || context === "rh" || context === "manager"
          ? "_benef"
          : ""
      }_work_suggest_filters`
    );

    if (filtersData?.data?.matchers) {
      setFilters(
        filtersData?.data?.matchers.map((el: any) => ({
          ...el,
          checked: savedFilters ? savedFilters.includes(el.code) : el.canSelect,
        }))
      );
    }
  }, [context, filtersData, person_id]);

  useEffect(() => {
    const filteredFilters = filters.filter(
      (el: any) => el.checked && el.canSelect
    );
    if (filteredFilters.length === 0) {
      setChipLabel("Matching");
    } else {
      let title = filteredFilters
        .map((el: any) => t(`tests.${el.code}`))
        .slice(0, 2)
        .join(", ");

      if (filteredFilters.length > 2) {
        title += ` +${filteredFilters.length - 2}`;
      }

      setChipLabel(title);
    }
  }, [filters, t]);

  const handleSubmit = (value: any) => {
    setFilters(value);
    setAnchorEl(null);
    submit(
      value
        .filter((el: any) => el.checked && el.canSelect)
        .map((el: any) => el.code)
    );
  };

  const handleClear = () => {
    setFilters((current: any) =>
      current.map((el: any) => ({ ...el, checked: false }))
    );
    setAnchorEl(null);
    submit([]);
  };

  return (
    <div>
      <Chip
        sx={{
          pl: 2,
          pr: 2,
          m: 0.5,
          cursor: "pointer",
          fontWeight: active ? "bold" : "",
          border: active ? "2px solid" : "1px solid",
        }}
        label={filtersLoading ? t("autocomplete.loading") : chipLabel}
        color="primary"
        disabled={filtersLoading}
        variant="outlined"
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <FiltersList
          baseFilters={filters}
          reset={open}
          submit={handleSubmit}
          clear={handleClear}
        />
      </Popover>
    </div>
  );
};

export default TestsFilters;

const deepCopy = (value: any) => {
  return JSON.parse(JSON.stringify(value));
};

const FiltersList: React.FC<any> = ({ baseFilters, reset, submit, clear }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [filters, setFilters] = useState(deepCopy(baseFilters));
  const isCollab = useSelector((state: any) => state.auth.isCollab);
  const handleSubmit = () => {
    submit(filters);
  };

  const handleClear = () => {
    clear();
  };

  useEffect(() => {
    setFilters(deepCopy(baseFilters));
  }, [baseFilters, reset]);

  const handleChange = (event: any, filter: any) => {
    if (!filter.canSelect) return;
    setFilters((current: any) => {
      const arr: any = [...current];
      arr[arr.findIndex((el: any) => el.code === filter.code)].checked =
        event.target.checked;
      return arr;
    });
  };

  const redirect = (test: string) => {
    navigate(
      `/app/${
        test === "personality"
          ? "big5?intro=true"
          : test === "motivations"
          ? "culturefit?intro=true"
          : test === "mood"
          ? "mood"
          : "inc?intro=true"
      }`
    );
  };

  const isTest = (filter: any) => {
    if (!isCollab) return false;
    if (filter.canSelect) return false;

    switch (filter.code) {
      case "technical_skills":
        return false;
      case "transversal_skills":
        return false;
      default:
        return true;
    }
  };

  return (
    <>
      <FormGroup sx={{ padding: "10px" }}>
        {filters.map((filter: any) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              key={filter.code}
              control={
                <Checkbox
                  disabled={!filter.canSelect}
                  checked={filter.canSelect ? filter.checked : false}
                  onChange={(e) => handleChange(e, filter)}
                />
              }
              label={t(`tests.${filter.code}`)}
            />
            {isTest(filter) && (
              <Button
                sx={{ ml: "auto", fontSize: "14px" }}
                color="primary"
                variant="text"
                onClick={() => redirect(filter.code)}
              >
                Passer le test
              </Button>
            )}
          </div>
        ))}
        <BottomFilterContent
          handleSubmit={handleSubmit}
          handleClear={handleClear}
          disabled={false}
        />
      </FormGroup>
    </>
  );
};
