import {
  FormGroup,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useContext } from "react";
import { AdminParamsContext } from "../../../contexts/AdminParamsContext";
import AdminSaveParamsButton from "../AdminSaveParamsButton";
import { useTranslation } from "react-i18next";

const AdminNotificationsParams = () => {
  const { t } = useTranslation("common");

  const { parametersFetch, editParams } = useContext(AdminParamsContext);

  const params = parametersFetch?.data?.["hydra:member"]?.find(
    (el: any) => el.type === "NOTIFICATIONS_PARAMS"
  );

  const handleChanges = (p: any, key: string) => {
    const cp = { ...params };
    cp.parameters[key] = p;

    editParams(cp, "NOTIFICATIONS_PARAMS");
  };

  const obj = {
    param: params?.parameters,
  };

  return (
    <div>
      <Typography variant="h4">{t("admin.params.notif.title")}</Typography>
      <FormGroup>
        <Typography variant="body1" sx={{ mt: 1 }}>
          {t("admin.params.notif.display")}
        </Typography>
        <FormControlLabel
          value={obj?.param?.DISPLAY_NOTIF?.RH}
          onChange={(e: any) =>
            handleChanges(
              { ...obj?.param?.DISPLAY_NOTIF, RH: e.target.checked },
              "DISPLAY_NOTIF"
            )
          }
          control={<Checkbox checked={obj?.param?.DISPLAY_NOTIF?.RH} />}
          label={t("admin.params.notif.rh")}
        />
        <FormControlLabel
          value={obj?.param?.DISPLAY_NOTIF?.MANAGER}
          onChange={(e: any) =>
            handleChanges(
              {
                ...obj?.param?.DISPLAY_NOTIF,
                MANAGER: e.target.checked,
              },
              "DISPLAY_NOTIF"
            )
          }
          control={<Checkbox checked={obj?.param?.DISPLAY_NOTIF?.MANAGER} />}
          label={t("roles.manager")}
        />
        <FormControlLabel
          value={obj?.param?.DISPLAY_NOTIF?.COLLAB}
          onChange={(e: any) =>
            handleChanges(
              {
                ...obj?.param?.DISPLAY_NOTIF,
                COLLAB: e.target.checked,
              },
              "DISPLAY_NOTIF"
            )
          }
          control={<Checkbox checked={obj?.param?.DISPLAY_NOTIF?.COLLAB} />}
          label={t("roles.collab")}
        />
      </FormGroup>
      <AdminSaveParamsButton type="NOTIFICATION_PARAMS" />
    </div>
  );
};

export default AdminNotificationsParams;
