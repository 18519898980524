import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modalStyle } from "../../../../_utils/modalStyle";
import { HighlightOff, Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const AdminLocationModal: React.FC<{
  open: boolean;
  close: () => void;
  handleSave: any;
  alreadyAdded: any;
}> = ({ open, close, handleSave, alreadyAdded }) => {
  const { t } = useTranslation("common");
  const [list, setList] = useState<any>(alreadyAdded ?? []);
  const [value, setValue] = useState("");

  const handleAdd = () => {
    setList([...list, value]);
    setValue("");
  };

  const removeFromList = (i: number) => {
    const arr = [...list];
    arr.splice(i, 1);
    setList(arr);
  };

  const handleKeyDown = (e: any) => {
    e.keyCode === 13 &&
      e.target.value.replace(/\s/g, "").length > 0 &&
      handleAdd();
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "500px" },
        }}
      >
        <Box sx={{ width: "100%", display: "flex" }}>
          <IconButton
            onClick={close}
            sx={{ ml: "auto", mt: 1, mr: 1 }}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent sx={{ mt: -6 }}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            {t("admin.params.location.handle")}
          </Typography>
          <TextField
            id="filled-disabled"
            sx={{ width: "100%" }}
            onKeyDown={handleKeyDown}
            label={t("form.location")}
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Button
                  onClick={handleAdd}
                  sx={{
                    visibility:
                      value.replace(/\s/g, "").length > 0
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <Typography>{t("btn.add")}</Typography>
                </Button>
              ),
            }}
          />
          {/* LIST */}

          <Box>
            {list.map((place: string, i: number) => (
              <Box
                sx={{ display: "flex", alignItems: "center", pt: 1 }}
                key={place + i}
              >
                <Typography>{place}</Typography>

                <IconButton
                  sx={{ ml: "auto" }}
                  onClick={() => removeFromList(i)}
                >
                  <Close color="error" />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            sx={{ mt: 3 }}
          >
            <Button
              sx={{ width: "150px", height: "40px" }}
              onClick={close}
              variant="outlined"
            >
              <Typography variant="body2">{t("btn.cancel")}</Typography>
            </Button>
            <Button
              sx={{ width: "150px", height: "40px" }}
              onClick={() => {
                handleSave(list);
                close();
              }}
              variant="contained"
            >
              <Typography variant="body2">{t("btn.save")}</Typography>
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AdminLocationModal;
