export const SET_DISPATCHPOPUP_STATE = "SET_DISPATCHPOPUP_STATE";

export const setState = (payload: any) => {
  return {
    type: SET_DISPATCHPOPUP_STATE,
    payload,
  };
};

export const setOpenDispatchModal = (payload: any) => {
  return (dispatch: any, getState: any) => {
    const { dispatchModal } = getState();
    dispatch(setState({ ...dispatchModal, open: payload }));
  };
};
