import {
  Box,
  Card,
  CardContent,
  Divider,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import WorkTrainingItemSkeleton from "./WorkTrainingItemSkeleton";

const Root = styled(Card)(({ theme }: any) => ({
  top: "0px",
  boxShadow: "none",
  backgroundColor: theme.palette.grey[100],
  width: "100%",
  borderRadius: "9px",
}));

const WorkTrainingPageSkeleton = () => {
  return (
    <>
      <Root>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Skeleton variant="circular" width="50px" height="50px" />
          <Typography variant="h3">
            <Skeleton width="220px" />
          </Typography>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            variant="body2"
          >
            <Skeleton width="460px" />
            <Skeleton width="440px" />
            <Skeleton width="420px" />
          </Typography>
        </CardContent>
      </Root>
      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <Skeleton width="130px" height="40px" />
      </Box>
      {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        sx={{ mt: 2 }}
      >
        <Skeleton
          variant="circular"
          sx={{ m: 0.5 }}
          width="35px"
          height="35px"
        />
        <Skeleton
          variant="circular"
          sx={{ m: 0.5 }}
          width="35px"
          height="35px"
        />
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          variant="body2"
        >
          <Skeleton width="100px" />
          <Skeleton width="80px" />
        </Typography>
      </Box> */}
      <Box
        display="flex"
        justifyContent="space-evenly"
        sx={{
          mt: 3,
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "stretch" },
        }}
      >
        <Box>
          <Typography variant="h2">
            <Skeleton width="200px" />
          </Typography>
          <Typography variant="body1">
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="270px" />
            <Skeleton width="260px" />
            <Skeleton width="220px" />
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          sx={{ height: "auto", display: { xs: "none", md: "block" } }}
        />
        <Box>
          <Typography variant="h2">
            <Skeleton width="190px" />
          </Typography>
          <Typography variant="body1">
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="280px" />
            <Skeleton width="270px" />
            <Skeleton width="260px" />
            <Skeleton width="220px" />
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
        <Typography variant="h2">
          <Skeleton width="200px" />
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
        <Box>
          <WorkTrainingItemSkeleton trainingPage />
          <Root sx={{ m: 2, width: "335px" }}>
            <CardContent>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="h5"
              >
                <Skeleton variant="circular" width="28px" height="28px" />
                <Skeleton width="160px" sx={{ ml: 1 }} />
              </Typography>
              <Typography sx={{ mt: 2 }} variant="body1">
                <Skeleton width="120px" />
                <Skeleton width="140px" />
              </Typography>
            </CardContent>
          </Root>
          <Root sx={{ m: 2, width: "335px" }}>
            <CardContent>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="h5"
              >
                <Skeleton variant="circular" width="28px" height="28px" />
                <Skeleton width="100px" sx={{ ml: 1 }} />
              </Typography>
              <Typography sx={{ mt: 2 }} variant="body1">
                <Skeleton width="100px" />
                <Skeleton width="200px" />
                <Skeleton width="40px" />
              </Typography>
            </CardContent>
          </Root>
          <Root sx={{ m: 2, width: "335px" }}>
            <CardContent>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="h5"
              >
                <Skeleton variant="circular" width="28px" height="28px" />
                <Skeleton width="100px" sx={{ ml: 1 }} />
              </Typography>
              <Typography sx={{ mt: 2 }} variant="body1">
                <Skeleton width="100px" />
                <Skeleton width="200px" />
                <Skeleton width="40px" />
              </Typography>
            </CardContent>
          </Root>
        </Box>
        <Divider
          orientation="vertical"
          sx={{ height: "auto", display: { xs: "none", md: "block" } }}
        />
        <Box>
          <Root sx={{ m: 2, width: "335px" }}>
            <CardContent>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="h5"
              >
                <Skeleton variant="circular" width="28px" height="28px" />
                <Skeleton width="250px" sx={{ ml: 1 }} />
              </Typography>
              <Typography sx={{ mt: 2 }} variant="body1">
                <Skeleton width="250px" />
                <Skeleton width="240px" />
                <Skeleton width="245px" />
              </Typography>
            </CardContent>
          </Root>
          <Root sx={{ m: 2, width: "335px" }}>
            <CardContent>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="h5"
              >
                <Skeleton variant="circular" width="28px" height="28px" />
                <Skeleton width="175px" sx={{ ml: 1 }} />
              </Typography>
              <Typography sx={{ mt: 2 }} variant="body1">
                <Skeleton width="70px" />
              </Typography>
            </CardContent>
          </Root>
          <Root sx={{ m: 2, width: "335px" }}>
            <CardContent>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="h5"
              >
                <Skeleton variant="circular" width="28px" height="28px" />
                <Skeleton width="250px" sx={{ ml: 1 }} />
              </Typography>
              <Typography sx={{ mt: 2 }} variant="body1">
                <Skeleton width="250px" />
                <Skeleton width="240px" />
                <Skeleton width="240px" />
                <Skeleton width="245px" />
                <Skeleton width="145px" />
              </Typography>
            </CardContent>
          </Root>
          <Root sx={{ m: 2, width: "335px" }}>
            <CardContent>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="h5"
              >
                <Skeleton variant="circular" width="28px" height="28px" />
                <Skeleton width="230px" sx={{ ml: 1 }} />
              </Typography>
              <Typography sx={{ mt: 2 }} variant="body1">
                <Skeleton width="50px" />
                <Skeleton width="245px" />
              </Typography>
            </CardContent>
          </Root>
        </Box>
      </Box>
    </>
  );
};

export default WorkTrainingPageSkeleton;
