import {
  Typography,
  styled,
  Box,
  Button,
  Rating,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import axios from "axios";

interface IFormInput {
  rating: number;
  time: number;
  complexity: number;
}

const CustomButton = styled(Button)({
  width: "275px",
  height: "55px",
  fontSize: "12px",
});

const Big5EndQuestionnary: React.FC<any> = ({ submitTest }) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: {
      rating: 0,
      time: 0,
      complexity: 5,
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    // const handleDataTime = (time: number) => {
    //   switch (time) {
    //     case 25:
    //       return 2;
    //     case 50:
    //       return 3;
    //     case 75:
    //       return 4;
    //     case 100:
    //       return 5;
    //     default:
    //       return 1;
    //   }
    // };

    const response = {
      type: "QB5",
      level: "QUES",
      description: JSON.stringify({
        rating: data.rating,
        time: data.time,
      }),
    };

    try {
      await axios.post("logs", response);
    } catch (err) {}

    submitTest();
  };

  return (
    <div>
      <Typography
        variant="subtitle1"
        sx={{
          mb: 3,
          textAlign: "center",
          lineHeight: "19px",
        }}
      >
        {t("big5.end_questionnary.title")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Controller
              control={control}
              name={"rating"}
              rules={{
                validate: {
                  required: (value) =>
                    Number(value) > 0 ? true : "errors.required",
                },
              }}
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => (
                <Box>
                  <Typography gutterBottom>
                    {t("big5.end_questionnary.rating")}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Rating
                      max={10}
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      icon={<IconRating />}
                      emptyIcon={<IconRatingEmpty />}
                    />
                  </Box>
                  <Typography variant="body2" color="error">
                    {errors?.rating ? t("errors.required") : " "}
                  </Typography>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>
                      {t("big5.end_questionnary.not_at_all")}
                    </Typography>
                    <Typography>
                      {t("big5.end_questionnary.very_appreciated")}
                    </Typography>
                  </Box>
                </Box>
              )}
            />
          </Box>

          <Box display="flex" justifyContent="center">
            <Controller
              control={control}
              name="time"
              rules={{
                validate: {
                  required: (value) =>
                    Number(value) > 0 ? true : "errors.required",
                },
              }}
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => (
                <FormControl>
                  <Typography textAlign="center">
                    {t("big5.end_questionnary.time")}
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChange((event.target as HTMLInputElement).value)
                    }
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={t("big5.end_questionnary.marks.less_than_5")}
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label={t("big5.end_questionnary.marks.less_than_10")}
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label={t("big5.end_questionnary.marks.between_10_and_15")}
                    />
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label={t("big5.end_questionnary.marks.between_15_and_20")}
                    />
                    <FormControlLabel
                      value={5}
                      control={<Radio />}
                      label={t("big5.end_questionnary.marks.more_than_twenty")}
                    />
                  </RadioGroup>
                  <Typography variant="body2" color="error">
                    {errors?.time ? t("errors.required") : " "}
                  </Typography>
                </FormControl>
              )}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <CustomButton disabled={loading} variant="contained" type="submit">
              {t("btn.valid")}
            </CustomButton>
          </Box>
        </Stack>
      </form>
    </div>
  );
};

export default Big5EndQuestionnary;

const IconRating = styled("div")(
  ({ theme }) => `
  width: 23px;
  height: 23px;
  border-radius: 100%;
  background: ${theme.palette.primary.main};
  margin: 3px;
`
);

const IconRatingEmpty = styled("div")`
  width: 23px;
  height: 23px;
  border-radius: 100%;
  background: #a4a4a4;
  margin: 3px;
`;
