import { Box } from "@mui/material";
import React from "react";
// import { useTranslation } from "react-i18next";

const NoResultsList = () => {
  // const { t } = useTranslation("common");

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      {/* <Typography variant="h3" textAlign="center" sx={{ mt: 4 }}>
        {t("autocomplete.no_result")}
      </Typography> */}
      <img
        src="/static/illustrations/no_result.svg"
        alt="no result"
        width="275"
      />
    </Box>
  );
};

export default NoResultsList;
