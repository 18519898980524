import { Container, Typography, Box, styled } from "@mui/material";
import Page from "../components/Page";
import CardIllustrationTests from "../components/tests/CardIllustrationTests";
import { useTranslation } from "react-i18next";
import MultipleCircularProgression from "../components/tests/MultipleCircularProgression";
import useFetch from "../hooks/useFetch";
import TestsAPI from "../_api/_testsAPI";
import { useEffect, useState } from "react";
import { TestType } from "../@types/testType";
import CardTest from "../components/tests/CardTest";
import Big5Result from "../components/big5/Big5Result";
import { useNavigate } from "react-router";
import CulturefitResult from "../components/culturefit/CulturefitResult";
import { useSelector, shallowEqual } from "react-redux";
import IncResult from "../components/inc/IncResult";
import TestPageSkeleton from "../components/skeletons/collab/TestPageSkeleton";
import MoodResults from "../components/mood/results/MoodResults";

const Title = styled(Typography)(({ theme }: any) => ({
  background: "#fbfbfb",
  borderRadius: theme.shape.borderRadiusXs,
  padding: "5px",
  paddingLeft: "10px",
  paddingRight: "10px",
}));

const TestsPage = () => {
  const { hash } = window.location;
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const [tests, setTests] = useState<TestType[]>([]);
  const [progress, setProgress] = useState<any>({});
  const [completedTests, setCompletedTests] = useState(0);
  const { data, loading } = useFetch(TestsAPI.get());

  const updateProgress = (code: string, value: number) => {
    const newProgress: any = { ...progress };
    newProgress[code] = value;
    setProgress(newProgress);
  };

  const initTest = (tests: TestType[], b5Pg?: number, cfPg?: number) => {
    console.log(tests);
    const pushTests: TestType[] = [];
    let testCompleted = 0;
    let progressTests: any = {};

    // eslint-disable-next-line array-callback-return
    tests
      .filter((el: any) => !el.hidden) // ! Hide tests
      .forEach((test: TestType) => {
        if (test.code !== "mb5") {
          pushTests.push(test);
          test.isPassed && (testCompleted = ++testCompleted);
          progressTests = {
            [test.code]: test.isPassed
              ? 100
              : test.code === "b5" && b5Pg
              ? b5Pg
              : test.code === "cf" && cfPg
              ? cfPg
              : 0,
            ...progressTests,
          };
        }
      });
    setCompletedTests(testCompleted);
    setProgress(progressTests);
    setTests(pushTests);
    if (hash === "#archetypes") {
      pushTests?.filter((test: any) => test?.code === "b5")?.[0] &&
        (pushTests?.filter((test: any) => test?.code === "b5")?.[0]?.isPassed
          ? setTimeout(() => {
              const scrollDiv: any =
                document.getElementById(`Big5-ctn`)?.offsetTop;
              scrollDiv &&
                window.scrollTo({
                  top: scrollDiv + 30,
                  behavior: "smooth",
                });
            }, 200)
          : navigate(`/app/big5?intro=true`));
    }
  };
  const deleteResult = (code: string) => {
    const newTests = [...tests];
    const index = newTests.findIndex((item) => item.code === code);
    newTests[index].isPassed = false;
    newTests[index].canPass = true;
    updateProgress(code, 0);
  };

  useEffect(() => {
    const big5_progress: string | any = localStorage.getItem(
      `big5_progress_${tokenData.person_id}`
    );
    const cf_progress: string | any = localStorage.getItem(
      `cf_progress_${tokenData?.person_id}`
    );
    data &&
      initTest(
        data["hydra:member"],
        big5_progress && Math.ceil(parseInt(big5_progress)),
        cf_progress && Math.ceil(parseInt(cf_progress))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Page title="Tests">
      <Container sx={{ pt: 13, pb: 5 }}>
        <CardIllustrationTests test="page" height="240" />
        {loading ? (
          <TestPageSkeleton />
        ) : (
          <>
            <Box display="flex" justifyContent="center">
              <Title textAlign="center" variant="subtitle1">
                {t("tests.page.list")}
              </Title>
            </Box>
            <Typography
              sx={{ mt: 2 }}
              textAlign="center"
              color="primary"
              variant="body1"
              fontWeight="bold"
            >
              {t(
                `tests.page.text_completion.${
                  completedTests === Object.values(progress).length
                    ? "all_done"
                    : completedTests > 0
                    ? `in_progress${completedTests > 1 ? "_2" : "_1"}${
                        Object.values(progress).length - completedTests > 1
                          ? "_2"
                          : "_1"
                      }`
                    : "no_test"
                }`,
                {
                  completedTests: completedTests,
                  allTests: Object.values(progress).length,
                  notPassedTests:
                    Object.values(progress).length - completedTests,
                }
              )}
            </Typography>
            {(progress?.b5 >= 0 || progress?.cf >= 0 || progress?.inc >= 0) && (
              <MultipleCircularProgression
                data={[
                  ...(progress?.b5 >= 0 ? [progress?.b5] : []),
                  ...(progress?.cf >= 0 ? [progress?.cf] : []),
                  ...(progress?.inc >= 0 ? [progress?.inc] : []),
                ]}
                labels={Object.keys(progress)}
              />
            )}
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              justifyContent="space-evenly"
            >
              {tests?.map((test) => (
                <CardTest
                  key={test.code}
                  item={test}
                  test={test.code}
                  progress={progress[test.code]}
                />
              ))}
            </Box>
            {progress?.b5 === 100 ||
            progress?.inc === 100 ||
            progress?.cf === 100 ? (
              <Box display="flex" justifyContent="center">
                <Typography
                  textAlign="center"
                  variant="subtitle1"
                  sx={{
                    mb: 5,
                    mt: 5,
                    background: "#fbfbfb",
                    padding: 1,
                  }}
                >
                  {t("tests.page.stats")}
                </Typography>
              </Box>
            ) : (
              <></>
            )}

            {progress?.inc === 100 &&
              tests[tests.findIndex((item) => item.code === "inc")].results && (
                <Box sx={{ mt: 2 }} id="Inc-ctn">
                  <IncResult
                    result={
                      tests[tests.findIndex((item) => item.code === "inc")]
                        .results.results
                    }
                  />
                </Box>
              )}
            {progress?.b5 === 100 &&
              tests[tests.findIndex((item) => item.code === "b5")] && (
                <Box sx={{ mt: 2 }} id="Big5-ctn">
                  <Big5Result
                    results={
                      tests[tests.findIndex((item) => item.code === "b5")]
                        .results?.results
                    }
                    traits={
                      tests[tests.findIndex((item) => item.code === "b5")]
                        .results?.traits
                    }
                    result={
                      tests[tests.findIndex((item) => item.code === "b5")]
                    }
                    deleteResult={() => deleteResult("b5")}
                    retryTest={() => navigate("/app/big5")}
                  />
                </Box>
              )}
            {progress?.cf === 100 &&
              tests[tests.findIndex((item) => item.code === "cf")] && (
                <Box sx={{ mt: 2 }} id="CultureFit-ctn">
                  <CulturefitResult
                    result={
                      tests[tests.findIndex((item) => item.code === "cf")]
                    }
                    deleteResult={() => deleteResult("cf")}
                    retryTest={() => navigate("/app/culturefit")}
                  />
                </Box>
              )}
            {progress?.mood === 100 &&
              tests[tests.findIndex((item) => item.code === "mood")]
                .results && (
                <Box sx={{ mt: 2 }} id="Mood-ctn">
                  <MoodResults
                    results={handleResults(
                      tests[tests.findIndex((item) => item.code === "mood")]
                        .results.results
                    )}
                  />
                </Box>
              )}
          </>
        )}
      </Container>
    </Page>
  );
};

const handleResults = (res: any) => {
  const resu = res;
  const keys = Object.keys(resu);
  const arr: any[] = [];
  keys.forEach((el: any) =>
    arr.push({
      key: el,
      ...resu[el],
    })
  );
  return arr;
};

export default TestsPage;
