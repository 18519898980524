import { CardContent, Typography, Skeleton, Box, Paper } from "@mui/material";

import React from "react";

const HomeLastActionSkeleton = () => {
  return (
    <>
      <Paper elevation={3} sx={{ mt: 2, borderRadius: "23px" }}>
        <CardContent>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            <Skeleton width="100px" />
          </Typography>
          {Array.from(Array(7).keys()).map((el: number) => (
            <Box
              key={el}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              sx={{ mb: 2 }}
            >
              <Skeleton
                variant="circular"
                width="35px"
                height="35px"
                sx={{ mr: 0.5 }}
              />
              <Typography sx={{ mr: 0.5 }} variant="body2">
                <Skeleton width="100px" />
                <Skeleton width="150px" />
              </Typography>
              <Skeleton
                variant="rectangular"
                width="35px"
                height="35px"
                sx={{ ml: "auto", borderRadius: "15px" }}
              />
            </Box>
          ))}
        </CardContent>
      </Paper>
    </>
  );
};

export default HomeLastActionSkeleton;
