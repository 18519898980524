import { SET_MENU } from "../actions/menuActions";
import initialState from "../initialState";

const menuReducer = (state = { ...initialState.menu }, action: any) => {
  switch (action.type) {
    case SET_MENU:
      return { ...action.payload };
    default:
      return { ...state };
  }
};

export default menuReducer;
