import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import TestsAPI from "../../_api/_testsAPI";
import { TestType } from "../../@types/testType";
import { Button, Card, CardContent, Dialog, Typography } from "@mui/material";
import CulturefitIntro from "./CulturefitIntro";
import CulturefitResult from "./CulturefitResult";
import CulturefitTest from "./CulturefitTest";
import SplashScreenTest from "../common/SplashScreenTest";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { Utils } from "../../Utils";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTranslation } from "react-i18next";

const CulturefitContainer: React.FC<{
  onboarding?: boolean;
  nextStep?: any;
  onboardingStep?: number;
  lastStep?: boolean;
  canSkip?: boolean;
  handlePrevious?: () => void;
}> = ({
  onboarding,
  nextStep,
  onboardingStep,
  lastStep,
  handlePrevious,
  canSkip,
}) => {
  const { t } = useTranslation("common");
  const [step, setStep] = useState(0);
  const [realodCall, setRealodCall] = useState(false);
  const [openSplashScreen, setOpenSplashScreen] = useState(false);
  const navigate = useNavigate();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query: any = useQuery();

  const isIntro = query.get("intro") === "true";
  const isTest = query.get("test") === "true";
  const isResult = query.get("result") === "true";

  const { data, loading } = useFetch(
    TestsAPI.getById("cf/result", { reload: realodCall })
  );

  const [result, setResult] = useState<TestType>(data);

  const tokenData = useSelector((state: any) => state.user, shallowEqual);

  useEffect(() => {
    if (isIntro || isTest) {
      switch (step) {
        case 0:
          return navigate("/app/culturefit?intro=true", { replace: true });
        case 1:
          return navigate("/app/culturefit?test=true", { replace: true });
        case 2:
          return navigate("/app/culturefit?result=true", { replace: true });
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const updateSTep = () => {
    if (step + 1 === 2) {
      setOpenSplashScreen(true);
      setRealodCall(true);
      setTimeout(() => {
        setOpenSplashScreen(false);
      }, 6500);
    }
    setStep(step + 1);
  };

  const abortTest = () => {
    setStep(result?.isPassed ? 2 : 0);
  };

  const resetResult = () => {
    const newResults = { ...result, results: null };
    setResult(newResults);
    setStep(0);
  };

  const skipStep = () => {
    const newState: any =
      Utils.getCookie(`${tokenData.person_id}_do_later_step`) || [];
    newState?.push("valors");
    Utils.setLongCookies(`${tokenData.person_id}_do_later_step`, newState);
    nextStep();
  };

  useEffect(() => {
    const cf_pending = localStorage.getItem(
      `cf_pending_${tokenData?.person_id}`
    );
    cf_pending && setStep(1);
    !loading && setResult(data);
  }, [data, loading, tokenData]);

  return (
    <>
      {(step === 0 && !result?.results) || isIntro ? (
        <>
          {onboardingStep && onboardingStep > 1 && (
            <Button onClick={handlePrevious} variant="outlined">
              <KeyboardBackspaceIcon sx={{ fontSize: "20px" }} />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {t("onboarding.previous_btn")}
              </Typography>
            </Button>
          )}
          <CulturefitIntro
            nextStep={updateSTep}
            skipTest={canSkip ? skipStep : handlePrevious}
            onboarding={onboarding}
            onboardingStep={onboardingStep}
            canSkip={canSkip}
          />
        </>
      ) : (step === 1 && !result?.results) || isTest ? (
        onboarding ? (
          <CulturefitTest
            resultsSent={updateSTep}
            nextStep={canSkip ? nextStep : handlePrevious}
            abortTest={abortTest}
            onboarding={onboarding}
            personId={tokenData?.person_id}
          />
        ) : (
          <Card>
            <CardContent>
              <CulturefitTest
                resultsSent={updateSTep}
                abortTest={abortTest}
                personId={tokenData?.person_id}
              />
            </CardContent>
          </Card>
        )
      ) : (
        (step === 2 || result?.results || isResult) && (
          <>
            {onboardingStep && onboardingStep > 1 && (
              <Button onClick={handlePrevious} variant="outlined">
                <KeyboardBackspaceIcon sx={{ fontSize: "20px" }} />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {t("onboarding.previous_btn")}
                </Typography>
              </Button>
            )}
            <CulturefitResult
              result={result}
              deleteResult={resetResult}
              retryTest={resetResult}
              onboarding={onboarding}
              nextStep={nextStep}
              lastStep={lastStep}
            />
          </>
        )
      )}
      <Dialog open={openSplashScreen} transitionDuration={{ exit: 1250 }}>
        <SplashScreenTest test="valors" />
      </Dialog>
    </>
  );
};

export default CulturefitContainer;
