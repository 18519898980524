import {
  Box,
  Button,
  Container,
  Typography,
  Paper,
  Chip,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Page from "../components/Page";
import campaignsAPI from "../_api/_campaignsAPI";
import peopleAPI from "../_api/_people";
import { ArrowBackRounded, Face } from "@mui/icons-material";
import StringAvatar from "../components/common/StringAvatar";
import { useTheme } from "@mui/styles";
import TestIcon from "../icons/menu/test";
import ProfileIcon from "../icons/menu/profile";
import ProfileHeader from "../components/profile/ProfileHeader";
import axios from "axios";
import CampaignCandidatesScores from "../components/folder/CampaignCandidatesScores";
import IncWorkCompare from "../components/inc/compare/IncWorkCompare";
import Big5Work from "../components/big5/Big5Work";
import MatchingSkillsCampaign from "../components/folder/matching/MatchingSkillsCampaign";
import CulturefitMatching from "../components/culturefit/CulturefitMatching";
import LogoLoader from "../components/LogoLoader";
import DownloadPdfButton from "../components/common/DownloadPdfButton";
import DownloadMatchingButton from "../components/admin/recruiter/DownloadMatchingButton";
import TestsAPI from "../_api/_testsAPI";

const CustomContainer = styled(Container)(({ theme }: any) => ({
  borderRadius: theme.shape.borderRadiusPage,
  backgroundColor: theme.palette.collabBlue,
}));

const CustomButton = styled(Button)(({ theme }: any) => ({
  borderRadius: theme.shape.borderRadiusPage,
  height: "55px",
}));

const DLButton = styled(DownloadPdfButton)(({ theme }: any) => ({
  height: "40px",
}));

const Icon: any = styled(Box, {
  shouldForwardProp: (props) => props !== "people",
})(({ theme, people }: any) => ({
  padding: "10px",
  width: "65px",
  height: "65px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: theme.shape.borderRadius8,
  marginLeft: people ? "auto" : "none",
  marginRight: people ? "9px" : "auto",
  backgroundColor: people
    ? theme.palette.green[500]
    : theme.palette.primary.main,
  color: people ? theme.palette.common.white : theme.palette.common.white,
}));

const Subtext = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary[400],
}));

const BoxIcon = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "45px",
  height: "45px",
  borderRadius: "50%",
  backgroundColor: "#F2F2F2",
});

const MatchingChip = styled(Chip)({
  borderRadius: "9px !important",
});

const MatchingPersonCampaignPage = () => {
  const theme: any = useTheme();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { t } = useTranslation("common");
  const { id, campaignId } = useParams();

  const [campaignData, setCampaignData] = useState<any>();
  const [matchingScores, setMatchingScores] = useState<any>();
  const [employeeData, setEmployeeData] = useState<any>();
  const [completenessData, setCompletenessData] = useState<any>();
  const [results, setResults] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCampaignData = async () => {
    try {
      const campaignRes = await axios.get(
        campaignsAPI.getById(campaignId!, { candidate: id })
      );
      const matchingResults = await axios.get(
        campaignsAPI.getById(`${campaignId}/matching`, { candidate: id })
      );
      setMatchingScores(matchingResults?.data?.personMatchingScore);
      setCampaignData(campaignRes.data);
    } catch (err) {
      console.log(err);
      // err?.status === 404 && navigate("/404", { replace: true });
    }
  };

  const getEmployeeData = async () => {
    setIsLoading(true);
    try {
      const completenessRes = await axios.get(
        peopleAPI.getById(`${id}/completeness`)
      );
      const res = await axios.get(peopleAPI.getById(id!));
      const resultsCall = await axios.get(TestsAPI.get({ "person.id": id }));
      setEmployeeData(res.data);
      setCompletenessData(completenessRes.data);
      setResults(resultsCall.data["hydra:member"]);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      // err?.status === 404 && navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    id && getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    campaignId && getCampaignData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  return (
    <Page padding title={"Matching"}>
      {isLoading ? (
        <LogoLoader open />
      ) : (
        <CustomContainer sx={{ pt: 3, pb: 3, mt: 7 }}>
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            sx={{ justifyContent: { xs: "center", sm: "auto" } }}
          >
            <CustomButton
              sx={{ mb: { xs: 1, sm: 0 } }}
              variant="outlined"
              onClick={() => navigate(state?.fromCampaign ? -1 : -2)}
            >
              <ArrowBackRounded sx={{ mr: 1 }} />
              <Typography>
                {t("recruiter.candidate.matching_page.return_campaigns")}
              </Typography>
            </CustomButton>
            <Box sx={{ ml: { xs: 0, sm: "auto" } }}>
              {campaignData?.work?.id ? (
                <DownloadMatchingButton
                  notCollab
                  profileData={employeeData}
                  workId={campaignData?.work?.id}
                />
              ) : (
                <DLButton
                  notCollab
                  type="selection"
                  person={employeeData}
                  variant="contained"
                  text="pdf.selection_not_collab"
                />
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: "100%", mb: "15px", mt: "5px" }}>
            <Box sx={{ display: "flex", flexGrow: 1, ml: "16px" }}>
              <Icon people>
                <TestIcon size={27} />
              </Icon>
              <Icon>
                <ProfileIcon size={30} />
              </Icon>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Typography variant="h4">
              {t("recruiter.candidate.matching_page.title_1")}&nbsp;
            </Typography>
            <Typography variant="h4" sx={{ color: theme.palette.success.main }}>
              {campaignData?.name}
            </Typography>
            <Typography variant="h4">
              &nbsp;{t("recruiter.candidate.matching_page.title_2")}&nbsp;
            </Typography>
            &nbsp;
            <StringAvatar
              size={30}
              name={employeeData?.firstName + " " + employeeData?.lastName}
              url={employeeData?.image?.contentUrl}
              onClick={() =>
                navigate(`/app/profile/${employeeData?.id}/overview`)
              }
            />
            <Typography
              variant="h4"
              color="primary"
              onClick={() =>
                navigate(`/app/profile/${employeeData?.id}/overview`)
              }
              sx={{ cursor: "pointer" }}
            >
              &nbsp;{employeeData?.firstName}
              &nbsp;{employeeData?.lastName}
            </Typography>
          </Box>
          <Subtext variant="body2" textAlign="center" sx={{ mt: 1, mb: 3 }}>
            {t("advisor.folder.new_folder_text")}
          </Subtext>
          <Paper elevation={3} sx={{ borderRadius: "16px", padding: "20px" }}>
            {employeeData && completenessData && (
              <Box display="flex" justifyContent="center" width="100%">
                <Box
                  sx={{
                    mt: 3,
                    minWidth: { md: "800px", lg: "900px" },
                  }}
                >
                  <ProfileHeader
                    notCollab
                    matchingPage
                    profileData={employeeData}
                    completeness={completenessData}
                  />
                </Box>
              </Box>
            )}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              sx={{ mt: 6 }}
            >
              <BoxIcon sx={{ mr: 1 }}>
                <Face
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </BoxIcon>
              <Typography variant="h4" fontWeight="regular">
                {t("recruiter.candidate.matching_page.title_3")}&nbsp;
                {employeeData?.firstName}
                &nbsp;{employeeData?.lastName}
              </Typography>
              {matchingScores && (
                <MatchingChip
                  label={t("recruiter.candidate.matching", {
                    percent: matchingScores?.global,
                  })}
                  sx={{
                    ml: 2,
                    minWidth: "140px",
                    maxWidth: "140px",
                    color:
                      theme.palette[
                        matchingScores?.global > 69
                          ? "success"
                          : matchingScores?.global < 70 &&
                            matchingScores?.global > 29
                          ? "warning"
                          : "error"
                      ][500],
                    backgroundColor:
                      theme.palette[
                        matchingScores?.global > 69
                          ? "success"
                          : matchingScores?.global < 70 &&
                            matchingScores?.global > 29
                          ? "warning"
                          : "error"
                      ][500_8],
                  }}
                  color="success"
                />
              )}
            </Box>
            {matchingScores && (
              <Box display="flex" justifyContent="center" sx={{ mt: 3, mb: 6 }}>
                <CampaignCandidatesScores
                  scores={matchingScores}
                  justifyContent="space-around"
                  campaign={campaignData}
                  matchingPage
                />
              </Box>
            )}
            {campaignData?.expectedData?.skills && (
              <MatchingSkillsCampaign campaignId={campaignId} personId={id} />
            )}
            {campaignData?.expectedData?.personality &&
              campaignData?.expectedPersonality &&
              results?.filter((test: any) => test.code === "b5")?.[0]
                ?.results && (
                <Box sx={{ m: 1 }}>
                  <Big5Work
                    compare
                    noCard
                    workResults={{
                      work: campaignData?.expectedPersonality,
                      person: results?.filter(
                        (test: any) => test.code === "b5"
                      )?.[0]?.results?.results,
                    }}
                  />
                  <Box display="flex" justifyContent="center">
                    <DownloadPdfButton
                      person={employeeData}
                      height="44px"
                      variant="outlined"
                      type="b5"
                    />
                  </Box>
                </Box>
              )}
            {campaignData?.expectedData?.valors &&
              campaignData?.expectedMotivations &&
              results?.filter((test: any) => test.code === "cf")?.[0]?.results
                ?.results && (
                <Box sx={{ m: 1 }}>
                  <CulturefitMatching
                    personScores={
                      results?.filter((test: any) => test.code === "cf")?.[0]
                        ?.results?.results
                    }
                    matchScores={campaignData?.expectedMotivations}
                    noCard
                  />
                  <Box display="flex" justifyContent="center">
                    <DownloadPdfButton
                      person={employeeData}
                      height="44px"
                      variant="outlined"
                      type="cf"
                    />
                  </Box>
                </Box>
              )}
            {(campaignData?.expectedData?.inc ||
              campaignData?.expectedData?.gamified) &&
              campaignData?.personIncMatchingScore && (
                <Box sx={{ m: 1 }}>
                  <IncWorkCompare
                    results={campaignData?.personIncMatchingScore}
                    workResult={campaignData?.expectedDynamics}
                    noCard
                  />
                  <Box display="flex" justifyContent="center">
                    <DownloadPdfButton
                      person={employeeData}
                      height="44px"
                      variant="outlined"
                      type="inc"
                    />
                  </Box>
                </Box>
              )}
          </Paper>
        </CustomContainer>
      )}
    </Page>
  );
};

export default MatchingPersonCampaignPage;
