import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SelectComponent from "../common/SelectComponent";
import ControlledTextField from "../forms/ControlledTextField";
import FeedbackReaction from "./FeedbackReaction";

interface IFormInput {
  type: string;
  mood: string;
  message: string;
}

const FeedbackModalBody: React.FC<{
  close: () => void;
  loading: boolean;
  submit: (e: any) => void;
}> = ({ close, loading, submit }) => {
  const { t } = useTranslation("common");
  const theme: any = useTheme();

  const types = [
    { value: "compliment", label: "feedback.type.compliment" },
    { value: "suggestion", label: "feedback.type.suggestion" },
    { value: "worry", label: "feedback.type.worry" },
    { value: "other", label: "feedback.type.other" },
  ];

  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: { type: "compliment", mood: "", message: "" },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={4}>
        <Controller
          name="mood"
          control={control}
          rules={{
            required: { value: true, message: "errors.required" },
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <FeedbackReaction
              handleMood={onChange}
              activeMood={value}
              error={errors?.["mood"] && !value}
            />
          )}
        />
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            {t("feedback.text_2")}
          </Typography>
          <Controller
            name="type"
            control={control}
            rules={{
              required: { value: true, message: "errors.required" },
            }}
            render={({ field: { value, onChange } }) => (
              <SelectComponent
                items={types}
                onChange={onChange}
                preSelected={value}
              />
            )}
          />
        </Box>
        <Box>
          <Typography variant="subtitle1">{t("feedback.text_3")}</Typography>
          <Typography
            variant="body2"
            sx={{ mb: 1, fontSize: "10px;", opacity: 0.8 }}
          >
            {t("feedback.max_length")}
          </Typography>

          <ControlledTextField
            name="message"
            label={t(`feedback.message`)}
            control={control}
            multiline
            required
          />
        </Box>
        <Box display="flex" justifyContent="space-around">
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ borderRadius: "5px", width: "160px", height: "58px" }}
          >
            <Typography variant="body1"> {t("btn.send")}</Typography>
          </Button>
          <Button
            onClick={close}
            variant="outlined"
            color="secondary"
            type="button"
            sx={{ borderRadius: "5px", width: "160px", height: "58px" }}
          >
            <Typography
              color="secondary"
              variant="body1"
              sx={{ color: theme.palette.text.secondary }}
            >
              {t("btn.cancel")}
            </Typography>
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default FeedbackModalBody;
