import React from "react";
import FeedbackPeBody from "./FeedbackPeBody";
import { HighlightOff } from "@mui/icons-material";
import { Modal, Card, Box, IconButton, CardContent } from "@mui/material";
import { modalStyle } from "../../_utils/modalStyle";

const FeedbackPeModal: React.FC<{
  open: boolean;
  close: () => void;
}> = ({ open, close }) => {
  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "350px", sm: "445px" },
        }}
      >
        <Box sx={{ width: "100%", display: "flex" }}>
          <IconButton
            onClick={close}
            sx={{ ml: "auto", mt: 1, mr: 1 }}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent sx={{ pt: 0, pl: { sm: 6 }, pr: { sm: 6 } }}>
          <FeedbackPeBody firstConnection />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default FeedbackPeModal;
