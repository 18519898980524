import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeIcon from "../../icons/menu/home";
import JobSuggestIcon from "../../icons/menu/job_suggest";
// import MissionIcon from "../../icons/menu/mission";
import ProfileIcon from "../../icons/menu/profile";
// import ProjectIcon from "../../icons/menu/project";
import TestIcon from "../../icons/menu/test";
import WorkIcon from "../../icons/menu/work";
import NavigationRenderer from "../NavigationRenderer";
import TrainingIcon from "../../icons/menu/training";
import ProjectIcon from "../../icons/menu/project";
import ResourcesMenuIcon from "../../components/ressources/ResourcesMenuIcon";

const CollabContextMenu: React.FC<{
  handleLinkClick: any;
}> = ({ handleLinkClick }: any) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const context = useSelector((state: any) => state.auth.context);
  const menu = useSelector((state: any) => state.menu);

  const links = [
    context !== "candidate" && {
      icon: (
        <HomeIcon
          active={location.pathname.includes("home")}
          context={context}
          size={25}
        />
      ),
      label: t("home"),
      path: "/app/home",
      active: location.pathname.includes("home"),
    },
    // {
    //   label: t("profile"),
    //   icon: <PersonOutline className={classes.icon} />,
    //   links: [
    //     {
    //       icon: <People className={classes.icon} />,
    //       label: t("profilepage.feed"),
    //       path: "/app/profile/feed",
    //     },
    //     {
    //       icon: <Star className={classes.icon} />,
    //       label: t("profilepage.ratings"),
    //       path: "/app/profile/ratings",
    //     },
    //     {
    //       icon: <Subject className={classes.icon} />,
    //       label: t("profilepage.other"),
    //       path: "/app/profile/other",
    //     },
    //   ],
    // },
    menu?.profile && {
      label: t("profile_menu.profile"),
      icon: (
        <ProfileIcon
          active={location.pathname.includes("profile")}
          context={context}
          size={25}
        />
      ),
      path: "/app/profile",
      active: location.pathname.includes("profile"),
    },
    {
      label: t("my_tests"),
      icon: (
        <TestIcon
          active={location.pathname.includes("tests")}
          context={context}
          size={25}
        />
      ),
      path: "/app/tests",
      active: location.pathname.includes("tests"),
    },
    menu?.projects && {
      icon: (
        <ProjectIcon
          active={location.pathname.includes("project")}
          context={context}
        />
      ),
      label: t("projects.my_title"),
      path: "/app/projects",
      active: location.pathname.includes("projects"),
    },
    menu?.works && {
      icon: (
        <WorkIcon
          active={location.pathname.includes("work/suggest")}
          context={context}
          size={25}
        />
      ),
      label: t("work_suggest.label"),
      path: "/app/work/suggest",
      active: location.pathname.includes("work/suggest"),
    },
    menu?.trainings && {
      icon: (
        <TrainingIcon
          active={location.pathname.includes("trainings/list")}
          context={context}
          size={25}
        />
      ),
      label: t("trainings.page"),
      path: "/app/trainings/list",
      active: location.pathname.includes("trainings/list"),
    },
    menu?.jobs && {
      icon: (
        <JobSuggestIcon
          active={location.pathname.includes("job/suggest")}
          context={context}
          size={25}
        />
      ),
      label: t("job_suggest.title"),
      path: "/app/job/suggest",
      active: location.pathname.includes("job/suggest"),
    },
    menu?.resources && {
      icon: <ResourcesMenuIcon />,
      label: t("resources.title"),
      path: "/app/resources",
      active: location.pathname.includes("resources"),
    },
    // {
    //   icon: <MissionIcon active={location.pathname.includes("mission")} context={context}/>,
    //   label: "Missions",
    // },
  ].filter((el) => el && el !== false);

  return <NavigationRenderer links={links} handleLinkClick={handleLinkClick} />;
};

export default CollabContextMenu;
