import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ControlledMonthSelector: React.FC<{
  control: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
  checked?: boolean;
  label?: any;
}> = ({ control, name, required = false, disabled, checked, label }) => {
  const { t } = useTranslation("common");

  return (
    <FormControl fullWidth>
      <InputLabel>{t("admin.graph.month_label")}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={{
          required: { value: required, message: "errors.required" },
        }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <Select
            disabled={disabled}
            error={Boolean(errors?.[name])}
            value={value}
            label={label}
            onChange={onChange}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((e) => (
              <MenuItem key={e} value={e}>
                {t(`admin.graph.month.${e}`)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default ControlledMonthSelector;
