import React, { useEffect, useState } from "react";
import projectsService from "../services/projects.service";

const useProjects = (id: string = "") => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [person, setPerson] = useState<any>({});
  const [careers, setCareers] = useState<any>([]);
  const [trainings, setTrainings] = useState<any>([]);

  React.useEffect(() => {
    setLoading(true);
    const subscription = projectsService.get(id).subscribe({
      next: (res: any) => {
        setLoading(false);
        setError(false);
      },
      error: (err: any) => {
        setLoading(false);
        setError(true);
      },
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [id]);

  useEffect(() => {
    const subscription = projectsService._projects.subscribe((p: any) => {
      setPerson(p.person);
      setCareers(p.projectCareers);
      setTrainings(p.projectTrainings);
    });
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  return { loading, error, person, careers, trainings };
};

export default useProjects;
