import React from "react";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  // Button,
  Checkbox,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const AdminDisplayJobsTarget: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">
        {t("admin.params.work_suggest.title")}
      </Typography>
      <FormGroup sx={{ mt: 3 }}>
        <TextField
          sx={{ maxWidth: "250px" }}
          label={t("admin.params.work_suggest.menu")}
          value={param.MENU_NAME}
          onChange={(event: any) =>
            handleChanges({ ...param, MENU_NAME: event.target.value })
          }
        />
        <TextField
          sx={{ mt: 2, maxWidth: "600px" }}
          value={param.EXPLAIN_TEXT}
          multiline
          onChange={(event: any) =>
            handleChanges({ ...param, EXPLAIN_TEXT: event.target.value })
          }
          label={t("admin.params.work_suggest.text_explain")}
          minRows={2}
          variant="outlined"
        />
      </FormGroup>

      <FormGroup sx={{ mt: 2 }}>
        <Typography>{t("admin.params.work_suggest.display")}</Typography>
        <FormControlLabel
          value={param.SKILLS}
          onChange={(e: any) =>
            handleChanges({ ...param, SKILLS: e.target.checked })
          }
          control={<Checkbox checked={param.SKILLS} />}
          label={t("admin.params.work_suggest.skills")}
        />
        <FormControlLabel
          value={param.PERSONALITY}
          onChange={(e: any) =>
            handleChanges({ ...param, PERSONALITY: e.target.checked })
          }
          control={<Checkbox checked={param.PERSONALITY} />}
          label={t("admin.params.work_suggest.b5")}
        />
        <FormControlLabel
          value={param.VALORS}
          onChange={(e: any) =>
            handleChanges({ ...param, VALORS: e.target.checked })
          }
          control={<Checkbox checked={param.VALORS} />}
          label={t("admin.params.work_suggest.cf")}
        />
        <FormControlLabel
          value={param.MOTIVATIONS}
          onChange={(e: any) =>
            handleChanges({ ...param, MOTIVATIONS: e.target.checked })
          }
          control={<Checkbox checked={param.MOTIVATIONS} />}
          label={t("admin.params.work_suggest.inc")}
        />
      </FormGroup>

      <FormGroup sx={{ mt: 2 }}>
        <Typography>{t("admin.params.work_suggest.display_reach")}</Typography>
        <FormControlLabel
          value={param.REACH_SKILLS}
          onChange={(e: any) =>
            handleChanges({ ...param, REACH_SKILLS: e.target.checked })
          }
          control={<Checkbox checked={param.REACH_SKILLS} />}
          label={t("admin.params.work_suggest.skills")}
        />
        <FormControlLabel
          value={param.REACH_PERSONALITY}
          onChange={(e: any) =>
            handleChanges({ ...param, REACH_PERSONALITY: e.target.checked })
          }
          control={<Checkbox checked={param.REACH_PERSONALITY} />}
          label={t("admin.params.work_suggest.b5")}
        />
        <FormControlLabel
          value={param.REACH_VALORS}
          onChange={(e: any) =>
            handleChanges({ ...param, REACH_VALORS: e.target.checked })
          }
          control={<Checkbox checked={param.REACH_VALORS} />}
          label={t("admin.params.work_suggest.cf")}
        />
        <FormControlLabel
          value={param.REACH_MOTIVATIONS}
          onChange={(e: any) =>
            handleChanges({ ...param, REACH_MOTIVATIONS: e.target.checked })
          }
          control={<Checkbox checked={param.REACH_MOTIVATIONS} />}
          label={t("admin.params.work_suggest.inc")}
        />
      </FormGroup>

      <Typography sx={{ mt: 2, mb: 1 }}>
        {t("admin.params.work_suggest.video")}
      </Typography>
      <TextField
        sx={{ width: { xs: "100%", sm: "80%", md: "60%" } }}
        label={t("admin.params.work_suggest.ytb")}
        value={param.WORK_VIDEO}
        onChange={(event: any) =>
          handleChanges({ ...param, WORK_VIDEO: event.target.value })
        }
      />
      {/* <Button variant="outlined">Choisir un fichier</Button> */}

      <Typography sx={{ mt: 2 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
    </div>
  );
};

export default AdminDisplayJobsTarget;
