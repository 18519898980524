import {
  Autocomplete,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { Api } from "../_api/Api.class";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

const LanguagesAutocomplete: React.FC<any> = ({
  addElement,
  ignoreLanguages,
}) => {
  const api = new Api(`languages`);
  const [needle, setNeedle] = useState<any>(null);
  const { t } = useTranslation("common");

  const { data, loading, error } = useFetch(api.get({ label: needle }), true);

  const [value, setValue] = useState<any>("");
  const [options, setOptions] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>("");
  const [init, setInit] = useState(true);

  const [helperText, setHelperText] = useState("");

  const handleChange = (e: any, val: any) => {
    if (val?.id) {
      addElement(val);
      setValue({ label: "" });
      setInputValue("");
      setOptions([]);
      setHelperText("");
    }
  };

  const search = () => {
    setNeedle(inputValue);
    setHelperText("");
  };

  useEffect(() => {
    let finalList: any = [];
    if (data && data["hydra:member"]) {
      finalList = [...data["hydra:member"]];
      if (ignoreLanguages) {
        ignoreLanguages.forEach((el: any) => {
          const index = finalList.findIndex((e: any) => e.id === el.id);
          if (index > -1) finalList.splice(index, 1);
        });
      }
      setOptions(finalList);
      if (finalList.length === 0 && inputValue?.length >= 3) {
        setHelperText("Aucun résultat");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (init) {
      setInit(false);
      return;
    }
    const timeout = setTimeout(() => {
      if (inputValue?.length >= 3) {
        search();
      } else {
        // setOptions([]);
        if (inputValue?.length < 3 && inputValue.length > 1) {
          setHelperText("3 caractères min");
        }
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <Autocomplete
      {...{ loading, value, options }}
      id="autocompleteComponent"
      onChange={handleChange}
      freeSolo
      disableClearable={inputValue?.length === 0}
      getOptionLabel={(option: any) => option?.label || ""}
      loadingText={t("autocomplete.loading")}
      forcePopupIcon
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={t("profilepage.infos.lang")}
          // sx={{
          //   "& .MuiOutlinedInput-root": {
          //     borderTopLeftRadius: noRadius ? "0px" : "",
          //     borderBottomLeftRadius: noRadius ? "0px" : "",
          //   },
          // }}
          value={inputValue}
          error={error}
          onChange={(text: any) => {
            setInputValue(text.target.value);
          }}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default LanguagesAutocomplete;
