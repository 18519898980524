import { Card, CardContent, Skeleton, styled, Typography } from "@mui/material";
import React from "react";

const Root = styled(Card)(({ theme }: any) => ({
  top: "0px",
  boxShadow: "none",
  backgroundColor: theme.palette.grey[100],
  width: "335px",
  borderRadius: "9px",
  margin: theme.spacing(1.5),
}));

const WorkTrainingItemSkeleton: React.FC<{ trainingPage?: boolean }> = ({
  trainingPage,
}) => {
  return (
    <Root>
      <CardContent>
        {trainingPage ? (
          <>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              variant="h5"
            >
              <Skeleton variant="circular" width="28px" height="28px" />
              <Skeleton width="120px" sx={{ ml: 1 }} />
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              variant="h4"
            >
              <Skeleton width="220px" />
              <Skeleton
                variant="circular"
                width="24px"
                height="24px"
                sx={{ ml: "auto" }}
              />
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              <Skeleton width="150px" />
            </Typography>
            {/* <Typography variant="body1">
              <Skeleton width="75px" />
            </Typography> */}
          </>
        )}

        <Typography
          sx={{ mt: 1, display: "flex", alignItems: "center" }}
          variant="h3"
        >
          <Skeleton width="60px" />
          <Skeleton width="60px" sx={{ ml: 1 }} />
        </Typography>
        <Typography sx={{ mt: 1 }} variant="h3">
          <Skeleton width="100px" />
        </Typography>
        {/* <Typography sx={{ mt: 1 }} variant="h3">
          <Skeleton width="225px" />
        </Typography> */}
        {/* <Typography sx={{ mt: 1 }} variant="h3">
          <Skeleton width="150px" />
        </Typography> */}
        <Typography sx={{ mt: 1 }} variant="h3">
          <Skeleton width="120px" />
        </Typography>
      </CardContent>
    </Root>
  );
};

export default WorkTrainingItemSkeleton;
