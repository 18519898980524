const diplomas = [
  "CAP, BEP",
  "Bac",
  "Bac +2 (BTS, DUT)",
  "Bac +3 (Licence)",
  "Bac +4 (Maîtrise, master 1)",
  "Bac +5 (Master, Ingénieur)",
  "Bac +8 (Doctorat)",
];

export default diplomas;
