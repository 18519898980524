import { Box, styled } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import IncAdaptationHeader from "./headers/IncAdaptationHeader";
import IncAdaptationDescription from "./IncAdaptationDescription";

const ChartContainer = styled(Box)(({ theme }: any) => ({
  display: "flex",
  marginTop: theme.spacing(6),
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const IncAdaptation: React.FC<any> = ({ result }) => {
  const { t } = useTranslation("common");

  const [chartState, setChartState]: any = React.useState({
    series: [
      {
        name: "Series 1",
        data: [80, 50, 30, 40, 100, 20],
      },
      {
        name: "Series 1",
        data: [80, 50, 30, 40, 100, 20],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        toolbar: {
          show: false,
        },
        height: 350,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      stroke: {
        width: 2,
        colors: ["#FFF", "#FFF"],
      },
      fill: {
        opacity: 0.3,
        colors: ["#4C6FFF", "#FF6C40"],
      },
      markers: {
        size: 4,
        colors: ["#4C6FFF", "#FF6C40"],
      },
      xaxis: {
        range: 10,
        categories: [
          t("inc.adaptation.flexibility"),
          t("inc.adaptation.shade"),
          t("inc.adaptation.relativity"),
          t("inc.adaptation.reflection"),
          t("inc.adaptation.personal_opinions"),
          t("inc.adaptation.difficult_situation"),
          t("inc.adaptation.curiosity"),
        ],
        labels: {
          style: {
            fontSize: "14px",
          },
        },
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        max: 10,
      },
      grid: {
        padding: {
          top: -50,
          bottom: -50,
          left: 10,
          right: -30,
        },
      },
    },
  });

  const keys = Object.keys(result?.dynamic_adaptation);

  React.useEffect(() => {
    const state = { ...chartState };
    if (result?.dynamic_adaptation) {
      state.series = keys.map((e: any) => ({ name: t(`inc.${e}`), data: [] }));
      state.options.xaxis.categories = [];
      const categories: any = [];

      result.dynamic_adaptation[keys[0]].forEach((el: any) =>
        categories.push(el.title)
      );

      for (let key in result.dynamic_adaptation) {
        categories.forEach((str: string) => {
          const value =
            result.dynamic_adaptation[key].find((el: any) => el.title === str)
              ?.value || 0;
          state.series[keys.indexOf(key)].data.push(value);
        });
      }

      state.options = { ...state.options, xaxis: { categories } };
    }
    setChartState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <Box sx={{ textAlign: "center", mb: 2 }}>
      <IncAdaptationHeader />

      <ChartContainer>
        <Box sx={{ flexGrow: 1 }}>
          <ReactApexChart
            options={chartState.options}
            series={chartState.series}
            type="radar"
            height={350}
          />
        </Box>
      </ChartContainer>
      <IncAdaptationDescription result={result} />
    </Box>
  );
};

export default IncAdaptation;
