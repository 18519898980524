import { Box, Rating, styled, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext } from "react";
import { ProfileContext } from "../../contexts/ProfileContext";

const BubbleContainer = styled(Box)(({ theme }: any) => ({
  backgroundColor: theme.palette.primary.main,
  padding: 9,
  borderRadius: "9px 9px 0px 0px",
  display: "flex",
  gap: 6,
  width: "min-content",
}));

const LabelContainer = styled(Box)(({ theme }: any) => ({
  backgroundColor: "#EDF1FF",
  padding: "7px 14px 7px 20px",
  borderRadius: "0px 9px 9px 9px",
  display: "flex",
  gap: 6,
  width: "fit-content",
}));

const Label = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.primary.main,
  maxWidth: "40ch",
}));

const Bullet = styled(Box)(({ theme }: any) => ({
  backgroundColor: theme.palette.common.white,
  border: "1px solid white",
  width: "13.5px",
  height: "13.5px",
  borderRadius: "50%",
  margin: 3,
}));

const EmptyBullet = styled(Box)(({ theme }: any) => ({
  backgroundColor: theme.palette.primary.main,
  border: "1px solid white",
  width: "13.5px",
  height: "13.5px",
  borderRadius: "50%",
  margin: 3,
}));

// const IconContainer = styled(Box)(({ theme }: any) => ({
//   minWidth: 21,
//   height: 21,
//   borderRadius: "50%",
//   backgroundColor: "#EDF1FF",
//   margin: "5px 15px auto 0px",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// }));

const RatingBubbles = ({
  identifier,
  readOnly,
  context,
  showLabel = true,
  offline,
  onChanges,
  intendLevel,
  profileSection,
}: any) => {
  const { state, dispatch }: any = useContext(context);
  const profileContext = useContext(ProfileContext);

  const handleChange = (event: any, newValue: any) => {
    if (!newValue) return;

    const oldValue = state.value;
    dispatch({ type: "VALUE", value: newValue });
    if (offline) onChanges && onChanges(event, newValue);
    if (offline) return;
    dispatch({ type: "LOADING", value: true });
    axios
      .put(state.skill["@id"].replace("/api/", ""), {
        level: `/api/skill_levels/${newValue}`,
      })
      .then((res: any) => {
        // Update profile skills
        if (profileContext) {
          profileContext.updateSkills(res.data);
        }
      })
      .catch((error: any) => {
        dispatch({ type: "VALUE", value: oldValue });
      })
      .finally(() => {
        dispatch({ type: "LOADING", value: false });
      });
  };

  return (
    <Box display="flex">
      {/* {state.skill.isActive && (
        <IconContainer>
          <img
            src={`/static/icons/portfolio.svg`}
            alt={`experience icon`}
            width="12px"
            color="primary"
          />
        </IconContainer>
      )} */}
      <Box
        sx={
          {
            // ml: state.skill.isActive ? 0 : profileSection ? 4.5 : 0,
          }
        }
      >
        <BubbleContainer>
          <Rating
            readOnly={state.loading || state.readOnly || readOnly}
            emptyIcon={<EmptyBullet />}
            max={4}
            name={`unique-rating-${identifier}`}
            value={state.value}
            onChange={handleChange}
            icon={<Bullet />}
            sx={
              intendLevel && intendLevel > 0
                ? {
                    "& span:nth-of-type(n) span": {
                      borderRadius: 2,
                    },
                    "& span:nth-of-type(1) span": {
                      backgroundImage:
                        intendLevel === 1
                          ? `url('/static/icons/Ico/chps/skillsDashed.svg')`
                          : "",
                    },
                    "& span:nth-of-type(2) span": {
                      backgroundImage:
                        intendLevel === 2
                          ? `url('/static/icons/Ico/chps/skillsDashed.svg')`
                          : "",
                    },
                    "& span:nth-of-type(3) span": {
                      backgroundImage:
                        intendLevel === 3
                          ? `url('/static/icons/Ico/chps/skillsDashed.svg')`
                          : "",
                    },
                    "& span:nth-of-type(4) span": {
                      backgroundImage:
                        intendLevel === 4
                          ? `url('/static/icons/Ico/chps/skillsDashed.svg')`
                          : "",
                    },
                  }
                : {}
            }
          />
        </BubbleContainer>
        {showLabel ? (
          <LabelContainer>
            <Label> {state.skill.label || state.skill.skill.label}</Label>
          </LabelContainer>
        ) : null}
      </Box>
    </Box>
  );
};

export default RatingBubbles;
