import { Box, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import palette from "../../theme/palette";

const Container = styled("div")({
  height: "10px",
  borderRadius: "10px",
  display: "flex",
  position: "relative",
  marginTop: ".5em",
  width: "100%",
  backgroundColor: "#7388A95A",
});

const ProgressMarker: any = styled("div", {
  shouldForwardProp: (props) => props !== "returnColor" && props !== "result",
})(({ returnColor, result }: any) => ({
  display: "flex",
  position: "relative",
  width: "100%",
  "&:before": {
    width: "20%",
    height: "10px",
    borderRadius: "10px",
    position: "absolute",
    bottom: "0",
    top: "0",
    content: "''",
    transition: "left .6s ease-in-out",
    left: result ? `${(result - 1) * 20}%` : "40%",
    willChange: "left",
    backgroundColor: returnColor(),
  },
  "&.compare:before": {
    backgroundColor: "#333539",
  },
}));

const Big5CompareBars: React.FC<any> = ({
  result,
  trait,
  compare,
  compareResult,
}) => {
  const returnColor = () => {
    switch (trait) {
      case "agreeableness":
        return palette.b5.agreeableness;
      case "conscientiousness":
        return palette.b5.conscientiousness;
      case "extraversion":
        return palette.b5.extraversion;
      case "neuroticism":
        return palette.b5.neuroticism;
      case "openness":
        return palette.b5.openness;
    }
  };
  const ref = useRef();
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    let observer: any;

    if ("IntersectionObserver" in window) {
      observer = new IntersectionObserver(([entry]) => {
        setState(entry.isIntersecting);
      });
    } else {
      setState(true);
    }

    const current: any = ref.current;

    ref.current && observer && observer.observe(current);

    return () => current && observer.unobserve(current);
  }, []);

  return (
    <Box ref={ref}>
      <Container>
        <ProgressMarker
          returnColor={returnColor}
          result={isVisible && result}
        />
      </Container>
      {compare && (
        <Container>
          <ProgressMarker
            className={"compare"}
            returnColor={returnColor}
            result={isVisible && compareResult}
          />
        </Container>
      )}
    </Box>
  );
};

export default Big5CompareBars;
