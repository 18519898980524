import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminJobs: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">{t("admin.params.jobs.title")}</Typography>
      <FormGroup>
        <FormControlLabel
          value={param.DISPLAY_SUGGEST}
          onChange={(e: any) =>
            handleChanges({ ...param, DISPLAY_SUGGEST: e.target.checked })
          }
          control={<Checkbox checked={param.DISPLAY_SUGGEST} />}
          label={t("admin.params.jobs.display")}
        />
        <FormControlLabel
          value={param.BTN_APPLY}
          onChange={(e: any) =>
            handleChanges({ ...param, BTN_APPLY: e.target.checked })
          }
          control={<Checkbox checked={param.BTN_APPLY} />}
          label={t("admin.params.jobs.apply")}
        />
        <FormControlLabel
          value={param.research}
          onChange={(e: any) =>
            handleChanges({ ...param, research: e.target.checked })
          }
          control={<Checkbox checked={param.research} />}
          label={t("admin.params.jobs.research")}
        />
      </FormGroup>

      <Typography textAlign="right">{t("admin.params.reco_text")}</Typography>
    </div>
  );
};

export default AdminJobs;
