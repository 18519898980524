import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const InfoSkillHeader: React.FC<any> = ({ type }) => {
  const { t } = useTranslation("common");
  return (
    <Box display="flex" alignItems="center" width="100%">
      <img
        style={{
          width: "25px",
          borderRadius: "8px",
          background: type === "technical" ? "#DAC2F4" : "#fff1c2",
          padding: "5px",
        }}
        alt="skills icon"
        src={
          "/static/icons/skills" +
          (type === "technical" ? "" : "_abilities") +
          "_logo.svg"
        }
      />

      <Typography
        sx={{
          background: type === "technical" ? "#fbf9fd" : "#fffdf6",
          ml: 1,
          p: "3px",
          pl: "5px",
          pr: "5px",
          borderRadius: "4px",
        }}
        variant="body1"
        fontWeight="bold"
      >
        {type === "technical" ? t("tests.my_skills") : t("tests.my_abilities")}
      </Typography>
    </Box>
  );
};

export default InfoSkillHeader;
