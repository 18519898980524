import { Box } from "@mui/material";
import React from "react";
import WorkTrainingItemSkeleton from "./WorkTrainingItemSkeleton";

const WorkTrainingSkeletonList = () => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      sx={{ justifyContent: { xs: "center", md: "space-evenly" } }}
    >
      {Array.from(Array(6).keys()).map((el: number) => (
        <WorkTrainingItemSkeleton key={el} />
      ))}
    </Box>
  );
};

export default WorkTrainingSkeletonList;
