import {
  Modal,
  Card,
  CardContent,
  Box,
  IconButton,
  Typography,
  CardActions,
  Button,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { modalStyle } from "../../_utils/modalStyle";
import { HighlightOff } from "@mui/icons-material";
import SkillEdit from "../skills/SkillEdit";
import { useTranslation } from "react-i18next";
import personSkillsAPI from "../../_api/_personSkillsAPI";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  margin: "auto",
  marginBottom: "11px",
  marginTop: "-16px",
  width: 55,
  height: 55,
  background: theme.palette.primary[500_8],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SkillEditList: React.FC<{
  skillsList: any;
  handleSkill?: any;
  handleRemove?: any;
}> = ({ skillsList, handleSkill, handleRemove }) => {
  return (
    <>
      {skillsList.map((skill: any) => (
        <SkillEdit
          skill={skill}
          key={skill.id}
          identifier={skill.id}
          offline={true}
          skillEmitter={handleSkill}
          deleteEmitter={handleRemove}
          canRemove={false}
        />
      ))}
    </>
  );
};

const WorkSkillsModal: React.FC<{
  open: boolean;
  close: () => void;
  handleSubmit: (skill: any) => void;
  type?: string;
  skillsList?: any;
}> = ({ open, close, handleSubmit, skillsList, type }) => {
  const { t } = useTranslation("common");

  const [newSkillsList, setNewSkillsList] = useState([...skillsList]);

  useEffect(() => {
    const list = [...skillsList];
    list.forEach((el: any) => {
      el.level = { level: el.skillLevel };
    });
    setNewSkillsList(list);
  }, [skillsList]);

  const handleChange = (skill: any) => {
    const index = newSkillsList.findIndex((el: any) => el.id === skill.id);
    const copy = [...newSkillsList];
    copy[index] = skill;
    setNewSkillsList(copy);
  };

  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = () => {
    setLoading(true);
    const calls: any = [];

    newSkillsList.map((skill: any) =>
      calls.push(
        personSkillsAPI.post({
          label: skill.skill.label,
          level: `/api/skill_levels/${skill.level.level}`,
          skill: skill.skill["@id"],
        })
      )
    );
    Promise.all(calls)
      .then((x) => {
        handleSubmit(x);
        close();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
        }}
      >
        <Box sx={{ width: "100%", display: "flex" }}>
          <IconButton
            onClick={close}
            sx={{ ml: "auto", mt: 1, mr: 1 }}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <Icon>
          {type === "transversal" ? (
            <LightbulbOutlinedIcon color="primary" />
          ) : (
            <BuildOutlinedIcon color="primary" />
          )}
        </Icon>
        <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
          {t(
            `skills.work_${
              type === "transversal" ? "abilities" : "skills"
            }_modal`
          )}
        </Typography>
        <CardContent>
          <SkillEditList
            handleSkill={handleChange}
            skillsList={newSkillsList}
          />
        </CardContent>
        <CardActions
          sx={{ display: "flex", justifyContent: "center", mb: 2, mt: 2 }}
        >
          <Button
            onClick={handleSave}
            disabled={loading}
            size="large"
            variant="contained"
          >
            {t("btn.save")}
          </Button>
          <Button onClick={close} size="large">
            {t("btn.cancel")}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default WorkSkillsModal;
