import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ControlledQuill from "../forms/ControlledQuill";
import { QuillCampaignStyle } from "../../_utils/quillCampaignStyle";
import { useForm } from "react-hook-form";
import { AdminParamsContext } from "../../contexts/AdminParamsContext";
import { useSelector } from "react-redux";

const AdminEmail = () => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const { params, editParams } = useContext(AdminParamsContext);

  const emailParams = params?.find((el: any) => el.type === "LABELS_PARAMS");
  const parameters =
    emailParams?.parameters?.["fr"]?.[context]?.[
      context === "advisor" ? "folder" : "campaign"
    ]?.email_content;

  const commonContent = t("recruiter.campaign.email_content", {
    firstName: "{{firstName}}",
    lastName: "{{lastName}}",
    company: "{{company}}",
  });

  const [value, setValue] = useState(parameters ?? commonContent ?? "");

  const { control, watch } = useForm({
    defaultValues: {
      emailContent: value,
    },
  });

  useEffect(() => {
    const to = setTimeout(() => {
      const p = { ...emailParams };

      if (!parameters) {
        p.parameters["fr"] = {
          ...(p.parameters["fr"] ? p.parameters["fr"] : {}),
        };
        p.parameters["fr"][context] = {
          ...(p.parameters["fr"][context] ?? {}),
        };
        p.parameters["fr"][context][
          context === "advisor" ? "folder" : "campaign"
        ] = {
          ...(p.parameters["fr"][context][
            context === "advisor" ? "folder" : "campaign"
          ]
            ? {
                ...p.parameters["fr"][context][
                  context === "advisor" ? "folder" : "campaign"
                ],
                email_content: value,
              }
            : { email_content: value }),
        };
      } else {
        p.parameters["fr"][context][
          context === "advisor" ? "folder" : "campaign"
        ].email_content = value;
      }
      editParams(p, "LABELS_PARAMS");
    }, 300);

    return () => {
      clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const sub = watch((values: any) => {
      setValue(values.emailContent);
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t("admin.params.archived.mail")}
      </Typography>

      <Typography>
        {"{{firstName}}"} : {t("admin.advisor.display_firstname")}{" "}
        {t(`admin.advisor.${context === "advisor" ? "advisor" : "recruiter"}`)},{" "}
        {"{{lastName}}"} : {t("admin.advisor.display_lastname")}{" "}
        {t(`admin.advisor.${context === "advisor" ? "advisor" : "recruiter"}`)},{" "}
        {"{{company}}"} : {t("admin.advisor.display_company")}
      </Typography>

      <ControlledQuill
        name="emailContent"
        control={control}
        style={QuillCampaignStyle}
      />
    </div>
  );
};

export default AdminEmail;
