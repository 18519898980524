import { Box, styled, Typography } from "@mui/material";
import React from "react";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { useTranslation } from "react-i18next";

const Text = styled(Typography)(({ theme }: any) => ({
  maxWidth: "650px",
  color: theme.palette.secondary.light,
  textAlign: "center",
}));

const IncAdaptationHeader: React.FC<any> = ({ work }) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
        <Box display="flex" alignItems="center">
          <PublishedWithChangesIcon
            sx={{ color: "#4C6FFF", fontSize: "60px", mr: "10px" }}
          />
          <Typography
            variant="h3"
            sx={{
              lineHeight: "30px",
              color: "#4C6FFF",
              textAlign: "left",
              textTransform: "uppercase",
            }}
          >
            {t("inc.adaptation.title_1")}
            <br />
            {t("inc.adaptation.title_2")}
          </Typography>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="center">
        <Text>
          {t(work ? "inc.result.dyn_adapt_work" : "inc.result.dyn_adapt")}
        </Text>
      </Box>
    </>
  );
};

export default IncAdaptationHeader;
