import {
  Box,
  // Chip,
  Paper,
  IconButton,
  styled,
} from "@mui/material";
import React, { useEffect, useReducer, useState } from "react";
import { skillsReducer } from "./skillsReducer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { useSelector } from "react-redux";
import WorkSkillsModal from "../works/WorkSkillsModal";
import RatingBubbles from "./RatingBubbles";

const Root = styled(Box)({
  "&:hover $addons": {
    visibility: "visible",
  },
});

const Main = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
});

// const ChipDiv = styled("div")({
//   display: "flex",
//   marginRight: "10px",
//   "&:hover": {
//     "& $descriptionBadge": {
//       cursor: "pointer",
//       transform: "scale(1)",
//     },
//   },
//   userSelect: "none",
// });

// const CustomChip = styled(Chip)({
//   marginBottom: "3px",
//   minWidth: "180px",
//   maxWidth: "180px",
//   paddingLeft: "20px",
//   paddingRight: "20px",
//   paddingTop: "20px",
//   borderRadius: "50px",
//   paddingBottom: "20px",
// });

// const DescriptionBadge = styled(Box)(({ theme }: any) => ({
//   transform: "scale(0)",
//   transition: "transform 0.1s linear",
//   position: "relative",
//   top: -5,
//   right: 25,
//   color: theme.palette.primary.main,
//   backgroundColor: theme.palette.common.white,
//   boxShadow: `0px 1px 8px -2px ${theme.palette.grey[500]}`,
//   width: 20,
//   height: 20,
//   justifyContent: "center",
//   padding: "0 auto",
//   textAlign: "center",
//   borderRadius: theme.shape.borderRadiusMd,
// }));

const SkillContext = React.createContext<any>(null);

const RatingItem: React.FC<{
  identifier: any;
  skill: any;
  type?: string;
  canEdit?: boolean;
  compare?: boolean;
  workSkill?: boolean;
  profileSection?: boolean;
  handleSubmitSkill?: any;
}> = ({
  identifier,
  skill,
  type,
  canEdit = false,
  compare,
  workSkill,
  profileSection,
  handleSubmitSkill,
}) => {
  // const chipRef = useRef<any>(null);
  // const [label, setLabel] = useState("");

  const initialState = {
    value: !compare
      ? skill.level?.level || skill.skillLevel || skill.personLevel
      : skill.userSkillLevel,
    deleting: false,
    skill,
    loading: false,
    readOnly: false,
  };

  // useEffect(() => {
  //   if (chipRef.current) {
  //     const span = chipRef.current.querySelector("span");
  //     if (span.getBoundingClientRect().width >= 140) {
  //       setLabel(skill.label || skill.skill.label);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chipRef]);

  const [state, dispatch] = useReducer<typeof skillsReducer>(
    skillsReducer,
    initialState
  );

  useEffect(() => {
    if (compare && skill.userSkillLevel) {
      dispatch({ type: "VALUE", value: skill.userSkillLevel });
    }
  }, [skill, compare]);

  const context = useSelector((state: any) => state.auth.context);

  const [open, setOpen] = useState<boolean>(false);

  // const { palette }: any = useTheme();

  return (
    <>
      <Paper>
        <SkillContext.Provider value={{ state, dispatch }}>
          <Root>
            <Main
              sx={{
                mt: 0.75,
                mb: 0.75,
              }}
            >
              {(context === "collab" || context === "benef") &&
                (compare || workSkill) && (
                  <IconButton
                    sx={{ mr: 0.75, width: "40px", height: "40px" }}
                    disabled={skill.skillLevel <= skill.userSkillLevel}
                    onClick={() => setOpen(true)}
                  >
                    {!skill.userSkillLevel ? (
                      <AddCircleOutlineIcon color="primary" />
                    ) : (
                      skill.skillLevel > skill.userSkillLevel && (
                        <TuneRoundedIcon color="primary" />
                      )
                    )}
                  </IconButton>
                )}
              {/* <ChipDiv>
                <Tooltip placement="bottom" title={label}>
                  <CustomChip
                    ref={chipRef}
                    sx={{
                      backgroundColor: skill.isActive
                        ? palette.green[500]
                        : palette.primary.main,
                    }}
                    label={skill.label || skill.skill?.label}
                    color="primary"
                  />
                </Tooltip>
                <Box sx={{ width: 20, height: 20 }}>
                  {skill.skill?.description && (
                    <Tooltip placement="top" title={skill?.skill?.description}>
                      <DescriptionBadge>
                        <Typography variant="subtitle2">?</Typography>
                      </DescriptionBadge>
                    </Tooltip>
                  )}
                </Box>
              </ChipDiv> */}
              <RatingBubbles
                readOnly={!canEdit}
                identifier={identifier}
                context={SkillContext}
                showLabel
                intendLevel={compare ? skill.skillLevel : 0}
                profileSection={profileSection}
              />
            </Main>
          </Root>
        </SkillContext.Provider>
      </Paper>
      <WorkSkillsModal
        open={open}
        close={() => setOpen(false)}
        skillsList={[skill]}
        type={type}
        handleSubmit={handleSubmitSkill}
      />
    </>
  );
};

export default RatingItem;
