import { Typography, Stack, styled } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IncSoftskillsType } from "../../../@types/incSoftskillsType";
import IncMotivationHeader from "../headers/IncMotivationHeader";
import IncCompareProgress from "./IncCompareProgress";
import IncProgress from "./IncProgress";

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary[400],
}));

const Text = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const IncMotivationsCompare: React.FC<{
  results: IncSoftskillsType[];
  score: number;
}> = ({ results, score }) => {
  const { t } = useTranslation("common");
  const [traits, setTraits] = useState<IncSoftskillsType[]>([]);

  const returnKey = (trait: any) => {
    let v: number = 0;
    const min = trait?.min;
    const max = trait?.max;
    min < -3 ? (v = 1) : min <= 3 ? (v = 2) : (v = 4);
    max > -3 && min < -3 && (v += 2);
    min < -3 && max < 1 && (v -= 2);
    min >= 1 && max <= 3 && (v += 2);
    min === 3 && max >= 3 && (v += 2);
    min < 3 && max > 3 && (v += 4);
    return v;
  };

  useEffect(() => {
    const newResults: IncSoftskillsType[] = [];
    results?.map((el: IncSoftskillsType) =>
      newResults.push({ ...el, key: returnKey(el) })
    );
    const sortBy = [1, 7, 3, 2, 6, 4];
    setTraits(
      newResults.sort((a, b) => sortBy.indexOf(b?.key) - sortBy.indexOf(a?.key))
    );
  }, [results]);

  return (
    <div>
      <Text sx={{ mt: 3 }} variant="body1" textAlign="center">
        {t("inc.motivation_intro")}
      </Text>

      <Box sx={{ mt: 8 }}>
        <IncMotivationHeader />
      </Box>

      <IncProgress value={score} />

      <Box
        display="flex"
        justifyContent="center"
        marginTop={2}
        marginBottom={5}
      >
        <Box display="flex" alignItems="center" sx={{ mr: 2 }}>
          <Box
            sx={{
              mr: 1,
              width: "34px",
              height: "15px",
              background: "linear-gradient(90deg, #B4FDA4 0%, #7ADEA6 100%)",
              borderRadius: "9px",
            }}
          />
          <Subtitle>{t("inc.softskills.your_dynamic")}</Subtitle>
        </Box>

        <Box display="flex" alignItems="center">
          <Box
            sx={{
              mr: 1,
              width: "20px",
              height: "20px",
              backgroundColor: "#333539",
              borderRadius: "100px",
            }}
          />
          <Subtitle>{t("inc.softskills.expected_value")}</Subtitle>
        </Box>
      </Box>

      <Stack spacing={4}>
        {traits.map((el: any, i: number) => (
          <IncCompareProgress key={el.texts?.title || i} trait={el} />
        ))}
      </Stack>
    </div>
  );
};

export default IncMotivationsCompare;
