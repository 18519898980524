import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Image = styled("img")({
  width: "250px",
});

const Icon = styled("img")(({ theme }: any) => ({
  background: "#FDF7FF",
  borderRadius: "4px",
  marginRight: "10px",
  padding: theme.spacing(1),
  width: "40px",
}));

const Title = styled(Typography)(({ theme }: any) => ({
  background: "#FDF7FF",
  borderRadius: "4px",
  padding: theme.spacing(0.5),
  paddingLeft: theme.spacing(0.8),
  paddingRight: theme.spacing(0.8),
}));
const IncHeader = () => {
  const { t } = useTranslation("common");
  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Image
          src="/static/illustrations/Personality_disorder-pana.svg"
          alt=""
        />
      </Box>
      <Box
        marginTop={3}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon alt="dynamics icon" src="/static/icons/inc_logo.svg" />
        <Title variant="h4">{t("tests.inc")}</Title>
      </Box>
    </Box>
  );
};

export default IncHeader;
