import { Card, CardContent, Typography, Skeleton, styled } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const WorkItem = styled(Card)(({ theme }: any) => ({
  transition: "all 0.25s ease-in-out",
  "&:hover": {
    transform: "scale(1.015)",
    boxShadow: theme.customShadows.z20,
  },
}));

const RoundScore = styled(Skeleton)(({ theme }: any) => ({
  position: "relative",
  color: "white",
  textAlign: "center",
  paddingTop: "1rem",
  marginLeft: theme.spacing(2),
  minWidth: "62px",
}));

const WorkSuggestItemSkeleton: React.FC<{ index: number; benef?: boolean }> = ({
  index,
  benef = false,
}) => {
  return (
    <WorkItem sx={{ mb: 2, mt: index === 0 ? 2 : 0 }}>
      <CardContent>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box
            display="flex"
            alignItems="center"
            sx={{ width: { sm: "115px", md: "15%", lg: "12%" } }}
          >
            <Typography variant="h4">
              <Skeleton width="22px" />
            </Typography>
            <RoundScore variant="circular" width={62} height={62} />
          </Box>
          <Box
            sx={{ width: { xs: "100%", sm: "52.5%", md: "60%", lg: "55%" } }}
          >
            <Box>
              {benef ? (
                <Box
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  sx={{ gap: 1 }}
                >
                  <Skeleton width={35} height={35} variant="circular" />
                  <Typography variant="h5">
                    <Skeleton width="150px" />
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Skeleton width="392px" />
                  </Box>
                  <Typography variant="h5">
                    <Skeleton
                      variant="rectangular"
                      width="150px"
                      sx={{ mt: "8px" }}
                    />
                  </Typography>
                </>
              )}
              <Box display="flex" sx={{ mt: 2, flexWrap: "wrap", gap: 2 }}>
                {index < 3 &&
                  Array.from(Array(benef ? 3 : 4).keys()).map((el: number) => (
                    <Box key={el}>
                      <Skeleton
                        variant="rectangular"
                        width={benef ? "150px" : "130px"}
                        sx={{ borderRadius: "5px" }}
                        height="10px"
                      />
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ mt: 1, mb: 1 }}
                      >
                        <Skeleton
                          variant="rectangular"
                          width="30px"
                          height="30px"
                          sx={{
                            borderRadius: "4px",
                          }}
                        />
                        <Typography sx={{ ml: 1 }} variant="body2">
                          <Skeleton width={benef ? "110px" : "90px"} />
                        </Typography>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "25%", md: "25%", lg: "30%" },
              mt: { xs: 2, sm: 0 },
            }}
            display="flex"
            justifyContent="right"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                alignItems: "center",
                width: { xs: "100%", sm: "150px", md: "100%" },
              }}
            >
              {!benef && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", sm: "column", lg: "row" },
                    alignItems: "center",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    sx={{ position: "relative", width: "80px" }}
                  >
                    <Skeleton variant="circular" height="40px" width="40px" />
                    <Typography variant="body2">
                      <Skeleton width="35px" sx={{ margin: "auto" }} />
                      <Skeleton width="45px" sx={{ margin: "auto" }} />
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </WorkItem>
  );
};

export default WorkSuggestItemSkeleton;
