import Message from "./Message";
import { Box, Button, Divider, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  requestClearNotificationByConversation,
  requestGetPreviousMessages,
} from "../../redux/actions/chatActions";
import MessageSkeleton from "../skeletons/MessageSkeleton";
import AvatarsOfPickedAutocompleteMembers from "./AvatarsOfPickedAutocompleteMembers";
import AvatarGroupFolderComponent from "../folder/AvatarGroupFolderComponent";
import { useTranslation } from "react-i18next";
import StringAvatar from "../common/StringAvatar";
import chatService from "../../services/chat.service";

const Body = styled(Box)(({ theme }: any) => ({
  backgroundColor: "#F9FAFC",
  minHeight: "60vh",
  maxHeight: "60vh",
  overflowY: "scroll",
  paddingTop: theme.spacing(1),
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F9FAFC",
  },
}));

const AvatarGroupCards = styled(Box)({
  display: "flex",
  padding: "10px",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
  alignItems: "baseline",
});

const AvatarGroupContainer = styled(Box)({
  position: "relative",
  display: "none",
  flexDirection: "row-reverse",
  marginRight: "8px",
  "@media (min-width:600px)": {
    display: "none",
  },
  "@media (min-width:750px)": {
    display: "flex",
  },
});

const LightTypography: any = styled(Typography)(({ theme }: any) => ({
  position: "absolute",
  width: "18px",
  height: "18px",
  minWidth: "18px",
  minHeight: "18px",
  border: "solid 3px #fff",
  borderRadius: theme.shape.borderRadius48,
  left: "25px",
  top: "25px",
  alignmentBaseline: "baseline",
  zIndex: 10,
}));

const PanelBody: React.FC<{
  convDetails: any;
  chatBodyRef: any;
  setModalDisplay: React.Dispatch<React.SetStateAction<any>>;
  setMsgData: React.Dispatch<React.SetStateAction<any>>;
  ACInputValue: any;
  setBottomAncor: React.Dispatch<React.SetStateAction<any>>;
  flyings: any;
  creatingConversation: any;
  loaderState: boolean;
  setWaitingPreviousMessages: React.Dispatch<React.SetStateAction<any>>;
  setTopAncor: React.Dispatch<React.SetStateAction<any>>;
  emojiPickerDisplay: any;
  setEmojiPickerDisplay: React.Dispatch<React.SetStateAction<any>>;
}> = ({
  convDetails,
  chatBodyRef,
  setModalDisplay,
  setMsgData,
  ACInputValue,
  setBottomAncor,
  flyings,
  creatingConversation,
  loaderState,
  setWaitingPreviousMessages,
  setTopAncor,
  emojiPickerDisplay,
  setEmojiPickerDisplay,
}) => {
  const [clearDelay, setClearDelay] = useState<any>(Date.now());
  const dispatch = useDispatch();
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const { t } = useTranslation("common");

  const parseMembersToAvatarGroupFormat = () => {
    var list: any[] = [];
    // eslint-disable-next-line array-callback-return
    convDetails?.members?.map((member: any, i: any) => {
      if (member?.id !== tokenData.person_id) {
        list.push({ id: member?.id, person: member });
      }
    });
    return list;
  };

  const clearNotifications = () => {
    setClearDelay(Date.now());
    if (convDetails?.notification) {
      dispatch(requestClearNotificationByConversation(convDetails));
    }
  };

  const showAvatarsMembers = () => {
    if (convDetails?.members?.length > 3) {
      return (
        <AvatarGroupContainer>
          <AvatarGroupFolderComponent
            color="primary"
            size={40}
            list={parseMembersToAvatarGroupFormat()}
          />
        </AvatarGroupContainer>
      );
    } else {
      return convDetails?.members?.map((member: any) => {
        return (
          <Box
            key={member?.id + "bAvatar"}
            onClick={() => {
              window.open(`/app/profile/${member?.id}/overview`);
              // window.open(`/app/profile/${member?.id}/overview`, "_blank");
              // setTimeout(() => window.focus(), 5000);
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "5px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <StringAvatar
                size={40}
                name={member?.fullName}
                url={member?.image?.contentUrl || member?.image}
              />
              <LightTypography
                component="span"
                sx={{
                  backgroundColor: chatService.getColorByStatus(
                    member?.status ?? member?.connectionStatus
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {member?.fullName}
              </Typography>
              {/* <Typography>{"some complemntary info :p"}</Typography> */}
            </Box>
          </Box>
        );
      });
    }
  };

  const setMessagesNodes = () => {
    var authorMsgStreak = 0;
    return convDetails?.messages?.map((e: any, i: any) => {
      let curPerson = tokenData.person_id;
      let msg_author_id = e?.author?.id ?? e?.authorId ?? e?.author?.["@id"];
      let owned =
        curPerson === msg_author_id || msg_author_id.includes(curPerson);
      let prev_msg = i > 0 && convDetails?.messages[i - 1];
      let authorIsTheSame =
        (prev_msg?.author?.id ??
          prev_msg?.authorId ??
          prev_msg?.author?.["@id"]) === msg_author_id;
      if (authorMsgStreak > 3) authorIsTheSame = false;

      authorIsTheSame ? (authorMsgStreak += 1) : (authorMsgStreak = 0);

      //upd message.author.connectionStatus by conversation.members.x.connectionStatus
      let theUpToDateOne = convDetails.members.find(
        (uptodate: any) => uptodate?.id === msg_author_id
      );
      if (e.author !== undefined)
        e.author.connectionStatus = theUpToDateOne?.connectionStatus;

      return (
        <Message
          key={e.id}
          data={e}
          style={{ own: owned, sameAuthorAsPrevious: authorIsTheSame }}
          openDeleteModal={setModalDisplay}
          setDataToModal={setMsgData}
        />
      );
    });
  };

  return (
    <Body
      ref={chatBodyRef}
      onScroll={(e: any) => {
        return Date.now() - clearDelay / 1000 > 1 && clearNotifications();
      }}
      onClick={(e: any) => {
        emojiPickerDisplay && setEmojiPickerDisplay(false);
      }}
    >
      <Box
        sx={{ float: "left", clear: "both" }}
        ref={(el) => {
          setTopAncor(el);
        }}
      ></Box>
      {convDetails?.messages?.length === 0 ||
      convDetails?.messages === undefined ||
      convDetails?.messages === null ||
      convDetails?.messages?.length === convDetails?.nbrMessages ||
      creatingConversation ? (
        <></>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setWaitingPreviousMessages(true);
              dispatch(requestGetPreviousMessages(convDetails));
            }}
          >
            {t("chat.load_previous_msg")}
          </Button>
        </Box>
      )}
      {!creatingConversation ? (
        loaderState ? (
          Array.from(Array(5).keys()).map((el: number) => (
            <MessageSkeleton key={el} own={el % 2 === 0} />
          ))
        ) : (
          <>
            <AvatarGroupCards>{showAvatarsMembers()}</AvatarGroupCards>
            <Divider />
            {setMessagesNodes()}

            {flyings?.map((e: any, i: any) => {
              return (
                <Message
                  key={i + "flyings"}
                  data={e}
                  style={{ own: true, flying: true }}
                />
              );
            })}
          </>
        )
      ) : (
        <>
          <AvatarGroupCards>
            <AvatarsOfPickedAutocompleteMembers ACInputValue={ACInputValue} />
          </AvatarGroupCards>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "20%",
            }}
          >
            <Typography variant="body1" sx={{ width: "20em" }}>
              {t("chat.write_msg_to_start_conv")}
            </Typography>
          </Box>
        </>
      )}
      <Box
        sx={{ float: "left", clear: "both" }}
        ref={(el) => {
          setBottomAncor(el);
        }}
      ></Box>
    </Body>
  );
};

export default PanelBody;
