import {
  Box,
  Button,
  Container,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import React, { useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BenefWorkSuggest from "../components/benef/BenefWorkSuggest";
import Page from "../components/Page";
import useFetch from "../hooks/useFetch";
import peopleAPI from "../_api/_people";
import StringAvatar from "../components/common/StringAvatar";

const Root = styled(Container)(({ theme }: any) => ({
  background: theme.palette.collabBlue,
  borderRadius: theme.shape.borderRadiusPage,
}));

const CustomButton = styled(Button)(({ theme }: any) => ({
  borderRadius: theme.shape.borderRadiusPage,
  height: "55px",
}));

const BoxIcon = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "50px",
  height: "50px",
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.primary[500],
}));

const TextLight = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const Profile = styled(Box)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

const PersonWorkSuggest = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const personInfos = useFetch(peopleAPI.getById(id!));
  const personName = `${personInfos?.data?.firstName} ${personInfos?.data?.lastName}`;
  const navigate = useNavigate();
  const [mounted, setMounted] = useState(false);

  const redirectBenef = (id: string) => {
    navigate(`/app/profile/${id}/overview`);
  };

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <Page padding title={t("work.plural_title")}>
      <Root sx={{ mt: 7, mb: 9, pt: 6, pb: 6 }}>
        <Box sx={{ mt: -2 }}>
          <CustomButton
            sx={{ fontSize: "12px", mb: { xs: 2, sm: 0 } }}
            variant="outlined"
            color="primary"
            onClick={() =>
              navigate(`/app/profile/${id}/overview`, { replace: true })
            }
          >
            <ArrowBackRoundedIcon sx={{ mr: 1 }} />
            <Typography variant="body2">Retourner sur le profil</Typography>
          </CustomButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", mt: { xs: 2, sm: -2 }, mb: 2 }}
        >
          <BoxIcon>
            {mounted && (
              <Player
                loop
                autoplay
                src="/static/illustrations/lotties/work.json"
                style={{ height: "35px", width: "35px" }}
              />
            )}
          </BoxIcon>
          <TextLight sx={{ ml: 1 }} variant="h4">
            {t("work.plural_title")}
          </TextLight>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          {personInfos.loading ? (
            <Typography variant="h5" alignItems="center" height={40}>
              <Skeleton variant="text" width={310} />
            </Typography>
          ) : (
            <>
              <TextLight variant="h5" alignItems="center">
                Matching avec &nbsp;{" "}
              </TextLight>
              <Profile onClick={() => redirectBenef(personInfos?.data?.id)}>
                <StringAvatar
                  size={40}
                  name={personName}
                  url={personInfos?.data?.image?.contentUrl}
                />
                <Typography color="primary" variant="h5" fontWeight="regular">
                  &nbsp;
                  {personName}
                </Typography>
              </Profile>
            </>
          )}
        </Box>
        {/* <Typography
          variant="body1"
          sx={{ textAlign: "center", mt: 3, mb: 2, opacity: "0.7" }}
        >
          {t("advisor.contact_us")}
        </Typography> */}
        <BenefWorkSuggest benefId={id!} />
      </Root>
    </Page>
  );
};

export default PersonWorkSuggest;
