import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Text = styled(Typography)({
  maxWidth: "650px",
  color: "#333539",
  opacity: "0.8",
  textAlign: "center",
});

const IncIrritantsHeader: React.FC<any> = ({ work }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
        <Box display="flex" alignItems="center">
          <img
            src="/static/icons/Icons-irittants/icon_title.svg"
            alt="smile group"
          />
          <Typography
            variant="h3"
            sx={{ lineHeight: "30px", color: "#404040", ml: 2 }}
          >
            {work ? (
              <>
                {t("inc.irritants.work_1")}
                <br />
                {t("inc.irritants.work_2")}
              </>
            ) : (
              <>
                {t("inc.irritants.person_1")}
                <br />
                {t("inc.irritants.person_2")}
              </>
            )}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Text variant="body1" textAlign="center" sx={{ mb: 4 }}>
          {t(!work ? "inc.result.irrits" : "inc.irritants_intro")}
        </Text>
      </Box>
    </>
  );
};

export default IncIrritantsHeader;
