import {
  Box,
  Button,
  Typography,
  Modal,
  Card,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { modalStyle } from "../../../_utils/modalStyle";
import IncIrritantsListHeader from "./IncIrritantsListHeader";
import IncIrritantsListItem from "./IncIrritantsListItem";
import { HighlightOff } from "@mui/icons-material";

const ExpandBtn = styled(Button)(({ theme }: any) => ({
  borderRadius: theme.shape.borderRadiusPage,
  backgroundColor: "#404040",
  color: "white",
  width: "210px",
  height: "45px",
  "&:hover": {
    backgroundColor: "#404040",
  },
}));

const IncIrritantsMoreBtn = ({
  text,
  titleText,
  light,
  neutral,
  important,
  color,
  work,
}: any) => {
  const [selectedColor, setSelectedColor] = useState("");
  const [open, setOpen] = useState(false);

  return (
    <>
      <ExpandBtn
        sx={{ mb: 4, mt: 2 }}
        onClick={() => {
          setOpen(true);
          setSelectedColor(color);
        }}
      >
        <Typography variant="body2">{text}</Typography>
      </ExpandBtn>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedColor("");
        }}
      >
        <Card
          sx={{
            ...modalStyle,
            p: 2,
            overflowY: "auto",
          }}
        >
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpen(false);
                setSelectedColor("");
              }}
              sx={{ ml: "auto" }}
            >
              <HighlightOff />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center" sx={{ mb: 3 }}>
            <IncIrritantsListHeader
              text={titleText}
              color={color}
              work={work}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            flexWrap="wrap"
          >
            {(selectedColor === "green"
              ? light
              : selectedColor === "orange"
              ? neutral
              : important
            )?.map((el: any, i: number) => (
              <IncIrritantsListItem
                el={el}
                key={i}
                color={work ? el.color : color}
                bgColor={selectedColor}
              />
            ))}
          </Box>
        </Card>
      </Modal>
    </>
  );
};

export default IncIrritantsMoreBtn;
