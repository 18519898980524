import { Button, IconButton, styled, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const CarouselItem: any = styled("div", {
  shouldForwardProp: (props) => props !== "previous" && props !== "current",
})(({ previous, current }: any) => ({
  marginBottom: "20px",
  transition: "all 0.5s linear",
  position: "absolute",
  top: previous ? "10%" : "35%",
  opacity: current ? "1" : previous ? "0.7" : "0",
  visibility: "visible",
  width: "100%",
  transform: current ? "none" : previous ? "scale(0.8)" : "scale(0)",
  zIndex: previous ? 2 : "auto",
  "@media screen and (max-width: 600px)": {
    top: previous ? "-25%" : "10%",
    display: previous ? "none" : "inherit",
  },
}));

const TestItem = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media screen and (max-width: 600px)": {
    display: "flex",
    flexDirection: "column",
  },
});

const CarouselButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "selected",
})(({ theme, selected }: any) => ({
  flex: 1,
  maxWidth: "500px",
  [theme.breakpoints.down("sm")]: {
    minWidth: "100px",
    maxWidth: "100px",
    fontSize: ".75rem",
    lineHeight: ".75rem",
    height: "40px",
  },
  [theme.breakpoints.up("sm")]: {
    minWidth: "200px",
    fontSize: "1rem",
    lineHeight: "1rem",
    height: "50px",
  },

  "@media screen and (max-width: 600px)": {
    height: "auto",
    width: "auto",
    maxWidth: "450px",
    lineHeight: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },

  borderRadius: "25px",
  cursor: "pointer",
  "&:focus": {
    outline: "none",
  },
  backgroundImage: selected ? theme.palette.gradients.b5_linear : "auto",
}));

const OrSpacer = styled(Typography)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: { margin: "0 5px" },
  [theme.breakpoints.up("sm")]: { margin: "0 30px" },
  "@media screen and (max-width: 600px)": {
    fontSize: "12px",
  },
}));

const Big5TestItem: React.FC<{
  value: any;
  previousValue: any;
  last: boolean;
  currentIndex: number;
  index: number;
  setStepClick: any;
  setAnswer: any;
}> = ({
  value,
  previousValue,
  last,
  currentIndex,
  index,
  setStepClick,
  setAnswer,
}) => {
  const { t } = useTranslation("common");

  const setStep = (index: number) => {
    value.value !== null && !last && setStepClick(index);
  };

  const handleSelection = (selection: any) => {
    if (
      index === currentIndex ||
      (index === currentIndex + 1 && previousValue.value !== null)
    ) {
      setAnswer({ selection: selection, index: index });
    }
  };

  return (
    <CarouselItem
      current={index === currentIndex}
      previous={index === currentIndex - 1}
      onClick={() => setStep(index)}
    >
      {value ? (
        <TestItem data-active={currentIndex === index}>
          {index === currentIndex && (
            <>
              {value.description?.[0] && (
                <Tooltip title={value.description[0]} enterTouchDelay={0}>
                  <IconButton aria-label="big5 item description">
                    <HelpOutlineOutlinedIcon
                      color="primary"
                      sx={{ fontSize: "17px" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
          <CarouselButton
            id={`btn-left-${index}`}
            className="carousel-btn"
            variant={value.value === value.labels[0] ? "contained" : "outlined"}
            selected={value.value === value.labels[0]}
            onClick={() => handleSelection(value.labels[0])}
          >
            {value.answers[0]}
          </CarouselButton>
          <OrSpacer variant="body1">{t("or")}</OrSpacer>
          <CarouselButton
            className="carousel-btn"
            id={`btn-right-${index}`}
            variant={value.value === value.labels[1] ? "contained" : "outlined"}
            selected={value.value === value.labels[1]}
            onClick={() => handleSelection(value.labels[1])}
          >
            {value.answers[1]}
          </CarouselButton>
          {index === currentIndex && (
            <>
              {value.description?.[1] && (
                <Tooltip title={value.description[1]} enterTouchDelay={0}>
                  <IconButton aria-label="big5 item description 2">
                    <HelpOutlineOutlinedIcon
                      color="primary"
                      sx={{ fontSize: "17px" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </TestItem>
      ) : (
        <p>&nbsp;</p>
      )}
    </CarouselItem>
  );
};

export default Big5TestItem;
