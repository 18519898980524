import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Big5CompareBarLegend = () => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={2}
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
        flexWrap="wrap"
        width="500px"
      >
        <Box display="flex" alignItems="center" marginTop={1}>
          <Box
            sx={{
              width: "50px",
              height: "20px",
              background: "#EFB651",
              borderRadius: "10px",
              mr: 1,
            }}
          />
          <Typography sx={{ whiteSpace: "nowrap" }}>
            {t("big5.work.mastered", { context })}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" marginTop={1}>
          <Box
            sx={{
              width: "50px",
              height: "20px",
              background: "#333539",
              borderRadius: "10px",
              mr: 1,
            }}
          />
          <Typography sx={{ whiteSpace: "nowrap" }}>
            {t("big5.work.expected")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Big5CompareBarLegend;
