import React, { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import referentialAPI from "../../../_api/_referentialAPI";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField, CircularProgress, Box } from "@mui/material";

const LocationReferentialAutocomplete: React.FC<{
  sendDataToParent: (value: any) => void;
  formError?: boolean;
  formHelper?: string;
  parentLoading?: boolean;
  baseValue?: any;
  style?: any;
}> = ({
  sendDataToParent,
  formError,
  formHelper,
  parentLoading,
  baseValue,
  style,
}) => {
  const { t } = useTranslation("common");
  const [value, setValue] = useState<any>(baseValue ?? { label: "" });
  const [init, setInit] = useState(true);
  const [needle, setNeedle] = useState("");
  const [helperText, setHelperText] = useState("");
  const [options, setOptions] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>("");

  const { data, loading, error } = useFetch(
    referentialAPI.get({ type: "AREA", label: needle }),
    true
  );

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    sendDataToParent(newValue);
  };

  useEffect(() => {
    if (init) {
      setInit(false);
      return;
    }
    const timeout = setTimeout(() => {
      if (inputValue?.length >= 3) {
        search();
      } else {
        // setOptions([]);
        if (inputValue?.length < 3 && inputValue.length > 1) {
          setHelperText("3 caractères min");
        }
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const search = () => {
    setNeedle(inputValue);
    setHelperText("");
  };

  useEffect(() => {
    if (data && data["hydra:member"]) {
      data["hydra:member"]?.length > 0 && setOptions(data["hydra:member"]);
      if (data["hydra:member"].length === 0 && inputValue?.length >= 3) {
        setHelperText("Aucun résultat");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Autocomplete
        {...{ loading, value, options }}
        id="autocompleteComponent"
        onChange={handleChange}
        freeSolo
        disableClearable={inputValue?.length === 0}
        getOptionLabel={(option: any) => option.label || ""}
        loadingText={t("autocomplete.loading")}
        disabled={parentLoading}
        renderOption={(props, option) => (
          <Box {...props} component="li" key={option?.id}>
            <span>{option?.label}</span>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={"Recherchez une région, un département"}
            value={inputValue}
            error={error || formError}
            onChange={(text) => {
              setInputValue(text.target.value);
            }}
            helperText={helperText || formHelper}
            disabled={parentLoading}
            sx={{ ...style }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default LocationReferentialAutocomplete;
