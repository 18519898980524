import { Utils } from "../../Utils";

export const SET_PREFERENCES = "SET_PREFERENCES";

export const setPreferences = (payload: any) => {
  return {
    type: SET_PREFERENCES,
    payload,
  };
};

export const setTableItemsPerPagePreferences = (payload?: any) => {
  return (dispatch: any, getState: any) => {
    const { user, preferences } = getState();
    const userId = user.user_id;
    const cookieItemsPerPage = Utils.getCookie(`${userId}_itemsPerPage`);

    const itemsPerPage = payload
      ? payload
      : cookieItemsPerPage
      ? Number(cookieItemsPerPage)
      : 10;

    if (userId) {
      Utils.setLongCookies(`${userId}_itemsPerPage`, itemsPerPage);
    }

    dispatch(
      setPreferences({
        ...preferences,
        itemsPerPage,
      })
    );
  };
};
