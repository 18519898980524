import {
  Breadcrumbs,
  Card,
  CardContent,
  Pagination,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useFetch from "../../hooks/useFetch";
import WorkSuggestItem from "../work_suggest/WorkSuggestItem";
import WorkSuggestItemSkeleton from "../work_suggest/WorkSuggestItemSkeleton";
import { useSelector, shallowEqual } from "react-redux";
import { Utils } from "../../Utils";
import axios from "axios";
import WorkListFilters from "../works/WorkListFilters";
import { useLocation } from "react-router-dom";

const tryParse = (val: any, fallback?: any) => {
  try {
    return JSON.parse(val);
  } catch (err) {
    return fallback ?? val;
  }
};

const BenefWorkSuggest: React.FC<{ benefId: string }> = ({ benefId }) => {
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const navigate = useNavigate();
  const { id } = useParams();
  const [totalWorks, setTotalWorks] = useState(0);
  const [worksList, setWorksList] = useState([]);
  const [passedTests, setPassedTests] = useState<any>([]);
  const location: any = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [stateParamsSkill, setStateParamsSkill] = useState([]);
  const [httpParams, setHttpParams] = useState<any>(
    location?.state?.workSuggestHttpParams || {
      page: 1,
      personId: id,
      parent: "",
      depth: 2,
      isCategory: false,
      breadcrumb: [{ label: "Catégories" }],
      mood: tryParse(
        Utils.getCookie(`${tokenData.person_id}_work_suggest_mood`),
        [0, 1, 2, 3]
      ),
      testsFilters:
        Utils.getCookie(`${tokenData.person_id}_benef_work_suggest_filters`) ||
        null,
    }
  );

  const matchers = useFetch(`tests?matchers=true&personId=${id}`);

  const handleWorkSelect = (e: any) => {
    if (e?.type === "category") {
      const newParams = { ...httpParams };
      newParams.parent = e.id;
      newParams.depth = ++httpParams.depth;
      newParams.breadcrumb = [...httpParams?.breadcrumb, e];
      setHttpParams(newParams);
      setNavigationState(newParams);
    } else {
      navigate(`/app/work/${e.id}/${id}`, {
        state: { from: "works" },
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    setData(null);
    const source = axios?.CancelToken?.source();
    const api = `/works/list?${`page=${httpParams.page}`}${
      httpParams.needle ? `&needle=${httpParams.needle}` : ""
    }&filters=${
      Array.isArray(httpParams?.testsFilters)
        ? JSON.stringify(httpParams?.testsFilters)
        : httpParams?.testsFilters
    }&personId=${httpParams?.personId}${
      httpParams.skills?.length > 0 ? `&skills=${httpParams.skills}` : ""
    }${httpParams.studyLevel ? `&${httpParams.studyLevel}` : ""}${
      httpParams.tension ? `&tension=${httpParams.tension}` : ""
    }${httpParams.video ? `&video=${httpParams.video}` : ""}${
      httpParams?.parent
        ? `&category=/api/work_categories/${httpParams?.parent}`
        : httpParams?.category
        ? `&category=${httpParams.category}`
        : ""
    }${
      httpParams?.mood && passedTests?.includes("mood")
        ? `&mood=${JSON.stringify(httpParams.mood)}`
        : ""
    }${httpParams.tagsFilter ? `${httpParams.tagsFilter}` : ""}`;
    const suggestApi = `/works/suggest?personId=${
      httpParams?.personId
    }&filters=${
      Array.isArray(httpParams?.testsFilters)
        ? JSON.stringify(httpParams?.testsFilters)
        : httpParams?.testsFilters
    }${httpParams.skills?.length > 0 ? `&skills=${httpParams.skills}` : ""}${
      httpParams?.parent ? `&parent=${httpParams?.parent}` : ""
    }`;
    axios
      .get(httpParams.depth < 2 ? suggestApi : api)
      .then((res: any) => {
        if (res) {
          setData(res?.data?.["hydra:member"]?.[0]?.categories || res?.data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });

    return () => {
      source?.cancel("Cancelled");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpParams, passedTests]);

  useEffect(() => {
    const matches = matchers?.data?.["hydra:member"];
    if (matches) {
      const passed = matches
        .filter((e: any) => e.isPassed)
        .map((e: any) => e.code);
      if (passedTests.length === 0) {
        setPassedTests([...passed, "skills", "transversalSkills"]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchers]);

  const handlePage = (val: any) => {
    if (val) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      const newParams = { ...httpParams };
      newParams.page = val;
      setHttpParams(newParams);
      setNavigationState(newParams);
    }
  };

  const handleBreadcrumb = (index: number, event: any) => {
    const newParams = { ...httpParams };
    newParams.parent = event.id;
    newParams.depth = index;
    newParams.breadcrumb =
      index === 0
        ? [httpParams?.breadcrumb[0]]
        : [httpParams?.breadcrumb[0], httpParams?.breadcrumb[1]];
    setHttpParams(newParams);
    setNavigationState(newParams);
  };

  const setNavigationState = (params: any, skills?: any) => {
    navigate(`/app/profile/${id}/work-suggest`, {
      state: {
        workSuggestHttpParams: params,
        skills: skills ?? stateParamsSkill,
      },
    });
  };

  useEffect(() => {
    location?.state?.skills && setStateParamsSkill(location?.state?.skills);
  }, [location?.state]);

  useEffect(() => {
    data && setWorksList(data?.["hydra:member"] ?? data);
    data && setTotalWorks(data?.["hydra:totalItems"] ?? data?.length);
  }, [data]);

  return (
    <Box>
      <Card>
        <CardContent>
          <WorkListFilters
            httpParams={httpParams}
            setHttpParams={setHttpParams}
            setNavigationState={setNavigationState}
            loading={loading}
            stateParamsSkill={stateParamsSkill}
            benefId={benefId}
            stateParams={location?.state?.workSuggestHttpParams}
            totalItems={totalWorks}
            isCategory={httpParams.depth < 2}
            workSuggest
          />
        </CardContent>
      </Card>
      {httpParams?.breadcrumb?.length > 1 && (
        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 1.5 }}>
          {httpParams?.breadcrumb?.map((item: any, index: number) =>
            index < 2 ? (
              <Link
                key={index}
                sx={{ cursor: "pointer" }}
                underline="hover"
                color="inherit"
                onClick={() => handleBreadcrumb(index, item)}
              >
                {item?.label}
              </Link>
            ) : (
              <Typography key={index} color="text.primary">
                {item?.label}
              </Typography>
            )
          )}
        </Breadcrumbs>
      )}
      <Stack>
        {data && !loading && (
          <>
            {worksList?.map((work: any, index: number) => (
              <WorkSuggestItem
                key={work.id}
                work={work}
                index={index}
                page={httpParams.page}
                onNextCategory={handleWorkSelect}
                noBookmark
              />
            ))}
          </>
        )}
        {loading && (
          <>
            {Array.from(Array(10).keys()).map((el: number) => (
              <WorkSuggestItemSkeleton key={el} index={el} />
            ))}
          </>
        )}
        {!loading && worksList?.length === 0 && (
          <Typography sx={{ mt: 4 }} textAlign="center" variant="h4">
            Aucune suggestion.
          </Typography>
        )}
        {/* {!loading && error && (
          <Typography sx={{ mt: 3 }} textAlign="center">
            Une erreur est survenue.
          </Typography>
        )} */}
      </Stack>

      {!httpParams?.isCategory && (
        <Box marginTop={2} display="flex" justifyContent="center">
          {data && !loading && Math.ceil(totalWorks) > 10 && (
            <Pagination
              count={Math.ceil(totalWorks / 10)}
              page={httpParams.page}
              onChange={(event, value) => handlePage(value)}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default BenefWorkSuggest;
