import { Player } from "@lottiefiles/react-lottie-player";
import { alpha, Box, styled } from "@mui/material";
import React, { useState, useEffect } from "react";

const LoaderBox: any = styled(Box, {
  shouldForwardProp: (props) => props !== "noBackground",
})(({ theme, noBackground }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  backgroundColor: noBackground
    ? "transparent"
    : alpha(theme.palette.grey[300], 0.3),
  borderRadius: theme.shape.borderRadiusSm,
}));

export default function Loader({ noBackground }: boolean | any) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);
  return (
    <LoaderBox noBackground={noBackground}>
      {mounted && (
        <Player
          autoplay
          loop
          src={`/static/illustrations/lotties/general_loading.json`}
          style={{ height: "300px", width: "70%" }}
        ></Player>
      )}
    </LoaderBox>
  );
}
