import { BehaviorSubject, tap } from "rxjs";
import http from "./http/http";

const baseAdvancedFilters = {
  location: "",
  project: false,
  rank: "",
  skill: [],
  works: [],
  service: [],
  inProgress: false,
  followed: false,
};
class successionService {
  static filters = [];
  static _filters = new BehaviorSubject<any>(this.filters);
  static results = [];
  static _results = new BehaviorSubject<any>(this.results);
  static advancedFilters = { ...baseAdvancedFilters };
  static _advancedFilters = new BehaviorSubject<any>(this.advancedFilters);

  static setAdvancedFilters(newFilters: any) {
    this.advancedFilters = { ...this.advancedFilters, ...newFilters };
  }

  static setFilters(newFilters: any) {
    this.filters = newFilters;
    this._filters.next(this.filters);
  }

  static get(id: string) {
    return http
      .get(`people/${id}/suggest-collab`, {
        params: {
          filters: JSON.stringify(this.filters),
          advancedFilters: JSON.stringify(this.advancedFilters),
        },
      })
      .pipe(
        tap({
          next: (res) => {
            this.results = res.data["hydra:member"];
            this._results.next(this.results);
          },
          error: () => {},
        })
      );
  }
}

export default successionService;
