import { Button, styled } from "@mui/material";
import React from "react";

const CustomButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "secondary",
})(({ theme, secondary }: any) => ({
  height: "42px",
  width: "130px",
  borderRadius: theme.shape.borderRadiusXs,
  fontWeight: "normal",
  fontSize: "12px",
  margin: "10px",
  color: secondary ? theme.palette.secondary[400] : "auto",
}));

const CustomButtonModal: React.FC<{
  text: string;
  variant: string;
  type?: string;
  color?: string;
  onClick?: () => void;
  secondary?: boolean;
  disabled?: boolean;
}> = ({ text, variant, type, color, onClick, secondary, disabled }) => {
  return (
    <CustomButton
      secondary={secondary}
      onClick={onClick}
      size="medium"
      type={type ?? "button"}
      variant={variant}
      color={color}
      disabled={disabled}
    >
      {text}
    </CustomButton>
  );
};

export default CustomButtonModal;
