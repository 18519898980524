import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, TextField, Typography } from "@mui/material";
import http from "../../../services/http/http";
import StringAvatar from "../../common/StringAvatar";
import { useTranslation } from "react-i18next";

const RecruiterTransferInput: React.FC<any> = ({ getValue, recruiterId }) => {
  const [value, setValue] = useState<any | null>(null);
  const [terms, setTerms] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);

  const { t } = useTranslation("common");

  useEffect(() => {
    getValue(value);
  }, [value, getValue]);

  useEffect(() => {
    setValue(null);
    const to = setTimeout(() => {
      setTerms(searchValue);
    }, 500);
    return () => {
      clearTimeout(to);
    };
  }, [searchValue]);

  useEffect(() => {
    let sub: any;
    if (terms) {
      sub = http.get(`recruiters?term=${terms}`).subscribe({
        next: (res) => {
          setOptions(
            res.data["hydra:member"].filter((el: any) => el.id !== recruiterId)
          );
        },
        error: (err) => {
          setOptions([]);
        },
      });
    } else {
      setOptions([]);
    }

    return () => {
      sub?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terms]);

  return (
    <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={options.map((option) => option)}
      getOptionLabel={(option) => option.fullName}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      renderOption={(props, option) => (
        <Box {...props} component="li" key={option.id}>
          <StringAvatar
            size={35}
            name={option.fullName}
            url={option?.person?.image?.contentUrl}
          />
          <Typography sx={{ ml: 1 }}>{option.fullName}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          label={t("btn.search")}
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
    />
  );
};

export default RecruiterTransferInput;
