import React from "react";
import { useTranslation } from "react-i18next";
import { Chip, Divider, styled } from "@mui/material";
import { Box } from "@mui/system";
import LongText from "../LongText";
import ShowAllButton from "../buttons/ShowAllButton";
import InfoSkillHeader from "./InfoSkillHeader";

const BoxTitle = styled(Box)(({ theme }: any) => ({
  height: "35px",
  borderRadius: theme.shape.borderRadiusXs,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const MainInfoSkills = ({
  skills,
  transversalSkills,
  notCollab,
  changeTab,
}: any) => {
  const { t } = useTranslation("common");
  return (
    <>
      {skills.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", mt: 5 }}>
          <BoxTitle>
            <InfoSkillHeader type={"technical"} />
          </BoxTitle>
          <ShowAllButton
            title={t("actions.see_skills")}
            onClick={() => changeTab(notCollab ? 4 : 3)}
          />
        </Box>
      )}
      <Box
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {skills
          .filter((el: any, i: number) => i < 10)
          ?.map((skill: any) => (
            <Chip
              sx={{ m: 0.75 }}
              key={skill.id}
              label={<LongText text={skill.label} maxChar={25} />}
              color="primary"
            />
          ))}
      </Box>
      {transversalSkills.length > 0 && skills.length > 0 && (
        <Divider sx={{ mt: 3 }} />
      )}
      {transversalSkills.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", mt: 5 }}>
          <BoxTitle>
            <InfoSkillHeader type={"transversal"} />
          </BoxTitle>
          <ShowAllButton
            title={t("actions.see_abilities")}
            onClick={() => changeTab(notCollab ? 5 : 4)}
          />
        </Box>
      )}
      <Box
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {transversalSkills
          .filter((el: any, i: number) => i < 10)
          ?.map((skill: any) => (
            <Chip
              sx={{ m: 0.75 }}
              key={skill.id}
              label={<LongText text={skill.label} maxChar={25} />}
              color="primary"
            />
          ))}
      </Box>
    </>
  );
};

export default MainInfoSkills;
