import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayRH: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <FormGroup>
        <Typography variant="h4">{t("admin.params.rh.display")}</Typography>
        <FormControlLabel
          value={param.SUCCESSION_PLAN}
          onChange={(e: any) =>
            handleChanges({ ...param, SUCCESSION_PLAN: e.target.checked })
          }
          control={<Checkbox checked={param.SUCCESSION_PLAN} />}
          label={t("admin.params.rh.succession")}
        />
        <FormControlLabel
          value={param.SUGGEST_COLLAB}
          onChange={(e: any) =>
            handleChanges({ ...param, SUGGEST_COLLAB: e.target.checked })
          }
          control={<Checkbox checked={param.SUGGEST_COLLAB} />}
          label={t("admin.params.rh.suggest")}
        />
      </FormGroup>
      <Typography sx={{ mt: 1 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
    </div>
  );
};

export default AdminDisplayRH;
