import React, { useState, useEffect } from "react";
import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";
import referentialAPI from "../../../_api/_referentialAPI";
import FilterPopoverContainer from "../../common/chip_filter/FilterPopoverContainer";
import BottomFilterContent from "../../common/chip_filter/BottomFilterContent";

const WorkStudyLevelFilter = ({ preSelected, onChange }: any) => {
  const { t } = useTranslation("common");
  const theme: any = useTheme();
  const [value, setValue] = useState<any>(preSelected ?? []);
  const [values, setValues] = useState<any>(null);
  const [list, setList] = useState<any>([]);
  const [close, setClose] = useState<any>(false);

  const { data } = useFetch(referentialAPI.get({ type: "studyLevel" }));

  const returnTitleChip = () => {
    const first = `${value?.[0]}`;
    const second = `${value?.[1]}`;
    return value?.length > 2
      ? `${first}, ${second} +${value?.length - 2}`
      : value?.length > 1
      ? `${first}, ${second}`
      : value?.length > 0
      ? `${first}`
      : t("experience_section.form.study_level");
  };

  const handleChange = (newValue: any) => {
    const newArr = [...value];
    if (newValue.length > 0) {
      const index = newArr.findIndex((el) => el === newValue);
      index > -1 ? newArr.splice(index, 1) : newArr.push(newValue);
    }
    if (newArr?.length > 0) {
      const studyLevel: any = list.filter((el: any) =>
        newArr.includes(el.label)
      );
      let newFilters: string = "";
      const queryName = "studyLevel[]";
      studyLevel?.forEach((level: any, i: any) => {
        newFilters = `${newFilters}${i > 0 ? "&" : ""}${queryName}=${
          level.code
        }`;
      });
      setValues({ arr: newArr, formattedData: newFilters });
    } else {
      setValues({ arr: [], formattedData: "" });
    }
    setValue(newArr);
  };

  useEffect(() => {
    data?.["hydra:member"] &&
      setList(
        data?.["hydra:member"]?.sort((a: any, b: any) => a?.code - b?.code)
      );
  }, [data]);

  const handleSubmit = () => {
    onChange(values);
    setClose(!close);
  };

  const handleClear = () => {
    setValues({ arr: [], formattedData: [] });
    onChange({ arr: [], formattedData: [] });
    setValue([]);
    setClose(!close);
  };

  useEffect(() => {
    if (preSelected?.length > 0) {
      const studyLevel: any = list.filter((el: any) =>
        preSelected.includes(el.label)
      );
      let newFilters: string = "";
      const queryName = "studyLevel[]";
      studyLevel?.forEach((level: any, i: any) => {
        newFilters = `${newFilters}${i > 0 ? "&" : ""}${queryName}=${
          level.code
        }`;
      });
      setValues({ arr: preSelected, formattedData: newFilters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelected]);

  return (
    <>
      <FilterPopoverContainer
        title={returnTitleChip()}
        idPop="work_study_popover"
        close={close}
        active={value?.length > 0}
        content={
          <>
            {list?.map((item: any) => (
              <MenuItem
                sx={{
                  backgroundColor:
                    value.indexOf(item.label) > -1 &&
                    theme.palette.primary[100],
                }}
                onClick={() => handleChange(item?.label)}
                key={item.id}
                value={item.label}
              >
                <Checkbox checked={value.indexOf(item.label) > -1} />
                <ListItemText primary={t(item.label)} />
              </MenuItem>
            ))}
            <BottomFilterContent
              handleSubmit={handleSubmit}
              handleClear={handleClear}
              disabled={value?.length === 0}
            />
          </>
        }
      />
    </>
  );
};

export default WorkStudyLevelFilter;
