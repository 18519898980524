import { Button, Tooltip, Typography } from "@mui/material";
import React from "react";

import { useTranslation } from "react-i18next";

const NoShareButton: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <Tooltip title={t("third_party.profiles.no_accept_share")}>
        <span>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#cfd8dc",
              color: "#424242",
              pointerEvents: "none",
              opacity: 0.5,
              boxShadow: "none",
            }}
            sx={{ width: "100%", height: "50px" }}
          >
            <Typography sx={{ ml: 1 }}>
              {t("third_party.profiles.share")}
            </Typography>
          </Button>
        </span>
      </Tooltip>
    </>
  );
};

export default NoShareButton;
