import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../components/Page";
import { Box, Button, Container, styled, Typography } from "@mui/material";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
// import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
// import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import WorkTrainingPageInfos from "../components/work_training/WorkTrainingPageInfos";
import WorkTrainingPageSkeleton from "../components/skeletons/collab/WorkTrainingPageSkeleton";
import { workTraining } from "../@types/workTrainingType";
import WorkTrainingPageContent from "../components/work_training/WorkTrainingPageContent";
import axios from "axios";
import worksAPI from "../_api/_worksAPI";
import { WorkType } from "../@types/workType";

const Banner = styled(Box)(({ theme }: any) => ({
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: theme.shape.borderRadiusSm,
  minHeight: "180px",
  width: "100%",
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const IconCtn = styled(Box)(({ theme }: any) => ({
  height: "50px",
  width: "50px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.22)",
}));

// const ShareCtn = styled(Box)(() => ({
//   height: "32px",
//   width: "32px",
//   borderRadius: "50%",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   backgroundColor: "rgba(46, 58, 89, 0.1)",
//   cursor: "pointer",
//   transition: "all 0.15s ease-in-out",
//   "&:hover": {
//     transform: "scale(1.1)",
//   },
// }));

const WorkTrainingPage = () => {
  const dlUrl: any = process.env.REACT_APP_WORK_TRAINING_URL;
  const { t } = useTranslation("common");
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<workTraining>();
  const [work, setWork] = useState<WorkType>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetch(`${dlUrl}/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/ld+json",
            Accept: "application/ld+json",
            authorization:
              "9B0917DAFEAAE1932DC22BE55F23D631E0344CE47E761D88598703300097131A",
          },
        });
        try {
          const trainingData = await res.json();
          if (trainingData?.domaineFormation?.rome?.[0]?.value) {
            const workId = await axios.get(
              worksAPI.get({
                code: `rome4_${trainingData?.domaineFormation?.rome?.[0]?.value}`,
              })
            );
            const workData: any = await axios.get(
              worksAPI.getById(workId?.data["hydra:member"]?.[0]?.id)
            );
            setWork(workData.data);
          }
          setData(trainingData);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    id && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Page title={t("trainings.page")}>
      <Container sx={{ pt: 10, pb: 5 }}>
        {loading ? (
          <WorkTrainingPageSkeleton />
        ) : (
          <Container>
            <Banner
              sx={{
                backgroundImage: `url(${
                  work?.workCoverPicture ??
                  "/static/images/image-header-trainings.jpg"
                })`,
                p: 1,
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconCtn>
                  <SchoolRoundedIcon sx={{ fontSize: "28px" }} />
                </IconCtn>
                <Typography
                  variant="h4"
                  textAlign="center"
                  sx={{ mt: 0.5, maxWidth: "40ch", lineHeight: "1.2" }}
                >
                  {data?.intituleFormation?.value}
                </Typography>
                {work ? (
                  <Typography
                    variant="body1"
                    textAlign="center"
                    sx={{ maxWidth: "80ch" }}
                    dangerouslySetInnerHTML={{ __html: work.description }}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    textAlign="center"
                    sx={{ maxWidth: "80ch" }}
                  >
                    {`Par ${data?.organismeFormationResponsable?.nomOrganisme?.value}`}
                  </Typography>
                )}
              </Box>
            </Banner>
            {work && (
              <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                <Button
                  sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}
                  variant="contained"
                  onClick={() => navigate(`/app/work/${work?.id}`)}
                >
                  <Typography variant="body2">
                    {t("work_sheet.see_work_sheet")}
                  </Typography>
                </Button>
              </Box>
            )}
            {/* <Box
              display="flex"
              alignItems="center"
              justifyContent="end"
              sx={{ mt: 1 }}
            >
              <ShareCtn sx={{ m: 0.5 }}>
                <FavoriteBorderRoundedIcon sx={{ fontSize: "20px" }} />
              </ShareCtn>
              <ShareCtn sx={{ m: 0.5 }}>
                <ShareOutlinedIcon sx={{ fontSize: "20px" }} />
              </ShareCtn>
              <Typography
                variant="body2"
                textAlign="center"
                sx={{ fontSize: "10px", maxWidth: "20ch" }}
              >
                {t("trainings.title_cat.share")}
              </Typography>
            </Box> */}
            <WorkTrainingPageContent data={data} />
            <WorkTrainingPageInfos data={data} />
          </Container>
        )}
      </Container>
    </Page>
  );
};

export default WorkTrainingPage;
