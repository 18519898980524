import React, { Suspense, lazy } from "react";
import { useRoutes } from "react-router";
import LogoLoader from "../components/LogoLoader";
import NoMenuLayout from "../layout/NoMenuLayout";

import ProfileContextProvider from "./../contexts/ProfileContext";
import IncTestContextProvider from "../components/inc/test/IncTestContext";
import { Navigate } from "react-router-dom";

const IncGamePage = lazy(() => import("../pages/IncGamePage"));

const NewOnboardingPage = lazy(() => import("../pages/NewOnboardingPage"));

const OnboardingRoutes = () => {
  return useRoutes([
    {
      path: "",
      element: <NoMenuLayout />,
      children: [
        {
          path: "onboarding",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <IncTestContextProvider>
                  <NewOnboardingPage />
                </IncTestContextProvider>
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "game",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <IncGamePage />
            </Suspense>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/onboarding" replace /> },
  ]);
};

export default OnboardingRoutes;
