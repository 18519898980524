import {
  Box,
  Button,
  Card,
  Modal,
  Typography,
  IconButton,
  CardContent,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { peopleType } from "../../../@types/people";
import { modalStyle } from "../../../_utils/modalStyle";
import StringAvatar from "../../common/StringAvatar";
import { HighlightOff } from "@mui/icons-material";
import ItemTestsForm from "../../common/ItemTestsForm";
import { useSelector } from "react-redux";

const RemindTestModal: React.FC<{
  open: boolean;
  close: () => void;
  handleAskTest: () => void;
  person: peopleType;
  remindType: string;
  type: string;
}> = ({ open, close, handleAskTest, person, remindType, type }) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  return (
    <Modal open={open} onClose={close}>
      <Card sx={{ ...modalStyle }}>
        <Box sx={{ width: "100%", display: "flex" }}>
          <IconButton
            onClick={close}
            sx={{ ml: "auto", mt: 1, mr: 1 }}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent>
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            sx={{ mb: 2 }}
          >
            <ItemTestsForm
              key={
                type === "CultureFit"
                  ? "valors"
                  : type === "Big5"
                  ? "personality"
                  : type === "Inc"
                  ? "inc"
                  : type === "mood"
                  ? "mood"
                  : ""
              }
              code={
                type === "CultureFit"
                  ? "valors"
                  : type === "Big5"
                  ? "personality"
                  : type === "Inc"
                  ? "inc"
                  : type === "mood"
                  ? "mood"
                  : ""
              }
              index={0}
              checked
            />
          </Box>
          <Box display="flex" width="100%" justifyContent="center">
            <Box display="flex" align-items="center" sx={{ flexWrap: "wrap" }}>
              <Typography variant="h4" sx={{ mt: 0.5, mr: 1, lineHeight: 1.2 }}>
                {t(`tests.remind.askNew_title`)}
              </Typography>
              <StringAvatar
                size={35}
                url={person?.image?.contentUrl}
                name={person?.firstName + " " + person?.lastName}
              />
              <Typography
                variant="h4"
                sx={{ mt: 0.5, ml: 1, lineHeight: 1.2 }}
                color="primary"
              >
                {person?.firstName} {person?.lastName}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="body2"
            textAlign="center"
            sx={{ opacity: 0.7, mt: 0.5 }}
          >
            {t(`tests.remind.${remindType}_subtitle_${context}`)}
          </Typography>
          <Box display="flex" justifyContent="center">
            <Typography
              variant="h5"
              textAlign="center"
              fontWeight="normal"
              sx={{ maxWidth: "21ch", mt: 4 }}
            >
              {t(`tests.remind.${remindType}_text`)}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            sx={{ mt: 3, flexWrap: "wrap", mb: 2 }}
          >
            <Button
              sx={{ height: "37px", width: "140px", borderRadius: "4px", m: 1 }}
              color="primary"
              onClick={handleAskTest}
              variant="contained"
              autoFocus
            >
              <Typography variant="body2">
                {t("tests.remind.confirm")}
              </Typography>
            </Button>
            <Button
              sx={{ height: "37px", width: "110px", borderRadius: "4px", m: 1 }}
              onClick={close}
              color="secondary"
              variant="outlined"
            >
              <Typography variant="body2">{t("btn.cancel")}</Typography>
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default RemindTestModal;
