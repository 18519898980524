import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckInputValid from "../common/CheckValidInput";

const ControlledEmailInput: React.FC<{
  control: any;
  name: string;
  required?: boolean;
  showCheck?: boolean;
  style?: any;
  disabled?: boolean;
  spaced?: boolean;
}> = ({
  control,
  name,
  required = false,
  showCheck,
  style,
  disabled = false,
  spaced = false,
}) => {
  const { t }: any = useTranslation("common");

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: "errors.required" },
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "errors.email",
        },
      }}
      render={({ field, formState: { errors } }) => (
        <TextField
          sx={style}
          helperText={
            errors[name] ? t(errors?.[name]?.message) : spaced ? " " : ""
          }
          error={Boolean(errors[name])}
          label={t(`form.mail${required ? "_required" : ""}`)}
          {...field}
          InputProps={{
            endAdornment: showCheck &&
              field?.value &&
              !Boolean(errors[name]) && <CheckInputValid />,
          }}
          disabled={disabled}
        />
      )}
    />
  );
};

export default ControlledEmailInput;
