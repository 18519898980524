import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LoadExpSplashScreen from "../common/LoadExpSplashScreen";

const StatusSplashscreen: React.FC<any> = ({
  type,
}: {
  type: "logout" | "welcome" | "onboarding" | "general" | "sign-up";
}) => {
  const [mounted, setMounted] = useState(true);
  const { t } = useTranslation("common");

  const firstName = useSelector((state: any) => state.user?.firstName);
  const logo = useSelector((state: any) => state.home?.logo?.contentUrl);

  useEffect(() => {
    return () => setMounted(false);
  }, []);

  return (
    <div>
      <Box
        textAlign="center"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <img
          width="250"
          src={logo ?? "/static/logo/MT-Logo-Horizontal-Colors.svg"}
          alt="logo entreprise"
        />
        {mounted && (
          <Player
            autoplay
            loop
            src={`/static/illustrations/lotties/${type}_loading.json`}
            style={{ height: "300px", width: "70%", marginTop: "-60px" }}
          ></Player>
        )}
        {type === "welcome" && (
          <>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              <Typography variant="h3">
                {t("splash_screen.welcome_1")}
              </Typography>
              <Typography variant="h3" color="primary">
                &nbsp;{t("splash_screen.welcome_2")}
              </Typography>
            </Box>
            <LoadExpSplashScreen />
          </>
        )}

        {type === "logout" && (
          <>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              <Typography variant="h3">{t("splash_screen.loggout")}</Typography>
            </Box>
          </>
        )}

        {type === "sign-up" && (
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Typography variant="h3" color="primary">
              {t("splash_screen.hello_1", {
                name: firstName,
              })}
            </Typography>
            <Typography variant="h3">
              &nbsp;{t("splash_screen.hello_2")}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default StatusSplashscreen;
