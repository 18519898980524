import React from "react";
import useFetch from "../../../hooks/useFetch";
import StatsAPI from "../../../_api/_statsAPI";
// import FeedbacksList from "../../admin_params/FeedbacksList";
import AdminRecruiterCredits from "../recruiter/AdminRecruiterCredits";
import HomeStatsAdvisor from "../../_advisor/HomeStatsAdvisor";

const AdminAdvisorHome: React.FC = () => {
  // const [httpParams, setHttpParams] = useState<any>({});

  const { data, loading } = useFetch(StatsAPI.getById("data2"));

  return (
    <>
      <AdminRecruiterCredits />
      <br />

      <HomeStatsAdvisor row data={data?.data} loading={loading} />

      {/* <StatsAdmin
        data={data?.data}
        loading={loading}
        httpParams={httpParams}
        setHttpParams={setHttpParams}
      /> */}

      {/* <FeedbacksList /> */}
    </>
  );
};

export default AdminAdvisorHome;
