import { useTheme } from "@mui/material/styles";
import React from "react";

const TrainingIcon: React.FC<{
  active?: boolean;
  context?: string;
  size?: number;
}> = ({ active, context, size }) => {
  const theme: any = useTheme();
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      width={size ?? "20"}
      height={size ?? "20"}
      data-testid="SchoolOutlinedIcon"
    >
      <path
        fill={
          active && context === "recruiter"
            ? "#4C6FFF"
            : !active &&
              (context === "collab" ||
                context === "benef" ||
                context === "candidate")
            ? theme.palette.contextColors.collab.font
            : context === "recruiter"
            ? theme.palette.contextColors.recruiter.font
            : "#fff"
        }
        d="M12 3 1 9l4 2.18v6L12 21l7-3.82v-6l2-1.09V17h2V9L12 3zm6.82 6L12 12.72 5.18 9 12 5.28 18.82 9zM17 15.99l-5 2.73-5-2.73v-3.72L12 15l5-2.73v3.72z"
      ></path>
    </svg>
  );
};

export default TrainingIcon;
