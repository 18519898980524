import { FormControl, FormHelperText } from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import isMobilePhone from "validator/lib/isMobilePhone";

let navigatorLanguage = "fr";

try {
  const c = navigator.language.split("-");
  if (c.length > 1) navigatorLanguage = c[1].toLocaleLowerCase();
  else navigatorLanguage = navigator.language.toLowerCase();
} catch (err) {
  navigatorLanguage = "fr";
}

const ControlledPhoneInput: React.FC<{
  control: any;
  name: string;
  required?: boolean;
  inputStyle?: any;
  searchStyle?: any;
}> = ({
  control,
  required = false,
  name,
  inputStyle = {},
  searchStyle = {},
}) => {
  const { t }: any = useTranslation("common");
  const [countryCode, setCountryCode] = useState(navigatorLanguage);

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          control={control}
          name={name}
          rules={{
            required: {
              value: required,
              message: "errors.required",
            },
            validate: {
              phone: (value) =>
                countryCode === "fr"
                  ? isMobilePhone(value ?? "") || (value ?? "").length === 0
                    ? true
                    : "errors.phone"
                  : true,
            },
          }}
          render={({ field, formState: { errors } }) => (
            <div>
              <PhoneInput
                {...field}
                country={navigatorLanguage}
                specialLabel={t(`form.phone${required ? "_required" : ""}`)}
                placeholder="+33 6 07 08 09 10"
                searchStyle={{ width: "100% !important", ...searchStyle }}
                isValid={!Boolean(errors.phone)}
                onChange={(e, c: any) => {
                  console.log(isMobilePhone(e));
                  field.onChange(e.length > 0 ? `+${e}` : "");
                  setCountryCode(c?.countryCode ?? "fr");
                }}
                inputStyle={{
                  height: "53px",
                  ...inputStyle,
                }}
              />
              <FormHelperText error>
                {errors?.phone?.message ? t(errors?.phone?.message) : " "}
              </FormHelperText>
            </div>
          )}
        />
      </FormControl>
    </div>
  );
};

export default ControlledPhoneInput;
