import { Box, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { IncTestContext } from "./IncTestContext";
import IncQuestionCursorComponent from "./IncQuestionCursorComponent";
import useWindowDimensions from "../../../hooks/getWindowDimensions";
import { useTranslation } from "react-i18next";

const IncQuestionCursor: React.FC<any> = ({
  questions,
  handleDoLater,
  onBoarding,
}) => {
  const { t } = useTranslation("common");
  const [sliders, setSliders] = useState<any>([]);
  const { setResponses, handlePreviousTest, handleNextTest } =
    useContext(IncTestContext);

  useEffect(() => {
    setCurrentIndex(0);
    if (questions) {
      setSliders(
        questions.map((question: any) => ({
          textLeft: question.details.cursor_text_left,
          textRight: question.details.cursor_text_right,
          value: question.details.cursor_start_value,
          code: question.code,
        }))
      );
    }
  }, [questions]);

  useEffect(() => {
    const values = sliders.map((el: any) => ({
      code: el.code,
      result: [{ code: "", value: el.value }],
    }));

    setResponses(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliders]);

  const handleSliderChange = (value: any, index: number) => {
    const arr = [...sliders];
    arr[index].value = value;
    setSliders(arr);
  };

  const { width } = useWindowDimensions();
  const isMobile = width <= 1400;

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex === 5) {
      return handleNextTest();
    }
    setCurrentIndex(currentIndex + 1);
  };

  const handlePrev = () => {
    if (currentIndex <= 0) {
      return handlePreviousTest();
    }
    setCurrentIndex(currentIndex - 1);
  };

  return (
    <Box>
      {isMobile
        ? sliders[currentIndex] && (
            <IncQuestionCursorComponent
              isMobile={isMobile}
              handleSliderChange={handleSliderChange}
              slider={sliders[currentIndex]}
              index={currentIndex}
            />
          )
        : sliders.map((slider: any, i: number) => (
            <IncQuestionCursorComponent
              isMobile={isMobile}
              handleSliderChange={handleSliderChange}
              key={i}
              slider={slider}
              index={i}
            />
          ))}

      {isMobile && (
        <Box>
          <Box
            width="100%"
            display="flex"
            flexWrap="wrap"
            justifyContent="space-evenly"
          >
            {questions?.[0]?.previousGroup &&
              (onBoarding
                ? questions?.[0]?.previousGroup?.split("_")[1] !== "01"
                : true) && (
                <Button
                  sx={{
                    width: "280px",
                    height: "60px",
                    fontSize: "14px",
                    mr: 2,
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={handlePrev}
                >
                  {t("btn.prev")}
                </Button>
              )}
            <Button
              sx={{ width: "280px", height: "60px", fontSize: "14px" }}
              variant="contained"
              onClick={handleNext}
            >
              {t("btn.continue")}
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" sx={{ m: 1 }}>
            <Button
              sx={{
                width: "240px",
                height: "53px",
                fontSize: "14px",
                color: "#8F9BB3",
              }}
              onClick={handleDoLater}
            >
              {t("tests.modal.do_later")}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default IncQuestionCursor;
