import { SET_HOME } from "../actions/homeActions";
import initialState from "../initialState";

const homeReducer = (state = { ...initialState.home }, action: any) => {
  switch (action.type) {
    case SET_HOME:
      return { ...action.payload };
    default:
      return { ...state };
  }
};

export default homeReducer;
