import {
  Box,
  Typography,
  Button,
  Container,
  FormGroup,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import ControlledTextField from "../../../forms/ControlledTextField";

interface IFormInput {
  label: string;
  children: any;
}

const AdminCreateWorkCatForm: React.FC<{
  form: any;
  close: () => void;
  submit: (e: any) => void;
  deleteSkillCat: (e: any) => void;
  skillWork: any;
  loading?: boolean;
}> = ({ submit, loading, close, skillWork, deleteSkillCat, form }) => {
  const { t } = useTranslation("common");
  const [data, setData] = useState("");
  const [list, setList] = useState<any>(skillWork?.children ?? []);

  const { handleSubmit, control, setValue } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: form,
  });

  const handleAdd = () => {
    setList([...list, data]);
    setValue("children", [...list, data]);
    setData("");
  };

  const removeFromList = (i: number) => {
    const arr = [...list];
    arr.splice(i, 1);
    setList(arr);
    setValue("children", arr);
  };

  const handleKeyDown = (e: any) => {
    e.keyCode === 13 && e.preventDefault();
    e.keyCode === 13 &&
      e.target.value.replace(/\s/g, "").length > 0 &&
      handleAdd();
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Container sx={{ pt: 2, pb: 5 }}>
        <FormGroup>
          <Stack spacing={2}>
            <ControlledTextField
              name="label"
              label={t("admin.params.works_cat.name")}
              control={control}
              required
            />
            <TextField
              id="filled-disabled"
              sx={{ width: "100%" }}
              onKeyDown={handleKeyDown}
              label={t("admin.params.works_cat.under_cat")}
              value={data}
              onChange={(e: any) => setData(e.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={handleAdd}
                    sx={{
                      visibility:
                        data.replace(/\s/g, "").length > 0
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    <Typography>{t("btn.add")}</Typography>
                  </Button>
                ),
              }}
            />
            <Box>
              {list?.map((category: string | any, i: number) => (
                <Box
                  sx={{ display: "flex", alignItems: "center", pt: 1 }}
                  key={(category?.id ?? category) + i}
                >
                  <Typography>{category?.label ?? category}</Typography>

                  <IconButton
                    sx={{ ml: "auto" }}
                    onClick={() => removeFromList(i)}
                  >
                    <Close color="error" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Stack>
          {skillWork && (
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              sx={{ mt: 5, mb: 2 }}
            >
              <Button
                type="button"
                color="error"
                onClick={deleteSkillCat}
                variant="contained"
                sx={{ width: "250px", height: "45px" }}
              >
                <Typography variant="body2">
                  {t("admin.params.works_cat.delete")}
                </Typography>
              </Button>
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="space-evenly"
            width="100%"
            sx={{ mb: -4, mt: skillWork ? 0 : 5 }}
          >
            <Button
              type="button"
              color="secondary"
              onClick={close}
              variant="outlined"
              sx={{ width: "200px", height: "45px", m: 0.5 }}
            >
              <Typography variant="body2">{t("btn.cancel")}</Typography>
            </Button>
            <Button
              type="submit"
              disabled={loading}
              variant="contained"
              sx={{ width: "200px", height: "45px", m: 0.5 }}
            >
              <Typography variant="body2">{t("btn.save")}</Typography>
            </Button>
          </Box>
        </FormGroup>
      </Container>
    </form>
  );
};

export default AdminCreateWorkCatForm;
