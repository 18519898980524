import { Box } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { autocompleteTypes } from "../../../_utils/autocompleteTypes";
import MultipleAutocompleteComponent from "../../common/MultipleAutocompleteComponent";
import FilterPopoverContainer from "../../common/chip_filter/FilterPopoverContainer";
import BottomFilterContent from "../../common/chip_filter/BottomFilterContent";

const WorkSkillFilter = ({
  handleSkills,
  loading,
  stateParamsSkill,
  skillsType,
}: any) => {
  const { t } = useTranslation("common");
  const [close, setClose] = useState<any>(false);
  const [values, setValues] = useState<any>({ items: stateParamsSkill });

  const returnTitleChip = () => {
    const first = `${values?.items?.[0]?.label?.slice(0, 25)}
        ${values?.items?.[0]?.label?.length > 25 ? "..." : ""}`;
    return values?.items?.length > 1
      ? `${first} +${values?.items?.length - 1}`
      : values?.items?.length > 0
      ? `${first}`
      : t("skills.label");
  };

  const handleSubmit = () => {
    handleSkills(values);
    setClose(!close);
  };

  const handleClear = () => {
    handleSkills(null);
    setValues(null);
    setClose(!close);
  };

  return (
    <>
      <FilterPopoverContainer
        title={returnTitleChip()}
        idPop="work_skill_popover"
        close={close}
        active={values?.items?.length > 0}
        content={
          <Box sx={{ minWidth: "325px" }}>
            <Box sx={{ p: 2 }}>
              <MultipleAutocompleteComponent
                placeholder={t("autocomplete.placeholder.skills")}
                type={autocompleteTypes.skills}
                sendDataToParent={setValues}
                parentLoading={loading}
                baseValue={stateParamsSkill}
                skillsType={skillsType}
              />
            </Box>
            <BottomFilterContent
              handleSubmit={handleSubmit}
              handleClear={handleClear}
              disabled={!values?.items}
            />
          </Box>
        }
      />
    </>
  );
};

export default WorkSkillFilter;
