import { Box } from "@mui/material";
import React from "react";
import IncGroupHeader from "./headers/IncGroupHeader";
import IncGroupSquares from "./group/IncGroupSquares";
import useINCGroupFormatter from "../../hooks/useINCGroupFormatter";

const IncGroup: React.FC<any> = ({ result }) => {
  const results = useINCGroupFormatter(result?.dynamic_behaviour?.values);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      <IncGroupHeader />

      {results && <IncGroupSquares result={results} />}

      {/* <IncGroupChart
        result={result?.dynamic_behaviour?.values?.map((el: any) => ({
          ...el,
          texts: { ...el, title: t(`inc.key.${el?.detail}`) },
        }))}
      /> */}
    </Box>
  );
};

export default IncGroup;
