import { Box, styled, Typography } from "@mui/material";
import React from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import getFileIcon from "../../_utils/getFileIcon";
import FileSaver from "file-saver";

const Root = styled("div")`
  width: 180px;
  margin-top: 15px;
  color: #555555;
`;

const Overlay = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Thumbnail = styled("div")`
  height: 256px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
`;

const IconContainer = styled("div")`
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: center;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const RessourceCard: React.FC<{ data: any }> = ({ data }) => {
  const icon = getFileIcon(data.extension);
  const bgColor = getExtensionBackgroundColor(data.extension);
  const fileType = data?.media?.name?.split(".")?.[1];

  const open = () => {
    if (data?.media?.contentUrl)
      window?.open(data?.media?.contentUrl ?? "", "_blank")?.focus();
  };

  const download = () => {
    if (data?.media?.contentUrl) {
      fetch(data?.media?.contentUrl, {
        method: "GET",
        headers: { "Content-Type": "blob" },
      })
        .then((res) => res.blob())
        .then(function (myBlob) {
          const typeName = data.media.name.split(".")[1];
          const blob2 = new Blob([myBlob], {
            type: `application/${typeName}`,
          });

          FileSaver.saveAs(blob2, data.media.name);
        })
        .catch(function (err) {});

      // var link = document.createElement("a");
      // link.download = data.media.name;
      // link.href = data.media.contentUrl;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      // link.remove();
    }
  };

  return (
    <Root>
      <Thumbnail
        style={{
          backgroundImage: `url("${data?.media?.thumbnail?.contentUrl}")`,
          backgroundColor: bgColor,
        }}
      >
        <IconContainer>
          {icon ? (
            <img
              src={icon}
              alt="icon"
              style={{
                height: "35px",
                marginBottom: "-17px",
              }}
            />
          ) : null}
        </IconContainer>

        {!data?.media?.thumbnail?.contentUrl && (
          <Typography sx={{ color: "white", wordBreak: "break-word" }}>
            {data?.media?.name ?? ""}
          </Typography>
        )}

        {/* <Box
          marginLeft="auto"
          zIndex="2"
          sx={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <Chip
            label="Pass To Work"
            variant="filled"
            sx={{
              background: "white",
            }}
          />
        </Box> */}
        <Overlay className="overlay">
          <Box display="flex" width="60%" justifyContent="space-around">
            {(fileType === "pdf" || fileType === "img") && (
              <IconButton
                aria-label="upload picture"
                sx={{
                  background: "white",
                  color: "#555555",
                  "&:hover": { background: "white" },
                }}
                onClick={open}
              >
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            )}

            <IconButton
              aria-label="upload picture"
              sx={{
                background: "white",
                color: "#555555",
                "&:hover": { background: "white" },
              }}
              onClick={download}
            >
              <GetAppRoundedIcon />
            </IconButton>
          </Box>
        </Overlay>
      </Thumbnail>

      <Typography
        variant="subtitle1"
        fontWeight="bold"
        sx={{ marginTop: "15px", wordBreak: "break-word" }}
      >
        {data.title}
      </Typography>
      <Typography>{data.subtitle}</Typography>
    </Root>
  );
};

export default RessourceCard;

const getExtensionBackgroundColor = (extension: string) => {
  console.log(extension);
  switch (extension) {
    case "pdf":
      return "#cb0000";
    case "xls":
      return "#255d0b";
    case "xlsx":
      return "#255d0b";
    case "excel":
      return "#255d0b";
    case "zip":
      return "#cdbc00";
    case "docx":
      return "#0e0ded";
    default:
      return "grey";
  }
};
