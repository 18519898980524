import {
  Card,
  CardContent,
  Box,
  Divider,
  Button,
  Stack,
  styled,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DownloadPdfButton from "../common/DownloadPdfButton";
import IncAdaptation from "./IncAdaptation";
import IncIrritants from "./IncIrritants";
import IncMotivations from "./IncMotivations";
import IncGroup from "./IncGroup";
import IncHeader from "./IncHeader";
import { useSelector } from "react-redux";

const Root = styled(Card)<{ onboarding?: boolean }>(
  ({ onboarding }) =>
    onboarding && {
      boxShadow: "none",
    }
);

const IncResult: React.FC<{
  result: any;
  onboarding?: boolean;
  notCollab?: boolean;
  nextStep?: any;
  person?: any;
}> = ({ result, onboarding, nextStep, notCollab, person }) => {
  const { t }: { t: any } = useTranslation("common");
  const navigate = useNavigate();
  const home = useSelector((state: any) => state.home);
  const { isCollab } = useSelector((state: any) => state.auth);

  const redirectTests = () => {
    navigate("/app/tests");
  };

  return (
    <Root onboarding={onboarding}>
      <CardContent>
        <IncHeader />

        <Stack
          sx={{ mt: 2 }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          width="100%"
          spacing={6}
        >
          {/* MOTIV */}
          {result?.dynamic_motivation && <IncMotivations result={result} />}
          {/* Adaptation */}
          {result?.dynamic_adaptation && <IncAdaptation result={result} />}
          {/* Irritants */}
          {result?.dynamic_nettlesome && <IncIrritants result={result} />}
          {/* Group */}
          {result?.dynamic_behaviour && <IncGroup result={result} />}
        </Stack>
        {onboarding ? (
          <>
            <Divider sx={{ mt: 4, mb: 4 }} />
            <Box textAlign="center">
              <Button
                variant="contained"
                onClick={nextStep}
                sx={{ width: "200px", height: "55px", fontSize: "14px" }}
              >
                {t("btn.continue")}
              </Button>
            </Box>
          </>
        ) : !window.location.pathname.includes("app/tests") && !notCollab ? (
          <>
            <Divider sx={{ mt: 4, mb: 4 }} />
            <Box textAlign="center" display="flex" justifyContent="center">
              <Button
                sx={{ height: "55px", fontSize: "12px", m: 1 }}
                variant="outlined"
                onClick={redirectTests}
              >
                {t("tests.page.return_to_result")}
              </Button>
              {((home?.paramjson?.candidatePdf && isCollab) || !isCollab) && (
                <Box sx={{ m: 1 }}>
                  <DownloadPdfButton
                    height="55px"
                    variant="outlined"
                    type="inc"
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          ((home?.paramjson?.candidatePdf && isCollab) ||
            (!isCollab && !person.anonymous)) && (
            <>
              <Divider sx={{ mt: 4, mb: 4 }} />
              <Box textAlign="center" sx={{ mt: 3 }}>
                <DownloadPdfButton
                  person={person}
                  height="55px"
                  variant="outlined"
                  type="inc"
                />
              </Box>
            </>
          )
        )}
      </CardContent>
    </Root>
  );
};

export default IncResult;
