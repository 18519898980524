import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { EventSourcePolyfill } from "event-source-polyfill";
import chatAPI from "../_api/_chatAPI";
import axios from "axios";
import {
  setConversations,
  setNotifications,
  addMercureSource,
  clearMercureSources,
} from "../redux/actions/chatActions";

const useConversations = (): any => {
  const dispatch = useDispatch();

  const message: any = useSelector((state: any) => state.chat.message);
  const context = useSelector((state: any) => state.auth.context);
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const convs: any = useSelector((state: any) => state.chat.conversations);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const parameters = useSelector((state: any) => state.parameters);

  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    let chatIsActive =
      parameters?.find((el: any) => el.type === "DISPLAY_PARAMS")?.parameters
        ?.CHAT ?? false;

    typeof chatIsActive == "boolean" && setActive(chatIsActive);
  }, [parameters]);

  useEffect(() => {
    if (message) {
      const convsCopy = [...convs];
      const index = convs.findIndex((el: any) => el.id === message.convId);

      if (convsCopy[index]) {
        convsCopy[index].receivedMessages = convsCopy[index].receivedMessages
          ? convsCopy[index].receivedMessages + 1
          : 1;
        dispatch(setConversations(convsCopy));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const requestGetConversationList = async () => {
    if (tokenData?.person_id) {
      try {
        const conversations =
          (await axios.get(chatAPI.getById(`conversations`)))?.data?.[
            "hydra:member"
          ] || [];

        conversations
          .sort((a: any, b: any) =>
            new Date(a?.lastMessage?.createdAt).getTime() >
            new Date(b?.lastMessage?.createdAt).getTime()
              ? -1
              : 1
          )
          // eslint-disable-next-line array-callback-return
          .map((e: any) => {
            e?.messages?.sort((a: any, b: any) =>
              new Date(a?.createdAt).getTime() <
              new Date(b?.createdAt).getTime()
                ? -1
                : 1
            );
            e.lastMessage = e?.messages[e?.messages?.length - 1];
          });

        const notifications =
          (await axios.get(chatAPI.getById(`notifications`)))?.data?.[
            "hydra:member"
          ] || [];

        notifications.map((e: any) => {
          e.convId = e?.conversation.slice(-36);
          let conversation = conversations.find((c: any) => c?.id === e.convId);
          conversation.notification = e;
          return e;
        });

        setEventSourceConversationsInit(
          tokenData?.person_id,
          tokenData?.mercure
        );

        dispatch(setNotifications(notifications));
        dispatch(setConversations(conversations));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const setEventSourceConversationsInit = (
    personId: any,
    mercureToken: any
  ) => {
    if (personId && mercureToken) {
      const url = `${process.env.REACT_APP_MERCURE_URL}?topic=/api/chat/conversations/${personId}`;

      dispatch(addMercureSource(tokenData?.mercure, EventSourcePolyfill, url));
    }
  };

  useEffect(() => {
    if (context && active && isLogged) {
      requestGetConversationList();
    }

    return () => {
      dispatch(clearMercureSources());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, active, isLogged]);
};
export default useConversations;
