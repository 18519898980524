import { Stack, Box, Typography, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../../../contexts/ProfileContext";
import LanguagesAutocomplete from "../../LanguagesAutocomplete";
import { HighlightOff } from "@mui/icons-material";

const LanguagesSelect: React.FC<any> = ({ onChange }) => {
  const { languages } = useContext(ProfileContext);
  const [list, setList] = useState<any>(
    languages.map((el: any) => el.language)
  );

  const addElement = (el: any) => {
    if (!el) return;
    if (list.findIndex((e: any) => e.id === el.id) === -1)
      setList([...list, el]);
  };

  const removeElement = (id: number) => {
    const l = [...list];
    const index = l.findIndex((el: any) => el.id === id);
    l.splice(index, 1);
    setList(l);
  };

  useEffect(() => {
    onChange(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <div>
      <LanguagesAutocomplete
        ignoreLanguages={[...list]}
        addElement={addElement}
      />
      <Stack spacing={2} sx={{ mt: 2 }}>
        {list.map((el: any) => (
          <Box
            display="flex"
            alignItems="center"
            sx={{ width: "150px" }}
            key={el?.id}
          >
            <Typography variant="body1">{el?.label}</Typography>
            <IconButton
              aria-label="remove language"
              sx={{ ml: "auto" }}
              onClick={() => removeElement(el?.id)}
            >
              <HighlightOff color="error" />
            </IconButton>
          </Box>
        ))}
      </Stack>
    </div>
  );
};

export default LanguagesSelect;
