import {
  Avatar,
  Container,
  Stack,
  Typography,
  useTheme,
  styled,
  Button,
} from "@mui/material";
import React, { useContext } from "react";
import Page from "../../components/Page";
import SuccesstionItem from "../../components/RH-Manager/SuccesstionItem";
import WorkSuggestItemSkeleton from "../../components/work_suggest/WorkSuggestItemSkeleton";
import { ProfileContext } from "../../contexts/ProfileContext";
import useSuccession from "../../hooks/useSuccession";
import { returnInitial } from "../../Utils";
import SuccessionFilters from "./SuccessionFilters";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";

const BackButton = styled(Button)`
  border-radius: 100px;
  font-size: 12px;
  font-weight: normal;
  padding: 5px 15px;
  height: 55px;
  margin-bottom: 20px;
`;

const Root = styled("div")(({ theme }: any) => ({
  background: theme.palette.collabBlue,
  borderRadius: "27px",
  padding: "38px 24px 48px 24px",
}));

const SuccessionPage = () => {
  const { peopleData } = useContext(ProfileContext);

  const { loading, filters, matchers, employees } = useSuccession(
    peopleData?.id
  );

  const theme: any = useTheme();

  const navigate = useNavigate();
  const backToProfile = () => {
    navigate(`/app/profile/${peopleData?.id}/overview`);
  };

  return (
    <Page
      sx={{ pt: 12, pb: 5 }}
      title={`Plan de succession | ${peopleData?.firstName} ${peopleData?.lastName}`}
    >
      <Container>
        <Root>
          <BackButton variant="outlined" onClick={backToProfile}>
            <ArrowBackRoundedIcon sx={{ mr: 1 }} />
            <Typography variant="body2">Retourner sur le profil</Typography>
          </BackButton>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              sx={{
                border: `1px solid ${theme.palette.primary.main} !important`,
                width: 100,
                height: 100,
              }}
              src={peopleData?.image?.contentUrl ?? ""}
              children={
                <Typography variant="body1">
                  {returnInitial(
                    peopleData?.firstName + " " + peopleData?.lastName
                  )}
                </Typography>
              }
            />
            <div style={{ marginLeft: "10px" }}>
              <Typography variant="h4">
                {peopleData?.firstName} {peopleData?.lastName}
              </Typography>
              <Typography variant="subtitle2">
                {peopleData?.actualWork}
              </Typography>
              <Typography>{peopleData?.email}</Typography>
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>
            <SuccessionFilters matchers={matchers ?? []} filters={filters} />
          </div>

          {!loading ? (
            <Stack spacing={2}>
              {employees?.map((el: any) => (
                <div key={el.person.id}>
                  <SuccesstionItem infos={el} />
                </div>
              ))}
            </Stack>
          ) : (
            <>
              {new Array(10).fill(0).map((e: any, index: number) => (
                <WorkSuggestItemSkeleton key={index} index={index} benef />
              ))}
            </>
          )}
        </Root>
      </Container>
    </Page>
  );
};

export default SuccessionPage;
