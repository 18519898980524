import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ControlledYearSelector: React.FC<{
  control: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
  checked?: boolean;
  label?: any;
}> = ({ control, name, required = false, disabled, checked, label }) => {
  const { t } = useTranslation("common");
  const currentYear = new Date().getFullYear();
  const years = new Array(21).fill(null).map((_, i) => currentYear + i);

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel>
          {t("date.year")} {required ? "*" : ""}
        </InputLabel>
        <Controller
          name={name}
          control={control}
          rules={{
            required: { value: required, message: "errors.required" },
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <Select
              value={value}
              label={label}
              onChange={onChange}
              error={Boolean(errors?.[name])}
            >
              {years.map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </div>
  );
};

export default ControlledYearSelector;
