import React from "react";

import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import { Box, Skeleton, Typography } from "@mui/material";
import RessourceVideo from "./RessourceVideo";
import { useTranslation } from "react-i18next";

const ResourcesVideos: React.FC<{
  videos: any[];
  sorted: boolean;
  loading: boolean;
}> = ({ videos, sorted, loading }) => {
  const { t } = useTranslation("common");
  return (
    <section>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ borderBottom: "1px solid lightgrey", paddingBottom: "8px" }}
        marginTop="30px"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PlayCircleOutlinedIcon sx={{ mr: 1 }} />
          <Typography variant="subtitle1" fontWeight="normal">
            {t("resources.videos_to_watch")}
          </Typography>
        </Box>
      </Box>

      {!loading && videos.length === 0 && (
        <Typography sx={{ mt: 1 }}>
          {sorted ? t("resources.no_video_search") : t("resources.no_video")}
        </Typography>
      )}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(360px, 1fr))",
        }}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4].map((el) => (
              <Skeleton
                key={el}
                sx={{ marginTop: "15px" }}
                variant="rounded"
                width={360}
                height={203}
              />
            ))}
          </>
        ) : (
          <>
            {videos.map((e: any) => (
              <RessourceVideo data={e} key={e.id} />
            ))}
          </>
        )}
      </Box>
    </section>
  );
};

export default ResourcesVideos;
