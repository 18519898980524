import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayProjects: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">{t("admin.params.projects.title")}</Typography>
      <FormGroup>
        <Typography sx={{ mt: 2 }}>
          {t("admin.params.projects.text")}
        </Typography>
        <FormControlLabel
          value={param.TRAINING}
          onChange={(e: any) =>
            handleChanges({ ...param, TRAINING: e.target.checked })
          }
          control={<Checkbox checked={param.TRAINING} />}
          label={t("admin.params.projects.training")}
        />
        <FormControlLabel
          value={param.MOBIL_GEO}
          onChange={(e: any) =>
            handleChanges({ ...param, MOBIL_GEO: e.target.checked })
          }
          control={<Checkbox checked={param.MOBIL_GEO} />}
          label={t("admin.params.projects.geo")}
        />
        <FormControlLabel
          value={param.WORK}
          onChange={(e: any) =>
            handleChanges({ ...param, WORK: e.target.checked })
          }
          control={<Checkbox checked={param.WORK} />}
          label={t("admin.params.projects.work")}
        />
      </FormGroup>

      <Typography textAlign="right" sx={{ mt: 1 }}>
        {t("admin.params.reco_text")}
      </Typography>
    </div>
  );
};

export default AdminDisplayProjects;
