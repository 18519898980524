import {
  Card,
  CardContent,
  Box,
  Stack,
  Typography,
  IconButton,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Flipped, Flipper } from "react-flip-toolkit";
import { useTranslation } from "react-i18next";

const SelecteableCard: any = styled(Card, {
  shouldForwardProp: (props) => props !== "selected",
})(({ theme, selected }: any) => ({
  "& .iconButton": {
    backgroundColor: selected ? theme.palette.primary[500] : "auto",
  },
}));

const UpDown = styled(Box)(({ theme }: any) => ({
  display: "flex",
  flexDirection: "column",
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

const UpDownButton = styled(IconButton)({
  height: "20px",
  width: "20px",
});

const CustomIconButton = styled(IconButton)(({ theme }: any) => ({
  width: "45px",
  height: "45px",
  flexShrink: 0,
  borderRadius: "50px",
  marginRight: theme.spacing(2),
  border: `2px solid ${theme.palette.grey[300]}`,
  color: theme.palette.grey[300],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const IncQuestionHierarchy: React.FC<any> = ({
  question,
  sendResponse,
  validated,
  mounted,
}) => {
  const [selected, setSelected] = useState<any>([]);
  const [answers, setAnswers] = useState<any>([]);
  const { t } = useTranslation("common");
  useEffect(() => {
    sendResponse &&
      sendResponse({
        code: question.code,
        answer: selected,
      });

    const selectionObjects: any = [];
    const newAnswers: any = [...question.answers];
    selected.forEach((el: any) => {
      selectionObjects.push(newAnswers.find((e: any) => e.code === el.code));
      newAnswers.splice(
        newAnswers.findIndex((e: any) => e.code === el.code),
        1
      );
    });

    selectionObjects.forEach((el: any, index: number) => {
      newAnswers.splice(index, 0, el);
    });
    setAnswers(newAnswers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (question?.answers) {
      setAnswers(question.answers);
      const answered = question?.answers.filter((el: any) => el.listIndex >= 0);

      answered.sort((a: any, b: any) => (a.listIndex > b.listIndex && 1) || -1);

      if (answered.length > 0) {
        const arr = answered.map((el: any) => ({
          code: el.code,
          value: el.value,
        }));

        setSelected(arr);
      }
    }
  }, [question]);

  const handleSelect = (element: any) => {
    const index = selected.findIndex((el: any) => el.code === element.code);
    if (index >= 0) {
      const arr = [...selected];
      arr.splice(index, 1);
      setSelected(arr);
    } else if (selected.length < question.details.max_selection_choices) {
      const { code, value } = element;
      setSelected([...selected, { code, value }]);
    }
  };

  const handleUp = (e: any, el: any) => {
    e.stopPropagation();

    let arr = [...selected];
    const current =
      selected[selected.findIndex((e: any) => e.code === el.code)];

    if (selected.length > 1) {
      arr.splice(
        selected.findIndex((e: any) => e.code === el.code),
        1
      );
      arr.splice(
        selected.findIndex((e: any) => e.code === el.code) - 1,
        0,
        current
      );
    }

    setSelected(arr);
  };

  const handleDown = (e: any, el: any) => {
    e.stopPropagation();

    let arr = [...selected];
    const current =
      selected[selected.findIndex((e: any) => e.code === el.code)];

    if (selected.length > 1) {
      arr.splice(
        selected.findIndex((e: any) => e.code === el.code),
        1
      );
      arr.splice(
        selected.findIndex((e: any) => e.code === el.code) + 1,
        0,
        current
      );
    }

    setSelected(arr);
  };

  return (
    <div>
      <Typography variant="h5">
        Classez jusqu'à 3 comportements du plus fréquent (1) au moins fréquent
        (3)
      </Typography>
      <Flipper flipKey={answers.map((e: any) => e.code ?? "").join("")}>
        <Stack spacing={2}>
          {answers?.map((el: any) => (
            <Flipped key={el.code} flipId={el.code}>
              <SelecteableCard
                sx={{
                  cursor:
                    selected.length < 3 ||
                    selected.find((e: any) => e.code === el.code)
                      ? "pointer"
                      : "default",
                }}
                selected={
                  selected.findIndex((e: any) => e.code === el.code) >= 0
                }
                onClick={() => mounted && handleSelect(el)}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pl:
                      selected.findIndex((e: any) => e.code === el.code) >= 0
                        ? "0"
                        : "",
                  }}
                >
                  {selected.findIndex((e: any) => e.code === el.code) >= 0 && (
                    <UpDown>
                      <UpDownButton
                        aria-label="up item"
                        disabled={
                          selected.findIndex((e: any) => e.code === el.code) ===
                          0
                        }
                        onClick={(e: any) => mounted && handleUp(e, el)}
                      >
                        <KeyboardArrowUpIcon />
                      </UpDownButton>

                      <UpDownButton
                        aria-label="down item"
                        disabled={
                          selected.findIndex((e: any) => e.code === el.code) ===
                          question.details.max_selection_choices - 1
                        }
                        onClick={(e: any) => mounted && handleDown(e, el)}
                      >
                        <KeyboardArrowDownIcon />
                      </UpDownButton>
                    </UpDown>
                  )}
                  <CustomIconButton className="iconButton">
                    <Typography sx={{ fontWeight: "600", fontSize: "25px" }}>
                      {selected.findIndex((e: any) => e.code === el.code) >=
                        0 &&
                        selected.findIndex((e: any) => e.code === el.code) + 1}
                    </Typography>
                  </CustomIconButton>
                  <Typography variant="subtitle1">{el.label}</Typography>
                </CardContent>
              </SelecteableCard>
            </Flipped>
          ))}
        </Stack>
      </Flipper>
      {validated && selected.length === 0 && (
        <Typography sx={{ mt: 2 }} color="error">
          {t("inc.errors.select_at_least_one")}
        </Typography>
      )}
    </div>
  );
};

export default IncQuestionHierarchy;
