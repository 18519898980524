import {
  Box,
  IconButton,
  Collapse,
  Typography,
  Card,
  CardContent,
  Divider,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import palette from "../../theme/palette";
import DonutChartTest from "../tests/DonutChartTest";
import { Big5Config } from "./Big5Config";
import Big5ProgressMarker from "./Big5ProgressMarker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { returnColor } from "./big5Utils";
import Big5CompareBarLegend from "./Big5CompareBarLegend";
import Big5Archetypes from "./Big5Archetypes";
import Big5TraitInfos from "./Big5TraitInfos";

const Arrow: any = styled(ExpandMoreIcon, {
  shouldForwardProp: (props) => props !== "orderDesc",
})(({ theme, orderDesc }: any) => ({
  color: theme.palette.secondary.main,
  transition: "all 0.25s ease-in-out",
  transform: orderDesc ? "rotate(180deg)" : "rotate(0deg)",
}));

// const Profile = styled(Typography)(({ theme }: any) => ({
//   width: "566px",
//   textAlign: "center",
//   background: "#fbfbfb",
//   padding: theme.spacing(1),
//   borderRadius: theme.shape.borderRadius10,
// }));

const MoreItem = styled("div")`
  background-color: #5ec445;
  color: white;
  padding: 8px 15px;
  border-radius: 25px;
  text-align: center;
  margin: 10px 5px;
`;

const LessItem = styled("div")`
  background-color: #e13c5c;
  color: white;
  padding: 8px 15px;
  border-radius: 25px;
  text-align: center;
  margin: 10px 5px;
`;

const Big5Restitution: React.FC<{
  results?: any;
  traits?: any;
  compare?: boolean;
  notCollab?: boolean;
  work?: boolean;
  result?: any;
  compareResult?: any;
}> = ({ results, traits, compare, notCollab, work, compareResult, result }) => {
  const { t } = useTranslation("common");
  const [displayGraph, setDisplayGraph] = useState(false);
  const btraits = Big5Config.traits;

  const [openIndex, setOpenIndex] = useState(-1);

  useEffect(() => {
    if (compare) setDisplayGraph(false);
  }, [compare]);

  const expandComment = (index: number) => {
    setOpenIndex(index === openIndex ? -1 : index);
  };

  return results ? (
    <div style={{ maxWidth: "900px", marginLeft: "auto", marginRight: "auto" }}>
      {displayGraph ? (
        <DonutChartTest
          data={Object.values(results)}
          colors={Object.values(palette.b5)}
          labels={[
            t("big5.traits.openness.label"),
            t("big5.traits.conscientiousness.label"),
            t("big5.traits.extraversion.label"),
            t("big5.traits.agreeableness.label"),
            t("big5.traits.neuroticism.label"),
          ]}
        />
      ) : (
        <Box>
          {compare && <Big5CompareBarLegend />}

          {result?.MORE && result?.LESS && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Box>
                <Typography sx={{ color: "#5ec445" }} textAlign="center">
                  {t("big5.result.correspond_more")}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  {result.MORE.map((el: string) => (
                    <MoreItem key={el}>
                      <Typography>{el}</Typography>
                    </MoreItem>
                  ))}
                </Box>
              </Box>

              <Box>
                <Typography sx={{ color: "#e13c5c" }} textAlign="center">
                  {t("big5.result.correspond_less")}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  {result.LESS.map((el: string) => (
                    <LessItem key={el}>
                      <Typography>{el}</Typography>
                    </LessItem>
                  ))}
                </Box>
              </Box>
            </Box>
          )}

          {btraits.map((trait, index) => (
            <Box sx={{ mt: 2, mb: 2 }} key={trait}>
              <Box display="flex" alignItems="center">
                <Box width={notCollab ? "100%" : "95%"}>
                  <Big5ProgressMarker
                    compare={compare}
                    compareResult={compareResult ? compareResult[trait] : 0}
                    result={results[trait]}
                    trait={trait}
                  />
                </Box>
                {traits?.length > 0 && (
                  <Box sx={{ ml: "auto" }}>
                    <IconButton
                      aria-label="expand button"
                      sx={{ ml: "auto" }}
                      onClick={() => expandComment(index)}
                    >
                      <Arrow fontSize="large" orderDesc={openIndex === index} />
                    </IconButton>
                  </Box>
                )}
              </Box>
              {traits?.length > 0 && (
                <Collapse in={openIndex === index} unmountOnExit>
                  <Box sx={{ mt: 2 }}>
                    <Box
                      sx={{ mt: 2 }}
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      justifyContent="space-around"
                    >
                      {traits?.[
                        traits?.findIndex((res: any) => res?.key === trait)
                      ]?.sstraits.map((sstrait: any) => (
                        <Card
                          key={sstrait.label}
                          sx={{
                            boxShadow: `none!important`,
                            mb: 2,
                            background: `${returnColor(trait)}`,
                            color: "white",
                          }}
                        >
                          <CardContent>
                            <Box display="flex" alignItems="center">
                              <Typography variant="subtitle1">
                                {sstrait.percentage} %
                              </Typography>
                              <Typography sx={{ ml: 1 }} variant="body1">
                                {sstrait.label}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                    </Box>
                    <Big5TraitInfos traits={traits} trait={trait} />
                  </Box>
                </Collapse>
              )}
            </Box>
          ))}
        </Box>
      )}
      {!compare && !work && (
        <>
          <Big5Archetypes />
          <Divider sx={{ mt: 4, mb: 4 }} />
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Big5Restitution;
