/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import peopleAPI from "../../_api/_people";
import useFetch from "../../hooks/useFetch";
import ProfileEditFormBody from "./ProfileEditFormBody";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import UsersAPI from "../../_api/_usersAPI";
import { SubmitHandler, useForm } from "react-hook-form";
import ProfileStateEdit from "./ProfileStateEdit";
import profileFormatter from "../../_utils/formatters/profileFormatter";
import { Box, Paper } from "@mui/material";

const EditProfileInfos: React.FC<{ close?: () => void }> = ({ close }) => {
  const { t } = useTranslation("common");
  const { person_id: personId } = useSelector((state: any) => state.user);
  const { data: peopleData, loading: peopleLoading } = useFetch(
    peopleAPI.getById(personId)
  );
  const [infos, setInfos] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    peopleData && setInfos(profileFormatter({ ...peopleData, status: "30" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleData]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
  };

  if (peopleLoading || !infos) return <LoaderForm />;

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Paper elevation={3} sx={{ maxWidth: "835px", padding: "24px" }}>
        <ProfileStateEdit user={infos} />
      </Paper>
    </Box>
  );
};

export default EditProfileInfos;

const LoaderForm = () => {
  return <div></div>;
};
