import { Fade, Stack, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import React, { useState } from "react";
import BigButton from "../common/BigButton";
import ProjectCareerCard from "./ProjectCareerCard";
import ProjectModal from "./project_modal/ProjectModal";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProjectsCareerList: React.FC<{
  careerItems: any;
}> = ({ careerItems }) => {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const { t } = useTranslation("common");
  const { id } = useParams();

  React.useEffect(() => {
    console.log(careerItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fade in={true} timeout={500}>
      <div>
        {!id && (
          <BigButton onClick={openModal} sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "inherit" }}>
              {t("projects.add_project")}
            </Typography>{" "}
            <AddCircle sx={{ ml: 1 }} />
          </BigButton>
        )}

        <Stack spacing={2}>
          {careerItems?.map((careerItem: any) => (
            <ProjectCareerCard key={careerItem.id} project={careerItem} />
          ))}
        </Stack>

        {open && <ProjectModal open={open} close={closeModal} />}
      </div>
    </Fade>
  );
};

export default ProjectsCareerList;
