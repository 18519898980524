import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminReferential: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">
        {t("admin.params.referentiel.title")}
      </Typography>
      <FormGroup>
        <FormControlLabel
          value={param.DISPLAY_REF_ETP}
          onChange={(e: any) =>
            handleChanges({ ...param, DISPLAY_REF_ETP: e.target.checked })
          }
          control={<Checkbox checked={param.DISPLAY_REF_ETP} />}
          label={t("admin.params.referentiel.display")}
        />
        <FormControlLabel
          value={param.DISPLAY_ROME}
          onChange={(e: any) =>
            handleChanges({ ...param, DISPLAY_ROME: e.target.checked })
          }
          control={<Checkbox checked={param.DISPLAY_ROME} />}
          label={t("admin.params.referentiel.rome")}
        />
      </FormGroup>
      <Typography sx={{ mt: 1 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
    </div>
  );
};

export default AdminReferential;
