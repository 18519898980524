import { Box, Divider, Pagination, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch";
import worksAPI from "../../../../_api/_worksAPI";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Loader from "../../../../Loader";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import BigButton from "../../../common/BigButton";

const AdminWorksListParams = () => {
  const { t } = useTranslation("common");
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const theme: any = useTheme();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [label, setLabel] = useState("");
  const [term, setTerm] = useState("");
  const { data, loading } = useFetch(
    worksAPI.get({
      page,
      label,
      itemsPerPage: 12,
      company: `/api/companies/${tokenData.company_id}`,
    })
  );

  useEffect(() => {
    const to = setTimeout(() => {
      setLabel(term);
      setPage(1);
    }, 300);

    return () => {
      if (to) clearTimeout(to);
    };
  }, [term]);

  useEffect(() => {
    data?.["hydra:member"] && setList(data?.["hydra:member"]);
  }, [data]);

  return (
    <div>
      <Box>
        <Typography textAlign="center" variant="h4" marginBottom={2}>
          {t("admin.params.works.title")}
        </Typography>
        {/* <Button
          variant="contained"
          sx={{ height: "35px", ml: "auto" }}
          onClick={() => navigate(`/app/params/work`)}
        >
          <Typography variant="body2">
            {t("admin.params.works.create")}
          </Typography>
        </Button> */}
      </Box>
      <BigButton onClick={() => navigate(`/app/params/work`)}>
        {t("admin.params.works.create")}
      </BigButton>
      <TextField
        sx={{
          width: { xs: "100%", sm: "90%", md: "60%", lg: "30%" },
          mt: 2,
          mb: 2,
        }}
        onChange={(e: any) => setTerm(e.target.value)}
        label={t("admin.params.works.job_title")}
      />
      {loading ? (
        <Loader />
      ) : list?.length === 0 ? (
        <Typography textAlign="center" variant="body1" sx={{ mt: 5, mb: 5 }}>
          {t("admin.params.works.no_work")}
        </Typography>
      ) : (
        list?.map((work: any, index: number) => (
          <Box key={work?.id}>
            <Box
              onClick={() => navigate(`/app/params/work/${work?.id}`)}
              display="flex"
              alignItems="center"
              sx={{
                pt: 1.5,
                pb: 1.5,
                pl: 0.5,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: theme.palette.background.neutral,
                  "& .edit_btn": {
                    opacity: 1,
                  },
                },
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                {work.label}
              </Typography>
              <Typography
                className="edit_btn"
                sx={{
                  ml: "auto",
                  pr: 0.5,
                  opacity: 0,
                  display: "flex",
                  alignItems: "center",
                }}
                variant="body2"
                fontWeight="bold"
                color="primary"
              >
                {t("admin.params.works.edit")}
                <ArrowForwardIosIcon sx={{ fontSize: "16px", ml: 1 }} />
              </Typography>
            </Box>
            {index + 1 < list.length && <Divider />}
          </Box>
        ))
      )}
      {data?.["hydra:totalItems"] > 12 && (
        <Box display="center" justifyContent="center" sx={{ mt: 2 }}>
          <Pagination
            count={Math.ceil(data?.["hydra:totalItems"] / 12)}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Box>
      )}
    </div>
  );
};

export default AdminWorksListParams;
