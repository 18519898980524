import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProfilePictureFormButton from "../common/form/ProfilePictureFormButton";
import GenderSelect from "../common/GenderSelect";
import ControlledTextField from "../forms/ControlledTextField";
import AddressAutocomplete from "../common/autocomplete/AddressAutocomplete";
import VisioList from "./VisioList";
import UsersAPI from "../../_api/_usersAPI";
import peopleAPI from "../../_api/_people";
import { setUserPartial } from "../../redux/actions/userActions";
import thirdPartiesAPI from "../../_api/_thirdPartiesAPI";
import { toast } from "react-toastify";

interface IFormInput {
  firstName: string;
  lastName: string;
  gender: string;
  image?: string;
  zoom?: string;
  teams?: string;
  linkedin?: string;
  email?: string;
  phone?: string;
  company_name?: string;
  addressValue?: any;
}

const ProfileStateEdit: React.FC<any> = ({ user }) => {
  const { t } = useTranslation("common");
  const userState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const disableEditName = useSelector(
    (state: any) => state?.home?.paramjson?.disableEditName
  );
  console.log("state:", disableEditName);
  const isThird = useSelector((state: any) => state.auth.isThirdParty);
  const thirdId = userState.thirdParty_id;

  console.log("user", user);

  const defaultValues: Partial<IFormInput> = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    gender: user.gender,
    company_name: userState.company_name,
    addressValue: { label: user.address ?? "" },
    zoom: user.otherData?.visio?.zoom ?? "",
    teams: user.otherData?.visio?.teams ?? "",
    linkedin: user.otherData?.visio?.linkedin ?? "",
  };

  const {
    handleSubmit,
    control,
    formState: { touchedFields },
  } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues,
  });

  console.log("control", control);

  const onSubmit = async (data: IFormInput) => {
    try {
      setLoading(true);
      const keys = Object.keys(touchedFields);
      const values: any = {
        ...(data.gender !== userState.gender && { gender: data.gender }),
      };
      const resData: any = data;
      keys.forEach((key) => (values[key] = resData[key]));
      if (user.address !== data?.addressValue?.label) {
        values.addressValue = data.addressValue;
      }

      const body = {
        ...values,
        ...((values.zoom || values.teams || values.linkedin) && {
          otherData: {
            visio: {
              ...(values.zoom && { zoom: values.zoom }),
              ...(values.teams && { teams: values.teams }),
              ...(values.linkedin && { linkedin: values.linkedin }),
            },
          },
        }),
      };

      const shouldContinue = Object.keys(values).length > 0;

      if (!shouldContinue) return;

      if (values.company_name && thirdId) {
        console.log("company_name", values.company_name);
        await thirdPartiesAPI.put(thirdId, {
          companyName: values.company_name,
        });
      }

      await UsersAPI.put(userState.user_id, body);
      await peopleAPI.put(userState.person_id, body);

      const userPartialKeys = [
        "firstName",
        "lastName",
        "email",
        "company_name",
        "gender",
      ];
      const userObj: any = {};
      userPartialKeys.forEach((key) => {
        if (values[key]) userObj[key] = values[key];
      });
      console.log("userObj", userObj);
      dispatch(setUserPartial(userObj));
      toast.success(t("admin.params.alert_save"));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <ProfilePictureFormButton image={userState.image} />

      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <Controller
          name="gender"
          control={control}
          rules={{
            required: { value: true, message: "errors.required" },
          }}
          render={({ field: { value, onChange } }) => (
            <GenderSelect gender={value} setGender={onChange} littleMargin />
          )}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ mt: 2, mb: 2, p: 1, mr: 1 }}
      >
        <ControlledTextField
          name="email"
          label={t("form.mail")}
          control={control}
          disabled={disableEditName}
        />
      </Box>
      <Grid container sx={{ marginTop: "0px !important", pr: 1 }}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ p: 1 }}>
            <ControlledTextField
              name="firstName"
              label={t("form.firstname")}
              control={control}
              disabled={disableEditName}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ p: 1 }}>
            <ControlledTextField
              name="lastName"
              label={t("form.lastname")}
              control={control}
              disabled={disableEditName}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ padding: "8px", paddingRight: "8px!important" }}
        >
          <Controller
            name="addressValue"
            control={control}
            rules={{
              required: { value: true, message: "errors.required" },
            }}
            render={({ field: { value, onChange } }) => (
              <AddressAutocomplete value={value} onChange={onChange} />
            )}
          />
        </Grid>

        {isThird && (
          <Grid item xs={12} sm={12}>
            <Box sx={{ p: 1 }}>
              <ControlledTextField
                name="company_name"
                label={t("form.company")}
                control={control}
                disabled={disableEditName}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <Grid container sx={{ marginTop: "0px !important", pr: 1 }}>
        {/* Moyen de visio disponible */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ ml: 1 }}>
            {t("profilepage.visios.subtitle")}
          </Typography>
        </Grid>
        <VisioList control={control} />
      </Grid>

      <Box display="flex" justifyContent="center" marginTop={2}>
        <Button
          disabled={loading}
          type="submit"
          size="large"
          variant="contained"
          sx={{ fontSize: "12px", width: "200px", height: "55px" }}
        >
          {t("btn.save")}
        </Button>
      </Box>
    </form>
  );
};

export default ProfileStateEdit;
