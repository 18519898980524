import { Container, Box, styled } from "@mui/material";
import React from "react";

const Root = styled(Box)(({ theme }: any) => ({
  height: "100%",
  display: "flex",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Main = styled(Container)({
  backgroundColor: "white",
  borderTopLeftRadius: "35px",
});

const Side = styled(Box)(({ theme }: any) => ({
  width: "350px",
  minWidth: "350px",
  marginLeft: "auto",
  maxWidth: "350px",
  backgroundColor: "white",
  paddingTop: theme.spacing(13),
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.down("lg")]: {
    width: "320px",
    minWidth: "320px",
    maxWidth: "320px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },
}));

export default function SideContentLayout({ main, side }: any) {
  return (
    <Root>
      <Main sx={{ display: "flex", width: "100%" }}>
        <Container sx={{ pt: 13, pb: 5 }}>{main}</Container>
      </Main>
      {side && (
        <Side>
          <Container>{side}</Container>
        </Side>
      )}
    </Root>
  );
}
