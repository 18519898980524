import { Box, Button, Container, Stack, styled } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CardIllustration from "../components/common/CardIllustration";
import Page from "../components/Page";
import AccountParams from "../components/params/AccountParams";
import { useDispatch, useSelector } from "react-redux";
import SharesCollab from "../components/_collab/home/shares/SharesCollab";
import EditProfileInfos from "../components/profile/EditProfileInfos";
import SharesPartners from "../components/common/form/SharesPartners";
import { useForm } from "react-hook-form";
import peopleAPI from "../_api/_people";
import { setUserPartial } from "../redux/actions/userActions";

const AccountParamsPage = () => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  // const disableEditShares = useSelector(
  //   (state: any) => state?.home?.paramjson?.disableEditShares
  // );
  const disableEditShares = true;
  const sharePartners = useSelector(
    (state: any) => state.home?.paramjson.signUp?.sharePartners
  );
  const isCollab = useSelector((state: any) => state.auth.isCollab);

  return (
    <Page title={t("profile_menu.params")}>
      <Container sx={{ pt: 13, pb: 5 }}>
        <CardIllustration type="params" />
        <Stack spacing={5}>
          <div>
            <EditProfileInfos />
          </div>

          {sharePartners && isCollab && <ParnersForm />}

          {!disableEditShares && (
            <>
              {/* {context === "benef" && (
                <div>
                  <SharesBenef />
                </div>
              )}
              {context === "candidate" && (
                <div>
                  <SharesCandidate />
                </div>
              )} */}
              {context === "collab" && (
                <div>
                  <SharesCollab />
                </div>
              )}
            </>
          )}
          <div>
            <AccountParams />
          </div>
        </Stack>
      </Container>
    </Page>
  );
};

export default AccountParamsPage;

type IFormInput = {
  sharePartners: string;
};

const ParnersForm = () => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const otherData = user?.people?.otherData ?? {};

  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: {
      sharePartners: otherData.hasOwnProperty("sharePartners")
        ? otherData?.sharePartners
          ? "accept"
          : "refuse"
        : "",
    },
  });

  const submit = async (data: IFormInput) => {
    try {
      setLoading(true);
      const value = data.sharePartners === "accept";
      const body = {
        otherData: {
          sharePartners: data.sharePartners === "accept",
        },
      };

      await peopleAPI.put(user.person_id, body);

      dispatch(
        setUserPartial({
          people: {
            ...user.people,
            otherData: { ...user.people.otherData, sharePartners: value },
          },
        })
      );
    } catch (err) {
    } finally {
      setLoading(false);
    }

    // PUT API PEOPLE OTHERDATA
  };

  return (
    <form noValidate onSubmit={handleSubmit(submit)}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <SharesPartners control={control} />
        <Box>
          <CustomButton disabled={loading} variant="contained" type="submit">
            {t("btn.save")}
          </CustomButton>
        </Box>
      </Box>
    </form>
  );
};

const CustomButton = styled(Button)({
  width: "200px",
  padding: "6px 16px",
  height: "55px",
  fontSize: "12px",
});
