import { Box, Collapse, Typography, styled } from "@mui/material";
import { Star } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import WorkSkillTitle from "./WorkSkillTitle";

const TopBox = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  background: theme.palette.grey[500_8],
  borderRadius: theme.shape.borderRadius,
}));

const StarIcon = styled(Star)(({ theme }: any) => ({
  color: "#FFC107",
  marginRight: theme.spacing(1),
}));

const Bullet: any = styled("div", {
  shouldForwardProp: (props) => props !== "themecolor",
})(({ theme, themecolor = theme.palette.common.white }: any) => ({
  background: themecolor,
  minWidth: 13.5,
  height: 13.5,
  borderRadius: 25,
  margin: 4,
}));

const WorkSkillsHeader: React.FC<{
  type?: "transversal" | "technical";
  compare?: boolean;
  candEdit?: boolean;
  expand?: boolean;
  context?: string;
  handleEdit?: any;
  updated?: boolean;
}> = ({
  type = "technical",
  compare,
  candEdit,
  expand,
  context,
  handleEdit,
  updated,
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <img
          src={`/static/illustrations/work/${
            type === "technical" ? "work-skills" : "transversal"
          }.svg`}
          alt="Illustration pizzaïolo"
        />
      </Box>

      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <WorkSkillTitle
          type={type}
          context={context}
          handleEdit={handleEdit}
          compare={compare}
          candEdit={candEdit}
        />
      </Box>
      {!updated && (
        <Collapse in={!expand}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <TopBox>
              <StarIcon />
              <Typography>
                {t(
                  `work_sheet.top_${
                    type === "technical" ? "skills" : "abilities"
                  }`
                )}
              </Typography>
            </TopBox>
          </Box>
        </Collapse>
      )}
      {compare && !updated && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "37.5px",
                height: "37.5px",
                backgroundColor: "primary.main",
                borderRadius: "6px",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "19.4px",
                  height: "19.4px",
                  backgroundImage: `url('/static/icons/Ico/chps/skillsDashed.svg')`,
                }}
              >
                <Bullet />
              </Box>
            </Box>
            <Typography variant="body1" sx={{ opacity: 0.7, ml: 0.5 }}>
              {t("skills.expected_level")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "37.5px",
                height: "37.5px",
                backgroundColor: "primary.main",
                borderRadius: "6px",
              }}
            >
              <Bullet />
            </Box>
            <Typography variant="body1" sx={{ opacity: 0.7, ml: 0.5 }}>
              {t("skills.mastered_level")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default WorkSkillsHeader;
