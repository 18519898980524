import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import diplomas from "../../_utils/diplomasList";
import Tag from "../common/Tag";

const ExperiencesTags: React.FC<{ experience: any }> = ({ experience }) => {
  const { t } = useTranslation("common");
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {experience.diplomaLevel >= 0 && diplomas[experience.diplomaLevel] && (
        <Tag title={diplomas[experience.diplomaLevel]} />
      )}
      {(!experience.dateEnd || new Date(experience.dateEnd) > new Date()) && (
        <Tag title={t("ongoing")} color="success" />
      )}
      {experience.validityDate && (
        <Tag
          title={`${t("experience_section.expire_on")} ${new Date(
            experience.validityDate
          ).toLocaleDateString("default", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}`}
          color="warning"
        />
      )}
    </Box>
  );
};

export default ExperiencesTags;
