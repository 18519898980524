import { Box, Typography, Slide, Grow, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";

const StepLabel = styled(Typography)({
  transition: "all 1s ease-in-out",
});

const StepSplash = () => {
  const { t } = useTranslation("common");
  const [step_1, setStep_1] = useState<any>(false);
  const [step_2, setStep_2] = useState<any>(false);
  const [step_3, setStep_3] = useState<any>(false);
  const [step_4, setStep_4] = useState<any>(false);
  return (
    <Box textAlign="left">
      <Slide
        direction="right"
        in={true}
        timeout={500}
        mountOnEnter
        unmountOnExit
        onEntered={() =>
          setTimeout(() => {
            setStep_1(true);
          }, 1000)
        }
      >
        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          {step_1 && (
            <Grow in={true} timeout={1000}>
              <CheckCircleIcon color="primary" />
            </Grow>
          )}
          <Box sx={{ ml: step_1 ? 1 : 4 }}>
            <StepLabel variant="subtitle2" color={step_1 ? "primary" : ""}>
              {t("tests.splash_screen.step_1")}
            </StepLabel>
          </Box>
        </Box>
      </Slide>
      <Slide
        direction="right"
        in={true}
        timeout={1000}
        mountOnEnter
        unmountOnExit
        onEntered={() =>
          setTimeout(() => {
            setStep_2(true);
          }, 1500)
        }
      >
        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          {step_2 && (
            <Grow in={true} timeout={1000}>
              <CheckCircleIcon color="primary" />
            </Grow>
          )}
          <Box sx={{ ml: step_2 ? 1 : 4 }}>
            <StepLabel variant="subtitle2" color={step_2 ? "primary" : ""}>
              {t("tests.splash_screen.step_2")}
            </StepLabel>
          </Box>
        </Box>
      </Slide>
      <Slide
        direction="right"
        in={true}
        timeout={1500}
        mountOnEnter
        unmountOnExit
        onEntered={() =>
          setTimeout(() => {
            setStep_3(true);
          }, 2000)
        }
      >
        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          {step_3 && (
            <Grow in={true} timeout={1000}>
              <CheckCircleIcon color="primary" />
            </Grow>
          )}
          <Box sx={{ ml: step_3 ? 1 : 4 }}>
            <StepLabel variant="subtitle2" color={step_3 ? "primary" : ""}>
              {t("tests.splash_screen.step_3")}
            </StepLabel>
          </Box>
        </Box>
      </Slide>
      <Slide
        direction="right"
        in={true}
        timeout={2000}
        mountOnEnter
        unmountOnExit
        onEntered={() =>
          setTimeout(() => {
            setStep_4(true);
          }, 2500)
        }
      >
        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          {step_4 && (
            <Grow in={true} timeout={1000}>
              <CheckCircleIcon color="primary" />
            </Grow>
          )}
          <Box sx={{ ml: step_4 ? 1 : 4 }}>
            <StepLabel variant="subtitle2" color={step_4 ? "primary" : ""}>
              {t("tests.splash_screen.step_4")}
            </StepLabel>
          </Box>
        </Box>
      </Slide>
    </Box>
  );
};

export default StepSplash;
