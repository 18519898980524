import { Paper, CardContent, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import actionHistoryAPI from "../../_api/_actionHistoryAPI";
import HomeLastActionSkeleton from "../skeletons/advisor/HomeLastActionSkeleton";
import LastActionItem from "./LastActionItem";

const LastActionCard = ({ personId }: any) => {
  const { t } = useTranslation("common");
  const { data, loading } = useFetch(
    actionHistoryAPI.get(personId ? { "person.id": personId } : {})
  );

  const LastActionPaper = styled(Paper)(({ theme }: any) => ({
    boxShadow: theme.customShadows.z8,
    borderRadius: "23px",
  }));

  return (
    <>
      {loading ? (
        <HomeLastActionSkeleton />
      ) : (
        <LastActionPaper elevation={3}>
          <CardContent sx={{ pb: 2 }}>
            <Typography fontWeight="bold" variant="body1" sx={{ mb: 2 }}>
              {t("last_actions.title")}
            </Typography>
            {data?.["hydra:member"]
              .filter((el: any, i: number) => i < 7)
              ?.map((item: any, index: number) => (
                <LastActionItem key={item.id + String(index)} item={item} />
              ))}
            {data?.["hydra:member"]?.length === 0 && (
              <Typography variant="body2">
                {t("profilepage.no_last_actions")}
              </Typography>
            )}
          </CardContent>
        </LastActionPaper>
      )}
    </>
  );
};

export default LastActionCard;
