import React, { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router";
import LogoLoader from "../components/LogoLoader";
import NoMenuLayout from "../layout/NoMenuLayout";

const RGPDLockPage = lazy(() => import("../pages/RGPDLockPage"));

const RgpdRoute = () => {
  return useRoutes([
    {
      path: "",
      element: <NoMenuLayout />,
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RGPDLockPage />
            </Suspense>
          ),
        },
        { path: "*", element: <Navigate to="/" replace /> },
      ].filter((el: any) => el.path),
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
};

export default RgpdRoute;
