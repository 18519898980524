import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useState } from "react";
import loaderJSON from "../../general_loading.json";
// import { useSelector } from "react-redux";

// function hexToRgb(hex: string) {
//   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//   return result
//     ? {
//         r: parseInt(result[1], 16),
//         g: parseInt(result[2], 16),
//         b: parseInt(result[3], 16),
//       }
//     : null;
// }

const IntroSplashscreen = () => {
  const [mounted, setMounted] = useState(true);
  // const theme = useSelector(
  //   (state: any) => state.home?.paramjson?.theme?.css?.primary
  // );

  // console.log(theme);

  const loaderFile: any = { ...loaderJSON };

  // const color1 = theme?.[500];
  // const color2 = theme?.["dark"];
  // const color3 = theme?.["light"];
  // const color4 = theme?.[200];

  // loaderFile["layers"][0]["shapes"][2]["it"][2]["c"]["k"] = [1, 0, 0, 1]; // first
  // // loaderFile["layers"][0]["shapes"][3]["it"][2]["c"]["k"] = [1, 0, 0, 1]; // Second
  // loaderFile["layers"][0]["shapes"][1]["it"][2]["c"]["k"] = [1, 0, 0, 1]; // Third
  // loaderFile["layers"][0]["shapes"][0]["it"][2]["c"]["k"] = [1, 0, 0, 1]; // last

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);
  return (
    <div>
      {mounted && (
        <Player
          autoplay
          loop
          src={loaderFile}
          style={{ height: "300px", width: "70%" }}
        ></Player>
      )}
    </div>
  );
};

export default IntroSplashscreen;
