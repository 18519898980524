import { Box, Divider, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import RatingBubblesOnly from "../../../skills/RatingBubblesOnly";

const AdminSkills: React.FC<any> = ({ param, handleChanges }) => {
  const theme: any = useTheme();
  const { t } = useTranslation("common");
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t("tests.skills")}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
        {t("admin.params.skills.text")}
      </Typography>
      <RatingBubblesOnly
        identifier={1}
        color={theme.palette.green[500]}
        level={1}
      />
      <TextField
        sx={{ mt: 2, width: "200px" }}
        value={param.LEVEL1}
        onChange={(event: any) =>
          handleChanges({ ...param, LEVEL1: event.target.value })
        }
        label={t("admin.params.skills.bubble")}
        variant="outlined"
      />
      <TextField
        sx={{ mt: 2, maxWidth: "600px" }}
        value={param.LEVEL1_DEF}
        multiline
        onChange={(event: any) =>
          handleChanges({ ...param, LEVEL1_DEF: event.target.value })
        }
        label={t("admin.params.skills.description")}
        minRows={2}
        variant="outlined"
      />
      <Divider sx={{ mt: 3, mb: 3 }} />
      <RatingBubblesOnly
        identifier={2}
        color={theme.palette.green[500]}
        level={2}
      />
      <TextField
        sx={{ mt: 2, width: "200px" }}
        value={param.LEVEL2}
        onChange={(event: any) =>
          handleChanges({ ...param, LEVEL2: event.target.value })
        }
        label={t("admin.params.skills.bubble")}
        variant="outlined"
      />
      <TextField
        sx={{ mt: 2, maxWidth: "600px" }}
        value={param.LEVEL2_DEF}
        multiline
        onChange={(event: any) =>
          handleChanges({ ...param, LEVEL2_DEF: event.target.value })
        }
        label={t("admin.params.skills.description")}
        minRows={2}
        variant="outlined"
      />
      <Divider sx={{ mt: 3, mb: 3 }} />
      <RatingBubblesOnly
        identifier={3}
        color={theme.palette.green[500]}
        level={3}
      />
      <TextField
        sx={{ mt: 2, width: "200px" }}
        value={param.LEVEL3}
        onChange={(event: any) =>
          handleChanges({ ...param, LEVEL3: event.target.value })
        }
        label={t("admin.params.skills.bubble")}
        variant="outlined"
      />
      <TextField
        sx={{ mt: 2, maxWidth: "600px" }}
        value={param.LEVEL3_DEF}
        multiline
        onChange={(event: any) =>
          handleChanges({ ...param, LEVEL3_DEF: event.target.value })
        }
        label={t("admin.params.skills.description")}
        minRows={2}
        variant="outlined"
      />
      <Divider sx={{ mt: 3, mb: 3 }} />
      <RatingBubblesOnly
        identifier={4}
        color={theme.palette.green[500]}
        level={4}
      />
      <TextField
        sx={{ mt: 2, width: "200px" }}
        value={param.LEVEL4}
        onChange={(event: any) =>
          handleChanges({ ...param, LEVEL4: event.target.value })
        }
        label={t("admin.params.skills.bubble")}
        variant="outlined"
      />
      <TextField
        sx={{ mt: 2, maxWidth: "600px" }}
        value={param.LEVEL4_DEF}
        multiline
        onChange={(event: any) =>
          handleChanges({ ...param, LEVEL4_DEF: event.target.value })
        }
        label={t("admin.params.skills.description")}
        minRows={2}
        variant="outlined"
      />
      <Typography sx={{ mt: 3 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
    </Box>
  );
};

export default AdminSkills;
