import { ButtonBase, styled } from "@mui/material";

const BigButton: any = styled(ButtonBase)(({ theme, ...props }: any) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  border: "1px solid " + theme.palette.primary.main,
  width: "100%",
  color: theme.palette.primary.main,
  ...props.sx,
  "&:hover": {
    background: theme.palette.grey[500_8],
  },
}));

export default BigButton;
