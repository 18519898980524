/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@mui/material";
import React from "react";
import IncProgress from "./IncProgress";

import IncGroupHeader from "../headers/IncGroupHeader";
import IncGroupChart from "../IncGroupChart";
import useINCGroupFormatter from "../../../hooks/useINCGroupFormatter";
import IncGroupSquares from "../group/IncGroupSquares";

const IncGroupCompare: React.FC<any> = ({ results, score, workResult }) => {
  const res = useINCGroupFormatter(results);

  return (
    <div>
      <IncGroupHeader />

      <IncProgress value={score} />

      <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
        {res && <IncGroupSquares compare result={res} />}
      </Box>
    </div>
  );
};

export default IncGroupCompare;
