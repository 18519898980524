import axios from "axios";
import peopleAPI from "../../_api/_people";
import {
  openLoaderWithType,
  setLoaderOpen,
  // setLoaderType,
} from "./globalLoaderActions";
import { handleHome } from "./homeActions";
import { handleMenu } from "./menuActions";
import { handleUser, setUser } from "./userActions";
import ResultsAPI from "../../_api/_resultsAPI";

export const SET_LOGGED = "SET_LOGGED";
export const SET_CONTEXT = "SET_CONTEXT";
export const SET_REDIRECTLOGINURL = "SET_REDIRECTLOGINURL";

export const setLogged = (value: boolean) => {
  return {
    type: SET_LOGGED,
    payload: value,
  };
};

export const setContext = (value: string) => {
  return {
    type: SET_CONTEXT,
    payload: value,
  };
};

export const setRedirectLoginUrl = (value: any) => {
  return {
    type: SET_REDIRECTLOGINURL,
    payload: value,
  };
};

export const login = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const { data } = await axios.get("is_logged");
      const people = (await axios.get(peopleAPI.getById(data.person_id)))?.data;
      data.profilePicture = people.image?.contentUrl ?? "";
      dispatch(handleUser({ ...data, people }));
      dispatch(setContext(localStorage.getItem("context") ?? data.context));
      dispatch(handleMenu());
      dispatch(setLogged(true));
      const home = await (await axios.get("home")).data?.["hydra:member"]?.[0];
      dispatch(handleHome(home));
    } catch (err: any) {
      localStorage.clear();
      // sessionStorage.clear();
      dispatch(setLogged(false));
      dispatch(setUser(null));
      console.log("ERROR", err);
    }
  };
};

export const logout: any = () => {
  return async (dispatch: any) => {
    try {
      await axios.post("logout", null);
      localStorage.clear();
      // sessionStorage.clear();
      dispatch(setLogged(false));
      dispatch(setRedirectLoginUrl(null));
      dispatch(setUser(null));
    } catch (err: any) {}
  };
};

export const init = async (dispatch: any, signed_up?: boolean) => {
  try {
    const user = (await axios.get("is_logged"))?.data;

    await handleTestsRedirect();

    const people = (await axios.get(peopleAPI.getById(user.person_id)))?.data;

    user.onboarding = people.onboarding;
    user.onboardingTest = people.onboardingTest;
    user.gender = people.gender;
    user.profilePicture = people.image?.contentUrl ?? "";
    user.rgpd = people.rgpd;
    user.status = people?.connectionStatus;
    user.people = people;

    if (signed_up) {
      dispatch(openLoaderWithType("sign-up"));
      setTimeout(() => {
        // dispatch(setLoaderType("default"));
        dispatch(setLoaderOpen(false));
      }, 5000);
    }

    dispatch(handleUser(user));

    dispatch(setContext(localStorage.getItem("context") ?? user.context));

    dispatch(handleMenu());

    dispatch(setLogged(true));

    if (signed_up) {
      const home = await (await axios.get("home")).data?.["hydra:member"]?.[0];
      dispatch(handleHome(home));
    }
    // dispatch(setProfilePicture(profilePicture));
  } catch (err: any) {
    localStorage.clear();
    // sessionStorage.clear();
    dispatch(setLogged(false));
    dispatch(setUser(null));
  }
};

const handleTestsRedirect = async () => {
  if (
    sessionStorage.getItem("redirectTest") &&
    sessionStorage.getItem("redirectTestData")
  ) {
    const data = JSON.parse(sessionStorage.getItem("redirectTestData") ?? "{}");
    const test = sessionStorage.getItem("redirectTest");

    try {
      const body = {
        duration: 0,
        raw: data,
        test: `/api/tests/${test}`,
      };

      await ResultsAPI.post(body);

      sessionStorage.removeItem("redirectTest");
      sessionStorage.removeItem("redirectTestData");
    } catch (err) {}
  }
};
