import { Utils } from "./../Utils";
const initialState: any = {
  token: Utils.getCookie("token"),
  refresh_token: Utils.getCookie("refresh_token"),
  home: null,
  parameters: [],
  ptwPopup: {
    open: false,
    active: false,
  },
  dispatchModal: {
    open: false,
  },
  chat: {
    message: null,
    conversations: [],
  },
  auth: {
    isLogged: false,
    context: "",
    isCollab: false,
    advisorOrRecruiter: false,
    isAdvisor: false,
    isRecruiter: false,
    isThirdParty: false,
  },
  user: null,
  globalLoader: {
    open: false,
    type: "",
    current: "",
  },
  menu: {
    profile: true,
    tests: true,
    projects: true,
    works: true,
    trainings: true,
    jobs: true,
  },
  preferences: {
    itemsPerPage: 10,
  },
};
export default initialState;
