import {
  Box,
  Button,
  Modal,
  Card,
  CardContent,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { modalStyle } from "../../../../_utils/modalStyle";
import { HighlightOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import peopleAPI from "../../../../_api/_people";
import UsersAPI from "../../../../_api/_usersAPI";
import { useSelector } from "react-redux";
import axios from "axios";

const AdminUserRoleModal: React.FC<{
  open: boolean;
  close: () => void;
  reloadCall: () => void;
  users: any;
}> = ({ open, close, users, reloadCall }) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState({
    rh: false,
    manager: false,
    admin: false,
    adminRh: false,
    recruiter: false,
    adminRecruiter: false,
    advisor: false,
    adminAdvisor: false,
  });

  const deleteUser = () => {
    setLoading(true);
    const calls: any = [];
    users?.map((user: any) => calls.push(peopleAPI.delete(user.id)));
    Promise.all(calls)
      .then((res: any) => {
        reloadCall();
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    setLoading(true);
    const calls: any = [];
    let userId: any;
    if (users?.length === 1 && !users?.[0]?.userId) {
      try {
        const res = await axios.get(
          peopleAPI.getById(users?.[0]?.person["@id"].split("/")[3])
        );
        userId = res?.data?.userId;
      } catch (err) {
        setLoading(false);
      }
    }

    // eslint-disable-next-line array-callback-return
    users?.map(async (user: any) => {
      const newRoles = [];
      user?.roles?.includes("ROLE_COLLAB") && newRoles.push("ROLE_COLLAB");
      roles.rh && newRoles.push("ROLE_RH");
      roles.manager && newRoles.push("ROLE_MANAGER");
      roles.admin && newRoles.push("ROLE_ADMIN");
      roles.adminRh && newRoles.push("ROLE_ADMINRH");
      roles.recruiter && newRoles.push("ROLE_RECRUITER");
      roles.adminRecruiter && newRoles.push("ROLE_ADMINRECRUITER");
      roles.advisor && newRoles.push("ROLE_ADVISOR");
      roles.adminAdvisor && newRoles.push("ROLE_ADMINADVISOR");
      calls.push(UsersAPI.put(user.userId ?? userId, { roles: newRoles }));
    });
    Promise.all(calls)
      .then((res: any) => {
        reloadCall();
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setRoles({
      rh: false,
      manager: false,
      admin: false,
      adminRh: false,
      recruiter: false,
      adminRecruiter: false,
      advisor: false,
      adminAdvisor: false,
    });
    close();
  };

  useEffect(() => {
    users &&
      setRoles({
        rh: users?.length === 1 ? users?.[0]?.roles.includes("ROLE_RH") : false,
        manager:
          users?.length === 1
            ? users?.[0]?.roles.includes("ROLE_MANAGER")
            : false,
        admin:
          users?.length === 1
            ? users?.[0]?.roles.includes("ROLE_ADMIN")
            : false,
        adminRh:
          users?.length === 1
            ? users?.[0]?.roles.includes("ROLE_ADMINRH")
            : false,
        recruiter:
          users?.length === 1
            ? users?.[0]?.roles.includes("ROLE_RECRUITER")
            : false,
        adminRecruiter:
          users?.length === 1
            ? users?.[0]?.roles.includes("ROLE_RECRUITER")
            : false,
        advisor:
          users?.length === 1
            ? users?.[0]?.roles.includes("ROLE_ADVISOR")
            : false,
        adminAdvisor:
          users?.length === 1
            ? users?.[0]?.roles.includes("ROLE_ADMINADVISOR")
            : false,
      });
  }, [users]);

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "400px" },
        }}
      >
        <Box sx={{ width: "100%", display: "flex" }}>
          <IconButton
            onClick={handleClose}
            sx={{ ml: "auto", mt: 1, mr: 1 }}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent sx={{ mt: -6 }}>
          <Typography variant="h4">{t("admin.params.roles.handle")}</Typography>
          <FormGroup sx={{ mt: 3 }}>
            {context === "recruiter" ? (
              <>
                <FormControlLabel
                  disabled
                  value={roles.recruiter}
                  onChange={(e: any) =>
                    setRoles({ ...roles, recruiter: !roles.recruiter })
                  }
                  control={<Checkbox checked={roles.recruiter} disabled />}
                  label={t("roles.recruiter")}
                />
                <FormControlLabel
                  value={roles.adminRecruiter}
                  onChange={(e: any) =>
                    setRoles({
                      ...roles,
                      adminRecruiter: !roles.adminRecruiter,
                    })
                  }
                  control={<Checkbox checked={roles.adminRecruiter} />}
                  label={t("roles.adminrecruiter")}
                />
              </>
            ) : context === "advisor" ? (
              <>
                <FormControlLabel
                  disabled
                  value={roles.advisor}
                  onChange={(e: any) =>
                    setRoles({ ...roles, advisor: !roles.advisor })
                  }
                  control={<Checkbox checked={roles.advisor} disabled />}
                  label={t("roles.advisor")}
                />
                <FormControlLabel
                  value={roles.adminAdvisor}
                  onChange={(e: any) =>
                    setRoles({ ...roles, adminAdvisor: !roles.adminAdvisor })
                  }
                  control={<Checkbox checked={roles.adminAdvisor} />}
                  label={t("roles.adminadvisor")}
                />
              </>
            ) : (
              <>
                <FormControlLabel
                  value={roles.rh}
                  onChange={(e: any) => setRoles({ ...roles, rh: !roles.rh })}
                  control={<Checkbox checked={roles.rh} />}
                  label={t("roles.rh")}
                />
                <FormControlLabel
                  value={roles.manager}
                  onChange={(e: any) =>
                    setRoles({ ...roles, manager: !roles.manager })
                  }
                  control={<Checkbox checked={roles.manager} />}
                  label={t("roles.manager")}
                />
                <FormControlLabel
                  value={roles.admin}
                  onChange={(e: any) =>
                    setRoles({ ...roles, admin: !roles.admin })
                  }
                  control={<Checkbox checked={roles.admin} />}
                  label={t("roles.admin")}
                />
                <FormControlLabel
                  value={roles.adminRh}
                  onChange={(e: any) =>
                    setRoles({ ...roles, adminRh: !roles.adminRh })
                  }
                  control={<Checkbox checked={roles.adminRh} />}
                  label={t("roles.adminrh")}
                />
              </>
            )}
          </FormGroup>
          {context === "admin" && (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              sx={{ mt: 4 }}
            >
              <Button
                onClick={() => deleteUser()}
                disabled={loading}
                variant="contained"
                color="error"
                sx={{ height: "40px", width: "100%", m: 1 }}
              >
                <Typography variant="body2">
                  {t("admin.params.roles.delete")}
                </Typography>
              </Button>
            </Box>
          )}
          <Box display="flex" justifyContent="space-around" width="100%">
            <Button
              sx={{ height: "40px", width: "150px", m: 1 }}
              onClick={() => handleClose()}
              variant="outlined"
            >
              <Typography variant="body2">{t("btn.cancel")}</Typography>
            </Button>
            <Button
              disabled={loading}
              sx={{ height: "40px", width: "150px", m: 1 }}
              onClick={() => handleSave()}
              variant="contained"
            >
              <Typography variant="body2">{t("btn.save")}</Typography>
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AdminUserRoleModal;
