import { IconButton } from "@mui/material";
import React, { useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AdvisorDeleteModal from "./AdvisorDeleteModal";
import http from "../../../services/http/http";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { concat } from "rxjs";

const DeleteAdvisorButton: React.FC<any> = ({
  personId,
  reload,
  fullName,
  recruiterId,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("common");

  const [loading, setLoading] = useState(false);

  const deleteUser = (toId: string) => {
    setLoading(true);

    concat(
      http.post("recruiters/transfer", {
        from: recruiterId,
        to: toId,
      }),
      http.delete(`people/${personId}`)
    ).subscribe({
      next: (res) => {
        setLoading(false);
        reload((c: number) => c + 1);
      },
      error: (err) => {
        toast.error(t("errors.error_append"));
        setLoading(false);
      },
    });
  };

  return (
    <div>
      <IconButton onClick={setOpen.bind(this, true)} color="error">
        <DeleteForeverIcon />
      </IconButton>
      <AdvisorDeleteModal
        fullName={fullName}
        recruiterId={recruiterId}
        open={open}
        close={setOpen.bind(this, false)}
        deleteUser={deleteUser}
        loading={loading}
      />
    </div>
  );
};

export default DeleteAdvisorButton;
