import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Typography, Box } from "@mui/material";

const DemoFinished = () => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <MailOutlineIcon sx={{ fontSize: "60px" }} />
      </Box>
      <Typography textAlign="center">
        Veuillez confirmer votre demande en cliquant sur le lien dans l'email
        que vous allez recevoir.
      </Typography>
    </div>
  );
};

export default DemoFinished;
