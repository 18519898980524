import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const AdminDisplayRecruiter: React.FC<any> = ({param = {}, handleChanges}) => {
    const {t} = useTranslation("common");
    const [value, setValue] = useState(param.MERGE ?? false)

    React.useEffect(() => {
        setValue(param.MERGE ?? false);
    }, [param]);

    return (
        <div>
            <FormGroup>
                <Typography variant="h4">{t("admin.params.recruiter.pool")}</Typography>
                <FormControlLabel
                    control={<Checkbox onChange={(e: any) =>
                        handleChanges({...param, MERGE: e.target.checked})
                    } checked={value}/>}
                    label={t("admin.params.recruiter.merge")}
                />
            </FormGroup>
        </div>
    );
};

export default AdminDisplayRecruiter;
