import { Box, Rating } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ExperienceRating: React.FC<any> = ({ onChange, value }) => {
  const { t } = useTranslation("common");
  const [hover, setHover] = React.useState(-1);

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: number | null
  ) => {
    if (newValue === null) return;
    onChange(newValue);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Rating
        name="simple-controlled"
        value={value}
        onChange={handleChange}
        max={4}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      />
      {value !== null && (
        <Box sx={{ ml: 2 }}>
          {t(`work.rating.${hover !== -1 ? hover : value}`)}
        </Box>
      )}
    </Box>
  );
};

export default ExperienceRating;
