import { Paper, Stack, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import IncCompareAdaptation from "./IncCompareAdaptation";
import IncIrritantsList from "./IncIrritantsList";
import IncGroupCompare from "./IncGroupCompare";
import IncMotivationsCompare from "./IncMotivationsCompare";
import { useTranslation } from "react-i18next";
import IncCompareAdaptationDescription from "./IncCompareAdaptationDescription";
import { useSelector } from "react-redux";

const BoxIcon = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: theme.shape.borderRadiusXs,
  flexShrink: 0,
  width: "36px",
  height: "36px",
  backgroundColor: theme.palette.purple[100],
}));

const Title = styled(Typography)(({ theme }: any) => ({
  marginLeft: "8px",
  backgroundColor: "#F9F2FC",
  padding: "4px 13px",
  borderRadius: theme.shape.borerRadius6,
}));

const IncWorkCompare: React.FC<{
  results: any;
  noCard?: boolean;
  workResult?: any;
}> = ({ results, noCard, workResult }) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);

  return (
    <Paper
      elevation={noCard ? 0 : 3}
      sx={{ padding: "20px", borderRadius: "23px" }}
    >
      <Box display="flex" justifyContent="center">
        <img src="/static/illustrations/yoga_illu.svg" alt="" />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 4.25 }}
      >
        <BoxIcon>
          <img src="/static/icons/inc_logo.svg" width="25" alt="inc logo" />
        </BoxIcon>
        <Title variant="h5">
          {context === "recruiter"
            ? t("recruiter.campaign.matching_inc")
            : t("work_sheet.inc_matching")}
        </Title>
      </Box>

      <Stack
        spacing={10}
        sx={{
          mr: "auto",
          ml: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: "650px" }}>
          <IncMotivationsCompare
            score={results.motivation}
            results={results.motivation_values}
          />
        </Box>
        <Box sx={{ maxWidth: "950px" }}>
          <IncCompareAdaptation
            score={results.adaptability}
            results={results.adaptability_values}
          />
          <IncCompareAdaptationDescription
            workResult={results?.adaptability_values}
          />
        </Box>
        <Box sx={{ maxWidth: "950px" }}>
          <IncIrritantsList
            work
            score={results.resilience}
            light={results.resilience_values
              ?.filter((el: any) => el.work_value === 10)
              ?.sort((a: any, b: any) => a?.difference - b?.difference)}
            neutral={results.resilience_values
              ?.filter((el: any) => el.work_value === 30)
              ?.sort((a: any, b: any) => a?.difference - b?.difference)}
            important={results.resilience_values
              ?.filter((el: any) => el.work_value >= 60)
              ?.sort((a: any, b: any) => a?.difference - b?.difference)}
          />
        </Box>
        <Box sx={{ maxWidth: "950px" }}>
          <IncGroupCompare
            score={results.group}
            results={results.group_values}
            workResult={workResult?.dym_comp?.values}
          />
        </Box>
      </Stack>
    </Paper>
  );
};

export default IncWorkCompare;
