import React, { useEffect } from "react";
import "./App.scss";
import BaseRoutes from "./routes/baseRoutes";
import DefaultRoutes from "./routes/routes";
import { styled } from "@mui/styles";
import AdvisorRoutes from "./routes/routes_advisor";
import RecruiterRoutes from "./routes/routes_recruiter";
import CreRoutes from "./routes/routes_cre";
import CandidateRoutes from "./routes/routes_candidate";
import AdminRoutes from "./routes/adminRoutes";
import { useDispatch, useSelector } from "react-redux";
import useInit from "./hooks/initHook";
import GlobalSplashscreen from "./components/splashscreen/GlobalSplashscreen";
import useGlobalSplashscreen from "./components/splashscreen/useGlobalSpashscreen";
import { Provider, ErrorBoundary } from "@rollbar/react";
import rollbarConfig from "./rollbarConfig";
import OnboardingRoutes from "./routes/onBoardingRoutes";
import useConversations from "./hooks/useConversations";
import RhRoutes from "./routes/rh_routes";
import { IconProps, CloseButtonProps, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { IconButton } from "@mui/material";
import { setRedirectLoginUrl } from "./redux/actions/authActions";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import PtwPopup from "./components/common/ptw_popup/PtwPopup";
import DispatchInfosModal from "./components/dispatchInfos/DispatchInfosModal";
import PasswordRoutes from "./routes/pwdRoutes";
import ExpiredRoutes from "./routes/ExpiredRoutes";
import ThirdPartyRoutes from "./routes/thirdPartyRoutes";
import RgpdRoute from "./routes/rgpdRoute";

const StyledToastContainer = styled(ToastContainer)(({ theme }: any) => ({
  top: "66px !important",
  "& .Toastify__toast-theme--light": {
    borderRadius: theme.shape.borderRadius8,
    width: "288px",
  },
  "& .Toastify__toast-body": {
    gap: "24px",
    color: theme.palette.secondary.main,
  },
}));

const Icon = styled(({ icon, ...props }: any) => icon(props))(
  ({ theme, type }: any) => ({
    width: "40px",
    height: "40px",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius12,
    backgroundColor: theme.palette[type][500_16],
    color: theme.palette[type][500],
  })
);

const ExitButton = styled(IconButton)({
  width: "30px",
  height: "30px",
  alignSelf: "center",
});

const App: React.FC = (props: any) => {
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const person_id = useSelector((state: any) => state.user?.person_id);
  const hasRGPD = useSelector((state: any) => state.user?.rgpd);
  const requirePwdReset = useSelector(
    (state: any) => state.user?.requirePwdReset
  );
  const context = useSelector((state: any) => state.auth.context);
  const onboarding = useSelector((state: any) => state.user?.onboarding);
  const ptwPopup = useSelector((state: any) => state.ptwPopup);
  const companyExpired = useSelector(
    (state: any) => state?.user?.companyExpired
  );
  const isGom = process.env.REACT_APP_GOM === "true";

  const home = useSelector((state: any) => state.home);
  const favicon = home?.favicon?.contentUrl ?? null;

  const icons = (props: any) => ({
    error: <ErrorRoundedIcon {...props} />,
    success: <CheckCircleRoundedIcon {...props} />,
    warning: <WarningRoundedIcon {...props} />,
    info: <InfoRoundedIcon {...props} />,
  });

  const handleToastIcon = ({ type }: IconProps) => {
    if (type !== "default") {
      return (
        <Icon
          icon={(props: any) => icons(props)[type]}
          type={type === "success" ? "green" : type}
        />
      );
    }
  };

  const CloseButton = ({ closeToast }: CloseButtonProps) => (
    <ExitButton aria-label="close notif" onClick={closeToast}>
      <ClearRoundedIcon
        sx={{
          width: "20px",
          height: "20px",
        }}
      />
    </ExitButton>
  );

  // Init app
  const loading = useInit();
  const dispatch = useDispatch();

  useConversations();

  useGlobalSplashscreen(loading);

  const getContextRouting = () => {
    if (isLogged && !hasRGPD) {
      return <RgpdRoute />;
    }
    if (companyExpired) {
      return <ExpiredRoutes />;
    }
    if (!isLogged && !person_id) {
      return <BaseRoutes />;
    }
    if (isLogged && onboarding < 100) {
      return <OnboardingRoutes />;
    }

    if (isLogged && requirePwdReset) {
      return <PasswordRoutes />;
    }

    return context === "collab" || context === "benef" ? (
      <DefaultRoutes />
    ) : context === "candidate" ? (
      <CandidateRoutes />
    ) : context === "advisor" ? (
      <AdvisorRoutes />
    ) : context === "third_party" ? (
      <ThirdPartyRoutes />
    ) : context === "recruiter" ? (
      <RecruiterRoutes />
    ) : context === "cre" ? (
      <CreRoutes />
    ) : context === "admin" ? (
      <AdminRoutes />
    ) : context === "rh" ? (
      <>
        <RhRoutes />
      </>
    ) : context === "manager" ? (
      <div data-context="manager">
        <RhRoutes />
      </div>
    ) : (
      <DefaultRoutes />
    );
  };

  window.onfocus = function (ev) {
    axios
      .get("is_logged")
      .then(() => {})
      .catch((err) => {});
  };

  useEffect(() => {
    window.location.pathname.substring(0, 4) === "/app" &&
      dispatch(setRedirectLoginUrl(window.location.pathname));
    isLogged && person_id && dispatch(setRedirectLoginUrl(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, person_id]);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <GlobalSplashscreen />
        {!loading && (
          <Helmet>
            {home && (
              <>
                {favicon && <link id="favicon" rel="icon" href={favicon} />}
                {favicon && (
                  <link
                    id="favicon-safari"
                    rel="apple-touch-icon"
                    href={favicon}
                  />
                )}
              </>
            )}
          </Helmet>
        )}
        {!(loading || (!loading && isLogged && (!person_id || !context))) &&
          getContextRouting()}
        <StyledToastContainer
          icon={handleToastIcon}
          hideProgressBar
          autoClose={5000}
          closeButton={CloseButton}
        />

        {isGom && (
          <>
            {ptwPopup.active && (
              <>
                <PtwPopup open={ptwPopup.open} />
              </>
            )}

            <DispatchInfosModal />
          </>
        )}
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
