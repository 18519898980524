import * as React from "react";
import { styled } from "@mui/material/styles";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import customIcons from "../../_utils/fulfillmentIcons";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const FullfilmentRating: React.FC<any> = ({ onChange, value }) => {
  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: number | null
  ) => {
    if (newValue === null) return;
    onChange?.(newValue);
  };

  return (
    <StyledRating
      name="highlight-selected-only"
      readOnly={!Boolean(onChange)}
      value={value}
      onChange={handleChange}
      IconContainerComponent={IconContainer}
      getLabelText={(value: number) => customIcons[value].label}
      highlightSelectedOnly
    />
  );
};

export default FullfilmentRating;
