import React from "react";
import {
  Card,
  Typography,
  Box,
  CardContent,
  Grid,
  ButtonGroup,
  Button,
  Switch,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AreaChart from "../common/chart/AreaChart";
import SingleColumnChart from "../common/chart/SingleColumnChart";
import HomeStatsAdvisorSkeleton from "../skeletons/advisor/HomeStatsAdvisorSkeleton";
import { shallowEqual, useSelector } from "react-redux";

const SelectionButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "selected",
})(({ theme, selected }: any) =>
  selected
    ? {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        },
      }
    : {}
);

const HomeStatsAdvisor: React.FC<any> = ({
  data,
  loading,
  row = false,
}: any) => {
  const { t } = useTranslation("common");
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const months = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];

  const [connectRange, setConnectRange] = useState("month");
  const [folderRange, setFolderRange] = useState("month");

  const [etpStats, setEtpStats] = useState<boolean>(row ? true : false);

  const getSum = (arr: any) => {
    return arr?.inc + arr?.b5 + arr?.cf;
  };

  return loading ? (
    <HomeStatsAdvisorSkeleton row={row} />
  ) : data ? (
    <>
      <Box display="flex" alignItems="center" flexWrap="wrap" height="38px">
        <Typography variant="body1" fontWeight="bold">
          {t(row ? "admin.graph.title" : "advisor.stats")}
        </Typography>
        {tokenData.roles.includes("ROLE_ADMINADVISOR") && !row && (
          <Box display="flex" alignItems="center" sx={{ ml: "auto" }}>
            <Typography variant="body2"> {t("advisor.my_data")}</Typography>
            <Switch
              defaultChecked={etpStats}
              onChange={() => {
                setEtpStats(!etpStats);
              }}
            />
            <Typography variant="body2">{t("advisor.company_data")}</Typography>
          </Box>
        )}
      </Box>
      <Grid container display="flex">
        <Grid
          item
          xl={row ? 12 : 6}
          lg={row ? 12 : 6}
          md={row ? 12 : 6}
          sm={12}
          xs={12}
        >
          <Card sx={{ m: 1 }}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{ opacity: "0.6" }}
                  >
                    {t("stats.connection")}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h4" sx={{ mr: 1 }}>
                      {connectRange === "month"
                        ? etpStats
                          ? data?.months?.connections_company?.total
                          : data?.months?.connections_person?.total
                        : etpStats
                        ? data?.days?.connections_company?.total
                        : data?.days?.connections_person?.total}
                    </Typography>
                    <img
                      src={`/static/icons/Ico/chps/${
                        (
                          connectRange === "month"
                            ? etpStats
                              ? data?.months?.connections_company?.evolution ===
                                -1
                              : data?.months?.connections_person?.evolution ===
                                -1
                            : etpStats
                            ? data?.days?.connections_company?.evolution === -1
                            : data?.days?.connections_person?.evolution === -1
                        )
                          ? "down"
                          : (
                              connectRange === "month"
                                ? etpStats
                                  ? data?.months?.connections_company
                                      ?.evolution === 1
                                  : data?.months?.connections_person
                                      ?.evolution === 1
                                : etpStats
                                ? data?.days?.connections_company?.evolution ===
                                  1
                                : data?.days?.connections_person?.evolution ===
                                  1
                            )
                          ? "up"
                          : "equal"
                      }.svg`}
                      alt="operator"
                      width="14"
                      height="14"
                    />
                  </Box>
                </Box>
                <ButtonGroup
                  sx={{ ml: "auto" }}
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <SelectionButton
                    selected={connectRange === "month"}
                    onClick={() => setConnectRange("month")}
                  >
                    {t("filters.month")}{" "}
                  </SelectionButton>
                  <SelectionButton
                    selected={connectRange === "day"}
                    onClick={() => setConnectRange("day")}
                  >
                    {t("filters.day")}
                  </SelectionButton>
                </ButtonGroup>
              </Box>
              <SingleColumnChart
                data={
                  connectRange === "month"
                    ? etpStats
                      ? data?.months?.connections_company?.data
                      : data?.months?.connections_person?.data
                    : etpStats
                    ? data?.days?.connections_company?.data
                    : data?.days?.connections_person?.data
                }
                labels={
                  connectRange === "month"
                    ? data?.months?.labels.map((el: number) => months[el - 1])
                    : data?.days?.labels
                }
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xl={row ? 12 : 6}
          lg={row ? 12 : 6}
          md={row ? 12 : 6}
          sm={12}
          xs={12}
        >
          <Card sx={{ m: 1 }}>
            <CardContent>
              <Typography
                variant="body2"
                fontWeight="bold"
                sx={{ opacity: "0.6" }}
              >
                {t("stats.create_tests")}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="h4" sx={{ mr: 1 }}>
                  {folderRange === "month"
                    ? getSum(data?.months?.tests?.total)
                    : getSum(data?.days?.tests?.total)}
                </Typography>
                <img
                  src={`/static/icons/Ico/chps/${
                    (
                      folderRange === "month"
                        ? data?.months?.tests?.evolution === -1
                        : data?.days?.tests?.evolution === -1
                    )
                      ? "down"
                      : (
                          folderRange === "month"
                            ? data?.months?.tests?.evolution === 1
                            : data?.days?.tests?.evolution === 1
                        )
                      ? "up"
                      : "equal"
                  }.svg`}
                  alt="operator"
                  width="14"
                  height="14"
                />
                <ButtonGroup
                  sx={{ ml: "auto" }}
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <SelectionButton
                    selected={folderRange === "month"}
                    onClick={() => setFolderRange("month")}
                  >
                    {t("filters.month")}{" "}
                  </SelectionButton>
                  <SelectionButton
                    selected={folderRange === "day"}
                    onClick={() => setFolderRange("day")}
                  >
                    {t("filters.day")}
                  </SelectionButton>
                </ButtonGroup>
              </Box>
              <AreaChart
                data={
                  folderRange === "month"
                    ? data?.months?.tests?.data
                    : data?.days?.tests?.data
                }
                labels={
                  folderRange === "month"
                    ? data?.months?.labels.map((el: number) => months[el - 1])
                    : data?.days?.labels
                }
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

export default HomeStatsAdvisor;
