import React, { useState } from "react";
import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import FilterPopoverContainer from "../../common/chip_filter/FilterPopoverContainer";
import BottomFilterContent from "../../common/chip_filter/BottomFilterContent";

const filters = [
  { value: "asc", label: "work.filters.tension_asc" },
  { value: "desc", label: "work.filters.tension_desc" },
];

const WorkTensionFilter = ({ handleSort, preSelected }: any) => {
  const [close, setClose] = useState<any>(false);
  const [value, setValue] = useState<any>(preSelected ?? null);
  const theme: any = useTheme();
  const { t } = useTranslation("common");

  const returnTitleChip = () => {
    return value
      ? `${t(`${filters?.find((el: any) => el?.value === value)?.label}`)}`
      : t("work_sheet.voltage");
  };

  const handleSubmit = () => {
    handleSort(value);
    setClose(!close);
  };

  const handleClear = () => {
    setValue(null);
    handleSort(null);
    setClose(!close);
  };

  return (
    <>
      <FilterPopoverContainer
        title={returnTitleChip()}
        idPop="work_tension_popover"
        close={close}
        active={value !== null}
        content={
          <>
            {filters?.map((item: any) => (
              <MenuItem
                sx={{
                  backgroundColor:
                    value === item.value && theme.palette.primary[100],
                }}
                onClick={() => {
                  setValue(item?.value === value ? null : item.value);
                }}
                key={item.value}
                value={item.label}
              >
                <Checkbox checked={value === item.value} />
                <ListItemText primary={t(item.label)} />
              </MenuItem>
            ))}
            <BottomFilterContent
              handleSubmit={handleSubmit}
              handleClear={handleClear}
              disabled={!value}
            />
          </>
        }
      />
    </>
  );
};

export default WorkTensionFilter;
