import { Box, Divider, Pagination, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch";
import workCategoryAPI from "../../../../_api/_workCategoryAPI";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Loader from "../../../../Loader";
import { useTheme } from "@mui/styles";
import AdminCreateWorkCatModal from "./AdminCreateWorkCatModal";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import BigButton from "../../../common/BigButton";

const AdminWorksCategoryListParams = () => {
  const { t } = useTranslation("common");
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const theme: any = useTheme();
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [reload, setReload] = useState(0);
  const [selectedSkillWork, setSelectedSkillWork] = useState(null);
  const [openWorkCatModal, setOpenWorkCatModal] = useState(false);
  const { data, loading } = useFetch(
    workCategoryAPI.getById("tree", {
      page,
      itemsPerPage: 12,
      reload,
      company: `/api/companies/${tokenData.company_id}`,
    })
  );

  useEffect(() => {
    data?.["hydra:member"] && setList(data?.["hydra:member"]);
  }, [data]);

  useEffect(() => {
    if (!openWorkCatModal) setSelectedSkillWork(null);
  }, [openWorkCatModal]);

  return (
    <div>
      <Box sx={{ mb: 2 }}>
        <Typography textAlign="center" variant="h4">
          {t("admin.params.works_cat.title")}
        </Typography>
        {/* <Button
          variant="contained"
          sx={{ height: "35px", ml: "auto" }}
          onClick={() => setOpenWorkCatModal(true)}
        >
          <Typography variant="body2">
            {t("admin.params.works_cat.create")}
          </Typography>
        </Button> */}
      </Box>

      <Box sx={{ mb: 2 }}>
        <BigButton onClick={() => setOpenWorkCatModal(true)}>
          {t("admin.params.works_cat.create")}
        </BigButton>
      </Box>

      {loading ? (
        <Loader />
      ) : list?.length === 0 ? (
        <Typography textAlign="center" variant="body1" sx={{ mt: 5, mb: 5 }}>
          {t("admin.params.works_cat.no_works_cat")}
        </Typography>
      ) : (
        list?.map((skillCat: any, index: number) => (
          <Box key={skillCat?.id}>
            <Box
              onClick={() => {
                setSelectedSkillWork(skillCat);
                setOpenWorkCatModal(true);
              }}
              display="flex"
              alignItems="center"
              sx={{
                pt: 1.5,
                pb: 1.5,
                pl: 0.5,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: theme.palette.background.neutral,
                  "& .edit_btn": {
                    opacity: 1,
                  },
                },
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                {skillCat.label}
              </Typography>
              <Typography
                className="edit_btn"
                sx={{
                  ml: "auto",
                  pr: 0.5,
                  opacity: 0,
                  display: "flex",
                  alignItems: "center",
                }}
                variant="body2"
                fontWeight="bold"
                color="primary"
              >
                {t("btn.edit")}
                <ArrowForwardIosIcon sx={{ fontSize: "16px", ml: 1 }} />
              </Typography>
            </Box>
            <Box>
              {skillCat?.children?.map((child: any) => (
                <Box
                  key={child.id}
                  display="flex"
                  alignItems="center"
                  sx={{
                    pt: 1.5,
                    pb: 1.5,
                    pl: 0.5,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: theme.palette.background.neutral,
                      "& .edit_btn": {
                        opacity: 1,
                      },
                    },
                  }}
                >
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {child.label}
                  </Typography>
                </Box>
              ))}
            </Box>
            {index + 1 < list.length && <Divider />}
          </Box>
        ))
      )}
      {data?.["hydra:totalItems"] > 12 && (
        <Box display="center" justifyContent="center" sx={{ mt: 2 }}>
          <Pagination
            count={Math.ceil(data?.["hydra:totalItems"] / 12)}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Box>
      )}
      <AdminCreateWorkCatModal
        open={openWorkCatModal}
        close={() => setOpenWorkCatModal(false)}
        reload={() => setReload(reload + 1)}
        skillWork={selectedSkillWork}
      />
    </div>
  );
};

export default AdminWorksCategoryListParams;
