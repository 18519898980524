import { Box, Paper, Typography, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
// import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AccessibleRoundedIcon from "@mui/icons-material/AccessibleRounded";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import PinDropRoundedIcon from "@mui/icons-material/PinDropRounded";
// import BookmarkHeartComponent from "../common/BookmarkHeartComponent";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { workTraining } from "../../@types/workTrainingType";
import { capitalizeFirstLetter } from "../../Utils";

const TrainingItem: any = styled(Paper)(({ theme, trainingpage }: any) => ({
  width: "335px",
  height: "fit-content",
  padding: trainingpage !== "true" ? "20px" : "0",
  margin: trainingpage !== "true" ? theme.spacing(1.5) : 0,
  borderRadius: "9px",
  boxShadow:
    trainingpage !== "true" ? "0px 10px 60px #00000021 !important" : "none",
  cursor: trainingpage !== "true" ? "pointer" : "default",
  transition: "all 0.15s ease-in-out",
  "&:hover": {
    transform: trainingpage !== "true" ? "scale(1.02)" : "",
  },
}));

const InfoCtn = styled(Box)(({ theme }: any) => ({
  height: "fit-content",
  borderRadius: "9px",
  padding: "8px",
  backgroundColor: theme.palette.primary[500_8],
  marginTop: theme.spacing(2),
  width: "fit-content",
}));

const WorkTrainingItem: React.FC<{
  training?: workTraining;
  handleBookmark?: any;
  trainingPage?: boolean;
}> = ({ training, handleBookmark, trainingPage }) => {
  const { t } = useTranslation("common");
  const theme: any = useTheme();
  const navigate = useNavigate();

  const redirect = () => {
    navigate(`/app/trainings/${training?.["@id"]?.split("/")[3]}`);
  };
  return (
    <TrainingItem
      onClick={redirect}
      elevation={trainingPage ? 0 : 3}
      trainingpage={JSON.stringify(trainingPage)}
    >
      {!trainingPage && (
        <>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">
              {training?.intituleFormation?.value}
            </Typography>
            {/* <Box sx={{ ml: "auto" }}>
            <BookmarkHeartComponent
              id={training?.["@id"]}
              isBookmarked={training?.isBookmarked}
              reload={handleBookmark}
            />
            </Box> */}
          </Box>
          <Typography
            variant="body1"
            sx={{ maxWidth: "27ch", color: theme.palette.secondary[400] }}
          >
            Par {training?.organismeFormationResponsable?.nomOrganisme?.value}
          </Typography>
        </>
      )}
      {/* <InfoCtn sx={{ backgroundColor: theme.palette.green[500_8] }}>
        <Typography variant="body2" sx={{ color: theme.palette.green[500] }}>
          {t("trainings.available_seat", { nbr: 156 })}
        </Typography> 
      </InfoCtn>*/}
      <Box display="flex">
        {training?.action?.prixTotalTtc?.value ? (
          <InfoCtn>
            <Box display="flex" alignItems="center">
              <EuroRoundedIcon color="primary" sx={{ fontSize: "17px" }} />
              <Typography variant="body2" sx={{ ml: 0.75 }} color="primary">
                {training?.action?.prixTotalTtc?.value}
              </Typography>
            </Box>
          </InfoCtn>
        ) : (
          <></>
        )}
        <InfoCtn sx={{ ml: training?.action?.prixTotalTtc?.value ? 1 : 0 }}>
          <Box display="flex" alignItems="center">
            <UpdateRoundedIcon color="primary" sx={{ fontSize: "17px" }} />
            <Typography variant="body2" sx={{ ml: 0.75 }} color="primary">
              {training?.action?.nombreHeuresTotal?.value
                ? `${training?.action?.nombreHeuresTotal?.value}h`
                : "ND"}
            </Typography>
          </Box>
        </InfoCtn>
      </Box>
      <InfoCtn>
        <Box display="flex" alignItems="center">
          <PinDropRoundedIcon sx={{ fontSize: "17px" }} />
          <Typography variant="body2" sx={{ ml: 0.75 }} color="secondary">
            {training?.organismeFormationResponsable?.coordonneesOrganisme
              ?.coordonnees?.adresse?.ville?.value ?? "ND"}
          </Typography>
        </Box>
      </InfoCtn>
      <InfoCtn>
        <Box display="flex" alignItems="center">
          <AccessibleRoundedIcon sx={{ fontSize: "17px" }} />
          <Typography variant="body2" sx={{ ml: 0.75 }} color="secondary">
            {training?.action?.accesHandicapes?.value === "Oui"
              ? t("trainings.handi_ok")
              : training?.action?.accesHandicapes?.value === "Non"
              ? t("trainings.handi_bad")
              : training?.action?.accesHandicapes?.value
              ? capitalizeFirstLetter(training?.action?.accesHandicapes?.value)
              : t("trainings.handi_bad")}
          </Typography>
        </Box>
      </InfoCtn>
      {/* <InfoCtn>
        <Box display="flex" alignItems="center">
          <WorkOutlineOutlinedIcon sx={{ fontSize: "17px" }} />
          <Typography variant="body2" sx={{ ml: 0.75 }} color="secondary">
            CAP Boulanger
          </Typography>
        </Box>
      </InfoCtn> */}
    </TrainingItem>
  );
};

export default WorkTrainingItem;
