import { BehaviorSubject } from "rxjs";
import axios from "axios";
import recruiterCandidatesAPI from "../_api/_recruiterCandidatesAPI";
import recruiterCandidatAdvisorAPI from "../_api/_recruiterCandidateAdvisorAPI";

class chatService {
  static creatingConversation = false;
  static _creatingConversation = new BehaviorSubject(this.creatingConversation);
  static setCreatingConversation(value: boolean) {
    this.creatingConversation = value;
    this._creatingConversation.next(this.creatingConversation);
  }

  static convId = null;
  static _convId = new BehaviorSubject(this.convId);
  static setConvId(value: any) {
    this.convId = value;
    this._convId.next(this.convId);
  }

  static getColorByStatus(status: any): any {
    let fitColor = "";

    switch (status?.value) {
      case "connected":
        fitColor = "#76C00D"; //green
        break;

      case "busy":
        fitColor = "#bf8211"; //orange
        break;

      case "away":
        fitColor = "#cd0707"; //red
        break;

      default:
        fitColor = "#cd0707"; //red
      // throw new Error("Invalid connection state.value");
    }
    return fitColor;
  }

  static getColorByMembersStatus(convInfo: any, client_id: any): any {
    let stacks: any = [];
    // eslint-disable-next-line array-callback-return
    convInfo?.members.map((member: any) => {
      if (member?.id === client_id) return true; //continue equivalent
      let status: string = member?.connectionStatus?.value;

      stacks[status] === undefined
        ? (stacks[status] = 1)
        : (stacks[status] += 1);
    });

    if (stacks["away"] === 0 || stacks["away"] === undefined) return "#11bf17"; // all green
    if (stacks["connected"] === 0 || stacks["connected"] === undefined)
      return "#cd0707"; // all red
    return "#bf8211"; //mixed state => Orange
  }

  static findConversationByMembers(
    needle_members: any,
    conversations: any,
    client_id: any
  ): any {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function arraysEqual(a1: any, a2: any) {
      /* WARNING: arrays must not contain {objects} or behavior may be undefined */
      // eslint-disable-next-line eqeqeq
      return JSON.stringify(a1) == JSON.stringify(a2);
    }
    let output = "";

    needle_members = needle_members.map((n_member: any) => {
      return n_member.slice(-36);
    });
    needle_members.push(client_id);
    needle_members.sort();

    // eslint-disable-next-line array-callback-return
    conversations.map((c: any) => {
      let local_members = c?.members.map((member: any) => {
        return member.id;
      });

      local_members.sort();

      if (arraysEqual(local_members, needle_members)) {
        output = c.id;
      }
    });

    return output;
  }

  static findFirstNameById(needle_id: any, members: any) {
    let output = "";
    // eslint-disable-next-line array-callback-return
    members.map((member: any) => {
      if (member?.id === needle_id) output = member?.firstName;
    });
    if (output === "")
      throw new Error("Could not match person id with a firstName");
    return output;
  }

  static async getConversationCandidatsList(
    userContext: any,
    error: any = null
  ) {
    switch (userContext) {
      case "advisor":
        try {
          const res: any = await axios.get(
            recruiterCandidatesAPI.get({
              pagination: false,
              isArchived: false,
              order: "ASC",
            })
          );

          return res.data?.["hydra:member"];
        } catch (err) {
          console.error(
            "Error : chat autcomplete data loader ( ctx advisor ) ",
            err
          );
          error?.fct();
        }
        break;
      case "admin":
        console.log("chargement des person visible par les admin adv ( ctx )");
        break;
      case "mentors":
        console.log("chargement des person visible par les mentors ( ctx )");
        break;
      case "benef":
        try {
          const res: any = await axios.get(recruiterCandidatAdvisorAPI.get());
          return res.data?.["hydra:member"].map((e: any) => {
            e.candidate = e.recruiter.person;
            e.id = e.recruiter.person.id;
            delete e.recruiter;
            return e;
          });
        } catch (err) {
          error?.fct();
          console.error(
            "Error : chat autcomplete data loader ( ctx benef ) ",
            err
          );
        }
        break;
    }
  }

  static setConvImageByMember(el: any, client_id: any) {
    if (el?.members?.length !== 2) return el;
    // eslint-disable-next-line array-callback-return
    el?.members.map((member: any) => {
      if (member?.id === client_id) return true; //continue equivalent
      el.image = member?.image?.contentUrl ?? null;
    });

    return el;
  }
}

export default chatService;
