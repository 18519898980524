import React from "react";

const ParamsIcon: React.FC<{ active?: boolean }> = ({ active }) => {
  return (
    <svg
      id="settings_black_24dp_1_"
      data-name="settings_black_24dp(1)"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 17 17"
    >
      <path
        id="Tracé_12893"
        data-name="Tracé 12893"
        d="M0,0H17V17H0Z"
        fill="none"
      />
      <path
        id="Tracé_12894"
        data-name="Tracé 12894"
        d="M14.425,9.777a5.52,5.52,0,0,0,.05-.694,5.52,5.52,0,0,0-.05-.694L15.92,7.22A.357.357,0,0,0,16,6.767L14.588,4.316a.354.354,0,0,0-.312-.177.331.331,0,0,0-.12.021l-1.764.708a5.175,5.175,0,0,0-1.2-.694L10.926,2.3a.345.345,0,0,0-.347-.3H7.746a.345.345,0,0,0-.347.3L7.129,4.175a5.442,5.442,0,0,0-1.2.694L4.169,4.16a.4.4,0,0,0-.128-.021.351.351,0,0,0-.3.177L2.32,6.767A.349.349,0,0,0,2.4,7.22L3.9,8.389a5.617,5.617,0,0,0-.05.694,5.618,5.618,0,0,0,.05.694L2.4,10.946a.357.357,0,0,0-.085.453L3.736,13.85a.354.354,0,0,0,.312.177.331.331,0,0,0,.12-.021L5.932,13.3a5.175,5.175,0,0,0,1.2.694L7.4,15.869a.345.345,0,0,0,.347.3h2.833a.345.345,0,0,0,.347-.3l.269-1.877a5.442,5.442,0,0,0,1.2-.694l1.764.708a.4.4,0,0,0,.128.021.351.351,0,0,0,.3-.177L16,11.4a.357.357,0,0,0-.085-.453Zm-1.4-1.211a3.784,3.784,0,0,1,.035.517c0,.149-.014.3-.035.517l-.1.8.63.5.765.6-.5.857-.9-.361-.737-.3-.637.482a4.148,4.148,0,0,1-.885.517l-.751.3-.113.8-.142.956H8.666l-.135-.956-.113-.8-.751-.3a4.019,4.019,0,0,1-.871-.5l-.645-.5-.751.3-.9.361-.5-.857.765-.6.63-.5-.1-.8c-.021-.22-.035-.382-.035-.524s.014-.3.035-.517l.1-.8-.63-.5-.765-.6.5-.857.9.361.737.3L6.775,6a4.148,4.148,0,0,1,.885-.517l.751-.3.113-.8.142-.956h.985l.135.956.113.8.751.3a4.019,4.019,0,0,1,.871.5l.645.5.751-.3.9-.361.5.857-.758.6-.63.5.1.8ZM9.162,6.25A2.833,2.833,0,1,0,12,9.083,2.833,2.833,0,0,0,9.162,6.25Zm0,4.25a1.417,1.417,0,1,1,1.417-1.417A1.421,1.421,0,0,1,9.162,10.5Z"
        transform="translate(-0.662 -0.583)"
        fill={active ? "#4C6FFF" : "#adb6c7"}
      />
    </svg>
  );
};

export default ParamsIcon;
