import { Box, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CardIllustration from "../components/common/CardIllustration";
import Page from "../components/Page";
import WorkTrainingFiltersPage from "../components/work_training/WorkTrainingFiltersPage";
import WorkTrainingList from "../components/work_training/WorkTrainingList";
import WorkTrainingListFilters from "../components/work_training/WorkTrainingListFilters";

const baseValue = {
  page: 1,
  location: null,
  range: 125,
  type: null,
  needle: "",
  category: "",
  order: "relevance",
  reload: 0,
  certif: false,
  indemn: false,
  price: [0, 20000],
  duration: [0, 3000],
  levels: [],
  dateStart: "",
  dateEnd: "",
};

const WorkTrainingsPage = () => {
  const dlUrl = process.env.REACT_APP_WORK_TRAINING_URL;
  const { t }: any = useTranslation("common");
  const navigate = useNavigate();
  const location: any = useLocation();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [firstCall, setFirstCall] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [httpParams, setHttpParams] = useState<any>(
    location?.state?.workTrainingsHttpParams
      ? { ...location?.state?.workTrainingsHttpParams, page: 1 }
      : baseValue
  );

  const returnDate = (date: any) => {
    return `${date.getFullYear()}${
      date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()
    }${date.getDay() < 10 ? `0${date.getDay()}` : date.getDay()}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let levelsParams = "";
    httpParams?.levels?.map(
      (level: number) =>
        (levelsParams = levelsParams + `&codeNiveauSortie.value[]=${level}`)
    );
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetch(
          `${dlUrl}?page=${httpParams?.page}${
            httpParams?.needle?.length > 0
              ? `&intituleFormation.valueSearch=${httpParams?.needle}`
              : ""
          }&action.modalitesEnseignement.value=${httpParams?.type}&order[${
            httpParams?.order
          }]=asc${httpParams?.certif ? `&certifiante.value=1` : ""}${
            httpParams?.indemn
              ? `&action.priseEnChargeFraisPossible.value=1`
              : ""
          }&action.prixTotalTtc.value[between]=${
            httpParams?.price[0] + ".." + httpParams?.price[1]
          }&action.nombreHeuresTotal.value[between]=${
            httpParams?.duration[0] + ".." + httpParams?.duration[1]
          }${
            httpParams?.category
              ? `&domaineFormation.rome.value[]=${httpParams?.category?.code}`
              : ""
          }${
            httpParams?.location && httpParams?.type === 0
              ? `&location[latitude]=${
                  httpParams?.location?.center?.[1]
                }&location[longitude]=${
                  httpParams?.location?.center?.[0]
                }&distance=${httpParams?.range * 1000}`
              : ""
          }${levelsParams}${
            httpParams?.dateStart
              ? `&action.session.periode.debut.value=${returnDate(
                  httpParams.dateStart
                )}`
              : ""
          }${
            httpParams?.dateEnd
              ? `&action.session.periode.fin.value=${returnDate(
                  httpParams.dateEnd
                )}`
              : ""
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/ld+json",
              Accept: "application/ld+json",
              authorization:
                "9B0917DAFEAAE1932DC22BE55F23D631E0344CE47E761D88598703300097131A",
            },
          }
        );
        try {
          const trainingsData = await res.json();
          setData(
            httpParams?.page === 1
              ? trainingsData?.["hydra:member"]
              : [...data, ...trainingsData?.["hydra:member"]]
          );
          setTotalItems(trainingsData?.["hydra:totalItems"]);
          setFirstCall(true);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    httpParams?.type !== null && httpParams?.type >= 0 && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpParams]);

  const setNavigationState = (params: any) => {
    navigate(`/app/trainings/list`, {
      state: {
        workTrainingsHttpParams: params,
      },
    });
  };

  const handleParams = (params: any) => {
    if (params !== httpParams) {
      setHttpParams(params);
      setNavigationState(params);
    }
  };

  return (
    <Page title={t("trainings.page")}>
      <Container sx={{ pt: 10, pb: 5 }}>
        <CardIllustration type="training" />
        <WorkTrainingListFilters
          httpParams={httpParams}
          setHttpParams={handleParams}
          handleClear={() => setFirstCall(false)}
          loading={loading}
          baseValue={baseValue}
        />
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            justifyContent: { xs: "center", md: "auto" },
          }}
        >
          {firstCall &&
            (httpParams?.type === 0
              ? httpParams?.location && httpParams?.needle
              : httpParams?.type === 2 && httpParams?.needle) && (
              <>
                <Box
                  sx={{
                    display: { xs: "none", md: "block" },
                    mt: -5,
                  }}
                >
                  <WorkTrainingFiltersPage
                    httpParams={httpParams}
                    setHttpParams={handleParams}
                    totalItems={totalItems}
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    justifyContent: "center",
                  }}
                >
                  <WorkTrainingFiltersPage
                    httpParams={httpParams}
                    setHttpParams={handleParams}
                    totalItems={totalItems}
                    mobileScreen
                  />
                </Box>
              </>
            )}
          <Box
            display="flex"
            justifyContent="center"
            sx={{ width: "100%", mt: { xs: 0, md: data?.length > 1 ? -4 : 2 } }}
          >
            <WorkTrainingList
              handleParams={(e: any) => {
                setHttpParams(e);
                setNavigationState(e);
              }}
              httpParams={httpParams}
              total={totalItems}
              list={data}
              loading={loading}
              firstCall={firstCall}
            />
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default WorkTrainingsPage;
