import { setTableItemsPerPagePreferences } from "./preferencesActons";

export const SET_USER = "SET_USER";
export const SET_PROFILE_PICTURE = "SET_PROFILE_PICTURE";

export const setUser = (payload: any) => {
  return {
    type: SET_USER,
    payload,
  };
};

export const handleUser = (payload: any) => {
  return (dispatch: any, getState: any) => {
    const { home } = getState();
    const webappUrl = home?.paramjson?.webappUrl;
    dispatch(setUser({ ...payload, webappUrl }));
    dispatch(setTableItemsPerPagePreferences());
  };
};

export const setUserPartial = (partial: any = {}) => {
  return (dispatch: any, getState: any) => {
    const { user } = getState();
    dispatch(setUser({ ...user, ...partial }));
  };
};

export const setProfilePicture = (url: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(setUser({ ...getState().user, profilePicture: url }));
  };
};
