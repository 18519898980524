import { Button } from "@mui/material";
import React from "react";

const LogoutModalButton: React.FC<{
  onClick: VoidFunction;
  variant: "text" | "outlined" | "contained" | undefined;
  text: string;
  disabled?: boolean;
}> = ({ onClick, variant, text, disabled = false }) => {
  return (
    <Button
      onClick={() => onClick && onClick()}
      size="large"
      variant={variant}
      sx={{ m: 1, fontSize: "14px" }}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default LogoutModalButton;
