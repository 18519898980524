import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { Container } from "@mui/material";

import Page from "../components/Page";
import useFetch from "../hooks/useFetch";
import campaignsAPI from "../_api/_campaignsAPI";
import WorksList from "../components/works/WorksList";
import BenefWorkMatchingHeader from "../components/folder/matching/BenefWorkMatchingHeader";
import { WorkType } from "../@types/workType";

const BenefWorkMatchingPage = () => {
  const { id }: any = useParams();
  const { t } = useTranslation("common");
  const { data } = useFetch(campaignsAPI.getById(id));
  const navigate = useNavigate();

  const handleSelectWork = (work: WorkType) => {
    navigate(`/app/matching/${work.id}/${id}`);
  };

  return (
    <Page title={t("work_suggest.title")}>
      <Container sx={{ pt: 10, pb: 5 }}>
        <BenefWorkMatchingHeader folderData={data} />
        <WorksList handleNavigate={handleSelectWork} />
      </Container>
    </Page>
  );
};

export default BenefWorkMatchingPage;
