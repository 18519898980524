import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const AdminDisplayChat: React.FC<any> = ({ param = false, handleChanges }) => {
    const { t } = useTranslation("common");
    const [value, setValue] = useState(param)

    React.useEffect(() => {
        setValue(param);
    }, [param]);

  return (
    <div>
      <Typography variant="h4" sx={{ mt: 2 }}>
          {t("admin.params.chat.title")}
      </Typography>
      <FormGroup>
        <FormControlLabel
          value={param}
          onChange={(e: any) => handleChanges(e.target.checked)}
          control={<Checkbox checked={value} />}
          label={t("admin.params.chat.display")}
        />
      </FormGroup>

      <Divider sx={{ mt: 2 }} />
    </div>
  );
};

export default AdminDisplayChat;
