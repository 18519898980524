import React, { useState, useEffect } from "react";
import { Button, Box, TextField, Typography, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import PinDropRoundedIcon from "@mui/icons-material/PinDropRounded";
import SocialDistanceRoundedIcon from "@mui/icons-material/SocialDistanceRounded";
import LocationWithRange from "../common/LocationWithRange";

const CardFilter:any = styled(Box)(({ theme, active }: any) => ({
  width: "170px",
  height: "53px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid",
  color: active ? "white" : theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  borderRadius: "7px",
  backgroundColor: active ? theme.palette.primary.main : "white",
  cursor: "pointer",
  marginLeft: theme.spacing(1),
}));

const WorkTrainingListFilters = ({
  httpParams,
  setHttpParams,
  loading,
  baseValue,
  handleClear,
}: any) => {
  const { t } = useTranslation("common");
  const [needle, setNeedle] = useState(httpParams.needle);
  const [params, setParams] = useState(httpParams);
  const [clear, setClear] = useState(0);

  const handleType = (type: number) => {
    setParams({
      ...params,
      page: 1,
      type: type,
    });
  };

  const handleLocation = (event: any) => {
    setParams?.({ ...params, page: 1, location: event });
  };

  const handleRangeLocation = (event: number) => {
    setParams?.({ ...params, page: 1, range: event });
  };

  const handleNeedle = (event: any) => {
    const newParams = { ...params };
    newParams.page = 1;
    newParams.needle = event;
    setParams(newParams);
  };

  const clearNeedle = (event: any) => {
    setNeedle(event);
    handleNeedle(event);
  };

  const resetFilters = () => {
    setClear(clear + 1);
    handleClear();
    setHttpParams(baseValue);
  };

  useEffect(() => {
    setNeedle(httpParams?.needle);
    setParams(httpParams);
  }, [httpParams]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          mb: 1.5,
          flexWrap: { xs: "wrap", sm: "nowrap" },
          justifyContent: { xs: "center", sm: "end" },
        }}
      >
        <Box display="flex" alignItems="center" flexWrap="wrap" width="100%">
          <Box
            sx={{
              width: { xs: "100%", sm: "50%", md: "60%" },
              mt: { xs: 1, sm: 0 },
            }}
          >
            <TextField
              placeholder={t("trainings.filters.search_label")}
              variant="outlined"
              disabled={loading}
              fullWidth={true}
              value={needle}
              onChange={(text) => {
                setNeedle(text.target.value);
                handleNeedle(text.target.value ?? "");
              }}
              InputProps={{
                endAdornment: needle && (
                  <React.Fragment>
                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => clearNeedle("")}
                    />
                  </React.Fragment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "48%", md: "39.25%" },
              ml: { xs: 0, sm: 0.5 },
            }}
          >
            <LocationWithRange
              label={"Lyon, Bretagne, Lille ..."}
              displayIcon
              displayRange
              sendDataToParent={handleLocation}
              sendRangeToParent={handleRangeLocation}
              baseValue={params?.location}
              disabled={params.type === 2}
              maxRange={250}
              defaultRange={params?.range}
              clear={clear}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            width: "350px",
            mt: { xs: 1, sm: 0 },
            justifyContent: { xs: "center", sm: "end" },
            flexWrap: { xs: "wrap", sm: "nowrap" },
          }}
        >
          <CardFilter
            sx={{ mb: { xs: 0.5, sm: 0 } }}
            onClick={() => !loading && handleType(0)}
            active={params.type === 0}
          >
            <PinDropRoundedIcon sx={{ fontSize: "25px" }} />
            <Typography variant="body1" sx={{ ml: 0.5 }}>
              {t("trainings.center")}
            </Typography>
          </CardFilter>
          <CardFilter
            onClick={() => !loading && handleType(2)}
            active={params.type === 2}
          >
            <SocialDistanceRoundedIcon sx={{ fontSize: "25px" }} />
            <Typography variant="body1" sx={{ ml: 0.5 }}>
              {t("trainings.distance")}
            </Typography>
          </CardFilter>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <Button
          sx={{ width: "225px", height: "35px", m: 0.5 }}
          onClick={resetFilters}
          disabled={params === baseValue || loading}
        >
          <Typography variant="body1" color="secondary">
            {t("admin.graph.reset_filters")}
          </Typography>
        </Button>
        <Button
          sx={{ width: "225px", height: "35px", m: 0.5 }}
          variant="contained"
          onClick={() => setHttpParams(params)}
          disabled={
            (!params?.type && params?.type !== 0) ||
            (params?.type === 0 && !params?.location) ||
            !params?.needle ||
            loading
          }
        >
          <Typography variant="body1">{t("btn.search")}</Typography>
        </Button>
      </Box>
    </>
  );
};

export default WorkTrainingListFilters;
