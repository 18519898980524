import {
  Container,
  Typography,
  Paper,
  Card,
  Modal,
  Box,
  CardContent,
  IconButton,
  CardActions,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useFetch from "../../../../hooks/useFetch";
import Loader from "../../../../Loader";
import worksAPI from "../../../../_api/_worksAPI";
import Page from "../../../Page";
import AdminWorkInfosForm from "./AdminWorkInfosForm";
import axios from "axios";
import { modalStyle } from "../../../../_utils/modalStyle";
import { HighlightOff } from "@mui/icons-material";
import { toastError } from "../../../../_utils/toastError";

const AdminWorkInfosPage = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const { id } = useParams();
  const { data, loading } = useFetch(worksAPI.getById(`${id}/detail`));
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    label: "",
    description: "",
    access: "",
    condition: "",
    childrens: [],
    worksMobilities: [],
    worksEvolutions: [],
    code: "",
    codeReference1: "",
  });

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const deleteWork = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`works/${id}`);
      navigate("/app/params/works");
    } catch (err) {
      console.log(err);
      closeModal();
      setIsLoading(false);
      toastError();
    }
  };

  useEffect(() => {
    data &&
      setForm({
        label: data?.label,
        description: data?.description,
        access: data?.access,
        condition: data?.condition,
        childrens: data?.designations,
        worksMobilities: data?.mobilities,
        worksEvolutions: data?.evolutions,
        code: data?.code,
        codeReference1: data?.codeReference1,
      });
  }, [data]);

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    const obj = {
      label: values?.label,
      description: values?.description,
      access: values?.access,
      condition: values?.condition,
      childrens: values?.childrens?.map((el: any) => el["@id"] ?? el?.uri),
      worksMobilities: values?.worksMobilities?.map(
        (el: any) => el["@id"] ?? el?.uri
      ),
      worksEvolutions: values?.worksEvolutions?.map(
        (el: any) => el["@id"] ?? el?.uri
      ),
      code: values?.code,
      codeReference1: values?.codeReference1,
    };

    try {
      const skillsBodies = values.skills.map((el: any) => ({
        level: `/api/skill_levels/${el.level.level}`,
        work: `/api/works/${data?.id}`,
        skill: el?.skill?.["@id"] ?? el["@id"],
      }));

      const nIds = skillsBodies.map((el: any) => el.skill);
      const oIds = data?.skills.map((el: any) => el.skill["@id"]);
      const deleted: any = [];
      oIds.forEach((uri: any) => {
        if (!nIds.includes(uri)) {
          deleted.push(
            data?.skills
              .find((f: any) => f.skill?.["@id"] === uri)
              ?.["@id"].slice(5)
          );
        }
      });

      const deleteCalls = deleted.map((uri: any) => axios.delete(uri));

      if (data) {
        await worksAPI.put(data.id, obj);

        const editCalls = values?.skills
          ?.filter((el: any) => el["@id"].slice(0, 9) === "/api/work")
          ?.filter(
            (el: any) =>
              el.level.level !==
              data?.skills?.find((f: any) => f["@id"] === el["@id"]).level.level
          )
          .map((el: any) =>
            axios.put(el["@id"].slice(5, el["@id"].length), {
              level: `/api/skill_levels/${el.level.level}`,
            })
          );

        const postCalls = values?.skills
          ?.filter((el: any) => el["@id"].slice(0, 9) !== "/api/work")
          .map((el: any) =>
            axios.post("work_skills", {
              level: `/api/skill_levels/${el.level.level}`,
              work: `/api/works/${data?.id}`,
              skill: el?.skill?.["@id"] ?? el["@id"],
            })
          );

        await Promise.all([...postCalls, ...editCalls, ...deleteCalls]);

        toast.success(t("admin.params.works.alert_edit"));
        setIsLoading(false);
        navigate("/app/params/works");
      } else {
        const work: any = await worksAPI.post(obj);
        const workId = work["@id"];
        skillsBodies.forEach((el: any) => {
          el.work = workId;
        });
        toast.success(t("admin.params.works.alert_created"));
        setIsLoading(false);
        navigate("/app/params/works");
      }
    } catch (err) {
      console.log(err);
      toast.error(t("errors.error_append"));
      setIsLoading(false);
    }
  };

  return (
    <Page title="Métier">
      <Container sx={{ pt: 10, pb: 5 }}>
        <Paper elevation={0} sx={{ borderRadius: "23px" }}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Typography textAlign="center" variant="h4" sx={{ pt: 2 }}>
                {t(`admin.params.works.${data ? "edit" : "create"}`)}
              </Typography>
              <AdminWorkInfosForm
                loading={isLoading}
                form={form}
                canDelete={Boolean(id)}
                submit={handleSubmit}
                openModal={openModal}
                workSkills={
                  JSON.parse(JSON.stringify(data?.skills ?? [])) ?? []
                }
              />
              {Boolean(id) && (
                <DeleteModal
                  loading={isLoading}
                  open={open}
                  close={closeModal}
                  deleteWork={deleteWork}
                />
              )}
            </>
          )}
        </Paper>
      </Container>
    </Page>
  );
};

export default AdminWorkInfosPage;

const DeleteModal: React.FC<any> = ({ open, close, deleteWork, loading }) => {
  const { t } = useTranslation("common");
  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "80%", md: "420px" },
        }}
      >
        <CardContent sx={{ pt: 0 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingTop: 3,
              mb: 2,
            }}
          >
            <Typography variant="h5">
              {t("admin.params.works.delete_title")}
            </Typography>
            <IconButton
              onClick={close}
              sx={{ marginLeft: "auto" }}
              aria-label="close"
            >
              <HighlightOff />
            </IconButton>
          </Box>

          <Box display="flex" justifyContent="center">
            <Typography>{t("admin.params.works.delete")}</Typography>
          </Box>
        </CardContent>

        <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            size="large"
            sx={{ width: "255px" }}
            disabled={loading}
            onClick={close}
          >
            {t("btn.cancel")}
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ width: "255px" }}
            color="error"
            disabled={loading}
            onClick={deleteWork}
          >
            {t("btn.delete")}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
