import { Box, Button, TextField, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SelectComponent from "../../common/SelectComponent";
import { toast } from "react-toastify";
import { trimObject } from "../../../Utils";
import { useSelector } from "react-redux";
import axios from "axios";
import useFetch from "../../../hooks/useFetch";
import { toastError } from "../../../_utils/toastError";

const Ball = styled("div")`
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background: red;
  z-index: 1000000;
`;

interface IFormInput {
  position: string;
  left: number;
  top: number;
  bottom: number;
  right: number;
  container: string;
}

const baseValues = {
  position: "",
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  container: "",
};

const types = [
  { value: "fixed", label: "Fixed" },
  { value: "absolute", label: "Absolute" },
  { value: "relative", label: "Relative" },
  { value: "inherit", label: "Inherit" },
];

const presetsValues: any = {
  "bottom-right": {
    position: "fixed",
    bottom: 10,
    right: 10,
  },
  "bottom-left": {
    position: "fixed",
    bottom: 10,
    left: 10,
  },
  "top-left": {
    position: "fixed",
    top: 10,
    left: 10,
  },
  "top-right": {
    position: "fixed",
    top: 10,
    right: 10,
  },
};

const comparePreset = (preset: string, obj: any) => {
  const presetValue = { ...baseValues, ...presetsValues[preset] };
  const comparedObject = { ...baseValues, ...obj };

  const keys = Object.keys(presetValue);

  let same = true;

  keys.forEach((key) => {
    if (presetValue[key] !== comparedObject[key]) same = false;
  });

  return same;
};

const AdminWidgetConfigurator = () => {
  const { t } = useTranslation("common");
  const [settings, setSettings] = useState<any>({
    position: "fixed",
    bottom: "10",
    right: "10",
  });
  const [position, setPosition] = useState<any>({
    position: "fixed",
    bottom: "10px",
    right: "10px",
  });
  const [presetValue, setPresetValue] = useState("bottom-right");
  const company_id = useSelector((state: any) => state.user?.company_id);
  const { data: companyData, loading: dataLoading } = useFetch(
    `companies/${company_id}`
  );
  const [loading, setLoading] = useState(true);
  const [widgetClientId, setWidgetClientId] = useState<string | null>(null);

  useEffect(() => {
    setWidgetClientId(companyData?.data?.widgetClientId);
  }, [companyData]);

  const presetTypes = [
    {
      label: t("admin.widget.preset_title.custom"),
      value: "custom",
    },
    {
      label: t("admin.widget.preset_title.top-left"),
      value: "top-left",
    },
    {
      label: t("admin.widget.preset_title.top-right"),
      value: "top-right",
    },
    {
      label: t("admin.widget.preset_title.bottom-left"),
      value: "bottom-left",
    },
    {
      label: t("admin.widget.preset_title.bottom-right"),
      value: "bottom-right",
    },
  ];

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading]);

  useEffect(() => {
    const positionsKeys = ["top", "bottom", "left", "right"];

    const obj: any = {};

    positionsKeys.forEach((key) => {
      if (settings[key]) obj[key] = settings[key] + "px";
    });

    if (settings.position) obj.position = settings.position;

    setPosition(obj);
  }, [settings]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: {
      position: "fixed",
      left: 0,
      top: 0,
      bottom: 10,
      right: 10,
      container: "",
    },
  });

  useEffect(() => {
    if (companyData?.data?.widget) {
      reset({
        ...baseValues,
        ...companyData.data.widget,
      });
      setPresetValue(companyData?.data?.widget?.preset ?? "custom");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  const values = useWatch({ control });

  useEffect(() => {
    const to = setTimeout(() => {
      setSettings(values);
    }, 200);
    return () => {
      clearTimeout(to);
    };
  }, [values]);

  useEffect(() => {
    console.log(
      "values",
      values,
      presetValue,
      comparePreset(presetValue, values)
    );
    if (presetValue !== "custom" && !comparePreset(presetValue, values)) {
      setPresetValue("custom");
    }
  }, [presetValue, values]);

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    try {
      setLoading(true);

      const body = { ...trimObject(data), preset: presetValue };

      await axios.put(`companies/${company_id}`, {
        data: {
          ...companyData.data,
          widget: body,
        },
      });

      toast.success(t("admin.params.alert_save"));
    } catch (err) {
      toastError();
    }

    setLoading(false);
  };

  const copy = () => {
    navigator.clipboard.writeText(
      `<script src="${scriptURL}" data-client-id="${widgetClientId}" id="mt-widget-loader" async="true" defer="true"></script>`
    );
    toast.success(t("admin.widget.copied"));
  };

  const handlePresetChange = (v: string) => {
    setPresetValue(v);
    if (v === "custom") return;
    setSettings(presetsValues[v]);
    reset({ ...baseValues, ...presetsValues[v] });
  };

  return (
    <div>
      {dataLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              {t("admin.widget.preset")}
            </Typography>
            <SelectComponent
              items={presetTypes}
              onChange={handlePresetChange}
              preSelected={presetValue}
            />
          </Box>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {presetValue === "custom" && (
              <>
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {t("admin.widget.position")}
                  </Typography>
                  <Controller
                    name="position"
                    control={control}
                    rules={{
                      required: { value: true, message: "errors.required" },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <SelectComponent
                        items={types}
                        onChange={onChange}
                        preSelected={value}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    sx={{ width: "49%" }}
                    label={"top"}
                    type="number"
                    error={Boolean(errors?.top)}
                    helperText={t(errors?.top?.message ?? " ")}
                    {...register("top", {
                      required: { value: false, message: t("errors.required") },
                      valueAsNumber: true,
                    })}
                  />

                  <TextField
                    sx={{ width: "49%" }}
                    label={"bottom"}
                    type="number"
                    error={Boolean(errors?.bottom)}
                    helperText={t(errors?.bottom?.message ?? " ")}
                    {...register("bottom", {
                      required: { value: false, message: t("errors.required") },
                      valueAsNumber: true,
                    })}
                  />

                  <TextField
                    sx={{ width: "49%" }}
                    label={"left"}
                    type="number"
                    error={Boolean(errors?.left)}
                    helperText={t(errors?.left?.message ?? " ")}
                    {...register("left", {
                      required: { value: false, message: t("errors.required") },
                      valueAsNumber: true,
                    })}
                  />

                  <TextField
                    sx={{ width: "49%" }}
                    label={"right"}
                    type="number"
                    error={Boolean(errors?.right)}
                    helperText={t(errors?.right?.message ?? " ")}
                    {...register("right", {
                      required: { value: false, message: t("errors.required") },
                      valueAsNumber: true,
                    })}
                  />
                </Box>

                <Box>
                  <TextField
                    sx={{ width: "300px" }}
                    label={"Container"}
                    type="string"
                    error={Boolean(errors?.container)}
                    helperText={t(errors?.container?.message ?? " ")}
                    {...register("container", {
                      required: { value: false, message: t("errors.required") },
                    })}
                  />
                </Box>
              </>
            )}

            <Button
              variant="contained"
              sx={{ width: "200px", height: "45px", fontSize: "12px" }}
              type="submit"
              disabled={loading}
            >
              {t("btn.save")}
            </Button>
          </form>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Script:
            </Typography>
            {widgetClientId ? (
              <>
                <Typography
                  onClick={copy}
                  sx={{ cursor: "pointer" }}
                >{`<script src="${scriptURL}" data-client-id="${widgetClientId}" id="mt-widget-loader"
      async="true" defer="true"></script>`}</Typography>
                <Button
                  onClick={copy}
                  sx={{ fontSize: "12px", mt: 1 }}
                  variant="outlined"
                >
                  {t("admin.widget.copy")}
                </Button>
              </>
            ) : (
              <Typography>{t("unavailable")}</Typography>
            )}
          </Box>

          {!settings.container && <Ball style={position} />}
        </>
      )}
    </div>
  );
};

const scriptURL = "https://static.monkey-tie.com/scripts/mood.js";

export default AdminWidgetConfigurator;
