import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  styled,
} from "@mui/material";
import MenuNav from "./MenuNav";
import ExpandButton from "../components/ExpandButton";

const NavItem = styled(List)({
  marginTop: 20,
});

const Nested = styled("div")(({ theme }: any) => ({
  paddingLeft: theme.spacing(4),
}));

const NestedMenu: React.FC<{
  menu: any;
  handleLinkClick: any;
}> = ({ menu, handleLinkClick }: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <NavItem>
      <ListItem button onClick={handleClick} sx={{ ml: 3 }}>
        <ListItemIcon>{menu.icon}</ListItemIcon>
        <ListItemText primary={menu.label} />
        <ExpandButton open={open} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Nested>
            <MenuNav handleLinkClick={handleLinkClick} links={menu.links} />
          </Nested>
        </List>
      </Collapse>
    </NavItem>
  );
};

export default NestedMenu;
