import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import SelectComponent from "../common/SelectComponent";
import { useTranslation } from "react-i18next";
import { Box, TextField, Typography } from "@mui/material";
import ControlledEmailInput from "../forms/ControlledEmailInput";
import LocationReferentialAutocomplete from "../common/autocomplete/LocationReferentialAutocomplete";
import CustomButtonModal from "../common/CustomBtnModal";
import { useDispatch, useSelector } from "react-redux";
import { setUserPartial } from "../../redux/actions/userActions";
import axios from "axios";
import { setHome } from "../../redux/actions/homeActions";
import { toastError } from "../../_utils/toastError";

interface IFormInput {
  infos: {
    firstName?: string;
    lastName?: string;
    company?: string;
    email?: string;
    region?: any;
  };
  email?: string;
  pe: boolean;
  type: number;
}

const types = [
  { value: 1, label: "Conseiller pôle emploi" },
  { value: 2, label: "Autre conseiller" },
  { value: 3, label: "Recruteur" },
  { value: 4, label: "Aucun" },
];

const DispatchInfosForm = () => {
  const [type, setType] = useState(1);
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const hasName = type !== 4;
  const hasEmail = type !== 4 && type !== 1;
  const hasCompany = type !== 4 && type !== 1;
  const hasRegion = type !== 4 && type === 1;

  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: {
      type: 1,
      pe: false,
      email: "",
      infos: {
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        region: null,
      },
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    try {
      setLoading(true);
      const body = { ...data };
      body.type = body.type - 1;

      if (data.type === 4) {
        body.infos = {};
        delete body.email;
      } else {
        body.pe = body.type === 0;
        body.infos.region = data.type === 1 ? body.infos.region : null;
        body.infos.company = data.type === 1 ? "" : body.infos.company;
        body.infos.email = body.email;
        delete body.email;
      }

      const otherData = { ...(user?.people?.otherData ?? {}) };
      otherData.dispatchInfos = body;

      await axios.put(`people/${user?.person_id}`, {
        otherData,
      });

      dispatch(
        setUserPartial({
          people: {
            ...user.people,
            otherData,
          },
        })
      );

      const home = await (await axios.get("home")).data?.["hydra:member"]?.[0];
      dispatch(setHome(home));
    } catch (err) {
      toastError();
      setLoading(false);
    }
  };

  useEffect(() => {
    const subscription = watch((values: any) => {
      const tp = values.type;
      setType(tp);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Typography sx={{ mb: 1 }}>Type d'accompagnement</Typography>
      <Controller
        name="type"
        control={control}
        rules={{
          required: { value: true, message: "errors.required" },
        }}
        render={({ field: { value, onChange } }) => (
          <SelectComponent
            items={types}
            onChange={onChange}
            preSelected={value}
            sx={{
              width: "300px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 2,
            }}
          />
        )}
      />

      {type !== 4 && (
        <Typography sx={{ mb: 1 }}>
          Informations sur mon {type === 3 ? "recruteur" : "conseiller"}
        </Typography>
      )}

      {hasEmail && (
        <>
          <ControlledEmailInput
            control={control}
            name={"email"}
            spaced
            required
            style={{ width: "300px" }}
          />
        </>
      )}

      {hasName && (
        <>
          <TextField
            sx={{ width: "300px" }}
            label={t("form.firstname")}
            error={Boolean(errors?.infos?.firstName)}
            helperText={t(errors?.infos?.firstName?.message ?? " ")}
            {...register("infos.firstName", {
              required: { value: true, message: t("errors.required") },
            })}
          />

          <TextField
            sx={{ width: "300px" }}
            label={t("form.lastname")}
            error={Boolean(errors?.infos?.lastName)}
            helperText={t(errors?.infos?.lastName?.message ?? " ")}
            {...register("infos.lastName", {
              required: { value: true, message: t("errors.required") },
            })}
          />
        </>
      )}

      {hasCompany && (
        <>
          <TextField
            sx={{ width: "300px" }}
            label={t("form.company")}
            error={Boolean(errors?.infos?.company)}
            helperText={t(errors?.infos?.company?.message ?? " ")}
            {...register("infos.company", {
              required: { value: true, message: t("errors.required") },
            })}
          />
        </>
      )}

      {hasRegion && (
        <>
          <Controller
            control={control}
            name="infos.region"
            rules={{
              required: { value: true, message: "errors.required" },
            }}
            render={({ field: { value, onChange } }) => (
              <LocationReferentialAutocomplete
                baseValue={value}
                sendDataToParent={onChange}
                style={{ width: "300px" }}
                formError={Boolean(errors?.infos?.region?.message)}
                formHelper={
                  errors?.infos?.region?.message
                    ? t(String(errors?.infos?.region?.message))
                    : " "
                }
              />
            )}
          />
        </>
      )}

      <Box>
        <CustomButtonModal
          disabled={loading}
          type="submit"
          variant="contained"
          text={t("btn.save")}
        />
      </Box>
    </form>
  );
};

export default DispatchInfosForm;
