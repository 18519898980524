import { styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const SaveLoading = styled("span")(({ theme }: any) => ({
  "@keyframes blink": {
    "0%": {
      opacity: ".2",
    },
    "20%": {
      opacity: "1",
    },
    "100%": {
      opacity: ".2",
    },
  },
  saving: {
    animationName: "blink",
    animationDuration: "1.4s",
    animationIterationCount: "infinite",
    animationFillMode: "both",
    "&:nth-of-type(2)": {
      animationDelay: ".2s",
    },
    "&:nth-of-type(3)": {
      animationDelay: ".4s",
    },
  },
}));

const LoadExpSplashScreen = () => {
  const { t } = useTranslation("common");

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2" sx={{ opacity: "0.6" }}>
        {t("splash_screen.load_exp")}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          opacity: "0.6",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SaveLoading>.</SaveLoading>
        <SaveLoading>.</SaveLoading>
        <SaveLoading>.</SaveLoading>
      </Typography>
    </Box>
  );
};

export default LoadExpSplashScreen;
