import axios from "axios";

export const SET_PARAMETERS = "SET_PARAMETERS";

export const setParameters = (content: any) => {
  return {
    type: SET_PARAMETERS,
    content,
  };
};

export const initParameters = () => {
  return async (dispatch: any, getState: any) => {
    const res = await axios.get("parameters");
    dispatch(setParameters(res?.data?.["hydra:member"]));
  };
};
