import { BehaviorSubject, Observable, tap } from "rxjs";
import http from "./http/http";

class projectsService {
  static projects: any = { projectCareers: [], projectTrainings: [] };
  static _projects = new BehaviorSubject(this.projects);

  static get(id: string): Observable<any> {
    return http.get(`projects${id && `?person.id=${id}`}`).pipe(
      tap((res: any) => {
        this.projects = res.data["hydra:member"][0];
        this._projects.next(this.projects);
      })
    );
  }

  static edit(id: string, body: any) {
    return http.put(`project_careers/${id}`, body).pipe(
      tap({
        next: (response: any) => {
          const arr: any = [...this.projects.projectCareers];
          const index = arr.findIndex((el: any) => el.id === id);
          arr[index] = response.data;
          this.projects = { ...this.projects, projectCareers: arr };
          this._projects.next(this.projects);
        },
        error: () => {},
      })
    );
  }

  static createProject(body: any) {
    return http.post(`project_careers`, body).pipe(
      tap({
        next: (res: any) => {
          const arr = [...this.projects.projectCareers];
          arr.push(res.data);
          this.projects = { ...this.projects, projectCareers: arr };
          this._projects.next(this.projects);
        },
        error: () => {},
      })
    );
  }

  static remove(id: string): Observable<any> {
    return http.delete(`project_careers/${id}`).pipe(
      tap({
        next: () => {
          const arr = [...this.projects.projectCareers];
          const index = arr.findIndex((el: any) => el.id === id);
          arr.splice(index, 1);
          this.projects = { ...this.projects, projectCareers: arr };
          this._projects.next(this.projects);
        },
        error: () => {
          console.log("ERROR");
        },
      })
    );
  }

  static createTraining(body: any) {
    return http.post("project_trainings", body).pipe(
      tap({
        next: (res) => {
          const arr = [...this.projects.projectTrainings];
          arr.push(res.data);
          this.projects = { ...this.projects, projectTrainings: arr };
          this._projects.next(this.projects);
        },
        error: () => {
          console.log("ERROR");
        },
      })
    );
  }

  static editTraining(id: string, body: any) {
    return http.put(`project_trainings/${id}`, body).pipe(
      tap({
        next: (response) => {
          const arr: any = [...this.projects.projectTrainings];
          const index = arr.findIndex((el: any) => el.id === id);
          arr[index] = response.data;
          this.projects = { ...this.projects, projectTrainings: arr };
          this._projects.next(this.projects);
        },
        error: () => {},
      })
    );
  }

  static deleteTraining(id: string) {
    return http.delete(`project_trainings/${id}`).pipe(
      tap({
        next: () => {
          const arr = [...this.projects.projectTrainings];
          const index = arr.findIndex((el: any) => el.id === id);
          arr.splice(index, 1);
          this.projects = { ...this.projects, projectTrainings: arr };
          this._projects.next(this.projects);
        },
        error: () => {
          console.log("ERROR");
        },
      })
    );
  }
}

export default projectsService;
