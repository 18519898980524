import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminAdvisorVisibility: React.FC<any> = ({
  param,
  handleChanges,
  context,
}) => {
  const { t } = useTranslation("common");
  return (
    <div>
      <Typography variant="h4">{t("admin.params.visibility.title")}</Typography>
      {param && (
        <>
          <FormGroup>
            <FormControl sx={{ mt: 2 }}>
              <FormLabel id="demo-radio-buttons-group-label">
                {t("admin.params.visibility.pool")}
              </FormLabel>
              <Typography variant="body1">
                {t(`admin.params.visibility.display_${context}`)}
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="PERSO"
                  onChange={(e: any) =>
                    handleChanges({ ...param, FISHPOND: "PERSO" })
                  }
                  control={<Radio checked={param.FISHPOND === "PERSO"} />}
                  label={t("admin.params.visibility.perso_pool")}
                />
                <FormControlLabel
                  value="ALL"
                  onChange={(e: any) =>
                    handleChanges({ ...param, FISHPOND: "ALL" })
                  }
                  control={<Radio checked={param.FISHPOND === "ALL"} />}
                  label={t(`admin.params.visibility.all_pool_${context}`)}
                />
              </RadioGroup>
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <FormLabel id="demo-radio-buttons-group-label">
                {context === "advisor"
                  ? t("advisor.folder.page")
                  : t("recruiter.campaign.page")}
              </FormLabel>
              <Typography variant="body1">
                {t(`admin.params.visibility.display_${context}`)}
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={"PERSO"}
                  onChange={(e: any) =>
                    handleChanges({
                      ...param,
                      CAMPAIGN: "PERSO",
                    })
                  }
                  control={<Radio checked={param.CAMPAIGN === "PERSO"} />}
                  label={t(`admin.params.visibility.only_${context}`)}
                />
                <FormControlLabel
                  value={"ALL"}
                  onChange={(e: any) =>
                    handleChanges({
                      ...param,
                      CAMPAIGN: "ALL",
                    })
                  }
                  control={<Radio checked={param.CAMPAIGN === "ALL"} />}
                  label={t(`admin.params.visibility.all_campaigns_${context}`)}
                />
              </RadioGroup>
            </FormControl>
          </FormGroup>
        </>
      )}
    </div>
  );
};

export default AdminAdvisorVisibility;
