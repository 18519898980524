import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

let navigatorLanguage = "fr";

try {
  const c = navigator.language.split("-");
  if (c.length > 1) navigatorLanguage = c[1].toLocaleLowerCase();
  else navigatorLanguage = navigator.language.toLowerCase();
} catch (err) {
  navigatorLanguage = "fr";
}

const PhoneField = ({
  phoneNumber,
  setPhoneNumber,
  handleKeyPress,
  error,
  textError,
  label,
  autoFocus,
  setCountryCode,
}: any) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <PhoneInput
        country={navigatorLanguage}
        value={phoneNumber}
        onChange={(e: string, c: any) => {
          setPhoneNumber(`+${e}`);
          setCountryCode(c?.countryCode ?? navigatorLanguage);
        }}
        specialLabel={label ?? t("form.phone")}
        placeholder="+33 6 07 08 09 10"
        searchStyle={{ width: "100% !important" }}
        onKeyDown={handleKeyPress}
        isValid={!error}
        inputProps={{
          name: "phone",
          required: true,
          autoFocus: autoFocus,
        }}
      />
      {error && (
        <Typography variant="caption" color="error">
          {textError ||
            (!phoneNumber ? "Ce champ est requis" : "Téléphone invalide")}
        </Typography>
      )}
    </div>
  );
};

export default PhoneField;
