import { SET_PARAMETERS } from "../actions/parametersActions";
import initialState from "../initialState";

const parametersReducer = (
  state = [...initialState.parameters],
  action: any
) => {
  switch (action.type) {
    case SET_PARAMETERS:
      return [...action.content];
    default:
      return [...state];
  }
};

export default parametersReducer;
