// ----------------------------------------------------------------------

export default function Container(theme: any) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {},
        maxWidthXl: {
          // maxWidth: "1536px",
        },
      },
    },
  };
}
