import {
  OPEN_WITH_TYPE,
  RESET_LOADER,
  SET_OPEN,
  SET_TYPE,
} from "../actions/globalLoaderActions";
import initialState from "../initialState";

const globalLoaderReducer = (
  state = { ...initialState.globalLoader },
  action: any
) => {
  switch (action.type) {
    case SET_OPEN:
      return { ...state, open: action.payload };
    case SET_TYPE:
      return { ...state, type: action.payload };
    case OPEN_WITH_TYPE:
      return { ...state, open: true, type: action.payload };
    case RESET_LOADER:
      return { open: false, type: "" };
    default:
      return { ...state };
  }
};

export default globalLoaderReducer;
