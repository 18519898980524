import { FormControlLabel, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import peopleAPI from "../../../_api/_people";
import BookmarkSwitch from "../BookmarkSwitch";
import FilterPopoverContainer from "../../common/chip_filter/FilterPopoverContainer";
import BottomFilterContent from "../../common/chip_filter/BottomFilterContent";

const WorkBookmarkFilter = ({
  handleBookmark,
  httpParams,
  relaodBookmark,
}: any) => {
  const theme: any = useTheme();
  const { t } = useTranslation("common");
  const [close, setClose] = useState<any>(false);
  const [value, setValue] = useState<any>(httpParams?.bookmark || false);
  const [totalBookmark, setTotalBookmark] = useState(0);
  const tokenData = useSelector((state: any) => state.user);

  const { data } = useFetch(
    peopleAPI.getById(`${tokenData.person_id}/bookmarks_count`, {
      relaodBookmark: relaodBookmark,
    })
  );

  const returnTitleChip = () => {
    return value
      ? `${t("work_suggest.bookmark_filter")}`
      : t("work_suggest.bookmark_label");
  };

  const handleSubmit = () => {
    handleBookmark(value);
    setClose(!close);
  };

  const handleClear = () => {
    setValue(false);
    handleBookmark(false);
    setClose(!close);
  };

  useEffect(() => {
    data?.bookmark && setTotalBookmark(data.bookmark.bookmarksCount.works);
  }, [data]);

  return (
    <>
      <FilterPopoverContainer
        title={returnTitleChip()}
        idPop="work_bookmark_popover"
        close={close}
        active={value}
        content={
          <>
            <FormControlLabel
              sx={{ p: 2 }}
              control={
                <BookmarkSwitch
                  baseValue={value}
                  onChange={() => {
                    setValue(!value);
                  }}
                />
              }
              label={
                <Typography
                  sx={{ marginLeft: 1, color: theme.palette.secondary.main }}
                >
                  {value
                    ? t("work_suggest.bookmark_hidden")
                    : t("work_suggest.bookmark", { count: totalBookmark })}
                </Typography>
              }
            />
            <BottomFilterContent
              handleSubmit={handleSubmit}
              handleClear={handleClear}
              disabled={!value}
            />
          </>
        }
      />
    </>
  );
};

export default WorkBookmarkFilter;
