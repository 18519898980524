import React, { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import peopleAPI from "../_api/_people";
import personSkillsAPI from "../_api/_personSkillsAPI";
import { useSelector, shallowEqual } from "react-redux";
import { setUser } from "../redux/actions/userActions";
import { useDispatch } from "react-redux";
import thirdPartiesAPI from "../_api/_thirdPartiesAPI";
import profileFormatter from "../_utils/formatters/profileFormatter";

const ProfileContext = createContext<any>(null);

export { ProfileContext };

const Profile = ({ children }: any) => {
  const params = useParams();
  const dispatch = useDispatch();
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const isThird = useSelector(
    (state: any) => state.auth.context === "third_party"
  );

  const [careerItems, setCareerItems] = useState<any>([]);
  const [peopleData, setPeopleData] = useState<any>({});
  const [skills, setSkills] = useState<any>([]);
  const [transversalSkills, setTransversalSkills] = useState<any>([]);
  const [languages, setLanguages] = useState<any>([]);
  const languagesResponse = useFetch(
    peopleAPI.getById(
      `${params?.id ? params.id : tokenData?.person_id}/languages`
    )
  );

  const [reSkills, setReSkills] = useState(0);
  const [reTransversalSkills, setReTransversalSkills] = useState(0);

  const reloadSkills = () => {
    setReSkills((c) => c + 1);
    setReTransversalSkills((c) => c + 1);
  };

  const skillsResponse = useFetch(
    personSkillsAPI.get({
      "person.id": params?.id ? params.id : tokenData?.person_id,
      pagination: false,
      reload: reSkills,
    })
  );

  const transversalSkillsResponse = useFetch(
    personSkillsAPI.get({
      "person.id": params?.id ? params.id : tokenData?.person_id,
      pagination: false,
      "skill.type": "transversal",
      reload: reTransversalSkills,
    })
  );

  const peopleResponse = useFetch(
    isThird
      ? thirdPartiesAPI.getById(
          `${tokenData.thirdParty_id}/profiles/${params.profileId}`
        )
      : peopleAPI.getById(params?.id ? params.id : tokenData?.person_id),
    false,
    profileFormatter
  );

  const careerResponse = useFetch(
    peopleAPI.getById(
      `${params?.id ? params.id : tokenData?.person_id}/career_items`
    )
  );

  const fullName = `${peopleResponse?.data?.firstName ?? ""} ${
    peopleResponse?.data?.lastName ?? ""
  }`;

  const removeCareerItem = (id: string) => {
    const index = careerItems.findIndex((el: any) => el.id === id);
    const arr = [...careerItems];
    arr.splice(index, 1);
    setCareerItems(arr);
  };

  const updateSkills = (skill: any) => {
    const copy = [
      ...(skill.type === "transversal" ? transversalSkills : skills),
    ];

    const index = copy.findIndex((el: any) => el.id === skill.id);
    copy[index] = skill;
    if (skill.type === "transversal") {
      setTransversalSkills(copy);
    } else {
      setSkills(copy);
    }
  };

  useEffect(() => {
    if (peopleResponse.error?.status === 404) {
      navigate("/404", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleResponse?.error]);

  useEffect(() => {
    setCareerItems(careerResponse?.data?.careerItems);
  }, [careerResponse?.data]);

  useEffect(() => {
    setPeopleData(peopleResponse?.data);
    peopleResponse?.data?.image?.contentUrl &&
      peopleResponse?.data?.userId === user.user_id &&
      dispatch(
        setUser({
          ...user,
          profilePicture: peopleResponse?.data?.image?.contentUrl,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleResponse?.data]);

  useEffect(() => {
    const sk = skillsResponse?.data?.["hydra:member"] || [];
    sk?.forEach((el: any) => el.type === "skills");
    setSkills(sk);
  }, [skillsResponse?.data]);

  useEffect(() => {
    const sk = transversalSkillsResponse?.data?.["hydra:member"] || [];
    sk?.forEach((el: any) => el.type === "transversal");
    setTransversalSkills(sk);
  }, [transversalSkillsResponse?.data]);

  useEffect(() => {
    if (languagesResponse?.data) {
      setLanguages(languagesResponse?.data?.languages);
    }
  }, [languagesResponse?.data]);

  const values = {
    careerResponse,
    fullName,
    peopleResponse,
    careerItems,
    setCareerItems,
    peopleData,
    setPeopleData,
    skills,
    skillsResponse,
    setSkills,
    removeCareerItem,
    updateSkills,
    transversalSkills,
    setTransversalSkills,
    transversalSkillsResponse,
    languages,
    setLanguages,
    languagesResponse,
    reloadSkills,
  };

  return (
    <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>
  );
};

export default Profile;
