import { Box, Slide } from "@mui/material";
import React, { useState, useEffect, useReducer } from "react";
import TopBar from "./TopBar";
// import { requestUserIsAlive } from "../../redux/actions/chatActions";
// import { useDispatch } from "react-redux";

const initialState = {
  term: "",
  type: null,
};

const reducer = (state: any, { field, value }: any) => {
  return {
    ...state,
    [field]: value,
  };
};

const TopBarContainer: React.FC<any> = ({
  isLogged,
  handleDrawerToggle,
  fullWidthBar,
}) => {
  const [small, setSmall] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fnc = (e: any) => {
      setSmall(window.scrollY >= 140);
    };
    window.addEventListener("scroll", fnc);
    return () => {
      window.removeEventListener("scroll", fnc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLogged && (
        <TopBar
          fullWidthBar={fullWidthBar}
          state={state}
          dispatch={dispatch}
          handleDrawerToggle={handleDrawerToggle}
        />
      )}

      {isLogged && (
        <Box position="fixed" sx={{ width: "100%", zIndex: 100 }}>
          <Slide unmountOnExit in={small} direction="down">
            <Box
              sx={{
                background: "white",
                zIndex: 1,
                position: "relative",
                boxShadow: "0 8px 16px 0 rgb(145 158 171 / 24%)",
              }}
            >
              <TopBar
                fullWidthBar={fullWidthBar}
                small={true}
                handleDrawerToggle={handleDrawerToggle}
              />
            </Box>
          </Slide>
        </Box>
      )}
    </>
  );
};

export default TopBarContainer;
