import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography, Badge, styled } from "@mui/material";
import { formatDistance } from "date-fns";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-GB";
import { useSelector, shallowEqual } from "react-redux";
import { requestClearNotificationByConversation } from "../../redux/actions/chatActions";
import { useDispatch } from "react-redux";
import chatService from "../../services/chat.service";
import { useTranslation } from "react-i18next";
import AvatarGroupFolderComponent from "../folder/AvatarGroupFolderComponent";
import StringAvatar from "../common/StringAvatar";

const Card: any = styled(Box, {
  shouldForwardProp: (props) => props !== "selected",
})(({ selected }: any) => ({
  margin: "0 5px 0 5px",
  display: "flex",
  alignItems: "center",
  padding: "8px",
  borderRadius: "10px",
  border: "1px solid",
  transition: "all 0.25s ease-in-out",
  borderColor: selected ? "#DBE5ED" : "#fff",
  backgroundColor: selected ? "#F9FAFC" : "auto",
  cursor: selected ? "pointer" : "default",
  "&:hover": {
    backgroundColor: "#F9FAFC",
    cursor: "pointer",
  },
  "&:target": {
    backgroundColor: "#F9FAFC",
    cursor: "pointer",
    border: "1px solid #DBE5ED",
  },
}));

const LightTypography: any = styled(Typography)(({ theme }: any) => ({
  position: "absolute",
  width: "15px",
  height: "15px",
  border: "solid 3px #fff",
  borderRadius: theme.shape.borderRadius48,
  left: "28px",
  top: "25px",
  alignmentBaseline: "baseline",
  zIndex: 1200,
}));

const ConvSubtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.grey[500],
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  maxWidth: "150px",
  minWidth: "150px",
}));

const ConvTitle = styled((props) => <Typography component="p" {...props} />)({
  fontWeight: 700,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  maxWidth: "150px",
  minWidth: "150px",
});

const ConversationListItem: React.FC<{ convInfo: any; mobile: any }> = ({
  convInfo,
  mobile,
}) => {
  const { t }: any = useTranslation("common");

  const [convId, setConvId] = useState<any>(null);

  useEffect(() => {
    const sub = chatService._convId.subscribe((e) => setConvId(e));

    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const [selected, setSelected] = useState<any>();

  const dispatch = useDispatch();
  const locale = localStorage.getItem("lang") || "fr";
  let fnsDate = convInfo?.lastMessage
    ? formatDistance(new Date(convInfo?.lastMessage?.createdAt), Date.now(), {
        includeSeconds: true,
        locale: locale === "fr" ? frLocale : enLocale,
      })
    : "";

  var autoConvName: any = "";

  // eslint-disable-next-line array-callback-return
  convInfo?.members.forEach((member: any) => {
    if (tokenData?.person_id !== member?.id && member?.fullName)
      autoConvName +=
        autoConvName === "" ? member?.fullName : ", " + member?.fullName;
  });

  const getImage = () => {
    let image = "";
    convInfo?.members.forEach((member: any) => {
      if (tokenData?.person_id !== member?.id && member?.fullName) {
        image = member.image?.contentUrl || member.image;
      }
    });
    return image;
  };

  const clearNotifications = () => {
    if (convInfo?.notification) {
      dispatch(requestClearNotificationByConversation(convInfo));
    }
  };

  useEffect(() => {
    setSelected(convId === convInfo.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convId]);

  const defineSingleOrGroupAvatar = (conversation: any) => {
    let output: any = <></>;
    var list: any[] = [];
    // eslint-disable-next-line array-callback-return
    conversation?.members?.map((member: any, i: any) => {
      if (member?.id !== tokenData.person_id) {
        list.push({ id: member?.id, person: member });
      }
    });

    if (conversation.members.length > 2) {
      output = (
        <AvatarGroupFolderComponent
          color="primary"
          size={40}
          list={list}
          closer={true}
        />
      );
    } else {
      output = (
        <>
          <StringAvatar
            size={40}
            name={autoConvName ?? convInfo?.name}
            url={convInfo?.image ?? getImage()}
          />
          <LightTypography
            component="span"
            sx={{
              backgroundColor: chatService.getColorByMembersStatus(
                convInfo,
                tokenData?.person_id
              ),
            }}
          />
        </>
      );
    }

    return output;
  };

  return (
    <>
      <Card
        selected={selected}
        onClick={() => {
          mobile.setState(true);
          if (convId !== convInfo.id) {
            chatService.setConvId(convInfo.id);
            setSelected(true);
          }
          clearNotifications();
        }}
      >
        <Box sx={{ position: "relative", minWidth: "80px" }}>
          {defineSingleOrGroupAvatar(convInfo)}
        </Box>
        <Box
          sx={{ marginLeft: "5px", display: "flex", flexDirection: "column" }}
        >
          <ConvTitle>{convInfo?.name ?? autoConvName}</ConvTitle>
          <ConvSubtitle variant="body2" sx={{ mt: "-3px" }}>
            {convInfo?.lastMessage?.content ?? t("chat.no_message")}
          </ConvSubtitle>
        </Box>

        {convInfo?.notification && convInfo?.notification?.count > 0 ? (
          <Box sx={{ width: "15px", marginLeft: "auto" }}>
            <Badge
              color="primary"
              badgeContent={convInfo?.notification?.count}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            ></Badge>
          </Box>
        ) : (
          <Box sx={{ marginLeft: "auto" }}>
            <Typography
              variant="body2"
              sx={{ marginBottom: "revert", textAlign: "center" }}
            >
              {fnsDate}
            </Typography>
          </Box>
        )}
      </Card>
    </>
  );
};

export default ConversationListItem;
