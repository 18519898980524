import { Box, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IncTopSoftSkills from "./IncTopSoftSkills";

const HeaderIamge = styled("img")({
  width: "65px",
  marginRight: "10px",
  borderRadius: "17px",
});

const Title = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.orange[500],
}));

const TitleSpan = styled("span")(({ theme }: any) => ({
  color: theme.palette.orange[500],
}));

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const IncSoftSkills: React.FC<any> = ({ result, work = false }) => {
  const { t } = useTranslation("common");
  const [softSkills, setSoftSkills] = useState<any>([]);

  const returnKey = (trait: any) => {
    let v: number = 0;
    const min = trait?.min;
    const max = trait?.max;
    min < -3 ? (v = 1) : min <= 3 ? (v = 2) : (v = 4);
    max > -3 && min < -3 && (v += 2);
    min < -3 && max < 1 && (v -= 2);
    min >= 1 && max <= 3 && (v += 2);
    min === 3 && max >= 3 && (v += 2);
    min < 3 && max > 3 && (v += 4);
    return v;
  };

  useEffect(() => {
    const sk =
      result?.dynamic_motivation?.soft_skills ?? result.dym_motiv?.soft_skills;
    let obj = [];

    sk?.gold?.forEach((el: any) => (el.type = "gold"));
    sk?.silver?.forEach((el: any) => (el.type = "silver"));
    sk?.bronze?.forEach((el: any) => (el.type = "bronze"));
    obj = [...(sk.gold ?? []), ...(sk.silver ?? []), ...(sk.bronze ?? [])];

    const newResults: any[] = [];
    obj?.forEach((el: any) => newResults.push({ ...el, key: returnKey(el) }));

    const sortBy = [1, 7, 3, 2, 6, 4];
    setSoftSkills(
      newResults.sort((a, b) => sortBy.indexOf(b?.key) - sortBy.indexOf(a?.key))
    );
  }, [result]);

  return (
    <Box>
      <Box display="flex" justifyContent="center" sx={{ mt: 8.5, mb: 2 }}>
        <Box display="flex" alignItems="center">
          <HeaderIamge alt="trophy" src="/static/icons/inc/trophy_2.svg" />
          <Title
            variant="h3"
            sx={{
              lineHeight: "30px",
              textTransform: "uppercase",
            }}
          >
            <TitleSpan
              style={{ fontWeight: "lighter" }}
              dangerouslySetInnerHTML={{
                __html: t("inc.softskills.top_title", {
                  context: work ? "work" : "",
                }),
              }}
            />
          </Title>
        </Box>
      </Box>

      <Box marginTop={2} display="flex" justifyContent="center">
        <Subtitle textAlign="center" maxWidth="760px">
          {t("inc.softskills.desc")}
        </Subtitle>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        sx={{ mt: 3 }}
      >
        <Box display="flex" alignItems="center" sx={{ mr: 2 }}>
          <Box
            sx={{
              mr: 1,
              width: "15px",
              height: "15px",
              backgroundColor: work ? "#333539" : "#1a52c2",
              borderRadius: "9px",
            }}
          />
          <Subtitle>{t("inc.softskills.legend_1")}</Subtitle>
        </Box>

        {!work && (
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                mr: 1,
                width: "34px",
                height: "15px",
                background: "linear-gradient(90deg, #B4FDA4 0%, #7ADEA6 100%)",
                borderRadius: "9px",
              }}
            />
            <Subtitle>{t("inc.softskills.legend_2")}</Subtitle>
          </Box>
        )}
      </Box>
      <Box
        marginTop={2}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        flexDirection="column"
      >
        {softSkills
          ?.filter((ssk: any) => ssk.type === "gold")
          .map((ssk: any, i: number) => (
            <IncTopSoftSkills key={ssk?.var} ssk={ssk} work={work} />
          ))}
      </Box>
      <Box
        marginTop={2}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        flexDirection="column"
      >
        {softSkills
          ?.filter((ssk: any) => ssk.type === "silver")
          .map((ssk: any, i: number) => (
            <IncTopSoftSkills key={ssk?.var} ssk={ssk} work={work} />
          ))}
      </Box>
      {softSkills?.filter((ssk: any) => ssk.type === "silver")?.length === 0 &&
        softSkills?.filter((ssk: any) => ssk.type === "gold")?.length === 0 && (
          <Box
            marginTop={2}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            flexDirection="column"
          >
            {softSkills
              ?.filter((ssk: any) => ssk.type === "bronze")
              .map((ssk: any, i: number) => (
                <IncTopSoftSkills key={ssk?.var} ssk={ssk} work={work} />
              ))}
          </Box>
        )}
    </Box>
  );
};

export default IncSoftSkills;
