import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import FilterPopoverContainer from "../../common/chip_filter/FilterPopoverContainer";
import BottomFilterContent from "../../common/chip_filter/BottomFilterContent";

const WorkBlackListFilter = ({ handleBlackList, preSelected }: any) => {
  const { t } = useTranslation("common");
  const theme: any = useTheme();
  const [value, setValue] = useState<any>(preSelected || 0);
  const [close, setClose] = useState<any>(false);

  const returnTitleChip = () => {
    return value === 1
      ? `${t(`work_suggest.blacklist_filter`)}`
      : t(`work_suggest.blacklist_label`);
  };

  const handleSubmit = () => {
    handleBlackList(value);
    setClose(!close);
  };

  const handleClear = () => {
    setValue(0);
    handleBlackList(0);
    setClose(!close);
  };

  return (
    <>
      <FilterPopoverContainer
        title={returnTitleChip()}
        idPop="work_tension_popover"
        close={close}
        active={value === 1}
        content={
          <Box>
            <Typography variant="body1" fontWeight="bold" sx={{ p: 1.5 }}>
              {t(`work_suggest.blacklist_filter`)}
            </Typography>
            <MenuItem
              sx={{
                backgroundColor: value === 1 && theme.palette.primary[100],
              }}
              onClick={() => {
                setValue(1);
              }}
              value={"true"}
            >
              <Checkbox checked={value === 1} />
              <ListItemText primary={t("form.yes")} />
            </MenuItem>
            <MenuItem
              sx={{
                backgroundColor: value === 0 && theme.palette.primary[100],
              }}
              onClick={() => {
                setValue(0);
              }}
              value={"false"}
            >
              <Checkbox checked={value === 0} />
              <ListItemText primary={t("form.no")} />
            </MenuItem>
            <BottomFilterContent
              handleSubmit={handleSubmit}
              handleClear={handleClear}
              disabled={value === 0}
            />
          </Box>
        }
      />
    </>
  );
};

export default WorkBlackListFilter;
