import { Box, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";

import NewSkillsContainer from "./container/NewSkillsContainer";
import useFetch from "../../../hooks/useFetch";
import BigButton from "../../common/BigButton";
import SkillModal from "../SkillModal";

import CategoriesSlider from "./CategoriesSlider";
import Loader from "../../../Loader";
import useWorkflow from "../../../hooks/useWorkflow";
import { useSelector } from "react-redux";
import WorkflowModal from "./WorkflowModal";
import { getNumericDate } from "../../../_utils/getNumericDate";
import { useTranslation } from "react-i18next";

export const defaultTheme = {
  background: "#BAD6FF",
  button: "#3D5CFF",
  gradient: "#8BA7FF",
};

const handleCategory = (category: any) => {
  const parsed = JSON.parse(JSON.stringify({ ...category }));

  parsed.skills = parsed.skills?.map((el: any) => ({
    ...el,
    id: el.personSkillId ?? el.id,
  }));

  parsed.children.forEach((cat: any) => {
    cat.skills = cat.skills.map((el: any) => ({
      ...el,
      id: el.personSkillId ?? el.id,
    }));
  });

  return parsed;
};

const NewSkillsSection: React.FC<any> = ({
  personId,
  workId,
  workLabel,
  transversal = false,
}) => {
  const { t } = useTranslation("common");
  const [selected, setSelected] = useState(0);
  const [reload, setReload] = useState(0);
  const wf = useWorkflow(personId);
  const [hasInvalidSkills, setHasInvalidSkills] = useState(false);
  const [workflowState, setWfState] = useState(wf.workflowState);
  const currentPersonId = useSelector((state: any) => state.user.person_id);
  const { context, isCollab } = useSelector((state: any) => state.auth);
  const needWorkflow = useSelector(
    (state: any) => state.home.paramjson.workflow
  );
  const [openWorkflowModal, setOpenWorkflowModal] = useState("");
  const { data, loading } = useFetch(
    `skill_categories/skills?r=${reload}${
      personId ? `&person.id=${personId}` : ""
    }${workId ? `&work.id=${workId}` : ""}${
      transversal ? "&transversal=true" : ""
    }`
  );

  useEffect(() => {
    setSelected(0);
  }, [transversal]);

  const [categories, setCategories] = useState<any>([]);

  const setWorkflowState = (nwf: any) => {
    if (workflowState.status > 10 && nwf.status === 10) {
      setReload((c) => c + 1);
    }
    setWfState(nwf);
  };

  useEffect(() => {
    setWorkflowState(wf.workflowState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wf.workflowState]);

  useEffect(() => {
    const categoriesArray =
      data?.["hydra:member"]?.map((el: any, index: number) => ({
        ...el,
        value: 0,
        theme: el.colors ?? defaultTheme,
      })) ?? [];

    setCategories(categoriesArray ?? []);
  }, [data]);

  const selectCategory = (index: number) => {
    setSelected(index === selected ? -1 : index);
  };

  const handleChanges = (category: any) => {
    setCategories((current: any) => {
      const categoryIndex = current.findIndex(
        (el: any) => el.id === category.id
      );
      const arr = [...current];
      arr[categoryIndex] = category;
      return arr;
    });
  };

  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    setReload((current) => current + 1);
  };

  const handleWorkflow = () => {
    const categoriesWithoutValidation = categories.filter(
      (c: any) =>
        c.children.filter(
          (cc: any) =>
            cc.skills.filter((sk: any) => sk.levels.validate === 0).length > 0
        ).length > 0
    );

    const categoriesInvalid = categories.filter(
      (c: any) =>
        c.children.filter(
          (cc: any) =>
            cc.skills.filter((sk: any) => sk.levels.validate === -1).length > 0
        ).length > 0
    );

    setHasInvalidSkills(categoriesInvalid?.length > 0);

    const allValid = categoriesWithoutValidation.length === 0;

    setOpenWorkflowModal(allValid ? "ok" : "missing");

    // try {
    //   await axios.post("/skill_workflows/validate", {
    //     person: `/api/people/${personId ?? currentPersonId}`,
    //   });
    // } catch (err) {}
  };

  return (
    <div>
      {/* {!workId && !personId && (
        <Box onClick={() => setWorkflow((c) => !c)} sx={{ mt: 2 }}>
          <BigButton>workflow</BigButton>
        </Box>
      )} */}

      {needWorkflow &&
        !transversal &&
        workflowState?.statusDates &&
        !workId &&
        workflowState?.statusDates?.[workflowState?.status ?? 0]?.date && (
          <DatesHeader>
            <Typography>
              {t(`skills.workflow.banner.status_${workflowState.status}`)}{" "}
              <Typography component="span">
                {getNumericDate(
                  new Date(
                    workflowState?.statusDates?.[workflowState.status]?.date
                  )
                )}
              </Typography>
            </Typography>
          </DatesHeader>
        )}

      <SButtonsContainer>
        {!workflowState.loading &&
          needWorkflow &&
          !transversal &&
          (((workflowState.status === 10 ||
            workflowState.status === 40 ||
            workflowState.status === 30 ||
            workflowState.status === 60) &&
            isCollab) ||
            ((workflowState.status === 20 || workflowState.status === 50) &&
              context === "manager")) &&
          !workId && (
            <Box onClick={handleWorkflow} flex={1}>
              <BigButton>
                {t("skills.workflow.valid_btn", {
                  context: isCollab ? "collab" : "manager",
                })}
              </BigButton>
            </Box>
          )}

        {!workId &&
          !personId &&
          (workflowState.status === 10 || transversal) && (
            <>
              <Box id="spacer" />
              <Box onClick={() => setOpen(true)} flex={1}>
                <BigButton>
                  {t(
                    `profilepage.skills_section.add${
                      transversal ? "_abilities" : ""
                    }`
                  )}
                </BigButton>
              </Box>
            </>
          )}
      </SButtonsContainer>

      {!loading && categories && (
        <Box display="grid">
          <CategoriesSlider
            categories={categories}
            selectCategory={selectCategory}
            selected={selected}
          />
        </Box>
      )}

      {!loading && categories?.length > 0 && selected >= 0 ? (
        <NewSkillsContainer
          workflowState={workflowState}
          compare={
            !isCollab && !personId
              ? false
              : workId ||
                workflowState.status === 20 ||
                workflowState.status === 40 ||
                workflowState.status === 50 ||
                workflowState.status === 30 ||
                workflowState.status === 60
          }
          personId={personId}
          workLabel={workLabel}
          canEdit={!personId && !workId}
          type={workId ? "work" : "workflow"}
          reload={() => setReload((current) => current + 1)}
          handleChanges={handleChanges}
          currentCategory={handleCategory(categories[selected])}
          transversal={transversal}
        />
      ) : null}

      {!loading && categories?.length === 0 && (
        <Typography textAlign="center">
          {t(`skills.none${transversal ? "_transversal" : ""}`)}
        </Typography>
      )}

      {/* {!workId && !personId && workflowState.status === 10 && (
        <Box onClick={() => setOpen(true)} sx={{ mt: 2 }}>
          <BigButton>Ajouter des compétences</BigButton>
        </Box>
      )} */}

      {loading && <Loader />}

      <SkillModal
        type={transversal ? "transversal" : ""}
        open={open}
        close={() => setOpen(false)}
        handleSubmit={handleSubmit}
      />

      <WorkflowModal
        open={openWorkflowModal}
        close={() => setOpenWorkflowModal("")}
        personId={personId ?? currentPersonId}
        workflowState={workflowState}
        setWorkflowState={setWorkflowState}
        hasInvalidSkills={hasInvalidSkills}
      />
    </div>
  );
};

export default NewSkillsSection;

const SButtonsContainer = styled("div")`
  display: flex;
  #spacer {
    width: 10px;
    height: 10px;
  }
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;

const DatesHeader = styled("div")(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.grey[400],
  borderRadius: "8px",
  textAlign: "center",
  padding: "8px 10px",
  marginBottom: theme.spacing(1),
  // "> *": {
  //   opacity: 0.8,
  // },
}));
