import { Box, CircularProgress } from "@mui/material";
import { Create, DeleteForever } from "@mui/icons-material";
import React, { useState } from "react";
import StyledButton from "../styled/StyledButton";
import projectsService from "../../services/projects.service";

const ProjectCardActions: React.FC<{
  loading: boolean;
  open: boolean;
  project: any;
  setOpen: (b: boolean) => void;
}> = ({ loading, setOpen, project }) => {
  const [loadingDelete, setLoading] = useState(false);

  const deleteItem = () => {
    setLoading(true);
    projectsService.remove(project.id).subscribe({
      next: () => {},
      error: () => {
        setLoading(false);
      },
    });
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <StyledButton
        sx={{ mb: 1 }}
        onClick={() => setOpen(true)}
        width={35}
        height={35}
      >
        {!loading ? (
          <Create color="primary" sx={{ width: 20, height: 20 }} />
        ) : (
          <CircularProgress size={20} sx={{ position: "absolute" }} />
        )}
      </StyledButton>
      <StyledButton
        disabled={loadingDelete}
        onClick={deleteItem}
        width={35}
        height={35}
      >
        {!loading ? (
          <DeleteForever color="error" sx={{ width: 20, height: 20 }} />
        ) : (
          <CircularProgress size={20} sx={{ position: "absolute" }} />
        )}
      </StyledButton>
    </Box>
  );
};

export default ProjectCardActions;
