import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import i18next from "i18next";
import { mergeDeep } from "./Utils";

const locales = ["fr", "en"];

const nav = navigator as any;
let lng = (
  localStorage.getItem("lng") ||
  nav.language ||
  nav["userLanguage"] ||
  "fr"
).split("-")[0];

if (!locales.includes(lng)) lng = locales[0];

if (!localStorage.getItem("lng")) localStorage.setItem("lng", lng);

export const i18nInit = {
  interpolation: { escapeValue: false }, // React already does escaping,
  lng,
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    fr: {
      common: common_fr,
    },
  },
};

export const seti18n = (labels?: any) => {
  let config: any = { ...i18nInit };

  if (labels) {
    const resources = { ...config.resources };
    const merged = mergeDeep(resources, {
      fr: { common: labels.fr ?? {} },
      en: { common: labels.en ?? {} },
    });
    config.resources = merged;
  }

  i18next.init(config);
};
