import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AdvisorBenefDropCard from "./AdvisorBenefDropCard";
import { IconButton, Typography } from "@mui/material";

const grid = 8;

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? "#dbf6ff" : "#ebebeb",
  padding: grid,
  width: 300,
  borderRadius: "0px 0px 8px 8px",
});

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list.benefs);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return { ...list, benefs: result };
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  source: any,
  destination: any,
  droppableSource: { index: number; droppableId: string | number },
  droppableDestination: { index: number; droppableId: string | number }
) => {
  const sourceClone = Array.from(source.benefs);
  const destClone = Array.from(destination.benefs);

  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  result[droppableSource.droppableId] = { ...source, benefs: sourceClone };
  result[droppableDestination.droppableId] = {
    ...destination,
    benefs: destClone,
  };

  return result;
};

const AdminAdvisorBenefsDropList: React.FC<any> = ({
  state,
  setState,
  removeAdvisor,
}) => {
  function onDragEnd(result: any) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState: any = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];
      setState(newState);
    }
  }

  const handleBack = (rc: any, bn: any) => {
    const newState = [...state];
    const advisorIndex = newState.findIndex((el: any) => el.id === rc.id);
    const oldAdvisorIndex = newState.findIndex(
      (el: any) => el.id === bn.advisorID
    );

    newState[advisorIndex].benefs = newState[advisorIndex].benefs.filter(
      (el: any) => el.id !== bn.id
    );

    if (oldAdvisorIndex >= 0) {
      newState[oldAdvisorIndex].benefs = [
        ...newState[oldAdvisorIndex].benefs,
        bn,
      ];
    }

    setState(newState);
  };

  return (
    <div style={{ display: "flex" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        {state.map((el: any, ind: any) => (
          <div
            key={ind}
            style={{ margin: "8px", borderRadius: "0px 0px 8px 8px" }}
          >
            <div
              style={{
                padding: "8px 8px 0px 8px",
                background: "#ebebeb",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography fontWeight="bold">
                  {el.advisor?.fullName}
                </Typography>{" "}
                <IconButton
                  style={{ marginLeft: "auto" }}
                  onClick={() => removeAdvisor(el.index)}
                  aria-label="delete"
                >
                  <CloseIcon color="error" sx={{ fontSize: "20px" }} />
                </IconButton>
              </div>
              <hr />
            </div>
            <div style={{ maxHeight: "500px" }}>
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {el.benefs?.map((item: any, index: number) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <AdvisorBenefDropCard
                            provided={provided}
                            snapshot={snapshot}
                            item={item}
                            moved={item.advisorID !== el.id}
                            handleBack={() => handleBack(el, item)}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        ))}
      </DragDropContext>
    </div>
  );
};

export default AdminAdvisorBenefsDropList;
