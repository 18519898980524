import { Divider, Box } from "@mui/material";
import React, { useContext } from "react";
import { AdminParamsContext } from "../../../contexts/AdminParamsContext";
import AdminSaveParamsButton from "../AdminSaveParamsButton";
import AdminFeedbacks from "./general/AdminFeedbacks";
import AdminGeneralInfos from "./general/AdminGeneralInfos";
import AdminGeneralMatchers from "./general/AdminGeneralMatchers";
import AdminGeneralTests from "./general/AdminGeneralTests";
import AdminGeneralWorks from "./general/AdminGeneralWorks";
import AdminJobs from "./general/AdminJobs";
import AdminLocation from "./general/AdminLocation";
import AdminReferential from "./general/AdminReferential";
import AdminShares from "./general/AdminShares";
import AdminSkills from "./general/AdminSkills";

const AdminGeneralParams: React.FC<any> = () => {
  const { parametersFetch, editParams } = useContext(AdminParamsContext);

  const params = parametersFetch?.data?.["hydra:member"]?.find(
    (el: any) => el.type === "GENERAL_PARAMS"
  );

  const handleChanges = (p: any, key: string) => {
    const cp = { ...params };
    cp.parameters[key] = p;

    editParams(cp, "GENERAL_PARAMS");
  };

  const obj = {
    param: params?.parameters,
    handleChanges,
  };

  return (
    <div>
      {params && (
        <Box sx={{ position: "relative" }}>
          <AdminGeneralInfos
            param={obj.param.INFO_GEN}
            handleChanges={(p: any) => handleChanges(p, "INFO_GEN")}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminGeneralTests
            param={obj.param.TESTS}
            handleChanges={(p: any) => handleChanges(p, "TESTS")}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminGeneralMatchers
            param={obj.param.MATCHERS}
            handleChanges={(p: any) => handleChanges(p, "MATCHERS")}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminGeneralWorks
            param={obj.param.WORK_DATA}
            handleChanges={(p: any) => handleChanges(p, "WORK_DATA")}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminFeedbacks
            param={obj.param.FEEDBACK}
            handleChanges={(p: any) => handleChanges(p, "FEEDBACK")}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminJobs
            param={obj.param.JOB_SUGGEST}
            handleChanges={(p: any) => handleChanges(p, "JOB_SUGGEST")}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminReferential
            param={obj.param.REFERENTIEL}
            handleChanges={(p: any) => handleChanges(p, "REFERENTIEL")}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminLocation
            param={obj.param.LOCATION}
            handleChanges={(p: any) => handleChanges(p, "LOCATION")}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminShares
            param={obj.param.SHARE}
            handleChanges={(p: any) => handleChanges(p, "SHARE")}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />

          <AdminSkills
            param={obj.param.SKILLS}
            handleChanges={(p: any) => handleChanges(p, "SKILLS")}
          />

          <AdminSaveParamsButton type="GENERAL_PARAMS" />
        </Box>
      )}
    </div>
  );
};

export default AdminGeneralParams;
