import { Box, Card, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TimerIcon from "@mui/icons-material/Timer";

const Root: any = styled(Card, {
  shouldForwardProp: (props) => props !== "type",
})(({ theme, type }: any) => ({
  position: "relative",
  padding: "5px",
  marginBottom: "2em",
  borderRadius: "11px !important",

  backgroundImage:
    type === "page"
      ? `url('/static/illustrations/test_page_illu.jpg')`
      : "auto",
  backgroundSize: type === "page" ? "cover" : "auto",
  backgroundRepeat: type === "page" ? "no-repeat" : "auto",
  backgroundPosition: type === "page" ? "center" : "auto",

  background:
    type === "page" ? "auto" : theme.palette.gradients[`${type}_test`],
}));

const IconContainer = styled("div")({
  width: "61px",
  height: "61px",
  borderRadius: "50%",
  backgroundColor: "rgba(255, 255, 255, 1);",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Text = styled(Typography)({ maxWidth: "76ch" });

export default function CardIllustrationTests({ test, height }: string | any) {
  const { t } = useTranslation("common");

  return (
    <Root
      sx={{
        height: `${height}px !important`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      type={test}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <IconContainer>
          <img
            src={`/static/icons/${
              test === "page" ? "test_page" : test
            }_logo.svg`}
            alt="customer icon"
          />
        </IconContainer>
        <Typography
          variant="h2"
          color="white"
          sx={{ lineHeight: { xs: 1.2, sm: 64 / 30 } }}
        >
          {test === "page"
            ? t("tests.page.title")
            : t(`tests.card_illustration.${test}_title`)}
        </Typography>
        <Text variant="body2" color="white" alignItems="center" display="flex">
          {test !== "page" && <TimerIcon sx={{ mr: 1 }} />}
          {test === "page"
            ? t("tests.page.text_page")
            : t(`tests.card_illustration.${test}_time`)}
        </Text>
      </Box>
    </Root>
  );
}
