import { Box, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const sortedKeys = [
  "excess_of_trust",
  "excess_of_self_confidence",
  "assertiveness",
  "lack_of_self_confidence",
  "lack_of_trust",
];

const Grid = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  width: 420px;
  gap: 10px;
  grid-gap: 10px;
  position: relative;
  @media screen and (max-width: 550px) {
    width: 320px;
  }
  @media screen and (max-width: 400px) {
    width: 280px;
  }
`;

const Root = styled("div")`
  display: flex;
  flex-direction: column;
`;

const Item = styled("div")`
  width: 130px;
  height: 130px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  text-align: center;
  span {
    margin-top: 5px;
    font-size: 12px;
    line-height: 12px;
  }
  cursor: pointer;
  /* border: 3px solid red; */
  padding: 5px;

  @media screen and (max-width: 550px) {
    width: 100px;
    height: 100px;
    span {
      margin-top: 5px;
      font-size: 10px;
      line-height: 10px;
    }
    padding: 2px;
    img {
      height: 50px;
    }
  }

  @media screen and (max-width: 400px) {
    width: 80px;
    height: 80px;
    span {
      margin-top: 5px;
      font-size: 9px;
      line-height: 9px;
    }
    padding: 2px;
    img {
      height: 40px;
    }
  }
`;

const Overlay = styled("div")`
  position: absolute;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const styles = [
  { gridColumn: "2/3", gridRow: 1, background: "rgba(76, 111, 255, 1)" },
  { gridColumn: "1/3", gridRow: 2, background: "rgba(76, 111, 255, 1)" },
  { gridColumn: "2/3", gridRow: 2, background: "rgba(76, 111, 255, 1)" },
  { gridColumn: "3/3", gridRow: 2, background: "rgba(76, 111, 255, 1)" },
  { gridColumn: "2/3", gridRow: 3, background: "rgba(76, 111, 255, 1)" },
];

const calculateOpacity = (
  value: number,
  minValue: number,
  maxValue: number
) => {
  const minOpacity = 0.1;
  const maxOpacity = 1;

  const res =
    ((value - minValue) / (maxValue - minValue)) * (maxOpacity - minOpacity) +
    minOpacity;

  return res > 1 ? 1 : res;
};

const colors: any = {
  green: "#1EC626",
  red: "#F58A1E",
};

const IncGroupSquares: React.FC<{ result: any; compare?: boolean }> = ({
  result,
  compare = false,
}) => {
  const [selected, setSelected] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const { t } = useTranslation("common");
  const [sortedList, setSortedList] = useState<any>([]);

  useEffect(() => {
    if (result) {
      const values = result.map((el: any) => el.score);
      const minValue = Math.min(...values);
      const maxValue = Math.max(...values);

      const r = [];
      for (let i = 0; i < sortedKeys.length; i++) {
        const item: any = result.find((el: any) => el.key === sortedKeys[i]);
        r.push({
          ...item,
          score: item.score,
          style: {
            ...styles[i],
            ...(item.color && compare
              ? { border: `3px solid ${colors[item.color]}` }
              : {}),
            background: `rgba(76, 111, 255, ${calculateOpacity(
              item.score,
              minValue,
              maxValue
            )})`,
          },
        });
      }

      setSortedList(r);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const select = (key: string, index: number) => {
    setSelected(key);
    setSelectedIndex(index);
  };

  const reset = () => {
    setSelected(null);
    setSelectedIndex(null);
  };

  return (
    <Root>
      <Grid>
        {sortedList.map((e: any, i: number) => (
          <Item
            onClick={() => select(e.key, i)}
            key={e.key}
            style={{
              ...e.style,
              // transform: `scale(${selected === i ? 1.1 : 1})`,
              zIndex: selected === e.key ? 3 : 1,
            }}
          >
            <HelpOutlineRoundedIcon
              sx={{
                position: "absolute",
                right: 5,
                top: 5,
                color: e.score > 20 ? "white" : "#585858",
                width: "20px",
              }}
            />

            {/* <span>XX%</span> */}
            <img
              src={`/static/icons/Icons-dyn-group/${e.key}.svg`}
              alt="icon"
            />
            <Typography
              component="span"
              sx={{ color: e.score > 20 ? "white" : "#585858" }}
            >
              {t(`inc.key.${e.key}`)}
            </Typography>
          </Item>
        ))}
        <Overlay
          style={{
            zIndex: selectedIndex >= 0 && selectedIndex !== null ? 2 : 0,
          }}
        ></Overlay>
        <Overlay
          onClick={reset}
          style={{
            display:
              selectedIndex >= 0 && selectedIndex !== null ? "inherit" : "none",
            zIndex: selectedIndex >= 0 && selectedIndex !== null ? 4 : 0,
            background: "rgba(255, 255, 255, 0.6)",
          }}
        >
          {selectedIndex !== null && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2>{t(`inc.key.${selected}`)}</h2>

              <ul>
                {(
                  t(`inc.group_list.${selected}`, {
                    returnObjects: true,
                  }) as Array<string>
                )?.map((el) => (
                  <li key={el}>{el}</li>
                ))}
              </ul>
            </div>
          )}
        </Overlay>
      </Grid>
      {compare && <SquaresLegend />}
    </Root>
  );
};

export default IncGroupSquares;

const SquaresLegend = () => {
  const { t } = useTranslation("common");

  const colors = ["green", "red"];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      {colors.map((item: string, index: number) => (
        <Box
          key={item + index}
          display="flex"
          alignItems="center"
          sx={{
            m: 2,
            background: item === "green" ? "#ECFCEC" : "#FEEEDF",
            padding: "10px",
            borderRadius: "9px",
          }}
        >
          {item === "green" ? (
            <CheckCircleIcon
              color="success"
              sx={{ fontSize: "12px", mr: "5px" }}
            />
          ) : (
            <CheckCircleIcon
              sx={{ color: "#F58A1E", fontSize: "12px", mr: "5px" }}
            />
          )}
          <Typography
            sx={{
              color: item === "green" ? "#1EC626" : "#F58A1E",
            }}
            variant="body2"
          >
            {t(`inc.irritant.${item === "green" ? "compatible" : "difficult"}`)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
