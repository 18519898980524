import { Box, Typography, styled } from "@mui/material";
import React from "react";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ProfileCtn = styled("div")`
  flex: 1;
  background: #e8e6f850;
  padding: 20px;
  border-radius: 25px;
`;

const RisksCtn = styled("div")`
  flex: 1;
  background: #fcf7ef;
  padding: 20px;
  border-radius: 25px;
`;

const Root = styled("div")`
  display: flex;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const Big5TraitInfos: React.FC<any> = ({ traits, trait }) => {
  const context = useSelector((state: any) => state.auth.context);
  const { t } = useTranslation("common");
  return (
    <Root>
      <ProfileCtn>
        <Box
          sx={{ color: "#4c6fff", fontWeight: "bold", mb: 1 }}
          display="flex"
          alignItems="center"
        >
          <Box
            sx={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#4c70ff21",
              marginRight: 1,
            }}
          >
            <FaceOutlinedIcon />
          </Box>
          <Typography>{t("big5.result.profile", { context })}</Typography>
        </Box>

        <Typography variant="body1">
          {
            traits?.[traits?.findIndex((res: any) => res?.key === trait)]
              ?.profile
          }
        </Typography>
      </ProfileCtn>

      <Box sx={{ width: "20px", height: "20px" }} />

      <RisksCtn>
        <Box
          sx={{ color: "#e4b266", fontWeight: "bold", mb: 1 }}
          display="flex"
          alignItems="center"
        >
          <Box
            sx={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#F7E9D3",
              marginRight: 1,
            }}
          >
            <WarningAmberOutlinedIcon sx={{ width: "20px" }} />
          </Box>
          <Typography>{t("big5.result.risks", { context })}</Typography>
        </Box>
        <Typography variant="body1">
          {traits?.[traits?.findIndex((res: any) => res?.key === trait)]?.risk}
        </Typography>
      </RisksCtn>
    </Root>
  );
};

export default Big5TraitInfos;
