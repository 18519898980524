export const checkQuestionType = (question: any) => {
  switch (question.type) {
    case "1":
      if (question.details.max_selection_choices > 1) {
        return "checkbox";
      } else {
        return "radio";
      }
    case "5":
      return "hierarchy";
    case "7":
      return "cursor";
    default:
      return "checkbox";
  }
};
