import React from "react";
import Page from "../Page";

import IncTest from "./test/IncTest";

// let counter = 1;

const IncTestsPage: React.FC<any> = () => {
  return (
    <Page padding sx={{ pt: 10, pb: 5 }} title="Inc">
      <IncTest />
    </Page>
  );
};

export default IncTestsPage;
