import { Box, Fade, styled, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../contexts/ProfileContext";
import Loader from "../../Loader";
import BigButton from "../common/BigButton";
import HobbieCard from "./HobbieCard";
import HobbiesModal from "./HobbiesModal";
import ProfileSectionsHeader from "./ProfileSectionsHeader";

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.grey[500],
}));

const Grid = styled(Box)({
  rowGap: "24px",
  width: "auto",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill,minmax(160px, 1fr))",
});

const HobbiesSection: React.FC<{ notCollab: boolean; employeeData?: any }> = ({
  notCollab,
  employeeData,
}) => {
  const { t } = useTranslation("common");
  const { peopleData, peopleResponse, fullName } = useContext(ProfileContext);
  const hobbies = employeeData
    ? employeeData?.hobbies
    : peopleData?.hobbies || [];
  const [open, setOpen] = useState(false);

  return (
    <div>
      {peopleResponse?.loading ? (
        <Loader />
      ) : (
        <Fade in={true} timeout={500}>
          <div>
            <ProfileSectionsHeader
              title={t("profilepage.hobbies_section.title")}
              subtitle={
                hobbies.length > 0
                  ? t(
                      `profilepage.hobbies_section${
                        notCollab ? ".not_collab" : ""
                      }.count`,
                      {
                        count: hobbies.length,
                        name: notCollab && fullName,
                      }
                    )
                  : ""
              }
              text={t("profilepage.hobbiesDescription")}
              icon={
                <img
                  src="/static/icons/happy.svg"
                  alt="hobbies icon"
                  color="primary"
                />
              }
              notCollab={notCollab}
            />

            {/* ADD BUTTON */}
            {!notCollab && (
              <Box sx={{ mb: 2 }}>
                <BigButton onClick={() => setOpen(true)}>
                  <Typography variant="body1" sx={{ fontWeight: "inherit" }}>
                    {t("profilepage.hobbies_section.add")}
                  </Typography>{" "}
                  <AddCircle sx={{ ml: 1 }} />
                </BigButton>
              </Box>
            )}

            {hobbies.length > 0 ? (
              <Grid>
                {hobbies.map((el: string, i: number) => (
                  <HobbieCard
                    key={el + i}
                    hobbie={el}
                    index={i}
                    notCollab={notCollab}
                  />
                ))}
              </Grid>
            ) : (
              <Box sx={{ mt: 5, textAlign: "center", width: "100%" }}>
                <Subtitle>
                  {t(
                    `profilepage.hobbies_section${
                      notCollab ? ".not_collab" : ""
                    }.empty`,
                    {
                      name: fullName,
                    }
                  )}
                </Subtitle>
              </Box>
            )}

            <HobbiesModal open={open} close={() => setOpen(false)} />
          </div>
        </Fade>
      )}
    </div>
  );
};

export default HobbiesSection;
