import { Box, styled, Typography } from "@mui/material";
import React from "react";
import chatService from "../../services/chat.service";
import StringAvatar from "../common/StringAvatar";

const LightTypography: any = styled(Typography)(({ theme }: any) => ({
  position: "absolute",
  width: "18px",
  height: "18px",
  minWidth: "18px",
  minHeight: "18px",
  border: "solid 3px #fff",
  borderRadius: theme.shape.borderRadius48,
  left: "25px",
  top: "25px",
  alignmentBaseline: "baseline",
  zIndex: 10,
}));

const AvatarsOfPickedAutocompleteMembers: React.FC<{ ACInputValue: any[] }> = ({
  ACInputValue,
}) => {
  return (
    <>
      {ACInputValue?.map((member: any) => (
        <Box
          key={member?.id + "avatarAC"}
          sx={{ display: "flex", flexDirection: "row", padding: "5px" }}
        >
          <Box sx={{ position: "relative" }}>
            <StringAvatar
              size={40}
              name={
                member?.candidate?.firstName + " " + member?.candidate?.lastName
              }
              url={member?.candidate?.image?.contentUrl}
            />
            <LightTypography
              component="span"
              sx={{ backgroundColor: chatService.getColorByStatus(null) }}
            />
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              {member?.candidate?.firstName + " " + member?.candidate?.lastName}
            </Typography>
            {/* <Typography>{"some complemntary info :p"}</Typography> */}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default AvatarsOfPickedAutocompleteMembers;
