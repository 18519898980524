import useFetch from "../../hooks/useFetch";
import TestsAPI from "../../_api/_testsAPI";
import IncIntro from "./IncIntro";
import IncResult from "./IncResult";
import React, { useContext, useEffect, useState } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import { Button, Box, Dialog, Typography } from "@mui/material";
import SplashScreenTest from "../common/SplashScreenTest";
import { useLocation, useNavigate } from "react-router-dom";
import LogoLoader from "../LogoLoader";
import IncTest from "./test/IncTest";
import { IncTestContext } from "./test/IncTestContext";
import { useSelector, shallowEqual } from "react-redux";
import { Utils } from "../../Utils";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTranslation } from "react-i18next";

const IncContainer: React.FC<{
  onboarding?: boolean;
  nextStep?: any;
  onboardingStep?: number;
  canSkip?: boolean;
  handlePrevious?: () => void;
}> = ({ onboarding, nextStep, onboardingStep, handlePrevious, canSkip }) => {
  const { t } = useTranslation("common");
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const [openSplashScreen, setOpenSplashScreen] = useState(false);
  const navigate = useNavigate();
  const { showResults, step, setStep } = useContext(IncTestContext);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query: any = useQuery();

  useEffect(() => {
    const url = `${process.env.REACT_APP_MERCURE_URL}?topic=/api/users/${tokenData?.user_id}`;
    let eventSource: any;

    if (tokenData) {
      eventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: `Bearer ${tokenData?.mercure}`,
        },
      });

      eventSource.onmessage = (e: any) => {
        setOpenSplashScreen(true);
        setRealodCall(true);
        setTimeout(() => {
          setOpenSplashScreen(false);
        }, 6500);
      };
    }

    return () => {
      eventSource?.close();
    };
  }, [tokenData]);

  useEffect(() => {
    if (showResults) updateSTep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResults]);

  const [realodCall, setRealodCall] = useState(false);

  const { data, loading } = useFetch(
    TestsAPI.getById("inc/result", { reload: realodCall })
  );

  const updateSTep = () => {
    step + 1 === 2 && setRealodCall(true);
    setStep(step + 1);
  };

  // const abortTest = () => {
  //   setStep(data?.results?.isPassed ? 2 : 0);
  // };

  const goToTests = () => {
    if (!onboarding) {
      navigate("/app/inc/test");
    } else {
      setStep(1);
    }
  };

  const skipStep = () => {
    const newState: any =
      Utils.getCookie(`${tokenData.person_id}_do_later_step`) || [];
    newState?.push("motivations");
    Utils.setLongCookies(`${tokenData.person_id}_do_later_step`, newState);
    nextStep();
  };

  return (
    <>
      {!loading ? (
        <div>
          {(step === 0 && !data?.results) || query.get("intro") === "true" ? (
            <>
              {onboardingStep && onboardingStep > 1 && (
                <Button onClick={handlePrevious} variant="outlined">
                  <KeyboardBackspaceIcon sx={{ fontSize: "20px" }} />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {t("onboarding.previous_btn")}
                  </Typography>
                </Button>
              )}
              <IncIntro
                nextStep={goToTests}
                skipTest={canSkip ? skipStep : handlePrevious}
                onboarding={onboarding}
                onboardingStep={onboardingStep}
                canSkip={canSkip}
              />
            </>
          ) : step === 1 && !data?.results ? (
            <Box sx={{ width: "100%" }}>
              <IncTest
                onBoarding={onboarding}
                nextStep={canSkip ? nextStep : handlePrevious}
              />
            </Box>
          ) : (
            (step === 2 || data?.results) && (
              <>
                {onboardingStep && onboardingStep > 1 && (
                  <Button onClick={handlePrevious} variant="outlined">
                    <KeyboardBackspaceIcon sx={{ fontSize: "20px" }} />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {t("onboarding.previous_btn")}
                    </Typography>
                  </Button>
                )}
                <IncResult
                  result={data?.results?.results}
                  nextStep={nextStep}
                  onboarding={onboarding}
                />
              </>
            )
          )}
        </div>
      ) : (
        <LogoLoader open={true} />
      )}
      <Dialog open={openSplashScreen} transitionDuration={{ exit: 1250 }}>
        <SplashScreenTest test="inc" />
      </Dialog>
    </>
  );
};

export default IncContainer;
