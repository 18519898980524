import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography, Tooltip, styled } from "@mui/material";
import { useNavigate } from "react-router";

const Root: any = styled(Box, {
  shouldForwardProp: (props) => props !== "done" && props !== "test",
})(({ theme, done, test }: any) => ({
  borderRadius: "12px",
  padding: "15px",
  width: "250px",
  paddingBottom: theme.spacing(5),
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "260px",
  textAlign: "center",
  marginBottom: "1em",
  backgroundImage: done
    ? theme.palette.gradients[`${test}_linear`]
    : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23C6C6C6FF' stroke-width='2' stroke-dasharray='12' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e")`,
}));

const IconContainer: any = styled("div", {
  shouldForwardProp: (props) => props !== "test",
})(({ theme, test }: any) => ({
  width: "75px",
  height: "75px",
  borderRadius: "50%",
  position: "relative",
  backgroundColor: test
    ? theme.palette.tests[`${test}_light`]
    : theme.palette.background.neutral,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Title: any = styled(Typography, {
  shouldForwardProp: (props) => props !== "done" && props !== "test",
})(({ theme, done, test }: any) =>
  !done && test
    ? {
        color: theme.palette.tests[test],
        height: "30px",
      }
    : {
        color: theme.palette.common.white,
        height: "30px",
      }
);

const TestButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "test",
})(({ theme, test }: any) => ({
  width: "160px",
  height: "37px",
  borderRadius: theme.shape.borderRadius10,
  fontSize: "12px",
  backgroundImage: test ? theme.palette.gradients[`${test}_linear`] : "auto",
  "&.b5_finish": {
    backgroundColor: "white",
    color: "#377BFF",
    backgroundImage: "none!important",
  },
  "&.cf_finish": {
    backgroundColor: "white",
    color: "#FD7588",
    backgroundImage: "none!important",
  },
  "&.inc_finish": {
    backgroundColor: "white",
    color: "#E683FC",
    backgroundImage: "none!important",
  },
  "&.mood_finish": {
    backgroundColor: "white",
    color: "#ff9b3d",
    backgroundImage: "none!important",
  },
}));

const CardTest: React.FC<{ test: string; item: any; progress: number }> = ({
  test,
  item,
  progress,
}) => {
  const { t }: any = useTranslation("common");
  const navigate = useNavigate();

  const redirect = () => {
    navigate(
      `/app/${
        test === "b5"
          ? "big5?intro=true"
          : test === "cf"
          ? "culturefit?intro=true"
          : test === "mood"
          ? "mood"
          : "inc?intro=true"
      }`
    );
  };

  return (
    <Root done={progress === 100} test={test}>
      <Tooltip
        title={
          <div
            dangerouslySetInnerHTML={{
              __html: t(`tests.tooltip.${test}`),
            }}
          />
        }
      >
        <HelpOutlineIcon
          sx={{
            opacity: progress === 100 ? "" : "0.4",
            display: "flex",
            ml: "auto",
            width: "21px",
            color: progress === 100 ? "white" : "",
            cursor: "pointer",
          }}
        />
      </Tooltip>
      <IconContainer test={test}>
        {progress === 100 && (
          <Box
            style={{
              position: "absolute",
              right: "-10px",
            }}
          >
            <img
              style={{ width: "25px" }}
              src="/static/icons/check.svg"
              alt="check icon"
            />
          </Box>
        )}
        <img src={`/static/icons/${test}_logo.svg`} alt="customer icon" />
      </IconContainer>
      <Title
        sx={{ mt: 2, lineHeight: "14px" }}
        variant="subtitle2"
        test={test}
        done={progress === 100}
      >
        {t(`tests.page.test_of`)} {t(`tests.${test}_little`)}
      </Title>
      <TestButton
        sx={{ mt: 3 }}
        onClick={redirect}
        disabled={!item.canPass}
        variant="contained"
        test={test}
        className={progress === 100 && `${test}_finish`}
      >
        {item.canPass
          ? t(
              progress > 0 && progress < 100
                ? "tests.modal.finish"
                : progress > 0
                ? "tests.page.retry"
                : "tests.page.btn_test"
            )
          : t("tests.done")}
      </TestButton>
    </Root>
  );
};

export default CardTest;
