import { Box, styled, Typography } from "@mui/material";
import React from "react";

const Root = styled(Box)({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  marginBottom: "11px",
  width: 55,
  height: 55,
  background: theme.palette.primary[500_8],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Title = styled(Typography)(({ theme }: any) => ({
  backgroundColor: theme.palette.grey[100],
  padding: "7px 14px",
  borderRadius: "6px",
}));

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.grey[500],
}));

const ProfileSectionsHeader: React.FC<{
  title: string;
  subtitle?: string;
  text: string;
  icon: any;
  notCollab?: boolean;
}> = ({ title, subtitle, text, icon, notCollab }) => {
  return (
    <Root>
      <Icon>{icon}</Icon>

      <Box sx={{ display: "flex", alignItems: "center", mb: "13px" }}>
        <Title variant="subtitle1">{title}</Title>
      </Box>

      <Typography
        color="primary"
        variant="subtitle2"
        sx={{ lineHeight: "17px", mb: 1 }}
      >
        {subtitle}
      </Typography>

      {!notCollab && (
        <Subtitle variant="body1" sx={{ mb: 2, lineHeight: "17px" }}>
          {text}
        </Subtitle>
      )}
    </Root>
  );
};

export default ProfileSectionsHeader;
