import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayProfile: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");
  return (
    <div>
      <Typography variant="h4">{t("admin.params.profile.title")}</Typography>
      <FormGroup>
        <Typography>{t("admin.params.profile.text")}</Typography>
        <FormControlLabel
          disabled={!param.EXP && param.TRAINING}
          value={param.TRAINING}
          onChange={(e: any) =>
            handleChanges({ ...param, TRAINING: e.target.checked })
          }
          control={<Checkbox checked={param.TRAINING} />}
          label={t("admin.params.profile.training")}
        />
        <FormControlLabel
          disabled={param.EXP && !param.TRAINING}
          value={param.EXP}
          onChange={(e: any) =>
            handleChanges({ ...param, EXP: e.target.checked })
          }
          control={<Checkbox checked={param.EXP} />}
          label={t("admin.params.profile.exp")}
        />
      </FormGroup>

      <Typography textAlign="right">{t("admin.params.reco_text")}</Typography>
    </div>
  );
};

export default AdminDisplayProfile;
