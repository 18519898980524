import React, { Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import LogoLoader from "../components/LogoLoader";

import FullLayout from "../layout/FullLayout";
import NoMenuLayout from "../layout/NoMenuLayout";
import PersonWorkSuggest from "../pages/PersonWorkSuggest";
import ProfilePage from "../pages/ProfilePage";
import ProjectsPage from "../pages/ProjectsPage";
import SuccessionPage from "../pages/RH-Manager/SuccessionPage";

import ProfileContextProvider from "./../contexts/ProfileContext";
import { useSelector } from "react-redux";

// import RhEmployeesContextProdiver from "./../contexts/RhEmployeesContext";
const RessourcesPage = React.lazy(() => import("../pages/RessourcesPage"));
const OnboardingPage = React.lazy(() => import("../pages/OnboardingPage"));
const CguPage = React.lazy(() => import("../pages/CguPage"));
const NotFound = React.lazy(() => import("../pages/NotFound"));
const RHHome = React.lazy(() => import("../pages/RH-Manager/RHHome"));
const AccountDataPage = React.lazy(() => import("../pages/AccountDataPage"));
const DeactivateReminder = React.lazy(
  () => import("../pages/DeactivateReminder")
);
const AccountParamsPage = React.lazy(
  () => import("../pages/AccountParamsPage")
);
const RhEmployeesPage = React.lazy(
  () => import("../pages/RH-Manager/RhEmployeesPage")
);
const WorkPage = React.lazy(() => import("../pages/WorkPage"));
const WorkListPage = React.lazy(() => import("../pages/WorkListPage"));
const SuggestPage = React.lazy(
  () => import("../components/RH-Manager/SuggestPage")
);
const JobSuggestPage = React.lazy(() => import("../pages/JobSuggestPage"));
const RHSkillsPage = React.lazy(
  () => import("../pages/RH-Manager/RHSkillsPage")
);

const Router: React.FC = () => {
  const menu = useSelector((state: any) => state.menu);

  const baseRedirect =
    sessionStorage.getItem("disconnected-path") ?? "/app/home";

  return useRoutes([
    {
      path: "login",
      element: <Navigate to={baseRedirect} replace />,
    },
    {
      path: "app",
      element: <FullLayout />,
      children: [
        { path: "", element: <Navigate to="/app/home" replace /> },
        {
          path: "home",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RHHome />
            </Suspense>
          ),
        },
        {
          path: "employees",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              {/* <RhEmployeesContextProdiver> */}
              <RhEmployeesPage />
              {/* </RhEmployeesContextProdiver> */}
            </Suspense>
          ),
        },
        {
          path: "skills",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RHSkillsPage />
            </Suspense>
          ),
        },
        menu?.jobs && {
          path: "job/suggest",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <JobSuggestPage />
            </Suspense>
          ),
        },
        {
          path: "work",
          children: [
            { path: "", element: <Navigate to="/app/work/list" /> },
            {
              path: "list",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkListPage />
                </Suspense>
              ),
            },
            {
              path: ":id",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkPage />
                </Suspense>
              ),
            },
            {
              path: ":id/:personId",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkPage />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "suggest/:id",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <SuggestPage />
            </Suspense>
          ),
        },
        // common
        menu?.settings && {
          path: "account",
          children: [
            {
              path: "",
              element: <Navigate to="/app/account/params" replace />,
            },
            {
              path: "params",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <AccountParamsPage />
                </Suspense>
              ),
            },
            {
              path: "data",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <AccountDataPage />
                </Suspense>
              ),
            },
          ],
        },
        menu?.resources && {
          path: "resources",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RessourcesPage />
            </Suspense>
          ),
        },
        {
          path: "profile/:id/work-suggest",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <PersonWorkSuggest />
            </Suspense>
          ),
        },
        {
          path: "profile/:id/succession",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <SuccessionPage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "profile/:id/projects",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProjectsPage />
            </Suspense>
          ),
        },
        {
          path: "profile/:id/:tab",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <ProfilePage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
      ].filter((el: any) => el),
    },

    // Common
    {
      path: "",
      element: <NoMenuLayout />,
      children: [
        {
          path: "onboarding",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <OnboardingPage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "cgu",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <CguPage />
            </Suspense>
          ),
        },
        {
          path: "deactivate_reminder",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <DeactivateReminder />
            </Suspense>
          ),
        },
        {
          path: "404",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <NotFound />
            </Suspense>
          ),
        },
        { path: "/", element: <Navigate to="/app" replace /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
};

export default Router;
