import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from "@mui/material";
import { TestType } from "../../@types/testType";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import DeleteResultsModal from "../tests/DeleteResultsModal";
import ResultsAPI from "../../_api/_resultsAPI";
import DownloadPdfButton from "../common/DownloadPdfButton";
import { ProfileContext } from "../../contexts/ProfileContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: { width: "100% !important" },
  width: "100%",
  height: 16,
  borderRadius: 10,
  backgroundColor: theme.palette.action.disabledBackground,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    background: theme.palette.gradients.cf_linear,
  },
}));

const CustomButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "grey",
})(({ theme, grey }: any) => ({
  height: "44px",
  fontSize: "12px",
  color: grey ? theme.palette.grey[500] : "auto",
}));

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const Arrow: any = styled(ExpandMoreIcon, {
  shouldForwardProp: (props) => props !== "down",
})(({ theme, down }: any) => ({
  color: theme.palette.secondary[400],
  opacity: 0.6,
  transition: "all 0.25s ease-in-out",
  transform: down ? "rotate(180deg)" : "rotate(0deg)",
}));

const CulturefitResult: React.FC<{
  result: TestType;
  deleteResult?: any;
  retryTest?: any;
  nextStep?: any;
  onboarding?: boolean;
  notCollab?: boolean;
  lastStep?: boolean;
}> = ({
  result,
  deleteResult,
  retryTest,
  nextStep,
  onboarding,
  notCollab,
  lastStep,
}) => {
  const { t }: { t: any } = useTranslation("common");
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [highestScore, setHighestScore] = useState<any>(0);

  const [collapsedComments, setCollapsedComments] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const profile = useContext(ProfileContext);

  const { isCollab } = useSelector((state: any) => state.auth);
  const home = useSelector((state: any) => state.home);

  // const replayTest = () => {
  //   retryTest(true);
  // };

  const expandComment = (index: number) => {
    let newArr = [...collapsedComments];
    newArr[index] = !newArr[index];
    setCollapsedComments(newArr);
  };

  const redirectTests = () => {
    navigate("/app/tests");
  };

  const getPourcentageValue = (value: number): number => {
    return (value / highestScore) * 100;
  };

  const deleteResults = () => {
    const id: any = result?.results?.id;
    ResultsAPI.delete(id).then((res) => {
      deleteResult(true);
    });
  };

  useEffect(() => {
    result?.results &&
      setHighestScore(Object.values(result?.results?.results)[0]);
  }, [result?.results, result?.results?.results]);

  return (
    <Card
      sx={{
        width: { xs: "95%", sm: "100%" },
        boxShadow: onboarding ? "none" : "auto",
      }}
    >
      <CardContent>
        <Box display="flex" width="100%" justifyContent="center" sx={{ mb: 2 }}>
          <Box display="flex" alignItems="center">
            <img src="/static/icons/cf_logo.svg" alt="big5 logo" />
            <Typography textAlign="center" variant="h3" sx={{ ml: 2 }}>
              {t("tests.cf")}
            </Typography>
          </Box>
        </Box>
        {!notCollab && (
          <>
            <Divider />
            <Box display="flex" justifyContent="center">
              <div>
                <Typography
                  textAlign="center"
                  sx={{
                    mt: 2,
                    mb: 3,
                    fontWeight: "normal",
                    width: "100%",
                    maxWidth: "900px",
                  }}
                  variant="subtitle1"
                >
                  {t("culturefit.result.text_1")}
                </Typography>
                <Subtitle
                  textAlign="center"
                  sx={{ mt: 2, mb: 3, width: "100%" }}
                  variant="body1"
                >
                  {t("culturefit.result.text_2")}
                </Subtitle>
              </div>
            </Box>
          </>
        )}
        {result?.results && (
          <Box display="flex" flexDirection="column" alignItems="center">
            {Object.values(result?.results?.results).map(
              (item: any, index: number) => (
                <Box
                  key={Object.keys(result?.results?.results)[index]}
                  sx={{
                    mt: index > 0 ? 2 : 0,
                    width: { xs: "100%", sm: "80%" },
                  }}
                  display="flex"
                >
                  <Box sx={{ width: "90%" }}>
                    <Typography variant="subtitle1" sx={{ maxWidth: "300px" }}>
                      {t(
                        `culturefit.traits.${Object.keys(
                          result?.results?.results
                        )[index].toLowerCase()}`
                      )}
                    </Typography>
                    <BorderLinearProgress
                      variant="determinate"
                      value={getPourcentageValue(item)}
                    />
                    <Collapse in={collapsedComments[index]} unmountOnExit>
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        {t(
                          `culturefit.description.${Object.keys(
                            result?.results?.results
                          )[index].toLowerCase()}`
                        )}
                      </Typography>
                    </Collapse>
                  </Box>
                  <Box sx={{ ml: "auto", mt: 0.5 }}>
                    <IconButton
                      aria-label="expand button"
                      sx={{ ml: "auto" }}
                      onClick={() => expandComment(index)}
                    >
                      <Arrow fontSize="large" down={collapsedComments[index]} />
                    </IconButton>
                  </Box>
                </Box>
              )
            )}
          </Box>
        )}

        <>
          <Divider sx={{ mt: 4 }} />
          <Box
            sx={{ mt: 2 }}
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
          >
            {((home?.paramjson?.candidatePdf && isCollab) || !isCollab) && (
              <Box sx={{ m: 1 }}>
                <DownloadPdfButton
                  person={profile?.peopleData}
                  height="44px"
                  variant="outlined"
                  type="cf"
                />
              </Box>
            )}
            {!notCollab && (
              <>
                {!onboarding ? (
                  <>
                    <CustomButton
                      sx={{ m: 1 }}
                      variant="outlined"
                      color="inherit"
                      grey
                      onClick={() => setOpenModal(true)}
                    >
                      {t("tests.page.delete")}
                    </CustomButton>
                    {!window.location.pathname.includes("app/tests") && (
                      <CustomButton
                        sx={{ m: 1 }}
                        variant="outlined"
                        onClick={redirectTests}
                      >
                        {t("tests.page.return_to_result")}
                      </CustomButton>
                    )}
                  </>
                ) : (
                  <CustomButton
                    variant="contained"
                    onClick={nextStep}
                    sx={{ width: "150px", m: 1 }}
                  >
                    {t(lastStep ? "onboarding.finish_btn" : "btn.next")}
                  </CustomButton>
                )}
              </>
            )}
          </Box>
        </>
      </CardContent>
      <DeleteResultsModal
        open={openModal}
        close={() => setOpenModal(false)}
        deleteResults={() => deleteResults()}
      />
    </Card>
  );
};

export default CulturefitResult;
