import { List } from "@mui/material";
import React from "react";
// import LogoutButton from "../components/auth/LogoutButton";
import MenuNav from "./MenuNav";
// import { useSelector } from "react-redux";

// const NavItem = styled(LogoutButton)({
//   marginTop: 12,
//   paddingTop: 12,
//   paddingBottom: 12,
//   marginBottom: -4,
//   paddingLeft: 36,
// });

const NavigationRenderer: React.FC<{
  links: any[];
  handleLinkClick: any;
}> = ({ links, handleLinkClick }: any) => {
  // const context = useSelector((state: any) => state.auth.context);

  return (
    <List>
      <MenuNav handleLinkClick={handleLinkClick} links={links} />
      {/* <NavItem padding={3} context={context} iconSize={22} /> */}
    </List>
  );
};

export default NavigationRenderer;
