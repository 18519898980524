import { Card, Box, Typography, Skeleton } from "@mui/material";
import React from "react";

const WorkListItemSkeleton: React.FC<{
  size?: "small" | "medium";
  inProfile?: boolean;
}> = ({ size, inProfile }) => {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        p: "15px",
        mt: size === "small" ? "10px" : "15px",
      }}
    >
      <Skeleton variant="circular" width="50px" height="50px" />
      <Box sx={{ ml: { xs: 1, sm: 2 } }} display="flex" flexDirection="column">
        <Typography variant="body1">
          <Skeleton width="300px" />
        </Typography>
        {!inProfile && (
          <Skeleton
            variant="rectangular"
            width="150px"
            height="31px"
            sx={{ mt: "8px", borderRadius: "7px" }}
          />
        )}
      </Box>

      {inProfile ? (
        <Box sx={{ ml: "auto" }}>
          <Skeleton
            variant="rectangular"
            width="126px"
            height="30px"
            sx={{ borderRadius: "15px" }}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "column", md: "row" },
              alignItems: "center",
              ml: "auto",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              sx={{ position: "relative", width: "80px", mt: 0.5 }}
            >
              <Skeleton variant="circular" height="40px" width="40px" />
              <Typography variant="body2" sx={{ mt: 1.5 }}>
                <Skeleton width="35px" sx={{ margin: "auto" }} />
                <Skeleton width="45px" sx={{ margin: "auto" }} />
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Card>
  );
};

export default WorkListItemSkeleton;
