import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

const starSize = "40px";
const SStarFilled = styled(StarRoundedIcon)`
  color: #ffce71;
  font-size: ${starSize};
`;

const MoodFilter: React.FC<any> = ({ value, onChange }) => {
  const [moodFilter, setMoodFilter] = useState<any>(
    value?.[0] >= 0 ? value : []
  );

  useEffect(() => {
    onChange(moodFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moodFilter]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: number
  ) => {
    const checked = event.target.checked;
    if (checked) setMoodFilter((current: any) => [...current, value]);
    else
      setMoodFilter((current: any) =>
        current.filter((val: any) => val !== value)
      );
  };

  return (
    <Box sx={{ pl: 1, pr: 1 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              disabled={moodFilter?.length === 1 && moodFilter[0] === 3}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event, 3)
              }
              checked={moodFilter.includes(3)}
            />
          }
          label={<MoodLabel nbr={3} />}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={moodFilter?.length === 1 && moodFilter[0] === 2}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event, 2)
              }
              checked={moodFilter.includes(2)}
            />
          }
          label={<MoodLabel nbr={2} />}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={moodFilter?.length === 1 && moodFilter[0] === 1}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event, 1)
              }
              checked={moodFilter.includes(1)}
            />
          }
          label={<MoodLabel nbr={1} />}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={moodFilter?.length === 1 && moodFilter[0] === 0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event, 0)
              }
              checked={moodFilter.includes(0)}
            />
          }
          label={<MoodLabel nbr={0} />}
        />
      </FormGroup>
    </Box>
  );
};

const MoodLabel: React.FC<{ nbr: number }> = ({ nbr }) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      {nbr}
      <SStarFilled sx={{ fontSize: "20px" }} />
    </Box>
  );
};

export default MoodFilter;
