import React from "react";
import IncIrritantsHeader from "./headers/IncIrritantsHeader";
import IncIrritantsList from "./compare/IncIrritantsList";
import { Box } from "@mui/material";

const IncIrritants: React.FC<any> = ({ result }) => {
  return (
    <Box>
      <IncIrritantsHeader />
      <IncIrritantsList
        light={result?.dynamic_nettlesome?.lightweight}
        neutral={result?.dynamic_nettlesome?.neutral}
        important={result?.dynamic_nettlesome?.strong}
      />
    </Box>
  );
};

export default IncIrritants;
