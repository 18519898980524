import {
  Button,
  Typography,
  Box,
  Divider,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { TestType } from "../../@types/testType";
import ResultsAPI from "../../_api/_resultsAPI";
import { useNavigate } from "react-router-dom";
import DeleteResultsModal from "../tests/DeleteResultsModal";
import Big5Restitution from "./Big5Restitution";
import { shallowEqual, useSelector } from "react-redux";
import DownloadPdfButton from "../common/DownloadPdfButton";
import { ProfileContext } from "../../contexts/ProfileContext";

const Profile: any = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.tests.b5,
}));

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const CustomButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "deleteButton",
})(({ theme, deleteButton }: any) => ({
  height: "44px",
  fontSize: "12px",
  color: deleteButton ? theme.palette.secondary[400] : "auto",
  fontWeight: deleteButton ? 400 : "normal",
}));

const Big5Result: React.FC<{
  result: TestType;
  deleteResult?: any;
  retryTest?: any;
  nextStep?: any;
  onboarding?: boolean;
  results?: any;
  traits?: any;
  compare?: boolean;
  notCollab?: boolean;
  lastStep?: boolean;
  disableDownload?: boolean;
}> = ({
  result,
  deleteResult,
  retryTest,
  nextStep,
  onboarding,
  results,
  traits,
  compare,
  notCollab,
  lastStep,
  disableDownload = false,
}) => {
  const { t }: { t: any } = useTranslation("common");
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const profile = useContext(ProfileContext);
  const home = useSelector((state: any) => state.home, shallowEqual);
  const { context, isCollab } = useSelector((state: any) => state.auth);

  // const replayTest = () => {
  //   retryTest(true);
  // };

  const deleteResults = () => {
    const id: any = result?.results?.id;
    ResultsAPI.delete(id).then((res) => {
      deleteResult(true);
    });
  };

  const redirectTests = () => {
    navigate("/app/tests");
  };

  return (
    <Card sx={{ boxShadow: onboarding ? "none" : "auto" }}>
      <CardContent>
        <Box display="flex" justifyContent="center">
          <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            <img src="/static/icons/b5_logo.svg" alt="big5 logo" />
            <Typography variant="h3" sx={{ ml: 2 }}>
              {t("tests.b5")}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="center" marginBottom={5}>
          <Box sx={{ ml: 2, textAlign: "center", mt: 3, maxWidth: "900px" }}>
            <Typography variant="h4">
              {t(`big5.result.profile_${notCollab ? context : "collab"}`)}
              <Profile variant="h4" component="span">
                {result?.results?.dominante_traits.title}
              </Profile>
            </Typography>
            <Typography
              sx={{ mt: 2, mb: 3, fontWeight: "normal" }}
              variant="subtitle1"
            >
              {result?.results?.dominante_traits.desc}
            </Typography>
            {notCollab ? null : (
              <Subtitle
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: t("big5.result.desc", {
                    test: t("tests.b5_little"),
                  }),
                }}
              />
            )}
          </Box>
        </Box>
        <Big5Restitution
          compare={compare}
          results={results?.results ?? results}
          traits={traits}
          result={result?.results}
          notCollab={notCollab}
        />
        <Box
          sx={{ mt: 2 }}
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
        >
          {((home?.paramjson?.candidatePdf && isCollab) || !isCollab) &&
            !disableDownload && (
              <Box sx={{ m: 1 }}>
                <DownloadPdfButton
                  person={profile?.peopleData}
                  height="44px"
                  variant="outlined"
                  type="b5"
                />
              </Box>
            )}
          {!notCollab && (
            <>
              {!onboarding ? (
                <>
                  <CustomButton
                    sx={{ mb: { sm: 0, xs: 2 }, m: 1 }}
                    variant="outlined"
                    color="inherit"
                    onClick={() => setOpenModal(true)}
                    deleteButton
                  >
                    {t("tests.page.delete")}
                  </CustomButton>
                  {!window.location.pathname.includes("app/tests") && (
                    <CustomButton
                      sx={{ m: 1 }}
                      variant="outlined"
                      onClick={redirectTests}
                    >
                      {t("tests.page.return_to_result")}
                    </CustomButton>
                  )}
                </>
              ) : (
                <CustomButton
                  variant="contained"
                  onClick={nextStep}
                  sx={{ width: "150px", m: 1 }}
                >
                  {t(lastStep ? "onboarding.finish_btn" : "btn.next")}
                </CustomButton>
              )}
            </>
          )}
        </Box>
      </CardContent>
      <DeleteResultsModal
        open={openModal}
        close={() => setOpenModal(false)}
        deleteResults={() => deleteResults()}
      />
    </Card>
  );
};

export default Big5Result;
