import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BigButton from "../../../common/BigButton";
import SkillModal from "../../../skills/SkillModal";
import SkillEdit from "../../../skills/SkillEdit";

const AdminWorkSkills: React.FC<any> = ({ setSkillsList, workSkills }) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const handleSubmit = (res: any) => {
    const results = [...res];
    const wSkills = [...workSkills];

    results.forEach((el: any) => {
      const isIn = Boolean(
        wSkills.find((f: any) => `/api/skills/${el.id}` === f?.skill?.["@id"])
      );
      if (isIn) {
        const index = results.findIndex((f: any) => f.id === el.id);
        const wIndex = wSkills.findIndex(
          (f: any) => `/api/skills/${el.id}` === f?.skill?.["@id"]
        );
        wSkills[wIndex].level.level = results[index].level.level;
        results.splice(index, 1);
      }
    });

    setSkillsList([...wSkills, ...results]);
  };

  const removeSkill = (id: string) => {
    setSkillsList((current: any) =>
      current.filter((el: any) => el?.["@id"] !== id && el?.id !== id)
    );
  };

  const handleSkill = (el: any) => {
    setSkillsList((current: any) => {
      const arr = [...current];
      const index = arr.findIndex((f: any) => f["@id"] === el["@id"]);
      arr[index].level.level = el.level.level;
      return arr;
    });
  };

  const handleRemove = (el: any) => {
    removeSkill(el.id ?? el["@id"]);
  };

  return (
    <div>
      <Typography variant="body1" fontWeight="bold">
        {t("admin.params.works.skills")}
      </Typography>
      <BigButton onClick={setOpen.bind(this, true)}>Ajouter</BigButton>
      <Stack spacing={2} sx={{ mt: 2 }}>
        {workSkills?.map((el: any) => (
          <SkillEdit
            skill={el}
            key={el.id ?? el["@id"]}
            identifier={el.id ?? el["@id"]}
            offline={true}
            skillEmitter={handleSkill}
            deleteEmitter={handleRemove}
            deleteValidation={false}
            canRemove={true}
          />
        ))}
      </Stack>

      <SkillModal
        open={open}
        close={setOpen.bind(this, false)}
        handleSubmit={handleSubmit}
        type={"technical"}
        skipSuggest={true}
        editable={false}
        editableSkills={workSkills}
        noApi={true}
      />
    </div>
  );
};

export default AdminWorkSkills;
