import { Box, Paper, styled } from "@mui/material";
import React, { useState } from "react";
import ExperienceCardActions from "./ExperienceCardActions";
import ExperienceCardHeader from "./ExperienceCardHeader";
import ExperienceLogo from "./ExperienceLogo";
import ExperiencesTags from "./ExperiencesTags";
import customIcons from "../../_utils/fulfillmentIcons";
import { useSelector } from "react-redux";

const Root = styled(Paper)(({ theme }: any) => ({
  border: `1px solid ${theme.palette.grey[500_48]}`,
  display: "flex",
  padding: "25px 14px 20px 25px",
}));

const ExperienceCard: React.FC<{
  experience: any;
  handleEditExperience?: (el: any) => void;
  notCollab: boolean;
}> = ({ experience, handleEditExperience, notCollab }) => {
  const loading = false;
  const [editing, setEditing] = useState(false);

  const handleEdit = (e: any) => {
    handleEditExperience && handleEditExperience(e);
  };

  const hideFulfillmentLevel = useSelector(
    (state: any) => state.home?.paramjson?.hideFulfillmentLevel
  );

  return (
    <Root>
      <Box sx={{ display: "flex", overflow: "hidden", flex: 1 }}>
        <ExperienceLogo experience={experience} />
        <Box
          sx={{
            maxWidth: "100%",
            paddingRight: "40px",
            width: "100%",
            overflow: "hidden",
            position: "relative",
            display: "grid",
          }}
        >
          {/* Header infos */}
          <ExperienceCardHeader experience={experience} />

          {/* Tags */}
          <ExperiencesTags experience={experience} />

          {/* Fulfillment */}
          {!hideFulfillmentLevel && experience?.personalData?.fulfillment && (
            <Box sx={{ mt: 1 }}>
              {customIcons[experience?.personalData?.fulfillment].icon}
            </Box>
          )}

          {/* Description */}
          {experience.description && (
            <Box
              sx={{ paddingTop: 1 }}
              dangerouslySetInnerHTML={{ __html: experience.description }}
            />
          )}
        </Box>
      </Box>

      {/* Side */}
      {!notCollab && (
        <ExperienceCardActions
          experience={experience}
          editing={editing}
          setEditing={setEditing}
          loading={loading}
          handleEdit={handleEdit}
        />
      )}
    </Root>
  );
};

export default ExperienceCard;
