import { analyticsScript } from "../../scripts/analyticsScript";

export const SET_HOME = "SET_HOME";

export const setHome = (payload: any) => {
  return {
    type: SET_HOME,
    payload,
  };
};

export const handleHome = (payload: any) => {
  return (dispatch: any, getState: any) => {
    if (payload.otherData) {
      analyticsScript({ googleId: payload.otherData?.analytics });
    }

    // const { user } = getState();
    // if (
    //   isMobile &&
    //   user?.webapp &&
    //   payload?.paramjson?.webappUrl &&
    //   user?.onboarding >= 100
    // ) {
    //   const origin = window.location.origin;
    //   if (!origin.includes("localhost")) {
    //     window.location.href = payload?.paramjson?.webappUrl;
    //   }
    // }

    // // ! TEST
    // if (payload?.check_saml?.mods)
    //   payload.check_saml.mods = { phone: false, email: false };

    // console.log(payload);

    dispatch(setHome(payload));
  };
};
