import { Box, Divider, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import IncIrritantsListItem from "./IncIrritantsListItem";
import IncProgress from "./IncProgress";
import IncIrritantsHeader from "../headers/IncIrritantsHeader";
import { useTranslation } from "react-i18next";
import IncIrritantsMoreBtn from "./IncIrritantsMoreBtn";
import IncIrritantsListHeader from "./IncIrritantsListHeader";

const FlexBox = styled(Box)(({ theme }: any) => ({
  display: "flex",
  justifyContent: "center",
}));

const IncIrritantsList: React.FC<any> = ({
  light,
  neutral,
  important,
  score,
  work,
}) => {
  const { t } = useTranslation("common");

  return (
    <Box sx={{ mt: 4 }}>
      {work && (
        <>
          <IncIrritantsHeader work />
          {score && (
            <Box marginBottom={7}>
              <IncProgress value={score} />
            </Box>
          )}
        </>
      )}
      <Grid container sx={{ position: "relative" }}>
        <Grid item xs={12} md={6}>
          <FlexBox>
            <IncIrritantsListHeader
              work={work}
              text={t(
                `inc.irritants.title_${score || work ? "high" : "strong"}`
              )}
              color="red"
            />
          </FlexBox>
          <FlexBox sx={{ mb: 4 }}>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{ color: "#9F9F9F", mb: 2, mt: 1, maxWidth: "47ch" }}
            >
              {t(`inc.irritants.text_irritants_${work ? "high" : "strong"}`)}
            </Typography>
          </FlexBox>
          <FlexBox flexWrap="wrap" alignItems="center">
            {important?.slice(0, 4).map((el: any, i: number) => (
              <IncIrritantsListItem
                el={el}
                key={i}
                color={work ? el.color : "red"}
                bgColor="red"
              />
            ))}
            {(!important || important?.length === 0) && (
              <Typography variant="h5" textAlign="center" sx={{ mb: 4 }}>
                {t("inc.irritants.none")}
              </Typography>
            )}
          </FlexBox>
          {important?.length > 4 && (
            <FlexBox>
              <IncIrritantsMoreBtn
                work={work}
                color="red"
                text={t(`inc.irritants.see_more_${work ? "high" : "strong"}`)}
                titleText={t(`inc.irritants.title_${work ? "high" : "strong"}`)}
                light={light}
                neutral={neutral}
                important={important}
              />
            </FlexBox>
          )}
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: { xs: 4, md: 0 } }}>
          <FlexBox>
            <IncIrritantsListHeader
              work={work}
              text={t(`inc.irritants.title_${work ? "neutral" : "difficult"}`)}
              color="orange"
            />
          </FlexBox>
          <FlexBox sx={{ mb: 4 }}>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{ color: "#9F9F9F", mb: 2, mt: 1, maxWidth: "47ch" }}
            >
              {t(
                `inc.irritants.text_irritants_${work ? "neutral" : "difficult"}`
              )}
            </Typography>
          </FlexBox>
          <FlexBox flexWrap="wrap" alignItems="center">
            {neutral?.slice(0, 4).map((el: any, i: number) => (
              <IncIrritantsListItem
                el={el}
                key={i}
                color={work ? el.color : "orange"}
                bgColor="orange"
              />
            ))}
            {(!neutral || neutral?.length === 0) && (
              <Typography variant="h5" textAlign="center">
                {t("inc.irritants.none")}
              </Typography>
            )}
          </FlexBox>
          {neutral?.length > 4 && (
            <FlexBox>
              <IncIrritantsMoreBtn
                work={work}
                color="orange"
                text={t(
                  `inc.irritants.see_more_${work ? "neutral" : "difficult"}`
                )}
                titleText={t(
                  `inc.irritants.title_${work ? "neutral" : "difficult"}`
                )}
                light={light}
                neutral={neutral}
                important={important}
              />
            </FlexBox>
          )}
        </Grid>
        {/* <Grid item xs={12} md={6} sx={{ mt: 4 }}>
          <FlexBox>
            <IncIrritantsListHeader
              work={work}
              text={t(`inc.irritants.title_${work ? "low" : "light"}`)}
              color="green"
            />
          </FlexBox>
          <FlexBox sx={{ mb: 4 }}>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{ color: "#9F9F9F", mb: 2, mt: 1, maxWidth: "47ch" }}
            >
              {t(`inc.irritants.text_irritants_${work ? "low" : "light"}`)}
            </Typography>
          </FlexBox>
          <FlexBox flexWrap="wrap" alignItems="center">
            {light?.slice(0, 4).map((el: any, i: number) => (
              <IncIrritantsListItem
                el={el}
                key={i}
                color={work ? el.color : "green"}
                bgColor="green"
              />
            ))}
            {(!light || light?.length === 0) && (
              <Typography variant="h5" textAlign="center">
                {t("inc.irritants.none")}
              </Typography>
            )}
          </FlexBox>
          {light?.length > 4 && (
            <FlexBox>
              <IncIrritantsMoreBtn
                work={work}
                color="green"
                text={t(`inc.irritants.see_more_${work ? "low" : "light"}`)}
                titleText={t(`inc.irritants.title_${work ? "low" : "light"}`)}
                light={light}
                neutral={neutral}
                important={important}
              />
            </FlexBox>
          )}
        </Grid> */}
        <Divider
          orientation="vertical"
          sx={{
            position: "absolute",
            left: "50%",
            height: light?.length > 3 || neutral?.length > 3 ? "55%" : "20%",
            display: { xs: "none", md: "block" },
            bottom: score ? "35px" : "20px",
          }}
        />
      </Grid>
    </Box>
  );
};

export default IncIrritantsList;
