import { Box, Collapse, Zoom } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import palette from "../../theme/palette";

const chartParams: any = {
  options: {
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        track: { margin: 10 },
        hollow: {
          size: "40%",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: "30px",
            fontFamily: "Product Sans",
            color: palette.secondary.light,
          },
          total: {
            show: true,
            formatter: function (w: any) {
              return (
                Math.ceil(
                  w.globals.seriesTotals.reduce((a: any, b: any) => {
                    return a + b;
                  }, 0) / w.globals.series.length
                ) + "%"
              );
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        colorStops: [
          [
            {
              offset: 0,
              color: "#01BDFF",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#505DFF",
              opacity: 1,
            },
          ],
          [
            {
              offset: 0,
              color: "#FEA48C",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#FD4E85",
              opacity: 1,
            },
          ],
          [
            {
              offset: 0,
              color: "#82D7FF",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#E683FC",
              opacity: 1,
            },
          ],
        ],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
  },
};

const MultipleCircularProgression: React.FC<{
  data: number[];
  labels: string[];
}> = ({ data, labels }) => {
  const { t } = useTranslation("common");
  const transformLabels = (dataLabels: string[]) => {
    const newArr: string[] = [];
    dataLabels.map((item: string) => newArr.push(t(`tests.${item}`)));
    return newArr;
  };
  const chart = {
    ...chartParams,
    options: {
      ...chartParams.options,
      labels: transformLabels(labels),
    },
    series: data,
  };

  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <Zoom in={true}>
        <Box>
          <ReactApexChart
            series={chart.series}
            options={chart.options}
            chart={chart.chart}
            height={325}
            type="radialBar"
          />
        </Box>
      </Zoom>
    </Collapse>
  );
};
export default MultipleCircularProgression;
