import { CircularProgress, styled } from "@mui/material";
import { Close, Create } from "@mui/icons-material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import StyledButton from "../styled/StyledButton";
import ExperienceDeleteModal from "./experienceDeleteModal.tsx/ExperienceDeleteModal";
import ExperienceModal from "./ExperienceModal";
import TrainingModal from "./trainings/TrainingModal";

const DeleteButton: any = styled(StyledButton, {
  shouldForwardProp: (props) => props !== "loading",
})(({ theme, loading }: any) =>
  loading
    ? {}
    : {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error[500_16],
        border: "none",
        "&:hover": {
          backgroundColor: theme.palette.error[500_24],
        },
      }
);

const ExperienceCardActions: React.FC<{
  experience: any;
  editing: boolean;
  setEditing: any;
  loading: any;
  handleEdit: any;
}> = ({ experience, editing, setEditing, loading, handleEdit }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <StyledButton
            sx={{ mb: 1 }}
            onClick={() => (!editing ? setEditing(true) : null)}
            width={35}
            height={35}
          >
            {!loading ? (
              <Create color="primary" sx={{ width: 20, height: 20 }} />
            ) : (
              <CircularProgress size={20} sx={{ position: "absolute" }} />
            )}
          </StyledButton>
          <DeleteButton
            loading={loading}
            onClick={() => setOpen(true)}
            width={35}
            height={35}
          >
            {!loading ? (
              // <DeleteForever color="error" sx={{ width: 20, height: 20 }} />
              <Close />
            ) : (
              <CircularProgress size={20} sx={{ position: "absolute" }} />
            )}
          </DeleteButton>
        </Box>
      </Box>
      {experience.type === "training" ? (
        <TrainingModal
          width={"700px"}
          experience={{
            ...experience,
            ...(!experience.dateEnd ? { ongoing: true } : {}),
          }}
          edited={handleEdit}
          open={editing}
          handleClose={() => setEditing(false)}
        />
      ) : (
        <ExperienceModal
          width={"700px"}
          experience={{
            ...experience,
            ...(!experience.dateEnd ? { ongoing: true } : {}),
          }}
          edited={handleEdit}
          open={editing}
          handleClose={() => setEditing(false)}
        />
      )}
      <ExperienceDeleteModal
        open={open}
        experience={experience}
        handleClose={() => setOpen(false)}
        type={experience.type}
      />
    </>
  );
};

export default ExperienceCardActions;
