import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ControlledPasswordInput } from "../forms/ControlledPasswordInput";

interface IFormInput {
  oldPwd: string;
  newPwd: string;
  confirmPwd: string;
}

const EditPasswordForm: React.FC<{
  submit: (e: any) => void;
  loading: boolean;
}> = ({ submit, loading }) => {
  const { t } = useTranslation("common");

  const { handleSubmit, control, getValues } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: { oldPwd: "", newPwd: "", confirmPwd: "" },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box display="flex" justifyContent="center">
        <Stack
          spacing={2}
          sx={{
            width: {
              xs: "100%",
              sm: "70%",
            },
          }}
        >
          <ControlledPasswordInput
            name="oldPwd"
            label="account.params.old_pwd"
            control={control}
            required
          />
          <ControlledPasswordInput
            name="newPwd"
            label="account.params.new_pwd"
            control={control}
            getValues={getValues}
            required
            showRules
          />
          <ControlledPasswordInput
            name="confirmPwd"
            label="confirm_password_required"
            control={control}
            getValues={getValues}
            required
          />
        </Stack>
      </Box>
      <Box textAlign="center" sx={{ mt: 3 }}>
        <Button
          disabled={loading}
          variant="contained"
          type="submit"
          sx={{ height: "55px", width: "200px", fontSize: "12px" }}
        >
          {t("btn.save")}
        </Button>
      </Box>
    </form>
  );
};

export default EditPasswordForm;
