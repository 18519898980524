import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ProfileContext } from "../../contexts/ProfileContext";
import ExperienceSelectorCard from "../common/ExperienceSelectorCard";

const SimplifiedSwitch = () => {
  const { peopleData, setPeopleData } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const id = params?.id;
  const [checked, setChecked] = useState<boolean>(
    peopleData?.simplified || false
  );

  const handleClick = async () => {
    setLoading(true);

    const value = !checked;

    try {
      await axios.put(`people/${id}`, {
        data: {
          ...peopleData?.data,
          expectedData: {
            ...peopleData?.data?.expectedData,
            simplified: value,
          },
        },
      });

      setPeopleData({
        ...peopleData,
        simplified: value,
        data: {
          ...peopleData.data,
          expectedData: {
            ...peopleData?.data?.expectedData,
            simplified: value,
          },
        },
      });

      setChecked(value);

      setLoading(false);
    } catch (err) {
      setChecked(!value);
      setLoading(false);
    }
  };

  return (
    <ExperienceSelectorCard
      type="simplified"
      loading={loading}
      selected={checked}
      onClick={handleClick}
    />
  );
};

export default SimplifiedSwitch;
