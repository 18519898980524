import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminGeneralMatchers: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");
  return (
    <div>
      <Typography variant="h4">{t("admin.params.matching.title")}</Typography>
      <Typography>{t("admin.params.matching.text")}</Typography>
      <FormGroup>
        <FormControlLabel
          value={param.skills}
          onChange={(e: any) =>
            handleChanges({ ...param, skills: e.target.checked })
          }
          control={<Checkbox checked={param.skills} />}
          label={t("admin.params.matching.skills")}
        />
        <FormControlLabel
          value={param.personality}
          onChange={(e: any) =>
            handleChanges({ ...param, personality: e.target.checked })
          }
          control={<Checkbox checked={param.personality} />}
          label={t("admin.params.matching.b5")}
        />
        <FormControlLabel
          value={param.motivations}
          onChange={(e: any) =>
            handleChanges({ ...param, motivations: e.target.checked })
          }
          control={<Checkbox checked={param.motivations} />}
          label={t("admin.params.matching.cf")}
        />
        <FormControlLabel
          value={param.dynamics}
          onChange={(e: any) =>
            handleChanges({ ...param, dynamics: e.target.checked })
          }
          control={<Checkbox checked={param.dynamics} />}
          label={t("admin.params.matching.inc")}
        />
        <FormControlLabel
          value={param.mood}
          onChange={(e: any) =>
            handleChanges({ ...param, mood: e.target.checked })
          }
          control={<Checkbox checked={param.mood} />}
          label={t("admin.params.matching.mood")}
        />
        <Typography sx={{ mt: 1 }} textAlign="right">
          {t("admin.params.reco_text")}
        </Typography>
      </FormGroup>
    </div>
  );
};

export default AdminGeneralMatchers;
