import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { styled } from "@mui/styles";
import { Box, Divider, Grid, Chip, Typography } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IncAdaptationItem from "../IncAdaptationItem";
import TestsAPI from "../../../_api/_testsAPI";

const BoxCtn = styled(Box)(({ theme }: any) => ({
  width: "300px",
  height: "200px",
  borderRadius: theme.shape.borderRadius10,
}));

const IncCompareAdaptationDescription = ({ workResult }: any) => {
  const theme: any = useTheme();
  const { t } = useTranslation("common");
  const { id, personId }: any = useParams();
  const context = useSelector((state: any) => state.auth.context);
  const tokenData = useSelector((state: any) => state.user);

  const [result, setResult] = useState<any>(null);
  const [showDifficult, setShowDifficult] = useState<boolean>(false);
  const { data } = useFetch(
    TestsAPI.get({
      "person.id":
        context === "recruiter"
          ? id
          : personId
          ? personId
          : tokenData.person_id,
    })
  );
  const getResultText = (item: any, index: number) => {
    const comparison =
      item.work_value -
      result?.dynamic_adaptation?.[showDifficult ? "difficult" : "normal"]?.[
        index
      ].value;
    let text = "";
    if (comparison <= -1) {
      text = t("inc.adaptation.score_over");
    } else if (comparison >= 2) {
      text = t("inc.adaptation.score_behind");
    } else if (comparison === 1) {
      text = t("inc.adaptation.score_nearly_match");
    } else {
      text = t("inc.adaptation.score_match");
    }
    return text;
  };

  useEffect(() => {
    data?.["hydra:member"] &&
      setResult(
        data?.["hydra:member"].filter((el: any) => el.code === "inc")?.[0]
          ?.results?.results
      );
  }, [data]);

  return (
    <Grid container sx={{ mt: 4 }}>
      <Grid item xs={12} sm={5}>
        <Box sx={{ height: "110px" }}>
          <Box
            onClick={() => setShowDifficult(false)}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 2, opacity: showDifficult ? 0.3 : 1, cursor: "pointer" }}
          >
            <Box
              sx={{
                mr: 1,
                minWidth: "34px",
                height: "15px",
                backgroundColor: "#4C6FFF",
                borderRadius: "9px",
              }}
            />
            <Typography variant="subtitle1" fontWeight="normal">
              {t("inc.normal")}
            </Typography>
          </Box>
          <Divider />
          <Box
            onClick={() => setShowDifficult(true)}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              mt: 2,
              mb: 4,
              opacity: showDifficult ? 1 : 0.3,
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                mr: 1,
                minWidth: "34px",
                height: "15px",
                backgroundColor: "#FF6C40",
                borderRadius: "9px",
              }}
            />
            <Typography
              variant="subtitle1"
              fontWeight="normal"
              sx={{ whiteSpace: "nowrap" }}
            >
              {t("inc.difficult")}
            </Typography>
          </Box>
        </Box>
        {(showDifficult
          ? result?.dynamic_adaptation?.difficult
          : result?.dynamic_adaptation?.normal
        )
          ?.filter((el: any) => el.var !== "V165")
          ?.map((item: any, index: number) => (
            <Box
              key={index}
              display="flex"
              sx={{ justifyContent: { xs: "center", sm: "start" } }}
            >
              <BoxCtn
                sx={{
                  mb: 4,
                  p: 1,
                  backgroundColor: showDifficult
                    ? "rgba(255, 226, 208, 0.32)"
                    : "rgba(220, 220, 243, 0.32)",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ pt: 2 }}
                >
                  <Chip
                    sx={{
                      backgroundColor: showDifficult
                        ? "rgba(255, 110, 20, 0.2)"
                        : theme.palette.primary.lighter,
                      color: showDifficult
                        ? "#FF6E14"
                        : theme.palette.primary.main,
                      pl: 2,
                      pr: 2,
                      mb: 2,
                    }}
                    key={item.id}
                    label={item.title}
                    color={showDifficult ? "error" : "primary"}
                  />
                  <Chip
                    sx={{
                      backgroundColor: showDifficult
                        ? "rgba(255, 110, 20, 1)"
                        : theme.palette.primary.main,
                      pl: 2,
                      pr: 2,
                      mb: 2,
                      ml: 2,
                    }}
                    key={item.id}
                    label={`${item.value}/10`}
                    color={showDifficult ? "error" : "primary"}
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="body1"
                    textAlign="center"
                    sx={{ maxWidth: "31ch" }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              </BoxCtn>
            </Box>
          ))}
      </Grid>
      <Grid item sm={2} sx={{ display: { xs: "none", sm: "block" } }}>
        <Box sx={{ mt: 14 }}>
          {workResult
            ?.filter((el: any) => el.var !== "V165")
            ?.map((item: any, index: number) => (
              <Box
                key={item.title}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ height: "200px", mb: 4 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "35px",
                    width: "75px",
                    borderRadius: "7px",
                    mb: 2,
                    backgroundColor:
                      item.work_value <
                      result?.dynamic_adaptation?.[
                        showDifficult ? "difficult" : "normal"
                      ]?.[index].value
                        ? theme.palette.success.lighter
                        : item.work_value ===
                          result?.dynamic_adaptation?.[
                            showDifficult ? "difficult" : "normal"
                          ]?.[index].value
                        ? theme.palette.primary.lighter
                        : theme.palette.error.lighter,
                  }}
                >
                  {item.work_value <
                  result?.dynamic_adaptation?.[
                    showDifficult ? "difficult" : "normal"
                  ]?.[index].value ? (
                    <TrendingUpIcon color="success" />
                  ) : item.work_value ===
                    result?.dynamic_adaptation?.[
                      showDifficult ? "difficult" : "normal"
                    ]?.[index].value ? (
                    <DragHandleIcon color="primary" />
                  ) : (
                    <TrendingDownIcon color="error" />
                  )}
                </Box>
                <Typography
                  textAlign="center"
                  variant="body1"
                  sx={{
                    color:
                      item.work_value <
                      result?.dynamic_adaptation?.[
                        showDifficult ? "difficult" : "normal"
                      ]?.[index].value
                        ? theme.palette.success.main
                        : item.work_value ===
                          result?.dynamic_adaptation?.[
                            showDifficult ? "difficult" : "normal"
                          ]?.[index].value
                        ? theme.palette.primary.main
                        : theme.palette.error.main,
                  }}
                >
                  {getResultText(item, index)}
                </Typography>
              </Box>
            ))}
        </Box>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Box
          sx={{ height: "110px" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 4 }}
          >
            <Box
              sx={{
                mr: 1,
                width: "34px",
                height: "15px",
                backgroundColor: "#AFAFAF",
                borderRadius: "9px",
              }}
            />
            <Typography variant="subtitle1" fontWeight="normal">
              {t("inc.adaptation.await")}
            </Typography>
          </Box>
        </Box>
        {workResult
          ?.filter((el: any) => el.var !== "V165")
          ?.map((item: any) => (
            <IncAdaptationItem key={item?.var} item={item} compare />
          ))}
      </Grid>
    </Grid>
  );
};

export default IncCompareAdaptationDescription;
