import React from "react";
import {
  Box,
  Card,
  CardContent,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";

const Root = styled(Card)(({ theme }: any) => ({
  height: "100%",
  borderRadius: theme.shape.borderRadius,
  overflow: "visible",
  position: "relative",
  boxShadow: theme.customShadows.default,
  border: `.6px solid #E6E6E6`,
}));

const Content = styled(CardContent)(({ theme }: any) => ({
  display: "flex",
  height: "100%",
  alignItems: "center",
  padding: theme.spacing(2),
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
  },
}));

const BoxIcon = styled(Skeleton)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: theme.shape.borderRadius10,
  flexShrink: 0,
}));

const AccessCardSkeleton: React.FC<{
  index?: number;
  withIconLink?: boolean;
  withSubtitle?: boolean;
}> = ({ index = 0, withIconLink = false, withSubtitle = false }) => {
  return (
    <Root sx={{ borderRadius: "7px" }}>
      <Content sx={{ pb: "16px !important" }}>
        {withIconLink && (
          <BoxIcon
            variant="rectangular"
            sx={{ mr: 2 }}
            height="68px"
            width="62px"
          />
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">
            <Skeleton width="130px" />
          </Typography>
          {withSubtitle && <Skeleton width="80%" />}
        </Box>
      </Content>
    </Root>
  );
};

export default AccessCardSkeleton;
