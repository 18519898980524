import React from "react";
import { HighlightOff } from "@mui/icons-material";
import {
  Modal,
  Card,
  IconButton,
  styled,
  CardContent,
  Typography,
} from "@mui/material";
import { modalStyle } from "../../_utils/modalStyle";
import DemoForm from "./DemoForm";

const BodyRoot = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 15px;
  top: 10px;
`;

const MTLogo = styled((props) => (
  <div {...props}>
    <img src="static/logo/MT-Logo-Colors.svg" alt="monkey tie logo" />
  </div>
))(
  () => `
  display: flex;
  margin-top: -20px;
  justify-content: center;
  img {
    height: 150px;
  }
`
);

const DemoModal: React.FC<any> = ({ open, handleClose }) => {
  return (
    <Modal open={Boolean(open)} onClose={handleClose}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", lg: "500px", md: "500px" },
          minHeight: "200px",
        }}
      >
        <BodyRoot>
          <CloseButton onClick={handleClose} aria-label="close modal">
            <HighlightOff />
          </CloseButton>
        </BodyRoot>
        <CardContent>
          <MTLogo />
          <Typography sx={{ mt: -1 }} variant="h3" textAlign="center">
            Accéder à mon essai gratuit
          </Typography>
          {open && <DemoForm type={open} />}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default DemoModal;
