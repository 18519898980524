import {
  Typography,
  Stack,
  Box,
  Button,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import NextStepButton from "../buttons/NextStepButton";

const Container = styled(Box)({
  maxWidth: "630px",
});

const Title = styled(Typography)({
  fontSize: "40px",
});

const BodyContainer = styled(Stack)({
  maxWidth: "490px",
});

const AlignedBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const CustomButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "onBoarding",
})(({ theme, onBoarding }: any) => ({
  width: onBoarding ? "100%" : "210px",
  height: onBoarding ? "60px" : "48px",
  borderRadius: "10px",
  fontSize: "14px",
  backgroundImage: onBoarding ? "auto" : theme.palette.gradients.b5_linear,
}));

const IntroText = styled("div")(({ theme }: any) => ({
  width: "120px",
  height: "30px",
  borderRadius: "6px",
  padding: "3px",
  backgroundColor: theme.palette.background.neutral,
}));

const Big5Intro: React.FC<{
  nextStep: any;
  skipTest?: any;
  onboarding?: boolean;
  onboardingStep?: number;
  canSkip?: boolean;
}> = ({ nextStep, skipTest, onboarding, onboardingStep, canSkip }) => {
  const { t } = useTranslation("common");

  return onboarding ? (
    <Container>
      <Title variant="h2">
        {onboarding && onboardingStep ? `0${onboardingStep}. ` : ""}
        {t("big5.intro.title")}
      </Title>
      <BodyContainer sx={{ mb: 4, mt: 1 }} spacing={3}>
        <AlignedBox>
          <Typography variant="h4">🚀</Typography>
          <Typography
            variant="body1"
            sx={{ ml: 2 }}
            dangerouslySetInnerHTML={{ __html: `${t("big5.intro.text_1")}` }}
          ></Typography>
        </AlignedBox>
        <AlignedBox>
          <Typography variant="h4">😊</Typography>
          <Typography variant="body1" sx={{ ml: 2 }}>
            {t("big5.intro.text_2")}
          </Typography>
        </AlignedBox>
        <AlignedBox>
          <Typography variant="h4">👑</Typography>
          <Typography variant="body1" sx={{ ml: 2 }}>
            {t("big5.intro.text_3")}
          </Typography>
        </AlignedBox>
        <AlignedBox>
          <Typography variant="h4">✨</Typography>
          <Typography variant="body1" sx={{ ml: 2 }}>
            {t("big5.intro.text_4")}
          </Typography>
        </AlignedBox>
      </BodyContainer>
      <Box sx={{ display: "flex", gap: 2 }}>
        <CustomButton variant="contained" onClick={() => nextStep()} onBoarding>
          {t("tests.page.begin")}
        </CustomButton>
        <NextStepButton
          onClick={() => skipTest()}
          text={canSkip ? undefined : t("btn.prev")}
        />
      </Box>
    </Container>
  ) : (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <IntroText>
            <Typography fontWeight="bold" variant="body1">
              {t("tests.page.label")}
            </Typography>
          </IntroText>
          <Typography
            sx={{ mt: 3, maxWidth: "55ch" }}
            variant="body1"
            fontWeight="bold"
            dangerouslySetInnerHTML={{ __html: `${t("big5.intro.text_1")}` }}
          ></Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            {t("big5.intro.text_2")}
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            {t("big5.intro.text_3")}
          </Typography>
          <Typography sx={{ mt: 2, maxWidth: "55ch" }} variant="body1">
            {t("big5.intro.text_4")}
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body1"
            fontWeight="bold"
            color="primary"
          >
            {t("tests.page.ready")}
          </Typography>
          <CustomButton
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => nextStep()}
          >
            {t("tests.page.begin_test")}
          </CustomButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Big5Intro;
