import React from "react";
import { Controller } from "react-hook-form";
import { password, sameValue } from "../../_formValidators/commonValidators";
import PasswordRules from "../common/PasswordRules";
import PasswordInput from "../PasswordInput";

export const ControlledPasswordInput: React.FC<{
  control: any;
  name: string;
  label: string;
  required?: boolean;
  showRules?: boolean;
  getValues?: any;
}> = ({ control, name, label, required = false, showRules, getValues }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: "errors.required" },
        validate: {
          [name]: (value: string) =>
            password(value)
              ? "errors.password"
              : name === "newPwd"
              ? sameValue(value, getValues("oldPwd"))
                ? "errors.old_pwd_same"
                : true
              : name === "confirmPwd"
              ? !sameValue(value, getValues("newPwd"))
                ? "errors.not_same"
                : true
              : name === "confirmPassword"
              ? !sameValue(value, getValues("password"))
                ? "errors.not_same"
                : true
              : true,
        },
      }}
      render={({
        field: { value, onChange, onBlur },
        formState: { errors },
      }) => (
        <>
          <PasswordInput
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            error={Boolean(errors?.[name])}
            label={label}
            helperText={errors?.[name]?.message}
            showRules={showRules}
          />
          {showRules && <PasswordRules value={value} />}
        </>
      )}
    />
  );
};
