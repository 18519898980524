import React from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const WorkItemTension: React.FC<{ value: number }> = ({ value }) => {
  const theme: any = useTheme();
  const { t }: any = useTranslation("common");
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{ position: "relative", width: "80px" }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "40px",
          height: "40px",
          backgroundColor:
            theme.palette[
              value
                ? value < 3
                  ? "success"
                  : value < 5
                  ? "warning"
                  : "error"
                : "grey"
            ][500_8],
          borderRadius: "50%",
        }}
      >
        {value ? (
          value < 3 ? (
            <SpeedIcon color="success" />
          ) : value < 5 ? (
            <SpeedIcon color="warning" />
          ) : (
            <SpeedIcon color="error" />
          )
        ) : (
          "ND"
        )}
      </Box>
      <Typography
        variant="body2"
        textAlign="center"
        sx={{ opacity: "0.6", mt: 1.5 }}
      >
        {t(
          value
            ? `work_sheet.indicators.${
                value < 3 ? "low" : value < 5 ? "middle" : "high"
              }_tension_list`
            : "work_sheet.no_data"
        )}
      </Typography>
      <Tooltip
        title={
          <div
            dangerouslySetInnerHTML={{
              __html: t("work_sheet.voltage_tooltip"),
            }}
          />
        }
      >
        <HelpOutlineIcon
          sx={{
            zIndex: 10,
            position: "absolute",
            color: "#8F9BB3",
            fontSize: "12px",
            top: "0px",
            right: "0px",
          }}
        />
      </Tooltip>
    </Box>
  );
};

export default WorkItemTension;
