import { Box, styled, Typography } from "@mui/material";
import React from "react";

const VideoResponsive = styled("div")`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border: none;
  }
`;

const RessourceVideo: React.FC<{ data: any }> = ({ data }) => {
  return (
    <Box
      sx={{
        width: "360px",
        marginTop: "15px",
        "@media screen and (max-width: 400px)": {
          width: "320px",
        },
        color: "#555555",
      }}
    >
      <Box
        sx={{
          width: "360px",
          overflow: "hidden",
          borderRadius: "10px",
          "@media screen and (max-width: 400px)": {
            width: "320px",
          },
        }}
      >
        <VideoResponsive>
          <iframe
            width="auto"
            height="auto"
            src={`https://www.youtube.com/embed/${data.url}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </VideoResponsive>
      </Box>

      <Typography
        fontWeight="bold"
        sx={{ marginTop: "15px" }}
        variant="subtitle1"
      >
        {data.title}
      </Typography>
      <Typography>{data.subtitle}</Typography>
    </Box>
  );
};

export default RessourceVideo;
