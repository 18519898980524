import { Box, Typography, Divider, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { workTraining } from "../../@types/workTrainingType";

const TitleCtn = styled(Box)(({ theme }: any) => ({
  height: "44px",
  borderRadius: theme.shape.borderRadius6,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(76, 111, 255, 0.07)",
  paddingRight: "15px",
  paddingLeft: "15px",
  paddingBottom: "10px",
  paddingTop: "10px",
  width: "fit-content",
}));

const WorkTrainingPageContent: React.FC<{ data?: workTraining }> = ({
  data,
}) => {
  const { t } = useTranslation("common");
  return (
    <Box
      display="flex"
      justifyContent="space-evenly"
      sx={{
        mt: 4,
        flexDirection: { xs: "column", md: "row" },
        alignItems: { xs: "center", md: "stretch" },
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", md: "block" },
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <TitleCtn>
          <Typography variant="h5" color="primary" sx={{ fontSize: "20px" }}>
            {t("trainings.title_cat.contents")}
          </Typography>
        </TitleCtn>
        <Typography
          variant="subtitle1"
          fontWeight="normal"
          sx={{
            maxWidth: "35ch",
            lineHeight: 1.3,
            mt: 3,
            textAlign: { xs: "center", md: "left" },
          }}
          dangerouslySetInnerHTML={{
            __html: data?.contenuFormation
              ? data?.contenuFormation?.value.replace(/\n/g, "<br />")
              : "",
          }}
        ></Typography>
      </Box>
      <Divider
        orientation="vertical"
        sx={{ height: "auto", display: { xs: "none", md: "block" } }}
      />
      <Box
        sx={{
          mt: { xs: 5, md: 0 },
          display: { xs: "flex", md: "block" },
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <TitleCtn>
          <Typography variant="h5" color="primary" sx={{ fontSize: "20px" }}>
            {t("trainings.title_cat.goal")}
          </Typography>
        </TitleCtn>
        <Typography
          variant="subtitle1"
          fontWeight="normal"
          sx={{
            maxWidth: "35ch",
            lineHeight: 1.3,
            mt: 3,
            textAlign: { xs: "center", md: "left" },
          }}
          dangerouslySetInnerHTML={{
            __html: data?.objectifFormation
              ? data?.objectifFormation?.value.replace(/\n/g, "<br />")
              : "",
          }}
        ></Typography>
      </Box>
    </Box>
  );
};

export default WorkTrainingPageContent;
