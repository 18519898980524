export default function Slider(theme: any) {
  return {
    MuiSlider: {
      styleOverrides: {
        root: {
          height: 8,
        },
        thumb: {
          height: 23,
          width: 23,
          backgroundColor: theme.palette.common.white,
          "&::after": {
            backgroundColor: theme.palette.primary.main,
            width: 10,
            height: 10,
          },
          "&:focus, &:hover, &.Mui-active": {
            boxShadow:
              "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              boxShadow: theme.shadows.z16,
            },
          },
        },
        mark: {
          height: 8,
          marginTop: 15,
          width: 1,
          "&.MuiSlider-markActive": {
            opacity: 1,
            backgroundColor: "currentColor",
          },
        },
      },
    },
  };
}
