import { Box, Paper, Typography, Tooltip, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setIcon } from "../../_utils/testsIcons";

const TopSoftSkillsPaper = styled(Paper)(({ theme }: any) => ({
  borderRadius: "23px",
  pb: 2,
  boxShadow: theme.customShadows.z8,
}));

const Item = styled(Box)(({ theme }: any) => ({
  textAlign: "center",
  margin: theme.spacing(2),
}));

const ImageContainer = styled(Box)({
  borderRadius: "35px",
  position: "relative",
  width: "55px",
  height: "55px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#fff7e8",
  marginLeft: "auto",
  marginRight: "auto",
});

const Medal = styled("img")({
  position: "absolute",
  top: 0,
  right: "-10px",
  width: "25px",
});

const Image = styled("img")({
  width: "35px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const ProfileTopSoftSkills = ({ context, completeness, data }: any) => {
  const { t } = useTranslation("common");
  const [softSkills, setSoftSkills] = useState<any>([]);

  const initSoftSkills = (result: any) => {
    const sk = result?.results?.results?.dynamic_motivation?.soft_skills;
    if (!sk) return;
    const newSk: any = [
      ...(sk.gold ?? [])?.map((el: any) => ({ ...el, type: "gold" })),
      ...(sk.silver ?? [])?.map((el: any) => ({ ...el, type: "silver" })),
      ...(sk.bronze ?? [])?.map((el: any) => ({ ...el, type: "bronze" })),
    ];
    setSoftSkills(newSk.splice(0, 3));
  };

  useEffect(() => {
    data?.length > 0 &&
      initSoftSkills(data.filter((el: any) => el.code === "inc")?.[0]);
  }, [data]);

  return softSkills?.length > 0 ? (
    <TopSoftSkillsPaper elevation={3}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 2 }}
      >
        <img
          width={completeness?.needGamified ? "60" : "50"}
          src={
            completeness?.needGamified
              ? "/static/icons/inc_game_logo.svg"
              : setIcon("inc")
          }
          alt="test inc"
        />
        <Box sx={{ ml: 1.5 }}>
          <Typography variant="subtitle1">
            {t(
              `inc.softskills.top${
                context === "advisor" || context === "recruiter" ? "_not" : ""
              }_collab`
            )}{" "}
          </Typography>
          <Typography variant="subtitle1" fontWeight="normal" sx={{ mt: -0.5 }}>
            {t(`inc.softskills.label`)}
          </Typography>
        </Box>
      </Box>
      <Box marginTop={2} display="flex" justifyContent="space-evenly">
        {softSkills?.map((ssk: any, i: number) => (
          <Item key={i} sx={{ cursor: "pointer" }}>
            <Tooltip title={ssk.text}>
              <ImageContainer display="flex" justifyContent="center">
                {(ssk.type === "silver" || ssk.type === "gold") && (
                  <Medal
                    alt="medal"
                    src={
                      ssk.type === "gold"
                        ? "/static/icons/inc/gold.png"
                        : "/static/icons/inc/silver.png"
                    }
                  />
                )}
                <Image
                  src={`/static/icons/Icons-irittants/${ssk.var}.svg`}
                  alt={`${ssk.title} icon`}
                />
              </ImageContainer>
            </Tooltip>
            <Typography marginTop={2} variant="body2" sx={{ maxWidth: "10ch" }}>
              {ssk.title}
            </Typography>
          </Item>
        ))}
      </Box>
    </TopSoftSkillsPaper>
  ) : (
    <></>
  );
};

export default ProfileTopSoftSkills;
