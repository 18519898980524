import {
  Box,
  IconButton,
  Modal,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import skillsAPI from "../../../../_api/_skillsAPI";
import skillsCatAPI from "../../../../_api/_skillsCatAPI";
import { modalStyle } from "../../../../_utils/modalStyle";
import AdminCreateSkillsForm from "./AdminCreateSkillsForm";
import { HighlightOff } from "@mui/icons-material";
import { toast } from "react-toastify";

const AdminCreateSkillModal: React.FC<{
  open: boolean;
  reload: () => void;
  close: () => void;
  skill?: any;
  type?: string;
}> = ({ open, close, reload, skill, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("common");

  const handleSubmit = async (values: any) => {
    console.log(values);
    setIsLoading(true);
    try {
      if (type === "skill") {
        if (skill) {
          await skillsAPI.put(skill.id, {
            label: values.label,
            description: values.description,
            skillCategory: values?.skillCategory["@id"],
          });
          toast.success(t("admin.params.skills.alert_edited"));
          reload();
          close();
        } else {
          await skillsAPI.post({
            label: values.label,
            description: values.description,
            skillCategory: values?.skillCategory["@id"],
          });
          toast.success(t("admin.params.skills.alert_created"));
          reload();
          close();
        }
      } else if (type === "cat") {
        await skillsCatAPI.post({
          label: values.label,
          description: values.description,
        });
        toast.success(t("admin.params.skills.alert_cat_created"));
        reload();
        close();
      }

      setIsLoading(false);
    } catch (err) {
      toast.error(t("errors.error_append"));
      setIsLoading(false);
    }
  };

  const deleteSkill = async () => {
    try {
      await skillsAPI.delete(skill.id);
      toast.success(t("admin.params.skills.alert_deleted"));
      reload();
      close();
      setIsLoading(false);
    } catch (err) {
      toast.error(t("errors.error_append"));
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
        }}
      >
        <Box display="flex" alignItems="center" sx={{ p: 1 }}>
          <IconButton aria-label="close" onClick={close} sx={{ ml: "auto" }}>
            <HighlightOff />
          </IconButton>
        </Box>
        <Typography textAlign="center" variant="h5" sx={{ mt: -4 }}>
          {type === "skill"
            ? t(`admin.params.skills.${skill ? "edit" : "create"}`)
            : t("admin.params.skills.create_skill_cat")}
        </Typography>
        <CardContent>
          <AdminCreateSkillsForm
            loading={isLoading}
            submit={handleSubmit}
            close={close}
            deleteSkill={deleteSkill}
            skill={skill}
            type={type}
            form={
              skill
                ? { ...skill, description: "" }
                : {
                    label: "",
                    description: "",
                    ...(type === "skill" && { skillCategory: "" }),
                  }
            }
          />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AdminCreateSkillModal;
