import { Box, Typography, Skeleton } from "@mui/material";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    msg: {
      marginLeft: "3vh",
      marginRight: "3vh",
      marginTop: "1vh",
      marginBottom: "1vh",
      display: "flex",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
      right: 0,
    },

    msg_icon_left: {
      width: "40px",
      height: "40px",
      borderRadius: "100%",
      margin: "8px 12px 0 0",
    },
    msg_icon_right: {
      width: "40px",
      height: "40px",
      borderRadius: "100%",
      margin: "8px 0 0 12px",
    },

    msg_text: {
      backgroundColor: "#fff",
      maxWidth: "315px",
      padding: "12px",
      textAlign: "left",
      borderRadius: theme.shape.borderRadius8,
      fontSize: "12px",
    },
    msg_left: {
      borderRadius: "0 12px 12px 0",
    },
    msg_right: {
      borderRadius: "12px 0 0 12px",
    },
    msg_text_container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    msg_time: {
      fontSize: "10px",
      margin: "5px 5px",
      display: "inline-block",
    },
    isRightCont: {
      marginLeft: "auto",
    },
    isRightText: {
      marginLeft: "auto",
    },
  })
);

// eslint-disable-next-line no-empty-pattern
const MessageSkeleton: React.FC<any> = ({ own }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.msg}>
        {own ? (
          <></>
        ) : (
          <Box className={classes.msg_icon_left}>
            <Skeleton variant="circular" width="40px" height="40px" />
          </Box>
        )}
        <Box
          className={clsx(
            classes.msg_text_container,
            own ? classes.isRightCont : null
          )}
        >
          <Box
            className={clsx(classes.msg_text, own ? classes.isRightText : null)}
          >
            <Typography component="p" sx={{ fontSize: "12px" }}>
              <Skeleton width="250px" height="40px" />
            </Typography>
          </Box>
          <Box
            className={clsx(classes.msg_time, own ? classes.isRightCont : null)}
          >
            <Skeleton width="50px" height="20px" />
          </Box>
        </Box>
        {own ? (
          <Box className={classes.msg_icon_right}>
            <Skeleton variant="circular" width="40px" height="40px" />{" "}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default MessageSkeleton;
