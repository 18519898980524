import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayTest: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">{t("admin.params.tests.title")}</Typography>
      <FormGroup>
        <FormControlLabel
          value={param.DOWNLOAD_TESTS}
          onChange={(e: any) =>
            handleChanges({ ...param, DOWNLOAD_TESTS: e.target.checked })
          }
          control={<Checkbox checked={param.DOWNLOAD_TESTS} />}
          label={t("admin.params.tests.download")}
        />
      </FormGroup>

      <Typography textAlign="right">{t("admin.params.reco_text")}</Typography>
    </div>
  );
};

export default AdminDisplayTest;
