import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayPageCollab: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">{t("admin.params.collab.title")}</Typography>

      <FormGroup sx={{ mt: 2 }}>
        <TextField
          label={t("admin.params.collab.menu")}
          value={param.MENU_NAME}
          onChange={(event: any) =>
            handleChanges({ ...param, MENU_NAME: event.target.value })
          }
        />
        <FormControlLabel
          value={param.DISPLAY_SUGGEST_COLLAB}
          onChange={(e: any) =>
            handleChanges({
              ...param,
              DISPLAY_SUGGEST_COLLAB: e.target.checked,
            })
          }
          control={<Checkbox checked={param.DISPLAY_SUGGEST_COLLAB} />}
          label={t("admin.params.collab.suggest")}
        />
      </FormGroup>
      <Typography sx={{ mt: 2 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
    </div>
  );
};

export default AdminDisplayPageCollab;
