import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AdminRecruiterTests: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const { tests } = useSelector((state: any) => state.home?.paramjson ?? {});

  const onChange = (obj: any) => {
    handleChanges({
      ...param,
      gamified: Boolean(obj.gamified),
      dynamics: Boolean(obj.dynamics),
    });
  };

  return (
    <div>
      {param && (
        <>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {t("admin.params.tests.passed")}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t(`admin.params.tests.preselected`, { context })}
          </Typography>
          <FormGroup>
            {tests.personality && (
              <FormControlLabel
                value={param.personality}
                onChange={(e: any) =>
                  handleChanges({ ...param, personality: e.target.checked })
                }
                control={<Checkbox checked={param.personality} />}
                label={t("tests.b5")}
              />
            )}
            {tests.motivations && (
              <FormControlLabel
                value={param.motivations}
                onChange={(e: any) =>
                  handleChanges({ ...param, motivations: e.target.checked })
                }
                control={<Checkbox checked={param.motivations} />}
                label={t("tests.cf")}
              />
            )}
            <Divider />
            {tests.dynamics && (
              <FormControlLabel
                value={param.dynamics}
                onChange={(e: any) => onChange({ dynamics: e.target.checked })}
                control={<Checkbox checked={param.dynamics} />}
                label={t("tests.inc")}
              />
            )}
            {tests.gamified && (
              <FormControlLabel
                value={param.gamified}
                onChange={(e: any) => onChange({ gamified: e.target.checked })}
                control={<Checkbox checked={param.gamified} />}
                label={t("tests.gamified")}
              />
            )}
          </FormGroup>
        </>
      )}
    </div>
  );
};

export default AdminRecruiterTests;
