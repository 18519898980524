import React, { useRef, useState, useLayoutEffect } from "react";
import CategoryItem from "./CategoryItem";
import { ButtonBase, styled } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// import LinearProgress, {
//   linearProgressClasses,
// } from "@mui/material/LinearProgress";

const CategoriesSlider: React.FC<any> = ({
  selectCategory,
  categories,
  selected,
}) => {
  const rootRef = useRef<any>(null);

  const [rightArrowOptions, setRightArrowOptions] = useState<any>({});
  const [leftArrowOptions, setLeftArrowOptions] = useState<any>({});
  const [hasScroll, setHasScroll] = useState(false);

  useLayoutEffect(() => {
    const current = rootRef.current;
    handleEffect();
    window.addEventListener("resize", handleEffect);

    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", handleEffect);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootRef]);

  const scrollRight = () => {
    const current = rootRef.current;
    if (!current) return;
    current.scroll({
      top: 0,
      left: current.scrollLeft + current.clientWidth,
      behavior: "smooth",
    });
  };

  const scrollLeft = () => {
    const current = rootRef.current;
    if (!current) return;
    current.scroll({
      top: 0,
      left: current.scrollLeft - current.clientWidth,
      behavior: "smooth",
    });
  };

  const handleEffect = () => {
    const current = rootRef.current;
    if (current) {
      if (current.clientWidth < current.scrollWidth) {
        setHasScroll(true);
        setRightArrowOptions({
          left: `${current.clientWidth - 40 + current.scrollLeft}px`,
        });
        setLeftArrowOptions({
          left: `${current.scrollLeft + 10}px`,
        });
        current.addEventListener("scroll", handleScroll);
      } else {
        setHasScroll(false);
      }
    }
  };

  const handleScroll = (event: any) => {
    setHasScroll(true);
    setRightArrowOptions({
      left: `${event.target.clientWidth - 40 + event.target.scrollLeft}px`,
    });
    setLeftArrowOptions({
      left: `${event.target.scrollLeft + 10}px`,
    });
  };

  return (
    <Root ref={rootRef}>
      {categories.map((el: any, index: number) => (
        <CategoryItem
          key={el.label}
          title={el.label}
          theme={el.theme}
          value={el.value}
          current={selected === index}
          select={() => selectCategory(index)}
        />
      ))}

      {hasScroll && (
        <>
          {rootRef?.current?.scrollLeft + rootRef?.current?.clientWidth <
            rootRef?.current?.scrollWidth && (
            <ArrowButton onClick={scrollRight} sx={rightArrowOptions}>
              <ChevronRightIcon />
            </ArrowButton>
          )}
          {rootRef?.current?.scrollLeft > 0 && (
            <ArrowButton onClick={scrollLeft} sx={leftArrowOptions}>
              <ChevronLeftIcon />
            </ArrowButton>
          )}
        </>
      )}
    </Root>
  );
};

export default CategoriesSlider;

const Root = styled("div")(({ theme }: any) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    visibility: "hidden",
    background: theme.palette.grey[400],
    width: 1,
    height: 0,
  },
  "&::-webkit-scrollbar-thumb": {
    background: "grey",
    visibility: "hidden",
    borderRadius: "10px",
    "&:hover": {
      background: theme.palette.primary.light,
    },
  },
  maxWidth: "100%",
  padding: "7px",
  paddingBottom: "30px",
  borderRadius: theme.shape.borderRadius10,
  [theme.breakpoints.down("lg")]: {
    // maxWidth: "664px",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

const ArrowButton = styled(ButtonBase)(({ theme }: any) => ({
  display: "inherit",
  color: theme.palette.secondary[500_80],
  border: `1px solid ${theme.palette.secondary[500_80]}`,
  background: "white",
  position: "absolute",
  zIndex: 3,
  borderRadius: "16px",
  "&:hover": {
    background: theme.palette.grey[200],
  },
}));
