import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormGroup,
  FormControlLabel,
  IconButton,
  Slider,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import rncpLevels from "../../_utils/trainingRncpList";
import DatePicker from "../common/DatePicker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useDebounce from "../../hooks/useDebounce";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import SelectComponent from "../common/SelectComponent";
import useFetch from "../../hooks/useFetch";
import workCategoryAPI from "../../_api/_workCategoryAPI";

const NbrResult = styled(Box)(({ theme }: any) => ({
  height: "31px",
  borderRadius: "9px",
  padding: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.primary[500_8],
}));

const ArrowRotate: any = styled(IconButton)(({ rotate }: any) => ({
  transition: "all 0.25s ease-in-out",
  transform: `rotate(${rotate === "true" ? "180" : "0"}deg)`,
}));

const CustomSlider = styled(Slider)(({ theme }: any) => ({
  width: "220px",
  "& .MuiSlider-rail": {
    backgroundColor: "grey !important",
    height: "5px",
  },
  "& .MuiSlider-track": {
    height: "5px",
  },
  "& .MuiSlider-thumbColorPrimary:after": {
    width: "17px",
    height: "17px",
  },
  "& .MuiSlider-valueLabelOpen": {
    transform: "translateY(160%) scale(1) !important",
    backgroundColor: "transparent",
    color: theme.palette.secondary[400],
  },
  "& .MuiSlider-valueLabelOpen:before": {
    display: "none",
  },
}));

const WorkTrainingFiltersPage: React.FC<{
  httpParams: any;
  setHttpParams: any;
  mobileScreen?: boolean;
  workPage?: boolean;
  totalItems?: number;
}> = ({
  httpParams,
  setHttpParams,
  mobileScreen,
  workPage,
  totalItems,
}: any) => {
  const { t } = useTranslation("common");
  const [price, setPrice] = useState(httpParams.price);
  const debouncedPrice = useDebounce(price, 300);
  const [duration, setDuration] = useState(httpParams.duration);
  const debouncedDuration = useDebounce(duration, 300);
  const [displayFilters, setDisplayFilters] = useState<boolean>(false);
  const [categoryListFilter, seetCategoryListFilter] = useState<any>([]);

  const categoryCall = useFetch(workCategoryAPI.get({ pagination: false }));

  const handlePrice = (event: any) => {
    setHttpParams?.({ ...httpParams, page: 1, price: event });
  };

  const handleDuration = (event: any) => {
    setHttpParams?.({ ...httpParams, page: 1, duration: event });
  };

  const handleCategory = (event: any) => {
    const code: string =
      categoryListFilter?.[
        categoryListFilter?.findIndex((item: any) => item?.id === event)
      ]?.code;
    const newParams = { ...httpParams };
    newParams.page = 1;
    code
      ? (newParams.category = { id: event, code: code.slice(4) })
      : (newParams.category = "");
    setHttpParams(newParams);
  };

  const handleTrainingsLevel = (level: number) => {
    const newParams = { ...httpParams };
    newParams.page = 1;
    newParams.levels.includes(level)
      ? newParams.levels.splice(
          newParams.levels.findIndex((item: number) => item === level),
          1
        )
      : newParams.levels.push(level);
    setHttpParams(newParams);
  };

  useEffect(() => {
    categoryCall?.data?.["hydra:member"] &&
      seetCategoryListFilter(categoryCall?.data?.["hydra:member"]);
  }, [categoryCall]);

  useEffect(() => {
    if (debouncedPrice) {
      handlePrice(debouncedPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPrice]);

  useEffect(() => {
    if (debouncedDuration) {
      handleDuration(debouncedDuration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDuration]);

  return (
    <Box sx={{ width: workPage ? "300px" : "250px" }}>
      <Box
        display="flex"
        alignItems="center"
        sx={{ mt: 1, mb: 2, cursor: mobileScreen ? "pointer" : "" }}
        onClick={() => mobileScreen && setDisplayFilters(!displayFilters)}
      >
        <Typography
          fontWeight="bold"
          variant="body1"
          color="primary"
          sx={{ fontSize: "16px" }}
        >
          {t("filters.label")}
        </Typography>
        <NbrResult sx={{ ml: 2 }}>
          <Typography color="primary" variant="body1">
            {t("filters.results", { count: totalItems })}
          </Typography>
        </NbrResult>
        {mobileScreen && (
          <ArrowRotate
            sx={{ ml: "auto" }}
            rotate={JSON.stringify(displayFilters)}
            onClick={() => setDisplayFilters(!displayFilters)}
          >
            <KeyboardArrowDownIcon />
          </ArrowRotate>
        )}
      </Box>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Collapse in={mobileScreen ? displayFilters : true}>
        <>
          {!workPage && (
            <Box sx={{ opacity: httpParams.type === 2 ? 0.3 : 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, mt: 1 }}>
                {t("admin.params.works_cat.title")}
              </Typography>
              <SelectComponent
                items={categoryListFilter}
                label={t("autocomplete.categories")}
                icon={<FormatListBulletedRoundedIcon />}
                onChange={handleCategory}
                preSelected={httpParams?.category?.id}
                disabled={httpParams.type === 2}
              />
              <Divider sx={{ mt: 4, mb: 2 }} />
            </Box>
          )}
          <Typography variant="subtitle1">
            {t("trainings.filters.price")}
          </Typography>
          <CustomSlider
            value={price}
            onChange={(event: Event, newValue: number | number[]) =>
              setPrice(newValue as number[])
            }
            valueLabelDisplay="on"
            valueLabelFormat={(x: number) => `${x} €`}
            min={0}
            max={30000}
            step={500}
          />
          <Divider sx={{ mt: 4, mb: 2 }} />
          <Typography variant="subtitle1">
            {t("trainings.filters.certif")}
          </Typography>
          <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={httpParams?.certif}
                  onClick={() =>
                    setHttpParams?.({
                      ...httpParams,
                      certif: !httpParams.certif,
                      page: 1,
                    })
                  }
                />
              }
              label={
                <Typography
                  variant="body1"
                  color={httpParams?.certif ? "primary" : "secondary.400"}
                >
                  {t(`trainings.filters.certif_2`)}
                </Typography>
              }
            />
          </FormGroup>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Typography variant="subtitle1">
            {t("trainings.filters.leave_level")}
          </Typography>
          <FormGroup sx={{ mt: 1 }}>
            {rncpLevels.map((item: any) => (
              <FormControlLabel
                key={item.level}
                control={
                  <Checkbox
                    checked={httpParams?.levels?.includes(item.level)}
                    onClick={() => handleTrainingsLevel(item.level)}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    color={
                      httpParams?.levels?.includes(item.level)
                        ? "primary"
                        : "secondary.400"
                    }
                  >
                    {item.label}
                  </Typography>
                }
              />
            ))}
          </FormGroup>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Typography variant="subtitle1">
            {t("trainings.filters.indemnification")}
          </Typography>
          <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={httpParams?.indemn}
                  onClick={() =>
                    setHttpParams?.({
                      ...httpParams,
                      indemn: !httpParams.indemn,
                      page: 1,
                    })
                  }
                />
              }
              label={
                <Typography
                  variant="body1"
                  color={httpParams?.indemn ? "primary" : "secondary.400"}
                >
                  {t(`trainings.filters.indem_2`)}
                </Typography>
              }
            />
          </FormGroup>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Typography variant="subtitle1">
            {t("trainings.filters.duration")}
          </Typography>
          <CustomSlider
            value={duration}
            onChange={(event: Event, newValue: number | number[]) =>
              setDuration(newValue as number[])
            }
            valueLabelDisplay="on"
            valueLabelFormat={(x: number) => `${x} h`}
            min={0}
            max={5000}
            step={200}
          />
          <Divider sx={{ mt: 4, mb: 2 }} />
          <Typography variant="subtitle1">
            {t("trainings.filters.dateStart")}
          </Typography>
          <DatePicker
            sx={{
              width: "200px",
              mt: 2,
              "& .MuiInputLabel-root": { zIndex: "0 !important" },
            }}
            label={t("form.start_date")}
            minDate={new Date()}
            date={httpParams?.dateStart}
            variant="outlined"
            sendDataToParent={(val: any) =>
              setHttpParams?.({ ...httpParams, dateStart: val, page: 1 })
            }
          />
          <Typography variant="subtitle1" sx={{ mt: 3 }}>
            {t("trainings.filters.dateEnd")}
          </Typography>
          <DatePicker
            sx={{
              width: "200px",
              mt: 2,
              "& .MuiInputLabel-root": { zIndex: "0 !important" },
            }}
            label={t("form.end_date")}
            minDate={
              httpParams?.dateStart
                ? new Date(httpParams?.dateStart)
                : new Date()
            }
            date={httpParams?.dateEnd}
            variant="outlined"
            sendDataToParent={(val: any) =>
              setHttpParams?.({ ...httpParams, dateEnd: val, page: 1 })
            }
          />
        </>
      </Collapse>
    </Box>
  );
};

export default WorkTrainingFiltersPage;
