import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminGeneralTests: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">{t("admin.params.tests.title")}</Typography>
      {param && (
        <>
          <FormGroup>
            <FormControlLabel
              value={param.gamified}
              onChange={(e: any) =>
                handleChanges({ ...param, gamified: e.target.checked })
              }
              control={<Checkbox checked={param.gamified} />}
              label={t("admin.params.tests.gamified")}
            />
            <FormControlLabel
              value={param.b5}
              onChange={(e: any) =>
                handleChanges({ ...param, b5: e.target.checked })
              }
              control={<Checkbox checked={param.b5} />}
              label={t("admin.params.tests.b5")}
            />
            <FormControlLabel
              value={param.cf}
              onChange={(e: any) =>
                handleChanges({ ...param, cf: e.target.checked })
              }
              control={<Checkbox checked={param.cf} />}
              label={t("admin.params.tests.cf")}
            />
            <FormControlLabel
              value={param.inc}
              onChange={(e: any) =>
                handleChanges({ ...param, inc: e.target.checked })
              }
              control={<Checkbox checked={param.inc} />}
              label={t("admin.params.tests.inc")}
            />
            <FormControlLabel
              value={param.mood}
              onChange={(e: any) =>
                handleChanges({ ...param, mood: e.target.checked })
              }
              control={<Checkbox checked={param.mood} />}
              label={t("admin.params.tests.mood")}
            />
          </FormGroup>
        </>
      )}
    </div>
  );
};

export default AdminGeneralTests;
