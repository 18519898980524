import axios, { AxiosRequestConfig } from "axios";
import { AxiosObservable, createObservable } from "./createObservable";

class http {
  static get<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): AxiosObservable<T> {
    return createObservable<T>(axios.get, url, config);
  }

  static post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosObservable<T> {
    return createObservable<T>(axios.post, url, data, config);
  }

  static put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosObservable<T> {
    return createObservable<T>(axios.put, url, data, config);
  }

  static patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosObservable<T> {
    return createObservable<T>(axios.patch, url, data, config);
  }

  static delete<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): AxiosObservable<T> {
    return createObservable<T>(axios.delete, url, config);
  }
}

export default http;
