export const colors = [
  "green",
  "purple",
  "orange",
  "error",
  "primay",
  "success",
  "info",
  "warning",
  "red",
];
