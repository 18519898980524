import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AskShareModal from "../../../components/benef/AskShareModal";

const AskShareButton: React.FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const close = () => setOpen(false);

  return (
    <>
      <Button
        onClick={openModal}
        variant="contained"
        sx={{ width: "100%", height: "50px" }}
      >
        <Typography sx={{ ml: 1 }}>
          {t("third_party.profiles.ask_accept_share")}
        </Typography>
      </Button>
      <AskShareModal
        open={open}
        close={close}
        profiles={[{ id }]}
        onFinish={() => {}}
      />
    </>
  );
};

export default AskShareButton;
