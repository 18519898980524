import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ProfilePictureModal from "./ProfilePictureModal";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { checkFileExt } from "../../Utils";
import ProfilePictureSignUpModal from "./ProfilePictureSignUpModal";

const DropZone = styled(Box)(() => ({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "144px",
  height: "144px",
  padding: "5px",
  border: `1px dashed rgba(145, 158, 171, 0.32)`,
  transition: "all 0.2s ease-in-out",
  borderRadius: "50%",
  marginTop: "8px",
  "&:hover": {
    cursor: "pointer",
  },
}));

const NoImg = styled(Box)(() => ({
  backgroundColor: "rgb(244, 246, 248)",
  color: "rgb(99, 115, 129)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  "&:hover": {
    opacity: 0.72,
  },
}));

const WithImg = styled(Box)(({ theme }: any) => ({
  backgroundColor: "rgb(22, 28, 36)",
  color: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  "&:hover": {
    opacity: 0.72,
  },
}));

const ProfilePictureButton: React.FC<{
  image: any;
  onboarding?: boolean;
  handleBlob?: (blob: any) => void;
}> = ({ image, onboarding, handleBlob }) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [upImg, setUpImg] = useState<any>(null);
  const [hover, setHover] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [url, setUrl] = useState<any>(image ?? null);

  const onClick = () => {
    if (inputFileRef?.current) inputFileRef.current.click();
  };

  const onDrop = (event: any) => {
    setFile(event.dataTransfer.files[0]);
    stopEvent(event);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setOpen(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(event.dataTransfer.files.item(0));
    }
    setHover(false);
  };

  const onDragLeave = (event: any) => {
    stopEvent(event);
    setHover(false);
  };

  const onDragOver = (event: any) => {
    stopEvent(event);
    setHover(true);
  };

  const onFileChange = (e: any) => {
    if (!checkFileExt(e.target.files[0])) return;

    setFile(e.target.files[0]);
    setOpen(true);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const stopEvent = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <DropZone
        onDrop={onDrop}
        onClick={onClick}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        sx={{ cursor: hover ? "copy !important" : "auto" }}
      >
        {url === null ? (
          <NoImg>
            <AddAPhotoIcon sx={{ marginBottom: 1 }} />
            <Typography variant="body1">
              {t(`profilepage.${onboarding ? "your" : "add"}_photo`)}
            </Typography>
          </NoImg>
        ) : (
          <Box
            sx={{
              backgroundImage: `url(${url})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
          >
            <WithImg
              sx={{
                opacity: "0",
                "&:hover": { opacity: 1 },
              }}
            >
              <AddAPhotoIcon sx={{ marginBottom: 1 }} />
              <Typography variant="body1">
                {t(`profilepage.${onboarding ? "your" : "add"}_photo`)}
              </Typography>
            </WithImg>
          </Box>
        )}
      </DropZone>
      <Box sx={{ mb: -1, mt: 1 }}>
        <Typography variant="body2">
          {t("profilepage.allowed_files_photo")}
        </Typography>
        <Typography variant="body2">
          {t("profilepage.allowed_size_photo")}
        </Typography>
      </Box>
      <input onChange={onFileChange} ref={inputFileRef} type="file" hidden />
      {onboarding ? (
        <ProfilePictureSignUpModal
          setUrl={(url: string) => {
            setUrl(url);
          }}
          handleBlob={handleBlob}
          upImg={upImg}
          file={file}
          open={open}
          close={() => {
            setFile(null);
            setUpImg(null);
            setOpen(false);
          }}
        />
      ) : (
        <ProfilePictureModal
          setUrl={setUrl}
          upImg={upImg}
          file={file}
          open={open}
          close={() => {
            setFile(null);
            setUpImg(null);
            setOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default ProfilePictureButton;
