import React, { useState } from "react";
import {
  Modal,
  Card,
  CardContent,
  Typography,
  CardActions,
  Box,
  IconButton,
  styled,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../../_utils/modalStyle";
import LogoutModalButton from "./LogoutModalButton";
import { logout } from "./../../../redux/actions/authActions";
import useGlobalSplashscreen from "../../splashscreen/useGlobalSpashscreen";

const CardImage = styled("img")({
  position: "relative",
  left: "24%",
  width: "45%",
  marginTop: 1,
});

const LogoutModal: React.FC<{
  open: boolean;
  width?: string;
  close: () => void;
}> = ({ open, close, width = "510px" }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const [loggingout, setLoggingout] = useState(false);

  useGlobalSplashscreen(loggingout, "logout");

  const handleLogout = () => {
    setLoggingout(true);
    dispatch(logout());
  };

  return (
    <Modal open={open} onClose={close}>
      <Card
        sx={{
          ...modalStyle,
          width: {
            xs: "95%",
            sm: !width ? "auto" : width,
            md: !width ? "auto" : width,
          },
        }}
      >
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ width: "100%", display: "flex" }}>
            <IconButton
              aria-label="close modal"
              onClick={close}
              sx={{ ml: "auto", mt: 1, mr: -2, mb: 1 }}
            >
              <HighlightOff />
            </IconButton>
          </Box>
          <CardImage
            alt="Logout icon"
            src="/static/illustrations/Logout-illu.svg"
          />
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", mt: 3, fontWeight: 600 }}
          >
            {t("logout.want_to_logout")}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mt: -1,
            mb: 5,
          }}
        >
          <LogoutModalButton
            onClick={handleLogout}
            variant="contained"
            text={t("logout.log_me_out")}
          ></LogoutModalButton>
          <LogoutModalButton
            onClick={close}
            variant="outlined"
            text={t("logout.keep_me_connected")}
          ></LogoutModalButton>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default LogoutModal;
