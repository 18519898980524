import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@mui/icons-material";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  InputLabel,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import StringAvatar from "../../common/StringAvatar";
import { useSelector } from "react-redux";

const AdminSelectRecruiter: React.FC<{
  preSelected?: any;
  onChange: any;
  parentLoading?: boolean;
  items: any;
  recruiter: any;
}> = ({ preSelected, onChange, parentLoading, items, recruiter }) => {
  const context = useSelector((state: any) => state.auth.context);

  const { t } = useTranslation("common");
  const [values, setValues] = useState<any>(preSelected || []);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setValues(value);
    onChange(value?.map((item: any) => item?.id));
  };

  useEffect(() => {
    if (preSelected && items?.length > 0) {
      const selected: any = [];
      preSelected?.map((item: any) =>
        selected.push(items?.[items?.findIndex((e: any) => e?.id === item)])
      );
      setValues(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelected, items]);

  return (
    <FormControl fullWidth sx={{ m: 0.5 }}>
      <InputLabel id="recruitter-multiple-label">
        {t(`admin.params.right_access.choose_${context}`)}
      </InputLabel>
      <Select
        id="recruiters-select"
        onChange={handleChange}
        multiple
        sx={{ width: "250px" }}
        value={values}
        renderValue={(selected: any) =>
          selected
            ?.map((item: any) => item?.firstName + " " + item?.lastName)
            .join(" / ")
        }
        input={
          <OutlinedInput
            label={t(`admin.params.right_access.choose_${context}`)}
          />
        }
        IconComponent={ExpandMore}
        disabled={parentLoading}
      >
        {items?.map(
          (item: any) =>
            item?.id !== recruiter?.id && (
              <MenuItem key={item.id} value={item}>
                <Checkbox checked={values.indexOf(item) > -1} />
                <StringAvatar
                  size={30}
                  name={item.firstName + " " + item.lastName}
                  url={item?.person?.image?.contentUrl}
                />
                <ListItemText
                  sx={{ ml: 1 }}
                  primary={item.firstName + " " + item.lastName}
                />
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  );
};

export default AdminSelectRecruiter;
