import { Navigate, useRoutes } from "react-router-dom";
import { lazy, Suspense } from "react";
import LogoLoader from "../components/LogoLoader";
import FullLayout from "../layout/FullLayout";
import NoMenuLayout from "../layout/NoMenuLayout";

import ProfileContextProvider from "../contexts/ProfileContext";
import AccountParamsPage from "../pages/AccountParamsPage";
import IncPage from "../components/inc/IncPage";
import IncTestsPage from "../components/inc/IncTestsPage";
import IncTestContextProvider from "../components/inc/test/IncTestContext";
import Big5Page from "../pages/Big5Page";
import CulturefitPage from "../pages/CulturefitPage";
import TestsPage from "../pages/TestsPage";
import RefuseInvitationPage from "../pages/RefuseInvitationPage";
import { useSelector } from "react-redux";
const CguPage = lazy(() => import("../pages/CguPage"));
const NotFound = lazy(() => import("../pages/NotFound"));
const OnboardingPage = lazy(() => import("../pages/OnboardingPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const DeactivateReminder = lazy(() => import("../pages/DeactivateReminder"));

// ----------------------------------------------------------------------

export default function Router() {
  const { onboardingTest } = useSelector((state: any) => state.user);
  const menu = useSelector((state: any) => state.menu);

  const hasProfile =
    onboardingTest?.includes("job") || onboardingTest?.includes("skills");

  return useRoutes([
    {
      path: "app",
      element: <FullLayout />,
      children: [
        {
          path: "",
          element: (
            <Navigate
              to={hasProfile ? "/app/profile/overview" : "/app/tests"}
              replace
            />
          ),
        },
        hasProfile && {
          path: "profile",
          element: <Navigate to="/app/profile/overview" replace />,
        },
        hasProfile && {
          path: "profile/:tab",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <ProfilePage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "tests",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <TestsPage />
            </Suspense>
          ),
        },
        {
          path: "big5",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <Big5Page />
            </Suspense>
          ),
        },
        {
          path: "culturefit",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <CulturefitPage />
            </Suspense>
          ),
        },
        {
          path: "inc",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <IncPage />
            </Suspense>
          ),
        },
        {
          path: "inc/test",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <IncTestContextProvider>
                <IncTestsPage />
              </IncTestContextProvider>
            </Suspense>
          ),
        },
        menu?.settings && {
          path: "account",
          children: [
            {
              path: "",
              element: <Navigate to="/app/account/params" replace />,
            },
            {
              path: "params",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <AccountParamsPage />
                </Suspense>
              ),
            },
          ],
        },
      ].filter((el) => el),
    },
    {
      path: "",
      element: <NoMenuLayout />,
      children: [
        {
          path: "onboarding",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <IncTestContextProvider>
                  <OnboardingPage />
                </IncTestContextProvider>
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "cgu",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <CguPage />
            </Suspense>
          ),
        },
        {
          path: "refused-invitation",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RefuseInvitationPage />
            </Suspense>
          ),
        },
        {
          path: "deactivate_reminder",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <DeactivateReminder />
            </Suspense>
          ),
        },
        {
          path: "404",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <NotFound />
            </Suspense>
          ),
        },
        { path: "/", element: <Navigate to="/app" replace /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
