import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { modalStyle } from "../../../_utils/modalStyle";
import { HighlightOff } from "@mui/icons-material";
import axios from "axios";
import { toastError } from "../../../_utils/toastError";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const WorkflowModal: React.FC<{
  open: string;
  close: () => void;
  personId: string;
  workflowState: any;
  setWorkflowState: (ws: any) => void;
  hasInvalidSkills: boolean;
}> = ({
  open,
  close,
  personId,
  setWorkflowState,
  workflowState,
  hasInvalidSkills,
}) => {
  const [loading, setLoading] = useState(false);
  const { isCollab } = useSelector((state: any) => state.auth);
  const { status } = workflowState;
  const { t } = useTranslation("common");

  useEffect(() => {
    setLoading(false);
  }, [open]);

  const submit = async () => {
    try {
      const res = await axios.post("/skill_workflows/validate", {
        person: `/api/people/${personId}`,
      });
      setWorkflowState({ ...res.data, loading: false });
      setLoading(false);
      close();

      toast.success(t("skills.workflow.validate"));

      // TODO validation message
    } catch (err) {
      // TODO 409

      toastError("errors.add_manager");
      setLoading(false);
    }
  };

  return (
    <Modal open={Boolean(open)}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "80%", md: "500px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            paddingTop: 3,
            mb: -1,
          }}
        >
          <Box sx={{ ml: "48px", flexGrow: 1 }}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {t("skills.workflow.modal.title", {
                context: isCollab ? "collab" : "manager",
              })}
            </Typography>
          </Box>
          <IconButton sx={{ mr: 1, mt: -2 }} onClick={close} aria-label="close">
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent>
          <Typography>
            {(status !== 30 && status !== 60) || (isCollab && open === "ok")
              ? t(
                  `skills.workflow.modal.content_${status < 60 ? status : 30}`,
                  {
                    context:
                      status === 30 || status === 60
                        ? hasInvalidSkills
                          ? "invalid"
                          : "valid"
                        : "",
                  }
                )
              : t("skills.workflow.modal.need_all")}

            {}
            {/* {open === "ok"
              ? t("skills.workflow.confirm", {
                  context: isCollab ? "collab" : "manager",
                })
              : t("skills.workflow.confirm_unfinished")} */}
          </Typography>
        </CardContent>

        <CardActions sx={{ justifyContent: "center" }}>
          <SButton
            size="large"
            disabled={loading}
            variant="outlined"
            onClick={close}
          >
            Annuler
          </SButton>
          {((status !== 30 && status !== 60) ||
            (isCollab && open === "ok")) && (
            <SButton
              size="large"
              disabled={loading}
              onClick={submit}
              variant="contained"
            >
              {t("btn.confirm")}
            </SButton>
          )}
        </CardActions>
      </Card>
    </Modal>
  );
};

export default WorkflowModal;

const SButton = styled(Button)`
  width: 255px;
  font-weight: normal;
`;
