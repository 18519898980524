import {
  Button,
  Card,
  CardActions,
  CardContent,
  Box,
  Modal,
  Typography,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { autocompleteTypes } from "../../_utils/autocompleteTypes";
import AutocompleteComponent from "../common/AutocompleteComponent";
import SkillEdit from "./SkillEdit";
import SkillsSuggests from "./SkillsSuggests";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { modalStyle } from "../../_utils/modalStyle";
import { toast } from "react-toastify";
import SelectComponent from "../common/SelectComponent";
import skillsAPI from "../../_api/_skillsAPI";

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  margin: "auto",
  marginBottom: "11px",
  width: 55,
  height: 55,
  background: theme.palette.primary[500_8],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SkillEditList: React.FC<{
  skillsList: any;
  handleSkill: any;
  handleRemove: any;
}> = ({ skillsList, handleSkill, handleRemove }) => {
  return (
    <>
      {skillsList.map((skill: any) => (
        <SkillEdit
          skill={skill}
          key={skill.id}
          identifier={skill.id}
          offline={true}
          skillEmitter={handleSkill}
          deleteEmitter={handleRemove}
          deleteValidation={false}
          canRemove={true}
        />
      ))}
    </>
  );
};

const SkillModal: React.FC<{
  open: boolean;
  width?: string;
  handleSubmit?: any;
  type?: string;
  campaignId?: string;
  editable?: boolean;
  editableSkills?: any;
  skipSuggest?: boolean;
  noApi?: boolean;
  transversal?: boolean;
  close: () => void;
}> = ({
  open,
  close,
  width = "650px",
  handleSubmit,
  type = "",
  editable,
  editableSkills,
  campaignId,
  skipSuggest,
  noApi,
  transversal,
}) => {
  const { t }: any = useTranslation("common");
  const theme: any = useTheme();
  const [skillsList, setSkillsList] = useState<any>(
    editable || noApi ? editableSkills : []
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [abilities, setAbilities] = useState<any>([]);

  const handleSelect = (val: any) => {
    if (val.id && skillsList.findIndex((el: any) => el.id === val.id) === -1) {
      if (campaignId) {
        if (
          val?.id &&
          editableSkills?.findIndex((el: any) => el?.skill?.id === val?.id) ===
            -1
        ) {
          setSkillsList([...skillsList, { ...val, level: { level: 1 } }]);
        } else {
          toast.warning(
            t(
              `errors.${
                type !== "transversal" ? "skills" : "abilities"
              }_already_add`
            )
          );
        }
      } else {
        setSkillsList([...skillsList, { ...val, level: { level: 1 } }]);
      }
    }
  };

  const handleRemove = (skill: any) => {
    const index = skillsList.findIndex((el: any) => el.id === skill.id);

    const cp = [...skillsList];
    cp.splice(index, 1);
    setSkillsList(cp);
  };

  const handleSkill = (skill: any) => {
    const index = skillsList.findIndex((el: any) => el.id === skill.id);
    const cp = [...skillsList];
    cp[index] = skill;
    setSkillsList(cp);
  };

  useEffect(() => {
    setSkillsList(editable ? editableSkills : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSave = () => {
    if (noApi) {
      handleSubmit(skillsList);
      setSkillsList([]);
      close();
      return;
    }
    if (skillsList.length === 0) return close();
    setLoading(true);
    let calls = [];
    if (editable) {
      const skillsToEdit = skillsList.filter(
        (el: any, index: number) =>
          el.level.level !== editableSkills[index].level.level
      );
      const skillsToRemove = editableSkills.filter(
        (el: any, index: number) => el?.id !== skillsList?.[index]?.id
      );
      skillsToEdit.map((skill: any) =>
        calls.push(
          axios.put(`campaign_skills/${skill.id}`, {
            skill: skill.skill["@id"],
            campaign: campaignId,
            level: "/api/skill_levels/" + skill.level.level,
          })
        )
      );
      skillsToRemove.map((skill: any) =>
        calls.push(axios.delete(`campaign_skills/${skill.id}`))
      );
    } else {
      calls = skillsList.map((skill: any) =>
        axios.post(campaignId ? "campaign_skills" : "person_skills", {
          level: `/api/skill_levels/${skill.level.level}`,
          skill: skill["@id"],
          ...(campaignId && { campaign: campaignId }),
          ...(!campaignId && { label: skill.label }),
        })
      );
    }

    Promise.all(calls)
      .then((res: any) => {
        const all = res.map((r: any) => r?.data);
        if (editable) {
          const newSkills = skillsList;
          all.map(
            (skill: any) =>
              (newSkills[newSkills.findIndex((el: any) => el.id === skill.id)] =
                skill)
          );
          setSkillsList(newSkills);
        }
        if (handleSubmit) handleSubmit(editable ? skillsList : all);
        setLoading(false);
        toast.success(
          t(
            `success.${type !== "transversal" ? "skills" : "aptitudes"}_${
              editable ? "modified" : "added"
            }`
          )
        );
        close();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error(t("errors.error_append"));
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await axios.get(
        skillsAPI.getById("autocomplete", {
          pagination: false,
          type: "transversal",
        })
      );
      setAbilities(data?.data?.["hydra:member"]);
    };
    type === "transversal" && fetchData();
  }, [type]);

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "80%", md: !width ? "auto" : width },
        }}
      >
        <CardContent sx={{ pt: 0 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              paddingTop: 3,
              mb: 2,
            }}
          >
            <Box sx={{ ml: "24px", flexGrow: 1 }}>
              <Icon>
                {type !== "transversal" ? (
                  <BuildOutlinedIcon color="primary" />
                ) : (
                  <LightbulbOutlinedIcon color="primary" />
                )}
              </Icon>
              <Typography
                variant="h5"
                color="secondary"
                sx={{ textAlign: "center" }}
              >
                {editable
                  ? t(
                      `profilepage.skills_section.edit${
                        type === "transversal" ? "_transversal" : ""
                      }`
                    )
                  : type === "transversal"
                  ? t("profilepage.skills_section.add_one_transversal")
                  : t("profilepage.skills_section.add_one")}
              </Typography>
            </Box>
            <IconButton
              onClick={close}
              sx={{ mr: -2, mt: -2 }}
              aria-label="close"
            >
              <HighlightOff />
            </IconButton>
          </Box>

          {!editable && (
            <Box display="flex" justifyContent="center">
              <Box sx={{ width: { xs: "95%", md: "60%" } }}>
                {type === "transversal" ? (
                  <SelectComponent
                    items={abilities}
                    label={t(`skills.choose_list`)}
                    onChange={handleSelect}
                    clearOnSelect
                  />
                ) : (
                  <AutocompleteComponent
                    label={t(
                      `autocomplete.placeholder.search_${
                        type === "transversal" ? "abilities" : "skills"
                      }`
                    )}
                    placeholder={t("autocomplete.placeholder.skill_example")}
                    type={autocompleteTypes.skills}
                    clearOnSelect={true}
                    sendDataToParent={handleSelect}
                    skillType={type}
                  />
                )}
              </Box>
            </Box>
          )}

          {!campaignId && !skipSuggest && (
            <Box sx={{ mt: 4 }}>
              <SkillsSuggests
                skillType={type}
                skillsList={skillsList}
                handleSelect={handleSelect}
                canAdd={true}
              />
            </Box>
          )}

          <Stack sx={{ mt: 2 }} spacing={2}>
            <SkillEditList
              skillsList={skillsList}
              handleSkill={handleSkill}
              handleRemove={handleRemove}
            />
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            pl: 3,
            pr: 3,
            pb: 2,
            mb: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleSave}
            sx={{ m: 1, width: "255px" }}
            size="large"
            disabled={loading}
            variant="contained"
          >
            <Typography variant="body1">
              {t(
                `skills.btn_valid${type === "transversal" ? "_abilities" : ""}`
              )}
            </Typography>
          </Button>
          <Button
            onClick={close}
            sx={{ m: 1, width: "255px", color: theme.palette.secondary[400] }}
            size="large"
            color="secondary"
            variant="outlined"
          >
            <Typography variant="body1">{t("skills.btn_cancel")}</Typography>
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default SkillModal;
