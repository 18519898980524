import { styled } from "@mui/material";
import React from "react";
import MoodRating from "../mood/MoodRating";

const SRoot = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled("img")`
  width: 50px;
  height: auto;
`;

const WorkSuggestMood: React.FC<{ value: number }> = ({ value }) => {
  return (
    <SRoot>
      <Logo src="/static/mood/logo.svg" />
      <MoodRating value={value} size="small" />
    </SRoot>
  );
};

export default WorkSuggestMood;
