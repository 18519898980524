import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";

const PasswordSecurityContainer = styled("div")(({ theme }: any) => ({
  display: "flex",
  justifyContent: "space-around",
  width: "98%",
  marginBottom: "0.5em !important",
  margin: theme.spacing(1),
}));

const PasswordSecurity: any = styled("div", {
  shouldForwardProp: (props) => props !== "color",
})(({ theme, color }: any) => ({
  height: "5px",
  width: "19%",
  backgroundColor: color ? theme.palette[color].main : "#CECECE",
}));

const PasswordRules: React.FC<{
  value: string;
}> = ({ value }) => {
  const pwdCheck = [1, 2, 3, 4, 5];
  const [progressSecurity, setProgressSecurity] = useState(0);

  const handleGetValue = (e: any) => {
    const minCharact = (value: string) => {
      return value.length >= 8;
    };
    const minLower = (value: string) => {
      let regex = /[a-z]/;
      return regex.test(value);
    };
    const minUpper = (value: string) => {
      let regex = /[A-Z]/;
      return regex.test(value);
    };
    const minSpecial = (value: string) => {
      let regex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      return regex.test(value);
    };
    const minNumber = (value: string) => {
      let regex = /\d/g;
      return regex.test(value);
    };

    const result = [
      minCharact(e),
      minLower(e),
      minUpper(e),
      minSpecial(e),
      minNumber(e),
    ];

    setProgressSecurity(result.filter((item) => item === true).length);
  };

  useEffect(() => {
    handleGetValue(value);
  }, [value]);

  return (
    <PasswordSecurityContainer>
      {pwdCheck.map((item) => (
        <PasswordSecurity
          key={item}
          color={
            progressSecurity >= item &&
            progressSecurity > 0 &&
            (progressSecurity < 3
              ? "error"
              : progressSecurity < 5
              ? "warning"
              : "success")
          }
        />
      ))}
    </PasswordSecurityContainer>
  );
};

export default PasswordRules;
