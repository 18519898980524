import { Avatar, Box, styled, Tooltip, Typography } from "@mui/material";
import React from "react";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const CustomAvatar: any = styled(Avatar, {
  shouldForwardProp: (props) => props !== "avatarstyle" && props !== "profile",
})(({ theme, avatarstyle, profile }: any) => ({
  border: !profile
    ? `1px solid ${theme.palette.primary.main} !important`
    : "auto",
  ...avatarstyle,
}));

const CustomBox = styled(Box)<{
  avatarstyle: any;
  name: string;
  size?: number;
}>(({ theme, avatarstyle, name, size }: any) => ({
  backgroundColor: stringToColor(name),
  color: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: size && size !== 30 ? 0 : 1,
  fontSize: size ? size / 2 : 15,
  textTransform: "uppercase",
  ...avatarstyle,
}));

const StringAvatar: React.FC<{
  name: string;
  size: number;
  url?: string;
  onClick?: () => void;
  index?: any;
  profile?: any;
  avatarStyle?: any;
  tooltip?: string;
  sx?: any;
}> = ({
  name,
  size,
  url,
  onClick,
  index,
  profile,
  avatarStyle,
  tooltip,
  sx,
}) => {
  let defaultSx = url
    ? { position: "relative", ml: index > 0 ? -1 : 0 }
    : {
        borderRadius: "50%",
        cursor: onClick ? "pointer" : "default",
        ml: index > 0 ? -0.5 : 0,
        zIndex: 50 - index,
        width: size ?? 30,
        height: size ?? 30,
        minWidth: size ?? 30,
        minHeight: size ?? 30,
      };

  let mergedSx = { ...defaultSx, ...sx };
  return url ? (
    <Box sx={mergedSx}>
      <CustomAvatar
        onClick={() => onClick && onClick()}
        sx={{
          width: size,
          height: size,
          cursor: onClick ? "pointer" : "default",
          zIndex: 50 - index,
        }}
        src={url}
        alt={name ?? "Avatar"}
        avatarstyle={avatarStyle}
        profile={profile}
      />
      {tooltip && (
        <Tooltip title={tooltip}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              position: "absolute",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "#F2F2F2",
              cursor: "pointer",
              bottom: 0,
              right: 0,
            }}
          >
            <Typography color="primary" variant="body3" fontWeight="bold">
              ?
            </Typography>
          </Box>
        </Tooltip>
      )}
    </Box>
  ) : (
    <CustomBox
      avatarstyle={avatarStyle}
      name={name}
      size={size}
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={() => onClick && onClick()}
      sx={mergedSx}
    >
      {name.split(" ")[0][0]}
      {name.split(" ")[1][0]}
    </CustomBox>
  );
};

export default StringAvatar;
