import {
  Box,
  ButtonBase,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import Page from "../components/Page";
import SearchIcon from "@mui/icons-material/Search";
import useRessources from "../hooks/useRessources";

import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";

import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ResourcesFiles from "../components/ressources/ResourcesFiles";
import ResourcesVideos from "../components/ressources/ResourcesVideos";
import { useTranslation } from "react-i18next";

const RoundedButton = styled(ButtonBase)`
  color: white;
  padding: 9px 20px;
  background: #333333;
  border: 1px solid #333333;
  border-radius: 100px;
  margin-left: 10px;
  margin-top: 5px;
  &.active {
    background: white;
    color: #333333;
  }
`;

const RessourcesPage = () => {
  const [sortType, setSortType] = useState<string | null>("");
  const [sortBy, setSortBy] = useState<string | null>("date:asc");
  const [search, setSearch] = useState<string | null>(null);

  const { t } = useTranslation("common");

  const { loading, videos, files } = useRessources(sortBy, sortType, search);

  const handleType = (type: string) => {
    setSortType(sortType === type ? "" : type);
  };

  const handleSearch = (event: any) => {
    console.log(event.target.value);
    setSearch(event.target.value);
  };

  return (
    <Page title="Ressources" padding sx={{ marginTop: "52px" }}>
      <section>
        <Typography color="primary" variant="h4">
          {t("resources.page_title")}
        </Typography>
        <Typography sx={{ maxWidth: "70ch" }} color="#6F6F6F">
          {t("resources.page_subtitle")}
        </Typography>

        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          marginRight="auto"
          marginTop="20px"
          marginBottom="20px"
        >
          <TextField
            id="outlined-start-adornment"
            sx={{ m: 1, width: "600px" }}
            placeholder={t("resources.search_placeholder")}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{ marginTop: "5px" }}
              variant="subtitle1"
              fontWeight="normal"
            >
              {t("resources.content_type")}
            </Typography>

            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <RoundedButton
                className={sortType === "" ? "active" : ""}
                onClick={handleType.bind(this, "")}
              >
                {t("resources.types.all")}
              </RoundedButton>
              <RoundedButton
                className={sortType === "guide" ? "active" : ""}
                onClick={handleType.bind(this, "guide")}
              >
                {t("resources.types.guide")}
              </RoundedButton>
              <RoundedButton
                className={sortType === "report" ? "active" : ""}
                onClick={handleType.bind(this, "report")}
              >
                {t("resources.types.report")}
              </RoundedButton>
              <RoundedButton
                className={sortType === "study" ? "active" : ""}
                onClick={handleType.bind(this, "study")}
              >
                {t("resources.types.study")}
              </RoundedButton>
              <RoundedButton
                className={sortType === "course" ? "active" : ""}
                onClick={handleType.bind(this, "course")}
              >
                {t("resources.types.course")}
              </RoundedButton>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Typography sx={{ whiteSpace: "nowrap", mr: 1 }}>
              {t("filters.order_by")}
            </Typography>
            <FormControl variant="standard" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sortBy}
                label="Age"
                onChange={(e: any) => setSortBy(e?.target.value as string)}
              >
                <MenuItem value={"date:asc"}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "nowrap",
                    }}
                  >
                    Date{" "}
                    <ArrowUpwardRoundedIcon
                      sx={{ fontSize: "16px", ml: 0.5 }}
                    />
                  </Typography>
                </MenuItem>
                <MenuItem value={"date:desc"}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "nowrap",
                    }}
                  >
                    Date{" "}
                    <ArrowDownwardRoundedIcon
                      sx={{ fontSize: "16px", ml: 0.5 }}
                    />
                  </Typography>
                </MenuItem>
                <MenuItem value={"name:asc"}>A-Z</MenuItem>
                <MenuItem value={"name:desc"}>Z-A</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </section>

      <section>
        <ResourcesFiles
          loading={loading}
          files={files}
          sorted={Boolean(sortBy || search)}
        />

        <ResourcesVideos
          loading={loading}
          videos={videos}
          sorted={Boolean(sortBy || search)}
        />
      </section>
    </Page>
  );
};

export default RessourcesPage;
