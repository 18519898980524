export const SET_OPEN = "SET_OPEN";
export const SET_TYPE = "SET_TYPE";
export const OPEN_WITH_TYPE = "OPEN_WITH_TYPE";
export const RESET_LOADER = "RESET_LOADER";

export const setLoaderOpen = (payload: boolean) => {
  return {
    type: SET_OPEN,
    payload,
  };
};

export const setLoaderType = (payload: string) => {
  return {
    type: SET_TYPE,
    payload,
  };
};

export const openLoaderWithType = (payload: string) => {
  return {
    type: OPEN_WITH_TYPE,
    payload,
  };
};

export const resetLoader = () => {
  return {
    type: RESET_LOADER,
  };
};
