import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import CulturefitContainer from "../components/culturefit/CulturefitContainer";
import Page from "../components/Page";
import CardIllustrationTests from "../components/tests/CardIllustrationTests";

const CulturefitPage = () => {
  const { t }: any = useTranslation("common");
  return (
    <Page title={t("tests.title.cf")}>
      <Container sx={{ pt: 13, pb: 5 }}>
        <CardIllustrationTests test="cf" height="240" />
        <CulturefitContainer />
      </Container>
    </Page>
  );
};

export default CulturefitPage;
