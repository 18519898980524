import { Box, styled, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const Container = styled(Box)(() => ({
  height: "10px",
  borderRadius: "10px",
  display: "flex",
  position: "relative",
  marginTop: ".5em",
  width: "100%",
  backgroundColor: "#7388A95A",
}));

const Big5EditableBars: React.FC<any> = ({ result, trait, handlePosition }) => {
  const [resultPosition, setResultPosition] = useState<number>();
  const theme: any = useTheme();

  const returnColor = () => {
    switch (trait) {
      case "agreeableness":
        return theme.palette.b5.agreeableness;
      case "conscientiousness":
        return theme.palette.b5.conscientiousness;
      case "extraversion":
        return theme.palette.b5.extraversion;
      case "neuroticism":
        return theme.palette.b5.neuroticism;
      case "openness":
        return theme.palette.b5.openness;
    }
  };

  const DotMarker = styled(Box)(() => ({
    zIndex: 1,
    width: "1px",
    height: "10px",
    backgroundColor: returnColor(),
    position: "absolute",
    content: "''",
    bottom: "0",
    top: "0",
  }));

  const EditableMarker: any = styled("span", {
    shouldForwardProp: (props) => props !== "index",
  })(({ index }: any) => ({
    zIndex: 1,
    cursor: "pointer",
    "&:before": {
      width: "20%",
      height: "10px",
      borderRadius: "10px",
      position: "absolute",
      bottom: "0",
      top: "0",
      content: "''",
      left: `${
        index === 1
          ? "0"
          : index === 2
          ? "20"
          : index === 3
          ? "40"
          : index === 4
          ? "60"
          : index === 5
          ? "80"
          : ""
      }%`,
    },
    "&:hover::before": {
      backgroundColor: returnColor(),
    },
  }));

  const ProgressMarker: any = styled("span", {
    shouldForwardProp: (props) => props !== "index",
  })(({ index }: any) => ({
    display: "flex",
    position: "relative",
    width: "100%",
    "&:before": {
      width: "20%",
      height: "10px",
      borderRadius: "10px",
      position: "absolute",
      bottom: "0",
      top: "0",
      content: "''",
      transition: "left .6s ease-in-out",
      left: `${
        index === 1
          ? "0"
          : index === 2
          ? "20"
          : index === 3
          ? "40"
          : index === 4
          ? "60"
          : index === 5
          ? "80"
          : ""
      }%`,
      willChange: "left",
      backgroundColor: returnColor(),
    },
    "&.compare:before": {
      backgroundColor: "#333539",
    },
  }));

  const ref = useRef();
  const [isVisible, setState] = useState(false);

  const handleResult = (event: any) => {
    handlePosition(event);
    setResultPosition(event.position);
  };

  useEffect(() => {
    let observer: any;

    if ("IntersectionObserver" in window) {
      observer = new IntersectionObserver(([entry]) => {
        setState(entry.isIntersecting);
      });
    } else {
      setState(true);
    }

    const current: any = ref.current;

    ref.current && observer && observer.observe(current);

    return () => current && observer.unobserve(current);
  }, []);

  useEffect(() => {
    result && setResultPosition(result);
  }, [result]);

  return (
    <Box ref={ref}>
      <Container>
        {Array.from(Array(5).keys()).map((index: number) => (
          <Box display="flex" key={index}>
            <EditableMarker
              index={index + 1}
              id={`${trait}-${index + 1}`}
              onClick={() =>
                handleResult({ trait: trait, position: index + 1 })
              }
            />
            {index < 5 && index > 0 && (
              <DotMarker
                sx={{
                  left: `${
                    index === 1
                      ? "20"
                      : index === 2
                      ? "40"
                      : index === 3
                      ? "60"
                      : index === 4
                      ? "80"
                      : ""
                  }%`,
                }}
              />
            )}
          </Box>
        ))}
        <ProgressMarker
          id={`${trait}-marker`}
          index={isVisible ? resultPosition : null}
        />
      </Container>
    </Box>
  );
};

export default Big5EditableBars;
