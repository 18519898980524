import React from "react";
import { useTheme } from "@mui/styles";
import { AvatarGroup, Box } from "@mui/material";
import StringAvatar from "../common/StringAvatar";

const AvatarGroupFolderComponent = ({
  color,
  list,
  size = 30,
  max = 3,
  closer = false,
}: any) => {
  const theme: any = useTheme();
  return (
    <Box display="flex" alignItems="left">
      <AvatarGroup
        max={max}
        sx={{
          "& .MuiAvatarGroup-avatar": {
            width: size,
            height: size,
            fontSize: ".9rem",
            zIndex: 0,
            marginLeft: closer ? "-22px" : "-10px",
          },
          "& .MuiAvatar-colorDefault": {
            border: closer
              ? "none"
              : `1px solid ${theme.palette[color][500]} !important`,
            backgroundColor: "white",
            color: theme.palette[color][500],
            fontSize: closer ? "15px" : "11px",
            justifyContent: closer ? "flex-end" : "center",
          },
        }}
      >
        {list?.map((people: any, i: number) => {
          let beCloser = closer && i !== 0 ? { marginLeft: -22 + "px" } : {};
          return (
            <StringAvatar
              sx={beCloser}
              key={people?.id}
              size={size}
              name={people?.person?.firstName + " " + people?.person?.lastName}
              url={people?.person?.image?.contentUrl || people?.person?.image}
              index={i}
            />
          );
        })}
      </AvatarGroup>
    </Box>
  );
};

export default AvatarGroupFolderComponent;
