import { useTheme } from "@mui/styles";
import React from "react";

const TestIcon: React.FC<{
  active?: boolean;
  context?: string;
  color?: string;
  size?: number;
}> = ({ active, context, size, color }) => {
  const theme: any = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? "20"}
      height={size ?? "20"}
      viewBox="0 0 13.477 17"
    >
      <g id="_003-test" data-name="003-test" transform="translate(-53.056)">
        <g
          id="Groupe_3069"
          data-name="Groupe 3069"
          transform="translate(53.056)"
        >
          <g id="Groupe_3068" data-name="Groupe 3068">
            <path
              id="Tracé_12543"
              data-name="Tracé 12543"
              d="M282.679,373H280.72a.664.664,0,1,0,0,1.328h1.959a.664.664,0,0,0,0-1.328Z"
              transform="translate(-272.519 -360.615)"
              fill={
                color
                  ? color
                  : !active &&
                    (context === "collab" ||
                      context === "benef" ||
                      context === "candidate")
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
            <path
              id="Tracé_12544"
              data-name="Tracé 12544"
              d="M282.679,252H280.72a.664.664,0,1,0,0,1.328h1.959a.664.664,0,0,0,0-1.328Z"
              transform="translate(-272.519 -243.633)"
              fill={
                color
                  ? color
                  : !active &&
                    (context === "collab" ||
                      context === "benef" ||
                      context === "candidate")
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
            <path
              id="Tracé_12545"
              data-name="Tracé 12545"
              d="M65.869,11.688a.664.664,0,0,0,.664-.664V2.656A2.659,2.659,0,0,0,63.877,0H55.712a2.659,2.659,0,0,0-2.656,2.656V14.344A2.659,2.659,0,0,0,55.712,17h8.164a2.659,2.659,0,0,0,2.656-2.656.664.664,0,1,0-1.328,0,1.33,1.33,0,0,1-1.328,1.328H55.712a1.33,1.33,0,0,1-1.328-1.328V2.656a1.33,1.33,0,0,1,1.328-1.328h8.165a1.33,1.33,0,0,1,1.328,1.328v8.367A.664.664,0,0,0,65.869,11.688Z"
              transform="translate(-53.056)"
              fill={
                color
                  ? color
                  : !active &&
                    (context === "collab" ||
                      context === "benef" ||
                      context === "candidate")
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
            <path
              id="Tracé_12546"
              data-name="Tracé 12546"
              d="M137.131,75.152a.664.664,0,0,0-.935.089l-1.435,1.736L134.2,76.4a.664.664,0,0,0-.952.927l.712.732.018.018a1.16,1.16,0,0,0,.8.319l.076,0a1.16,1.16,0,0,0,.821-.425l1.55-1.876A.664.664,0,0,0,137.131,75.152Z"
              transform="translate(-130.4 -72.51)"
              fill={
                color
                  ? color
                  : !active &&
                    (context === "collab" ||
                      context === "benef" ||
                      context === "candidate")
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
            <path
              id="Tracé_12547"
              data-name="Tracé 12547"
              d="M137.132,319.029a.664.664,0,0,0-.935.089l-1.435,1.737-.567-.579a.664.664,0,0,0-.949.929l.712.727.017.016a1.16,1.16,0,0,0,.8.319l.076,0a1.16,1.16,0,0,0,.821-.426l1.55-1.876A.664.664,0,0,0,137.132,319.029Z"
              transform="translate(-130.4 -308.289)"
              fill={
                color
                  ? color
                  : !active &&
                    (context === "collab" ||
                      context === "benef" ||
                      context === "candidate")
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
            <path
              id="Tracé_12548"
              data-name="Tracé 12548"
              d="M282.679,129H280.72a.664.664,0,1,0,0,1.328h1.959a.664.664,0,0,0,0-1.328Z"
              transform="translate(-272.519 -124.717)"
              fill={
                color
                  ? color
                  : !active &&
                    (context === "collab" ||
                      context === "benef" ||
                      context === "candidate")
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
            <path
              id="Tracé_12549"
              data-name="Tracé 12549"
              d="M137.132,198.028a.664.664,0,0,0-.935.089l-1.435,1.737-.567-.579a.664.664,0,0,0-.949.929l.712.727.017.016a1.16,1.16,0,0,0,.8.319l.076,0a1.16,1.16,0,0,0,.821-.425l1.55-1.876A.664.664,0,0,0,137.132,198.028Z"
              transform="translate(-130.4 -191.306)"
              fill={
                color
                  ? color
                  : !active &&
                    (context === "collab" ||
                      context === "benef" ||
                      context === "candidate")
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TestIcon;
