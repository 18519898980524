import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@mui/icons-material";
import StringAvatar from "./StringAvatar";

const CustomSelect: any = styled(Select, {
  shouldForwardProp: (props) =>
    props !== "noRadiusLeft" &&
    props !== "noRadiusRight" &&
    props !== "standard" &&
    props !== "textColor" &&
    props !== "noBorder",
})(
  ({
    theme,
    noRadiusLeft,
    noRadiusRight,
    standard,
    textColor,
    noBorder,
  }: any) => ({
    borderTopRightRadius: noRadiusRight ? "0px !important" : "auto",
    borderBottomRightRadius: noRadiusRight ? "0px !important" : "auto",
    borderTopLeftRadius: noRadiusLeft ? "0px !important" : "auto",
    borderBottomLeftRadius: noRadiusLeft ? "0px !important" : "auto",
    "& *": {
      color: textColor ? textColor : theme.palette.secondary.main,
      border: noBorder ? "0px !important" : "",
      "& .MuiBox-root": {
        display: "none",
      },
    },
    "& .MuiInput-root:before": {
      border: standard ? "none" : "auto",
    },
    "& .MuiInput-root.Mui-focused:after": {
      border: standard ? "none" : "auto",
    },
    "& .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
      background: standard ? "none" : "auto",
    },
  })
);

const CustomFormControl: any = styled(FormControl, {
  shouldForwardProp: (props) => props !== "standard",
})(({ standard }: any) =>
  standard
    ? {
        "& .MuiInput-root:before": {
          border: "none",
        },
        "& .MuiInput-root.Mui-focused:after": {
          border: "none",
        },
        "& .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
          background: "none",
        },
      }
    : {}
);

const SelectComponent: React.FC<{
  label?: string;
  items: any;
  preSelected?: any;
  icon?: any;
  variant?: "filled" | "standard" | "outlined" | undefined;
  onChange?: any;
  noRadiusRight?: boolean;
  noRadiusLeft?: boolean;
  parentLoading?: boolean;
  disabled?: boolean;
  fontWeight?: string;
  textColor?: string;
  width?: string;
  noBorder?: boolean;
  sx?: Object;
  clearOnSelect?: boolean;
}> = ({
  label,
  items,
  preSelected,
  icon,
  variant,
  onChange,
  noRadiusRight,
  noRadiusLeft,
  parentLoading,
  disabled,
  fontWeight,
  textColor,
  width,
  noBorder,
  sx,
  clearOnSelect,
}) => {
  const { t } = useTranslation("common");
  const [value, setValue] = useState(preSelected || "");
  const handleChange = (e: any, newValue: any) => {
    !clearOnSelect && setValue(newValue.props.value);
    onChange(
      clearOnSelect
        ? items[items.findIndex((el: any) => el.id === newValue.props.value)]
        : newValue.props.value
    );
  };

  useEffect(() => {
    setValue(preSelected ?? "");
  }, [preSelected]);

  return (
    <CustomFormControl
      variant={variant}
      fullWidth
      sx={{
        width: `${width ?? "100%"}`,
        color: "white !important",
      }}
      disabled={parentLoading || disabled}
      standard={variant === "standard"}
    >
      <CustomSelect
        noRadiusRight={noRadiusRight}
        noRadiusLeft={noRadiusLeft}
        standard={variant === "standard"}
        textColor={textColor}
        noBorder={noBorder}
        id="demo-simple-select-outlined"
        onChange={handleChange}
        displayEmpty
        value={value}
        IconComponent={ExpandMore}
        sx={{
          fontWeight: fontWeight,
          // color: "inherit",
          ...sx,
        }}
        inputProps={{
          "aria-label": "Without label",
        }}
        startAdornment={
          value && items?.[0]?.["@type"] === "WorkCategory" ? (
            <Box sx={{ mr: 1 }}>
              <img
                src={`/static/icons/work_cat/${
                  items?.filter((el: any) => el?.id === value)?.[0]?.code
                }.png`}
                width="30"
                alt="icon"
              />
            </Box>
          ) : (
            icon && <InputAdornment position="start">{icon}</InputAdornment>
          )
        }
      >
        {label && (
          <MenuItem value="">
            <span>{label}</span>
          </MenuItem>
        )}
        {items?.map((item: any) => (
          <MenuItem
            key={item.value ? item.label : item.id}
            value={item.value ? item.value : item.id}
          >
            {item?.["@type"] === "WorkCategory" && (
              <Box sx={{ mr: 1 }}>
                <img
                  src={`/static/icons/work_cat/${item.code}.png`}
                  width="20"
                  style={{ minWidth: "20px" }}
                  alt="icon"
                />
              </Box>
            )}
            {item?.firstName && (
              <Box sx={{ mr: 1 }}>
                <StringAvatar
                  size={30}
                  name={`${item.firstName} ${item.lastName}`}
                  url={item?.person?.image?.contentUrl}
                />
              </Box>
            )}
            {item?.label
              ? t(item.label)
              : `${item?.firstName} ${item?.lastName}`}
          </MenuItem>
        ))}
      </CustomSelect>
    </CustomFormControl>
  );
};

export default SelectComponent;
