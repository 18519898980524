import { Button, Container, Paper, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getNumericDate } from "../../../_utils/getNumericDate";

const Root = styled(Paper)(({ theme }: any) => ({
  mt: 2,
  borderRadius: "23px",
  boxShadow: theme.customShadows.z8,
}));

const DeanonymizationButton: React.FC<any> = ({ person }) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(person.anonymizationDate);

  const handleClick = async () => {
    try {
      setLoading(true);
      const api = `profiles/${person.profileId}/request`;
      await axios.patch(api, {});
      setDate({
        key: "request",
        value: getNumericDate(new Date()),
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Root>
      <Container sx={{ pt: 2, pb: 2 }}>
        <Typography fontWeight="bold" sx={{ mb: 1 }}>
          {t("third_party.anonymization.action_label")}
        </Typography>
        {date && (
          <Typography
            sx={{ color: (theme) => theme.palette.grey[500], fontSize: "12px" }}
            textAlign="center"
          >
            {t("third_party.anonymization.dates.date_status", {
              context: date.key,
              date: date.value,
            })}
          </Typography>
        )}
        <Button
          onClick={handleClick}
          disabled={loading}
          sx={{
            height: "33px",
            width: "100%",
            fontSize: "12px",
            fontWeight: "normal",
          }}
          variant="contained"
        >
          {t("third_party.anonymization.request_label")}
        </Button>
      </Container>
    </Root>
  );
};

export default DeanonymizationButton;
