import React from "react";
import { Divider, Typography, styled, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getShortDate } from "../../_utils/getShortDate";
import ExperienceLogo from "../experiences/ExperienceLogo";

const Subtitle = styled(Typography, {
  shouldForwardProp: (props) => props !== "smallLocation",
})(({ theme, smallLocation }: any) => ({
  color: theme.palette.grey[500],
  display: smallLocation ? "none" : "auto",
  [theme.breakpoints.down("sm")]: {
    display: smallLocation ? "inherit" : "auto",
  },
}));

const SpanLocation = styled("span")(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const Infos = styled("div")`
  display: flex;
`;

const SuccessionItemMore: React.FC<any> = ({ infos }) => {
  console.log(infos);
  const { t } = useTranslation("common");

  return (
    <div>
      <Divider sx={{ mt: 2 }} />
      <Stack spacing={2} sx={{ mt: 2 }}>
        {infos.careerItems
          .filter((e: any) => e.type === "experience")
          .map((experience: any) => (
            <Infos key={experience["@id"]}>
              <ExperienceLogo experience={experience} />

              <div>
                <Typography>{experience.labelWork}</Typography>

                <Subtitle variant="body2">
                  {`${getShortDate(new Date(experience.dateStart))} - ${
                    experience.dateEnd
                      ? getShortDate(new Date(experience.dateEnd))
                      : t("today")
                  }
        `}
                  {experience.location && experience.location?.label && (
                    <SpanLocation>{`- ${
                      experience.location && experience.location?.label
                    }`}</SpanLocation>
                  )}
                </Subtitle>
              </div>
            </Infos>
          ))}
      </Stack>
    </div>
  );
};

export default SuccessionItemMore;
