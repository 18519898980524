import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import WorkTrainingOrder from "./WorkTrainingOrder";
import WorkTrainingFiltersPopover from "./WorkTrainingFiltersPopover";
import WorkTrainingItem from "./WorkTrainingItem";
import { useTranslation } from "react-i18next";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import WorkTrainingSkeletonList from "../skeletons/collab/WorkTrainingSkeletonList";

const NbrResult = styled(Box)(({ theme }: any) => ({
  height: "31px",
  borderRadius: "9px",
  padding: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.primary[500_8],
}));

const WorkTrainingList: React.FC<{
  httpParams: any;
  handleParams: any;
  workPage?: boolean;
  list?: any;
  total: number;
  loading: boolean;
  firstCall?: boolean;
}> = ({
  httpParams,
  handleParams,
  workPage,
  list,
  total,
  loading,
  firstCall,
}) => {
  const { t } = useTranslation("common");
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        sx={{
          ml: 2,
          mr: 2,
          justifyContent: !workPage ? { xs: "center", md: "auto" } : "center",
        }}
      >
        {workPage && (
          <>
            <Typography
              fontWeight="bold"
              variant="body1"
              sx={{ fontSize: "16px" }}
            >
              {t("trainings.filters_label")}
            </Typography>
            <NbrResult sx={{ ml: 2 }}>
              <Typography color="primary" variant="body1">
                {t("filters.results", { count: total })}
              </Typography>
            </NbrResult>
          </>
        )}
        <Box
          sx={{
            ml: workPage ? { xs: 0, sm: "auto" } : { xs: 0, md: "auto" },
          }}
        >
          {workPage && (
            <WorkTrainingFiltersPopover
              handleFilters={handleParams}
              httpParams={httpParams}
              total={total}
            />
          )}
          {list?.length > 1 && (
            <WorkTrainingOrder
              httpParams={httpParams}
              handleOrder={(order: string) =>
                handleParams({ ...httpParams, page: 1, order: order })
              }
            />
          )}
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ mt: 4 }}>
          <WorkTrainingSkeletonList />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            flexWrap="wrap"
            sx={{ justifyContent: { xs: "center", md: "space-evenly" } }}
          >
            {list?.map((item: any, index: number) => (
              <WorkTrainingItem
                key={`training-${index}`}
                training={item}
                handleBookmark={() =>
                  handleParams({
                    ...httpParams,
                    page: 1,
                    reload: ++httpParams.reload,
                  })
                }
              />
            ))}
            {list?.length === 0 &&
              (!firstCall ||
              (!httpParams?.type && httpParams?.type !== 0) ||
              (httpParams?.type === 0 && !httpParams?.location) ||
              (!workPage ? !httpParams?.needle : false) ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ color: "#909090", opacity: 0.38, mt: "180px" }}
                >
                  <ManageSearchRoundedIcon sx={{ fontSize: "80px" }} />
                  <Typography
                    variant="h3"
                    textAlign="center"
                    fontWeight="normal"
                    sx={{ maxWidth: "30ch" }}
                  >
                    {t("trainings.type_not_selected")}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography
                    variant="h3"
                    textAlign="center"
                    fontWeight="normal"
                    sx={{ mt: 5 }}
                  >
                    {t("autocomplete.no_result")}
                  </Typography>
                </>
              ))}
          </Box>
          {total > httpParams?.page * 10 && list?.length > 0 && (
            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
              <Button
                sx={{ borderRadius: "30px", padding: "12px" }}
                variant="contained"
                color="primary"
                onClick={() =>
                  handleParams({ ...httpParams, page: httpParams?.page + 1 })
                }
              >
                <Typography variant="body2">
                  {t("trainings.see_all")}
                </Typography>
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default WorkTrainingList;
