import { Box, Container, Paper, Typography } from "@mui/material";
import React from "react";
import Big5Restitution from "./Big5Restitution";
import { useTranslation } from "react-i18next";
import PassTest from "../common/PassTest";
import { useSelector } from "react-redux";

const Big5Work: React.FC<{
  workResults: any;
  compare?: boolean;
  noCard?: boolean;
}> = ({ workResults, compare, noCard }) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);

  return (
    <Paper elevation={noCard ? 0 : 3} sx={{ padding: 2, borderRadius: "23px" }}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <img src="/static/illustrations/work/yoga-mask.svg" alt="yogo mask" />
      </Box>

      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              background: "#E4F2FC",
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              width: 36,
              height: 36,
              mr: 1,
            }}
          >
            <img
              style={{ width: "20px" }}
              alt="skills icon"
              src="/static/icons/b5_logo.svg"
            />
          </Box>

          <Typography
            variant="h5"
            sx={{
              mb: 2,
              mt: 2,
              backgroundColor: "#fafdfe",
              padding: "3px",
              borderRadius: "7px",
            }}
          >
            {compare
              ? t(
                  context === "recruiter"
                    ? "recruiter.campaign.matching_b5"
                    : "work_sheet.matching_personality"
                )
              : t("work_sheet.expected_personality")}
          </Typography>
        </Box>
      </Box>

      {context !== "recruiter" && workResults.person && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="body2"
            sx={{
              width: "500px",
              textAlign: "center",
            }}
          >
            {t("work_sheet.text_big5")}
          </Typography>
        </Box>
      )}

      {!workResults.person &&
        compare &&
        (context === "advisor" ||
          context === "collab" ||
          context === "benef") && (
          <Typography textAlign="center" variant="body1" sx={{ mt: 6, mb: 6 }}>
            {t(`work_sheet.no_test${context === "advisor" ? "_advisor" : ""}`)}
          </Typography>
        )}

      {(workResults.person ||
        context === "recruiter" ||
        ((context === "advisor" || context === "rh" || context === "manager") &&
          !compare)) && (
        <Container>
          <Big5Restitution
            compare={workResults.person && compare}
            results={
              workResults.person && compare
                ? workResults.person
                : workResults.work
            }
            compareResult={
              compare && workResults.person ? workResults.work : null
            }
            notCollab={
              context !== "collab" &&
              context !== "candidate" &&
              context !== "benef"
            }
            work
          />
        </Container>
      )}

      {compare &&
        !workResults.person &&
        (context === "collab" || context === "benef") && (
          <PassTest url={"/app/big5"} />
        )}
    </Paper>
  );
};

export default Big5Work;
