import {
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  // Button,
  TextField,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayHome: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");
  return (
    <div>
      <Typography variant="h4">{t("admin.params.home.title")}</Typography>
      <FormGroup>
        <Typography sx={{ mt: 2, mb: 1 }}>
          {t("admin.params.home.add_home")}
        </Typography>
        <FormControlLabel
          value={param.PROFIL}
          onChange={(e: any) =>
            handleChanges({ ...param, PROFIL: e.target.checked })
          }
          control={<Checkbox checked={param.PROFIL} />}
          label={t("admin.params.home.profil")}
        />
        <FormControlLabel
          value={param.TESTS}
          onChange={(e: any) =>
            handleChanges({ ...param, TESTS: e.target.checked })
          }
          control={<Checkbox checked={param.TESTS} />}
          label={t("admin.params.home.tests")}
        />
        <FormControlLabel
          value={param.TOP5}
          onChange={(e: any) =>
            handleChanges({ ...param, TOP5: e.target.checked })
          }
          control={<Checkbox checked={param.TOP5} />}
          label={t("admin.params.home.top5")}
        />
        <FormControlLabel
          value={param.LAST_UPDATE}
          onChange={(e: any) =>
            handleChanges({ ...param, LAST_UPDATE: e.target.checked })
          }
          control={<Checkbox checked={param.LAST_UPDATE} />}
          label={t("admin.params.home.last_update")}
        />
      </FormGroup>
      <Typography sx={{ mt: 2, mb: 1 }}>
        {t("admin.params.home.video")}
      </Typography>
      <TextField
        sx={{ width: { xs: "100%", sm: "80%", md: "60%" } }}
        label={t("admin.params.home.ytb")}
        value={param.VIDEO_PRESENTATION}
        onChange={(event: any) =>
          handleChanges({ ...param, VIDEO_PRESENTATION: event.target.value })
        }
      />
      {/* <Button variant="outlined">Choisir un fichier</Button> */}

      <Typography sx={{ mt: 2 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
    </div>
  );
};

export default AdminDisplayHome;
