export const modalStyle: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "98vh",
  overflow: "auto",
  borderRadius: "6px",
  width: { xs: "95%", sm: "80%", md: "780px" },
};
