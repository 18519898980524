import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../../contexts/ProfileContext";
import beneficiaryStatusesAPI from "../../_api/_beneficiaryStatusesAPI";
import useFetch from "../../hooks/useFetch";
import {
  Box,
  Tooltip,
  Select,
  MenuItem,
  Chip,
  CircularProgress,
} from "@mui/material";
import peopleAPI from "../../_api/_people";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Status = () => {
  const { t } = useTranslation("common");
  const { setPeopleData, peopleData } = useContext(ProfileContext);

  const [status, setStatus] = useState(peopleData?.status);

  const [statusPossible, setStatusPossible] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState(status?.code);

  const apiUrl = `${beneficiaryStatusesAPI.url}/next?person.id=${peopleData?.id}&current=${peopleData?.status?.code}`;

  const { data, loading } = useFetch(apiUrl);

  const handleStatusChange = async (status: any) => {
    try {
      await peopleAPI.patch(`${peopleData.id}/status`, {
        status: status,
      });
      setPeopleData((current: any) => ({
        ...current,
        status: statusPossible.find((item: any) => item.code === status),
      }));
      toast.success(t("profilepage.success_edit_status"));
    } catch (err) {
      toast.error(t("errors.error_append"));
    }
  };

  const handleSelectChange = (event: any) => {
    setSelectedStatus(event.target.value);
    handleStatusChange(event.target.value); // Appeler handleStatusChange directement avec event.target.value
  };

  useEffect(() => {
    const updatedStatus = statusPossible.find(
      (item: any) => item.code === selectedStatus
    );
    if (updatedStatus) {
      setStatus(updatedStatus);
    }
  }, [selectedStatus, statusPossible]);

  useEffect(() => {
    if (data) {
      setStatusPossible(data["hydra:member"]);
      console.log(data["hydra:member"]);
    }
  }, [data, selectedStatus]);

  useEffect(() => {
    if (peopleData?.status) {
      setStatus(peopleData?.status);
      setSelectedStatus(peopleData?.status?.code);
    }
  }, [peopleData]);

  return (
    <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
      {loading ? (
        <Chip
          sx={{
            backgroundColor: status?.color,
            color: "white",
            display: "flex",
            alignItems: "center",
          }}
          label={
            <>
              {status?.status}
              <CircularProgress size={14} sx={{ color: "white", ml: 1 }} />
            </>
          }
        />
      ) : (
        <Tooltip
          title={status?.description}
          placement="top-start"
          // open={tooltipOpen}
        >
          {statusPossible.length > 0 ? (
            <Select
              value={selectedStatus}
              onChange={handleSelectChange}
              autoWidth
              sx={{
                height: 30,
                backgroundColor: status?.color,
                color: "white",
                padding: 0,
                borderRadius: 2,
                border: 0,
                "&:hover": {
                  border: 0, // Supprimer les bordures lors de l'état hover
                  outline: "none", // Supprimer l'outline par défaut
                },
                "&:focus": {
                  border: 0, // Supprimer les bordures lors de l'état focus
                  outline: "none", // Supprimer l'outline par défaut
                },
                "&:focus-visible": {
                  border: 0, // Supprimer les bordures lors de l'état focus-visible
                  outline: "none", // Supprimer l'outline par défaut
                },
              }}
            >
              <MenuItem
                sx={{ display: "none" }}
                key={status?.code}
                value={status?.code}
              >
                {status?.status}
              </MenuItem>

              {statusPossible?.map((item: any) => (
                // <Tooltip title={item?.description} placement="top-start">
                <MenuItem key={item.code} value={item.code}>
                  {item.status}
                </MenuItem>
                // </Tooltip>
              ))}
            </Select>
          ) : (
            <Chip
              sx={{ backgroundColor: status?.color, color: "white" }}
              label={status?.status}
            />
          )}
        </Tooltip>
      )}
    </Box>
  );
};

export default Status;
