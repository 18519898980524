import React, { useEffect } from "react";
import LogoLoader from "./LogoLoader";
import { styled } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ResultsAPI from "../_api/_resultsAPI";

const Root = styled("div")`
  height: calc(100vh - 20px);
`;

const parse = (value: any) => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
};

const RedirectPlatform = () => {
  const isLogged = useSelector((state: any) => state.auth.isLogged);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const params: any = {};

    searchParams.forEach((value, key) => {
      params[key] = parse(value);
    });

    if (isLogged) {
      postResults(params.data, params.test);
    } else {
      sessionStorage.setItem("redirectTest", params.test);
      sessionStorage.setItem("redirectTestData", JSON.stringify(params.data));
      navigate("/sign-up");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const postResults = async (data: any, test: string) => {
    try {
      const body = {
        duration: 0,
        raw: data,
        test: `/api/tests/${test}`,
      };

      await ResultsAPI.post(body);

      navigate(`/app/${test}`);
    } catch (err) {
      navigate("/app/home");
    }
  };

  return (
    <Root>
      <LogoLoader open={true} noMargin />
    </Root>
  );
};

export default RedirectPlatform;
