import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const PasswordInput: React.FC<{
  onChange: (e: any) => any;
  value: string;
  label: string;
  helperText?: string | any;
  name: string;
  error?: boolean;
  onBlur?: (e: any) => void;
  handleKeyDown?: () => void;
  showRules?: boolean;
  autoFocus?: boolean;
}> = ({
  onChange,
  value,
  label,
  helperText,
  name,
  error,
  onBlur,
  handleKeyDown,
  showRules,
  autoFocus,
}) => {
  const { t } = useTranslation("common");
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl variant="outlined" sx={{ width: "100%" }}>
      <InputLabel
        error={error}
        htmlFor={`outlined-adornment-${name ? name + "-" : ""}password`}
        sx={{ mb: 1 }}
      >
        {t(label)}
      </InputLabel>
      <OutlinedInput
        value={value}
        onBlur={onBlur}
        name={name}
        autoFocus={autoFocus}
        sx={{ mb: 1 }}
        error={error}
        id={`outlined-adornment-${name ? name + "-" : ""}password`}
        type={showPassword ? "text" : "password"}
        onChange={onChange}
        onKeyDown={(e: any) => e.keyCode === 13 && handleKeyDown?.()}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event: any) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={t(label)}
      />
      <FormHelperText
        sx={{ marginTop: "-5px", ml: 3 }}
        error={error}
        id={`my-helper-text-${name ? name + "-" : ""}password`}
      >
        {t(helperText || "")}
      </FormHelperText>
      {showRules && <FormHelperText>{t("pwd_rules")}</FormHelperText>}
    </FormControl>
  );
};

export default PasswordInput;
