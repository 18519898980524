import { Box, Typography, IconButton } from "@mui/material";
import React, { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import WorkSkillsModal from "../works/WorkSkillsModal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import RatingItem from "./RatingItem";

const SkillsCategoryGroup: React.FC<{
  category: any;
  compare?: boolean;
  type?: string;
  typeKey?: string;
  handleSubmitSkill?: any;
  profileSection?: boolean;
  flex?: boolean;
}> = ({
  category,
  compare,
  type,
  typeKey,
  handleSubmitSkill,
  profileSection,
  flex,
}) => {
  const context = useSelector((state: any) => state.auth.context);
  const location = useLocation();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box
      sx={{
        mb: 4,
        alignItems:
          location.pathname.includes("work") ||
          location.pathname.includes("campaign")
            ? "center"
            : "start",
        width:
          location.pathname.includes("work") ||
          location.pathname.includes("campaign")
            ? "fit-content"
            : "auto",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          mb: 2,
          width: "fit-content",
          alignSelf: "start",
          ml: compare && context === "collab" ? 5.75 : 0,
          backgroundColor: "rgba(180, 195, 255, 0.24)",
          borderRadius: "6px",
        }}
      >
        <Typography
          sx={{
            width: "fit-content",
            p: "4px 8px",
            mr: "auto",
            borderRadius: "3px",
            textAlign: "center",
          }}
          color="primary"
          variant="subtitle1"
        >
          {category.label.toUpperCase()}
        </Typography>
        {(context === "collab" || context === "benef") &&
          compare &&
          typeKey !== "acquired" && (
            <IconButton
              aria-label="add"
              sx={{ mr: 0.5, width: "40px", height: "40px" }}
              onClick={() => setOpen(true)}
            >
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
          )}
      </Box>
      <Box
        sx={{
          width: {
            xs: "250px",
            sm: flex && !profileSection ? "400px" : "auto",
            md: flex && !profileSection ? "800px" : "auto",
          },
          display: "flex",
          flexDirection: flex ? "row" : "column",
          flexWrap: flex ? "wrap" : "inherit",
          gap: 2,
        }}
      >
        {category.skillsList.map((skill: any, i: number) => (
          <Box key={skill.id ?? skill["@id"]}>
            <RatingItem
              compare={compare}
              identifier={skill.id ?? skill["@id"]}
              skill={skill}
              type={type}
              handleSubmitSkill={handleSubmitSkill}
              profileSection={profileSection}
            />
          </Box>
        ))}
      </Box>
      <WorkSkillsModal
        open={open}
        close={() => setOpen(false)}
        skillsList={category.skillsList}
        type={type}
        handleSubmit={handleSubmitSkill}
      />
    </Box>
  );
};

export default SkillsCategoryGroup;
