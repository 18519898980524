import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";

import CustomDrawer from "./../layout/CustomDrawer";
import { styled } from "@mui/styles";
import { Outlet } from "react-router-dom";
import TopBarContainer from "./topBar/TopBarContainer";
import { useSelector } from "react-redux";

const Root = styled("div")({ display: "flex", height: "100%" });
const Content = styled("main")({
  width: "100%",
  backgroundColor: "white",
});

function FullLayout(): any {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const isLogged = useSelector((state: any) => state.auth.isLogged);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <Root>
        <CssBaseline />

        {isLogged && (
          <CustomDrawer
            container={container}
            theme={theme}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            closeDrawer={closeDrawer}
          />
        )}
        <Content>
          <div />
          <TopBarContainer
            isLogged={isLogged}
            handleDrawerToggle={handleDrawerToggle}
          />
          <Outlet />
        </Content>
      </Root>
    </>
  );
}

export default FullLayout;
