import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../forms/ControlledTextField";
import CustomButtonModal from "../CustomBtnModal";
import GenderSelect from "../GenderSelect";
import PhoneField from "../PhoneField";
import ControlledEmailInput from "../../forms/ControlledEmailInput";

interface IFormInput {
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  phone: string;
  roles: any;
}

const InviteCandidateForm: React.FC<{
  form: any;
  close: () => void;
  submit: (e: any) => void;
  context: string;
  showAlertRole?: boolean;
  loading?: boolean;
  showRoles?: boolean;
}> = ({ form, close, submit, context, showAlertRole, loading, showRoles }) => {
  const { t } = useTranslation("common");

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: form,
  });

  const [roles, setRoles] = useState(getValues("roles"));

  const handleRoles = (value: any) => {
    setRoles(value);
    setValue("roles", value);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={2}>
        <Controller
          name="gender"
          control={control}
          rules={{
            required: { value: true, message: "errors.required" },
          }}
          render={({ field: { value, onChange } }) => (
            <GenderSelect gender={value} setGender={onChange} littleMargin />
          )}
        />
        <Typography
          variant="caption"
          color="error"
          sx={{ mt: -2, ml: 2, mb: 2 }}
        >
          {errors?.["gender"] && !getValues("gender") && t("errors.required")}
        </Typography>
        <ControlledTextField
          name="firstName"
          label={t("form.firstname") + "*"}
          control={control}
          required
        />
        <ControlledTextField
          name="lastName"
          label={t("form.lastname") + "*"}
          control={control}
          required
        />
        {!showRoles && (
          <Box sx={{ mb: 2 }}>
            <Controller
              name="phone"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <PhoneField
                  phoneNumber={value}
                  setPhoneNumber={onChange}
                  error={errors?.["phone"]}
                  label={t(
                    `form.phone${
                      form?.expectedData?.gamified ? "_required" : ""
                    }`
                  )}
                />
              )}
            />
          </Box>
        )}
        <ControlledEmailInput name="email" control={control} required />
        {showRoles && (
          <FormGroup sx={{ mt: 1 }}>
            <Typography variant="body1">{t("admin.users.roles")}:</Typography>
            {context === "recruiter" ? (
              <>
                <FormControlLabel
                  disabled
                  value={roles.recruiter}
                  onChange={(e: any) =>
                    handleRoles({ ...roles, recruiter: !roles.recruiter })
                  }
                  control={<Checkbox checked={roles.recruiter} disabled />}
                  label={t("roles.recruiter")}
                />
                <FormControlLabel
                  value={roles.adminRecruiter}
                  onChange={(e: any) =>
                    handleRoles({
                      ...roles,
                      adminRecruiter: !roles.adminRecruiter,
                    })
                  }
                  control={<Checkbox checked={roles.adminRecruiter} />}
                  label={t("roles.adminrecruiter")}
                />
                <FormControlLabel
                  value={roles.cre}
                  onChange={(e: any) =>
                    handleRoles({ ...roles, cre: !roles.cre })
                  }
                  control={<Checkbox checked={roles.cre} />}
                  label={t("roles.cre")}
                />
              </>
            ) : context === "advisor" ? (
              <>
                <FormControlLabel
                  disabled
                  value={roles.advisor}
                  onChange={(e: any) =>
                    handleRoles({ ...roles, advisor: !roles.advisor })
                  }
                  control={<Checkbox checked={roles.advisor} disabled />}
                  label={t("roles.advisor")}
                />
                <FormControlLabel
                  value={roles.adminAdvisor}
                  onChange={(e: any) =>
                    handleRoles({ ...roles, adminAdvisor: !roles.adminAdvisor })
                  }
                  control={<Checkbox checked={roles.adminAdvisor} />}
                  label={t("roles.adminadvisor")}
                />
              </>
            ) : (
              <>
                <FormControlLabel
                  value={roles.rh}
                  onChange={(e: any) =>
                    handleRoles({ ...roles, rh: !roles.rh })
                  }
                  control={<Checkbox checked={roles.rh} />}
                  label={t("roles.rh")}
                />
                <FormControlLabel
                  value={roles.manager}
                  onChange={(e: any) =>
                    handleRoles({ ...roles, manager: !roles.manager })
                  }
                  control={<Checkbox checked={roles.manager} />}
                  label={t("roles.manager")}
                />
                <FormControlLabel
                  value={roles.admin}
                  onChange={(e: any) =>
                    handleRoles({ ...roles, admin: !roles.admin })
                  }
                  control={<Checkbox checked={roles.admin} />}
                  label={t("roles.admin")}
                />
                <FormControlLabel
                  value={roles.adminRh}
                  onChange={(e: any) =>
                    handleRoles({ ...roles, adminRh: !roles.adminRh })
                  }
                  control={<Checkbox checked={roles.adminRh} />}
                  label={t("roles.adminrh")}
                />
                <FormControlLabel
                  value={roles.cre}
                  onChange={(e: any) =>
                    handleRoles({ ...roles, cre: !roles.cre })
                  }
                  control={<Checkbox checked={roles.cre} />}
                  label={t("roles.cre")}
                />
              </>
            )}
            {showAlertRole && (
              <Typography variant="body2" color="error">
                {t("admin.users.invite_form.min_role")}
              </Typography>
            )}
          </FormGroup>
        )}
      </Stack>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="center"
        sx={{ mt: 2 }}
      >
        <CustomButtonModal
          type="submit"
          variant="contained"
          text={t("btn.invite")}
          disabled={loading}
        />
        <CustomButtonModal
          secondary
          onClick={close}
          variant="outlined"
          color="secondary"
          text={t(`btn.cancel`)}
        />
      </Box>
    </form>
  );
};

export default InviteCandidateForm;
