import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminShares: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">{t("admin.params.shares.title")}</Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {t("admin.params.shares.text")}
      </Typography>
      <FormGroup>
        <FormControlLabel
          value={param.MANAGER}
          onChange={(e: any) =>
            handleChanges({ ...param, MANAGER: e.target.checked })
          }
          control={<Checkbox checked={param.MANAGER} />}
          label={t("admin.params.shares.manager")}
        />
        <FormControlLabel
          value={param.RH}
          onChange={(e: any) =>
            handleChanges({ ...param, RH: e.target.checked })
          }
          control={<Checkbox checked={param.RH} />}
          label={t("admin.params.shares.rh")}
        />
      </FormGroup>

      <Typography textAlign="right">{t("admin.params.reco_text")}</Typography>
    </div>
  );
};

export default AdminShares;
