import React, { useState, useContext, useEffect, useMemo } from "react";
import Page from "../components/Page";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AdminParamsContext } from "../contexts/AdminParamsContext";
import { CardContent, Container, Paper } from "@mui/material";
import AdminAdvisorHome from "../components/admin/advisor/AdminAdvisorHome";
import AdminAdvisorParams from "../components/admin/advisor/AdminAdvisorParams";
import AdminTabAnchor from "../components/_admin/AdminTabAnchor";
import AdminDisplayCommon from "../components/admin/AdminDisplayCommon";
import { useSelector } from "react-redux";
import AdminAdvisorBenefs from "../components/admin/advisor/AdminAdvisorBenefs";
import ChoiceModal from "../components/common/ChoiceModal";
import { isDeepEqual } from "../Utils";

const AdminAdvisorParamsPage = () => {
  const { t } = useTranslation("common");
  const { tab } = useParams();
  const context = useSelector((state: any) => state.auth.context);
  const [current, setCurrent] = useState(
    tab === "home" ? 0 : tab === "common" ? 1 : tab === context ? 2 : 0
  );
  const [pendingNavigation, setPendingNavigation] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [changeNavigate, setChangeNavigate] = useState<boolean>(false);

  const navigate = useNavigate();

  const { params, resetParams, oldParams } = useContext(AdminParamsContext);

  useEffect(() => {
    if (oldParams && params) {
      setHasChanged(!isDeepEqual(oldParams, params));
    }
  }, [oldParams, params]);

  useEffect(() => {
    if (changeNavigate && hasChanged) {
      setOpenModal(true);
    }
  }, [changeNavigate, hasChanged]);

  // useEffect(() => {
  //   const handlePopState = (event: any) => {
  //     if (
  //       hasChanged
  //       // && !canGoBack
  //     ) {
  //       event.preventDefault();
  //       setChangeNavigate(true);
  //       setOpenModal(true);
  //       window.history.pushState(null, "", window.location.pathname);
  //     }
  //   };

  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [
  //   hasChanged,
  //   // , canGoBack
  // ]);

  // const handleGoBack = () => {
  //   window.history.back();
  //   setCanGoBack(true);
  //   setOpenModal(false);
  //   setChangeNavigate(false);
  //   setParams(oldParams);
  // };

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (hasChanged) {
        event.preventDefault();
        // setChangeNavigate(true);

        event.returnValue = t("admin.modal.edit-subtitle");
        return t("admin.modal.edit-subtitle");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanged, t]);

  const performNavigation = (i: number) => {
    navigate(
      `/app/params/${
        i === 0
          ? context === "admin"
            ? "general"
            : "home"
          : i === 1
          ? context === "admin"
            ? "display"
            : "general"
          : i === 2
          ? context === "admin"
            ? "notif"
            : "display"
          : i === 3
          ? "works"
          : i === 4 && context
      }`,
      { replace: true }
    );
    setCurrent(i);
  };

  const handleClick = (i: number) => {
    if (hasChanged) {
      setPendingNavigation(i);
      setOpenModal(true);
    } else {
      performNavigation(i);
    }
  };

  const confirmNavigation = () => {
    if (pendingNavigation !== null) {
      performNavigation(pendingNavigation);
      setPendingNavigation(null);
      setOpenModal(false);
      setChangeNavigate(false);
      resetParams();
    }
  };
  const components: any = useMemo(
    () =>
      params
        ? [
            <AdminAdvisorHome />,
            <AdminDisplayCommon />,
            <AdminAdvisorParams />,
            <AdminAdvisorBenefs setHasChanged={setHasChanged} />,
          ]
        : null,
    [params]
  );

  return (
    <Page
      title={t("admin.title")}
      sx={{ pt: 12, pb: 5, width: { md: "calc(100vw - 270px)" } }}
    >
      <AdminTabAnchor
        current={current}
        setCurrent={setCurrent}
        handleClick={handleClick}
      />
      <Container>
        <Paper
          elevation={3}
          sx={{
            ml: { xs: 0, md: "155px" },
            width: "auto",
            borderRadius: "23px",
          }}
        >
          <CardContent>
            <Paper>{params && components?.[current]}</Paper>
          </CardContent>
        </Paper>
      </Container>
      <ChoiceModal
        open={openModal}
        close={() => setOpenModal(false)}
        title={t("admin.modal.edit-title")}
        subtitle={t("admin.modal.edit-subtitle")}
        color="primary"
        onClick={
          // changeNavigate ? handleGoBack :
          confirmNavigation
        }
      />
    </Page>
  );
};

export default AdminAdvisorParamsPage;
