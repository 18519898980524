import {
  Box,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LongText from "../LongText";
import MatchingProgressBar from "../common/MatchingProgressBar";
import { WorkType } from "../../@types/workType";
import { WorkCategoryType } from "../../@types/workCategoryType";
import BookmarkComponent from "../common/BookmarkComponent";
import StringAvatar from "../common/StringAvatar";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";
import Tag from "../common/Tag";
import BlackListComponent from "../common/BlackListComponent";
import { useSelector } from "react-redux";
import WorkItemTension from "../works/WorkItemTension";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import WorkSuggestMood from "./WorkSuggestMood";

const RoundScore = styled(Box)({
  minWidth: "62px",
  minHeight: "62px",
  background:
    "linear-gradient(145deg, rgb(0 84 165) 0%, rgb(149 180 213) 100%)",
  maxWidth: "62px",
  maxHeight: "62px",
  borderRadius: "50%",
  position: "relative",
  color: "white",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const BookmarkBox = styled(Card)(({ theme }: any) => ({
  transition: "opacity 0.5s ease",
}));

const WorkItem = styled(Card)(({ theme }: any) => ({
  transition: "all 0.25s ease-in-out",
  "&:hover": {
    transform: "scale(1.015)",
    boxShadow: theme.customShadows.z20,
  },
}));

const isValidColor = (color: string) => {
  return color && color.startsWith("#");
};

const WorkSuggestItem: React.FC<{
  reload?: () => void;
  work?: (WorkCategoryType & WorkType) | any;
  index: number;
  page: number;
  onNextCategory?: any;
  updateBookmark?: any;
  benef?: any;
  handleRedirectMatching?: any;
  noBookmark?: boolean;
  onTitleClick?: (e: any) => void;
}> = ({
  reload,
  work,
  index,
  page,
  onNextCategory,
  updateBookmark,
  benef,
  handleRedirectMatching,
  noBookmark,
  onTitleClick,
}) => {
  const [workInfo, setWorkInfo] = useState(work);
  const { t }: any = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const workSearch = useSelector(
    (state: any) => state?.home?.paramjson?.workSearch
  );

  const parameters = useSelector(
    (state: any) =>
      state.parameters.find((el: any) => el.type === "GENERAL_PARAMS")
        ?.parameters?.TESTS
  );

  const handleBookmark = (isBookmarked: boolean) => {
    const newObj: any = { ...workInfo };
    newObj.isBookmarked = isBookmarked;
    setWorkInfo(newObj);
    updateBookmark();
  };

  const handleBlackList = () => {
    reload && reload();
  };

  return (
    <WorkItem
      onClick={() =>
        onTitleClick
          ? onTitleClick(workInfo)
          : benef
          ? handleRedirectMatching(benef?.candidate?.id)
          : onNextCategory(workInfo)
      }
      sx={{
        mb: 2,
        cursor: "pointer",
        mt: index === 0 ? 2 : 0,
        "&:hover": {
          "& .bookmark": {
            opacity: 1,
          },
        },
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box
            display="flex"
            alignItems="center"
            sx={{ width: { sm: "115px", md: "15%", lg: "12%" } }}
          >
            <Typography variant="h4">{index + 1 + (page - 1) * 10}.</Typography>
            <RoundScore
              marginLeft={index + 1 + (page - 1) * 10 < 10 ? 3 : 2}
              sx={{
                opacity:
                  (benef ? benef?.scores?.global : workInfo?.scores?.global) <
                  20
                    ? 0.4
                    : (benef
                        ? benef?.scores?.global
                        : workInfo?.scores?.global) / 50,
              }}
            >
              <Typography variant="h5">
                {benef
                  ? benef?.scores?.global
                  : workInfo?.scores?.global
                  ? workInfo?.scores?.global
                  : 0}
                %
              </Typography>
            </RoundScore>
          </Box>
          <Box
            sx={{ width: { xs: "100%", sm: "52.5%", md: "60%", lg: "55%" } }}
          >
            {benef ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <StringAvatar
                  size={35}
                  name={`${benef?.candidate?.firstName} ${benef?.candidate?.lastName}`}
                  url={benef?.candidate?.image?.contentUrl}
                />
                <Typography variant="h5" sx={{ ml: 1 }}>
                  {benef?.candidate?.firstName} {benef?.candidate?.lastName}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" flexWrap="wrap">
                {workInfo?.type === "category" && work?.code?.length === 5 && (
                  <Box sx={{ mr: 1 }}>
                    <img
                      src={`/static/icons/work_cat/${workInfo?.code}.png`}
                      width="24"
                      alt="icon"
                    />
                  </Box>
                )}
                <Box>
                  <LongText
                    maxChar={45}
                    text={workInfo?.label}
                    variant="body1"
                    fontWeight="bold"
                  />
                </Box>
                {workInfo?.category?.label && (
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      ml: { md: 1 },
                      opacity: "0.7",
                    }}
                  >
                    <Typography sx={{ mr: 1 }}>|</Typography>
                    <LongText
                      maxChar={25}
                      text={workInfo?.category?.label}
                      variant="body1"
                    />
                  </Box>
                )}
                {(context === "benef" || context === "collab") &&
                  work?.type !== "category" && (
                    <BookmarkBox
                      onClick={(e: any) => e?.stopPropagation()}
                      className="bookmark"
                      sx={{ opacity: { xs: 1, sm: 0 }, ml: 1 }}
                    >
                      <BlackListComponent
                        id={
                          workInfo?.["@id"] ??
                          `/api/work_categories/${workInfo?.id}`
                        }
                        isBlackListed={workInfo?.isBlacklist}
                        reload={handleBlackList}
                      />
                    </BookmarkBox>
                  )}
              </Box>
            )}
            <Box display="flex" alignItems="center">
              {work?.studyLevel && (
                <Tag
                  title={workInfo?.studyLevel?.label}
                  // image="/static/icons/graduation-hat.svg"
                  icon={<SchoolOutlinedIcon sx={{ fontSize: "20px" }} />}
                />
              )}
              {workInfo?.workVideos?.length > 0 && !workSearch?.hideVideo && (
                <Tooltip title={t("work_sheet.video_available")}>
                  <SmartDisplayOutlinedIcon
                    color="primary"
                    sx={{
                      fontSize: "22px",
                      mt: 0.5,
                      ml: 0.5,
                    }}
                  />
                </Tooltip>
              )}
              {work?.tags?.length > 0 ? (
                <Box
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  sx={{ ml: 1 }}
                >
                  {work?.tags?.map((tag: any) => (
                    <Chip
                      key={tag.tag.label}
                      label={tag.tag.label}
                      sx={{
                        m: 0.5,
                        fontSize: "9px",
                        height: "24px",
                        backgroundColor: isValidColor(tag?.tag?.color)
                          ? tag?.tag?.color
                          : "#708CFF",
                        color: "white",
                      }}
                    />
                  ))}
                </Box>
              ) : null}
            </Box>
            <Box sx={{ mt: 2 }}>
              <MatchingProgressBar
                scores={benef ? benef?.scores : workInfo?.scores}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "25%", md: "25%", lg: "30%" },
              mt: { xs: 2, sm: 0 },
            }}
            display="flex"
            justifyContent="right"
          >
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                width: { xs: "100%", sm: "150px", md: "100%" },
              }}
            > */}
            {workInfo?.scores?.mood !== undefined &&
              workInfo?.scores?.mood !== null &&
              parameters?.mood && (
                <WorkSuggestMood value={workInfo.scores.mood} />
              )}
            {!benef &&
              work?.type !== "category" &&
              !workSearch?.hideTension && (
                <Box
                  sx={{
                    display: "flex",
                    // flexDirection: { xs: "row", sm: "column", md: "row" },
                    alignItems: "end",
                  }}
                >
                  <WorkItemTension value={workInfo?.otherDetails?.tension} />
                </Box>
              )}
            {/* </Box> */}
          </Box>
        </Box>
        {!benef && !noBookmark && work?.type !== "category" && (
          <BookmarkBox
            onClick={(e: any) => e?.stopPropagation()}
            className="bookmark"
            sx={{
              opacity: { xs: 1, sm: workInfo?.isBookmarked ? 1 : 0 },
              position: "absolute",
              right: { xs: "80px", sm: "10px" },
              top: "12px",
            }}
          >
            <BookmarkComponent
              id={workInfo?.["@id"]}
              isBookmarked={workInfo?.isBookmarked}
              reload={handleBookmark}
            />
          </BookmarkBox>
        )}
      </CardContent>
    </WorkItem>
  );
};

export default WorkSuggestItem;
