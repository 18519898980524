import { useEffect, useState } from "react";

const useINCGroupFormatter = (values: any) => {
  const [results, setResults] = useState<any>(null);

  useEffect(() => {
    console.log("values", values);

    if (values) {
      const r = values.map((el: any) => ({
        key: el.detail ?? el.texts?.detail ?? el.type,
        score: el.value,
        color: el.color,
      }));
      setResults(r);
    }
  }, [values]);

  return results;
};

export default useINCGroupFormatter;
