import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Subscription } from "rxjs";
import employeeService from "../services/employees.service";
import successionService from "../services/succession.service";

const useSuccession = (
  id: string
): {
  loading: boolean;
  error: boolean;
  employees: any[];
  filters: any;
  matchers: any;
} => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [employees, setEmployees] = useState<any[]>([]);
  const [filters, setFilters] = useState(successionService.filters);
  const parameters = useSelector((state: any) => state.parameters);
  const matchersParams = parameters?.find(
    (el: any) => el.type === "GENERAL_PARAMS"
  )?.parameters?.MATCHERS;
  const [matchers, setMatchers] = useState<any>([]);

  useEffect(() => {
    if (successionService.filters.length === 0) {
      const arr = [];
      for (const key in matchersParams) {
        matchersParams[key] && arr.push(key);
      }

      if (arr.includes("skills")) {
        arr.push("transversal_skills");
      }
      setMatchers(arr);

      successionService.setFilters(arr);
    }
  }, [matchersParams]);

  useEffect(() => {
    let subscription: Subscription;
    setLoading(true);

    if (id) {
      subscription = successionService.get(id).subscribe({
        next: (res) => {
          error && setError(false);
          setLoading(false);
        },
        error: () => {
          setError(true);
          setLoading(false);
        },
      });
    }

    return () => {
      subscription?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, id]);

  useEffect(() => {
    const listSub = successionService._results.subscribe((res) =>
      setEmployees(res)
    );
    const paramsSub = successionService._filters.subscribe((res) => {
      setLoading(true);
      setFilters(res);
    });
    return () => {
      paramsSub.unsubscribe();
      listSub.unsubscribe();
      employeeService.reset();
    };
  }, []);

  return { loading, error, employees, filters, matchers };
};
export default useSuccession;
