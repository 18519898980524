import { Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CardIllustration from "../components/common/CardIllustration";
import Page from "../components/Page";
// import AccountData from "../components/params/AccountData";

const AccountDataPage = () => {
  const { t } = useTranslation("common");
  return (
    <Page title={t(`profile_menu.data`)}>
      <Container sx={{ pt: 13, pb: 5 }}>
        <CardIllustration type="data" />
        {/* <AccountData /> */}
      </Container>
    </Page>
  );
};

export default AccountDataPage;
