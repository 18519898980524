import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import ConversationList from "./ConversationList";
import ConversationPanel from "./ConversationPanel";

import Box from "@mui/material/Box";
import Page from "../Page";
import chatService from "../../services/chat.service";

const MainContainer = styled(Box)({
  flex: 2,
  display: "flex",
  marginTop: "30px",
  padding: "5px",
  paddingBottom: "0px",
  "@media (min-width:600px)": {
    marginTop: "30px",
    padding: "5px",
    paddingBottom: "0px",
  },
  "@media (min-width:960px)": {
    marginTop: "40px",
    padding: "35px",
    paddingBottom: "0px",
  },
});

const ConvListContainer = styled(Box, {
  shouldForwardProp: (props) => props !== "mobile",
})<{ mobile: boolean }>(({ mobile }) => ({
  width: "100%",
  display: mobile ? "none" : "flex",
  textAlign: "left",
  "@media (min-width:600px)": {
    display: mobile ? "none" : "flex",
  },
  "@media (min-width:960px)": {
    flex: "0.5 1",
    width: "350px",
    display: mobile ? "flex" : "",
  },
  "@media (min-width:1200)": {
    flex: "0.7 1",
    width: "450px",
  },
}));

const ConvPanelContainer = styled(Box, {
  shouldForwardProp: (props) => props !== "mobile",
})<{ mobile: boolean }>(({ mobile }) => ({
  display: mobile ? "flex" : "none",
  "@media (min-width:600px)": {
    display: "none",
  },
  "@media (min-width:960px)": {
    flex: "1 1",
    display: "flex",
  },
  "@media (min-width:1200)": {
    flex: "1 1",
    display: "flex",
  },
  width: mobile ? "-webkit-fill-available" : "",
}));

const ChatPage = styled(Page)({
  mt: "40px",
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const PanelContainerResponsive = styled(Container)({
  padding: "0px",
  "@media (min-width:600px)": {
    paddingRight: "24px",
    paddingLeft: "24px",
  },
});

const Chat: React.FC<{}> = () => {
  const conversations = useSelector((state: any) => state.chat.conversations);
  const [convId, setConvId] = useState<any>(null);
  const [mobile, setMobile] = useState<boolean>(true);

  useEffect(() => {
    const sub = chatService._convId.subscribe((e) => setConvId(e));

    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!convId && conversations?.[0])
      chatService.setConvId(
        conversations?.[0]?.id ?? conversations?.[0]?.["@id"]
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations]);

  return (
    <ChatPage>
      <MainContainer>
        <ConvListContainer mobile={mobile}>
          <Container>
            <ConversationList
              conversations={conversations}
              mobile={{ state: mobile, setState: setMobile }}
            />
          </Container>
        </ConvListContainer>

        <ConvPanelContainer mobile={mobile}>
          <PanelContainerResponsive>
            <ConversationPanel
              mobile={{ state: mobile, setState: setMobile }}
            />
          </PanelContainerResponsive>
        </ConvPanelContainer>
      </MainContainer>
    </ChatPage>
  );
};

export default Chat;
