import { styled, Typography } from "@mui/material";
import React from "react";

const StyledBox: any = styled("span", {
  shouldForwardProp: (props) => props !== "themeColor",
})(({ theme, themeColor = "primary" }: any) => ({
  display: "flex",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  alignItems: "center",
  justifyContent: "center",
  height: 31,
  color: theme.palette[themeColor].main,
  backgroundColor: theme.palette[themeColor].lighter,
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(1),
  width: "fit-content",
}));

const Tag: React.FC<{
  title: string;
  image?: string;
  icon?: any;
  color?: "primary" | "secondary" | "success" | "error" | "default" | "warning";
}> = ({ title, color = "primary", image, icon }) => {
  return (
    <StyledBox themeColor={color}>
      {image && <img src={image} alt={`icon`} color="primary" width="20" />}
      {icon && icon}
      <Typography variant="caption" sx={{ ml: image || icon ? 1 : 0 }}>
        {title}
      </Typography>
    </StyledBox>
  );
};

export default Tag;
