import { FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const ControlledSwitch: React.FC<{
  control: any;
  name: string;
  required?: boolean;
  rules?: any;
  label?: string;
  handleChange?: any;
}> = ({ control, name, required = false, rules, label, handleChange }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: "errors.required" },
        ...rules,
      }}
      render={({ field }) => (
        <FormControlLabel
          control={<Switch {...field} checked={field.value} />}
          label={label}
        />
      )}
    />
  );
};

export default ControlledSwitch;
