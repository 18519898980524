import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import GenderSelect from "../common/GenderSelect";
import ProfilePictureButton from "../common/ProfilePictureButton";
import { useTranslation } from "react-i18next";
import VisioList from "./VisioList";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ControlledTextField from "../forms/ControlledTextField";
import CustomButtonModal from "../common/CustomBtnModal";
import { useSelector } from "react-redux";
import AddressAutocomplete from "../common/autocomplete/AddressAutocomplete";

interface IFormInput {
  gender: string;
  image: string;
  firstName: string;
  lastName: string;
  email: string;
  zoom: string;
  teams: string;
  linkedin: string;
  addressValue: any;
}

const ProfileEditFormBody: React.FC<any> = ({
  form,
  submit,
  loading,
  close,
}) => {
  const { t } = useTranslation("common");
  console.log("form", form);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { touchedFields },
  } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: form,
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    const keys = Object.keys(touchedFields);

    if (data.gender !== form.gender) {
      keys.push("gender");
    }

    const res: any = {};
    const resData: any = data;
    keys.forEach((key) => (res[key] = resData[key]));
    if (form.addressValue?.label !== data?.addressValue?.label)
      res.addressValue = data.addressValue;
    submit(res);
  };

  const disableEditName = useSelector(
    (state: any) => state?.home?.paramjson?.disableEditName
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack sx={{ "& .MuiGrid-root": { pr: 0 } }}>
        {/* Photo profil */}
        <ProfilePictureButton image={getValues("image")} />
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <Controller
            name="gender"
            control={control}
            rules={{
              required: { value: true, message: "errors.required" },
            }}
            render={({ field: { value, onChange } }) => (
              <GenderSelect gender={value} setGender={onChange} littleMargin />
            )}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ mt: 2, mb: 2, p: 1, mr: 1 }}
        >
          <ControlledTextField
            name="email"
            label={t("form.email")}
            control={control}
            disabled={disableEditName}
          />
        </Box>
        <Grid container sx={{ marginTop: "0px !important", pr: 1 }}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ p: 1 }}>
              <ControlledTextField
                name="firstName"
                label={t("form.firstname")}
                control={control}
                disabled={disableEditName}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ p: 1 }}>
              <ControlledTextField
                name="lastName"
                label={t("form.lastname")}
                control={control}
                disabled={disableEditName}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ padding: "8px", paddingRight: "8px!important" }}
          >
            <Controller
              name="addressValue"
              control={control}
              rules={{
                required: { value: true, message: "errors.required" },
              }}
              render={({ field: { value, onChange } }) => (
                <AddressAutocomplete value={value} onChange={onChange} />
              )}
            />
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: "0px !important", pr: 1 }}>
          {/* Moyen de visio disponible */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {t("profilepage.visios.subtitle")}
            </Typography>
          </Grid>
          <VisioList control={control} />
        </Grid>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <CustomButtonModal
            disabled={loading}
            type="submit"
            variant="contained"
            text={t("btn.save")}
          />
          <CustomButtonModal
            secondary
            onClick={close}
            variant="outlined"
            color="secondary"
            text={t(`btn.cancel`)}
          />
        </Box>
      </Stack>
    </form>
  );
};

export default ProfileEditFormBody;
