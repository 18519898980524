import { Toolbar, alpha, Typography, Tooltip, IconButton } from "@mui/material";
// import FilterListIcon from "@mui/icons-material/FilterList";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

type ToolbarButton = {
  name: string;
  onClick: () => void;
  icon: any;
};
interface EnhancedTableToolbarProps {
  numSelected: number;
  tableName?: string;
  actions?: ToolbarButton[];
  permanentActions?: any[];
}

function CustomTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, tableName, actions, permanentActions } = props;
  const { t } = useTranslation("common");

  // todo Tableau des composants à afficher si numSelected === 0

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {t("table.toolbar.selected", { count: numSelected })}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableName}
        </Typography>
      )}
      {numSelected > 0 ? (
        <>
          {actions?.map((action: any) => (
            <Tooltip key={action.name} title={action.name}>
              <IconButton
                disabled={action.disabled}
                onClick={() => {
                  action.onClick?.();
                }}
              >
                {action.icon}
              </IconButton>
            </Tooltip>
          ))}
        </>
      ) : (
        //Faire une liste grid
        <>
          {permanentActions?.map((el, index) => (
            <Fragment key={index}>{el}</Fragment>
          ))}
        </>
      )}
    </Toolbar>
  );
}

export default CustomTableToolbar;
