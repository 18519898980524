import { ButtonBase, styled } from "@mui/material";

const StyledPrimaryButton: any = styled(ButtonBase)(
  ({ theme, width, height, pl, pr, pt, pb, p, bordercolor }: any) => ({
    ...(!pt && !pb && !pl && !pr && !p ? { padding: theme.spacing(1) } : {}),
    ...(pt ? { paddingTop: theme.spacing(pt) } : {}),
    ...(pb ? { paddingBottom: theme.spacing(pb) } : {}),
    ...(pl ? { paddingLeft: theme.spacing(pl) } : {}),
    ...(pr ? { paddingRight: theme.spacing(pr) } : {}),
    ...(p ? { padding: theme.spacing(p) } : {}),
    borderRadius: 8,
    ...(bordercolor
      ? { border: "1px solid " + theme.palette[bordercolor].main }
      : { border: "1px solid " + theme.palette.grey[300] }),
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
    "&:hover": {
      background: theme.palette.grey[500_8],
    },
  })
);

export default StyledPrimaryButton;
