import { ButtonBase, styled } from "@mui/material";
import React, { useContext } from "react";
import { AdminParamsContext } from "../../contexts/AdminParamsContext";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";

const SaveButton = styled(ButtonBase)(({ theme }) => ({
  position: "fixed",
  bottom: 30,
  right: 30,
  background: theme.palette.primary.main,
  color: "white",
  width: "50px",
  height: "50px",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const AdminSaveParamsButton: React.FC<{
  type: any;
  additionalType?: string;
  additionalCall?: boolean;
}> = ({ type, additionalType, additionalCall }) => {
  const { saveParams, loading, setTriggerCall, triggerCall } =
    useContext(AdminParamsContext);

  return (
    <SaveButton
      onClick={() => {
        saveParams(type);
        additionalType && saveParams(additionalType);
        additionalCall && setTriggerCall(triggerCall + 1);
      }}
    >
      {loading ? (
        <CircularProgress
          sx={{ color: "white", width: "30px", height: "30px" }}
        />
      ) : (
        <SaveIcon />
      )}
    </SaveButton>
  );
};

export default AdminSaveParamsButton;
