import { Box } from "@mui/material";
import axios from "axios";
import React, { useContext, useReducer } from "react";
import { ProfileContext } from "../../contexts/ProfileContext";
import { skillsReducer } from "./skillsReducer";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Skill2 } from "./newSkills/container/Skill";

const SkillEdit: React.FC<{
  identifier?: any;
  skill: any;
  offline?: boolean;
  canRemove: boolean;
  showLevelNameSlider?: boolean;
  deleteEmitter?: (skill: any) => void;
  skillEmitter?: (skill: any) => void;
  handleDeleteSkill?: (skill: any) => void;
  deleteValidation?: boolean;
  context?: string;
}> = ({
  identifier,
  skill,
  offline,
  canRemove,
  showLevelNameSlider,
  deleteEmitter,
  skillEmitter,
  handleDeleteSkill,
  deleteValidation = true,
  context,
}) => {
  const initialState = {
    value: skill?.level?.level || skill?.skillLevel || skill?.personLevel || 1,
    deleting: false,
    skill,
    loading: false,
    readOnly: false,
  };
  const { t } = useTranslation("common");
  const [state, dispatch]: any = useReducer<any>(skillsReducer, initialState);

  const profileContext = useContext(ProfileContext);

  const handleChange = (event: any, newValue: any) => {
    if (skillEmitter)
      skillEmitter({
        ...state.skill,
        level: { level: newValue ?? state.value },
      });
    if (!newValue) return;
    const oldValue = state.value;
    dispatch({ type: "VALUE", value: newValue });
    if (offline) return;

    dispatch({ type: "LOADING", value: true });

    axios
      .put(state.skill["@id"].replace("/api/", ""), {
        level: `/api/skill_levels/${newValue}`,
      })
      .then((res: any) => {
        // Update profile skills
        if (profileContext) {
          res.data.type = skill.type;
          profileContext.updateSkills(res.data);
        }
      })
      .catch(() => {
        dispatch({ type: "VALUE", value: oldValue });
      })
      .finally(() => {
        dispatch({ type: "LOADING", value: false });
      });
  };

  const handleDelete = () => {
    handleDeleteSkill && deleteValidation && handleDeleteSkill(skill);
    if (deleteEmitter) deleteEmitter(skill);
    if (offline) return;
    if (!deleteValidation) {
      dispatch({ type: "LOADING", value: true });
      axios
        .delete(state.skill["@id"].replace("/api/", ""))
        .then(() => {
          if (profileContext) {
            const sk = [
              ...profileContext[
                skill.type === "skills" ? "skills" : "transversalSkills"
              ],
            ];
            const index = sk.findIndex((el: any) => el.id === state.skill.id);
            sk.splice(index, 1);
            if (skill.type === "skills") {
              profileContext.setSkills(sk);
            } else {
              profileContext.setTransversalSkills(sk);
            }
          }
          toast.success(
            t(
              `success.${
                skill.type !== "transversal" ? "skills" : "aptidues"
              }_deleted`
            )
          );
        })
        .catch(() => {
          dispatch({ type: "LOADING", value: false });
          toast.error(t("errors.error_append"));
        });
    }
  };

  const colors = skill?.skillCategoryData?.colors;

  return (
    <Box
      sx={{
        background: colors?.background ?? "#bad6ff",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <Skill2
        compare={false}
        editMode={true}
        canDelete={true}
        skill={{
          ...skill,
          levels: {
            left: state.value,
          },
          level: state.value,
        }}
        editSkill={handleChange}
        workflowState={{ canUpdate: true, status: 10 }}
        activeColor={colors?.button ?? "#3d5cff"}
        font={colors?.button ?? "#2e3a59"}
        addToDeleteList={handleDelete}
      />
    </Box>
  );
};

export default SkillEdit;
