import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import ConversationListItem from "./ConversationListItem";
import SearchIcon from "@mui/icons-material/Search";
import chatAPI from "../../_api/_chatAPI";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import axios from "axios";
import chatService from "../../services/chat.service";
import { requestUpdateConnectionStatus } from "../../redux/actions/chatActions";
import { setUser } from "../../redux/actions/userActions";
import { useTranslation } from "react-i18next";
import StringAvatar from "../common/StringAvatar";

const MainContainer = styled(Box)(({ theme }: any) => ({
  borderRadius: theme.shape.borderRadius12,
  minHeight: "500px",
}));

const Header = styled(Box)(({ theme }: any) => ({
  backgroundColor: "#fff",
  borderBottom: "1px solid " + theme.palette.grey[400],
  borderRadius: "12px 12px 0 0",
  padding: "0em 1.5em 12px 1.5em",
  marginBottom: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}));

const ConvListContainer = styled(Box)({
  width: "100%",
  maxHeight: "50vh",
  overflowY: "scroll",
});

const ConvListTitle = styled(Box)(({ theme }: any) => ({
  display: "flex",
  padding: theme.spacing(1),
  alignItems: "center",
}));

const SearchInput = styled(FormControl)({
  position: "sticky",
  backgroundColor: "transparent",
  '&:[class$="MuiInputLabel-root"]': {
    color: "#AFBBC6",
  },
  '&:[class$="MuiOutlinedInput-root"]': {
    backgroundColor: "#F9FAFC",
  },
});

const Card = styled(Box)({
  margin: "0 5px 5px 5px",
  display: "flex",
  alignItems: "center",
  padding: "0px",
  borderRadius: "10px",

  cursor: "pointer",
  border: "1px solid #fff",
});

const ConversationList: React.FC<{ conversations: any; mobile: any }> = ({
  conversations,
  mobile,
}) => {
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const { t }: any = useTranslation("common");
  const [convFilterValue, setConvFilterValue] = useState<any>("");
  const [visibleConvs, setVisibleConvs] = useState<any>(conversations);
  const defaultState =
    tokenData?.status?.value === undefined ? "away" : tokenData?.status?.value;
  const [connectStatus, setConnectStatus] = useState<any>(defaultState);
  const ConnectStatusTest = styled(TextField)(({ theme }: any) => ({
    alignItems: "baseline",
    border: "none !important",
    "& .MuiInput-root": {
      "&:after": {
        content: "none",
      },
      "&:before": {
        content: "none",
      },
    },
  }));

  const Light: any = styled(Typography)(({ theme }: any) => ({
    position: "absolute",
    width: "18px",
    height: "18px",
    border: "solid 3px #fff",
    borderRadius: theme.shape.borderRadius48,
    left: "36px",
    top: "36px",
    alignmentBaseline: "baseline",
    zIndex: 1200,
  }));

  const ConvTitle: any = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    overflow: "hidden",
    marginLeft: "8px",
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    onChangeHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convFilterValue]);

  useEffect(() => {
    setVisibleConvs(conversations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations]);

  useEffect(() => {
    if (tokenData?.status?.value !== undefined)
      setConnectStatus(tokenData?.status?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData?.status?.value]);

  const onChangeHandle = async () => {
    let copyConvs = conversations?.length > 0 ? [...conversations] : [];
    if (convFilterValue !== "") {
      const load = await axios.get(
        chatAPI.getById(`autocomplete_conversation`, {
          filter: convFilterValue,
        })
      );
      const displayble_data = load.data["hydra:member"];
      const convs_ids = displayble_data.map((e: any) => {
        return e?.id;
      });

      copyConvs = copyConvs?.filter((e: any) => {
        return convs_ids.includes(e?.id);
      });
    }

    setVisibleConvs(copyConvs);
  };

  return (
    <MainContainer>
      <Header>
        <img src="/static/logo/chatLogo.svg" alt="chatLogo" width="60" />

        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: 1 }}>
          <Typography sx={{ textAlign: "left" }} variant="h4">
            {t("chat.the")}
          </Typography>
          <Typography
            variant="h4"
            sx={{ textAlign: "left", fontWeight: "bold" }}
          >
            {"Messenger Pass To Work"}
          </Typography>
        </Box>
      </Header>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        pl="1.5em"
        pr="1.5em"
        pt="12px"
        pb="12px"
      >
        <Box sx={{ position: "relative" }}>
          <StringAvatar
            size={55}
            name={tokenData?.firstName + " " + tokenData?.lastName}
            url={tokenData?.image}
          />
          <Light
            component="span"
            sx={{
              backgroundColor: chatService.getColorByStatus(tokenData?.status),
            }}
          />
        </Box>
        <Box
          sx={{ marginLeft: "1em", display: "flex", flexDirection: "column" }}
        >
          <Typography variant="h5" component="p" sx={{ fontWeight: "bold" }}>
            {tokenData?.firstName + " " + tokenData?.lastName}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={{ mt: "-5px", color: "secondary.light" }}
          >
            {tokenData?.company_name}
          </Typography>

          <ConnectStatusTest
            label=""
            placeholder=""
            select
            value={connectStatus}
            onChange={(e: any) => {
              setConnectStatus(e?.target?.value);
              dispatch(requestUpdateConnectionStatus(e?.target?.value));
              if (!!tokenData.status) tokenData.status.value = e?.target?.value;
              setUser(tokenData);
            }}
            variant="standard"
          >
            <MenuItem key={1} value={"connected"}>
              <Typography sx={{ color: "#34b54a", fontWeight: "bold" }}>
                {t("chat.connected")}
              </Typography>
            </MenuItem>
            <MenuItem key={2} value={"away"}>
              <Typography sx={{ color: "#cf2525", fontWeight: "bold" }}>
                {t("chat.away")}
              </Typography>
            </MenuItem>
          </ConnectStatusTest>
        </Box>
      </Box>

      <ConvListTitle>
        <Typography component="p" sx={{ fontWeight: 700 }}>
          {t("chat.current_conv_title")}
        </Typography>
      </ConvListTitle>

      <Box sx={{ pl: "5px", pr: 0, pt: 1, pb: 1 }}>
        <SearchInput fullWidth size="small">
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ color: "red" }}
          >
            {t("chat.conv_filter_input_label")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={"text"}
            disabled={false}
            value={convFilterValue}
            onChange={(e: any) => {
              setConvFilterValue(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="none" edge="end">
                  <SearchIcon sx={{ color: "#AFBBC6" }} />
                </IconButton>
              </InputAdornment>
            }
            label={t("chat.conv_filter_input_label")}
          />
        </SearchInput>
      </Box>

      <ConvListContainer>
        <Card
          onClick={() => {
            mobile.setState(true);
            chatService.setCreatingConversation(true);
          }}
        >
          <Button variant="outlined" sx={{ width: "100%", minHeight: "55px" }}>
            <ConvTitle component="p">{t("chat.create_conv")}</ConvTitle>
          </Button>
        </Card>
        {visibleConvs
          ?.sort((a: any, b: any) =>
            new Date(a?.lastMessage?.createdAt).getTime() >
            new Date(b?.lastMessage?.createdAt).getTime()
              ? -1
              : 1
          )
          .map((el: any) => {
            el = chatService.setConvImageByMember(el, tokenData?.person_id);
            return (
              <ConversationListItem
                key={el?.id ?? el["@id"]}
                convInfo={el}
                mobile={mobile}
              />
            );
          })}
      </ConvListContainer>
    </MainContainer>
  );
};

export default ConversationList;

// TODO
// lors de la creation d'une conv  => avatar en dessous de 'linput de choix
// petit crayon pour modifier le titre conv
// creation du name par default en mode COnversation #12r45(radn )
