import React, { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router";
import LogoLoader from "../components/LogoLoader";
import NoMenuLayout from "../layout/NoMenuLayout";

const PasswordResetPage = lazy(() => import("../pages/PasswordResetPage"));

const PasswordRoutes = () => {
  return useRoutes([
    {
      path: "",
      element: <NoMenuLayout />,
      children: [
        {
          path: "reset_password",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <PasswordResetPage />
            </Suspense>
          ),
        },
        { path: "", element: <Navigate to="/reset_password" replace /> },
      ],
    },
    { path: "", element: <Navigate to="/reset_password" replace /> },
    { path: "*", element: <Navigate to="/reset_password" replace /> },
  ]);
};

export default PasswordRoutes;
