import { useTheme } from "@mui/styles";
import React from "react";

const ProfileIcon: React.FC<{
  active?: boolean;
  context?: string;
  size?: number;
}> = ({ active, context, size }) => {
  const theme: any = useTheme();
  return (
    <svg
      id="face_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width={size || "20"}
      height={size || "20"}
      viewBox="0 0 16 16"
    >
      <g id="Groupe_3185" data-name="Groupe 3185">
        <path
          id="Tracé_12900"
          data-name="Tracé 12900"
          d="M0,0H16V16H0Z"
          fill="none"
        />
      </g>
      <g
        id="Groupe_3186"
        data-name="Groupe 3186"
        transform="translate(1.333 1.333)"
      >
        <path
          id="Tracé_12901"
          data-name="Tracé 12901"
          d="M7.5,9.333A.833.833,0,1,1,6.667,8.5.834.834,0,0,1,7.5,9.333ZM10.667,8.5a.833.833,0,1,0,.833.833A.834.834,0,0,0,10.667,8.5Zm4.667.167A6.667,6.667,0,1,1,8.667,2,6.669,6.669,0,0,1,15.333,8.667ZM14,8.667a5.253,5.253,0,0,0-.22-1.493,6.87,6.87,0,0,1-1.447.16A6.666,6.666,0,0,1,7.16,4.873a6.677,6.677,0,0,1-3.827,3.7.436.436,0,0,1,0,.093A5.333,5.333,0,0,0,14,8.667Z"
          transform="translate(-2 -2)"
          fill={
            !active &&
            (context === "collab" ||
              context === "benef" ||
              context === "candidate")
              ? theme.palette.contextColors.collab.font
              : context === "FolderCardItem"
              ? "#1EC626"
              : "#fff"
          }
        />
      </g>
    </svg>
  );
};

export default ProfileIcon;
