import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeIcon from "../../icons/menu/home";
import TeamIcon from "../../icons/menu/team";
import DataIcon from "../../icons/menu/data";
import NavigationRenderer from "../NavigationRenderer";

const AdminContextMenu: React.FC<{
  handleLinkClick: any;
}> = ({ handleLinkClick }: any) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const location = useLocation();
  const links = [
    {
      icon: (
        <HomeIcon
          active={location.pathname.includes("home")}
          context={context}
          size={25}
        />
      ),
      label: t("home"),
      path: "/app/home",
      active: location.pathname.includes("home"),
    },
    {
      icon: <TeamIcon size={25} />,
      label: t("admin_menu.users"),
      path: "/app/users",
      active: location.pathname.includes("users"),
    },
    {
      icon: <DataIcon white size={25} />,
      label: t("admin_menu.params"),
      path: "/app/params/general",
      active: location.pathname.includes("params"),
    },
  ];

  return <NavigationRenderer links={links} handleLinkClick={handleLinkClick} />;
};

export default AdminContextMenu;
