// ----------------------------------------------------------------------

export default function Table(theme: any) {
  return {
    MuiTable: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {},
        head: {
          background: theme.palette.grey[200],
          color: theme.palette.grey[600],
          "&:first-of-type": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          },
          "&:last-child": {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
          },
          border: "none",
        },
        body: {},
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {},
        head: {},
        body: {},
      },
    },
  };
}
