import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ThirdProfileList: React.FC<any> = ({ thirdParties }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const redirect = (id: string) => navigate(`/app/third_party/${id}/profiles`);
  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title={t("Liste des partages")}
        titleTypographyProps={{ variant: "subtitle2" }}
      />
      <CardContent sx={{ pt: 1 }}>
        <Stack spacing={1}>
          {thirdParties.map((el: any) => (
            <Box
              key={el.fullName}
              sx={{ cursor: "pointer" }}
              onClick={() => redirect(el.id)}
            >
              <Typography>
                {el.fullName}{" "}
                <Typography component="span" sx={{ opacity: 0.7 }}>
                  - {el.company}
                </Typography>
              </Typography>
            </Box>
          ))}
          {thirdParties?.length === 0 && <Typography>Aucun partage</Typography>}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ThirdProfileList;
