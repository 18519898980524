import { Typography, Box, Button, Grid, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import NextStepButton from "../buttons/NextStepButton";

const MainTitle = styled(Typography)({
  fontSize: "40px",
});

const IntroText = styled("div")(({ theme }: any) => ({
  width: "120px",
  height: "30px",
  borderRadius: "6px",
  padding: "3px",
  backgroundColor: theme.palette.background.neutral,
}));

const BoxTitle = styled(Box)(({ theme }: any) => ({
  marginTop: theme.spacing(5),
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const Title = styled(Typography)({
  fontWeight: "normal",
  lineHeight: "21px",
});

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary[500_70],
  marginTop: theme.spacing(1),
  lineHeight: "18px",
  maxWidth: "35ch",
}));

const CustomButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "inc",
})(({ inc }: any) => ({
  width: inc ? "280px" : "100%",
  height: "60px",
  borderRadius: "10px",
  fontSize: "14px",
}));

const IncIntro: React.FC<{
  nextStep: any;
  skipTest: any;
  onboarding?: boolean;
  onboardingStep?: number;
  canSkip?: boolean;
}> = ({ nextStep, skipTest, onboarding, onboardingStep, canSkip }) => {
  const { t } = useTranslation("common");

  const texts = t("inc.intro.instructions", {
    returnObjects: true,
  }) as Array<any>;
  console.log(texts);

  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2, md: 0 } }}>
      {onboarding && (
        <MainTitle
          variant="h2"
          textAlign="center"
          sx={{ lineHeight: { xs: 1.4, sm: 64 / 30 } }}
        >
          {onboardingStep ? `0${onboardingStep}. ` : ""}
          {t("inc.intro.title")}
        </MainTitle>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <IntroText>
          <Typography fontWeight="bold" variant="body1">
            {t("tests.page.label")}
          </Typography>
        </IntroText>

        <Typography
          sx={{ mt: 3, maxWidth: "75ch" }}
          variant="body1"
          fontWeight="bold"
          dangerouslySetInnerHTML={{ __html: t("inc.intro.text1") }}
        />
        <Typography sx={{ mt: 2 }} variant="body1" fontWeight="bold">
          {t("inc.intro.text2")}
        </Typography>

        <Grid container width="100%">
          {texts.map((el: any, index: number) => (
            <Grid key={el.title} item xs={12} sm={6} md={6} lg={6} xl={3}>
              <BoxTitle>
                <Typography variant="h4" sx={{ fontSize: "40px" }}>
                  {texts[index].icon}
                </Typography>
                <Title
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html: `${index + 1}. ${texts[index].title}`,
                  }}
                />
                <Subtitle>{texts[index].text}</Subtitle>
              </BoxTitle>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: !onboarding ? "center" : "",
            mt: 4,
            gap: onboarding ? 2 : 0,
            width: { xs: "100%", md: "60%" },
          }}
        >
          <CustomButton
            variant="contained"
            color="primary"
            inc={!onboarding}
            onClick={() => nextStep()}
          >
            {t("inc.intro.begin")}
          </CustomButton>
          {onboarding && (
            <Box sx={{ width: "100%" }}>
              <NextStepButton
                text={canSkip ? undefined : t("btn.prev")}
                onClick={skipTest}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default IncIntro;
