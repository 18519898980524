import { Box, styled } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  marginBottom: theme.spacing(2),
  width: 41,
  minWidth: 41,
  height: 41,
  marginRight: theme.spacing(2.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ExperienceLogo: React.FC<any> = ({ experience }) => {
  const theme: any = useTheme();

  return (
    <Icon
      sx={{
        backgroundColor: experience?.companyData?.logo
          ? "transparent"
          : theme.palette.primary[500_8],
      }}
    >
      <img
        src={
          experience?.companyData?.logo
            ? experience?.companyData?.logo
            : experience?.work?.category?.code
            ? `/static/icons/work_cat/${experience?.work?.category?.code?.slice(
                0,
                5
              )}.png`
            : `/static/icons/${
                experience.type === "experience"
                  ? "portfolio"
                  : "graduation-hat"
              }.svg`
        }
        width={
          experience?.companyData?.logo
            ? "30"
            : experience?.work?.category?.code
            ? "40"
            : "22"
        }
        alt={`experience icon`}
        color="primary"
      />
      {/* <WorkOutline fontSize="medium" color="primary" /> */}
    </Icon>
  );
};

export default ExperienceLogo;
