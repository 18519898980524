import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminFeedbacks: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");
  return (
    <div>
      <Typography variant="h4">{t("admin.params.feedback.title")}</Typography>
      <FormGroup>
        <FormControlLabel
          value={param.DISPLAY_FEEDBACK}
          onChange={(e: any) =>
            handleChanges({ ...param, DISPLAY_FEEDBACK: e.target.checked })
          }
          control={<Checkbox checked={param.DISPLAY_FEEDBACK} />}
          label={t("admin.params.feedback.display")}
        />
        <FormControlLabel
          value={param.DOWNLOAD_FEEDBACK}
          onChange={(e: any) =>
            handleChanges({ ...param, DOWNLOAD_FEEDBACK: e.target.checked })
          }
          control={<Checkbox checked={param.DOWNLOAD_FEEDBACK} />}
          label={t("admin.params.feedback.download")}
        />
      </FormGroup>

      <Typography textAlign="right">{t("admin.params.reco_text")}</Typography>
    </div>
  );
};

export default AdminFeedbacks;
