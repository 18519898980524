import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BottomFilterContent from "../common/chip_filter/BottomFilterContent";
import FilterPopoverContainer from "../common/chip_filter/FilterPopoverContainer";

const WorkTrainingOrder = ({ handleOrder, httpParams }: any) => {
  const { t } = useTranslation("common");

  const [close, setClose] = useState<any>(false);
  const [value, setValue] = useState<any>(httpParams?.order);

  const returnTitleChip = () => {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="body1">Tri</Typography>
        <Typography variant="body1" fontWeight="bold" sx={{ ml: 0.5 }}>
          {t(
            `trainings.filters.${
              httpParams?.order === "relevance"
                ? "relevance"
                : httpParams?.order === "action.prixTotalTtc.value"
                ? "price"
                : httpParams?.order === "action.nombreHeuresTotal.value" &&
                  "duration"
            }`
          )}
        </Typography>
      </Box>
    );
  };

  const handleSubmit = () => {
    handleOrder(value);
    setClose(!close);
  };

  const handleClear = () => {
    setValue("");
    handleOrder("");
    setClose(!close);
  };

  return (
    <>
      <FilterPopoverContainer
        title={returnTitleChip()}
        idPop="order_popover"
        close={close}
        active={value?.length > 0}
        content={
          <>
            <FormGroup sx={{ mt: 1, ml: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value === "relevance"}
                    onClick={() => setValue("relevance")}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    color={value === "relevance" ? "primary" : "secondary.400"}
                  >
                    {t(`trainings.filters.relevance`)}
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value === "action.prixTotalTtc.value"}
                    onClick={() => setValue("action.prixTotalTtc.value")}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    color={
                      value === "action.prixTotalTtc.value"
                        ? "primary"
                        : "secondary.400"
                    }
                  >
                    {t(`trainings.filters.price`)}
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value === "action.nombreHeuresTotal.value"}
                    onClick={() => setValue("action.nombreHeuresTotal.value")}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    color={
                      value === "action.nombreHeuresTotal.value"
                        ? "primary"
                        : "secondary.400"
                    }
                  >
                    {t(`trainings.filters.duration`)}
                  </Typography>
                }
              />
            </FormGroup>
            <BottomFilterContent
              handleSubmit={handleSubmit}
              handleClear={handleClear}
            />
          </>
        }
      />
    </>
  );
};

export default WorkTrainingOrder;
