import React from "react";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Box, Skeleton, Typography } from "@mui/material";
import RessourceCard from "./RessourceCard";
import { useTranslation } from "react-i18next";

const ResourcesFiles: React.FC<{
  files: any[];
  sorted: boolean;
  loading: boolean;
}> = ({ files, sorted, loading }) => {
  const { t } = useTranslation("common");

  return (
    <section>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ borderBottom: "1px solid lightgrey", paddingBottom: "8px" }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InsertDriveFileOutlinedIcon sx={{ mr: 1 }} />
          <Typography variant="subtitle1" fontWeight="normal">
            {t("resources.documents_to_consult")}
          </Typography>
        </Box>
      </Box>

      {!loading && files.length === 0 && (
        <Typography sx={{ mt: 1 }}>
          {sorted
            ? t("resources.no_document_search")
            : t("resources.no_document")}
        </Typography>
      )}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(182px, 1fr))",
        }}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4].map((el) => (
              <Skeleton
                key={el}
                sx={{ marginTop: "15px" }}
                variant="rounded"
                width={180}
                height={256}
              />
            ))}
          </>
        ) : (
          <>
            {files.map((e: any) => (
              <RessourceCard data={e} key={e.id} />
            ))}
          </>
        )}
      </Box>
    </section>
  );
};

export default ResourcesFiles;
