import { Box, Divider, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const BottomFilterContent = ({
  handleClear,
  handleSubmit,
  disabled,
  extraContent,
}: any) => {
  const { t } = useTranslation("common");

  return (
    <Box
      sx={{
        position: "sticky",
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
      }}
    >
      <Divider sx={{ width: "100%" }} />
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        sx={{ mt: 1, pb: 1 }}
      >
        <Button
          color="secondary"
          variant="text"
          onClick={handleClear}
          sx={{ ml: 3 }}
          disabled={disabled}
        >
          <Typography variant="body1" fontWeight="bold">
            {t("btn.erase")}
          </Typography>
        </Button>
        {extraContent}
        <Button
          color="primary"
          variant="text"
          sx={{ ml: "auto", mr: 3 }}
          onClick={handleSubmit}
        >
          <Typography variant="body1" fontWeight="bold">
            {t("btn.valid")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default BottomFilterContent;
