import {
  Box,
  Modal,
  Card,
  Typography,
  IconButton,
  styled,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { modalStyle } from "../../../_utils/modalStyle";
import { HighlightOff } from "@mui/icons-material";
import TestIcon from "../../../icons/menu/test";
import ProfileIcon from "../../../icons/menu/profile";
import StringAvatar from "../../common/StringAvatar";
import CampaignAutocomplete from "../../common/autocomplete/CampaignAutocomplete";
import { useTranslation } from "react-i18next";

const Customcard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "22px",
});

const IconBox: any = styled(Box, {
  shouldForwardProp: (props) => props !== "people",
})(({ theme, people }: any) => ({
  padding: "10px",
  width: "40px",
  height: "40px",
  borderRadius: theme.shape.borderRadius8,
  color: theme.palette.common.white,
  marginLeft: people ? "auto" : 0,
  marginRight: people ? "9px" : "auto",
  backgroundColor: people
    ? theme.palette.green[500]
    : theme.palette.primary.main,
}));

const Subtext = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary[400],
}));

const MatchingCandidateProfile: React.FC<{
  open: boolean;
  close: () => void;
  person: any;
}> = ({ open, close, person }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const handleCampaign = (e: any) => {
    navigate(`/app/matching/${person.id}/${e.id}`);
  };

  return (
    <Modal open={open} onClose={close}>
      <Customcard
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "95%", md: "840px", lg: "840px" },
        }}
      >
        <Box sx={{ display: "flex", width: "100%", mb: "15px", mt: "5px" }}>
          <Box sx={{ display: "flex", flexGrow: 1, ml: "16px" }}>
            <IconBox people>
              <TestIcon />
            </IconBox>
            <IconBox>
              <ProfileIcon />
            </IconBox>
          </Box>

          <IconButton
            aria-label="close modal"
            sx={{ alignSelf: "end", mb: "auto", padding: "0" }}
            onClick={close}
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h4">Matching de</Typography>
          &nbsp;
          <StringAvatar
            size={30}
            name={person?.firstName + " " + person?.lastName}
            url={person?.image?.contentUrl}
          />
          <Typography variant="h4" color="primary">
            &nbsp;{person?.firstName}
            &nbsp;{person?.lastName}
          </Typography>
        </Box>
        <Subtext variant="body2" sx={{ mt: 1, mb: 3 }}>
          {t("advisor.folder.new_folder_text")}
        </Subtext>
        <Box width="80%" sx={{ mb: 4 }}>
          <CampaignAutocomplete
            handleCampaign={handleCampaign}
            matching
            preLoad
          />
        </Box>
      </Customcard>
    </Modal>
  );
};

export default MatchingCandidateProfile;
