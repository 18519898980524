import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import IncContainer from "./IncContainer";
import Page from "../Page";
import CardIllustrationTests from "../tests/CardIllustrationTests";

const IncPage = () => {
  const { t }: any = useTranslation("common");
  return (
    <Page title={t("tests.title.inc")}>
      <Container sx={{ pt: 13, pb: 5 }}>
        <CardIllustrationTests test="inc" height="240" />
        <IncContainer />
      </Container>
    </Page>
  );
};

export default IncPage;
