import { Menu, MenuItem, Box, Typography, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutButton from "../../components/auth/LogoutButton";
import DataIcon from "../../icons/menu/data";
import StringAvatar from "../../components/common/StringAvatar";
import ParamsIcon from "../../icons/menu/params";
import ProfileLightIcon from "../../icons/menu/profile_light";
import SelectComponent from "../../components/common/SelectComponent";
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined";

import UsersAPI from "../../_api/_usersAPI";
import rolesKeys from "../../_utils/rolesList";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setContext } from "../../redux/actions/authActions";
import useGlobalSplashscreen from "../../components/splashscreen/useGlobalSpashscreen";
import { handleMenu } from "../../redux/actions/menuActions";
// import TeamIcon from "../../icons/menu/team";

const ProfileMenu: React.FC<any> = ({
  anchorEl,
  menuId,
  isMenuOpen,
  handleMenuClose,
  tokenData,
  context,
  avatar,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const location = useLocation();
  const [contextList, setContextList] = useState<object[]>([]);
  const [openSplashScreen, setOpenSplashScreen] = useState(false);
  const dispatch = useDispatch();
  const menu = useSelector((state: any) => state.menu);

  useGlobalSplashscreen(openSplashScreen);

  const handleProfileRedirection = () => {
    const profileLocation = "/app/profile/overview";
    navigate(profileLocation, {
      replace: location.pathname === profileLocation,
    });
    handleMenuClose();
  };

  const handleRedirect = (redirect: string) => {
    navigate(`/app/${redirect}`);
    handleMenuClose();
  };

  const initContextList = (roles: string[]) => {
    const rolesConst: object[] = [];
    roles.map(
      (role: string, index: number) =>
        rolesKeys[role] &&
        rolesConst.push({
          value: rolesKeys[role],
          label: `${t(`roles.${rolesKeys[role]}`)} `,
          id: index,
        })
    );
    setContextList(rolesConst);
  };

  const handleContext = async (context: string) => {
    try {
      setOpenSplashScreen(true);
      const res = await UsersAPI.put(tokenData.user_id, { context: context });
      if (res) {
        localStorage.setItem("context", context);
        axios.defaults.headers["X-Context"] = context;
        dispatch(setContext(context));
        dispatch(handleMenu());

        navigate("/app/home");
        window.dispatchEvent(new Event("storage"));
        setTimeout(() => {
          setOpenSplashScreen(false);
        }, 2000);
      }
    } catch (err) {
      setOpenSplashScreen(false);
    }
  };

  useEffect(() => {
    tokenData?.roles?.length > 0 && initContextList(tokenData.roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData]);

  return (
    <Menu
      sx={{ mt: 5 }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box display="flex" alignItems="center" sx={{ m: 2 }}>
        <StringAvatar
          size={40}
          name={`${tokenData.firstName} ${tokenData.lastName}`}
          url={avatar}
        />
        <Typography variant="subtitle2" sx={{ ml: 1 }}>
          {tokenData?.firstName}
        </Typography>
      </Box>

      <Divider sx={{ mt: 2, mb: 2 }} />
      {menu.profile &&
        (context === "benef" ||
          context === "collab" ||
          context === "candidate") && (
          <MenuItem sx={{ m: 1 }} onClick={handleProfileRedirection}>
            <ProfileLightIcon active={location.pathname.includes("profile")} />
            <Typography
              variant="body1"
              color={location.pathname.includes("profile") ? "primary" : ""}
              sx={{
                ml: 2,
                fontWeight: "600",
                opacity: location.pathname.includes("profile") ? "1" : "0.5",
              }}
            >
              {t("profile_menu.profile")}
            </Typography>
          </MenuItem>
        )}
      {/* <MenuItem sx={{m:1}} onClick={() => handleRedirect("team")}>
        <TeamIcon active={location.pathname.includes("team")} />
        <Typography
          variant="body1"
          color={location.pathname.includes("team") ? "primary" : ""}
          sx={{
            ml: 2,
            fontWeight:"600",
            opacity: location.pathname.includes("team") ? "1" : "0.5",
          }}
        >
          {t("profile_menu.team")}
        </Typography>
      </MenuItem> */}
      {/* <MenuItem sx={{ m: 1 }} onClick={() => handleRedirect("account/data")}>
        <DataIcon active={location.pathname.includes("data")} />
        <Typography
          variant="body1"
          color={location.pathname.includes("data") ? "primary" : ""}
          sx={{
            ml: 2,
            fontWeight: "600",
            opacity: location.pathname.includes("data") ? "1" : "0.5",
          }}
        >
          {t("profile_menu.data")}
        </Typography>
      </MenuItem> */}

      {((tokenData?.roles.includes("ROLE_ADMINADVISOR") &&
        context === "advisor") ||
        (tokenData?.roles.includes("ROLE_ADMINRECRUITER") &&
          context === "recruiter")) && (
        <MenuItem sx={{ m: 1 }} onClick={() => handleRedirect("params/home")}>
          <DataIcon active={location.pathname.includes("app/params")} />
          <Typography
            variant="body1"
            color={location.pathname.includes("app/params") ? "primary" : ""}
            sx={{
              ml: 2,
              fontWeight: "600",
              opacity: location.pathname.includes("app/params") ? "1" : "0.5",
            }}
          >
            {t("profile_menu.admin")}
          </Typography>
        </MenuItem>
      )}
      {menu?.settings && (
        <>
          <MenuItem
            sx={{ m: 1 }}
            onClick={() => handleRedirect("account/params")}
          >
            <ParamsIcon active={location.pathname.includes("account/params")} />
            <Typography
              variant="body1"
              color={
                location.pathname.includes("account/params") ? "primary" : ""
              }
              sx={{
                ml: 2,
                fontWeight: "600",
                opacity: location.pathname.includes("account/params")
                  ? "1"
                  : "0.5",
              }}
            >
              {t("profile_menu.params")}
            </Typography>
          </MenuItem>
          <Divider sx={{ mt: 2 }} />
        </>
      )}

      <Box sx={{ pl: 1, pr: 1, mt: -1 }}>
        {contextList?.length > 1 && (
          <>
            <SelectComponent
              icon={<SwitchAccountOutlinedIcon />}
              onChange={handleContext}
              items={contextList}
              preSelected={context}
              fontWeight="bold"
              noBorder
              sx={{ opacity: 0.5 }}
            />
            <Divider sx={{ mb: 1 }} />
          </>
        )}
      </Box>

      <Box sx={{ m: 1 }}>
        <LogoutButton context="collab" menu />
      </Box>
    </Menu>
  );
};

export default ProfileMenu;
