import { Box, styled, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import { setAxiosAuthHeaderToken } from "../axiosConfig";
import { login } from "../redux/actions/authActions";

const Container = styled(Box)({
  fontFamily: "alphakind",
  color: "white",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  overflow: "hidden",
  width: "100%",
});

const Main = styled("main")({
  maxWidth: "450px",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  position: "relative",
  width: "100%",
});

const ContentBox = styled(Box)({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100,
  width: "100%",
});

const Logo = styled("img")({
  maxWidth: "85%",
});

const Button = styled("button")({
  boxShadow:
    "rgba(0, 0, 0, 0.6) 0px -3px 4px 0px inset, rgba(255, 119, 29, 0.96) 0px -3px 6px 0px inset, rgba(255, 193, 143, 0.8) 0px 3px 6px 0px inset, rgba(243, 99, 158, 0.3) 0px -1px 3px 0px inset",
  background:
    "url('/static/inc-game/button-line.png') center 90% / 60% no-repeat, linear-gradient(rgb(255 186 103 / 76%), rgb(255 178 103 / 0%) 67%), linear-gradient(rgb(205, 132, 54), rgb(205, 132, 54))",
  borderRadius: "12px",

  minHeight: "48px",
  padding: "10px",
  width: "250px",
  border: "none",
  cursor: "pointer",
  color: "white",
  "& > span": {
    fontWeight: "bold",
    fontSize: "20px",
  },
});

const ButtonRefuse = styled("button")({
  boxShadow:
    "rgb(0 0 0 / 60%) 0px -3px 4px 0px inset, rgb(88 88 88 / 96%) 0px -3px 6px 0px inset, rgb(116 116 116 / 80%) 0px 3px 6px 0px inset, rgb(243 99 158 / 30%) 0px -1px 3px 0px inset",
  background:
    "linear-gradient(rgb(92 92 92 / 76%), rgb(255 178 103 / 0%) 67%), linear-gradient(rgb(165 165 165), rgb(131 131 131))",
  borderRadius: "12px",

  minHeight: "48px",
  padding: "10px",
  width: "250px",
  border: "none",
  cursor: "pointer",
  color: "white",
  "& > span": {
    fontWeight: "bold",
    fontSize: "20px",
  },
});

const VideoBox = styled(Box)({
  position: "relative",
  height: "100%",
  width: "100%",
  overflow: "hidden",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
});

const VideoMain = styled("video")({
  height: "100vh",
});

const Video = styled("video")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  filter: "blur(10px) brightness(50%)",
  position: "absolute",
  top: "0px",
  left: "0px",
  zIndex: -1,
});

const WebappCGUV: React.FC<any> = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

  const [invitationToken, setInvitationToken] = useState<string | null>("");
  const [infos, setInfos] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    query.get("token") && setInvitationToken(query.get("token"));
  }, [query]);

  useEffect(() => {
    if (invitationToken) {
      if (sessionStorage.getItem(invitationToken)) {
        const data = JSON.parse(
          sessionStorage.getItem(invitationToken) ?? "{}"
        );
        sessionStorage.removeItem(invitationToken);
        if (data.firstName) {
          setInfos(data);
        } else {
          navigate("/login");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationToken]);

  const accept = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const body: any = {
          firstName: infos.firstName,
          lastName: infos.lastName,
          gender: infos.gender,
          roles: ["collab"],
          password: "",
          token: invitationToken,
          code: true,
        };

        if (infos.email) {
          body.email = infos.email;
        }

        if (infos.phone) {
          body.phone = infos.phone;
        }
        // const res = (await axios.post("users", body))?.data;
        // const login = await axios.post("code_check", {
        //   phone: res.phone,
        //   code: res.temporaryCode,
        // });

        // dispatch(setToken(login.data.token));
        // setAxiosAuthHeaderToken(login.data.token);

        await axios.post("users", body);

        dispatch(login());

        sessionStorage.clear();

        // navigate("/app/game");
      } catch (error: any) {
        console.error("ERROR", error);
      } finally {
        setLoading(false);
      }
    }

    // Create user + login => redirect to /inc-game
  };

  useLayoutEffect(() => {
    if (videoRef?.current) {
      videoRef?.current?.play();
    }
  }, [videoRef]);

  const refuse = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <Container>
        <Main>
          <ContentBox>
            <Logo src="/static/inc-game/pass-to-work-logo.png" alt="logo" />

            {!loading ? (
              <>
                <Typography sx={{ fontSize: "44px", fontFamily: "alphakind" }}>
                  Prêt ?
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "dosis",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  En continuant, vous acceptez nos <br />
                  <a
                    href="/cgu"
                    target="_blank"
                    style={{ textDecoration: "underline", color: "white" }}
                  >
                    conditions générales d’utilisation
                  </a>
                  .
                </Typography>
                <Box sx={{ position: "relative", mt: 5, cursor: "pointer" }}>
                  <Button
                    onClick={accept}
                    style={{ transform: "skew(-12deg)" }}
                  />
                  <div
                    onClick={accept}
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "alphakind",
                        fontSize: "24px",
                      }}
                    >
                      J'ACCEPTE
                    </span>
                  </div>
                </Box>
                <Box sx={{ position: "relative", mt: 5, cursor: "pointer" }}>
                  <ButtonRefuse
                    onClick={refuse}
                    style={{ transform: "skew(-12deg)" }}
                  />
                  <div
                    onClick={refuse}
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "alphakind",
                        fontSize: "24px",
                      }}
                    >
                      REFUSER
                    </span>
                  </div>
                </Box>
                <Typography
                  sx={{ fontFamily: "dosis", padding: 3, fontSize: "12px" }}
                >
                  Vous pourrez à tout moment les retrouver dans le menu du jeu.
                </Typography>
              </>
            ) : (
              <Box>
                <Typography
                  sx={{ fontSize: "44px", fontFamily: "alphakind", mt: 3 }}
                >
                  Chargement...
                </Typography>
              </Box>
            )}
          </ContentBox>
          <VideoBox>
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                background: "black",
                opacity: "0.6",
              }}
            ></Box>
            <VideoMain
              muted
              ref={videoRef}
              autoPlay
              loop
              playsInline
              src="/static/inc-game/accueil.mp4"
            />
          </VideoBox>
        </Main>
      </Container>
      <Video muted loop playsInline src="/static/inc-game/accueil.mp4" />
    </div>
  );
};

export default WebappCGUV;
