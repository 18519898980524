import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Box,
  Typography,
  useTheme,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import {
  Menu,
  Notifications,
  MoreVert,
  NotificationsOutlined,
  ChatOutlined,
  // ChatOutlined,
} from "@mui/icons-material";
import MobileMenu from "./MobileMenu";
import ProfileMenu from "./ProfileMenu";
import LanguagePopover from "./LanguagePopover";
// import Searchbar from "./searchbar/Searchbar";
import ExpandButton from "../../components/ExpandButton";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import notificationsAPI from "../../_api/_notificationsAPI";
import NotifMenu from "./NotifMenu";
import StringAvatar from "../../components/common/StringAvatar";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { requestUserIsAlive } from "../../redux/actions/chatActions";
// import Help from "./Help";
const drawerWidth = 270;

const CustomAppBar = styled(AppBar)(({ theme }: any) => ({
  boxShadow: "none",
  backgroundColor: "rgba(244, 245, 247, 0)",
  color: "grey",
  "&.notsmall": {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
}));

const MenuButton = styled(IconButton)(({ theme }: any) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const SectionDesktop = styled(Box)(({ theme }: any) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    alignItems: "center",
  },
}));

const ExpandButtonBox = styled(Box)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const SectionMobile = styled(Box)(({ theme }: any) => ({
  display: "flex",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const TopBar: React.FC<any> = ({
  handleDrawerToggle,
  small,
  fullWidthBar,
}: any) => {
  const { t } = useTranslation("common");
  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [notifAnchorEl, setNotifAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [reload, setReload] = useState(false);
  const [params, setParams] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNotifMenuOpen = Boolean(notifAnchorEl);
  const theme: any = useTheme();
  const [aliveIntervalUp, setAliveIntervalUp] = useState(false);
  const dispatch2 = useDispatch();

  const gender = useSelector((state: any) => state.user.gender);

  const context = useSelector((state: any) => state.auth.context);

  const tokenData = useSelector((state: any) => state.user, shallowEqual);

  const profilePicture = useSelector(
    (state: any) => state.user?.profilePicture
  );

  const { data } = useFetch(notificationsAPI.get({ page: 1 }));
  const notifCountCall = useFetch(
    notificationsAPI.getById("count", { reload: reload })
  );
  const chatNotif = useSelector((state: any) => state.chat.notifications);
  const parametersCall = useFetch("parameters");

  const handleNotifMenuOpen = async (event: React.MouseEvent<HTMLElement>) => {
    setNotifAnchorEl(event.currentTarget);
    try {
      await notificationsAPI.patch("read-all", {});
      setReload(!reload);
    } catch (err) {}
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotifMenuClose = () => {
    setNotifAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const [notifCount, setNotifCount] = useState(0);
  const [notifList, setNotifList] = useState([]);

  useEffect(() => {
    if (data && data["hydra:member"]) {
      setNotifList(data["hydra:member"]);
    }
  }, [data]);

  useEffect(() => {
    setNotifCount(0);
    notifCountCall.data &&
      setNotifCount(notifCountCall.data["hydra:member"][0].nbr);
  }, [notifCountCall]);

  useEffect(() => {
    if (parametersCall?.data?.["hydra:member"]) {
      setParams(parametersCall.data["hydra:member"]);
    }
  }, [parametersCall?.data]);

  useEffect(() => {
    if (
      params?.find((el: any) => el?.type === "DISPLAY_PARAMS")?.parameters
        ?.CHAT &&
      !aliveIntervalUp
    ) {
      setAliveIntervalUp(true);
      dispatch2(requestUserIsAlive());
      const local_interval = setInterval(() => {
        dispatch2(requestUserIsAlive());
      }, 1000 * 60);

      return () => {
        clearInterval(local_interval);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <CustomAppBar
      position={small ? "static" : "absolute"}
      sx={{
        zIndex: small ? 100 : 0,
        borderBottom: "1px solid #EFEFEF",
        marginLeft: fullWidthBar ? "0 !important" : "",
        width: `100% ${fullWidthBar ? " !important" : ""}`,
      }}
      className={!small ? "notsmall" : ""}
    >
      <Toolbar>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            width: small
              ? {
                  md: fullWidthBar ? "100% !important" : "calc(100% - 270px)",
                  lg: fullWidthBar ? "100% !important" : "calc(100% - 270px)",
                  sm: "100%",
                  xs: "100%",
                }
              : "100% !important",
          }}
        >
          {!fullWidthBar && (
            <MenuButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <Menu />
            </MenuButton>
          )}

          {/* <Searchbar
            state={state}
            dispatch={dispatch}
            small={small}
            context={context}
          ></Searchbar> */}

          <Box style={{ marginLeft: "auto" }}>
            <SectionDesktop>
              <LanguagePopover />

              {/* <Help /> */}

              <IconButton
                sx={{ width: "35px", height: "35px", mr: 0.75, ml: 0.75 }}
                aria-label="show notifications"
                aria-haspopup="true"
                color="inherit"
                onClick={handleNotifMenuOpen}
              >
                <Badge badgeContent={notifCount} color="error">
                  {notifCount > 0 ? (
                    <Notifications
                      color="secondary"
                      sx={{ fontSize: "22px" }}
                    />
                  ) : (
                    <NotificationsOutlined
                      color="secondary"
                      sx={{ fontSize: "22px" }}
                    />
                  )}
                </Badge>
              </IconButton>

              {(context === "benef" || context === "advisor") &&
                params?.find((el: any) => el?.type === "DISPLAY_PARAMS")
                  ?.parameters?.CHAT &&
                params?.find((el: any) => el?.type === "DISPLAY_PARAMS")
                  ?.parameters?.CHAT && (
                  <Link to="/app/chat">
                    <IconButton sx={{ width: "35px", height: "35px", mr: 1 }}>
                      <Badge badgeContent={chatNotif?.length} color="error">
                        {chatNotif?.length > 0 ? (
                          <ChatOutlined
                            color="secondary"
                            sx={{ fontSize: "22px" }}
                          />
                        ) : (
                          <ChatOutlined
                            color="secondary"
                            sx={{ fontSize: "22px" }}
                          />
                        )}
                      </Badge>
                    </IconButton>
                  </Link>
                )}
              <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={handleProfileMenuOpen}
              >
                <IconButton
                  sx={{ width: "40px", height: "40px" }}
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <StringAvatar
                    size={30}
                    name={`${tokenData.firstName} ${tokenData.lastName}`}
                    url={profilePicture}
                  />
                </IconButton>
                <Box sx={{ ml: 2.25, mr: 1 }}>
                  <ExpandButtonBox
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="subtitle2" fontWeight="500">
                      {tokenData?.firstName}
                    </Typography>
                    <ExpandButton
                      open={isMenuOpen}
                      noMarginLeft={true}
                      fontSize="20"
                    />
                  </ExpandButtonBox>
                  <Typography variant="body2" sx={{ mt: -0.5 }}>
                    {tokenData?.company_name && context === "advisor"
                      ? t(`advisor.context_${gender === "F" ? "F" : "M"}`, {
                          company: tokenData?.company_name,
                        })
                      : context === "recruiter" &&
                        t(`recruiter.context_${gender === "F" ? "F" : "M"}`, {
                          company: tokenData?.company_name,
                        })}
                  </Typography>
                </Box>
              </Box>
            </SectionDesktop>
            <SectionMobile>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <MoreVert sx={{ color: theme.palette.secondary.light }} />
              </IconButton>
            </SectionMobile>
          </Box>
        </Box>
      </Toolbar>
      {/* Laisser les conditions sur les menu pour les tests back */}
      {isMobileMenuOpen && (
        <MobileMenu
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          mobileMenuId={mobileMenuId}
          isMobileMenuOpen={isMobileMenuOpen}
          handleMobileMenuClose={handleMobileMenuClose}
          tokenData={tokenData}
          context={context}
          avatar={profilePicture}
          notifCount={notifCount}
          notifList={notifList}
        />
      )}
      {isMenuOpen && (
        <ProfileMenu
          anchorEl={anchorEl}
          menuId={menuId}
          isMenuOpen={isMenuOpen}
          handleMenuClose={handleMenuClose}
          tokenData={tokenData}
          context={context}
          avatar={profilePicture}
        />
      )}
      {isNotifMenuOpen && (
        <NotifMenu
          anchorEl={notifAnchorEl}
          isOpen={isNotifMenuOpen}
          handleMenuClose={handleNotifMenuClose}
          data={notifList}
        />
      )}
    </CustomAppBar>
  );
};

export default TopBar;
