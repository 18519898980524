import { EmojiEmotions, Send } from "@mui/icons-material";
import { Box, IconButton, OutlinedInput, styled } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { requestUserIsTyping } from "../../redux/actions/chatActions";

const TestIcon = styled(Send)({
  backgroundColor: "#4C6FFF",
  borderRadius: "100%",
  padding: "6px",
  width: "42px",
  height: "42px",
  color: "#fff",
});

const FooterContainer = styled(Box)({
  backgroundColor: "#F9FAFC",
  padding: "1.5em",
  borderRadius: "0 0 12px 12px",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
});

const FooterInput = styled(OutlinedInput)({
  borderRadius: "50px",
  width: "100%",
  backgroundColor: "#fff",
  paddingRight: 0,
  paddingTop: "6px",
  paddingBottom: "6px",
  '& [class$="MuiOutlinedInput-root"]': {
    width: "85vw",
    borderRadius: "30px",
    backgroundColor: "#fff",
    paddingRight: "10vw",
    "@media (min-width:470px)": {
      width: "140%",
    },
  },
});

const PanelFooter: React.FC<{
  typingsFirstName: any;
  textAreaRef: any;
  setEmojiPickerDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  setTextInputVal: React.Dispatch<React.SetStateAction<string>>;
  postMessage: () => void;
  textInputVal: string;
  creatingConversation: any;
  convId: any;
}> = ({
  typingsFirstName,
  textAreaRef,
  setEmojiPickerDisplay,
  setTextInputVal,
  postMessage,
  textInputVal,
  creatingConversation,
  convId,
}) => {
  const [lastStampTyping, setLastStampTyping] = useState<number>(0);
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const parseTypingsFirsName = () => {
    let res = typingsFirstName.join() + " ";

    typingsFirstName.length > 1
      ? (res += t("chat.are_typing"))
      : (res += t("chat.is_typing"));

    return res;
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && !event.ctrlKey) {
      event.preventDefault();
      postMessage();
    } else if (event.key === "Enter" && event.ctrlKey) {
      setTextInputVal(textInputVal + "\n");
    }
  };

  const showEmojiDialog = (state: any = null) => {
    setEmojiPickerDisplay((prev) => !prev);
  };

  return (
    <FooterContainer>
      {typingsFirstName.length > 0 && (
        <Box position="relative">
          <Box
            sx={{
              position: "absolute",
              left: "15px",
              top: "-65px",
              width: "max-content",
              backgroundColor: "#dfe2e387",
              borderRadius: "6px",
              padding: "2px 8px",
              color: "#979797",
            }}
          >
            {parseTypingsFirsName()}
          </Box>
        </Box>
      )}

      <FooterInput
        ref={textAreaRef}
        id="outlined-multiline-flexible"
        placeholder={t("chat.write_your_message")}
        multiline
        maxRows={2}
        onFocus={() => setEmojiPickerDisplay(false)}
        onChange={(e: any) => {
          setTextInputVal(e.target.value);
        }}
        onKeyDown={(e: any) => {
          handleKeyPress(e);
          let stamp_now = new Date().getTime();
          if (stamp_now - lastStampTyping > 3000 && !creatingConversation) {
            setLastStampTyping(stamp_now);
            dispatch(requestUserIsTyping(convId));
          }
        }}
        value={textInputVal}
        fullWidth={true}
        endAdornment={
          <Box display="flex" sx={{ marginLeft: "auto", marginRight: "6px" }}>
            <IconButton
              sx={{ padding: 0, paddingLeft: "5px" }}
              size={"large"}
              onClick={showEmojiDialog}
            >
              <EmojiEmotions fontSize={"large"} sx={{ color: "#d7dbe7" }} />
            </IconButton>
            <IconButton
              sx={{
                padding: 0,
                paddingLeft: "15px",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              onClick={postMessage}
              size={"large"}
            >
              <TestIcon id="send" fontSize={"large"} />
            </IconButton>
          </Box>
        }
      />
    </FooterContainer>
  );
};

export default PanelFooter;
