import React from "react";
import { Box, Chip, styled, Typography } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";

const BoxContainer = styled(Box)(({ theme }: any) => ({
  borderRadius: theme.shape.borderRadius10,
}));

const IncAdaptationDescription: React.FC<any> = ({ result }: any) => {
  const theme: any = useTheme();
  const { t } = useTranslation("common");

  return (
    <>
      <Box
        sx={{ display: { xs: "block", sm: "flex" }, mb: 3, width: "100%" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ ml: { xs: 0, md: 2 } }}
        >
          <Box
            sx={{
              mr: 1,
              width: "34px",
              height: "15px",
              backgroundColor: "#4C6FFF",
              borderRadius: "9px",
            }}
          />
          <Typography variant="subtitle1" fontWeight="normal">
            {t("inc.normal")}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ mr: { xs: 0, md: 2 } }}
        >
          <Box
            sx={{
              mr: 1,
              width: "34px",
              height: "15px",
              backgroundColor: "#FF6C40",
              borderRadius: "9px",
            }}
          />
          <Typography variant="subtitle1" fontWeight="normal">
            {t("inc.difficult")}
          </Typography>
        </Box>
      </Box>
      {result?.dynamic_adaptation?.normal
        ?.filter((el: any) => el.var !== "V165")
        ?.map((item: any, index: number) => (
          <Box
            sx={{ display: { xs: "block", sm: "flex" } }}
            alignItems="center"
            justifyContent="around"
            key={item?.var}
          >
            <BoxContainer
              sx={{
                mb: 4,
                p: 1,
                backgroundColor: "rgba(220, 220, 243, 0.32)",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
                sx={{ pt: 2 }}
              >
                <Chip
                  sx={{
                    backgroundColor: theme.palette.tests.blue.lighter,
                    color: theme.palette.tests.blue.main,
                    pl: 2,
                    pr: 2,
                    mb: 2,
                  }}
                  label={item?.title}
                  color="primary"
                />
                <Chip
                  sx={{
                    backgroundColor: theme.palette.tests.blue.main,
                    pl: 2,
                    pr: 2,
                    mb: 2,
                    ml: 2,
                  }}
                  label={`${item?.value}/10`}
                  color="primary"
                />
              </Box>
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ maxWidth: "50ch" }}
              >
                {item?.text}
              </Typography>
            </BoxContainer>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                p: 2,
                display: { xs: "none", sm: "block" },
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "35px",
                    width: "75px",
                    borderRadius: "7px",
                    mb: 2,
                    backgroundColor:
                      item?.value <
                      result?.dynamic_adaptation?.difficult[index].value
                        ? theme.palette.success.lighter
                        : item?.value ===
                          result?.dynamic_adaptation?.difficult[index].value
                        ? theme.palette.tests.blue.lighter
                        : theme.palette.error.lighter,
                  }}
                >
                  {item?.value <
                  result?.dynamic_adaptation?.difficult[index].value ? (
                    <TrendingUpIcon color="success" />
                  ) : item?.value ===
                    result?.dynamic_adaptation?.difficult[index].value ? (
                    <DragHandleIcon
                      sx={{ color: theme.palette.tests.blue.main }}
                    />
                  ) : (
                    <TrendingDownIcon color="error" />
                  )}
                </Box>
              </Box>
              <Typography
                textAlign="center"
                variant="body1"
                sx={{
                  color:
                    item?.value <
                    result?.dynamic_adaptation?.difficult[index]?.value
                      ? theme.palette.success.main
                      : item?.value ===
                        result?.dynamic_adaptation?.difficult[index]?.value
                      ? theme.palette.tests.blue.main
                      : theme.palette.error.main,
                }}
              >
                {t(
                  `inc.adaptation.${
                    item?.value <
                    result?.dynamic_adaptation?.difficult[index]?.value
                      ? "increased"
                      : item?.value ===
                        result?.dynamic_adaptation?.difficult[index]?.value
                      ? "unchanged"
                      : "decreased"
                  }_in_difficulty`
                )}
              </Typography>
            </Box>
            <BoxContainer
              sx={{
                mb: 4,
                p: 1,
                backgroundColor: "rgba(255, 226, 208, 0.32)",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
                sx={{ pt: 2 }}
              >
                <Chip
                  sx={{
                    backgroundColor: "rgba(255, 110, 20, 0.2)",
                    color: "#FF6E14",
                    pl: 2,
                    pr: 2,
                    mb: 2,
                  }}
                  label={
                    result?.dynamic_adaptation?.difficult?.filter(
                      (el: any) => el.var !== "V165"
                    )[index]?.title
                  }
                  color="error"
                />
                <Chip
                  sx={{
                    backgroundColor: "rgba(255, 110, 20, 1)",
                    pl: 2,
                    pr: 2,
                    mb: 2,
                    ml: 2,
                  }}
                  label={`${
                    result?.dynamic_adaptation?.difficult?.filter(
                      (el: any) => el.var !== "V165"
                    )[index]?.value
                  }/10`}
                  color="error"
                />
              </Box>
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ maxWidth: "50ch" }}
              >
                {
                  result?.dynamic_adaptation?.difficult?.filter(
                    (el: any) => el.var !== "V165"
                  )[index]?.text
                }
              </Typography>
            </BoxContainer>
          </Box>
        ))}
    </>
  );
};

export default IncAdaptationDescription;
