import {
  Modal,
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { ProfileContext } from "../../../contexts/ProfileContext";
import UsersAPI from "../../../_api/_usersAPI";
import { modalStyle } from "../../../_utils/modalStyle";
import OtherInfosFormBody from "./OtherInfosFormBody";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const OtherInfosModal: React.FC<{ open: boolean; close: any; infos: any }> = ({
  open,
  close,
  infos,
}) => {
  const { t } = useTranslation("common");
  const [langs, setLangs] = useState([]);
  const { languages, setLanguages, peopleData, setPeopleData } =
    useContext(ProfileContext);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: any) => {
    setLoading(true);
    const allFetches: any = [];
    const obj = {
      email: values.email,
      ...(values.phone && { phone: values.phone }),
    };
    allFetches.push(UsersAPI.put(tokenData?.user_id, obj));

    // Langs handler
    const langsToAdd: any = [];
    const langsToRemove: any = [];

    langs.forEach((lg: any) => {
      const res: any = languages
        .map((e: any) => e.language)
        .find((el: any) => el.id === lg.id);
      if (!res) langsToAdd.push(lg);
    });

    languages
      .map((el: any) => el.language)
      .forEach((lg: any) => {
        const res: any = langs.find((el: any) => el.id === lg.id);
        if (!res) langsToRemove.push(lg);
      });

    langsToAdd.forEach((lg: any) => {
      allFetches.push(
        axios.post("person_languages", {
          language: `/api/languages/${lg.id}`,
          level: "/api/skill_levels/1",
        })
      );
    });

    langsToRemove.forEach((lg: any) => {
      allFetches.push(
        axios.delete(
          `person_languages/${
            languages.find((el: any) => el.language.id === lg.id).id
          }`
        )
      );
    });
    // End langs handler

    Promise.all(allFetches)
      .then((res: any) => {
        // Langs response handler
        const added: any = res.filter(
          (el: any) =>
            el.config.method === "post" && el.config.url === "person_languages"
        );

        const removed: any = res.filter(
          (el: any) =>
            el.config.method === "delete" &&
            el.config.url.includes("person_languages")
        );

        const languagesCopy = [...languages];

        removed.forEach((el: any) => {
          const id = el.config.url.split("/")[1];
          const index = languagesCopy.findIndex((e: any) => e.id === id);
          languagesCopy.splice(index, 1);
        });

        const newLanguagesArray = [
          ...languagesCopy,
          ...added.map((e: any) => e.data),
        ];

        setLanguages(newLanguagesArray);
        // End langs response handler

        const userRes = res.find(
          (el: any) =>
            el.config.method === "put" && el.config.url.includes("users")
        )?.data;

        if (userRes) {
          setPeopleData({ ...peopleData, ...values });
        }

        close();
        toast.success(t("success.info_modifed"));
      })
      .catch((err) => {
        console.log(err);
        toast.error(t("errors.error_append"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLangChanges = (e: any) => {
    setLangs(e);
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          maxWidth: "500px",
        }}
      >
        <CardContent sx={{ pt: 0 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              paddingTop: 3,
              mb: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{ ml: "32px", textAlign: "center", flexGrow: 1 }}
            >
              {t("profilepage.other_infos")}
            </Typography>
            <IconButton
              aria-label="close"
              sx={{ mr: -2, mt: -2 }}
              onClick={close}
            >
              <HighlightOff />
            </IconButton>
          </Box>

          <Box>
            <OtherInfosFormBody
              submit={handleSubmit}
              loading={loading}
              onLangChanges={handleLangChanges}
              close={close}
              form={{
                email: infos.email || "",
                phone: infos.phone || "",
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OtherInfosModal;
