import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const useFetch = (
  api: string,
  cancelFirstCall = false,
  formatter: (data: any) => any = (data) => data
): any => {
  const [data, setData] = useState<any>(null);
  const [response, setResponse] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const { i18n } = useTranslation();
  const [mounted, setMounted] = useState(true);
  const [firstCall, setFirstCall] = useState(cancelFirstCall);

  const handleResponse = (res: any) => {
    setData(formatter(res?.data));
    setResponse(res);
  };

  const resetFetch = () => {
    setData(null);
    setResponse(null);
    setLoading(true);
    setError(null);
    setMounted(true);
  };

  useEffect(() => {
    setFirstCall(false);
    if (firstCall) return;

    resetFetch();

    const source = axios?.CancelToken?.source();

    (async () => {
      try {
        const res = await axios.get(api, {
          cancelToken: source?.token,
        });
        handleResponse(res);
        setLoading(false);
      } catch (err) {
        setError(error);
        if (!error?.message) {
          setLoading(false);
        }
      }
    })();

    return () => {
      setMounted(false);
      source?.cancel("Cancelled");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, i18n.language]);
  if (mounted) return { data, loading, error, response };
};
export default useFetch;
