import {
  Typography,
  Stack,
  Box,
  Button,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import NextStepButton from "../buttons/NextStepButton";

const Container = styled(Box)({
  maxWidth: "560px",
});

const Title = styled(Typography)({ fontSize: "40px" });

const BodyContainer = styled(Stack)({ maxWidth: "490px" });

const AlignCenterBox = styled(Box)({ display: "flex", alignItems: "center" });

const CutomButton: any = styled(Button, {
  shouldForwardProp: (props) => props !== "type",
})(({ theme, type }: any) => ({
  width: type === "cf" ? "210px" : "100%",
  height: type === "cf" ? "48px" : "60px",
  borderRadius: "10px",
  fontSize: "14px",
  backgroundImage: type === "cf" ? theme.palette.gradients.cf_linear : "auto",
}));

const IntoText = styled("div")(({ theme }: any) => ({
  width: "120px",
  height: "30px",
  borderRadius: "6px",
  padding: "3px",
  backgroundColor: theme.palette.tests.cf_light,
}));

const CulturefitIntro: React.FC<{
  nextStep: any;
  skipTest?: any;
  onboarding?: boolean;
  onboardingStep?: number;
  canSkip?: boolean;
}> = ({ nextStep, skipTest, onboarding, onboardingStep, canSkip }) => {
  const { t } = useTranslation("common");

  return onboarding ? (
    <Container>
      <Title variant="h2">
        {onboarding && onboardingStep ? `0${onboardingStep}. ` : ""}
        {t("culturefit.intro.title")}
      </Title>
      <BodyContainer sx={{ mb: 4, mt: 1 }} spacing={3}>
        <AlignCenterBox>
          <Typography variant="h4">🚀</Typography>
          <Typography variant="body1" sx={{ ml: 2 }}>
            {t("culturefit.intro.text_1")}
          </Typography>
        </AlignCenterBox>
        <AlignCenterBox>
          <Typography variant="h4">😊</Typography>
          <Typography variant="body1" sx={{ ml: 2 }}>
            {t("culturefit.intro.text_2")}
          </Typography>
        </AlignCenterBox>
        <AlignCenterBox>
          <Typography variant="h4">👑</Typography>
          <Typography variant="body1" sx={{ ml: 2 }}>
            {t("culturefit.intro.text_3")}
          </Typography>
        </AlignCenterBox>
      </BodyContainer>
      <Box sx={{ display: "flex", gap: 2 }}>
        <CutomButton variant="contained" onClick={() => nextStep()}>
          {t("tests.page.begin")}
        </CutomButton>
        <NextStepButton
          text={canSkip ? undefined : t("btn.prev")}
          onClick={skipTest}
        />
      </Box>
    </Container>
  ) : (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <IntoText>
            <Typography fontWeight="bold" variant="body1">
              {t("tests.page.label")}
            </Typography>
          </IntoText>

          <Typography
            sx={{ mt: 3, maxWidth: "55ch" }}
            variant="body1"
            fontWeight="bold"
          >
            {t("culturefit.intro.text_1")}
          </Typography>
          <Typography sx={{ mt: 2, maxWidth: "75ch" }} variant="body1">
            {t("culturefit.intro.text_2")}
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body1">
            {t("culturefit.intro.text_3")}
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body1"
            fontWeight="bold"
            color="primary"
          >
            {t("tests.page.ready")}
          </Typography>
          <CutomButton
            type={"cf"}
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => nextStep()}
          >
            {t("tests.page.begin_test")}
          </CutomButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CulturefitIntro;
