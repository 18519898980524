import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import GenderSelect from "../../components/common/GenderSelect";
import { useTranslation } from "react-i18next";
import CheckInputValid from "../../components/common/CheckValidInput";
import ControlledTextField from "../../components/forms/ControlledTextField";
import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  TextField,
  styled,
} from "@mui/material";
import ControlledEmailInput from "../../components/forms/ControlledEmailInput";
import ControlledPhoneInput from "../../components/forms/ControlledPhoneInput";
import { ControlledPasswordInput } from "../../components/forms/ControlledPasswordInput";
import slugify from "slugify";
import DemoError from "./DemoError";
import DemoFinished from "./DemoFinished";
import axios from "axios";

interface IFormInput {
  firstName: string;
  lastName: string;
  email?: string;
  gender: string;
  password: string;
  confirmPassword: string;
  phone?: string;
  share?: string;
  company: string;
  position: string;
  slug: string;
}

const Container = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px 20px;
  grid-auto-rows: minmax(50px, auto);
  margin-bottom: 5px;
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const DemoForm: React.FC<any> = ({ type }) => {
  const { t } = useTranslation("common");
  const [slug, setSlug] = useState("");
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      company: "",
      position: "",
      slug: "",
    },
  });

  const regex = /[^A-Za-z0-9-]/g;
  const onChangeSlug = (event: any, onChange: any) => {
    const value = event.target.value.toLowerCase();
    onChange(value.replaceAll(regex, ""));
  };
  const [hasCompany, setHasCompany] = useState(true);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const sub = watch((values) => {
      const { company } = values;
      setHasCompany(!Boolean(company?.length));
      let slugified = slugify(company ?? "", { lower: true, strict: true });
      setSlug(slugified);
    });

    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue("slug", slug);
  }, [setValue, slug]);

  const onSubmit: SubmitHandler<IFormInput> = async (values: IFormInput) => {
    const data = { ...values, type };
    const body = { data };

    const env = process.env.REACT_APP_ENV;

    switch (env) {
      case "dev":
        data.slug += "-develop";
        break;
      case "release":
        data.slug += "-release";
        break;
      case "review":
        data.slug += "-review";
        break;
      default:
        break;
    }

    try {
      await axios.post("user_requests", body);
      setLoading(false);
      setStatus("finished");
    } catch (err) {
      setLoading(false);
      setStatus("error");
    }
  };

  return (
    <>
      {!status && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name="gender"
            control={control}
            rules={{
              required: { value: true, message: "errors.required" },
            }}
            render={({ field: { value, onChange } }) => (
              <>
                <GenderSelect
                  gender={value}
                  setGender={onChange}
                  littleMargin
                />

                <FormHelperText
                  error
                  sx={{ marginTop: "-10px !important", textAlign: "center" }}
                >
                  {t(errors?.gender ? "errors.required" : " ")}
                </FormHelperText>
              </>
            )}
          />

          <Container style={{ marginTop: "10px" }}>
            <ControlledTextField
              label={t("onboarding.form.firstname")}
              control={control}
              name="firstName"
              props={{
                endAdornment: !errors["firstName"] &&
                  getValues("firstName") && <CheckInputValid />,
              }}
              required
              helperSpacing
            />
            <ControlledTextField
              label={t("onboarding.form.lastname")}
              control={control}
              name="lastName"
              props={{
                endAdornment: !errors["lastName"] && getValues("lastName") && (
                  <CheckInputValid />
                ),
              }}
              required
              helperSpacing
            />
          </Container>

          <Container>
            <ControlledEmailInput
              control={control}
              showCheck
              name="email"
              required
            />

            <ControlledPhoneInput
              control={control}
              name="phone"
              required
              inputStyle={{ width: "100%" }}
            />
          </Container>

          <Container>
            <ControlledTextField
              label={"Entreprise"}
              control={control}
              name="company"
              props={{
                endAdornment: !errors["company"] && getValues("company") && (
                  <CheckInputValid />
                ),
              }}
              required
              helperSpacing
            />
            <ControlledTextField
              label={"Fonction"}
              control={control}
              name="position"
              props={{
                endAdornment: !errors["position"] && getValues("position") && (
                  <CheckInputValid />
                ),
              }}
              required
              helperSpacing
            />
          </Container>

          <Controller
            control={control}
            name="slug"
            rules={{
              required: {
                value: true,
                message: "errors.required",
              },
              pattern: {
                value: /^(?!.*-(develop|release|review)$).*/gm,
                message: "errors.invalid_slug",
              },
            }}
            render={({
              field: { value, onChange, onBlur },
              formState: { errors },
            }) => (
              <>
                <TextField
                  onBlur={onBlur}
                  disabled={hasCompany}
                  label="Url de connexion"
                  id="outlined-start-adornment"
                  value={value}
                  error={Boolean(errors?.slug)}
                  sx={{ mb: 2, width: "100%" }}
                  onChange={(event: any) => onChangeSlug(event, onChange)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        .passtowork.fr
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText
                  sx={{ marginTop: "-15px", ml: 3 }}
                  error={Boolean(errors?.slug)}
                  id={`slug`}
                >
                  {t(errors?.slug?.message ?? " ")}
                </FormHelperText>
              </>
            )}
          />

          <ControlledPasswordInput
            name="password"
            label="password"
            control={control}
            showRules
          />
          <ControlledPasswordInput
            name="confirmPassword"
            label="confirm_password_required"
            control={control}
            getValues={getValues}
            required
          />

          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              disabled={loading}
              variant="contained"
              size="large"
              type="submit"
            >
              {t("btn.confirm")}
            </Button>
          </Box>
        </form>
      )}

      {status === "error" && <DemoError />}
      {status === "finished" && <DemoFinished />}
    </>
  );
};

export default DemoForm;
