import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayCollabs: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">{t("admin.params.collab.title")}</Typography>

      <FormGroup>
        <FormControlLabel
          value={param.COLLAB_ETP}
          onChange={(e: any) =>
            handleChanges({ ...param, COLLAB_ETP: e.target.checked })
          }
          control={<Checkbox checked={param.COLLAB_ETP} />}
          label={t("admin.params.collab.display_all")}
        />
        <FormControlLabel
          value={param.COLLAB_RH_PROX}
          onChange={(e: any) =>
            handleChanges({ ...param, COLLAB_RH_PROX: e.target.checked })
          }
          control={<Checkbox checked={param.COLLAB_RH_PROX} />}
          label={t("admin.params.collab.display_prox")}
        />
      </FormGroup>
      <Typography sx={{ mt: 1 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
    </div>
  );
};

export default AdminDisplayCollabs;
