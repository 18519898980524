export const analyticsScript = ({ googleId }) => {

  if (googleId) {
    const scriptTagElement = document.querySelector('#googletagmanager');

    if ((scriptTagElement && !scriptTagElement?.src?.includes(googleId)) || !scriptTagElement) {
      const scriptTag = scriptTagElement ?? document.createElement("script");
      scriptTag.setAttribute('id', "googletagmanager");
      scriptTag.setAttribute(
        "src",
        `https://www.googletagmanager.com/gtag/js?id=${googleId}`
      );
      scriptTag.async = true;
      scriptTag.setAttribute("async", "async");

      if (!scriptTagElement) {
        document.head.appendChild(scriptTag);
      }

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", googleId);
    }

  }


  // Clarity
  // (function (c, l, a, r, i, t, y) {
  //   c[a] =
  //     c[a] ||
  //     function () {
  //       (c[a].q = c[a].q || []).push(arguments);
  //     };
  //   t = l.createElement(r);
  //   t.async = 1;
  //   t.src = "https://www.clarity.ms/tag/" + i;
  //   y = l.getElementsByTagName(r)[0];
  //   y.parentNode.insertBefore(t, y);
  // })(window, document, "clarity", "script", "7kj2b236vj");

  if (process.env.REACT_APP_GOM === "true") {
    var el = document.createElement("script");
    el.setAttribute("src", "https://static.axept.io/sdk-slim.js");
    el.setAttribute("type", "text/javascript");
    el.setAttribute("async", "true");
    el.setAttribute("data-id", "5eccc9d2189c3a66cfd21e5e");
    el.setAttribute("data-cookies-version", "gom-base");
    if (document.body !== null) {
      document.body.appendChild(el);
    }
  }

  // var chatbot = document.createElement("script");
  // chatbot.setAttribute("src", "//js-eu1.hs-scripts.com/25792701.js");
  // chatbot.setAttribute("type", "text/javascript");
  // chatbot.setAttribute("async", "true");
  // chatbot.setAttribute("defer", "true");
  // chatbot.setAttribute("id", "hs-script-loader");
  // if (document.body !== null) {
  //   document.body.appendChild(chatbot);
  // }


  // setTimeout(() => {
  //   const div = document.getElementById('axeptio_overlay');
  //   console.log(div);
  // }, 2000);
};
