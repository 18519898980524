import { Card, Chip, Typography, Divider, Slider, styled } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import LongText from "../LongText";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const PopperItem = styled(Typography)(({ theme }: any) => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: "20px",
  paddingRight: "20px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.background.neutral,
  },
}));

const CustomPopper: React.FC<{
  options: any;
  selectedLocations: any;
  handleRemove: any;
  handleRangeLocation: any;
  displayRange: any;
  onSelectLocation: any;
  rangeLocation: any;
  multi: any;
  value: any;
}> = ({
  options,
  selectedLocations,
  handleRemove,
  handleRangeLocation,
  displayRange,
  onSelectLocation,
  rangeLocation,
  multi,
  value,
}) => {
  const marks = [
    {
      value: 0,
      label: "0km",
    },
    {
      value: 100,
      label: "200km",
    },
  ];

  const { t } = useTranslation("common");

  return (
    <div onMouseDown={(e) => e.preventDefault()}>
      {(options.length > 0 || (multi && selectedLocations.length > 0)) && (
        <Card
          sx={{ borderRadius: "5px !important" }}
          onMouseDown={(e) => e.preventDefault()}
        >
          {multi && (
            <Box display="flex" flexDirection="column" sx={{ mt: 1 }}>
              {selectedLocations.map((location: any, index: number) => (
                <Chip
                  key={location?.id}
                  label={<LongText text={location?.place_name} maxChar={28} />}
                  sx={{ ml: 2, mt: 1, maxWidth: "250px" }}
                  color="primary"
                  clickable
                  onDelete={() => handleRemove(index)}
                  deleteIcon={<CloseIcon />}
                />
              ))}
            </Box>
          )}
          {displayRange &&
            ((selectedLocations.length === 1 && multi) ||
              (!multi && value.label)) && (
              <Box
                sx={{ m: 2 }}
                display="flex"
                flexDirection="column"
                onMouseDown={(e) => e.preventDefault()}
              >
                <Typography variant="h6">{t("in_range")}:</Typography>
                <Slider
                  onMouseDown={(e) => e.preventDefault()}
                  onChange={handleRangeLocation}
                  sx={{ mt: 1, ml: 1, width: "275px" }}
                  defaultValue={rangeLocation}
                  aria-labelledby="discrete-slider-custom"
                  step={5}
                  marks={marks}
                />
                <Typography sx={{ mt: -2 }} textAlign="center" variant="h6">
                  {rangeLocation * 2} km
                </Typography>
              </Box>
            )}
          {displayRange &&
            (selectedLocations.length > 0 || (!multi && value.label)) &&
            options.length > 0 && <Divider sx={{ mt: 2, mb: 2 }} />}
          <Box sx={{ mt: 2, mb: 2 }}>
            {options.map((location: any, index: number) => (
              <PopperItem
                key={location?.id}
                onMouseDown={() => onSelectLocation(location, index)}
                variant="body1"
              >
                {location?.place_name}
              </PopperItem>
            ))}
          </Box>
        </Card>
      )}
    </div>
  );
};

export default CustomPopper;
