import { FormGroup, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayJobs: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">{t("admin.params.jobs.title")}</Typography>
      <FormGroup sx={{ mt: 3 }}>
        <TextField
          sx={{ maxWidth: "250px" }}
          label={t("admin.params.jobs.menu")}
          value={param.MENU_NAME}
          onChange={(event: any) =>
            handleChanges({ ...param, MENU_NAME: event.target.value })
          }
        />
        <TextField
          sx={{ mt: 2, maxWidth: "600px" }}
          value={param.EXPLAIN_TEXT}
          multiline
          onChange={(event: any) =>
            handleChanges({ ...param, EXPLAIN_TEXT: event.target.value })
          }
          label={t("admin.params.jobs.explain_text")}
          minRows={2}
          variant="outlined"
        />
      </FormGroup>
      <Typography sx={{ mt: 2 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
    </div>
  );
};

export default AdminDisplayJobs;
