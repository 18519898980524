import { CircularProgress, Typography, Box, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import palette from "../../theme/palette";

const Circle = styled(CircularProgress)({
  circle: {
    stroke: "url(#linearColors)",
    borderRadius: 15,
    strokeLinecap: "round",
  },
});

const CircularProgressLabel: React.FC<{
  progress: number;
  dbClickEvent?: any;
}> = ({ progress, dbClickEvent }) => {
  const { t } = useTranslation("common");
  const dbClick = () => {
    dbClickEvent();
  };
  return (
    <Box
      position="relative"
      display="inline-flex"
      sx={{ justifyContent: "center" }}
      onDoubleClick={dbClick}
    >
      <svg width="0" height="140">
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="20%" stopColor="#505DFF" />
          <stop offset="90%" stopColor="#01BDFF" />
        </linearGradient>
      </svg>
      <Circle
        size={140}
        variant="determinate"
        value={Math.round(progress)}
        thickness={2}
        color="primary"
        sx={{ position: "absolute", left: 0, zIndex: 10 }}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: `${palette.grey[300]} !important`,
        }}
        size={140}
        thickness={2}
        value={100}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{ opacity: 0.6 }}
      >
        <Typography
          variant="h3"
          fontWeight="500"
          component="div"
        >{`${Math.round(progress)}%`}</Typography>
        <Typography variant="caption" component="div">
          {t("big5.test.completeness")}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressLabel;
