import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modalStyle } from "../../../../_utils/modalStyle";
import { HighlightOff } from "@mui/icons-material";
import InviteCandidateForm from "../../../common/form/InviteCandidateForm";
import invitationsAPI from "../../../../_api/_invitationsAPI";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import RecruitersAPI from "../../../../_api/_recruitersAPI";
import { toast } from "react-toastify";

const AdminInviteUserModal: React.FC<{
  open: boolean;
  showRoles: boolean;
  close: () => void;
  reloadCall?: () => void;
}> = ({ open, showRoles, close, reloadCall }) => {
  const { t } = useTranslation("common");
  const [showAlertRole, setShowAlertRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const context = useSelector((state: any) => state.auth.context);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const hasCre = false;

  const handleSubmit = async (values: any) => {
    const roles = [];
    values.roles.rh && roles.push("ROLE_RH");
    values.roles.manager && roles.push("ROLE_MANAGER");
    values.roles.admin && roles.push("ROLE_ADMIN");
    values.roles.adminRh && roles.push("ROLE_ADMINRH");
    values.roles.recruiter && roles.push("ROLE_RECRUITER");
    values.roles.adminRecruiter && roles.push("ROLE_ADMINRECRUITER");
    values.roles.advisor && roles.push("ROLE_ADVISOR");
    values.roles.adminAdvisor && roles.push("ROLE_ADMINADVISOR");
    values.roles.cre && roles.push("ROLE_CRE");
    roles?.length === 0 && setShowAlertRole(true);
    if (roles?.length > 0) {
      const res = await axios.get(
        RecruitersAPI.getById(`${tokenData.recruiter_id}/isinpool`, {
          candidate: values?.email,
        })
      );
      setShowAlertRole(false);
      setLoading(true);
      if (!res?.data) {
        try {
          await invitationsAPI.post({
            gender: values.gender,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            roles: roles,
            parameters: { context: "admin" },
          });
          setLoading(false);
          reloadCall?.();
          toast.success(t("advisor.folder.invitation_sent"));
          close();
        } catch (err) {
          toast.error(t("errors.user_already_invited"));
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error(t("errors.user_already_registerd"));
      }
    }
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "500px" },
        }}
      >
        <Box sx={{ width: "100%", display: "flex" }}>
          <IconButton
            onClick={close}
            sx={{ ml: "auto", mt: 1, mr: 1 }}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent sx={{ mt: -6 }}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            {t(`admin.users.invite_form.${context}`)}
          </Typography>
          <InviteCandidateForm
            context={context}
            showAlertRole={showAlertRole}
            close={close}
            loading={loading}
            showRoles={showRoles}
            submit={handleSubmit}
            form={{
              gender: "",
              firstName: "",
              lastName: "",
              email: "",
              roles:
                context === "recruiter"
                  ? {
                      recruiter: true,
                      adminRecruiter: false,
                      ...(hasCre ? { cre: false } : {}),
                    }
                  : context === "advisor"
                  ? {
                      advisor: true,
                      adminAdvisor: false,
                      ...(hasCre ? { cre: false } : {}),
                    }
                  : {
                      rh: false,
                      manager: false,
                      admin: false,
                      adminRh: false,
                      ...(hasCre ? { cre: false } : {}),
                    },
            }}
          />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AdminInviteUserModal;
