const rollbarConfig = {
  accessToken:
    process.env.REACT_APP_ENV === "review" ||
    process.env.REACT_APP_ENV === "prod"
      ? "65ee66d053a146d48591e75b79b687dd"
      : undefined,
  autoInstrument:
    process.env.REACT_APP_ENV === "review" ||
    process.env.REACT_APP_ENV === "prod",
  payload: {
    environment:
      process.env.REACT_APP_ENV === "prod"
        ? "prod-front"
        : process.env.REACT_APP_ENV === "review"
        ? "review-front"
        : "",
  },
};

export default rollbarConfig;
