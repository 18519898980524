import { styled } from "@mui/material/styles";
import { Box, Chip, Typography } from "@mui/material";
import React from "react";

const BoxCtn: any = styled(Box, {
  shouldForwardProp: (props) => props !== "compare",
})(({ theme, compare }: any) => ({
  width: "300px",
  height: "200px",
  borderRadius: theme.shape.borderRadius10,
  backgroundColor: compare
    ? "rgba(175, 175, 175, 0.15)"
    : "rgba(220, 220, 243, 0.32)",
}));

const IncAdaptationItem = ({ item, compare }: any) => {
  return (
    <Box
      key={item.var}
      display="flex"
      sx={{ justifyContent: { xs: "center", sm: "end" } }}
    >
      <BoxCtn
        sx={{
          mb: 4,
          p: 1,
        }}
        compare={compare}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ pt: 2 }}
        >
          <Chip
            sx={{
              backgroundColor: compare
                ? "rgba(175, 175, 175, 0.32)"
                : "primary.lighter",
              color: compare ? "secondary.main" : "primary.main",
              pl: 2,
              pr: 2,
              mb: 2,
            }}
            key={item.id}
            label={item.title}
            color={compare ? "secondary" : "primary"}
          />
          <Chip
            sx={{
              backgroundColor: compare ? "#AFAFAF" : "primary.main",
              pl: 2,
              pr: 2,
              mb: 2,
              ml: 2,
            }}
            key={item.id}
            label={`${item.work_value || item.value}/10`}
            color={compare ? "secondary" : "primary"}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ maxWidth: "31ch" }}
          >
            {item.text}
          </Typography>
        </Box>
      </BoxCtn>
    </Box>
  );
};

export default IncAdaptationItem;
