import { Box, Divider, Skeleton, Typography } from "@mui/material";
import React from "react";

const CheckboxSkeleton = ({ textSize }: { textSize: number }) => {
  return (
    <Typography sx={{ height: "42px", display: "flex", alignItems: "center" }}>
      <Skeleton width={`${textSize}px`} />
    </Typography>
  );
};

const ParamsPageSkeleton = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        <Skeleton width="200px" />
      </Typography>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="53px"
        sx={{ borderRadius: "7px" }}
      />
      <Typography sx={{ mt: 2 }}>
        <Skeleton width="50px" />
      </Typography>
      <Box display="flex" alignItems="center">
        <Skeleton
          variant="rectangular"
          height="35px"
          width="150px"
          sx={{ borderRadius: "7px", mt: 1, mr: 2 }}
        />
      </Box>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="53px"
        sx={{ borderRadius: "7px", mt: 2 }}
      />
      <Typography
        sx={{ height: "42px", display: "flex", alignItems: "center" }}
      >
        <Skeleton width="180px" />
      </Typography>
      <Typography sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
        <Skeleton width="280px" />
      </Typography>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h4">
        <Skeleton width="80px" />
      </Typography>
      <CheckboxSkeleton textSize={200} />
      <CheckboxSkeleton textSize={180} />
      <CheckboxSkeleton textSize={150} />
      <CheckboxSkeleton textSize={250} />
      <Typography>
        <Skeleton width="100%" />
        <Skeleton width="50px" />
      </Typography>
      <Typography sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
        <Skeleton width="280px" />
      </Typography>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h4">
        <Skeleton width="80px" />
      </Typography>
      <Typography>
        <Skeleton width="350px" />
      </Typography>
      <CheckboxSkeleton textSize={150} />
      <CheckboxSkeleton textSize={130} />
      <CheckboxSkeleton textSize={110} />
      <CheckboxSkeleton textSize={250} />
      <Typography sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
        <Skeleton width="280px" />
      </Typography>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h4">
        <Skeleton width="60px" />
      </Typography>
      <Typography>
        <Skeleton width="220px" />
      </Typography>
      <CheckboxSkeleton textSize={150} />
      <CheckboxSkeleton textSize={130} />
      <CheckboxSkeleton textSize={110} />
      <CheckboxSkeleton textSize={250} />
      <Typography sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Skeleton width="280px" />
      </Typography>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h4">
        <Skeleton width="155px" />
      </Typography>
      <CheckboxSkeleton textSize={140} />
      <CheckboxSkeleton textSize={145} />
      <Typography sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Skeleton width="280px" />
      </Typography>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h4">
        <Skeleton width="165px" />
      </Typography>
      <CheckboxSkeleton textSize={175} />
      <CheckboxSkeleton textSize={300} />
      <Typography sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
        <Skeleton width="280px" />
      </Typography>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h4">
        <Skeleton width="120px" />
      </Typography>
      <CheckboxSkeleton textSize={280} />
      <CheckboxSkeleton textSize={250} />
      <Typography sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
        <Skeleton width="280px" />
      </Typography>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h4">
        <Skeleton width="80px" />
      </Typography>
      <CheckboxSkeleton textSize={280} />
      <CheckboxSkeleton textSize={250} />
      <Skeleton
        variant="rectangular"
        height="35px"
        width="200px"
        sx={{ borderRadius: "7px" }}
      />
      <Typography sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Skeleton width="280px" />
      </Typography>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h4">
        <Skeleton width="80px" />
      </Typography>
      <Typography sx={{ mt: 2 }}>
        <Skeleton width="220px" />
      </Typography>
      <CheckboxSkeleton textSize={130} />
      <CheckboxSkeleton textSize={75} />
      <Typography sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
        <Skeleton width="280px" />
      </Typography>
    </Box>
  );
};

export default ParamsPageSkeleton;
