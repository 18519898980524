import { Hidden, Drawer, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import SideBar from "./SideBar";

const drawerWidth = 270;

const Nav = styled("nav")(({ theme }: any) => ({
  [theme.breakpoints.up("md")]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

const DrawerPaper = styled(Drawer)(({ theme }: any) => ({
  "& .MuiDrawer-paper": {
    "&.collab, &.benef, &.candidate": {
      background: theme.palette.contextColors.collab.background,
      color: theme.palette.contextColors.collab.font,
    },
    "&.recruiter": {
      background: theme.palette.contextColors.recruiter.background,
      color: theme.palette.contextColors.recruiter.font,
    },
    border: "none",
    width: drawerWidth,
    background: theme.palette.contextColors.advisor.background,
    color: theme.palette.contextColors.advisor.font,
    //
    "&::-webkit-scrollbar": {
      background: theme.palette.grey[400],
      width: 1,
      height: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "grey",
      borderRadius: "10px",
      "&:hover": {
        background: theme.palette.primary.light,
      },
    },
  },
}));

const CustomDrawer: React.FC<any> = ({
  container,
  theme,
  mobileOpen,
  handleDrawerToggle,
  closeDrawer,
}: any) => {
  const context = useSelector((state: any) => state.auth.context);

  return (
    <Nav aria-label="navigation">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <DrawerPaper
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: `${context}`,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <SideBar close={closeDrawer} />
        </DrawerPaper>
      </Hidden>
      <Hidden mdDown implementation="css">
        <DrawerPaper
          classes={{
            paper: `${context}`,
          }}
          variant="permanent"
          open
        >
          <SideBar close={closeDrawer} />
        </DrawerPaper>
      </Hidden>
    </Nav>
  );
};

export default CustomDrawer;
