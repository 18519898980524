import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Collapse,
  Divider,
  IconButton,
  Badge,
  styled,
} from "@mui/material";
import {
  ArrowDropDown,
  ArrowRight,
  PersonAddAlt,
  Add,
  HighlightOff,
  ArrowBack,
} from "@mui/icons-material";
import { requestUpdateConversation } from "../../redux/actions/chatActions";
import { useDispatch, useSelector } from "react-redux";
import chatService from "../../services/chat.service";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import StringAvatar from "../common/StringAvatar";
import ConversationEditSummary from "./ConversationEditSummary";

const CardMember = styled(Box, {
  shouldForwardProp: (props) => props !== "clickable",
})<{ clickable?: boolean }>(({ theme, clickable }: any) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px",
  paddingRight: "40px", //see admin badge
  paddingLeft: "16px",
  borderRadius: theme.shape.borderRadius8,
  border: "1px solid #fff",
  transition: "all 0.25s ease-in-out",
  "&:hover": {
    backgroundColor: clickable ? theme.palette.grey[200] : "auto",
  },
}));

const AddListContainer = styled(Box)({
  maxHeight: "300px",
  overflow: "auto",
});

const SectionTitle = styled(Box)({
  display: "flex",
  marginTop: 2,
  alignItems: "center",
  padding: "16px",
});

const SideBarIcon = styled(({ icon, ...props }: any) => icon(props))(
  ({ theme }: any) => ({
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius8,
    fontSize: "28px",
    padding: "5px",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  })
);

const Header = styled(Container)(({ theme }: any) => ({
  textAlign: "center",
  paddingTop: theme.spacing(3),
}));

const Light: any = styled(Typography)(({ theme }: any) => ({
  position: "absolute",
  width: "15px",
  height: "15px",
  border: "solid 3px #fff",
  borderRadius: theme.shape.borderRadius48,
  left: "28px",
  top: "25px",
  alignmentBaseline: "baseline",
  zIndex: 1200,
}));

const ConversationEditPanel: React.FC<any> = ({ conversation, closeSelf }) => {
  const { t }: any = useTranslation("common");

  const context = useSelector((state: any) => state.auth.context);
  // TODO !replaceing benef by chatablePersons
  const [chatablePersonsList, setChatablePersonsList] = useState<any>(null);

  const dispatch = useDispatch();
  const [memberListOpen, setMemberListOpen] = useState(true);
  const [addListOpen, setAddListOpen] = useState(false);
  const [addListMembers, setAddListMembers] = useState([]);

  const toggleMemberList = () => {
    setMemberListOpen((prev) => !prev);
  };

  const toggleAddList = () => {
    if (context === "benef") {
      toast.warning(t("chat.cant_update_conv"));
    } else {
      setAddListOpen((prev: any) => !prev);
    }
  };

  const withIcon =
    (icon: "person" | "highlight" | "arrowDown" | "arrowRight" | "add") =>
    (props: any) => {
      switch (icon) {
        case "person":
          return <PersonAddAlt {...props} />;
        case "highlight":
          return <HighlightOff {...props} />;
        case "arrowDown":
          return <ArrowDropDown {...props} />;
        case "arrowRight":
          return <ArrowRight {...props} />;
        case "add":
          return <Add {...props} />;
      }
    };

  useEffect(() => {
    chatService
      .getConversationCandidatsList(context, { fct: () => toast.error("-") })
      .then((res: any) => {
        setChatablePersonsList(res);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useEffect(() => {
    if (!chatablePersonsList) return;
    let tmpConvMember = conversation?.members;

    let chatablePersons = chatablePersonsList
      .map((invitable: any) => {
        return tmpConvMember?.find((el: any) => {
          return (
            el["@id"] === invitable?.candidate["@id"] ||
            el?.id === invitable?.candidate?.id
          );
        })
          ? null
          : invitable;
      })
      .filter((x: any) => x !== null);
    setAddListMembers(chatablePersons);
  }, [chatablePersonsList, conversation?.members]);

  const addMemberToConv = (element: any, member: any) => {
    let newMemberSet = conversation?.members.map((m: any) => {
      return m["@id"] ?? m?.id;
    });
    newMemberSet.push(member?.candidate["@id"]);

    const reqBody = {
      submit_members: newMemberSet,
    };

    dispatch(requestUpdateConversation(reqBody, conversation));
  };

  return (
    <Header>
      <Box
        sx={{
          marginTop: "5em",
          display: "flex",
          padding: "16px",
          alignItems: "center",
        }}
      >
        <IconButton
          component="span"
          sx={{
            color: "#fff",
            background: "#D6D6D6 0% 0% no-repeat padding-box",
          }}
          onClick={closeSelf("right", false)}
        >
          <ArrowBack />
        </IconButton>
        <Typography
          variant="subtitle1"
          sx={{ color: "#2E3A59", fontWeight: "bold", ml: 1 }}
        >
          {t("chat.title_conv_details")}
        </Typography>
      </Box>

      <ConversationEditSummary conversation={conversation} />

      <SectionTitle>
        <Typography variant="subtitle2">Membres</Typography>
        <Box sx={{ marginLeft: "auto", display: "flex" }}>
          {memberListOpen ? (
            <Box sx={{ padding: "8px" }} onClick={toggleAddList}>
              {!addListOpen ? (
                <SideBarIcon icon={withIcon("person")} />
              ) : (
                <SideBarIcon icon={withIcon("highlight")} />
              )}
            </Box>
          ) : (
            ""
          )}
          <Box sx={{ padding: "8px" }} onClick={toggleMemberList}>
            {memberListOpen ? (
              <SideBarIcon icon={withIcon("arrowDown")} />
            ) : (
              <SideBarIcon icon={withIcon("arrowRight")} />
            )}
          </Box>
        </Box>
      </SectionTitle>
      <Collapse in={memberListOpen}>
        <Collapse in={addListOpen}>
          <AddListContainer>
            {addListMembers?.map((invitableMember: any) => {
              const fullName: any = `${invitableMember?.candidate?.firstName} ${invitableMember?.candidate?.lastName}`;
              return (
                <CardMember key={invitableMember?.id} clickable>
                  <Box sx={{ position: "relative" }}>
                    <StringAvatar
                      size={40}
                      name={fullName}
                      url={invitableMember?.candidate?.image?.contentUrl}
                    />
                  </Box>
                  <Box sx={{ marginLeft: "5px", textAlign: "left" }}>
                    <Typography component="p" sx={{ fontWeight: 700 }}>
                      {fullName}
                    </Typography>
                  </Box>
                  <Box sx={{ marginLeft: "auto", display: "flex" }}>
                    <Box
                      sx={{ padding: "8px" }}
                      onClick={(el: any) =>
                        addMemberToConv(el, invitableMember)
                      }
                    >
                      <SideBarIcon icon={withIcon("add")} />
                    </Box>
                  </Box>
                </CardMember>
              );
            })}
          </AddListContainer>
          <Divider sx={{ mt: 1, mb: 1 }} />
        </Collapse>
        <Box>
          {conversation?.members?.map((member: any) => {
            let mbIsAdmin = member?.id === conversation.creator;
            return (
              <CardMember key={member?.["@id"] ?? member?.id}>
                <Box sx={{ position: "relative" }}>
                  <StringAvatar
                    size={40}
                    name={member?.fullName}
                    url={member?.image?.contentUrl || member?.image}
                  />
                  <Light
                    component="span"
                    sx={{
                      backgroundColor: member?.connectionStatus
                        ? chatService.getColorByStatus(member?.connectionStatus)
                        : "#cd0707",
                    }}
                  />
                </Box>
                <Box sx={{ marginLeft: "5px", textAlign: "left" }}>
                  <Typography component="p" sx={{ fontWeight: 700 }}>
                    {member?.fullName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: member?.connectionStatus
                        ? chatService.getColorByStatus(member?.connectionStatus)
                        : "#cd0707",
                    }}
                  >
                    {t("chat." + member?.connectionStatus?.value)}
                  </Typography>
                </Box>

                {mbIsAdmin && (
                  <Box sx={{ width: "15px", marginLeft: "auto" }}>
                    <Badge
                      color="primary"
                      badgeContent={"Admin"}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    ></Badge>
                  </Box>
                )}
              </CardMember>
            );
          })}
        </Box>
      </Collapse>
      <SectionTitle>
        <Typography variant="subtitle2">
          {t("chat.conversation_files")}
        </Typography>
        <Box sx={{ marginLeft: "auto", display: "flex" }}>
          <Box sx={{ padding: "8px" }}>
            <SideBarIcon icon={withIcon("arrowDown")} />
          </Box>
        </Box>
      </SectionTitle>

      <Box sx={{ textAlign: "center", visibility: "hidden" }}>
        {t("chat.no_file")}
      </Box>
    </Header>
  );
};

export default ConversationEditPanel;
