import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const IncCheckboxList: React.FC<any> = ({
  question,
  sendResponse,
  validated,
  mounted,
}) => {
  const [state, setState] = useState<any>(null);
  const { t } = useTranslation("common");
  useEffect(() => {
    const obj: any = {};
    question.answers.forEach((el: any) => {
      obj[el.code] = el.previousValue !== undefined;
    });
    setState(obj);
  }, [question]);

  const handleChange = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    if (state) {
      const answer: any = [];

      const allChecked = Object.keys(state).filter((el: any) => state[el]);

      allChecked.forEach((key: any) => {
        answer.push({
          code: key,
          value: question.answers.find((el: any) => String(el.code) === key)
            .value,
        });
      });

      sendResponse({
        code: question.code,
        answer,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const limitReached =
    state &&
    Object.keys(state).filter((el: any) => state[el]).length >=
      question.details.max_selection_choices;

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {state &&
          question.answers.map((el: any) => (
            <FormControlLabel
              key={el.code}
              control={<Checkbox checked={state[el.code]} />}
              name={String(el.code)}
              onChange={(e: any) => mounted && handleChange(e)}
              label={<span style={{ fontSize: "16px" }}>{el.label}</span>}
              aria-label={`checkbox for ${el.label}`}
              disabled={limitReached && !state[el.code]}
            />
          ))}
      </FormGroup>
      {validated &&
        Object.keys(state).filter((el: any) => state[el]).length === 0 && (
          <Typography color="error">
            {t("inc.errors.select_at_least_one")}
          </Typography>
        )}
    </FormControl>
  );
};

export default IncCheckboxList;
