const rncpLevels = [
  { level: 1, label: "Sans niveau spécifique" },
  { level: 4, label: "Niveau 3 (CAP/BEP)" },
  { level: 5, label: "Niveau 4 (Baccalauréat)" },
  { level: 6, label: "Niveau 5 (DEUG, BTS, DUT, DEUST)" },
  { level: 7, label: "Niveau 6 (Licence, licence professionnelle, BUT)" },
  { level: 8, label: "Niveau 7 (Master, MBA)" },
];

export default rncpLevels;
