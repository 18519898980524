import { AddCircle } from "@mui/icons-material";
import { Fade, Typography, Stack } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import BigButton from "../common/BigButton";
import ProjectTrainingItem from "./ProjectTrainingItem";
import ProjectTrainingModal from "./project_modal/ProjectTrainingModal";
import { useTranslation } from "react-i18next";

const ProjectTrainingList: React.FC<any> = ({ items }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("common");
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const { id } = useParams();

  return (
    <Fade in={true} timeout={500}>
      <div>
        {!id && (
          <BigButton onClick={openModal} sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "inherit" }}>
              {t("projects.add_training")}
            </Typography>{" "}
            <AddCircle sx={{ ml: 1 }} />
          </BigButton>
        )}

        <Stack spacing={2}>
          {items?.map((careerItem: any) => (
            <ProjectTrainingItem key={careerItem.id} project={careerItem} />
          ))}
        </Stack>

        {open && <ProjectTrainingModal open={open} close={closeModal} />}
      </div>
    </Fade>
  );
};

export default ProjectTrainingList;
