import {
  Avatar,
  Collapse,
  Paper,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { returnInitial } from "../../Utils";
import MatchingProgressBar from "../common/MatchingProgressBar";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import IconButton from "@mui/material/IconButton";
import SuccessionItemMore from "./SuccessionItemMore";
import { useNavigate } from "react-router-dom";

const Root = styled(Paper)`
  padding: 10px;
  transition: all 0.25s ease-in-out;
  &:hover {
    transform: scale(1.015);
  }
`;

const Header = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SuccesstionItem: React.FC<any> = ({ infos }) => {
  const theme: any = useTheme();
  const [more, setMore] = useState(false);
  const navigate = useNavigate();

  return (
    <Root elevation={3}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/app/profile/${infos.person.id}/overview`)}
        >
          <Header>
            <Avatar
              sx={{
                border: `1px solid ${theme.palette.primary.main} !important`,
                width: 50,
                height: 50,
              }}
              src={infos.person?.image?.contentUrl ?? ""}
              children={
                <Typography variant="body1">
                  {returnInitial(
                    infos.person?.firstName + " " + infos.person?.lastName
                  )}
                </Typography>
              }
            />
            <div style={{ marginLeft: "10px" }}>
              <Typography variant="h5">
                {infos.person?.firstName + " " + infos.person?.lastName}
              </Typography>

              <Typography>
                Correspond à {infos?.scores?.global ?? "0"}%
              </Typography>
            </div>
          </Header>

          <MatchingProgressBar displayMatching scores={infos.scores} />
        </div>

        <div style={{ marginLeft: "auto" }}>
          <IconButton onClick={() => setMore(!more)}>
            {!more ? (
              <KeyboardArrowDownRoundedIcon />
            ) : (
              <KeyboardArrowUpRoundedIcon />
            )}
          </IconButton>
        </div>
      </div>

      <Collapse in={more}>
        <SuccessionItemMore infos={infos.person} />
      </Collapse>
    </Root>
  );
};

export default SuccesstionItem;
