import React from "react";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-GB";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled, TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const CustomDatePicker = styled("div")({
  width: "150px",
});

const DatePickerStandard: React.FC<{
  sendDataToParent: any;
  date?: any;
  label?: string;
  variant?: "filled" | "standard" | "outlined" | undefined;
  disabled?: boolean;
  maxDate?: Date | number;
  minDate?: Date | number;
  error?: boolean;
  onBlur?: any;
  sx?: any;
}> = ({
  sendDataToParent,
  date,
  variant = "standard",
  label,
  disabled,
  maxDate,
  minDate,
  error,
  onBlur,
  sx = {},
}) => {
  const localeMap: any = {
    en: enLocale,
    fr: frLocale,
  };

  const maskMap: any = {
    fr: "__/__/____",
    en: "__/__/____",
  };
  const { t } = useTranslation("common");

  const [locale] = React.useState("fr");

  return (
    <CustomDatePicker>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeMap[locale]}
      >
        <DatePicker
          value={date}
          disabled={disabled}
          label={label}
          minDate={minDate}
          onChange={sendDataToParent}
          mask={maskMap[locale]}
          maxDate={maxDate}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: t("autocomplete.placeholder.date_picker"),
              }}
              sx={sx}
              onBlur={() => onBlur && onBlur(date)}
              error={error}
              variant={variant}
            />
          )}
        />
      </LocalizationProvider>
    </CustomDatePicker>
  );
};

export default DatePickerStandard;
