import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { GithubPicker } from "react-color";
import { modalStyle } from "../../_utils/modalStyle";
import { HighlightOff } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import CustomButtonModal from "../common/CustomBtnModal";

const ColorModal: React.FC<{
  open: boolean;
  close: any;
  colorState: any;
  setColorState: any;
  thisTag: any;
  handleSaveColor: any;
}> = ({ close, open, colorState, setColorState, thisTag, handleSaveColor }) => {
  const colors = [
    "#505887",
    "#708CFF",
    "#9464C5",
    "#EB5483",
    "#f57e4e",
    "#F9BF58",
    "#5CE662",
  ];

  useEffect(() => {
    setColorState(thisTag?.tag?.color);
  }, [setColorState, thisTag]);

  const handleChangeComplete = (color: any) => {
    setColorState(color.hex);
  };

  const { t }: any = useTranslation("common");

  const handleClickModal = (e: any) => {
    e.stopPropagation();
  };

  return (
    <Modal open={open} onClick={handleClickModal}>
      <Card sx={{ ...modalStyle, width: 300 }}>
        <CardContent sx={{ textAlign: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{ mt: -2, mr: -2 }}
            >
              <HighlightOff />
            </IconButton>
          </Box>
          <Box sx={{ paddingInline: "16px" }}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {t("tag.edit_color")}
            </Typography>

            <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
              <Chip
                label={thisTag?.tag?.label}
                sx={{
                  backgroundColor: colorState,
                  color: "white",
                }}
              />
            </Box>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
              <GithubPicker
                color={colorState}
                colors={colors}
                onChangeComplete={handleChangeComplete}
              />
            </Box>
          </Box>
        </CardContent>
        <CardActions
          sx={{ justifyContent: "flex-end", paddingInlineEnd: "16px" }}
        >
          <CustomButtonModal
            onClick={() => handleSaveColor(thisTag)}
            variant="contained"
            text={t("btn.save")}
          />
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ColorModal;
