import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import { Api } from "../../_api/Api.class";
import { useTranslation } from "react-i18next";
import LongText from "../LongText";
import { ExpandMore } from "@mui/icons-material";

const MultipleAutocompleteComponent: React.FC<{
  type: string;
  required?: boolean;
  baseValue?: any;
  onBlur?: (e: any) => void;
  placeholder?: string;
  label?: string;
  limit?: number;
  formError?: boolean;
  formHelper?: string;
  sendDataToParent?: (value: any) => void;
  variant?: "filled" | "standard" | "outlined" | undefined;
  parentLoading?: boolean;
  disableAdd?: boolean;
  skillsType?: any;
}> = ({
  type,
  required,
  variant,
  onBlur,
  sendDataToParent,
  baseValue,
  label,
  placeholder,
  limit = 100,
  formError,
  formHelper,
  parentLoading,
  disableAdd,
  skillsType,
}) => {
  const [api] = useState(new Api(`${type}/autocomplete`));

  const [needle, setNeedle] = useState(null);

  const [helperText, setHelperText] = useState("");

  const { data, loading, error } = useFetch(
    api.get({ needle, limit, type: skillsType ? skillsType : "" }),
    true
  );

  const [value, setValue] = useState<any>(baseValue ?? []);
  const [options, setOptions] = useState<any>([]);

  const [init, setInit] = useState(true);

  const [inputValue, setInputValue] = useState<any>(
    baseValue ? baseValue.label : ""
  );
  const { t } = useTranslation("common");

  const handleChange = (event: any, newValue: any) => {
    const newArrId: any = [];
    const newArr: any = [];
    if ((disableAdd && newValue?.length < 10) || !disableAdd) {
      newValue.map((item: any) => newArrId.push(item.id));
      newValue.map((item: any) => newArr.push(item));
      setValue(newValue);
      if (sendDataToParent) sendDataToParent({ ids: newArrId, items: newArr });
    }
  };

  const search = () => {
    setNeedle(inputValue);
    setHelperText("");
  };

  useEffect(() => {
    if (baseValue && init) {
      setInit(false);
      return;
    }
    const timeout = setTimeout(() => {
      if (inputValue?.length >= 3) {
        search();
      } else {
        // setOptions([]);
        if (inputValue?.length < 3 && inputValue.length > 1) {
          setHelperText("3 caractères min");
        }
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    baseValue && setValue(baseValue);
  }, [baseValue]);

  useEffect(() => {
    if (data && data["hydra:member"]) {
      setOptions(data["hydra:member"]);
      if (data["hydra:member"].length === 0 && inputValue?.length >= 3) {
        setHelperText("Aucun résultat");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Autocomplete
      multiple
      id="multiple-tags-autocomplete"
      {...{ loading, value, options }}
      onChange={handleChange}
      disabled={parentLoading}
      disableClearable
      popupIcon={<ExpandMore />}
      loadingText={t("autocomplete.loading")}
      noOptionsText={t("autocomplete.no_result")}
      getOptionLabel={(option: any) => option?.label}
      isOptionEqualToValue={(option, value) => option?.label === value?.label}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => (
          <Chip
            variant="outlined"
            color="primary"
            label={<LongText maxChar={25} text={option.label} />}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          {...{ required, variant, placeholder, label }}
          onChange={(text) => {
            setInputValue(text.target.value);
          }}
          error={error || formError}
          onBlur={() => (onBlur ? onBlur(value) : null)}
          helperText={helperText || formHelper}
        />
      )}
    />
  );
};

export default MultipleAutocompleteComponent;
