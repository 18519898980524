const getFileIcon = (type: string) => {
  const path = "/static/icons/files";

  switch (type) {
    case "pdf":
      return `${path}/pdf.png`;
    case "doc":
      return `${path}/doc.png`;
    case "docx":
      return `${path}/docx.svg`;
    case "excel":
      return `${path}/excel.png`;
    case "image":
      return `${path}/image.png`;
    case "php":
      return `${path}/php.png`;
    case "ppt":
      return `${path}/ppt.png`;
    case "pptx":
      return `${path}/pptx.svg`;
    case "txt":
      return `${path}/txt.png`;
    case "xlsx":
      return `${path}/xlsx.svg`;
    case "xls":
      return `${path}/xlsx.svg`;
    case "zip":
      return `${path}/zip.png`;
    default:
      return null;
  }
};
export default getFileIcon;
