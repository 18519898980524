import { Backdrop, styled, Theme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

const CustomBackdrop: any = styled(Backdrop, {
  shouldForwardProp: (prop) =>
    prop !== "background" && prop !== "backgroundplain",
})(
  ({
    theme,
    background,
    backgroundplain,
  }: {
    theme: Theme;
    background: boolean;
    backgroundplain: boolean;
  } & any) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    ...(background ? {} : { background: "none" }),
    ...(backgroundplain ? { background: theme.palette.common.white } : {}),
    marginTop: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      marginLeft: 240,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(7),
    },
  })
);

const LogoLoader: React.FC<{
  open: boolean;
  background?: boolean;
  backgroundplain?: boolean;
  noMargin?: boolean;
}> = ({
  open,
  background = false,
  backgroundplain = false,
  noMargin = false,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return (
    <CustomBackdrop
      background={background}
      backgroundplain={backgroundplain}
      open={open}
      sx={{ ...(noMargin ? { margin: "0!important" } : {}) }}
    >
      {mounted && (
        <Player
          autoplay
          loop
          src={`/static/illustrations/lotties/general_loading.json`}
          style={{ height: "300px", width: "70%" }}
        ></Player>
      )}
    </CustomBackdrop>
  );
};
export default LogoLoader;
