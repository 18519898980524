import {
  Apps,
  Notifications,
  NotificationsOutlined,
} from "@mui/icons-material";
import {
  Menu,
  MenuItem,
  Box,
  Typography,
  Divider,
  Badge,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutButton from "../../components/auth/LogoutButton";
import DataIcon from "../../icons/menu/data";
import StringAvatar from "../../components/common/StringAvatar";
import ParamsIcon from "../../icons/menu/params";
import ProfileLightIcon from "../../icons/menu/profile_light";
import NotifMenu from "./NotifMenu";
import SelectComponent from "../../components/common/SelectComponent";

import rolesKeys from "../../_utils/rolesList";
import UsersAPI from "../../_api/_usersAPI";
import axios from "axios";
import { setContext } from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import useGlobalSplashscreen from "../../components/splashscreen/useGlobalSpashscreen";
import { handleMenu } from "../../redux/actions/menuActions";

const CustomBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    height: "16px",
    minWidth: "16px",
    padding: "0px 4px",
    fontSize: "12px",
  },
});

const NotificationIcon = styled(Notifications)({
  maxWidth: "20px",
  maxHeight: "20px",
});

const NotificationOutlinedIcon = styled(NotificationsOutlined)({
  maxWidth: "20px",
  maxHeight: "20px",
});

const MobileMenu: React.FC<any> = ({
  mobileMoreAnchorEl,
  mobileMenuId,
  isMobileMenuOpen,
  handleMobileMenuClose,
  tokenData,
  context,
  avatar,
  notifCount,
  notifList,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const location = useLocation();
  const [notifAnchorEl, setNotifAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const isNotifMenuOpen = Boolean(notifAnchorEl);
  const [contextList, setContextList] = useState<object[]>([]);
  const [openSplashScreen, setOpenSplashScreen] = useState(false);
  const dispatch = useDispatch();

  useGlobalSplashscreen(openSplashScreen);

  const handleProfileRedirection = () => {
    const profileLocation = "/app/profile/overview";
    navigate(profileLocation, {
      replace: location.pathname === profileLocation,
    });
    handleMobileMenuClose();
  };

  const handleRedirect = (redirect: string) => {
    navigate(`/app/${redirect}`);
    handleMobileMenuClose();
  };

  const handleNotifMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotifAnchorEl(event.currentTarget);
  };

  const handleNotifMenuClose = () => {
    setNotifAnchorEl(null);
  };

  const initContextList = (roles: string[]) => {
    const rolesConst: object[] = [];
    roles.map(
      (role: string, index: number) =>
        rolesKeys[role] &&
        rolesConst.push({
          value: rolesKeys[role],
          label: `${t(`roles.${rolesKeys[role]}`)}`,
          id: index,
        })
    );
    setContextList(rolesConst);
  };

  const handleContext = async (context: string) => {
    try {
      setOpenSplashScreen(true);
      const res = await UsersAPI.put(tokenData.user_id, { context: context });
      if (res) {
        localStorage.setItem("context", context);
        axios.defaults.headers["X-Context"] = context;
        dispatch(setContext(context));

        dispatch(handleMenu());
        navigate("/app/home");
        window.dispatchEvent(new Event("storage"));
        setTimeout(() => {
          setOpenSplashScreen(false);
        }, 2000);
      }
    } catch (err) {
      setOpenSplashScreen(false);
    }
  };

  useEffect(() => {
    tokenData?.roles?.length > 0 && initContextList(tokenData.roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData]);

  return (
    <Menu
      sx={{ mt: 5 }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Box display="flex" alignItems="center" sx={{ m: 2 }}>
        <StringAvatar
          size={40}
          name={`${tokenData.firstName} ${tokenData.lastName}`}
          url={avatar}
        />
        <Typography variant="h3" sx={{ ml: 1 }}>
          {tokenData?.firstName}
        </Typography>
      </Box>

      <Divider sx={{ mt: 2, mb: 2 }} />
      {(context === "collab" ||
        context === "benef" ||
        context === "candidate") && (
        <MenuItem sx={{ m: 1 }} onClick={handleProfileRedirection}>
          <ProfileLightIcon active={location.pathname.includes("profile")} />
          <Typography
            variant="body1"
            color={location.pathname.includes("profile") ? "primary" : ""}
            sx={{
              ml: 2,
              fontWeight: "600",
              opacity: location.pathname.includes("profile") ? "1" : "0.5",
            }}
          >
            {t("profile_menu.profile")}
          </Typography>
        </MenuItem>
      )}
      <MenuItem sx={{ m: 1 }} onClick={handleNotifMenuOpen}>
        <CustomBadge badgeContent={notifCount} color="error">
          {notifCount > 0 ? (
            <NotificationIcon
              color={
                location.pathname.includes("notification")
                  ? "primary"
                  : "secondary"
              }
              sx={{
                opacity: location.pathname.includes("notification")
                  ? "1"
                  : "0.5",
              }}
            />
          ) : (
            <NotificationOutlinedIcon
              color={
                location.pathname.includes("notification")
                  ? "primary"
                  : "secondary"
              }
              sx={{
                opacity: location.pathname.includes("notification")
                  ? "1"
                  : "0.5",
              }}
            />
          )}
        </CustomBadge>
        <Typography
          variant="body1"
          color={location.pathname.includes("notification") ? "primary" : ""}
          sx={{
            ml: 2,
            fontWeight: "600",
            opacity: location.pathname.includes("notification") ? "1" : "0.5",
          }}
        >
          {t("notif.title")}
        </Typography>
      </MenuItem>
      {/* <MenuItem sx={{ m: 1 }} onClick={() => handleRedirect("account/data")}>
        <DataIcon active={location.pathname.includes("data")} />
        <Typography
          variant="body1"
          color={location.pathname.includes("data") ? "primary" : ""}
          sx={{
            ml: 2,
            fontWeight: "600",
            opacity: location.pathname.includes("data") ? "1" : "0.5",
          }}
        >
          {t("profile_menu.data")}
        </Typography>
      </MenuItem> */}
      {((tokenData?.roles.includes("ROLE_ADMINADVISOR") &&
        context === "advisor") ||
        (tokenData?.roles.includes("ROLE_ADMINRECRUITER") &&
          context === "recruiter")) && (
        <MenuItem sx={{ m: 1 }} onClick={() => handleRedirect("params/home")}>
          <DataIcon active={location.pathname.includes("app/params")} />
          <Typography
            variant="body1"
            color={location.pathname.includes("app/params") ? "primary" : ""}
            sx={{
              ml: 2,
              fontWeight: "600",
              opacity: location.pathname.includes("app/params") ? "1" : "0.5",
            }}
          >
            {t("profile_menu.admin")}
          </Typography>
        </MenuItem>
      )}
      <MenuItem sx={{ m: 1 }} onClick={() => handleRedirect("account/params")}>
        <ParamsIcon active={location.pathname.includes("account/params")} />
        <Typography
          variant="body1"
          color={location.pathname.includes("account/params") ? "primary" : ""}
          sx={{
            ml: 2,
            fontWeight: "600",
            opacity: location.pathname.includes("account/params") ? "1" : "0.5",
          }}
        >
          {t("profile_menu.params")}
        </Typography>
      </MenuItem>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box sx={{ pl: 1, pr: 1, mt: 2 }}>
        {contextList?.length > 1 && (
          <SelectComponent
            icon={<Apps />}
            onChange={handleContext}
            items={contextList}
            preSelected={context}
            fontWeight="bold"
          />
        )}
      </Box>
      <Box sx={{ m: 1 }}>
        <LogoutButton context="collab" menu />
      </Box>
      {isNotifMenuOpen && (
        <NotifMenu
          anchorEl={notifAnchorEl}
          isOpen={isNotifMenuOpen}
          handleMenuClose={handleNotifMenuClose}
          data={notifList}
        />
      )}
    </Menu>
  );
};

export default MobileMenu;
