import { Rating, styled } from "@mui/material";
import React from "react";

const Bullet: any = styled("div", {
  shouldForwardProp: (props) => props !== "themeColor",
})(({ theme, themeColor = theme.palette.primary.main }: any) => ({
  background: themeColor,
  width: 20,
  height: 20,
  borderRadius: 25,
  margin: 4,
}));

const EmptyBullet = styled("div")(({ theme }: any) => ({
  // border: `1px solid ${theme.palette.primary.main}`,
  background: theme.palette.grey[400],
  width: 20,
  height: 20,
  borderRadius: 25,
  margin: 4,
}));

const RatingBubblesOnly = ({ level, color, identifier }: any) => {
  return (
    <>
      <Rating
        readOnly
        emptyIcon={<EmptyBullet sx={{ borderColor: color }} />}
        max={4}
        name={`unique-rating-${identifier}`}
        value={level}
        icon={<Bullet themeColor={color} />}
      />
    </>
  );
};

export default RatingBubblesOnly;
