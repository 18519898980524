import React, { useEffect, useRef, useState } from "react";
import { Backdrop, styled, Drawer, Box } from "@mui/material";
import axios from "axios";
import ConversationEditPanel from "./ConversationEditPanel";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  requestDeleteMessage,
  requestGetConversation,
} from "../../redux/actions/chatActions";
import Picker from "emoji-picker-react";
import chatService from "../../services/chat.service";
import { useTranslation } from "react-i18next";
import MessageDeleteValidationModal from "./MessageDeleteValidationModal";
import { toast } from "react-toastify";
import PanelHeader from "./PanelHeader";
import PanelBody from "./PanelBody";
import PanelFooter from "./PanelFooter";

const MainContainer = styled(Box)(({ theme }: any) => ({
  borderRadius: theme.shape.borderRadius12,
  minHeight: "400px",
  border: "solid 1px #dbe5ed",
}));

const ConversationPanel: React.FC<{ mobile: any }> = ({ mobile }) => {
  const { t }: any = useTranslation("common");
  const dispatch = useDispatch();

  const [convId, setConvId] = useState<any>(null);
  const [loaderState, setLoaderState] = useState<boolean>(false);

  useEffect(() => {
    const sub = chatService._convId.subscribe((e) => {
      setLoaderState(true);
      setConvId(e);
    });
    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tokenData = useSelector((state: any) => state.user, shallowEqual);

  const [sidePanel, setSidePanel] = useState({ right: false });
  const [textInputVal, setTextInputVal] = useState("");
  const conversations = useSelector((state: any) => state.chat.conversations);
  const [convName, setConvName] = useState<any>("");

  const [ACInputValue, setACInputValue] = useState<any>([]);
  const [waitingToSwapConv, setWaitingToSwapConv] = useState<any>(false);
  const [waitingPreviousMessages, setWaitingPreviousMessages] =
    useState<any>(false);
  const [convDetails, setConvDetails] = useState<any>(null);
  const [creatingConversation, setCreatingConversation] = useState<any>(false);

  const [topAncor, setTopAncor] = useState<any>(null);
  const [bottomAncor, setBottomAncor] = useState<any>(null);
  const [chosenEmoji, setChosenEmoji] = useState<any>(null);
  const [emojiPickerDisplay, setEmojiPickerDisplay] = useState<boolean>(false);
  const [flyings, setFlyings] = useState<any>([]);
  const [typingsFirstName, setTypingsFirstName] = useState<any>([]);

  const textAreaRef = useRef<any>(null);
  const chatBodyRef = useRef<any>(null);

  // on delete , store msg data & display deleteModal on state change ( prevent build of 1modal/1msg ?)
  const [msgData, setMsgData] = useState<any>(null);
  const [modalDisplay, setModalDisplay] = useState<any>(false);
  const deleteMessage = (msg_data: any) => {
    dispatch(requestDeleteMessage(msg_data));
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    setChosenEmoji(emojiObject);
  };

  useEffect(() => {
    if (!chosenEmoji) return;
    setTextInputVal(textInputVal + chosenEmoji.emoji);
    textAreaRef.current &&
      textAreaRef.current.lastElementChild.firstElementChild.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenEmoji]);

  useEffect(() => {
    let typingsInterval = setInterval(function () {
      update_typings();
    }, 3000);

    return () => {
      clearInterval(typingsInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typingsFirstName]);

  useEffect(() => {
    // setTimeout(() => bottomAncor && bottomAncor?.scrollIntoView({ behavior: "smooth" }), 1000);

    const sub = chatService._creatingConversation.subscribe((e) =>
      setCreatingConversation(e)
    );

    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let lastMessage = convDetails?.messages?.slice(-1).pop();
    let curPerson = tokenData.person_id;
    let msg_author_id =
      lastMessage?.author?.id ??
      lastMessage?.authorId ??
      lastMessage?.author?.["@id"];
    if (
      lastMessage &&
      (curPerson === msg_author_id || msg_author_id.includes(curPerson))
    )
      setFlyings([]);

    bottomAncor &&
      !waitingPreviousMessages &&
      bottomAncor?.scrollIntoView({ behavior: "smooth" });

    if (waitingPreviousMessages) {
      topAncor && topAncor?.scrollIntoView({ behavior: "smooth" });
      setWaitingPreviousMessages(false);
    }

    update_convName();
    setLoaderState(false);

    if (convDetails?.typingMembers) update_typings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations]);

  useEffect(() => {
    if (waitingToSwapConv !== false) {
      chatService.setCreatingConversation(false);
      chatService.setConvId(waitingToSwapConv);
      setWaitingToSwapConv(false);
    }

    update_convName();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingToSwapConv]);

  useEffect(() => {
    if (creatingConversation) {
      chatService.setCreatingConversation(false);
    }
    convId && dispatch(requestGetConversation(convId));
    const myConvIndex = conversations?.findIndex(
      (conversation: any) => conversation.id === convId
    );
    const copyConvs = [...conversations];
    setConvDetails(copyConvs[myConvIndex]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convId]);

  useEffect(() => {
    update_convName();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convDetails]);

  useEffect(() => {
    setTimeout(
      () => bottomAncor && bottomAncor?.scrollIntoView({ behavior: "smooth" }),
      0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flyings]);

  const update_convName = () => {
    var tmpConvName = "";
    // eslint-disable-next-line array-callback-return

    if (convDetails && !convDetails.name) {
      // eslint-disable-next-line array-callback-return
      convDetails?.members.map((member: any) => {
        if (tokenData?.person_id !== member?.id) {
          if (convDetails?.members.length > 2) {
            tmpConvName +=
              tmpConvName === "" ? member?.firstName : ", " + member?.firstName;
          } else {
            tmpConvName += member?.fullName;
          }
        }
      });
    } else {
      tmpConvName = convDetails && convDetails.name;
    }

    setConvName(tmpConvName);
  };

  const update_typings = () => {
    let copyConversationTypers = convDetails?.typingMembers?.slice();
    let duplicates: any = [];

    // eslint-disable-next-line array-callback-return
    copyConversationTypers?.map((member: any) => {
      if (
        new Date().getTime() - member.stamp < 6000 &&
        member?.person_id !== tokenData?.person_id
      ) {
        let firstName = chatService.findFirstNameById(
          member?.person_id,
          convDetails?.members
        );
        duplicates.push(firstName);
      }
    });

    var unique = duplicates.filter(
      (v: any, i: any, a: any) => a.indexOf(v) === i
    );

    if (JSON.stringify(unique) !== JSON.stringify(typingsFirstName)) {
      setTypingsFirstName(unique);
    }
  };

  const postMessage = () => {
    //OR POST CONVERSATION

    if (textInputVal === "" /** && noMediaFile */) {
      toast.error(t("chat.msg_cant_be_void"));
      return;
    }

    if (creatingConversation) {
      if (ACInputValue.length === 0) {
        toast.error(t("chat.person_to_required"));
        return;
      } else {
        const membersArr = ACInputValue.map((e: any) => e?.candidate?.["@id"]);

        axios
          .post("chat/conversation", {
            submit_members: membersArr,
            firstMessage: textInputVal,
          })
          .then((e: any) => {
            if (e?.request?.status === 201) {
              setWaitingToSwapConv(JSON.parse(e?.request?.response)?.id);
              toast.success(t("chat.conv_created"));
              setTextInputVal("");
            }
          })
          .catch((e: any) => {
            if (e?.status === 422) {
              toast.error(t("chat.conv_already_exist"));
              let already_existing_conv_id =
                chatService.findConversationByMembers(
                  membersArr,
                  conversations,
                  tokenData?.person_id
                );

              setConvId(already_existing_conv_id);
              setCreatingConversation(false);
            }
          });
      }
    } else {
      //default way
      setFlyings((flyings: any) => [
        ...flyings,
        {
          content: textInputVal,
          createdAt: Date.now(),
        },
      ]);

      bottomAncor && bottomAncor?.scrollIntoView({ behavior: "smooth" });

      axios
        .post("chat/message", {
          conversation: "/api/chat/conversation/" + convId,
          content: textInputVal,
          pinned: false,
        })
        .then((e: any) => {
          if (e?.request?.status === 201) {
            setTextInputVal("");
            setFlyings([]);
          }
        })
        .catch((e: any) => {
          toast.error(t("chat.msg_cant_be_sent"));
        });
    }
    setEmojiPickerDisplay(false);
  };

  const toggleDrawer =
    (anchor: any, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSidePanel({ ...sidePanel, [anchor]: open });
    };

  return (
    <MainContainer>
      <PanelHeader
        creatingConversation={creatingConversation}
        convName={convName}
        mobile={mobile}
        convDetails={convDetails}
        sidePanel={sidePanel}
        setSidePanel={setSidePanel}
        setACInputValue={setACInputValue}
      />

      <PanelBody
        convDetails={convDetails}
        chatBodyRef={chatBodyRef}
        setModalDisplay={setModalDisplay}
        setMsgData={setMsgData}
        ACInputValue={ACInputValue}
        setBottomAncor={setBottomAncor}
        flyings={flyings}
        creatingConversation={creatingConversation}
        loaderState={loaderState}
        setWaitingPreviousMessages={setWaitingPreviousMessages}
        setTopAncor={setTopAncor}
        emojiPickerDisplay={emojiPickerDisplay}
        setEmojiPickerDisplay={setEmojiPickerDisplay}
      />

      <Box sx={{ position: "relative" }}>
        {emojiPickerDisplay && (
          <Box sx={{ position: "absolute", top: "-290px", right: "50px" }}>
            <Picker onEmojiClick={onEmojiClick} />
          </Box>
        )}
      </Box>

      <PanelFooter
        typingsFirstName={typingsFirstName}
        textAreaRef={textAreaRef}
        setEmojiPickerDisplay={setEmojiPickerDisplay}
        setTextInputVal={setTextInputVal}
        postMessage={postMessage}
        textInputVal={textInputVal}
        creatingConversation={creatingConversation}
        convId={convId}
      />

      <React.Fragment key={"right"}>
        <Drawer
          ModalProps={{
            BackdropComponent: styled(Backdrop, {
              name: "MuiModal",
              slot: "Backdrop",
              overridesResolver: (props: any, styles: any) => {
                return styles.backdrop;
              },
            })({ zIndex: -1, opacity: "0!important" }),
          }}
          anchor={"right"}
          open={sidePanel["right"]}
          onClose={toggleDrawer("right", false)}
          children={
            <ConversationEditPanel
              conversation={convDetails}
              closeSelf={toggleDrawer}
            ></ConversationEditPanel>
          }
          hideBackdrop={false}
        />
      </React.Fragment>

      <MessageDeleteValidationModal
        open={modalDisplay}
        setOpen={setModalDisplay}
        onAccept={deleteMessage}
        msgData={msgData}
      />
    </MainContainer>
  );
};

export default ConversationPanel;
