import {
  Box,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Big5CompareBars from "./Big5CompareBars";
import palette from "../../theme/palette";
import Big5EditableBars from "./Big5EditableBars";

const Title = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.main,
  fontFamily: "Product Sans light,Product Sans light,sans-serif",
}));

const Big5ProgressMarker: React.FC<{
  result: number;
  trait: string;
  compare?: boolean;
  editable?: boolean;
  compareResult?: any;
  handlePosition?: any;
}> = ({ result, trait, compare, editable, compareResult, handlePosition }) => {
  const { t }: { t: any } = useTranslation("common");

  return (
    <Grid container display="flex" alignItems="center" width="100%">
      <Grid
        item
        md={2}
        lg={2}
        display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
      >
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            mr: 2,
            textAlign: "end",
            color: palette.secondary.main,
          }}
          dangerouslySetInnerHTML={{
            __html: t(`big5.traits.${trait}.adjectives.left`),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Box display="flex" alignItems="center">
          <Title
            sx={{
              display: "flex",
            }}
            variant="subtitle1"
            fontWeight="100"
          >
            {t(`big5.traits.${trait}.label`)}
            <sup>
              <Tooltip title={t(`big5.traits.${trait}.help`)}>
                <IconButton
                  aria-label="big5 trait info"
                  sx={{ padding: 0, marginLeft: 0.5, marginTop: "-10px" }}
                >
                  <HelpOutlineOutlinedIcon
                    sx={{ fontSize: "10px", color: palette.secondary.main }}
                  />
                </IconButton>
              </Tooltip>
            </sup>
          </Title>
        </Box>
        {editable ? (
          <Big5EditableBars
            result={result}
            trait={trait}
            handlePosition={handlePosition}
          />
        ) : (
          <Big5CompareBars
            result={result}
            trait={trait}
            compare={compare}
            compareResult={compareResult}
            editable={editable}
          />
        )}
      </Grid>
      <Grid
        item
        sm={10}
        xs={8}
        display={{ xs: "block", sm: "block", md: "none", lg: "none" }}
      >
        <Typography
          variant="body2"
          sx={{ mt: 2, color: palette.secondary.main }}
          dangerouslySetInnerHTML={{
            __html: t(`big5.traits.${trait}.adjectives.left`),
          }}
        />
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            ml: { sm: 4, md: 2 },
            textAlign: "start",
            color: palette.secondary.main,
          }}
          dangerouslySetInnerHTML={{
            __html: t(`big5.traits.${trait}.adjectives.right`),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Big5ProgressMarker;
