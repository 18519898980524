export const SET_PTW_STATE = "SET_PTW_STATE";

export const setState = (payload: any) => {
  return {
    type: SET_PTW_STATE,
    payload,
  };
};

export const setActive = (payload: any) => {
  return (dispatch: any, getState: any) => {
    const { ptwPopup } = getState();
    dispatch(setState({ ...ptwPopup, active: payload }));
  };
};

export const setOpen = (payload: any) => {
  return (dispatch: any, getState: any) => {
    const { ptwPopup } = getState();
    dispatch(setState({ ...ptwPopup, open: payload }));
  };
};
