import {
  // Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayOnboardingCollab: React.FC<any> = ({
  param,
  handleChanges,
}) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">{t("admin.params.onboarding.title")}</Typography>

      <Typography sx={{ mt: 1 }}>
        {t("admin.params.onboarding.display")}
      </Typography>
      <FormGroup>
        <FormControlLabel
          value={param.EXP}
          onChange={(e: any) =>
            handleChanges({ ...param, EXP: e.target.checked })
          }
          control={<Checkbox defaultChecked />}
          label="L'expérience"
        />
        <FormControlLabel
          value={param.SKILLS}
          onChange={(e: any) =>
            handleChanges({ ...param, SKILLS: e.target.checked })
          }
          control={<Checkbox defaultChecked />}
          label={t("admin.params.onboarding.skills")}
        />
        <FormControlLabel
          value={param.PERSONALITY}
          onChange={(e: any) =>
            handleChanges({ ...param, PERSONALITY: e.target.checked })
          }
          control={<Checkbox defaultChecked />}
          label={t("admin.params.onboarding.b5")}
        />
        <FormControlLabel
          value={param.VALORS}
          onChange={(e: any) =>
            handleChanges({ ...param, VALORS: e.target.checked })
          }
          control={<Checkbox defaultChecked />}
          label={t("admin.params.onboarding.cf")}
        />
        <FormControlLabel
          value={param.MOTIVATIONS}
          onChange={(e: any) =>
            handleChanges({ ...param, MOTIVATIONS: e.target.checked })
          }
          control={<Checkbox defaultChecked />}
          label={t("admin.params.onboarding.inc")}
        />
      </FormGroup>

      <Typography sx={{ mt: 2, mb: 1 }}>
        {t("admin.params.onboarding.video")}
      </Typography>
      <TextField
        sx={{ width: { xs: "100%", sm: "80%", md: "60%" } }}
        label={t("admin.params.onboarding.ytb")}
        value={param.VIDEO_PRESENTATION}
        onChange={(event: any) =>
          handleChanges({ ...param, VIDEO_PRESENTATION: event.target.value })
        }
      />
      {/* <Button variant="contained">Importer</Button> */}

      <Typography sx={{ mt: 2 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
    </div>
  );
};

export default AdminDisplayOnboardingCollab;
