import { Typography, styled } from "@mui/material";
import React from "react";
import { SkillsGrid2 } from "./SkillsGrid";
import { useSelector } from "react-redux";

const RightSide: React.FC<any> = ({
  category,
  type,
  editMode,
  reload,
  deleteList,
  addToDeleteList,
  loading,
  editSkill,
  isWork,
  workflowState,
  personId,
  handleAdd,
}) => {
  const isCollab = useSelector((state: any) => state.auth.isCollab);
  const context = useSelector((state: any) => state.auth.context);

  return (
    <>
      <WorkGrid
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "50px",
        }}
      >
        <WorkGrid
          style={{
            border: "1px solid #797979",
            minHeight: "100%",
            margin: "10px",
            width: "1px",
          }}
        />
      </WorkGrid>
      <WorkGrid style={{ flex: 1 }}>
        {category?.children?.map((cat: any) => (
          <div key={cat.label} style={{ marginBottom: "15px" }}>
            <Typography
              sx={{ color: category?.theme?.font }}
              variant="h5"
              fontSize="16px!important"
            >
              {cat.label}
            </Typography>

            <SkillsGrid2
              compare={true}
              reverse={true}
              personId={personId}
              skills={cat.skills}
              workflowState={workflowState}
              levelDirection="right"
              font={category.theme.font}
              category={{ ...cat, theme: category.theme }}
              type={type}
              editMode={
                context === "manager" &&
                (workflowState.status === 20 || workflowState.status === 50) &&
                !isWork
              }
              reload={reload}
              deleteList={deleteList}
              canAdd={isWork && isCollab}
              inList={true}
              editSkill={(id: string, level: number) =>
                editSkill(id, level, cat.id, "right")
              }
              canDelete={
                (workflowState.status === 30 || workflowState.status === 60) &&
                isCollab &&
                !isWork
              }
              loading={loading}
              addToDeleteList={addToDeleteList}
              handleAdd={handleAdd}
            />
          </div>
        ))}
      </WorkGrid>
    </>
  );
};

export default RightSide;

const WorkGrid = styled("div")`
  @media screen and (max-width: 1010px) {
    display: none;
  }
`;
