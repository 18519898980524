import { Fade, Box } from "@mui/material";
import StepSplash from "./StepSplash";
import Page from "../Page";
import { Player } from "@lottiefiles/react-lottie-player";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SplashScreenTest: React.FC<{ test: string }> = ({ test }) => {
  const { t }: any = useTranslation("common");
  const [mounted, setMounted] = useState(false);

  const logo = useSelector((state: any) => state.home?.logo?.contentUrl);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);
  return (
    <Page title={t(`tests.${test}`)}>
      <Box
        width="100%"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <Fade in={true} timeout={200}>
          <Box
            width="100%"
            textAlign="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <img
              width="300"
              src={logo ?? "/static/logo/MT-Logo-Horizontal-Colors.svg"}
              alt="logo monkey tie"
            />
            {mounted && (
              <Player
                autoplay
                loop
                src={`/static/illustrations/lotties/${test}_loading.json`}
                style={{ height: "350px", width: "100%" }}
              ></Player>
            )}
            <StepSplash />
          </Box>
        </Fade>
      </Box>
    </Page>
  );
};

export default SplashScreenTest;
