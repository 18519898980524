import { Button, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const grid = 8;
const getItemStyle = (isDragging: any, draggableStyle: any, props?: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : props.moved ? "#ffb238" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
  ...props.style,
});

const Card = styled("div")`
  border-radius: 8px;
`;

const AdvisorBenefDropCard: React.FC<any> = ({
  handleBack,
  moved,
  item,
  provided,
  snapshot,
}) => {
  const { t } = useTranslation("common");
  return (
    <Card
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, {
        moved,
        style: item.style ?? {},
      })}
    >
      <div>
        <p>{item.fullName}</p>
        <p>{item.email}</p>
        {moved && (
          <>
            <p>From: {item.advisorName}</p>
            <Button sx={{ color: "white" }} onClick={handleBack}>
              {t("btn.cancel")}
            </Button>
          </>
        )}
      </div>
    </Card>
  );
};

export default AdvisorBenefDropCard;
