import { SET_PTW_STATE } from "../actions/ptwPopupActions";
import initialState from "../initialState";

const userReducer = (state = { ...initialState.ptwPopup }, action: any) => {
  switch (action.type) {
    case SET_PTW_STATE:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default userReducer;
