import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const gradients = [
  {
    key: 1,
    value:
      "#FF0000,#FF0000 17%,#ff7373 34%,#ff7373 51%,#FF0000 68%,#FF0000 85%,#ff7373",
  },
  {
    key: 2,
    value:
      "#FFC257,#FFC257 17%,#ffa200 34%,#ffa200 51%,#FFC257 68%,#FFC257 85%,#ffa200",
  },
  {
    key: 3,
    value:
      "#FFC257,#FFC257 17%,#FF0000 34%,#FF0000 51%,#FFC257 68%,#FFC257 85%,#FF0000",
  },
  {
    key: 4,
    value:
      "#3ECE80,#3ECE80 17%,#7affb6 34%,#7affb6 51%,#3ECE80 68%,#3ECE80 85%,#7affb6",
  },
  {
    key: 6,
    value:
      "#3ECE80,#3ECE80 17%,#FFC257 34%,#FFC257 51%,#3ECE80 68%,#3ECE80 85%,#FFC257",
  },
  {
    key: 7,
    value:
      "#FF0000,#FFC257 17%,#3ECE80 34%,#3ECE80 51%,#FF0000 68%,#FFC257 85%,#3ECE80",
  },
];

const Medal = styled("img")(() => ({
  position: "absolute",
  top: 0,
  right: "-10px",
  width: "35px",
}));

const SkillImage = styled("img")(() => ({
  width: "45px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const DescriptionText = styled(Typography)(({ theme }: any) => ({
  fontWeight: 400,
  marginBottom: theme.spacing(1),
  color: theme.palette.secondary.light,
}));

const Item = styled(Box)(({ theme }: any) => ({
  textAlign: "center",
  margin: theme.spacing(2),
  display: "flex",
  width: "500px",
}));

const ImageContainer = styled(Box)(() => ({
  borderRadius: "50%",
  position: "relative",
  minWidth: "80px",
  height: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#fff7e8",
  marginLeft: "auto",
  marginRight: "auto",
}));

const IncTopSoftSkills: React.FC<{ ssk: any; work?: boolean }> = ({
  ssk,
  work = false,
}) => {
  const ProgressTrack = styled(Box)(({ theme }: any) => ({
    width: "100%",
    height: "9px",
    background: "#D6DBE3",
    position: "relative",
    borderRadius: "11px",
    [theme.breakpoints.down("md")]: {
      height: "6px",
    },
  }));

  const ProgressThumb = styled(Box)(({ theme }: any) => ({
    "@keyframes pulse": {
      "0%": {
        backgroundPosition: "100%",
      },
      "100%": {
        backgroundPosition: "0%",
      },
    },
    height: "18px",
    bottom: "-5px",
    position: "absolute",
    border: "3px solid white",
    borderRadius: "9px",
    opacity: "0.7",
    left: `calc(${ssk.min === 10 ? "96" : (ssk.min + 10) * 5}%)`,
    width: `${ssk.max === ssk.min ? "4.2" : ((ssk.max - ssk.min) / 20) * 100}%`,
    background: `linear-gradient(90deg, ${
      gradients?.filter((el: any) => el.key === ssk?.key)[0]?.value
    })`,
    [theme.breakpoints.down("md")]: {
      height: "16px",
      bottom: "-5px",
    },
    display: "block",
    backgroundSize: "300% 100%",
    animation: "pulse 2.5s linear infinite",
  }));

  const ProgressPointer = styled(Box)(({ theme }: any) => ({
    height: "26px",
    width: "26px",
    background: "#333539",
    border: "3px solid white",
    borderRadius: "50px",
    position: "absolute",
    bottom: "-9px",
    left: `calc(${(ssk.central + 10) * 5}% - 13px)`,
    [theme.breakpoints.down("md")]: {
      height: "20px",
      width: "20px",
      left: `calc(${(ssk.central + 10) * 5}% - 10px)`,
      bottom: "-7px",
    },
  }));

  const { t } = useTranslation("common");

  return (
    <Item>
      <ImageContainer display="flex" justifyContent="center">
        {(ssk.type === "silver" || ssk.type === "gold") && (
          <Medal
            alt="medal"
            src={
              ssk.type === "gold"
                ? "/static/icons/inc/gold.png"
                : "/static/icons/inc/silver.png"
            }
          />
        )}
        <SkillImage
          src={`/static/icons/Icons-irittants/${ssk.var}.svg`}
          alt={`${ssk.title} icon`}
        />
      </ImageContainer>
      <Box width="100%" marginLeft={2} sx={{ textAlign: "left" }}>
        <Typography variant="subtitle1">{ssk.title}</Typography>
        <DescriptionText variant="body2">{ssk.text}</DescriptionText>
        <Box className="progress-ctn" sx={{ width: "100%" }}>
          <ProgressTrack>
            {!work && <ProgressThumb />}
            <ProgressPointer
              sx={{ ...(!work ? { background: "#1a52c2!important" } : {}) }}
            />
          </ProgressTrack>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          marginTop={2}
        >
          <Typography sx={{ width: "auto", color: "#333539" }} variant="body2">
            {t("inc.softskills.-10")}
          </Typography>
          <Typography variant="body2" sx={{ color: "#333539" }}>
            0
          </Typography>
          <Typography sx={{ width: "auto", color: "#333539" }} variant="body2">
            {t("inc.softskills.+10")}
          </Typography>
        </Box>
      </Box>
    </Item>
  );
};

export default IncTopSoftSkills;
