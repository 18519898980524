import {
  Stack,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlledLocationAutocomplete from "../../common/autocomplete/ControlledLocationAutocomplete";
import ControlledWorkAutocomplete from "../../common/autocomplete/ControlledWorkAutocomplete";
import CustomButtonModal from "../../common/CustomBtnModal";
import ControlledDatePicker from "../../forms/ControlledDatePicker";
import ControlledQuill from "../../forms/ControlledQuill";
import ControlledSwitch from "../../forms/ControlledSwitch";
import ControlledTextField from "../../forms/ControlledTextField";
import diplomas from "./../../../_utils/diplomasList";

interface IFormInput {
  diplomaLevel: string;
  diplomaTitle: string;
  validityDate: string;
  company: string;
  work: any;
  location: string;
  dateStart: Date | any;
  dateEnd: Date | any;
  description: string;
  ongoing: boolean;
}

const TrainingFormBody: React.FC<{
  form: any;
  submit: (e: any) => void;
  loading: boolean;
  close: () => void;
}> = ({ form, submit, loading, close }) => {
  const { t } = useTranslation("common");

  const [requiredEndDate, setRequiredEndDate] = useState<boolean>(true);
  const [labelEndDate, setLabelEndDate] = useState<string>(
    `${t("form.end_date")}${requiredEndDate ? " *" : ""}`
  );
  const [minEndDate, setMinEndDate] = useState<any>(null);

  const { handleSubmit, control, setValue, watch, getValues, clearErrors } =
    useForm<IFormInput>({
      mode: "onBlur",
      defaultValues: form,
    });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    submit(data);
  };

  const handleOnGoing = (ongoing: boolean, dateEnd: any) => {
    ongoing && dateEnd && setValue("dateEnd", null);
    setRequiredEndDate(!ongoing);
    setLabelEndDate(`${t("form.end_date")}${!ongoing ? " *" : ""}`);
  };

  useEffect(() => {
    const subscription = watch((value: any) => {
      setMinEndDate(value.dateStart);
      handleOnGoing(value.ongoing, value.dateEnd);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={2}>
        <FormControl variant="outlined">
          <InputLabel
            style={{ background: "white", paddingRight: "4px" }}
            id="demo-simple-select-outlined-label"
          >
            {t("experience_section.form.study_level")}
          </InputLabel>
          <Controller
            control={control}
            name="diplomaLevel"
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(e: any) => onChange(e.target.value)}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
              >
                <MenuItem value="">
                  <em>{t("none")}</em>
                </MenuItem>
                {diplomas.map((el: any, i: number) => (
                  <MenuItem key={i + el} value={i}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <ControlledTextField
          name="diplomaTitle"
          label={t("experience_section.form.grade")}
          control={control}
          required
        />
        <ControlledDatePicker
          control={control}
          name="validityDate"
          minDate={getValues("dateStart") || Date.now()}
          label={t("experience_section.form.limit_grade")}
          style={{ width: "275px !important" }}
        />
        <ControlledTextField
          name="company"
          label={t("experience_section.form.orga")}
          control={control}
        />
        <ControlledWorkAutocomplete
          label={t("experience_section.form.work_prepared")}
          clearErrors={clearErrors}
          control={control}
          workObject={getValues("work")}
          handleWork={(e: any) => setValue("work", e)}
        />
        <ControlledLocationAutocomplete
          control={control}
          label={t("experience_section.form.location_formation")}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ mr: 1 }}>
            <ControlledDatePicker
              control={control}
              name="dateStart"
              maxDate={Date.now()}
              label={t("form.start_date") + " *"}
              required
            />
          </Box>
          <Box>
            <ControlledDatePicker
              control={control}
              name="dateEnd"
              minDate={minEndDate}
              label={labelEndDate}
              disabled={!requiredEndDate}
              required={requiredEndDate}
            />
          </Box>
        </Box>
        <Box sx={{ pl: 1 }}>
          <ControlledSwitch
            name="ongoing"
            control={control}
            label={t("experience_section.form.ongoing_formation")}
          />
        </Box>
        <ControlledQuill
          name="description"
          placeholder={t("form.description")}
          control={control}
        />
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <CustomButtonModal
            disabled={loading}
            type="submit"
            variant="contained"
            text={t("btn.save")}
          />
          <CustomButtonModal
            secondary
            onClick={close}
            variant="outlined"
            color="secondary"
            text={t(`btn.cancel`)}
          />
        </Box>
      </Stack>
    </form>
  );
};

export default TrainingFormBody;
