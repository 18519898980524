import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Radio,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loader from "../../Loader";
import ResultsAPI from "../../_api/_resultsAPI";
import TestsAPI from "../../_api/_testsAPI";
import FinishLaterTestModal from "../tests/FinishLaterTestModal";
import ReplayIcon from "@mui/icons-material/Replay";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { capitalizeFirstLetter, getDuration, Utils } from "../../Utils";
import LinearProgressBar from "../common/LinearProgressBar";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
});

const HeadText = styled("div")(({ theme }: any) => ({
  width: "120px",
  height: "30px",
  borderRadius: "6px",
  padding: "3px",
  backgroundColor: theme.palette.background.neutral,
}));

const CustomButton = styled(Button)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "275px",
  },
  height: "55px",
  fontSize: "14px",
}));

const CulturefitTest: React.FC<{
  resultsSent?: any;
  abortTest?: any;
  onboarding?: boolean;
  nextStep?: any;
  personId?: string;
}> = ({ resultsSent, abortTest, onboarding, nextStep, personId }) => {
  const { t } = useTranslation("common");
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressUnit, setProgressUnit] = useState(0);
  const [startTimestamp, setStartTimestamp] = useState(0);
  const [questions, setQuestions] = useState<any>([]);
  const [steps, setSteps] = useState<any>([]);
  const [formModel, setFormModel] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { data, loading } = useFetch(TestsAPI.getById("cf"));
  const navigate = useNavigate();

  const extractTrueQuestions = (stepsArr: any): any => {
    let questArr: any = [];
    stepsArr.forEach((step: any) => {
      questArr = questArr.concat(
        step.questions.filter((question: any) => question.type !== "label")
      );
    });
    return questArr;
  };

  const init = (stepsArrOld: any, pending: any): void => {
    const stepsArr: any = handleNewFormat(stepsArrOld);

    const newArr = stepsArr.map((a: any) => ({
      ...a,
      questions: a.questions.map((b: any) => ({ ...b })),
    }));
    setSteps(newArr);
    !pending && setFormModel(createFormModel(newArr));
    setStartTimestamp(+new Date());
    setQuestions(extractTrueQuestions(newArr));
    setProgressUnit((1 / Object.keys(createFormModel(newArr)).length) * 100);
  };

  const createFormModel = (questArr: any): object => {
    const form: any = {};

    questArr.forEach((step: any) => {
      step.questions
        .filter((question: any) => question.type !== "label")
        .forEach((question: any) => {
          form[question.code] = question.type === "checkboxList" ? {} : null;
        });
    });

    return form;
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const next = () => {
    if (isStepValid(steps[currentStep])) {
      setIsSubmitted(false);
      setCurrentStep(currentStep + 1);
      const scrollDiv: any = document.getElementById(`quest-ctn`)?.offsetTop;
      scrollDiv &&
        window.scrollTo({
          top: onboarding ? 0 : scrollDiv,
          behavior: "smooth",
        });
    } else {
      setIsSubmitted(true);
    }
  };

  const autoFillForm = () => {
    if (
      process.env.REACT_APP_ENV !== "dev" &&
      process.env.REACT_APP_ENV !== "release"
    )
      return;

    setFormModel({
      valeur: { A: true, B: true, C: true },
      remuneration: "B",
      avantageA: "A",
      avantageB: "A",
      avantageC: "C",
      avantageD: "B",
      avantageE: "C",
      avantageF: "C",
      avantageG: "B",
      avantageH: "A",
      avantageI: "B",
      avantageJ: "B",
      formation: "A",
      evolution: "A",
      reconnaissance: "B",
      managementA: "A",
      managementB: "B",
      managementC: "C",
      managementD: "B",
      managementE: "B",
      managementF: "C",
      expert: "B",
      responsable: "B",
      challenge: "A",
      devdurable: "B",
      egalite: "A",
      creative: "B",
      innovation: "A",
      teamwork: "B",
      ambianceA: "B",
      ambianceB: "B",
      ambianceC: "C",
      ambianceD: "C",
      ambianceE: "C",
      ambianceF: "C",
      bonus: { D: true, E: true, F: true },
    });
    setCurrentStep(steps.length - 1);
    setProgress(100);
  };

  const finishLater = () => {
    if (onboarding) {
      const newState: any = Utils.getCookie(`${personId}_do_later_step`) || [];
      newState?.push("valors");
      Utils.setLongCookies(`${personId}_do_later_step`, newState);
    }
    localStorage.setItem(`cf_pending_${personId}`, JSON.stringify(formModel));
    localStorage.setItem(`cf_progress_${personId}`, JSON.stringify(progress));
    localStorage.setItem(`cf_step_${personId}`, JSON.stringify(currentStep));
    onboarding ? nextStep() : navigate("/app/tests");
  };

  const isStepValid = (step: any) => {
    const formModelKeys: String[] | any = [];
    step?.questions.map(
      (quest: any) => quest.type !== "label" && formModelKeys.push(quest.code)
    );
    if (formModel) {
      const validQuestions = formModelKeys.filter((key: string) => {
        if (formModel[key] && formModel[key].constructor === Object) {
          const quantity = questions.filter(
            (question: any) => question.code === key
          )[0]?.answerQuantity;
          return isCheckboxlistAnswersQuantityValid(formModel, key, quantity);
        } else {
          return formModel[key];
        }
      });
      return validQuestions.length === formModelKeys.length;
    }
  };

  const getCheckboxlistValidAnswers = (question: any): string[] => {
    return Object.keys(question).filter((key) => !!question[key]);
  };

  const isCheckboxlistAnswersQuantityValid = (
    model: any,
    code: string,
    quantity: number
  ): boolean => {
    const question: any = model[code];
    const currentQuantity: any = getCheckboxlistValidAnswers(question).length;
    return currentQuantity === quantity;
  };

  const updateQuestionDisabled = (question: any): void => {
    question.disabled = isCheckboxlistAnswersQuantityValid(
      formModel,
      question.code,
      question.answerQuantity
    );
  };

  const updateProgress = (): void => {
    const formModelKeys = Object.keys(formModel);
    const validQuestions = formModelKeys.filter((key) => {
      if (formModel[key] && formModel[key].constructor === Object) {
        const quantity = questions.filter(
          (question: any) => question.code === key
        )[0]?.answerQuantity;
        return isCheckboxlistAnswersQuantityValid(formModel, key, quantity);
      } else {
        return formModel[key];
      }
    });
    setProgress(validQuestions.length * progressUnit);
  };

  const updateForm = (quest: any, event: any) => {
    const newForm = { ...formModel };
    if (quest.answerQuantity > 1) {
      newForm[quest.code][event] = newForm[quest.code][event] ? false : true;
    } else {
      newForm[quest.code] = event;
    }
    setFormModel(newForm);
  };

  const generateResults = (form: any): object => {
    const results: any = {};

    Object.keys(form).forEach((key) => {
      if (form[key] && typeof form[key] === "object") {
        results[key] = getCheckboxlistValidAnswers(form[key]);
      } else {
        results[key] = form[key];
      }
    });

    return results;
  };

  const prepareSave = (formModel: object) => {
    return {
      duration: getDuration(startTimestamp),
      raw: generateResults(formModel),
      test: "/api/tests/cf",
    };
  };

  const onSubmit = async () => {
    setSubmitted(true);
    const dataModel = prepareSave(formModel);
    try {
      await ResultsAPI.post(dataModel);
      resultsSent(true);
      localStorage.removeItem(`cf_pending_${personId}`);
      localStorage.removeItem(`cf_progress_${personId}`);
      localStorage.removeItem(`cf_step_${personId}`);
      if (onboarding) {
        const newState: any =
          Utils.getCookie(`${personId}_do_later_step`) || [];
        if (newState?.includes("valors")) {
          newState.splice(
            newState?.findIndex((el: any) => el === "valors"),
            1
          );
          Utils.setLongCookies(`${personId}_do_later_step`, newState);
        }
        const newStateComplete: any =
          Utils.getCookie(`${personId}_completed_step`) || [];
        if (!newStateComplete?.includes("valors")) {
          newStateComplete.push("valors");
          Utils.setLongCookies(`${personId}_completed_step`, newStateComplete);
        }
      }
      setSubmitted(false);
    } catch (err) {
      console.error("cf error", err);
      toast.error(t("errors.error_append"));
    }
  };

  useEffect(() => {
    const cf_pending = localStorage.getItem(`cf_pending_${personId}`);
    const cf_progress = localStorage.getItem(`cf_progress_${personId}`);
    const cf_step = localStorage.getItem(`cf_step_${personId}`);

    if (cf_pending && cf_progress && cf_step) {
      // setFormModel(JSON.parse(cf_pending));
      // setProgress(parseInt(cf_progress));
      // setCurrentStep(parseInt(cf_step));
    }

    data && init(data, cf_pending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {loading ? (
        <Loader noBackground={true} />
      ) : (
        <Container>
          <Box width="100%">
            {!onboarding && (
              <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
                <HeadText>
                  <Typography fontWeight="bold" variant="body1">
                    {t("tests.page.your_test")}
                  </Typography>
                </HeadText>
              </Box>
            )}
            <LinearProgressBar
              progress={progress}
              type="cf"
              dbClick={autoFillForm}
            />
          </Box>
          <Box id="quest-ctn">
            {steps[currentStep]?.questions.map((quest: any, index: number) => (
              <Box key={index} sx={{ mt: 2 }} width="100%">
                {quest.type !== "inline" && (
                  <Typography variant="h5" component="h6" sx={{ mb: 2, mt: 2 }}>
                    {quest.label}
                  </Typography>
                )}
                <FormGroup
                  row
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: quest.type === "checkboxList" ? "center" : "",
                    width: "100%",
                  }}
                >
                  {quest.type === "checkboxList" ? (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: { xs: "350px", sm: "500px" } }}
                    >
                      <Box
                        display="flex"
                        justifyContent="start"
                        flexDirection="column"
                      >
                        {quest.answers
                          .slice(0, quest.answers.length / 2)
                          .map((answer: any) => (
                            <FormControlLabel
                              key={answer?.["@id"]}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  color:
                                    isSubmitted &&
                                    !formModel[quest?.code][answer.value] &&
                                    !isStepValid(steps[currentStep])
                                      ? "red !important"
                                      : "",
                                },
                              }}
                              control={
                                <Checkbox
                                  disabled={
                                    quest.disabled &&
                                    !formModel[quest?.code][answer.value]
                                  }
                                  onChange={() => {
                                    updateForm(quest, answer.value);
                                    updateQuestionDisabled(quest);
                                    updateProgress();
                                  }}
                                  checked={
                                    formModel[quest?.code][answer?.value] ||
                                    false
                                  }
                                  name={answer.label}
                                  value={answer.value}
                                />
                              }
                              label={answer.label}
                            />
                          ))}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="start"
                        flexDirection="column"
                      >
                        {quest.answers
                          .slice(quest.answers.length / 2)
                          .map((answer: any) => (
                            <FormControlLabel
                              key={answer?.["@id"]}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  color:
                                    isSubmitted &&
                                    !formModel[quest?.code][answer.value] &&
                                    !isStepValid(steps[currentStep])
                                      ? "red !important"
                                      : "",
                                },
                              }}
                              control={
                                <Checkbox
                                  disabled={
                                    quest.disabled &&
                                    !formModel[quest?.code][answer.value]
                                  }
                                  onChange={() => {
                                    updateForm(quest, answer.value);
                                    updateQuestionDisabled(quest);
                                    updateProgress();
                                  }}
                                  checked={
                                    formModel[quest?.code][answer?.value] ||
                                    false
                                  }
                                  name={answer.label}
                                  value={answer.value}
                                />
                              }
                              label={answer.label}
                            />
                          ))}
                      </Box>
                    </Box>
                  ) : quest.type === "inline" ? (
                    <Box width="100%">
                      <Box display="flex" alignItems="center" flexWrap="wrap">
                        {isSubmitted &&
                          (!formModel[quest.code] ? (
                            <CloseIcon sx={{ mr: 2 }} color="error" />
                          ) : (
                            <CheckIcon sx={{ mr: 2 }} color="success" />
                          ))}
                        <Typography
                          variant="body1"
                          textAlign="left"
                          sx={{ maxWidth: "600px" }}
                        >
                          {quest.label}
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ ml: "auto" }}
                        >
                          {quest.answers.map((answer: any) => (
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "12px",
                                  color:
                                    isSubmitted && !formModel[quest.code]
                                      ? "red !important"
                                      : "",
                                },
                              }}
                              key={answer["@id"]}
                              name={answer.label}
                              value={answer.value}
                              labelPlacement="top"
                              onChange={() => {
                                updateForm(quest, answer.value);
                                updateProgress();
                              }}
                              checked={
                                formModel[quest.code] === answer.value || false
                              }
                              control={<Radio />}
                              label={capitalizeFirstLetter(answer.label)}
                            />
                          ))}
                        </Box>
                      </Box>
                      <Divider
                        sx={{
                          width: "100%",
                          mt: 1,
                        }}
                      />
                    </Box>
                  ) : (
                    quest.type === "radioList" &&
                    quest.answers.map((answer: any) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        key={answer["@id"]}
                      >
                        {isSubmitted &&
                          (!formModel[quest.code] ? (
                            <CloseIcon sx={{ mr: 2 }} color="error" />
                          ) : (
                            <CheckIcon sx={{ mr: 2 }} color="success" />
                          ))}
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              textAlign: "left",
                            },
                          }}
                          name={answer.label}
                          value={answer.value}
                          onChange={() => {
                            updateForm(quest, answer.value);
                            updateProgress();
                          }}
                          checked={
                            formModel[quest.code] === answer.value || false
                          }
                          control={<Radio />}
                          label={capitalizeFirstLetter(answer.label)}
                        />
                      </Box>
                    ))
                  )}
                </FormGroup>
              </Box>
            ))}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            sx={{ mb: 3, mt: 3, gap: 1 }}
          >
            <CustomButton
              sx={{ m: 0.5 }}
              disabled={currentStep === 0}
              variant="outlined"
              color="secondary"
              onClick={prev}
            >
              <ArrowBackIosIcon
                fontSize="small"
                sx={{ width: "15px", height: "15px" }}
              />
              {t("btn.prev")}
            </CustomButton>
            <CustomButton
              sx={{ m: 0.5 }}
              disabled={submitted}
              variant="contained"
              onClick={() => (progress < 100 ? next() : onSubmit())}
            >
              {submitted && <CircularProgress color="inherit" size={20} />}
              {t(`btn.${progress < 100 ? "next" : "valid"}`)}
              {progress < 100 && (
                <ArrowForwardIosIcon
                  fontSize="small"
                  sx={{ width: "15px", height: "15px" }}
                />
              )}
            </CustomButton>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            sx={{ gap: 2 }}
          >
            <Button
              sx={{
                width: "auto",
                fontSize: "12px",
                p: "10px 15px",
              }}
              variant="text"
              color="secondary"
              onClick={() => abortTest(true)}
            >
              <ReplayIcon sx={{ width: "15px", height: "15px" }} /> &nbsp;
              {t("big5.test.retry")}
            </Button>
            {progress < 100 && (
              <Button
                sx={{
                  width: "auto",
                  fontSize: "12px",
                  p: "10px 15px",
                }}
                variant="text"
                color="secondary"
                onClick={() => setOpenModal(true)}
              >
                {t("tests.modal.do_later")}
              </Button>
            )}
          </Box>
          <FinishLaterTestModal
            open={openModal}
            close={() => setOpenModal(false)}
            finishLater={() => finishLater()}
          />
        </Container>
      )}
    </>
  );
};

export default CulturefitTest;

const handleNewFormat = (data: any) => {
  const { questions } = data;

  const order = [
    "val",
    "remuAvt",
    "formEvo",
    "recoMgt",
    "exp",
    "respCha",
    "devEga",
    "creaInno",
    "teamAmb",
    "",
  ];
  const newData = order.map((key: any, index: number) => {
    const q = questions.filter((question: any) => question.groupKey === key);

    return {
      id: index,
      key: key,
      questions: q,
      label: q[0].groupName,
    };
  });

  return newData;
};
