import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HomeIcon from "../icons/menu/home";
import JobSuggestIcon from "../icons/menu/job_suggest";
import ProfileIcon from "../icons/menu/profile";
import WorkIcon from "../icons/menu/work";
import NavigationRenderer from "./NavigationRenderer";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import ResourcesMenuIcon from "../components/ressources/ResourcesMenuIcon";

const RHContextMenu: React.FC<any> = ({ handleLinkClick }) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const menu = useSelector((state: any) => state.menu);
  const location = useLocation();
  const links = [
    {
      icon: (
        <HomeIcon
          active={location.pathname.includes("app/home")}
          context={context}
          size={25}
        />
      ),
      label: t("home"),
      path: "/app/home",
      active: location.pathname.includes("app/home"),
    },
    {
      label: (
        <span style={{ textTransform: "capitalize" }}>
          {t("rh.menu.collabs")}
        </span>
      ),
      icon: (
        <ProfileIcon
          active={location.pathname.includes("employees")}
          context={context}
          size={25}
        />
      ),
      path: "/app/employees",
      active: location.pathname.includes("app/employees"),
    },
    menu?.works && {
      icon: (
        <WorkIcon
          active={location.pathname.includes("app/works")}
          context={context}
          size={25}
        />
      ),
      label: t("work_suggest.work_label"),
      path: "/app/work/list",
      active: location.pathname.includes("app/work"),
    },
    {
      icon: <BuildOutlinedIcon />,
      label: t("rh.menu.skills"),
      path: "/app/skills",
      active: location.pathname.includes("app/skills"),
    },
    menu?.jobs && {
      icon: (
        <JobSuggestIcon
          active={location.pathname.includes("app/job/suggest")}
          context={context}
          size={25}
        />
      ),
      label: t("job_suggest.title"),
      path: "/app/job/suggest",
      active: location.pathname.includes("app/job/suggest"),
    },
    menu?.resources && {
      icon: <ResourcesMenuIcon />,
      label: t("resources.title"),
      path: "/app/resources",
      active: location.pathname.includes("resources"),
    },
  ].filter((el: any) => el);

  return <NavigationRenderer links={links} handleLinkClick={handleLinkClick} />;
};

export default RHContextMenu;
