import {
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled,
  Stack,
} from "@mui/material";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ControlledEmailInput from "../../../forms/ControlledEmailInput";

const SButton = styled(Button)`
  height: 55px;
  width: 200px;
  font-size: 12px;
`;

const SCheckbox = styled(Checkbox)`
  padding-top: 5px;
  padding-bottom: 5px;
`;

type IFormInput = {
  email: string;
  manager: {
    projects: boolean;
    tests: boolean;
  };
  rh: {
    isSubscribed: boolean;
    projects: boolean;
    tests: boolean;
  };
};

const SharesCollabForm: React.FC<any> = ({
  form,
  managerInfo,
  handler,
  loading,
}) => {
  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: {
      ...{
        email: managerInfo?.[0]?.email ?? "",
        manager: {
          projects: false,
          tests: false,
        },
        rh: {
          isSubscribed: false,
          projects: false,
          tests: false,
        },
      },
      ...form,
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    handler(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={2}>
        <div>
          <Typography color="primary" variant="h5">
            Manager
          </Typography>
          <Stack spacing={1}>
            <div>
              <Typography fontWeight="bold" sx={{ mb: 0.5 }}>
                Ajouter mon manager
              </Typography>
              <ControlledEmailInput control={control} name={"email"} required />
            </div>
            <div>
              <Typography fontWeight="bold">Rendre visible :</Typography>
              <FormGroup>
                <Controller
                  name="manager.projects"
                  control={control}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <FormControlLabel
                      control={
                        <SCheckbox
                          checked={value}
                          onChange={(e: any) => onChange(e.target.checked)}
                        />
                      }
                      label="Projets"
                    />
                  )}
                />
                <Controller
                  name="manager.tests"
                  control={control}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <FormControlLabel
                      control={
                        <SCheckbox
                          checked={value}
                          onChange={(e: any) => onChange(e.target.checked)}
                        />
                      }
                      label="Tests"
                    />
                  )}
                />
              </FormGroup>
            </div>
          </Stack>
        </div>
        {/* <div>
          <Stack spacing={1}>
            <Typography sx={{ color: "#1fc726" }} variant="h5">
              Ressources humaines
            </Typography>
            <div>
              <Typography fontWeight="bold">Rendre visible :</Typography>
              <FormGroup>
                <Controller
                  name="rh.projects"
                  control={control}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <FormControlLabel
                      control={
                        <SCheckbox
                          checked={value}
                          onChange={(e: any) => onChange(e.target.checked)}
                        />
                      }
                      label="Projets"
                    />
                  )}
                />
                <Controller
                  name="rh.tests"
                  control={control}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <FormControlLabel
                      control={
                        <SCheckbox
                          checked={value}
                          onChange={(e: any) => onChange(e.target.checked)}
                        />
                      }
                      label="Tests"
                    />
                  )}
                />
              </FormGroup>
            </div>
          </Stack>
        </div> */}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <SButton
            sx={{ textAlign: "center" }}
            variant="contained"
            type="submit"
            disabled={loading}
          >
            Enregistrer
          </SButton>
        </div>
      </Stack>
    </form>
  );
};

export default SharesCollabForm;
