import { withStyles } from "@mui/styles";
import { LinearProgress } from "@mui/material";

const LinearProgressBar: React.FC<{
  progress: number;
  type: string;
  dbClick: any;
}> = ({ progress, type, dbClick }) => {
  const BorderLinearProgress = withStyles((theme: any) => ({
    root: {
      width: "100%",
      height: 20,
      borderRadius: 10,
      backgroundColor: theme.palette.action.disabledBackground,
    },
    bar: {
      borderRadius: 10,
      background: type === "cf" ? theme.palette.gradients.cf_linear : "auto",
    },
  }))(LinearProgress);
  return (
    <BorderLinearProgress
      onDoubleClick={dbClick}
      variant="determinate"
      value={Math.round(progress)}
    />
  );
};

export default LinearProgressBar;
