import { Button, Box, Chip, Popover, Divider, styled } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import React, { useEffect, useState } from "react";
import MoodFilter from "./MoodFilter";

const Footer = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 5px;
`;

const Header = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

const PreppendContainer = styled("div")`
  margin-right: -10px;
`;

const MoodButton: React.FC<any> = ({ mood, handleChange, preppend }) => {
  console.log("MOOD", mood);
  const [value, setValue] = useState(mood ?? [0, 1, 2, 3]);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [active, setActive] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "mood_popover" : undefined;

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setActive(mood?.length > 0);
    if (open) {
      setValue(mood);
    }
  }, [mood, open]);

  const onChange = (v: any) => {
    setValue(v);
  };

  const submit = () => {
    handleChange(value?.length > 0 ? value : false);
    setAnchorEl(null);
  };

  return (
    <div>
      <Chip
        aria-describedby={"mood_popover"}
        sx={{
          pl: 2,
          pr: 2,
          m: 0.5,
          cursor: "pointer",
          fontWeight: active ? "bold" : "",
          border: active ? "2px solid" : "1px solid",
        }}
        label={"Mood"}
        color="primary"
        variant="outlined"
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ minWidth: "150px" }}>
          {/* <FormControl sx={{ padding: 2, width: "100%" }}>
            <Header>
              <FormLabel id="demo-radio-buttons-group-label">
                Afficher
              </FormLabel>
              <PreppendContainer>{preppend}</PreppendContainer>
            </Header>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={value}
              onChange={onChange}
            >
              <FormControlLabel value={6} control={<Radio />} label="Tous" />
              <FormLabel id="demo-radio-buttons-group-label">
                Uniquement
              </FormLabel>
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="3 étoiles"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="2 étoiles"
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="1 étoile"
              />
            </RadioGroup>
          </FormControl> */}

          <Header>
            <FormLabel id="demo-radio-buttons-group-label">Afficher</FormLabel>
            <PreppendContainer>{preppend}</PreppendContainer>
          </Header>

          <MoodFilter value={mood} onChange={onChange} />
          <Divider />
          <Footer>
            <Button
              sx={{ fontSize: "14px", fontWeight: "bold" }}
              onClick={submit}
            >
              Valider
            </Button>
          </Footer>
        </Box>
      </Popover>
    </div>
  );
};

export default MoodButton;
