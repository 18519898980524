import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TestBadge from "../profile/badges/TestBadge";
import { Box, Typography, Tooltip, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";

const CardTest: any = styled(Box, {
  shouldForwardProp: (props) =>
    props !== "checked" &&
    props !== "code" &&
    props !== "disabled" &&
    props !== "canEdit",
})(({ theme, checked, code, disabled, canEdit }: any) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  width: "135px",
  height: "135px",
  borderRadius: theme.shape.borderRadiusSm,
  border: checked ? "none !important" : "1px solid #EAEAEA !important",
  position: "relative",
  backgroundImage: checked
    ? code === "personality"
      ? theme.palette.gradients.b5
      : code === "valors"
      ? theme.palette.gradients.cf
      : code === "inc"
      ? theme.palette.gradients.inc
      : code === "mood"
      ? theme.palette.gradients.skills
      : "auto"
    : "auto",
  opacity: disabled ? "0.6" : "auto",
  cursor: disabled
    ? "not-allowed !important"
    : canEdit
    ? "pointer !important"
    : "default",
}));

const BoxTest = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  backgroundColor: "white",
});

const ItemTestsForm: React.FC<{
  code: string;
  initTests?: any;
  canEdit?: boolean;
  displayTooltip?: boolean;
  folder?: any;
  index: number;
  checkTest?: any;
  checked: boolean;
  invitedModal?: boolean;
}> = ({
  code,
  initTests,
  canEdit,
  displayTooltip,
  folder,
  index,
  checkTest,
  checked,
  invitedModal,
}) => {
  const { t }: any = useTranslation("common");
  // const context = useSelector((state: any) => state.auth.context);

  return (
    <CardTest
      checked={checked}
      code={code}
      canEdit={canEdit}
      sx={{
        m: 1,
        ml: index === 0 ? 0 : 1,
      }}
      onClick={(e: any) => {
        e.preventDefault();
        checkTest?.(code, !checked);
      }}
    >
      {(code === "personality" ||
        code === "valors" ||
        code === "inc" ||
        code === "mood") &&
        displayTooltip && (
          <Tooltip
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    `tests.tooltip.${
                      code === "personality"
                        ? "b5"
                        : code === "valors"
                        ? "cf"
                        : code === "inc"
                        ? "inc"
                        : code === "mood"
                        ? "mood"
                        : ""
                    }`
                  ),
                }}
              />
            }
          >
            <HelpOutlineIcon
              color="primary"
              sx={{
                width: "20px",
                position: "absolute",
                top: "5px",
                right: "5px",
                color: checked ? "white" : "",
              }}
            />
          </Tooltip>
        )}
      {checked ? (
        <BoxTest>
          <img
            width={26}
            src={`/static/icons/${
              code === "personality"
                ? "b5"
                : code === "valors"
                ? "cf"
                : code === "inc"
                ? "inc"
                : code === "skills"
                ? "skills_abilities"
                : code === "mood"
                ? "mood"
                : ""
            }_logo.svg`}
            alt="test icon"
          />
        </BoxTest>
      ) : (
        <TestBadge
          type={
            code === "personality"
              ? "Big5"
              : code === "valors"
              ? "CultureFit"
              : code === "inc"
              ? "Inc"
              : code === "skills"
              ? "skills"
              : code === "mood"
              ? "mood"
              : ""
          }
          size={50}
          padding={12}
          noMargin
          inFolder
        />
      )}
      <Typography
        variant="body2"
        fontWeight="bold"
        sx={{
          mt: 1,
          maxWidth: code === "valors" ? "10ch" : "13ch",
          color: checked
            ? "white !important"
            : code === "mood"
            ? "#FF9300"
            : code === "personality"
            ? "#3A78FF"
            : code === "valors"
            ? "#FE7B89"
            : code === "inc"
            ? "#EA80FC"
            : "",
        }}
      >
        {t(
          code === "skills"
            ? "tests.all_skills"
            : code === "inc"
            ? "tests.inc"
            : code === "personality"
            ? "tests.b5"
            : code === "valors"
            ? "tests.cf"
            : code === "mood"
            ? "Mood"
            : ""
        )}
      </Typography>
    </CardTest>
  );
};

export default ItemTestsForm;
