import React, { useContext, useState, useEffect } from "react";
import { IncTestContext } from "./IncTestContext";
import {
  Badge,
  Box,
  Dialog,
  LinearProgress,
  linearProgressClasses,
  IconButton,
  styled,
} from "@mui/material";
import SplashScreenTest from "../../common/SplashScreenTest";
import IncTestPart from "./IncTestPart";
import IncTestText from "./IncTestText";
import WestIcon from "@mui/icons-material/West";

const GridBox: any = styled(Box, {
  shouldForwardProp: (props) => props !== "grid",
})(({ theme, grid }: any) =>
  grid
    ? {
        [theme.breakpoints.down("md")]: {
          display: "block",
        },
        [theme.breakpoints.up("md")]: {
          display: "grid",
        },
        gridTemplateColumns: "545px 1fr", // 730 intro, 545 question
        gridGap: "20px",
        overflow: "hidden",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
      }
    : {
        display: "flex",
        maxWidth: "100%",
        overflow: "hidden",
        paddingBottom: "20px",
        justifyContent: "center",
      }
);

const BorderLinearProgress = styled(LinearProgress)(({ theme }: any) => ({
  height: 17,
  borderRadius: 9,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 9,
    background: theme.palette.gradients.inc_linear,
  },
}));

const SideB: any = styled(Box, {
  shouldForwardProp: (props) => props !== "onBoarding" && props !== "isIntro",
})(({ theme, onBoarding, isIntro }: any) => ({
  width: "100%",
  height: "100%",
  minHeight: isIntro && onBoarding ? "40vh !important" : "",
  [theme.breakpoints.up("md")]: {
    minHeight: isIntro && !onBoarding ? "70vh !important" : "",
  },
}));

const CardBox: any = styled(Box, {
  shouldForwardProp: (props) => props !== "isIntro",
})(({ isIntro }: any) =>
  isIntro
    ? { height: "100%" }
    : {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }
);

const IncTest: React.FC<any> = ({ onBoarding, nextStep }) => {
  const [mounted, setMounted] = useState(true);
  const {
    question,
    loading,
    openSplashScreen,
    setIsOnboarding,
    handlePreviousTest,
    handleNextTest,
    handleSkip,
  } = useContext(IncTestContext);

  const isIntro = question?.questions?.[0]?.groupKey?.split("_")[1] === "01";
  const canGoBack = question?.questions?.[0]?.previousGroup;

  useEffect(() => {
    setIsOnboarding(onBoarding ?? false);
  }, [onBoarding, setIsOnboarding]);

  useEffect(() => {
    let to: any;
    if (loading) {
      setMounted(false);
    } else {
      to = setTimeout(() => {
        setMounted(true);
      }, 200);
    }
    return () => {
      if (to) clearTimeout(to);
    };
  }, [loading]);

  useEffect(() => {
    if (
      onBoarding &&
      question?.questions?.[0]?.groupKey?.split("_")[1] === "01" &&
      question?.questions?.[0]?.groupKey?.split("_")[2] === "01"
    ) {
      handleNextTest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  return (
    <div>
      {!isIntro && (
        <Box display="flex" width="100%" alignItems="center">
          {canGoBack &&
            question?.questions?.[0]?.groupKey !== "01_02_01" &&
            question?.questions?.[0]?.groupKey !== "02_02_01" &&
            question?.questions?.[0]?.groupKey !== "03_02_01" &&
            question?.questions?.[0]?.groupKey !== "04_02_01" && (
              <IconButton
                onClick={handlePreviousTest}
                sx={{ background: "#c4cdd57a", mr: 2 }}
              >
                <WestIcon sx={{ color: "white" }} />
              </IconButton>
            )}
          <Box sx={{ width: "100%", mb: 2, mt: 2 }}>
            {question?.completion && (
              <Badge
                badgeContent={`${
                  question?.completion > 99 ? 100 : question?.completion
                }%`}
                color="primary"
                sx={{
                  "& .MuiBadge-colorPrimary": {
                    backgroundColor: "white",
                    boxShadow: "0px 10px 50px #00000033",
                    color: "#ADB6C7",
                    width: "41px",
                    height: "41px",
                    borderRadius: "50%",
                    fontSize: "14px",
                  },
                  left: `${
                    question?.completion -
                    (question?.completion > 99 ? 1.5 : 0.5)
                  }% !important`,
                  mt: -4,
                }}
              ></Badge>
            )}
            <BorderLinearProgress
              onDoubleClick={handleSkip}
              variant="determinate"
              value={
                (question?.completion > 99 ? 100 : question?.completion) ?? 0
              }
            />
          </Box>
        </Box>
      )}
      <GridBox grid={isIntro}>
        {isIntro && !onBoarding && (
          <IncTestText canGoBack={canGoBack} group={question?.group} />
        )}
        <Box>
          <SideB isIntro={isIntro} onBoarding={onBoarding}>
            <Box
              sx={{
                height: "100%",
                boxShadow: isIntro ? "none" : "",
                marginLeft: 1,
                marginRight: 1,
              }}
            >
              <CardBox isIntro={isIntro}>
                {question?.questions && (
                  <IncTestPart
                    mounted={mounted}
                    isIntro={isIntro}
                    nextStep={nextStep}
                    onBoarding={onBoarding}
                    progress={question?.completion}
                  />
                )}
              </CardBox>
            </Box>
          </SideB>
        </Box>
      </GridBox>
      <Dialog
        open={openSplashScreen ?? false}
        transitionDuration={{ exit: 1250 }}
      >
        <SplashScreenTest test="inc" />
      </Dialog>
    </div>
  );
};

export default IncTest;
