import { Box, ButtonBase, styled, Typography } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";

const Text = styled(Box)(({ theme }: any) => ({
  background: "white",
  boxShadow: "10px 10px 30px #00000014",
  borderRadius: "16px",
  padding: theme.spacing(2),
  margin: theme.spacing(2),
}));

const CustomButton = styled(ButtonBase)({
  width: "48px",
  height: "48px",
  border: "2px solid",
  color: "white",
  borderRadius: "50px",
  "&.btn1": {
    width: "40px",
    height: "40px",
  },
  "&.btn2": {
    width: "34px",
    height: "34px",
  },
  "&.btn3": {
    width: "28px",
    height: "28px",
  },
  "&.btn4": {
    width: "24px",
    height: "24px",
  },
});

const arr = [
  { type: null, color: "#9DC0FF" },
  { type: "2", color: "#9DC0FF" },
  { type: "3", color: "#9DC0FF" },
  { type: "3", color: "#9DC0FF" },
  { type: "4", color: "#9DC0FF" },
  { type: "4", color: "#818181" },
  { type: "4", color: "#BBA6FD" },
  { type: "3", color: "#BBA6FD" },
  { type: "3", color: "#BBA6FD" },
  { type: "2", color: "#BBA6FD" },
  { type: null, color: "#BBA6FD" },
];

const IncQuestionCursorComponent: React.FC<any> = ({
  slider,
  index,
  handleSliderChange,
  isMobile,
}) => {
  return (
    <Box
      key={"Hello slider 1"}
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
    >
      <Text display="flex" justifyContent="center" textAlign="center">
        <Typography sx={{ width: "250px", fontSize: "16px" }}>
          {slider.textLeft}
        </Typography>
      </Text>

      <Box
        sx={{
          width: isMobile ? "auto" : "380px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row",
          height: isMobile ? "400px" : "auto",
        }}
      >
        {arr.map((bubble: any, i: number) => (
          <CustomButton
            key={i}
            sx={{
              borderColor: `${bubble.color}!important`,
              background: slider.value === i ? bubble.color : "white",
            }}
            onClick={() => handleSliderChange(i, index)}
            className={`btn${bubble.type}`}
          >
            {slider.value === i ? <CheckIcon /> : ""}
          </CustomButton>
        ))}
      </Box>

      <Text
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Typography sx={{ width: "250px", fontSize: "16px" }}>
          {slider.textRight}
        </Typography>
      </Text>
    </Box>
  );
};

export default IncQuestionCursorComponent;
