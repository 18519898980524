import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Big5Container from "../components/big5/Big5Container";
import Page from "../components/Page";
import CardIllustrationTests from "../components/tests/CardIllustrationTests";

const Big5Page = () => {
  const { t }: any = useTranslation("common");
  return (
    <Page title={t("tests.title.b5")}>
      <Container sx={{ pt: 13, pb: 5 }}>
        <CardIllustrationTests test="b5" height="190" />
        <Big5Container />
      </Container>
    </Page>
  );
};

export default Big5Page;
