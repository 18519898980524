import { Box, ButtonBase, styled, Typography } from "@mui/material";
import React from "react";
import HexagonOutlinedIcon from "@mui/icons-material/HexagonOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";

const Square = styled(ButtonBase)(({ theme }: any) => ({
  background: "white",
  borderRadius: "21px",
  padding: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(2),
    width: "151px",
    height: "154px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(1),
    width: "130px",
    height: "127px",
  },
  transition: "all 0.3s ease",
  backgroundColor: "transparent",
  "&:hover": {
    boxShadow: theme.customShadows.z8,
    backgroundColor: "white",
  },
  "&.selected": {
    background: theme.palette.primary[500],
    color: "white",
  },
}));

const Icon = styled(({ icon, ...props }: any) => icon(props))(
  ({ selected }: any) => ({
    color: selected ? "#FFFF" : "#4C6FFF",
    fontSize: "50px",
    transition: "all 0.3s ease",
  })
);

const IncSquareQuestion: React.FC<any> = ({ question, onChange, state }) => {
  const icons = (props: any) => [
    <RadioButtonUncheckedOutlinedIcon {...props} />,
    <HexagonOutlinedIcon {...props} />,
    <CheckBoxOutlineBlankSharpIcon {...props} />,
    <ChangeHistoryIcon {...props} />,
  ];

  return (
    <Box display="flex" justifyContent="center" width="100%" marginBottom={4}>
      <Box
        display="flex"
        flexWrap="wrap"
        width="100%"
        sx={{ justifyContent: { xs: "space-around", md: "space-between" } }}
      >
        {question?.answers?.map((el: any, i: number) => (
          <Square
            onClick={() => onChange(el.value)}
            key={i}
            className={state === el.value ? "selected" : ""}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Icon
                icon={(props: any) => icons(props)[i]}
                selected={state === el.value}
              />
              <Typography sx={{ mt: 2, lineHeight: "18px", fontSize: "16px" }}>
                {el.label}
              </Typography>
            </Box>
          </Square>
        ))}
      </Box>
    </Box>
  );
};

export default IncSquareQuestion;
