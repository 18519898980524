import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Box,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../../contexts/ProfileContext";
import careerItemsAPI from "../../../_api/_careerItemsAPI";
import { modalStyle } from "../../../_utils/modalStyle";
import { toast } from "react-toastify";

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  margin: "auto",
  marginBottom: "11px",
  width: 55,
  height: 55,
  background: theme.palette.primary[500_8],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const style: any = {
  width: "300px",
  padding: "20px",
};

const ExperienceDeleteModal: React.FC<any> = ({
  open,
  handleClose,
  type,
  experience,
}) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const { removeCareerItem } = useContext(ProfileContext);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await careerItemsAPI.delete(experience.id);
      removeCareerItem(experience.id);
      toast.success(
        t(
          `success.${type === "experience" ? "experience" : "training"}_deleted`
        )
      );
    } catch (err) {
      console.error("ERROR deleting careerItem", err);
      toast.error(t("errors.error_append"));
    }
    setLoading(false);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Card sx={{ ...modalStyle, ...style }}>
        <CardContent>
          <Icon>
            <img
              src={`/static/icons/${
                type === "experience" ? "portfolio" : "graduation-hat"
              }.svg`}
              alt={`experience icon`}
              color="primary"
            />
          </Icon>
          <Typography textAlign="center">
            {t(
              `experience_section.delete_${
                type === "experience" ? "exp" : "training"
              }`
            )}{" "}
            ?
          </Typography>
        </CardContent>
        <CardActions>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            sx={{ width: "100%" }}
          >
            <Button
              sx={{ width: "200px", m: 1 }}
              disabled={loading}
              onClick={handleDelete}
              size="large"
              variant="contained"
              color="error"
            >
              {t("btn.delete")}
              {loading && (
                <CircularProgress
                  style={{ width: 20, height: 20, marginLeft: "10px" }}
                />
              )}
            </Button>
            <Button
              sx={{ width: "200px", m: 1 }}
              disabled={loading}
              onClick={handleClose}
              size="large"
            >
              {t("btn.cancel")}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ExperienceDeleteModal;
