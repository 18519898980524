import { ButtonBase, Tooltip, Typography, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Root = styled(ButtonBase)(
  ({ theme }) => `
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.3s linear;
  border: 2px solid #eaeaea;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${theme.palette.primary.main};
  border-radius: 35px;
  width: 140px;
  height: 144px;
  * > path {
    transition: all 0.3s linear;
  }
  * > path.other {
      fill: ${theme.palette.primary.light};
    }
  &.selected {
    color: white;
    background: ${theme.palette.primary.main};
    border-color: ${theme.palette.primary.main};
    * > path.other {
      fill: white;
    }
  }
`
);

const ExperienceSelectorCard: React.FC<{
  type: "simplified" | "trainings" | "works";
  onClick: () => void;
  selected: boolean;
  loading?: boolean;
}> = ({ onClick, type, selected, loading = false }) => {
  const { t } = useTranslation("common");

  const texts = {
    simplified: {
      label: t("invite_form.ver_simplified"),
      tooltip: t(`experiences_selection.simplified`),
      icon: <SimplifiedIcon />,
    },
    trainings: {
      label: t("invite_form.trainings_access"),
      tooltip: t(`trainings.switch_tooltip`),
      icon: <TrainingsIcon />,
    },
    works: {
      label: t("invite_form.works_access"),
      tooltip: t(`experiences_selection.works`),
      icon: <WorkIcon />,
    },
  };

  return (
    <Tooltip
      title={
        <div
          dangerouslySetInnerHTML={{
            __html: texts[type].tooltip,
          }}
        />
      }
    >
      <div>
        <Root
          disabled={loading}
          onClick={onClick}
          className={`${selected ? "selected" : ""}`}
        >
          <div style={{ transform: "scale(0.8)" }}>{texts[type].icon}</div>

          <Typography fontWeight="bold" lineHeight="15px" marginTop="-8px">
            {texts[type].label}
          </Typography>
        </Root>
      </div>
    </Tooltip>
  );
};

export default ExperienceSelectorCard;

const SimplifiedIcon = () => (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="other"
      d="M34.5 17.25C34.5 20.3 35.7116 23.2251 37.8683 25.3817C40.0249 27.5384 42.95 28.75 46 28.75C49.05 28.75 51.9751 27.5384 54.1317 25.3817C56.2884 23.2251 57.5 20.3 57.5 17.25C57.5 14.2 56.2884 11.2749 54.1317 9.11827C51.9751 6.9616 49.05 5.75 46 5.75C42.95 5.75 40.0249 6.9616 37.8683 9.11827C35.7116 11.2749 34.5 14.2 34.5 17.25Z"
    />
    <path
      d="M9.88475 66.7964C10.6398 68.1043 11.6451 69.2508 12.8432 70.1702C14.0414 71.0896 15.4088 71.7641 16.8676 72.155C18.3264 72.5459 19.8479 72.6456 21.3452 72.4485C22.8426 72.2514 24.2864 71.7613 25.5943 71.0062C26.9022 70.2511 28.0486 69.2457 28.968 68.0475C29.8873 66.8494 30.5617 65.4818 30.9525 64.023C31.3434 62.5642 31.4431 61.0427 31.2459 59.5454C31.0487 58.0481 30.5585 56.6043 29.8033 55.2964C29.0483 53.9884 28.043 52.842 26.8448 51.9226C25.6467 51.0032 24.2792 50.3287 22.8204 49.9378C21.3617 49.5469 19.8402 49.4472 18.3428 49.6443C16.8455 49.8414 15.4017 50.3315 14.0938 51.0866C12.7858 51.8417 11.6395 52.8471 10.7201 54.0453C9.80073 55.2434 9.12637 56.611 8.73553 58.0698C8.34468 59.5286 8.24501 61.0501 8.44219 62.5474C8.63937 64.0447 9.12955 65.4885 9.88475 66.7964Z"
      className="other"
    />
    <path
      d="M66.4473 71.1118C67.7551 71.8669 69.1989 72.357 70.6962 72.5541C72.1935 72.7512 73.7149 72.6515 75.1737 72.2606C76.6324 71.8697 77.9999 71.1954 79.198 70.276C80.3961 69.3567 81.4015 68.2103 82.1566 66.9025C82.9117 65.5946 83.4018 64.1508 83.5989 62.6535C83.796 61.1562 83.6963 59.6348 83.3054 58.176C82.9145 56.7173 82.2402 55.3498 81.3208 54.1517C80.4015 52.9536 79.2551 51.9483 77.9473 51.1932C76.6394 50.4381 75.1956 49.948 73.6983 49.7509C72.201 49.5537 70.6796 49.6534 69.2208 50.0443C67.7621 50.4352 66.3946 51.1096 65.1965 52.0289C63.9984 52.9483 62.9931 54.0946 62.238 55.4025C61.4829 56.7103 60.9928 58.1541 60.7957 59.6514C60.5985 61.1487 60.6982 62.6701 61.0891 64.1289C61.48 65.5876 62.1544 66.9551 63.0737 68.1532C63.9931 69.3513 65.1394 70.3567 66.4473 71.1118Z"
      className="other"
    />
    <path
      d="M16.3248 45.7574C15.9475 45.7574 15.5701 45.7215 15.1838 45.6406C12.0662 45.0117 10.0537 41.984 10.6736 38.8754C11.4912 34.8145 12.9557 31.0051 15.0401 27.573C17.0975 24.1859 19.7029 21.2211 22.7936 18.7594C25.8752 16.3066 29.3701 14.402 33.1795 13.1082C37.0338 11.8055 41.1307 11.1406 45.3713 11.1406C48.5428 11.1406 51.1213 13.7191 51.1213 16.8906C51.1213 20.0621 48.5428 22.6406 45.3713 22.6406C33.4041 22.6406 24.2131 29.9 21.949 41.1395C21.401 43.8707 19.0022 45.7574 16.3248 45.7574ZM46.0002 82.0273C39.5764 82.0273 33.3502 80.3832 27.9865 77.2566C22.7576 74.2199 18.3553 69.7816 15.2377 64.427C13.6385 61.6777 14.5729 58.1648 17.3131 56.5656C20.0623 54.9664 23.5752 55.9008 25.1744 58.641C29.5768 66.1969 37.1596 70.5273 46.0002 70.5273C48.4889 70.5273 50.9326 70.159 53.2776 69.4312C56.3143 68.4879 59.5307 70.1859 60.474 73.2227C61.4174 76.2594 59.7194 79.4758 56.6826 80.4191C53.2326 81.4883 49.6389 82.0273 46.0002 82.0273ZM72.585 66.3047C71.6057 66.3047 70.6174 66.0531 69.71 65.532C66.9608 63.9418 66.0264 60.4199 67.6166 57.6707C69.6291 54.2027 70.5276 50.6 70.5276 46C70.5276 39.4504 67.976 33.2871 63.3401 28.6602C61.094 26.4141 61.094 22.7754 63.3401 20.5293C65.5861 18.2832 69.2248 18.2832 71.4709 20.5293C74.7772 23.8355 77.3736 27.6898 79.1885 31.9754C81.0662 36.4227 82.0186 41.1395 82.0186 46C82.0186 52.6484 80.599 58.1918 77.5623 63.4387C76.5022 65.2805 74.5705 66.3047 72.585 66.3047Z"
      fill="currentColor"
    />
  </svg>
);

const TrainingsIcon = () => (
  <svg
    width="84"
    height="84"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7223 60.375C10.7789 60.375 9.86016 59.8582 9.39258 58.9641C6.68555 53.7633 5.25 47.898 5.25 42C5.25 37.0371 6.21797 32.2301 8.1375 27.6938C9.99141 23.3133 12.641 19.384 16.0125 16.0125C19.384 12.641 23.3133 9.99141 27.6938 8.1375C32.2301 6.21797 37.0371 5.25 42 5.25C45.8801 5.25 49.7027 5.84883 53.3531 7.03828C54.7313 7.48945 55.4859 8.96602 55.043 10.3441C54.5918 11.7223 53.1152 12.477 51.7371 12.034C48.6035 11.0168 45.3305 10.5 42 10.5C24.634 10.5 10.5 24.634 10.5 42C10.5 47.127 11.6895 52.0242 14.0437 56.5359C14.7164 57.8238 14.216 59.407 12.9281 60.0797C12.5426 60.2766 12.1324 60.375 11.7223 60.375ZM57.7664 74.9027C56.823 74.9027 55.9043 74.3859 55.4367 73.4918C54.7641 72.2039 55.2645 70.6207 56.5523 69.9481C67.0031 64.493 73.5 53.7879 73.5 42C73.5 40.3758 73.377 38.7434 73.1309 37.152C72.9094 35.7164 73.8938 34.3793 75.3211 34.1578C76.7566 33.9363 78.0938 34.9207 78.3152 36.348C78.6023 38.202 78.75 40.1051 78.75 42C78.75 48.8496 76.8551 55.527 73.2703 61.3184C69.784 66.9457 64.8457 71.5395 58.9805 74.5992C58.5867 74.8043 58.1684 74.9027 57.7664 74.9027Z"
      className="other"
    />
    <path
      d="M21.3203 43.5176C21.3203 46.7048 22.3332 49.7616 24.1362 52.0153C25.9392 54.269 28.3846 55.5352 30.9344 55.5352C33.4842 55.5352 35.9296 54.269 37.7325 52.0153C39.5355 49.7616 40.5484 46.7048 40.5484 43.5176C40.5484 40.3303 39.5355 37.2736 37.7325 35.0199C35.9296 32.7661 33.4842 31.5 30.9344 31.5C28.3846 31.5 25.9392 32.7661 24.1362 35.0199C22.3332 37.2736 21.3203 40.3303 21.3203 43.5176Z"
      fill="currentColor"
    />
    <path
      d="M41.5162 78.75C53.058 78.75 54.715 73.4098 45.101 67.0195C41.0404 64.3207 36.1678 62.7539 30.9342 62.7539C25.7006 62.7539 20.8197 64.3289 16.7674 67.0195C7.15332 73.4098 8.81035 78.75 20.3521 78.75H41.5162Z"
      fill="currentColor"
    />
    <path
      d="M60.1455 15.8402C60.1455 17.2566 60.5958 18.6149 61.3973 19.6164C62.1988 20.6178 63.2858 21.1805 64.4193 21.1805C65.5528 21.1805 66.6399 20.6178 67.4414 19.6164C68.2429 18.6149 68.6932 17.2566 68.6932 15.8402C68.6932 14.4239 68.2429 13.0656 67.4414 12.0641C66.6399 11.0626 65.5528 10.5 64.4193 10.5C63.2858 10.5 62.1988 11.0626 61.3973 12.0641C60.5958 13.0656 60.1455 14.4239 60.1455 15.8402Z"
      fill="currentColor"
    />
    <path
      d="M69.1281 31.5C74.2551 31.5 74.9933 29.1293 70.7195 26.291C68.9148 25.0934 66.7492 24.3961 64.4195 24.3961C62.0898 24.3961 59.9242 25.0934 58.1195 26.291C53.8457 29.1293 54.584 31.5 59.7109 31.5H69.1281Z"
      fill="currentColor"
    />
  </svg>
);

const WorkIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.5 37.5C71.1172 37.5 70 38.6172 70 40C70 56.5391 56.5391 70 40 70C39.375 70 38.75 69.9766 38.1328 69.9453C35.6172 71.4766 32.8828 72.6953 30 73.5547C33.2344 74.5156 36.5938 75.0078 40 75.0078C44.7266 75.0078 49.3047 74.0859 53.625 72.2578C57.7969 70.4922 61.5391 67.9688 64.75 64.7578C67.9609 61.5469 70.4844 57.8047 72.25 53.6328C74.0781 49.3047 75 44.7266 75 40C75 38.6172 73.8828 37.5 72.5 37.5Z"
      fill="currentColor"
    />
    <path
      d="M39.9996 10C41.3825 10 42.4996 8.88281 42.4996 7.5C42.4996 6.11719 41.3825 5 39.9996 5C36.5778 5 33.2262 5.48438 29.9996 6.44531C32.8825 7.30469 35.6168 8.52344 38.1325 10.0547C38.7496 10.0234 39.3746 10 39.9996 10ZM48.2653 19.3594L53.07 14.5547L46.8903 8.35938L42.2653 12.9844C44.5153 14.8516 46.5387 16.9922 48.2653 19.3594ZM54.1012 47.9062L56.1559 48.5938L71.6403 33.1094L65.4528 26.9219L56.1793 36.1953L54.7418 35.7109C54.9137 37.1172 54.9996 38.5469 54.9996 39.9922C54.9996 42.7188 54.6871 45.3672 54.1012 47.9062ZM30.7418 68.5469C29.4293 68.125 28.0153 68.8438 27.5934 70.1562C27.1715 71.4688 27.8903 72.8828 29.2028 73.3047C29.4684 73.3906 29.734 73.4688 29.9996 73.5547C32.8825 72.6953 35.609 71.4766 38.1325 69.9453C35.609 69.7891 33.1325 69.3203 30.7418 68.5469Z"
      className="other"
    />
    <path
      d="M15.25 15.25C12.0391 18.4609 9.51562 22.2031 7.75 26.375C5.92187 30.6953 5 35.2734 5 40C5 43.6875 5.57031 47.3281 6.70313 50.8047C7.04688 51.8594 8.02344 52.5312 9.07812 52.5312C9.33594 52.5312 9.59375 52.4922 9.85156 52.4062C11.1641 51.9766 11.8828 50.5703 11.4609 49.2578C10.4922 46.2812 10.0078 43.1641 10.0078 40C10.0078 24.0859 22.4688 11.0234 38.1406 10.0547C35.5949 8.50921 32.8618 7.29621 30.0078 6.44531C28.7812 6.8125 27.5703 7.24219 26.3828 7.75C22.2109 9.51562 18.4609 12.0391 15.25 15.25ZM34.5 33.1094L15.9219 51.7031C12.5078 55.1172 12.5078 60.6641 15.9219 64.0781C19.3359 67.4922 24.8828 67.4922 28.2969 64.0781L46.8828 45.4922L54.1016 47.9062C54.6875 45.3672 55 42.7188 55 40C55 38.5469 54.9141 37.1172 54.7422 35.7188L46.9062 33.0938L43.8047 23.8203L48.2656 19.3594C46.5391 16.9922 44.5156 14.8516 42.2578 12.9922L31.4062 23.8438L34.5 33.1094ZM22.1094 54.7969C22.9609 53.9453 24.3516 53.9453 25.2031 54.7969C26.0547 55.6484 26.0547 57.0391 25.2031 57.8906L22.1094 60.9844C21.2578 61.8359 19.8672 61.8359 19.0156 60.9844C18.1641 60.1328 18.1641 58.7422 19.0156 57.8906"
      fill="currentColor"
    />
  </svg>
);
