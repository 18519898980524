import { styled, Theme, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";

const ClickableText: any = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "clickable",
})(({ theme, clickable }: any & { theme: Theme; clickable: boolean }) =>
  clickable
    ? {
        cursor: "pointer",
        "&:hover": { color: theme.palette.grey[900] },

        overflow: "hidden",
        textOverflow: "ellipsis",
      }
    : { overflow: "hidden", textOverflow: "ellipsis" }
);

const LongText: React.FC<{
  text: string;
  maxChar: number;
  variant?: string;
  color?: string;
  fontWeight?: string;
  lineHeight?: any;
  onMouseDown?: any;
  onClick?: any;
  clickable?: boolean;
}> = ({
  text,
  maxChar,
  variant,
  color,
  fontWeight,
  lineHeight,
  onMouseDown,
  onClick,
  clickable,
}: any) => {
  const [innerText, setInnerText] = useState("");

  const [overflown, setOverflown] = useState(false);

  const textRef = useRef<any>(null);

  useEffect(() => {
    if (text?.length > maxChar) {
      const splitted = text.slice(0, maxChar) + "...";
      setInnerText(splitted);
    } else {
      setInnerText(text);
    }
  }, [text, maxChar]);

  const onMouseEnter = () => {
    if (textRef.current) {
      const { clientWidth, scrollWidth } = textRef.current;
      setOverflown(clientWidth < scrollWidth);
    }
  };

  return (
    <Tooltip
      title={
        text?.length > maxChar || overflown ? (
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        ) : (
          ""
        )
      }
    >
      <ClickableText
        ref={textRef}
        clickable={clickable}
        onMouseDown={onMouseDown}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        variant={variant}
        color={color}
        fontWeight={fontWeight}
        sx={lineHeight ? { lineHeight: lineHeight } : {}}
        dangerouslySetInnerHTML={{ __html: innerText }}
      />
    </Tooltip>
  );
};
export default LongText;
