import {
  Box,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
  Zoom,
} from "@mui/material";
import React from "react";
import TitleWithShowAllSkeleton from "../TitleWithShowAllSkeleton";

const ProfileProgressionSkeleton: React.FC<{ withZoom?: boolean }> = ({
  withZoom = false,
}) => {
  return (
    <Zoom in={true} appear={withZoom}>
      <Box>
        <Box sx={{ mb: 1 }}>
          <TitleWithShowAllSkeleton />
        </Box>
        <Card>
          <CardContent>
            <Skeleton
              variant="circular"
              height={175}
              width={175}
              sx={{
                margin: "auto",
                marginTop: "15px",
                marginBottom: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  width: "155px",
                  height: "155px",
                  borderRadius: "50%",
                  visibility: "visible",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Skeleton
                  variant="circular"
                  height={145}
                  width={145}
                  sx={{
                    margin: "auto",
                    marginTop: "5px",
                    marginBottom: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "white",
                      width: "125px",
                      height: "125px",
                      borderRadius: "50%",
                      visibility: "visible",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: 0.75,
                      pt: 1,
                    }}
                  >
                    <Typography variant="h3">
                      <Skeleton width={60} />
                    </Typography>
                    <Typography variant="body2">
                      <Skeleton width={70} />
                    </Typography>
                  </Box>
                </Skeleton>
              </Box>
            </Skeleton>
            <Divider sx={{ mt: 4 }} />
            <Box
              display="flex"
              justifyContent="space-around"
              textAlign="center"
              marginTop={1.75}
              flexWrap="wrap"
            >
              <Typography variant="subtitle1" sx={{ width: "20%" }}>
                <Skeleton width="100%" sx={{ margin: "auto" }} />
              </Typography>
              <Typography variant="subtitle1" sx={{ width: "20%" }}>
                <Skeleton width="100%" sx={{ margin: "auto" }} />
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Zoom>
  );
};

export default ProfileProgressionSkeleton;
