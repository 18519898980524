import { Box, Checkbox, styled, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import successionService from "../../services/succession.service";

const Label = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const SuccessionFilters: React.FC<any> = ({ matchers, filters }) => {
  const noMargin = false;
  const theme: any = useTheme();
  const { t } = useTranslation("common");

  const checkTest = (test: string, value: boolean) => {
    let arr: any = [...filters];
    if (value) {
      arr.push(test);
    } else {
      arr.splice(
        arr.findIndex((e: string) => e === test),
        1
      );
    }
    successionService.setFilters(arr);
  };

  return (
    <div>
      <Box display="flex" flexWrap="wrap" flexGrow={1}>
        {matchers?.map(
          (test: any, index: number) =>
            test.code !== "cf" && (
              <Box
                key={test}
                display="flex"
                alignItems="center"
                marginLeft={noMargin ? 0 : "38px"}
              >
                <Checkbox
                  icon={
                    <CheckBoxOutlineBlankIcon
                      sx={{ color: theme.palette.secondary.light }}
                    />
                  }
                  disabled={filters.includes(test) && filters.length === 1}
                  checked={filters.includes(test)}
                  onChange={(e) => checkTest(test, e.target.checked)}
                  inputProps={{ "aria-label": "decorative checkbox" }}
                />
                <Label variant="body1">{t(`tests.${test}`)}</Label>
              </Box>
            )
        )}
      </Box>
    </div>
  );
};

export default SuccessionFilters;
