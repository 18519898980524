import React, { useState } from "react";
import { HighlightOff, People } from "@mui/icons-material";
import {
  Modal,
  Card,
  Box,
  IconButton,
  Typography,
  Paper,
  styled,
} from "@mui/material";
import { modalStyle } from "../../_utils/modalStyle";
import CampaignAutocomplete from "../common/autocomplete/CampaignAutocomplete";
import { getNumericDate } from "../../_utils/getNumericDate";
import TestBadge from "../profile/badges/TestBadge";
import WorkIcon from "../../icons/menu/work";
import { useTranslation } from "react-i18next";
import invitationsAPI from "../../_api/_invitationsAPI";
import axios from "axios";
import CampaignCandidatesAPI from "../../_api/_campaignCandidatesAPI";
import CustomButtonModal from "../common/CustomBtnModal";
import { toast } from "react-toastify";
import StringAvatar from "../common/StringAvatar";

const IconBox = styled(Box)(({ theme }: any) => ({
  padding: "10px",
  width: "40px",
  height: "40px",
  borderRadius: theme.shape.borderRadius8,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const BenefProfileInviteModal: React.FC<{
  open: boolean;
  context: string;
  persons: any[];
  close: () => void;
}> = ({ open, context, persons, close }) => {
  const { t } = useTranslation("common");
  const [selectedCampaign, setSelectedCampaign] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [alreadyFollowed, setAlreadyFollowed] = useState<any[]>([]);

  const handleCampaign = async (e: any) => {
    const alreadyPersons: any[] = [];
    setSelectedCampaign(e);
    const res = await axios.get(
      CampaignCandidatesAPI.get({
        "campaign.id": e.id,
      })
    );
    persons?.map((person: any) =>
      res?.data?.["hydra:member"]?.map(
        (item: any) =>
          item?.person?.id === person.id && alreadyPersons?.push(person)
      )
    );
    setAlreadyFollowed(alreadyPersons);
  };

  const handleInvite = () => {
    const calls: any = [];
    setLoading(true);
    try {
      // eslint-disable-next-line array-callback-return
      persons.map((person: any) => {
        const benef = {
          gender: person.gender,
          firstName: person.firstName,
          lastName: person.lastName,
          email: person.email,
          phone: person.phone,
          roles: [],
          parameters: {
            expectedData: selectedCampaign.expectedData,
            context: context,
            campaign: selectedCampaign["@id"],
          },
        };

        calls?.push(invitationsAPI.post(benef));
      });
      Promise.all(calls)
        ?.then((res: any) => {
          toast.success(
            t(
              `advisor.folder.added_${context === "advisor" ? "benef" : "cand"}`
            )
          );
          setLoading(false);
          setSelectedCampaign(null);
          setAlreadyFollowed([]);
          close();
        })
        .catch((err: any) => {
          toast.error(t("errors.error_append"));
          setLoading(false);
          setSelectedCampaign(null);
          close();
        });
    } catch (err) {
      setLoading(false);
      toast.error(t("errors.error_append"));
    }
  };

  const closeModal = () => {
    setSelectedCampaign(null);
    close();
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
        }}
      >
        <Box
          width="100%"
          sx={{ display: "flex", width: "100%", mb: "15px", mt: 1, p: 1 }}
        >
          <Box
            width="100%"
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <IconBox>
              <People sx={{ width: "20px", height: "20px" }} />
            </IconBox>
          </Box>

          <IconButton
            aria-label="close modal"
            sx={{ ml: "auto", mb: "auto", padding: "0" }}
            onClick={closeModal}
          >
            <HighlightOff />
          </IconButton>
        </Box>
        {persons.length === 1 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h4">
              {t(
                `${
                  context === "recruiter"
                    ? "recruiter.candidate"
                    : "advisor.benef"
                }.invite.title_1`
              )}
              &nbsp;
            </Typography>
            <StringAvatar
              size={30}
              name={persons[0]?.firstName + " " + persons[0]?.lastName}
              url={persons[0]?.image?.contentUrl}
            />
            <Typography variant="h4" color="primary">
              &nbsp;{persons[0]?.firstName}
              &nbsp;{persons[0]?.lastName}
            </Typography>
            <Typography variant="h4">
              &nbsp;
              {t(
                `${
                  context === "recruiter"
                    ? "recruiter.candidate"
                    : "advisor.benef"
                }.invite.title_2`
              )}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h4">
              {t(
                `${
                  context === "recruiter"
                    ? "recruiter.candidate"
                    : "advisor.benef"
                }.invite.title_multiple`,
                {
                  count: persons.length,
                }
              )}
              {/* Ajoutez {persons.length}{" "}
              {context === "recruiter" ? "candidats" : "bénéficiaires"} à{" "}
              {context === "recruiter" ? "une campagne" : "un dossier"} */}
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <Typography
            textAlign="center"
            variant="body2"
            sx={{ mt: 1, maxWidth: "75ch" }}
          >
            {t(
              context === "advisor"
                ? "advisor.benef.invitation.text_invite_modal_folder"
                : "recruiter.candidate.invitation.text_invite_modal_campaign"
            )}
          </Typography>
        </Box>
        <Box sx={{ mt: 1 }}>
          {alreadyFollowed?.map((person: any) => (
            <Typography
              key={person?.id}
              variant="body2"
              color="error"
              textAlign="center"
              sx={{ mt: 0.5 }}
            >
              {person?.firstName + " " + person?.lastName} est déjà présent(e)
              sur {context === "advisor" ? " ce dossier" : " cette campagne"}.
            </Typography>
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          sx={{ mb: 4, mt: 3 }}
        >
          <Box sx={{ width: { xs: "95%", sm: "80%" } }}>
            <CampaignAutocomplete
              handleCampaign={handleCampaign}
              matching
              preLoad
              onlyActive
            />
            {selectedCampaign && (
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Box display="flex" alignItems="center">
                    {context === "recruiter" && (
                      <Typography variant="body2" fontWeight="bold">
                        [{selectedCampaign.reference}]
                      </Typography>
                    )}
                    <Typography variant="body2" fontWeight="bold">
                      {selectedCampaign.name}
                    </Typography>
                  </Box>
                  {selectedCampaign?.work && (
                    <Box display="flex" alignItems="center" sx={{ mt: 0.75 }}>
                      <WorkIcon context="recruiter" size={18} active />
                      <Typography sx={{ ml: 1 }} variant="body2">
                        {selectedCampaign?.work?.label}
                      </Typography>
                    </Box>
                  )}
                </Box>
                {selectedCampaign?.startDate && (
                  <Typography
                    variant="body2"
                    textAlign="center"
                    sx={{ ml: "auto" }}
                  >
                    {getNumericDate(new Date(selectedCampaign?.startDate))}
                  </Typography>
                )}
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ mt: { xs: 2, md: 0 }, ml: "auto" }}
                >
                  {selectedCampaign?.expectedData?.gamified ? (
                    <TestBadge type="gamified" size={35} padding={0} />
                  ) : (
                    <>
                      {selectedCampaign?.expectedData?.skills && (
                        <TestBadge type="skills" size={35} padding={9} />
                      )}
                      {selectedCampaign?.expectedData?.personality && (
                        <TestBadge type="Big5" size={35} padding={9} />
                      )}
                      {selectedCampaign?.expectedData?.valors && (
                        <TestBadge type="CultureFit" size={35} padding={9} />
                      )}
                      {selectedCampaign?.expectedData?.inc && (
                        <TestBadge type="Inc" size={35} padding={9} />
                      )}
                    </>
                  )}
                </Box>
                <Box sx={{ ml: "auto" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setAlreadyFollowed([]);
                      setSelectedCampaign(null);
                    }}
                  >
                    <HighlightOff color="error" />
                  </IconButton>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
        <Box
          sx={{ mt: 4, mb: 1 }}
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="center"
        >
          <CustomButtonModal
            secondary
            onClick={closeModal}
            variant="outlined"
            color="secondary"
            text={t(`btn.cancel`)}
          />
          <CustomButtonModal
            variant="contained"
            onClick={handleInvite}
            disabled={loading || !selectedCampaign}
            text={t(`btn.valid`)}
          />
        </Box>
      </Card>
    </Modal>
  );
};

export default BenefProfileInviteModal;
