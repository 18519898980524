import { Box, ButtonBase, styled, Typography } from "@mui/material";
import React, { createRef, useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useSelector } from "react-redux";

const Root = styled(Box)(({ theme }: any) => ({
  position: "relative",
  background: "#5058870d",
  display: "flex",
  alignItems: "center",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    visibility: "hidden",
    background: theme.palette.grey[400],
    width: 1,
    height: 0,
  },
  "&::-webkit-scrollbar-thumb": {
    background: "grey",
    visibility: "hidden",
    borderRadius: "10px",
    "&:hover": {
      background: theme.palette.primary.light,
    },
  },
  padding: "7px",
  borderRadius: theme.shape.borderRadius10,
  [theme.breakpoints.down("lg")]: {
    // maxWidth: "664px",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

const Outline = styled(Box)(({ theme }: any) => ({
  position: "absolute",
  background: theme.palette.common.white,
  transition: "all 0.2s linear",
  borderRadius: theme.shape.borderRadius10,
  zIndex: 1,
}));

const Tab: any = styled(Box, {
  shouldForwardProp: (props) => props !== "active",
})(({ theme, active }: any) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  marginRight: theme.spacing(4),
  cursor: "pointer",
  flexShrink: 0,
  opacity: active ? 1 : 0.56,
  borderRadius: theme.shape.borderRadius10,
  transition: "all 0.2s linear",
  zIndex: 2,
  userSelect: "none",
}));

const TabText: any = styled(Typography, {
  shouldForwardProp: (props) => props !== "active",
})(({ theme, active }: any) => ({
  fontWeight: active ? 700 : 400,
  color: theme.palette.secondary.light,
}));

const ArrowButton = styled(ButtonBase)(({ theme }: any) => ({
  display: "inherit",
  color: theme.palette.secondary[500_80],
  border: `1px solid ${theme.palette.secondary[500_80]}`,
  background: "white",
  position: "absolute",
  zIndex: 3,
  borderRadius: "16px",
  "&:hover": {
    background: theme.palette.grey[200],
  },
}));

const CustomTabs: React.FC<any> = ({ value, onChange, tabs, tabChange }) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const rootRef = useRef<any>(null);
  const { hash } = window.location;

  const refs = useRef<any>(tabs.map((x: any) => createRef()));
  const outlineRef = useRef();
  const [currentTabIndex, setCurrentTabIndex] = useState(value);
  const [hasScroll, setHasScroll] = useState(false);
  const [rightArrowOptions, setRightArrowOptions] = useState<any>({});
  const [leftArrowOptions, setLeftArrowOptions] = useState<any>({});

  const changeTab = (i: number) => {
    setCurrentTabIndex(i);
    setStyleCurrentTab(i);
  };

  const handleScroll = (event: any) => {
    setHasScroll(true);
    setRightArrowOptions({
      left: `${event.target.clientWidth - 40 + event.target.scrollLeft}px`,
    });
    setLeftArrowOptions({
      left: `${event.target.scrollLeft + 10}px`,
    });
  };

  const handleEffect = () => {
    const current = rootRef.current;
    if (current) {
      if (current.clientWidth < current.scrollWidth) {
        setHasScroll(true);
        setRightArrowOptions({
          left: `${current.clientWidth - 40 + current.scrollLeft}px`,
        });
        setLeftArrowOptions({
          left: `${current.scrollLeft + 10}px`,
        });
        current.addEventListener("scroll", handleScroll);
      } else {
        setHasScroll(false);
      }
    }
  };

  useLayoutEffect(() => {
    const current = rootRef.current;
    handleEffect();
    window.addEventListener("resize", handleEffect);

    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", handleEffect);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootRef]);

  const scrollRight = () => {
    const current = rootRef.current;
    if (!current) return;
    current.scroll({
      top: 0,
      left: current.scrollLeft + current.clientWidth,
      behavior: "smooth",
    });
  };

  const scrollLeft = () => {
    const current = rootRef.current;
    if (!current) return;
    current.scroll({
      top: 0,
      left: current.scrollLeft - current.clientWidth,
      behavior: "smooth",
    });
  };

  const setStyleCurrentTab = (i: number) => {
    if (outlineRef?.current && refs?.current[i]?.current) {
      const outline: any = outlineRef?.current;
      const tabElement = refs?.current[i]?.current;
      const rect = tabElement?.getBoundingClientRect();
      const offsetLeft = tabElement?.offsetLeft;
      outline.style.left = offsetLeft + "px";
      outline.style.height = rect.height + "px";
      outline.style.width = rect.width + "px";
    }
  };

  useEffect(() => {
    changeTab(currentTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlineRef, refs]);

  useEffect(() => {
    value && setCurrentTabIndex(value);
    value && setStyleCurrentTab(value);
  }, [value]);

  useEffect(() => {
    if (tabChange || hash) {
      tabChange &&
        setCurrentTabIndex(
          tabChange === "skills"
            ? context === "advisor" || context === "recruiter"
              ? 4
              : 3
            : 1
        );
      setTimeout(() => {
        const scrollDiv: any = document.getElementById(
          `${tabChange || hash?.substring(1)}-ctn`
        )?.offsetTop;
        scrollDiv &&
          window.scrollTo({
            top: scrollDiv - 70,
            behavior: "smooth",
          });
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabChange, hash]);

  useEffect(() => {
    onChange && onChange(currentTabIndex, true);
  }, [currentTabIndex, onChange]);

  return (
    <Root ref={rootRef} sx={{ mt: 3, mb: 3 }}>
      <Outline ref={outlineRef} />
      {tabs.map((el: string, i: number) => (
        <Tab
          onClick={() => changeTab(i)}
          active={currentTabIndex === i}
          key={i}
          ref={refs.current[i]}
        >
          <TabText active={currentTabIndex === i}>{t(el)}</TabText>
        </Tab>
      ))}
      {hasScroll && (
        <>
          {rootRef?.current?.scrollLeft + rootRef?.current?.clientWidth <
            rootRef?.current?.scrollWidth && (
            <ArrowButton onClick={scrollRight} sx={rightArrowOptions}>
              <ChevronRightIcon />
            </ArrowButton>
          )}
          {rootRef?.current?.scrollLeft > 0 && (
            <ArrowButton onClick={scrollLeft} sx={leftArrowOptions}>
              <ChevronLeftIcon />
            </ArrowButton>
          )}
        </>
      )}
    </Root>
  );
};

export default CustomTabs;
