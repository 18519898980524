// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setLoaderOpen,
  openLoaderWithType,
} from "../../redux/actions/globalLoaderActions";

const useGlobalSplashscreen = (open: boolean, type: string = "default") => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    return () => {
      dispatch(setLoaderOpen(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type && open) {
      dispatch(openLoaderWithType(type));
    } else {
      dispatch(setLoaderOpen(false));
    }
  }, [dispatch, open, type]);
};

export default useGlobalSplashscreen;
