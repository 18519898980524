import React from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import ArchetypeAPI from "../../_api/_archetypeAPI";
import { useSelector } from "react-redux";
import StringAvatar from "../common/StringAvatar";

const Big5Archetypes = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();

  const context = useSelector((state: any) => state.auth.context);
  const tokenData = useSelector((state: any) => state.user);

  const { data } = useFetch(
    ArchetypeAPI.get({ personId: id || tokenData.person_id })
  );

  return data ? (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{ mt: 4 }}
    >
      <Chip
        sx={{
          background:
            "linear-gradient(89deg, #33C8FF 0%, #4E60FF 100%) 0% 0% no-repeat padding-box",
          pl: 2,
          pr: 2,
          mb: 2,
          width: "170px",
          height: "40px",
          borderRadius: "20px",
        }}
        label={
          <Box display="flex" alignItems="center">
            <AutoAwesomeIcon sx={{ fontSize: "18px", mr: 1 }} />
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t(
                  `big5.result.avatar_title${
                    context === "collab" || context === "benef" ? "_collab" : ""
                  }`
                ),
              }}
            ></Typography>
          </Box>
        }
        color="primary"
      />
      <Typography
        variant="subtitle1"
        fontWeight="normal"
        textAlign="center"
        sx={{ maxWidth: "60ch", mt: 2 }}
      >
        {t("big5.result.archetype_text")}
      </Typography>
      <Box display="flex" flexWrap="wrap" alignItems="center" sx={{ mt: 3 }}>
        {data?.archetype?.map((item: any) => (
          <Box
            display="flex"
            alignItems="center"
            sx={{ m: 1 }}
            key={item.avatar}
          >
            <StringAvatar
              size={55}
              name={item?.name}
              url={item?.avatar}
              tooltip={item?.desc}
            />
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ ml: 1, maxWidth: "7ch" }}
            >
              {item?.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default Big5Archetypes;
