import {
  InputAdornment,
  Card,
  Box,
  Typography,
  OutlinedInput,
  Divider,
  Button,
  Grid,
  CircularProgress,
  styled,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { AddCircle, CheckCircle } from "@mui/icons-material";
import recruiterCampaignsAPI from "../../../_api/_recruiterCampaignAPI";
import { useSelector } from "react-redux";
import TestBadge from "../../profile/badges/TestBadge";
import { getNumericDate } from "../../../_utils/getNumericDate";

const PopperCard = styled(Card)(({ theme }: any) => ({
  zIndex: 10,
  borderBottomLeftRadius: theme.shape.borderRadius8,
  borderBottomRightRadius: theme.shape.borderRadius8,
  width: "100%",
  borderTopLeftRadius: "0px",
  borderTopRightRadius: "0px",
  maxHeight: "300px",
  marginBottom: 16,
}));

const MatchingLink = styled(Box)(({ theme }: any) => ({
  cursor: "pointer",
  "&:hover": { backgroundColor: theme.palette.background.neutral },
}));

const GreenLabel = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.green[500],
}));

const InputNoBorder: any = styled(OutlinedInput, {
  shouldForwardProp: (props) => props !== "noBorder",
})(({ noBorder }: any) => ({
  borderBottomLeftRadius: noBorder ? "0px" : "auto",
  borderBottomRightRadius: noBorder ? "0px" : "auto",
}));

const CampaignAutocomplete = ({
  handleCampaign,
  alreadySelected,
  campaignToAdd,
  matching,
  preLoad,
  onlyActive,
}: any) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const [term, setTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [inputValue, setInputValue] = useState<any>(undefined);
  const [options, setOptions] = useState<any>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState<any>(
    alreadySelected ? alreadySelected : []
  );
  const [selectedCampaign, setSelectedCampaign] = useState<any>(
    campaignToAdd ? campaignToAdd : []
  );
  const { data, loading } = useFetch(
    recruiterCampaignsAPI.get({
      term,
      page: 1,
      itemsPerPage: preLoad && !term ? 5 : 50,
      isArchived: 0,
      ...(preLoad && !term && { "order[createdAt]": "desc" }),
      ...(onlyActive && { status: 20 }),
    }),
    !preLoad
  );

  const addFolder = (item: any) => {
    // for many campaigns

    // const newArr = [...selectedCampaign];
    // newArr.push(item);
    // setSelectedCampaign(newArr);
    // handleCampaign(newArr);
    // const newArrId = [...selectedCampaignId];
    // setSelectedCampaignId(newArrId);
    // newArrId.push(item.id);

    // for 1 campaign
    handleCampaign([item]);
    setSelectedCampaignId([item.id]);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputValue?.length >= 3) {
        setTerm(inputValue);
      } else {
        if (inputValue?.length === 0) {
          setOptions([]);
          setOpen(false);
          setHelperText("");
          setTerm("");
        }
        if (inputValue?.length < 3 && inputValue.length > 1) {
          setHelperText(t("autocomplete.min_charac_3"));
        }
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    if (data && data?.["hydra:member"]?.length > 0) {
      setOptions(data?.["hydra:member"]);
      setHelperText("");
      setOpen(true);
    } else if (
      data?.["hydra:member"]?.length === 0 &&
      inputValue?.length >= 3
    ) {
      setHelperText(t("autocomplete.no_result"));
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    alreadySelected !== selectedCampaignId &&
      setSelectedCampaignId(alreadySelected);
    campaignToAdd !== selectedCampaign && setSelectedCampaign(campaignToAdd);
  }, [alreadySelected, campaignToAdd, selectedCampaign, selectedCampaignId]);

  const PopperMy = () => {
    return options.length > 0 && open ? (
      <PopperCard>
        <Box sx={{ overflow: "auto", maxHeight: "250px" }}>
          {options.map((item: any) => (
            <MatchingLink
              key={item.id}
              onClick={() => matching && (handleCampaign(item), setOpen(false))}
            >
              <Grid
                container
                sx={{ display: "flex", alignItems: "center", padding: "15px" }}
              >
                <Grid item xs={12} sm={5} md={matching ? 8 : 4}>
                  <Box display="flex" alignItems="center">
                    {context === "recruiter" && (
                      <Typography variant="body2" fontWeight="bold">
                        [{item.reference}]
                      </Typography>
                    )}
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: context === "recruiter" ? 0.5 : 0 }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  {item?.startDate && (
                    <Typography variant="body2">
                      {getNumericDate(new Date(item?.startDate))}
                    </Typography>
                  )}
                  {item?.work && (
                    <Box display="flex" alignItems="center" sx={{ mt: 0.75 }}>
                      <Typography variant="body2">
                        {item?.work?.label}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={10} sm={6} md={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ mt: { xs: 2, md: 0 }, ml: 1 }}
                  >
                    {item?.expectedData?.gamified ? (
                      <TestBadge type="gamified" size={35} padding={0} />
                    ) : (
                      <>
                        {item?.expectedData?.skills && (
                          <TestBadge type="skills" size={35} padding={9} />
                        )}
                        {item?.expectedData?.personality && (
                          <TestBadge type="Big5" size={35} padding={9} />
                        )}
                        {item?.expectedData?.valors && (
                          <TestBadge type="CultureFit" size={35} padding={9} />
                        )}
                        {item?.expectedData?.inc && (
                          <TestBadge type="Inc" size={35} padding={9} />
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
                {!matching && (
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    md={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {selectedCampaignId?.includes(item?.id) ? (
                      <GreenLabel
                        variant="caption"
                        textAlign="center"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        <CheckCircle sx={{ mr: 1 }} color="inherit" />
                        {t(
                          `${context}.${
                            context === "advisor" ? "benef" : "candidate"
                          }.invitation.added`
                        )}
                      </GreenLabel>
                    ) : (
                      <Button
                        onClick={() => addFolder(item)}
                        variant="text"
                        sx={{
                          fontWeight: "300",
                          fontSize: "12px",
                        }}
                      >
                        <AddCircle color="primary" sx={{ mr: 1 }} />
                        <Typography
                          variant="body1"
                          display={{
                            xs: "none",
                            md: "block",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t(
                            `${context}.${
                              context === "advisor" ? "benef" : "candidate"
                            }.invitation.add`
                          )}
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
              <Divider />
            </MatchingLink>
          ))}
        </Box>
      </PopperCard>
    ) : (
      <></>
    );
  };

  document.addEventListener("click", (event: any) => {
    setOpen(
      document?.getElementById("outlined-campaign-search")?.className ===
        event?.srcElement?.className
    );
  });

  return (
    <>
      <InputNoBorder
        sx={{ width: "100%", mt: 2 }}
        noBorder={options.length > 0 && open}
        id="outlined-campaign-search"
        value={inputValue}
        placeholder={t("autocomplete.placeholder.folder_example")}
        onClick={() => options.length > 0 && setOpen(true)}
        onChange={(text: any) => {
          setInputValue(text.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            {loading ? (
              <CircularProgress color="primary" size={20} />
            ) : (
              <SearchIcon />
            )}
          </InputAdornment>
        }
      />
      <Typography variant="caption" sx={{ ml: 1, opacity: ".7", mt: 0.5 }}>
        {helperText}
      </Typography>
      <PopperMy />
    </>
  );
};

export default CampaignAutocomplete;
