import { SET_PROFILE_PICTURE, SET_USER } from "../actions/userActions";
import initialState from "../initialState";

const userReducer = (state = { ...initialState.user }, action: any) => {
  switch (action.type) {
    case SET_USER:
      // if (isMobile && action.payload?.webapp && action.payload?.webappUrl) {
      //   const origin = window.location.origin;
      //   if (!origin.includes("localhost")) {
      //     window.location.href = action.payload?.webappUrl;
      //   }
      // }
      return { ...action.payload };
    case SET_PROFILE_PICTURE:
      return { ...state, profilePicture: action.payload };
    default:
      return { ...state };
  }
};

export default userReducer;
