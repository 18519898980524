import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Box,
  Typography,
  Button,
  Tooltip,
  IconButton,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import CampaignCandidatesAPI from "../../_api/_campaignCandidatesAPI";
import { useNavigate } from "react-router";
import { HighlightOff } from "@mui/icons-material";
import BenefProfileInviteModal from "../benef/BenefProfileInviteModal";
import { AddCircle } from "@mui/icons-material";
import TestIcon from "../../icons/menu/test";
import recruitersCandidatesAPI from "../../_api/_recruitersCandidatesAPI";
import { toast } from "react-toastify";

const Root = styled(Box)({
  "&:hover $removedIcon": {
    visibility: "visible",
  },
});

const BoxIcon: any = styled(Box, {
  shouldForwardProp: (props) => props !== "green",
})(({ theme, green }: any) => ({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: green ? theme.palette.green[500_8] : "auto",
}));

const RemovedIcon = styled(IconButton)({ visibility: "hidden" });

const ProfileListCampaigns = ({
  person,
  personId,
  context,
  candidateId,
}: any) => {
  const { t }: any = useTranslation("common");
  const navigate = useNavigate();
  const [folderList, setFolderList] = useState<Object[]>([]);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [candidateInfo, setCandidateInfo] = useState<any>(false);

  const { data, loading } = useFetch(
    CampaignCandidatesAPI.get({
      "person.id": personId,
      itemsPerPage: 100,
      reload: reload,
    })
  );

  const recruiterCandidates = useFetch(
    recruitersCandidatesAPI.getById(candidateId)
  );

  useEffect(() => {
    data?.["hydra:member"] && setFolderList(data?.["hydra:member"]);
  }, [data]);

  useEffect(() => {
    recruiterCandidates?.data && setCandidateInfo(recruiterCandidates?.data);
  }, [recruiterCandidates]);

  const removeBenef = async (id: string, index: number) => {
    try {
      await CampaignCandidatesAPI.delete(id);
      const newList: Object[] = [...folderList];
      newList.splice(index, 1);
      setFolderList(newList);
      toast.success(
        t(
          `${context}.${context === "advisor" ? "benef" : "candidate"}.remove_${
            context === "advisor" ? "benef_folder" : "cand_campaign"
          }_alert`
        )
      );
    } catch (err) {
      toast.error(t("errors.error_append"));
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title={t(
          context === "advisor"
            ? "advisor.benef.profile_folder"
            : "recruiter.candidate.profile_campaign"
        )}
        titleTypographyProps={{ variant: "subtitle2" }}
      />
      <CardContent>
        <Stack spacing={1}>
          {folderList?.map((item: any, i: number) => (
            <Root key={item.id} display="flex" alignItems="center">
              <BoxIcon
                green
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    `/app/${context === "advisor" ? "folder" : "campaign"}/${
                      item.campaign["@id"].split("/")[3]
                    }`
                  )
                }
              >
                <TestIcon color="#3CB442" size={14} />
              </BoxIcon>
              <Typography
                onClick={() =>
                  navigate(
                    `/app/${context === "advisor" ? "folder" : "campaign"}/${
                      item.campaign["@id"].split("/")[3]
                    }`
                  )
                }
                variant="body2"
                sx={{ ml: 1, color: "#3CB442 !important", cursor: "pointer" }}
              >
                {item?.campaign?.name}
              </Typography>
              <Tooltip
                sx={{ ml: "auto" }}
                placement="left"
                title={t(
                  `${context}.${
                    context === "advisor" ? "benef" : "candidate"
                  }.remove_${
                    context === "advisor" ? "benef_folder" : "cand_campaign"
                  }`
                )}
              >
                <RemovedIcon
                  aria-label="remove campaign"
                  sx={{ ml: "auto" }}
                  onClick={() => removeBenef(item.id, i)}
                >
                  <HighlightOff color="error" />
                </RemovedIcon>
              </Tooltip>
            </Root>
          ))}
          {folderList?.length === 0 && !loading && (
            <Typography
              textAlign="center"
              variant="body2"
              sx={{ opacity: "0.7" }}
            >
              {t(
                `${context}.${
                  context === "advisor" ? "benef" : "candidate"
                }.no_profile_${context === "advisor" ? "folder" : "campaign"}`
              )}
            </Typography>
          )}
          {!candidateInfo.isArchived && (
            <Button onClick={() => setOpenAddModal(true)} variant="contained">
              <AddCircle sx={{ mr: 1 }} />
              <Typography variant="body2">
                {t(
                  `${context}.${
                    context === "recruiter" ? "candidate" : "benef"
                  }.add_${context === "recruiter" ? "campaign" : "folder"}`
                )}
              </Typography>
            </Button>
          )}
        </Stack>
      </CardContent>
      <BenefProfileInviteModal
        context={context}
        open={openAddModal}
        // eslint-disable-next-line no-sequences
        close={() => (setReload(!reload), setOpenAddModal(false))}
        persons={[person]}
      />
    </Card>
  );
};

export default ProfileListCampaigns;
