import { Box, Card, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CardRoot: any = styled(Card, {
  shouldForwardProp: (props) => props !== "type",
})(({ theme, type }: any) => ({
  position: "relative",
  padding: "5px",
  marginBottom: "2em",
  borderRadius: "11px !important",
  backgroundImage:
    type === "job"
      ? `url('/static/illustrations/job-suggest-card.png')`
      : type === "training"
      ? `url('/static/images/image-header-trainings.jpg')`
      : "auto",
  backgroundSize: type === "job" || type === "training" ? "cover" : "auto",
  backgroundRepeat:
    type === "job" || type === "training" ? "no-repeat" : "auto",
  backgroundPosition: type === "job" || type === "training" ? "center" : "auto",
  background:
    type === "work"
      ? "linear-gradient(145deg, rgba(0,59,116,1) 0%, rgba(149,180,213,1) 100%);"
      : type === "params" || type === "data"
      ? theme.palette.gradients.info
      : "auto",
}));

const IconContainer = styled("div")({
  width: "50px",
  height: "50px",
  borderRadius: "25px",
  backgroundColor: "rgba(255, 255, 255, 0.3);",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "1.5em",
});

const Text = styled(Typography)({ maxWidth: "78ch" });

export default function CardIllustration({ type }: string | any) {
  const { t } = useTranslation("common");
  return (
    <CardRoot type={type} sx={{ height: { xs: "250px", sm: "200px" } }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <IconContainer>
          <img
            src={`/static/icons/${
              type === "job"
                ? "customer"
                : type === "work"
                ? "suitcase"
                : type === "data"
                ? "lock"
                : type === "training"
                ? "training"
                : type === "params" && "gear"
            }.svg`}
            alt="customer icon"
          />
        </IconContainer>
        <Typography variant="h2" color="white">
          {type === "job"
            ? t("job_suggest.title")
            : type === "work"
            ? t("work_suggest.title")
            : type === "data"
            ? t("profile_menu.data")
            : type === "training"
            ? t("trainings.list")
            : type === "params" && t("profile_menu.params")}
        </Typography>
        <Text variant="body1" color="white">
          {type === "job"
            ? t("job_suggest.text")
            : type === "work"
            ? t("work_suggest.text")
            : type === "data"
            ? t("account.data.text")
            : type === "training"
            ? t("trainings.text_header")
            : type === "params" && t("account.params.text")}
        </Text>
      </Box>
    </CardRoot>
  );
}
