import { Box, Divider, Typography, TextField, Pagination } from "@mui/material";
import React, { useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch";
import Loader from "../../../../Loader";
import { sortSkills } from "../../../../Utils";
import skillsAPI from "../../../../_api/_skillsAPI";
import AdminCreateSkillModal from "./AdminCreateSkillModal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import BigButton from "../../../common/BigButton";

const AdminSkillsListParams = () => {
  const { t } = useTranslation("common");
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const theme: any = useTheme();
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [term, setTerm] = useState("");
  const [reload, setReload] = useState(0);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [openSkillModal, setOpenSkillModal] = useState(false);
  const [openSkillCatModal, setOpenSkillCatModal] = useState(false);
  const { data, loading } = useFetch(
    skillsAPI.get({
      page,
      label: search,
      itemsPerPage: 12,
      reload,
      company: `/api/companies/${tokenData.company_id}`,
    })
  );

  useEffect(() => {
    const to = setTimeout(() => {
      setSearch(term);
      setPage(1);
    }, 300);

    return () => {
      if (to) clearTimeout(to);
    };
  }, [term]);

  useEffect(() => {
    data?.["hydra:member"] && setList(sortSkills(data?.["hydra:member"]));
  }, [data]);

  return (
    <div>
      <Box>
        <Typography textAlign="center" variant="h4" marginBottom={2}>
          {t("tests.skills")}
        </Typography>
        {/* <Button
          variant="contained"
          sx={{ height: "35px", ml: "auto", mr: 1 }}
          onClick={() => setOpenSkillCatModal(true)}
        >
          <Typography variant="body2">
            {t("admin.params.skills.create_skill_cat")}
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{ height: "35px" }}
          onClick={() => setOpenSkillModal(true)}
        >
          <Typography variant="body2">
            {t("admin.params.skills.create")}
          </Typography>
        </Button> */}
      </Box>

      <Box sx={{ mb: 1 }}>
        <BigButton onClick={() => setOpenSkillModal(true)}>
          {t("admin.params.skills.create")}
        </BigButton>
      </Box>

      <BigButton onClick={() => setOpenSkillCatModal(true)}>
        {t("admin.params.skills.create_skill_cat")}
      </BigButton>

      <TextField
        sx={{
          width: { xs: "100%", sm: "90%", md: "60%", lg: "30%" },
          mt: 2,
          mb: 3,
        }}
        onChange={(e: any) => setTerm(e.target.value)}
        label={t("admin.params.skills.skill_title")}
      />
      {loading ? (
        <Loader />
      ) : list?.length === 0 ? (
        <Typography textAlign="center" variant="body1" sx={{ mt: 5, mb: 5 }}>
          {t("admin.params.skills.no_skill")}
        </Typography>
      ) : (
        list?.map((cat: any, index: number) => (
          <Box key={cat?.id}>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">
                {cat?.label}
              </Typography>
              {cat?.skillsList?.map((skill: any) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={skill?.id}
                  onClick={() => {
                    setSelectedSkill(skill);
                    setOpenSkillModal(true);
                  }}
                  sx={{
                    pt: 1.5,
                    pb: 1.5,
                    pl: 0.5,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: theme.palette.background.neutral,
                      "& .edit_btn": {
                        opacity: 1,
                      },
                    },
                  }}
                >
                  <Typography variant="body1">{skill?.label}</Typography>
                  <Typography
                    className="edit_btn"
                    sx={{
                      ml: "auto",
                      pr: 0.5,
                      opacity: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                    variant="body2"
                    fontWeight="bold"
                    color="primary"
                  >
                    {t("btn.edit")}
                    <ArrowForwardIosIcon sx={{ fontSize: "16px", ml: 1 }} />
                  </Typography>
                </Box>
              ))}
            </Box>
            {index + 1 < list.length && <Divider sx={{ mt: 2, mb: 2 }} />}
          </Box>
        ))
      )}
      {data?.["hydra:totalItems"] > 12 && (
        <Box display="center" justifyContent="center" sx={{ mt: 2 }}>
          <Pagination
            count={Math.ceil(data?.["hydra:totalItems"] / 12)}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Box>
      )}
      <AdminCreateSkillModal
        open={openSkillModal || openSkillCatModal}
        type={openSkillModal ? "skill" : openSkillCatModal ? "cat" : ""}
        close={() => {
          setSelectedSkill(null);
          setOpenSkillModal(false);
          setOpenSkillCatModal(false);
        }}
        reload={() => {
          setReload(reload + 1);
        }}
        skill={selectedSkill}
      />
    </div>
  );
};

export default AdminSkillsListParams;
