import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  IconButton,
  Modal,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import projectsService from "../../../services/projects.service";
import { modalStyle } from "../../../_utils/modalStyle";
import CustomButtonModal from "../../common/CustomBtnModal";
import ControlledQuill from "../../forms/ControlledQuill";
import ControlledYearSelector from "../../forms/ControlledYearSelector";

interface IFormInput {
  year: number | any;
  description: string;
  motivation: string;
  priority: number;
}

const ProjectTrainingModal: React.FC<any> = ({ open, close, project }) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: {
      year: project?.year ?? "",
      description: project?.description ?? "",
      motivation: project?.motivation ?? "",
      priority: project?.priority ?? 1,
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    setLoading(true);
    if (project) {
      projectsService.editTraining(project.id, data).subscribe({
        next: () => {
          close();
        },
        error: () => {
          setLoading(false);
        },
      });
    } else {
      projectsService.createTraining(data).subscribe({
        next: () => {
          close();
        },
        error: () => {
          setLoading(false);
        },
      });
    }
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "80%", md: "650px" },
        }}
      >
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h5">{t("projects.add_training")}</Typography>
            <IconButton onClick={close} sx={{ ml: "auto" }} aria-label="close">
              <HighlightOff />
            </IconButton>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div>
              <FormControl sx={{ width: "150px" }}>
                <ControlledYearSelector
                  name={"year"}
                  control={control}
                  label={t("projects.year")}
                  required
                />
                <Typography
                  sx={{
                    visibility: Boolean(errors?.["year"])
                      ? "visible"
                      : "hidden",
                  }}
                  variant="body2"
                  component="small"
                  color="error"
                >
                  {errors["year"]
                    ? t(String(errors["year"].message ?? "") ?? "")
                    : "_"}
                </Typography>
              </FormControl>
            </div>

            <FormControl fullWidth>
              <TextField
                sx={{ mr: 1 }}
                label={t("projects.desc")}
                error={Boolean(errors.description)}
                helperText={t(errors?.description?.message ?? " ")}
                {...register("description", {
                  required: { value: true, message: t("errors.required") },
                })}
              />
            </FormControl>

            <FormControl fullWidth>
              <Typography component="label">
                {t("projects.priority")} *
              </Typography>
              <Controller
                control={control}
                name="priority"
                render={({
                  field: { value, onChange },
                  formState: { errors },
                }) => (
                  <Rating
                    max={4}
                    name="simple-controlled"
                    value={value}
                    onChange={(event: any, newValue: any) => {
                      onChange(newValue);
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <ControlledQuill
                name="motivation"
                placeholder={"Motivation"}
                control={control}
              />
            </FormControl>

            <Box>
              <Typography component="small">
                {t("projects.required_fields")}
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="center"
              sx={{ mt: 1 }}
            >
              <CustomButtonModal
                disabled={loading}
                type="submit"
                variant="contained"
                text={t("btn.save")}
              />
              <CustomButtonModal
                secondary
                onClick={close}
                variant="outlined"
                color="secondary"
                text={t(`btn.cancel`)}
              />
            </Box>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ProjectTrainingModal;
