import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useFetch from "../hooks/useFetch";

const AdminParamsContext = createContext<any>({});

export { AdminParamsContext };
const AdminParamsCtx = ({ children }: any): any => {
  const { t } = useTranslation("common");
  const parametersFetch = useFetch("parameters");

  const [loading, setLoading] = useState(false);
  const [triggerCall, setTriggerCall] = useState<number>(0);
  const [params, setParams] = useState<any>(null);
  const [oldParams, setOldParams] = useState<any>(null);

  useEffect(() => {
    if (!oldParams && params) setOldParams(JSON.parse(JSON.stringify(params)));
  }, [oldParams, params]);

  const editParams = (param: any, type: string) => {
    const copyParams = [...params];
    const index = copyParams.findIndex((el: any) => el.type === type);
    copyParams[index] = param;
    setParams(copyParams);
  };

  useEffect(() => {
    if (parametersFetch?.data?.["hydra:member"]) {
      setParams(parametersFetch.data["hydra:member"]);
    }
  }, [parametersFetch?.data]);

  useEffect(() => {
    setLoading(parametersFetch?.loading ?? false);
  }, [parametersFetch?.loading]);

  const saveParams = async (types: string) => {
    setLoading(true);
    try {
      const calls = types.split(";").map((type: string) => {
        const fullParameters = params.find((el: any) => el.type === type);
        const obj = {
          parameters: fullParameters.parameters,
          type,
        };
        const url = `parameters/${fullParameters.id}`;
        return axios.put(url, obj).catch((err) => {
          toast.error("Un paramètre n'a pas pu être mis à jour.");
        });
      });

      await Promise.allSettled(calls);
      setOldParams(JSON.parse(JSON.stringify(params)));
      toast.success(t("admin.params.alert_save"));
    } catch (err: any) {
      console.error("ERROR updating parameters : ", err);
      toast.error(t("errors.error_append"));
    } finally {
      setLoading(false);
    }
  };

  const resetParams = () => {
    setParams(JSON.parse(JSON.stringify(oldParams)));
  };

  const value: any = {
    parametersFetch,
    params,
    setParams,
    editParams,
    saveParams,
    loading,
    triggerCall,
    setTriggerCall,
    oldParams,
    resetParams,
  };
  return (
    <AdminParamsContext.Provider value={value}>
      {children}
    </AdminParamsContext.Provider>
  );
};

export default AdminParamsCtx;
