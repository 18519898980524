import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Paper, styled, Typography } from "@mui/material";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import BeenhereRoundedIcon from "@mui/icons-material/BeenhereRounded";
import WorkTrainingItem from "./WorkTrainingItem";
// import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import AccessAlarmRoundedIcon from "@mui/icons-material/AccessAlarmRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import { capitalizeFirstLetter } from "../../Utils";
import { workTraining } from "../../@types/workTrainingType";

const TitleCtn = styled(Box)(({ theme }: any) => ({
  height: "44px",
  borderRadius: theme.shape.borderRadius6,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(76, 111, 255, 0.07)",
  paddingRight: "15px",
  paddingLeft: "15px",
  paddingBottom: "10px",
  paddingTop: "10px",
  width: "fit-content",
}));

const InfosItem = styled(Paper)(({ theme }: any) => ({
  width: "370px",
  padding: "25px",
  borderRadius: "9px",
  boxShadow: "0px 10px 60px #00000021 !important",
  marginTop: theme.spacing(2),
  position: "relative",
}));

const BulletCtn:any = styled(Box)(({ theme, orientation }: any) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    display: "none !important",
  },
  alignItems: "center",
  position: "absolute",
  width: "100%",
  top: "45%",
  left: orientation === "right" ? "97.5%" : "-10.5%",
}));

const Bullet = styled(Box)(() => ({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: "white",
}));

const WorkTrainingPageInfos: React.FC<{
  data?: workTraining;
}> = ({ data }) => {
  const { t } = useTranslation("common");
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ mt: 5 }}
    >
      <TitleCtn>
        <Typography variant="h5" color="primary" sx={{ fontSize: "20px" }}>
          {t("trainings.title_cat.infos")}
        </Typography>
      </TitleCtn>
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "stretch" },
        }}
      >
        <Box sx={{ mt: 4 }}>
          <InfosItem sx={{ mr: { xs: 0, md: 5 } }}>
            <BulletCtn orientation="right">
              <Bullet />
              <Divider sx={{ width: "30px" }} />
            </BulletCtn>
            <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
              <HelpRoundedIcon />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {t("trainings.title_cat.key_infos")}
              </Typography>
            </Box>
            <WorkTrainingItem training={data} trainingPage />
          </InfosItem>
          <InfosItem sx={{ mr: { xs: 0, md: 5 } }}>
            <BulletCtn orientation="right">
              <Bullet />
              <Divider sx={{ width: "30px" }} />
            </BulletCtn>
            <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
              <PriorityHighRoundedIcon />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {t("trainings.title_cat.procedures")}
              </Typography>
            </Box>
            <Typography variant="body1">
              {data?.action?.modalitesRecrutement
                ? capitalizeFirstLetter(
                    data?.action?.modalitesRecrutement?.value
                  )
                : "ND"}
            </Typography>
          </InfosItem>
          <InfosItem sx={{ mr: { xs: 0, md: 5 } }}>
            <BulletCtn orientation="right">
              <Bullet />
              <Divider sx={{ width: "30px" }} />
            </BulletCtn>
            <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
              <BeenhereRoundedIcon />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {t("trainings.title_cat.certif")}
              </Typography>
            </Box>
            {/* <Typography variant="body1">
              {data?.codeNiveauSortie?.value}
            </Typography> */}
            <Typography variant="body1">
              {data?.codeNiveauSortie
                ? capitalizeFirstLetter(data?.codeNiveauSortie?.value)
                : "ND"}
            </Typography>
          </InfosItem>
          <InfosItem sx={{ mr: { xs: 0, md: 5 } }}>
            <BulletCtn orientation="right">
              <Bullet />
              <Divider sx={{ width: "30px" }} />
            </BulletCtn>
            <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
              <AccountBalanceRoundedIcon />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {t("trainings.filters.indemnification")}
              </Typography>
            </Box>
            <Typography variant="body1">
              {data?.action?.detailConditionsPriseEnCharge?.value ?? "ND"}
            </Typography>
          </InfosItem>
        </Box>
        <Divider
          orientation="vertical"
          sx={{ height: "auto", display: { xs: "none", md: "block" } }}
        />
        <Box sx={{ mt: { xs: 0, md: 4 } }}>
          <InfosItem sx={{ ml: { xs: 0, md: 5 } }}>
            <BulletCtn orientation="left">
              <Divider sx={{ width: "30px" }} />
              <Bullet />
            </BulletCtn>
            <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
              <BusinessRoundedIcon />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {t("trainings.orga")}
              </Typography>
            </Box>
            <Typography variant="body1">
              {data?.organismeFormationResponsable?.nomOrganisme?.value}
            </Typography>
            {data?.organismeFormationResponsable?.coordonneesOrganisme?.coordonnees?.adresse?.ligne?.map(
              (item: any) => (
                <Typography key={item?.value} variant="body1">
                  {capitalizeFirstLetter(item?.value)}
                </Typography>
              )
            )}
            <Typography variant="body1">
              {
                data?.organismeFormationResponsable?.coordonneesOrganisme
                  ?.coordonnees?.adresse?.ville?.value
              }{" "}
              {
                data?.organismeFormationResponsable?.coordonneesOrganisme
                  ?.coordonnees?.adresse?.codePostal?.value
              }
            </Typography>
            <Typography variant="body1">
              <a
                href={`tel:${data?.organismeFormationResponsable?.contactOrganisme?.coordonnees?.telFixe?.numTel?.value}`}
                style={{ color: "#4c6fff", textDecoration: "none" }}
              >
                {
                  data?.organismeFormationResponsable?.contactOrganisme
                    ?.coordonnees?.telFixe?.numTel?.value
                }
              </a>
            </Typography>
            <Typography variant="body1">
              <a
                href={`mailto:${data?.organismeFormationResponsable?.contactOrganisme?.coordonnees?.courriel?.value}`}
                style={{ color: "#4c6fff", textDecoration: "none" }}
              >
                {
                  data?.organismeFormationResponsable?.contactOrganisme
                    ?.coordonnees?.courriel?.value
                }
              </a>
            </Typography>
            <Typography variant="body1">
              <a
                href={`${data?.urlFormation?.urlWeb?.[0]?.value}`}
                target="_blank"
                style={{ color: "#4c6fff", textDecoration: "none" }}
                rel="noreferrer"
              >
                {data?.urlFormation?.urlWeb &&
                data?.urlFormation?.urlWeb?.[0]?.value?.length > 45
                  ? `${data?.urlFormation?.urlWeb?.[0]?.value.slice(0, 45)}...`
                  : data?.urlFormation?.urlWeb?.[0]?.value}
              </a>
            </Typography>
          </InfosItem>
          {data?.action?.prixTotalTtc?.value ? (
            <InfosItem sx={{ ml: { xs: 0, md: 5 } }}>
              <BulletCtn orientation="left">
                <Divider sx={{ width: "30px" }} />
                <Bullet />
              </BulletCtn>
              <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
                <EuroRoundedIcon />
                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                  {t("trainings.title_cat.price")}
                </Typography>
              </Box>
              <Typography variant="body1">
                {data?.action?.prixTotalTtc?.value}€
              </Typography>
            </InfosItem>
          ) : (
            <></>
          )}
          <InfosItem sx={{ ml: { xs: 0, md: 5 } }}>
            <BulletCtn orientation="left">
              <Divider sx={{ width: "30px" }} />
              <Bullet />
            </BulletCtn>
            <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
              <NotificationsActiveRoundedIcon />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {t("trainings.title_cat.conditions")}
              </Typography>
            </Box>
            <Typography variant="body1">
              {data?.action?.conditionsSpecifiques
                ? capitalizeFirstLetter(
                    data?.action?.conditionsSpecifiques?.value
                  )
                : "ND"}
            </Typography>
          </InfosItem>
          <InfosItem sx={{ ml: { xs: 0, md: 5 } }}>
            <BulletCtn orientation="left">
              <Divider sx={{ width: "30px" }} />
              <Bullet />
            </BulletCtn>
            <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
              <AccessAlarmRoundedIcon />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {t("trainings.title_cat.duration")}
              </Typography>
            </Box>
            <Typography variant="body1">
              {data?.action?.nombreHeuresTotal
                ? `${data?.action?.nombreHeuresTotal?.value}h`
                : "ND"}
            </Typography>
            <Typography variant="body1">
              {data?.action?.rythmeFormation
                ? capitalizeFirstLetter(data?.action?.rythmeFormation?.value)
                : "ND"}
            </Typography>
          </InfosItem>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkTrainingPageInfos;
