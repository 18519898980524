import { Button, Typography, Box, styled } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import { getDuration, Utils } from "../../Utils";
import ResultsAPI from "../../_api/_resultsAPI";
import TestsAPI from "../../_api/_testsAPI";
import CircularProgressLabel from "../common/CircularProgress";
import Big5TestItem from "./Big5TestItem";
import Loader from "../../Loader";
import { useNavigate } from "react-router";
import FinishLaterTestModal from "../tests/FinishLaterTestModal";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { toast } from "react-toastify";
import { setActive } from "../../redux/actions/ptwPopupActions";
import { useDispatch, useSelector } from "react-redux";
import LinearProgressBar from "../common/LinearProgressBar";
import Big5EndQuestionnary from "./Big5EndQuestionnary";

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
});

const HeadText = styled("div")(({ theme }: any) => ({
  width: "120px",
  height: "30px",
  borderRadius: "6px",
  padding: "3px",
  backgroundColor: theme.palette.primary[100],
}));

const Carousel = styled("div")({
  position: "relative",
  overflow: "hidden",
  height: "230px",
  width: "100%",
});

const CustomButton = styled(Button)({
  width: "275px",
  height: "55px",
  fontSize: "12px",
});

const BackButton = styled(Button)`
  display: none;
  margin-top: 20px;
  font-size: 12px;
  @media screen and (max-width: 600px) {
    display: inherit;
  }
`;

const CircleProgressCtn = styled("div")`
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const ProgressBarCtn = styled("div")`
  display: none;
  position: relative;
  width: 100%;
  @media screen and (max-width: 600px) {
    display: inherit;
  }
`;

const Big5Test: React.FC<{
  resultsSent?: any;
  abortTest?: any;
  onboarding?: boolean;
  nextStep?: any;
  personId?: string;
}> = ({ resultsSent, abortTest, onboarding, nextStep, personId }) => {
  const { t } = useTranslation("common");
  const [progress, setProgress] = useState(0);
  const [index, setIndex] = useState(0);
  const [startTimestamp, setStartTimestamp] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { data, loading } = useFetch(TestsAPI.getById("b5"));
  const peConnect = useSelector((state: any) => state.user?.peConnect);
  const link = useSelector((state: any) => state.home?.paramjson?.webappUrl);
  const dispatch = useDispatch();

  const [showEndQuestionnary, setShowEndQuestionnary] = useState(false);
  const EndQuestionnary = useSelector(
    (state: any) => state.home?.paramjson?.viewQuestionAfterBig5
  );

  const navigate = useNavigate();

  const init = (questArray: any) => {
    const array: any = [];
    questArray.map((quest: any) =>
      array.push({
        value: null,
        labels: [quest.answers[0].value, quest.answers[1].value],
        answers: [quest.answers[0].label, quest.answers[1].label],
        description: [
          quest.answers[0].description,
          quest.answers[1].description,
        ],
      })
    );
    setQuestions(array);
    setStartTimestamp(+new Date());
  };

  const initPendingTest = (data: any, progress: number) => {
    const pendingIndex = data.findIndex((item: any) => !item.value);
    setIndex(pendingIndex);
    data && setQuestions(data);
    progress && setProgress(progress);
  };

  const autoFillForm = () => {
    if (
      process.env.REACT_APP_ENV !== "dev" &&
      process.env.REACT_APP_ENV !== "release"
    )
      return;

    questions.map(
      (quest: any) => (quest.value = quest.labels[Math.random() > 0.5 ? 1 : 0])
    );
    setIndex(questions.length - 1);
    setProgress(100);
    onSubmit();
  };

  const setStep = (e: number) => {
    if (e === index) {
      setIndex(index + 1);
    } else {
      setIndex(e);
    }
  };

  const setAnswer = (e: any) => {
    const arr: any = [...questions];
    arr[e.index].value = e.selection;
    setQuestions(arr);
    updateProgress();
  };

  const updateProgress = () => {
    const progressUnit = (1 / questions.length) * 100;
    let i = 0;
    questions.filter((quest: any) => quest.value !== null && i++);
    i * progressUnit === 100 && finishTest();
    setProgress(i * progressUnit);
  };

  const finishTest = () => {
    if (!EndQuestionnary) onSubmit();
    else {
      setShowEndQuestionnary(true);
    }
  };

  const finishLater = () => {
    if (onboarding) {
      const newState: any = Utils.getCookie(`${personId}_do_later_step`) || [];
      newState?.push("personality");
      Utils.setLongCookies(`${personId}_do_later_step`, newState);
    }
    localStorage.setItem(`big5_pending_${personId}`, JSON.stringify(questions));
    localStorage.setItem(`big5_progress_${personId}`, JSON.stringify(progress));
    onboarding ? nextStep() : navigate("/app/tests");
  };

  const generateResults = () => {
    const answers: any = [];
    questions.map((quest: any) => answers.push(quest.value));
    return answers;
  };

  const prepareSave = () => {
    return {
      duration: getDuration(startTimestamp),
      raw: generateResults(),
      test: "/api/tests/b5",
    };
  };

  const onSubmit = async () => {
    const dataModel = prepareSave();

    // Only for GOM
    if (peConnect && process.env.REACT_APP_GOM === "true" && link) {
      Utils.setLongCookies(
        `${personId}_ptw_popup_timer`,
        Date.now() + 2.5 * 1000 * 60
      );
      dispatch(setActive(true));
    }
    // ---

    try {
      await ResultsAPI.post(dataModel);
      resultsSent(true);
      localStorage.removeItem(`big5_pending_${personId}`);
      localStorage.removeItem(`big5_progress_${personId}`);
      if (onboarding) {
        const newState: any =
          Utils.getCookie(`${personId}_do_later_step`) || [];
        if (newState?.includes("personality")) {
          newState.splice(
            newState?.findIndex((el: any) => el === "personality"),
            1
          );
          Utils.setLongCookies(`${personId}_do_later_step`, newState);
        }
        const newStateComplete: any =
          Utils.getCookie(`${personId}_completed_step`) || [];
        if (!newStateComplete?.includes("personality")) {
          newStateComplete.push("personality");
          Utils.setLongCookies(`${personId}_completed_step`, newStateComplete);
        }
      }
    } catch (err) {
      console.error("b5 error", err);
      toast.error(t("errors.error_append"));
    }
  };

  useEffect(() => {
    const big5_pending = localStorage.getItem(`big5_pending_${personId}`);
    const big5_progress = localStorage.getItem(`big5_progress_${personId}`);

    big5_pending &&
      big5_progress &&
      initPendingTest(JSON.parse(big5_pending), parseInt(big5_progress));

    data && !big5_pending && init(data.questions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {loading ? (
        <Loader noBackground={true} />
      ) : !showEndQuestionnary ? (
        <Container>
          {!onboarding && (
            <HeadText>
              <Typography fontWeight="bold" variant="body1">
                {t("tests.page.your_test")}
              </Typography>
            </HeadText>
          )}
          <Typography
            sx={{ mb: 4, mt: 3 }}
            fontWeight="bold"
            color="primary"
            variant="body1"
          >
            {t("big5.test.title")}
          </Typography>

          <CircleProgressCtn>
            <CircularProgressLabel
              dbClickEvent={autoFillForm}
              progress={progress}
            />
          </CircleProgressCtn>
          <ProgressBarCtn>
            <LinearProgressBar
              progress={progress}
              type="b5"
              dbClick={autoFillForm}
            />
            <Box position="absolute" width="100%" height="100%" top="0">
              <Typography>{Math.floor(progress)}%</Typography>
            </Box>
          </ProgressBarCtn>

          <Carousel>
            {questions.map((quest: any, i: number) => (
              <Big5TestItem
                index={i}
                key={i}
                value={quest}
                previousValue={questions[i - 1]}
                last={i + 1 === questions.length}
                setStepClick={setStep}
                currentIndex={index}
                setAnswer={setAnswer}
              />
            ))}
            {index > 0 && (
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                top="135px"
                position="relative"
              >
                <BackButton
                  variant="outlined"
                  onClick={() => setIndex((current) => current - 1)}
                >
                  {t("btn.prev")}
                </BackButton>
              </Box>
            )}
          </Carousel>

          <Box
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            sx={{ mt: 1 }}
          >
            <CustomButton
              sx={{ m: 1 }}
              variant="contained"
              onClick={() => abortTest(true)}
            >
              <RestartAltIcon sx={{ mr: 1 }} /> {t("big5.test.retry")}
            </CustomButton>
            {progress < 100 && (
              <CustomButton
                sx={{ m: 1 }}
                variant="outlined"
                color="secondary"
                onClick={() => setOpenModal(true)}
              >
                {t("tests.modal.do_later")}
              </CustomButton>
            )}
          </Box>
          <FinishLaterTestModal
            open={openModal}
            close={() => setOpenModal(false)}
            finishLater={() => finishLater()}
          />
        </Container>
      ) : (
        <Big5EndQuestionnary submitTest={onSubmit} />
      )}
    </>
  );
};

export default Big5Test;
