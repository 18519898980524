import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TagList from "./TagList";
import useFetch from "../../hooks/useFetch";

import tagEntitiesAPI from "../../_api/_tagEntitiesAPI";
import AutocompleteTag from "../AutocompleteTag";

const TagCard = ({ workId, personId, type, style, needModal }: any) => {
  const { t }: any = useTranslation("common");
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const entityUrl =
    type === "person" ? `/api/people/${personId}` : `/api/works/${workId}`;

  const { data: dataEntities, loading } = useFetch(
    tagEntitiesAPI.get({ entity: entityUrl })
  );

  const [tagEntities, setTagEntities] = useState<any>([]);

  const removeTagEntity = (id: string) => {
    setTagEntities((current: any) =>
      current.filter((el: any) => el["@id"] !== id)
    );
  };

  const updateTagColor = (tagId: string, newColor: string) => {
    setTagEntities((current: any) =>
      current.map((tagEntity: { tag: { id: string } }) =>
        tagEntity.tag.id === tagId
          ? { ...tagEntity, tag: { ...tagEntity.tag, color: newColor } }
          : tagEntity
      )
    );
  };

  useEffect(() => {
    setTagEntities(dataEntities?.["hydra:member"]);
  }, [dataEntities]);

  const handleCardClick = () => {
    setIsEdit(!isEdit);
  };

  return (
    <div style={style}>
      <Card
        sx={{ backgroundColor: needModal === true ? "transparent" : undefined }}
      >
        <Grid container justifyContent={needModal === true ? "flex-end" : ""}>
          <Grid item>
            <CardHeader
              title={t("tag.label_tags")}
              titleTypographyProps={{ variant: "subtitle2" }}
              sx={{ color: needModal === true ? "white" : undefined }}
            />
          </Grid>
        </Grid>
        <CardContent
          onClick={!needModal ? handleCardClick : undefined}
          sx={{
            paddingInline: "16px",
            paddingTop: "5px",
            paddingBottom: "5px",
            cursor: !needModal ? "pointer" : undefined,
          }}
        >
          {!loading ? (
            <TagList
              removeTagEntity={removeTagEntity}
              tagEntities={tagEntities}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              personId={personId}
              updateTagColor={updateTagColor}
              type={type}
              needModal={needModal}
              workId={workId}
              setTagEntities={setTagEntities}
            />
          ) : null}
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "center",
          }}
        >
          {isEdit ? (
            <AutocompleteTag
              type={type}
              personId={personId}
              workId={workId}
              tagEntities={tagEntities}
              setTagEntities={setTagEntities}
            />
          ) : null}
        </CardActions>
      </Card>
    </div>
  );
};

export default TagCard;
