import { Box, styled, Typography } from "@mui/material";
import React from "react";

const Title = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.orange[500],
}));

const Subtitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
}));

const IncMotivationProfile: React.FC<any> = ({ result }) => {
  return (
    <>
      <Box display="flex">
        <Title variant="h3" color="primary">
          Profil :
        </Title>
        <Title sx={{ ml: 1 }} variant="h3" fontWeight="200">
          {result?.dynamic_motivation?.profile1?.title ??
            result?.dym_motiv?.profile1?.title}
        </Title>
      </Box>

      <Subtitle
        textAlign="center"
        variant="body1"
        sx={{ mt: 1, maxWidth: "760px" }}
        dangerouslySetInnerHTML={{
          __html:
            result?.dynamic_motivation?.profile1?.text ??
            result?.dym_motiv?.profile1?.text,
        }}
      />
    </>
  );
};

export default IncMotivationProfile;
