import React, { useState } from "react";
import { List, Typography, Avatar, Box, Button } from "@mui/material";
import CustomButtonModal from "../../../components/common/CustomBtnModal";
import { useTranslation } from "react-i18next";
import AskShareModal from "../../../components/benef/AskShareModal";

const NoAcceptList: React.FC<any> = ({
  listNoAccept,
}: {
  listNoAccept: any[];
}) => {
  const [showMore, setShowMore] = useState(false);
  const [openModalAskShare, setOpenModalAskShare] = useState(false);

  const chunkArray = (array: any[], size: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  const initialItems = listNoAccept.slice(0, 10);
  const remainingItems = listNoAccept.slice(10);

  const chunkedList = chunkArray(initialItems, 5);

  const closeAskModal = () => setOpenModalAskShare(false);

  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {listNoAccept.length > 1
          ? t("third_party.campaign.modal.no_accept_message_multi")
          : t("third_party.campaign.modal.no_accept_message")}
      </Typography>

      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: listNoAccept.length > 5 ? "space-around" : "",
        }}
      >
        {chunkedList.map((chunk, columnIndex) => (
          <List key={columnIndex} style={{ minWidth: "200px" }}>
            {chunk.map((el: any, index: number) => (
              <div
                key={index}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <Avatar sx={{ mr: 1 }} aria-label="initials">
                  {el?.person?.firstName.charAt(0) +
                    el?.person?.lastName.charAt(0)}
                </Avatar>
                <Typography fontWeight="bold" fontSize={13}>
                  {`${el?.person?.firstName} ${el?.person?.lastName}`}
                </Typography>
              </div>
            ))}
          </List>
        ))}
      </div>

      {showMore && remainingItems.length > 0 && (
        <div
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-around",
          }}
        >
          {chunkArray(remainingItems, 5).map((chunk, columnIndex) => (
            <List key={columnIndex} style={{ minWidth: "200px" }}>
              {chunk.map((el: any, index: number) => (
                <div
                  key={index}
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 5,
                  }}
                >
                  <Avatar sx={{ mr: 1 }} aria-label="initials">
                    {el?.person?.firstName.charAt(0) +
                      el?.person?.lastName.charAt(0)}
                  </Avatar>
                  <Typography fontWeight="bold" fontSize={13}>
                    {`${el?.person?.firstName} ${el?.person?.lastName}`}
                  </Typography>
                </div>
              ))}
            </List>
          ))}
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
      >
        {remainingItems.length > 0 && (
          <Button
            onClick={() => setShowMore(!showMore)}
            variant="contained"
            sx={{ margin: 5 }}
          >
            {showMore ? t("actions.show_less") : t("actions.show_more")}
          </Button>
        )}
      </div>
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant="h5" gutterBottom>
          {listNoAccept.length > 1
            ? t("third_party.campaign.modal.send_request_consent_multi")
            : t("third_party.campaign.modal.send_request_consent")}
        </Typography>

        <CustomButtonModal
          variant="contained"
          text={t(`btn.send`)}
          onClick={() => setOpenModalAskShare(true)}
        />
      </Box>
      <AskShareModal
        open={openModalAskShare}
        close={closeAskModal}
        profiles={listNoAccept}
        folderShare={true}
      />
    </div>
  );
};

export default NoAcceptList;
