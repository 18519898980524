import {
  Card,
  Modal,
  CardContent,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../contexts/ProfileContext";
import peopleAPI from "../../_api/_people";
import UsersAPI from "../../_api/_usersAPI";
import { modalStyle } from "../../_utils/modalStyle";
import ProfileEditFormBody from "./ProfileEditFormBody";
import { useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";

const ProfileEditModal: React.FC<{ open: boolean; close: any }> = ({
  close,
  open,
}) => {
  const { t } = useTranslation("common");
  const { peopleData, setPeopleData } = useContext(ProfileContext);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    zoom: "",
    teams: "",
    linkedin: "",
    image: "",
    addressValue: { label: "" },
  });
  React.useEffect(() => {
    if (peopleData)
      setForm({
        firstName: peopleData.firstName,
        lastName: peopleData.lastName,
        email: peopleData.email,
        gender: peopleData.gender,
        zoom: peopleData.otherData?.visio?.zoom,
        teams: peopleData.otherData?.visio?.teams,
        linkedin: peopleData.otherData?.visio?.linkedin,
        image: peopleData?.image?.contentUrl,
        addressValue: peopleData?.address
          ? { label: peopleData?.address }
          : { label: "" },
      });
  }, [peopleData]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const body = {
        ...values,
        ...((values.zoom || values.teams || values.linkedin) && {
          otherData: {
            visio: {
              ...(values.zoom && { zoom: values.zoom }),
              ...(values.teams && { teams: values.teams }),
              ...(values.linkedin && { linkedin: values.linkedin }),
            },
            hobbies: peopleData.otherData?.hobbies || [],
          },
        }),
      };
      const res = await UsersAPI.put(tokenData?.user_id, body);
      const peopleRes = await peopleAPI.put(tokenData?.person_id, body);

      body.addressInfo = peopleRes.data.addressInfo;
      body.address = peopleRes.data.addressInfo.full_address;

      if (res) setPeopleData({ ...peopleData, ...body });
      setLoading(false);
      toast.success(t("success.profile_modified"));
      close();
    } catch (err) {
      console.log("ERR", err);
      setLoading(false);
      toast.error(t("errors.error_append"));
    }
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
        }}
      >
        <CardContent sx={{ pt: 0 }}>
          <Box
            sx={{ width: "100%", display: "flex", alignItems: "start", mt: 3 }}
          >
            <Typography
              variant="h5"
              sx={{ ml: "24px", textAlign: "center", flexGrow: 1 }}
            >
              {t("profilepage.edit")}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{ ml: "auto", mt: -2, mr: -2 }}
            >
              <HighlightOff />
            </IconButton>
          </Box>

          {open && (
            <ProfileEditFormBody
              form={form}
              close={close}
              submit={handleSubmit}
              loading={loading}
            />
          )}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ProfileEditModal;
