import React from "react";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

const starSize = "40px";

const SStarEmpty = styled(StarRoundedIcon)`
  color: #e2e2e2;
  font-size: ${starSize};
`;
const SStarFilled = styled(StarRoundedIcon)`
  color: #ffce71;
  font-size: ${starSize};
`;

const MoodRating: React.FC<{ value: number; size?: "small" }> = ({
  value,
  size,
}) => {
  return (
    <div>
      <Rating
        name="customized-color"
        value={value}
        readOnly
        precision={1}
        max={3}
        icon={<SStarFilled sx={{ ...(size ? { fontSize: "20px" } : {}) }} />}
        emptyIcon={
          <SStarEmpty sx={{ ...(size ? { fontSize: "20px" } : {}) }} />
        }
      />
    </div>
  );
};

export default MoodRating;
