import { Fade, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import IntroSplashscreen from "./IntroSplashscreen";
import StatusSplashscreen from "./StatusSplashscreen";

const Root = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000000;
  background: white;
`;

const GlobalSplashscreen: React.FC = () => {
  const open = useSelector((state: any) => state.globalLoader.open);
  const type = useSelector((state: any) => state.globalLoader.type);

  const [inState, setInState] = useState(open);

  const ref = useRef<any>(null);

  const getLoaderFromType = () => {
    switch (type) {
      case "sign-up":
        return <StatusSplashscreen type="sign-up" />;
      default:
        return <IntroSplashscreen />;
    }
  };

  useEffect(() => {
    let timeout: any;
    if (!open) {
      timeout = setTimeout(() => {
        setInState(false);
      }, 2000);
    } else {
      setInState(true);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [open]);

  return (
    <Fade
      ref={ref}
      mountOnEnter
      unmountOnExit
      in={inState}
      timeout={{ exit: 500 }}
    >
      <Root>{getLoaderFromType()}</Root>
    </Fade>
  );
};

export default GlobalSplashscreen;
