import { SET_DISPATCHPOPUP_STATE } from "../actions/dispatchPopupActions";
import initialState from "../initialState";

const userReducer = (
  state = { ...initialState.dispatchModal },
  action: any
) => {
  switch (action.type) {
    case SET_DISPATCHPOPUP_STATE:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default userReducer;
