import { Button, styled } from "@mui/material";
import React from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ButtonRoot = styled(Button)(({ theme }: any) => ({
  fontWeight: "lighter",
  boxShadow: "none",
  fontSize: "12px",
  height: "30px",
  textTransform: "none",
  borderRadius: "50px",
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  color: "#484848",
  backgroundColor: "#F2F2F2",
  "& .MuiButton-endIcon": {
    marginLeft: "3px",
  },
}));

const Arrow = styled(ArrowForwardIos)({
  width: 9,
});

const ShowAllButton: React.FC<{
  title?: string;
  onClick?: any;
  marginLeft?: number;
}> = ({ title = "actions.see_all", onClick, marginLeft }) => {
  const { t } = useTranslation("common");
  return (
    <ButtonRoot
      onClick={() => onClick && onClick()}
      sx={{ ml: marginLeft ?? "auto" }}
      variant="contained"
      size="small"
      color="inherit"
      endIcon={<Arrow color="inherit" />}
    >
      {t(title)}
    </ButtonRoot>
  );
};
export default ShowAllButton;
