import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Typography, Skeleton, Tooltip, styled } from "@mui/material";
import { requestUpdateMessage } from "../../redux/actions/chatActions";
import { useDispatch } from "react-redux";
import chatService from "../../services/chat.service";
import StringAvatar from "../common/StringAvatar";
import MessageTextContainer from "./MessageTextContainer";

const Root = styled(Box)({
  marginLeft: "3vh",
  marginRight: "3vh",
  marginTop: "1vh",
  marginBottom: "1vh",
  display: "flex",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  wordBreak: "break-all",
  justifyContent: "flex-start",
});

const LeftIcon = styled(Box, {
  shouldForwardProp: (props) => props !== "isHidden",
})<{ isHidden: boolean }>(({ isHidden }) => ({
  width: "40px",
  height: "40px",
  borderRadius: "100%",
  margin: "8px 12px 0 0",
  visibility: isHidden ? "hidden" : "visible",
}));

const RightIcon = styled(Box, {
  shouldForwardProp: (props) => props !== "isHidden",
})<{ isHidden: boolean }>(({ isHidden }) => ({
  width: "40px",
  height: "40px",
  borderRadius: "100%",
  margin: "8px 0 0 12px",
  visibility: isHidden ? "hidden" : "visible",
}));

const LightTypography: any = styled(Typography)(({ theme }: any) => ({
  position: "absolute",
  width: "15px",
  height: "15px",
  border: "solid 3px #fff",
  borderRadius: theme.shape.borderRadius48,
  left: "28px",
  top: "25px",
  alignmentBaseline: "baseline",
  zIndex: 1200,
}));

const Message: React.FC<any> = ({
  data,
  style = { own: true, flying: false, sameAuthorAsPrevious: false },
  openDeleteModal,
  setDataToModal,
}) => {
  const dispatch = useDispatch();

  const [mouseIsOver, setMouseIsOver] = useState<boolean>(false);

  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [onDelete, setOnDelete] = useState<boolean>(false);

  const [textField, setTextField] = useState<string>(data?.content ?? "");

  const handleKeyPress = (event: any) => {
    if (onEdit || onDelete) {
      if (event.key === "Enter" && !event.ctrlKey) {
        event.preventDefault();
        data.content = event.target.value;
        style.flying = true;
        onEdit && dispatch(requestUpdateMessage(data)) && setOnEdit(false);
        // onDelete && dispatch(requestDeleteMessage(data)) && setOnDelete(false);
      } else if (event.key === "Enter" && event.ctrlKey) {
        setTextField(textField + "\n");
      } else if (event.key === "Escape") {
        onEdit && setOnEdit(false);
        onDelete && setOnDelete(false);
      }
    }
  };

  return (
    <>
      <Root
        onKeyDown={(e: any) => {
          handleKeyPress(e);
        }}
        onMouseEnter={(e) => {
          style.own && setMouseIsOver(true);
        }}
        onMouseLeave={(e) => {
          style.own && setMouseIsOver(false);
        }}
      >
        {style.own ? (
          <></>
        ) : (
          <LeftIcon isHidden={style.sameAuthorAsPrevious}>
            <Tooltip title={data?.author?.fullName ?? data?.authorFullName}>
              <Box sx={{ position: "relative" }}>
                <StringAvatar
                  size={40}
                  name={data?.author?.fullName ?? data?.authorFullName}
                  url={data?.author?.image?.contentUrl ?? data?.author?.image}
                  onClick={() =>
                    window.open(
                      `/app/profile/${
                        data?.author?.id ?? data?.authorId
                      }/overview`
                    )
                  }
                />
                <LightTypography
                  component="span"
                  sx={{
                    backgroundColor: chatService.getColorByStatus(
                      data?.author?.connectionStatus
                    ),
                  }}
                />
              </Box>
            </Tooltip>
          </LeftIcon>
        )}

        <MessageTextContainer
          style={style}
          mouseIsOver={mouseIsOver}
          onEdit={onEdit}
          onDelete={onDelete}
          setOnEdit={setOnEdit}
          textField={textField}
          openDeleteModal={openDeleteModal}
          setDataToModal={setDataToModal}
          data={data}
          setTextField={setTextField}
          setOnDelete={setOnDelete}
        />

        {style.own ? (
          <>
            <RightIcon isHidden={style.sameAuthorAsPrevious}>
              {!style.flying ? (
                <>
                  <Tooltip
                    title={data?.author?.fullName ?? data?.authorFullName}
                  >
                    <Box
                      sx={{ position: "relative" }}
                      onClick={() =>
                        window.open(
                          `/app/profile/${
                            data?.author?.id ?? data?.authorId
                          }/overview`
                        )
                      }
                    >
                      <StringAvatar
                        size={40}
                        name={data?.author?.fullName ?? data?.authorFullName}
                        url={
                          data?.author?.image?.contentUrl ?? data?.author?.image
                        }
                      />
                      <LightTypography
                        component="span"
                        sx={{
                          backgroundColor: chatService.getColorByStatus(
                            data?.author?.connectionStatus
                          ),
                        }}
                      />
                    </Box>
                  </Tooltip>
                </>
              ) : (
                <Skeleton
                  variant="circular"
                  width="40px"
                  height="40px"
                ></Skeleton>
              )}
            </RightIcon>
          </>
        ) : (
          <></>
        )}
      </Root>
    </>
  );
};

export default Message;
