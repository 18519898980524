import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { checkQuestionType } from "./checkQuestionType";
import IncCheckboxList from "./IncCheckboxList";
import IncQuestionHierarchy from "./IncQuestionHierarchy";
import IncRadioList from "./IncRadioList";
import { IncTestContext } from "./IncTestContext";

const IncQuestionHandler: React.FC<any> = ({ question, index, mounted }) => {
  const [questionType, setQuestionType] = useState<any>(null);
  const [questionsComponent, setQuestionsComponent] = useState<any>(null);
  const { handleUserResponse, validated } = useContext(IncTestContext);

  // const alphabetical = [
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "E",
  //   "F",
  //   "G",
  //   "H",
  //   "I",
  //   "J",
  //   "K",
  //   "L",
  //   "M",
  //   "N",
  //   "O",
  //   "P",
  //   "Q",
  //   "R",
  //   "S",
  //   "T",
  //   "U",
  //   "V",
  //   "W",
  //   "X",
  //   "Y",
  //   "Z",
  // ];

  useEffect(() => {
    if (question) setQuestionType(checkQuestionType(question));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const handleChange = (questionState: any) => {
    if (index >= 0 && questionState) handleUserResponse(index, questionState);
  };

  useEffect(() => {
    switch (questionType) {
      case "checkbox":
        return setQuestionsComponent(
          <IncCheckboxList
            validated={validated}
            sendResponse={handleChange}
            question={question}
            mounted={mounted}
          />
        );
      case "radio":
        return setQuestionsComponent(
          <IncRadioList
            validated={validated}
            sendResponse={handleChange}
            question={question}
            mounted={mounted}
          />
        );
      case "hierarchy":
        return setQuestionsComponent(
          <IncQuestionHierarchy
            validated={validated}
            sendResponse={handleChange}
            question={question}
            mounted={mounted}
          />
        );
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionType, validated]);

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ textTransform: "uppercase" }}>
        {question.name}
      </Typography>
      {questionsComponent}
    </Box>
  );
};

export default IncQuestionHandler;
