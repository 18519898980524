import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

const ProfileDocumentDeleteDialog: React.FC<any> = ({
  open,
  handleClose,
  handleDelete,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Voulez vous supprimer ce document ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Cette action est irréversible.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          color="error"
          variant="contained"
          onClick={handleDelete}
          autoFocus
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileDocumentDeleteDialog;
