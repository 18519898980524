import * as React from "react";
import clsx from "clsx";
import { styled } from "@mui/system";
import { useSwitch, UseSwitchProps } from "@mui/core/SwitchUnstyled";

const SwitchRoot = styled("span")`
  display: inline-block;
  position: relative;
  width: 62px;
  height: 28px;
  padding: 7px;
`;

const SwitchInput = styled("input")`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;

const SwitchThumb = styled("span")(
  ({ theme }: any) => `
  position: absolute;
  display: block;
  box-shadow: ${theme.customShadows.z1};
  background-color: ${theme.palette.mode === "dark" ? "white" : `white`};
  width: 30px;
  height: 30px;
  border-radius: 16px;
  top: -2px;
  left: 0px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50%;
    background-image: url('/static/icons/star_outline.svg');
  }

  &.focusVisible {
    background-color: #79B;
  }

  &.checked {
    transform: translateX(32px);
    
    &:before {
      background-image: url('/static/icons/star.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50%;
    }
  }
`
);

const SwitchTrack = styled("span")(
  ({ theme }) => `
  background-color: ${theme.palette.mode === "dark" ? "#8796A5" : "#aab4be"};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: block;
`
);

const BookmarkSwitch: React.FC<any> = (props: UseSwitchProps | any) => {
  const { getInputProps, disabled, focusVisible } = useSwitch(props);

  const stateClasses = {
    disabled,
    focusVisible,
  };

  return (
    <SwitchRoot className={clsx(stateClasses)}>
      <SwitchTrack>
        <SwitchThumb
          className={clsx(stateClasses, props.baseValue ? "checked" : "")}
        />
      </SwitchTrack>
      <SwitchInput {...getInputProps()} aria-label="Demo switch" />
    </SwitchRoot>
  );
};

export default BookmarkSwitch;
