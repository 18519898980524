import { Skeleton, Typography, Box } from "@mui/material";
import React from "react";

const TitleWithShowAllSkeleton: React.FC<{
  buttonWidth?: number;
  textWidth?: number;
  textVariant?:
    | "body1"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "subtitle1"
    | "subtitle2"
    | "body2"
    | "overline"
    | undefined;
}> = ({ buttonWidth = 86, textVariant = "body1", textWidth = 100 }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant={textVariant}>
        <Skeleton width={`${textWidth}px`} />
      </Typography>
      <Skeleton
        variant="rectangular"
        width={`${buttonWidth}px`}
        height="30px"
        sx={{ borderRadius: "15px" }}
      />
    </Box>
  );
};

export default TitleWithShowAllSkeleton;
