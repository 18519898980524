import React from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, Chip, Typography, Paper, Container, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import ArchetypeAPI from "../../_api/_archetypeAPI";
import StringAvatar from "../common/StringAvatar";
import TestBadge from "./badges/TestBadge";
import { useSelector } from "react-redux";
import ProfileArchetypesSkeleton from "../skeletons/advisor/ProfileArchetypesSkeleton";

const ArchetypesPaper = styled(Paper)(({ theme }: any) => ({
  borderRadius: "23px",
  boxShadow: theme.customShadows.z8,
}));

const ChipProfile = styled(Chip)({
  background:
    "linear-gradient(90deg, #04BBFF 0%, #4F60FF 100%) 0% 0% no-repeat padding-box",
  width: "120px",
  height: "40px",
  borderRadius: "20px",
});

const ChipAvatar = styled(Chip)({
  background:
    "linear-gradient(89deg, #33C8FF 0%, #4E60FF 100%) 0% 0% no-repeat padding-box",
  width: "170px",
  height: "40px",
  borderRadius: "20px",
});

const ProfileArchetypes = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const context = useSelector((state: any) => state.auth.context);
  const tokenData = useSelector((state: any) => state.user);

  const { loading, data } = useFetch(
    ArchetypeAPI.get({ personId: id || tokenData.person_id })
  );

  return loading ? (
    <ProfileArchetypesSkeleton />
  ) : data ? (
    <ArchetypesPaper elevation={3}>
      <Container>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{ mt: 2 }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            sx={{ mb: 2 }}
          >
            <TestBadge type="Big5" size={55} />
            <Box>
              <Typography
                variant="subtitle1"
                fontWeight="normal"
                sx={{ mb: -0.5 }}
              >
                {t(`big5.result.perso`)}
              </Typography>
              <Typography variant="subtitle1" color="primary">
                {data.profile}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-around">
            <ChipProfile
              sx={{ m: 0.5 }}
              label={
                <Typography
                  variant="body2"
                  whiteSpace="normal"
                  textAlign="center"
                >
                  {data.trait_1}
                </Typography>
              }
              color="primary"
            />
            <ChipProfile
              sx={{ m: 0.5 }}
              label={
                <Typography
                  variant="body2"
                  whiteSpace="normal"
                  textAlign="center"
                >
                  {data.trait_2}
                </Typography>
              }
              color="primary"
            />
          </Box>
          <Typography
            variant="body2"
            textAlign="center"
            sx={{ maxWidth: "30ch", mt: 2 }}
          >
            {data?.desc}
          </Typography>
          <ChipAvatar
            sx={{ mt: 3, mb: 1 }}
            label={
              <Box display="flex" alignItems="center">
                <AutoAwesomeIcon sx={{ fontSize: "18px", mr: 1 }} />
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `big5.result.avatar_title${
                        context === "collab" || context === "benef"
                          ? "_collab"
                          : ""
                      }`
                    ),
                  }}
                ></Typography>
              </Box>
            }
            color="primary"
          />
          <Box sx={{ mb: 1 }}>
            {data?.archetype?.map((item: any) => (
              <Box
                display="flex"
                alignItems="center"
                sx={{ m: 1 }}
                key={item.avatar}
              >
                <StringAvatar
                  size={50}
                  name={item?.name}
                  url={item?.avatar}
                  tooltip={item?.desc}
                />
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ ml: 1, maxWidth: "7ch" }}
                >
                  {item?.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </ArchetypesPaper>
  ) : (
    <></>
  );
};

export default ProfileArchetypes;
