import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import personSkillsAPI from "../../../_api/_personSkillsAPI";
import WorkSkillsCompare from "../../works/WorkSkillsCompare";

const MatchingSkillsCampaign = ({ campaignId, personId }: any) => {
  const [compareSkills, setCompareSkills] = useState<any>();
  const [compareAbilities, setCompareAbilities] = useState<any>();
  const [allSkills, setAllSkills] = useState<any>([]);
  const [allAbilities, setAllAbilities] = useState<any>([]);
  const [personSkills, setPersonSkills] = useState<any>(null);
  const [personAbilities, setPersonAbilities] = useState<any>(null);

  const { data } = useFetch(
    `campaign_skills?campaign.id=${campaignId}&pagination=false`
  );

  const getPersonSkills = async () => {
    try {
      const skills = await axios.get(
        personSkillsAPI.get({
          "person.id": personId,
          pagination: false,
        })
      );
      const abilities = await axios.get(
        personSkillsAPI.get({
          "person.id": personId,
          pagination: false,
          "skill.type": "transversal",
        })
      );
      skills?.data?.["hydra:member"] &&
        setPersonSkills(skills?.data?.["hydra:member"]);
      abilities?.data?.["hydra:member"] &&
        setPersonAbilities(abilities?.data?.["hydra:member"]);
    } catch (err) {}
  };

  useEffect(() => {
    personId && getPersonSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  useEffect(() => {
    data &&
      setAllSkills(
        data?.["hydra:member"]
          ?.filter((el: any) => el?.skill?.type !== "transversal")
          ?.sort((a: any, b: any) => b?.skillLevel - a?.skillLevel)
      );
    data &&
      setAllAbilities(
        data?.["hydra:member"]
          ?.filter((el: any) => el?.skill?.type === "transversal")
          ?.sort((a: any, b: any) => b?.skillLevel - a?.skillLevel)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    personSkills !== null && allSkills.length > 0 && handleSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSkills, personSkills]);

  useEffect(() => {
    personAbilities !== null && allAbilities.length > 0 && handleAbilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAbilities, personAbilities]);

  const handleSkills = () => {
    const newSkills = [...allSkills];
    for (let i = 0; i < newSkills?.length; ++i) {
      for (let y = 0; y < personSkills?.length; ++y) {
        personSkills[y]?.label === newSkills[i].label &&
          (newSkills[i] = {
            ...newSkills[i],
            userSkillLevel: personSkills[y]?.personLevel,
          });
      }
    }
    handleCompareSkills(newSkills);
  };

  const handleAbilities = () => {
    const newAbilities = [...allAbilities];
    for (let i = 0; i < newAbilities?.length; ++i) {
      for (let y = 0; y < personAbilities?.length; ++y) {
        personAbilities[y]?.label === newAbilities[i].label &&
          (newAbilities[i] = {
            ...newAbilities[i],
            userSkillLevel: personAbilities[y]?.personLevel,
          });
      }
    }
    handleCompareAbilities(newAbilities);
  };

  const handleCompareSkills = (skills: any) => {
    setCompareSkills({
      to_improve: skills.filter(
        (skill: any) => skill.userSkillLevel < skill.skillLevel
      ),
      acquired: skills.filter(
        (skill: any) => skill.userSkillLevel >= skill.skillLevel
      ),
      to_acquire: skills.filter((skill: any) => !skill.userSkillLevel),
    });
  };

  const handleCompareAbilities = (abilities: any) => {
    setCompareAbilities({
      to_improve: abilities.filter(
        (skill: any) => skill.userSkillLevel < skill.skillLevel
      ),
      acquired: abilities.filter(
        (skill: any) => skill.userSkillLevel >= skill.skillLevel
      ),
      to_acquire: abilities.filter((skill: any) => !skill.userSkillLevel),
    });
  };

  return (
    <>
      {compareSkills && (
        <Box sx={{ m: 2 }}>
          <WorkSkillsCompare
            type="technical"
            skillsWork={compareSkills}
            noCard
          />
        </Box>
      )}
      {compareAbilities && (
        <Box sx={{ m: 2 }}>
          <WorkSkillsCompare
            type="transversal"
            skillsWork={compareAbilities}
            noCard
          />
        </Box>
      )}
    </>
  );
};

export default MatchingSkillsCampaign;
