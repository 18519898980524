import { styled, Theme } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";
import React from "react";

const ExpandMoreButton: any = styled(ExpandMore, {
  shouldForwardProp: (props) => props !== "noMarginLeft" && props !== "open",
})(
  ({
    theme,
    noMarginLeft,
    open,
  }: { theme: Theme; noMarginLeft: boolean; open: boolean } & any) => ({
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
    marginLeft: noMarginLeft ? "" : "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  })
);

const ExpandButton: React.FC<{
  open: boolean;
  noMarginLeft?: boolean;
  fontSize?: string;
}> = ({ open, noMarginLeft, fontSize }) => {
  return (
    <ExpandMoreButton
      sx={{ fontSize: `${fontSize}px` ?? "" }}
      noMarginLeft={noMarginLeft}
      open={open}
    />
  );
};
export default ExpandButton;
