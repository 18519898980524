const VALUE = "VALUE";
const LOADING = "LOADING";

export const skillsReducer = (state: any, action: any) => {
  switch (action.type) {
    case VALUE:
      return {
        ...state,
        value: action.value,
      };
    case LOADING:
      return {
        ...state,
        loading: action.value,
      };
    default:
      return state;
  }
};
