import { useTheme } from "@mui/styles";
import React from "react";

const HomeIcon: React.FC<{
  active?: boolean;
  context?: string;
  size?: number;
}> = ({ active, context, size }) => {
  const theme: any = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? "20"}
      height={size ?? "20"}
      viewBox="0 0 17.394 17.391"
    >
      <path
        id="_005-house"
        data-name="005-house"
        d="M13.317,17.428H4.077A4.081,4.081,0,0,1,0,13.352V7.635A4.1,4.1,0,0,1,1.6,4.394L6.225.87a4.086,4.086,0,0,1,4.945,0l1.468,1.119V1.122a.679.679,0,0,1,1.359,0V3.361a.679.679,0,0,1-1.091.54l-2.56-1.951a2.724,2.724,0,0,0-3.3,0L2.428,5.474a2.733,2.733,0,0,0-1.07,2.161v5.716a2.721,2.721,0,0,0,2.718,2.718h9.24a2.721,2.721,0,0,0,2.718-2.718V7.635a2.735,2.735,0,0,0-1.058-2.168.679.679,0,1,1,.824-1.08,4.1,4.1,0,0,1,1.592,3.249v5.716A4.081,4.081,0,0,1,13.317,17.428ZM7.338,7.882a.849.849,0,1,0,.849.849A.849.849,0,0,0,7.338,7.882Zm3.567.849a.849.849,0,0,1-1.7,0A.849.849,0,0,1,10.9,8.731ZM8.187,11.449a.849.849,0,0,1-1.7,0A.849.849,0,0,1,8.187,11.449Zm2.718,0a.849.849,0,0,1-1.7,0A.849.849,0,0,1,10.9,11.449Z"
        transform="translate(0 -0.037)"
        fill={
          !active &&
          (context === "collab" ||
            context === "benef" ||
            context === "candidate")
            ? theme.palette.contextColors.collab.font
            : "#fff"
        }
      />
    </svg>
  );
};

export default HomeIcon;
