import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Box,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../_utils/modalStyle";

const Icon = styled(Box)(({ theme }: any) => ({
  borderRadius: "50%",
  margin: "auto",
  marginBottom: "11px",
  width: 55,
  height: 55,
  background: theme.palette.primary[500_8],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const style: any = {
  width: "350px",
  padding: "20px",
};

const ChoiceModal: React.FC<any> = ({
  open,
  close,
  loading,
  title,
  icon,
  onClick,
  subtitle,
  actionLabel,
  color,
}) => {
  const { t } = useTranslation("common");

  return (
    <Modal open={open} onClose={close}>
      <Card sx={{ ...modalStyle, ...style }}>
        <CardContent>
          {icon && <Icon>{icon}</Icon>}
          <Typography variant="h4" textAlign="center">
            {title}
          </Typography>
          {subtitle && (
            <Typography sx={{ mt: 2 }} textAlign="center">
              {subtitle}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            sx={{ width: "100%" }}
          >
            <Button
              sx={{ width: "200px", m: 1 }}
              disabled={loading}
              onClick={onClick}
              size="large"
              variant="contained"
              color={color ?? "primary"}
            >
              {actionLabel ?? t("btn.confirm")}
              {loading && (
                <CircularProgress
                  style={{ width: 20, height: 20, marginLeft: "10px" }}
                />
              )}
            </Button>
            <Button
              sx={{ width: "200px", m: 1 }}
              disabled={loading}
              onClick={close}
              size="large"
            >
              {t("btn.cancel")}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ChoiceModal;
