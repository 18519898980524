import {
  Box,
  FormGroup,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { checkFileExt } from "../../../../Utils";
import MediaObjectsAPI from "../../../../_api/_mediaObjectsAPI";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";

const AdminGeneralInfos: React.FC<any> = ({ param, handleChanges }) => {
  const fileInputRef = useRef<any>();
  const { t } = useTranslation("common");
  const tokenData = useSelector((state: any) => state.user, shallowEqual);

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const onFilesAdded = async (event: any) => {
    if (!checkFileExt(event?.target?.files?.item(0))) return;

    const companyId = `/api/companies/${tokenData.company_id}`;
    if (event?.target?.files?.item(0)?.size < 5000000) {
      const formData = new FormData();
      formData.append("file", event?.target?.files?.item(0));
      formData.append("uriContext", companyId);
      formData.append("name", event?.target?.files?.item(0).name);
      try {
        const res = await MediaObjectsAPI.post(formData);
        handleChanges({ ...param, LOGO: res?.data?.contentUrl });
      } catch (err) {}
    } else {
      toast.error(t("errors.file_too_big"));
    }
  };

  return (
    <div>
      {param && (
        <>
          <FormGroup>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {t("admin.params.general.infos")}
            </Typography>
            <TextField
              value={param.NAME_ETP}
              onChange={(event: any) =>
                handleChanges({ ...param, NAME_ETP: event.target.value })
              }
              label={t("admin.params.general.name_etp")}
              variant="outlined"
            />

            <Typography sx={{ mt: 2 }}>
              {t("admin.params.general.logo")}
            </Typography>
            <Box display="flex" alignItems="center">
              <Button
                variant="outlined"
                onClick={() => openFileDialog()}
                sx={{
                  "& input": { display: "none" },
                  width: "150px",
                  height: "35px",
                  mt: 1,
                  mr: 2,
                }}
              >
                <input type="file" ref={fileInputRef} onChange={onFilesAdded} />
                <Typography variant="body2">
                  {t("admin.params.browse_file")}
                </Typography>
              </Button>
              {param.LOGO ? (
                <img src={param.LOGO} alt="logo etp" width="40" />
              ) : (
                ""
              )}
            </Box>
            <TextField
              sx={{ mt: 2 }}
              value={param.MAIL_SUPPORT}
              onChange={(event: any) =>
                handleChanges({ ...param, MAIL_SUPPORT: event.target.value })
              }
              label={t("admin.params.general.support")}
              variant="outlined"
            />

            <FormControlLabel
              value={param.QUALIFICATION}
              onChange={(e: any) =>
                handleChanges({ ...param, QUALIFICATION: e.target.checked })
              }
              control={<Checkbox checked={param.QUALIFICATION} />}
              label={t("admin.params.general.qualif")}
            />
          </FormGroup>
          <Typography sx={{ mt: 1 }} textAlign="right">
            {t("admin.params.reco_text")}
          </Typography>
        </>
      )}
    </div>
  );
};

export default AdminGeneralInfos;
