import React, { useState } from "react";
import AdminSkillsListParams from "./works/AdminSkillsListParams";
import AdminWorksListParams from "./works/AdminWorksListParams";
import AdminWorksCategoryListParams from "./works/AdminWorksCategoryListParams";
import CustomTabs from "../../CustomTabs";

const AdminWorksParams = () => {
  const [value, setValue] = useState(0);
  const handleChange = (e: any) => {
    setValue(e);
  };
  const tabs = ["works", "skills", "works_categories"];

  const displayTabs = () => {
    switch (value) {
      case 0:
        return <AdminWorksListParams />;
      case 1:
        return <AdminSkillsListParams />;
      case 2:
        return <AdminWorksCategoryListParams />;
      default:
        return <AdminWorksListParams />;
    }
  };

  return (
    <div>
      <CustomTabs
        value={value}
        onChange={(e: number, force: boolean) => {
          handleChange(e);
        }}
        tabs={tabs.map((tab: string) => `admin.tabs.${tab}`)}
        // tabChange={tabChange}
      />

      {displayTabs()}
    </div>
  );
};

export default AdminWorksParams;
