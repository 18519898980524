import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  Popper,
  TextField,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import locationAPI from "../../_api/_locationAPI";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import CustomPopper from "./CustomPopper";

const LocationAutocomplete: React.FC<{
  sendDataToParent: any;
  sendRangeToParent?: any;
  displayRange?: boolean;
  baseValue?: string;
  onBlur?: any;
  formError?: boolean;
  label?: string;
  placeholder?: boolean;
  clearOnSelect?: boolean;
  formHelper?: string;
  variant?: "filled" | "standard" | "outlined" | undefined;
  displayIcon?: boolean;
  multi?: boolean;
  disabled?: boolean;
  clear?: boolean;
}> = ({
  sendDataToParent,
  sendRangeToParent,
  displayRange,
  variant = "standard",
  baseValue,
  formError,
  formHelper,
  onBlur,
  label,
  placeholder,
  clearOnSelect,
  displayIcon,
  multi,
  disabled,
  clear,
}) => {
  const [helperText, setHelperText] = useState("");
  const [needle, setNeedle] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [term, setTerm] = useState("");
  const [open, setOpen] = useState(true);
  const [rangeLocation, setRangeLocation] = useState(10);
  const [value, setValue] = useState<any>(baseValue ?? { label: "" });

  const { t } = useTranslation("common");

  const { data, loading, error } = useFetch(
    locationAPI.get({ needle: needle, onlyfrance: true, companyonly: false }),
    true
  );

  const getLocations = (text: string) => {
    text.length < 1 && setOptions([]);
    text.length > 1 && setNeedle(text);
    text.length > 1 && setOpen(true);
  };

  const setErrorText = (text: string, data: any) => {
    text?.length > 1 && data?.length === 0
      ? setHelperText(t("autocomplete.no_result"))
      : text?.length > 0 && text?.length < 3
      ? setHelperText(t("autocomplete.min_charac_2"))
      : setHelperText("");
  };

  const onSelectLocation = (val: any, index?: number) => {
    const selectedLocation: any = options.find(
      (el: any) => el?.place_name === val?.place_name
    );
    setHelperText("");
    if (selectedLocation) {
      selectedLocation.label = selectedLocation?.place_name;
      if (multi) {
        const newArr: any = [...selectedLocations];
        if (selectedLocations.filter((item: any) => item === val).length <= 0) {
          newArr.push(selectedLocation);
          setSelectedLocations(newArr);
          sendDataToParent(newArr);
          setValue({ label: "" });
          clearOptions();
        }
      } else {
        !displayRange && setOpen(false);
        setValue(clearOnSelect ? "" : selectedLocation);
        sendDataToParent(selectedLocation);
      }
    } else {
      setValue({ label: "" });
      clearOptions();
      sendDataToParent();
    }
  };

  const handleRemove = (index: number) => {
    const cp = [...selectedLocations];
    cp.splice(index, 1);
    setSelectedLocations(cp);
    sendDataToParent(cp);
  };

  const handleRangeLocation = (event: any, value: any) => {
    event.preventDefault();
    setRangeLocation(value);
    sendRangeToParent(value * 2);
  };

  const clearOptions = () => {
    setNeedle("");
    setOptions([]);
  };

  const PopperMy = (props: any) => {
    return (
      <Popper
        {...props}
        open={open}
        onMouseDown={(e) => e.preventDefault()}
        style={{
          width: 325,
        }}
        placement="bottom-start"
      >
        <CustomPopper
          options={options}
          selectedLocations={selectedLocations}
          multi={multi}
          displayRange={displayRange}
          rangeLocation={rangeLocation}
          handleRemove={handleRemove}
          handleRangeLocation={handleRangeLocation}
          onSelectLocation={onSelectLocation}
          value={value}
        />
      </Popper>
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getLocations(term);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  useEffect(() => {
    data && setOptions(data["hydra:member"]);
    data &&
      data["hydra:member"] &&
      !loading &&
      setErrorText(term, data["hydra:member"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, term]);

  useEffect(() => {
    if (error) console.log(error);
  }, [error]);

  useEffect(() => {
    if (clear) setValue({ label: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  return (
    <div>
      <Autocomplete
        {...{ loading, options }}
        value={(multi ? selectedLocations : value) || ""}
        multiple={multi}
        id="location-autocomplete"
        freeSolo
        filterOptions={(options) => options}
        disableClearable={needle?.length === 0}
        getOptionLabel={(option: any) => option?.place_name || ""}
        loadingText={t("autocomplete.loading")}
        loading={loading}
        forcePopupIcon
        onChange={onSelectLocation}
        PopperComponent={PopperMy}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              label && !displayIcon
                ? label
                : displayIcon || placeholder
                ? ""
                : t("autocomplete.placeholder.location_work")
            }
            placeholder={
              label && displayIcon
                ? label
                : displayIcon || placeholder
                ? t("autocomplete.placeholder.location_work")
                : ""
            }
            onChange={(event) => {
              !event.target.value && clearOptions();
              setTerm(event.target.value);
            }}
            onBlur={() => (onBlur ? onBlur(value) : null)}
            error={formError}
            helperText={helperText || formHelper}
            variant={variant}
            InputProps={{
              ...params.InputProps,
              startAdornment: displayIcon && (
                <InputAdornment position="start">
                  <RoomOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default LocationAutocomplete;
