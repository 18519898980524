import React, { useState } from "react";
import http from "../services/http/http";
import { toastError } from "../_utils/toastError";
import { useTranslation } from "react-i18next";

const useRessources = (
  sortBy?: string | null,
  sortType?: string | null,
  search?: string | null
) => {
  const [loading, setLoading] = useState(true);
  const [response, setresponse] = useState([]);
  const [resources, setResources] = useState([]);
  const { t } = useTranslation("common");

  const [videos, setVideos] = useState([]);
  const [files, setFiles] = useState([]);

  const checkName = (name: string, term: string) => {
    var pattern = term
      .toLowerCase()
      .split("")
      .map((x) => {
        return `(?=.*${x})`;
      })
      .join("");

    var regex = new RegExp(`${pattern}`, "g");

    return name.toLowerCase().match(regex);
  };

  const handleSort = (sort: any, data: any) => {
    let xData = data;
    switch (sort) {
      case "date:asc":
        xData.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        break;
      case "date:desc":
        xData.sort(
          (a: any, b: any) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
        break;
      case "name:asc":
        xData.sort((a: any, b: any) => (a.title < b.title ? -1 : 1));
        break;
      case "name:desc":
        xData.sort((a: any, b: any) => (a.title > b.title ? -1 : 1));
        break;
      default:
        break;
    }
    return xData;
  };

  React.useEffect(() => {
    let data = [...response];

    sortBy && (data = handleSort(sortBy, data));
    sortType && (data = data.filter((el: any) => el.type === sortType));
    search && (data = data.filter((el: any) => checkName(el.title, search)));
    setResources(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortType, search, response]);

  React.useEffect(() => {
    setVideos(resources.filter((el: any) => el.extension === "video"));
    setFiles(resources.filter((el: any) => el.extension !== "video"));
  }, [resources]);

  React.useEffect(() => {
    setLoading(true);
    const sub = http.get("resources").subscribe({
      next: (res) => {
        setresponse(res?.data?.["hydra:member"] ?? []);
        setLoading(false);
      },
      error: () => {
        toastError(t("errors.error_append"));
        setLoading(false);
      },
    });

    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, videos, files };
};

export default useRessources;
