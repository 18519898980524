import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BottomFilterContent from "../common/chip_filter/BottomFilterContent";
import FilterPopoverContainer from "../common/chip_filter/FilterPopoverContainer";
import WorkTrainingFiltersPage from "./WorkTrainingFiltersPage";

const WorkTrainingFiltersPopover = ({
  handleFilters,
  httpParams,
  total,
}: any) => {
  const baseParams = {
    page: 1,
    location: httpParams.location,
    range: httpParams.range ?? 500000,
    type: httpParams.type,
    order: httpParams.order,
    reload: 0,
    certif: false,
    indemn: false,
    price: [0, 20000],
    duration: [0, 3000],
    levels: [],
    dateStart: "",
    dateEnd: "",
  };
  const { t } = useTranslation("common");
  const [close, setClose] = useState<any>(false);
  const [value, setValue] = useState<any>(httpParams);

  const returnTitleChip = () => {
    let count = 0;
    Object.values(httpParams).map(
      (item: any, i: number) =>
        JSON.stringify(item) !== JSON.stringify(Object.values(baseParams)[i]) &&
        ++count
    );
    return `${t("filters.label")} ${count}`;
  };

  const returnActiveChip = () => {
    let count = 0;
    Object.values(httpParams).map(
      (item: any, i: number) =>
        JSON.stringify(item) !== JSON.stringify(Object.values(baseParams)[i]) &&
        ++count
    );
    return count > 0;
  };

  const handleSubmit = () => {
    handleFilters({ ...value, page: 1 });
    setClose(!close);
  };

  const handleClear = () => {
    setValue(baseParams);
    handleFilters(baseParams);
    setClose(!close);
  };

  useEffect(() => {
    setValue(httpParams);
  }, [httpParams]);

  return (
    <>
      <FilterPopoverContainer
        title={returnTitleChip()}
        idPop="filters_popover"
        close={close}
        active={returnActiveChip()}
        content={
          <>
            <Box sx={{ p: 2 }}>
              <WorkTrainingFiltersPage
                httpParams={value}
                setHttpParams={setValue}
                totalItems={total}
                workPage
              />
            </Box>
            <BottomFilterContent
              handleSubmit={handleSubmit}
              handleClear={handleClear}
            />
          </>
        }
      />
    </>
  );
};

export default WorkTrainingFiltersPopover;
