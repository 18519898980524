import { Box, styled, Typography } from "@mui/material";
import React from "react";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import { useTranslation } from "react-i18next";

const Text = styled(Typography)(({ theme }: any) => ({
  maxWidth: "650px",
  color: theme.palette.secondary.light,
  textAlign: "center",
}));

const IncGroupHeader: React.FC<any> = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
        <Box display="flex" alignItems="center">
          <GroupWorkOutlinedIcon
            sx={{ color: "#1A52C1", fontSize: "60px", mr: 2 }}
          />
          <Typography
            variant="h3"
            sx={{ lineHeight: "30px", color: "#1A52C1" }}
            dangerouslySetInnerHTML={{
              __html: t("inc.group.title"),
            }}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        sx={{ mb: 3 }}
      >
        <Text>{t("inc.result.dyn_group")}</Text>

        <Text sx={{ mt: 1 }}>{t("inc.result.dyn_group_legend")}</Text>
      </Box>
    </>
  );
};

export default IncGroupHeader;
