import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Chip, InputAdornment } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import chatService from "../../services/chat.service";
import { toast } from "react-toastify";
import StringAvatar from "../common/StringAvatar";

const MainAutocomplete = styled(Autocomplete)({
  flexGrow: 1,
  listStyle: "none",
  minWidth: "300px",
  backgroundColor: "#fff",
  borderRadius: "8px",
});

const InputBadge = styled(Chip)({
  marginRight: 1,
  backgroundColor: "#dbe1ff",
  borderRadius: "5px",
  color: "#4c6fff",
  fontWeight: "bold",
});

const InnerBadgeIcon = styled(Close)({
  color: "#4c6fff !important",
  fontSize: "16px !important",
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const CreateConversationAutocomplete: React.FC<{ sendDataToParent: any }> = ({
  sendDataToParent,
}) => {
  const context = useSelector((state: any) => state.auth.context);
  const { t }: any = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(true);
  const [chatablePersonsList, setChatablePersonsList] = useState<any>(null);
  const [autocompleteSelection, setAutocompleteSelection] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    chatService
      .getConversationCandidatsList(context, {
        fct: () => toast.error(t("chat.error_loading_list")),
      })
      .then((res: any) => {
        setChatablePersonsList(res);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useEffect(() => {
    const persons = autocompleteSelection.map((fullName: any) => {
      return chatablePersonsList.find(
        (e: any) =>
          e?.candidate?.firstName + " " + e?.candidate?.lastName === fullName
      );
    });
    sendDataToParent(persons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteSelection]);

  const LISTBOX_PADDING = 8;

  function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    };
    const person = chatablePersonsList?.find(
      (e: any) =>
        e?.candidate?.firstName + " " + e?.candidate?.lastName ===
        dataSet[0]?.key
    );
    const image = person?.candidate?.image?.contentUrl;
    const fullName = dataSet[1];
    const email = person?.candidate?.email;

    if (dataSet.hasOwnProperty("group")) {
      return (
        <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
          {dataSet?.group}
        </ListSubheader>
      );
    }

    return (
      <Typography
        component="li"
        sx={{ padding: 1 }}
        {...dataSet[0]}
        noWrap
        style={inlineStyle}
      >
        <Box sx={{ display: "flex" }}>
          <StringAvatar size={25} name={fullName} url={image} />
          <Box sx={{ marginLeft: 1 }}>
            <Typography component="p">{fullName}</Typography>
            <Typography component="p" sx={{ color: "lightgrey" }}>
              {email}
            </Typography>
          </Box>
        </Box>
      </Typography>
    );
  }

  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach(
      (item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      }
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 48 : 36;

    const getChildSize = (child: React.ReactChild) => {
      if (child.hasOwnProperty("group")) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  return (
    <MainAutocomplete
      loading
      loadingText={loading ? t("autocomplete.loading") : t("chat.void")}
      id="ACmutipleCustom"
      disableListWrap
      multiple
      getOptionDisabled={(options: any) =>
        autocompleteSelection.length >= 100 ? true : false
      }
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={
        chatablePersonsList
          ?.sort((a: any, b: any) =>
            b?.candidate?.firstName
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") >
            a?.candidate?.firstName
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              ? -1
              : 1
          )
          .map(
            (e: any) => e?.candidate?.firstName + " " + e?.candidate?.lastName
          ) ?? []
      }
      groupBy={(option: any) => option[0].toUpperCase()}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            label={t("chat.lastName_or_firsName")}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    {t("chat.to")}
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
      renderTags={(tagValue: any, getTagProps: any) =>
        tagValue.map((option: any, index: any) => (
          <InputBadge
            label={option}
            {...getTagProps({ index })}
            deleteIcon={<InnerBadgeIcon />}
            onDelete={() => {
              setAutocompleteSelection(
                tagValue.filter((entry: any) => entry !== option)
              );
            }}
          />
        ))
      }
      renderOption={(props: any, option: any) => {
        return [props, option];
      }}
      renderGroup={(params: any) => params}
      onChange={(event: any, value: any) => {
        setAutocompleteSelection(value);
      }}
      value={autocompleteSelection}
    />
  );
};

export default CreateConversationAutocomplete;
