import {
  SET_BENEFS,
  SET_CONVERSATIONS,
  SET_MESSAGE,
  SET_NOTIFICATIONS,
  SET_MERCURE_SOURCES,
} from "../actions/chatActions";
import initialState from "../initialState";

const chatReducer = (state = { ...initialState.chat }, action: any) => {
  switch (action.type) {
    case SET_CONVERSATIONS:
      return {
        ...state,
        conversations: action.conversations,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
      };
    case SET_MERCURE_SOURCES:
      return {
        ...state,
        mercureSources: action.mercureSources,
      };
    case SET_BENEFS:
      return {
        ...state,
        benefs: action.benefs,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      };

    default:
      return { ...state };
  }
};

export default chatReducer;
