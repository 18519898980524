import { styled, Typography } from "@mui/material";
import React from "react";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
  width: "254px",
  background: theme.palette.primary.main,
  "@media screen and (max-width: 600px)": {
    display: "none",
  },
}));

const StepDivider = styled("div")`
  border-left: 1px solid white;
  height: 30px;
  margin: 16px 0px;
`;

const Checked = styled((props: any) => (
  <div {...props}>
    <CheckRoundedIcon className="check-icon" />
  </div>
))({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "30px",
  margin: "16px 9px",
  "> .check-icon": {
    color: "white",
  },
});

const StepItem = styled((props: any) => (
  <div {...props}>
    <div className="step-item-index-container">
      <div className="step-item-index">
        <Typography component="span">{props.index}</Typography>
      </div>
      {props.last === "false" &&
        (props.done === "false" ? <StepDivider /> : <Checked />)}
    </div>
    <div className="step-item-label">
      <Typography>{props.label}</Typography>
    </div>
  </div>
))(({ theme, ...props }) => ({
  display: "flex",
  width: "160px",
  "> .step-item-label": {
    marginLeft: "10px",
    marginTop: "8px",
    color: props.active === "true" ? "white" : theme.palette.primary["200"],
    "> .MuiTypography-root": { cursor: "pointer" },
  },
  "> .step-item-index-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "* > .step-item-index": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "55px",
    height: "55px",
    background:
      props.active === "true"
        ? "white"
        : props.done === "true"
        ? "#1ec626"
        : "none",
    border: "1px solid",
    borderColor:
      props.active === "true"
        ? "white"
        : props.done === "true"
        ? "#1ec626"
        : theme.palette.primary["200"],
    borderRadius: "100px",
    color: props.active === "true" ? theme.palette.primary.main : "white",
    cursor: "pointer",
  },
}));

const Stepper: React.FC<{
  steps: string[];
  step: number;
  setStep: (value: number) => void;
  maxStep: number;
}> = ({ steps, step, setStep, maxStep }) => {
  const handleClick = (index: number) => {
    setStep(index);
  };

  return (
    <Root>
      <div>
        {steps.map((label, index) => (
          <StepItem
            onClick={() => handleClick(index)}
            key={label}
            index={index + 1}
            label={label}
            last={(index + 1 === steps.length).toString()}
            active={(step === index).toString()}
            done={(maxStep > index).toString()}
          />
        ))}
      </div>
    </Root>
  );
};

export default Stepper;
