import {
  Box,
  Container,
  Paper,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import React from "react";

const ChipSkeleton = styled(Skeleton)(() => ({
  borderRadius: "20px",
  margin: 4,
}));

const ProfileArchetypesSkeleton = () => {
  return (
    <Paper elevation={3} sx={{ borderRadius: "23px" }}>
      <Container>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{ mt: 2 }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            sx={{ mb: 2 }}
          >
            <Skeleton variant="circular" width={55} height={55} />
            <Box>
              <Typography variant="subtitle1" fontWeight="normal">
                <Skeleton width="100px" />
              </Typography>
              <Typography variant="subtitle1" color="primary">
                <Skeleton width="70px" />
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-around">
            <ChipSkeleton variant="rectangular" width="120px" height="40px" />
            <ChipSkeleton variant="rectangular" width="120px" height="40px" />
          </Box>
          <Typography
            variant="body2"
            textAlign="center"
            sx={{
              maxWidth: "30ch",
              mt: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Skeleton width="230px" />
            <Skeleton width="220px" />
            <Skeleton width="200px" />
            <Skeleton width="230px" />
            <Skeleton width="230px" />
            <Skeleton width="210px" />
            <Skeleton width="190px" />
          </Typography>
          <ChipSkeleton
            variant="rectangular"
            sx={{ mt: 3, mb: 1 }}
            width="170px"
            height="40px"
          />
          <Box sx={{ mb: 1 }}>
            {Array.from(Array(4).keys()).map((item: any) => (
              <Box display="flex" alignItems="center" sx={{ m: 1 }} key={item}>
                <Skeleton variant="circular" width="50px" height="50px" />
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ ml: 1, maxWidth: "7ch" }}
                >
                  <Skeleton width="50px" />
                  <Skeleton width="50px" />
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Paper>
  );
};

export default ProfileArchetypesSkeleton;
