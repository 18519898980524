import { Tooltip, Typography } from "@mui/material";
import React, { useLayoutEffect, useRef, useState } from "react";

const ResponsiveTitle: React.FC<{
  content: any;
  variant?:
    | "h6"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit"
    | undefined;
  className?: string;
}> = ({ content, variant = "h6", className }) => {
  const titleRef = useRef<any>(null);
  const [tooltip, setTooltip] = useState<any>("");

  useLayoutEffect(() => {
    if (titleRef?.current) {
      const tl = titleRef.current;
      if (tl.clientWidth < tl.scrollWidth) setTooltip(tl.innerText);
    }
  }, [titleRef]);

  return (
    <Tooltip title={tooltip}>
      <Typography className={className} ref={titleRef} noWrap variant={variant}>
        {content}
      </Typography>
    </Tooltip>
  );
};

export default ResponsiveTitle;
