import React, { useState } from "react";
import axios from "axios";
import { useSelector, shallowEqual } from "react-redux";
import { Button, Box, CircularProgress, styled } from "@mui/material";

import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";

const BoxIcon: any = styled(Box, {
  shouldForwardProp: (props) => props !== "loading",
})(({ theme, loading }: any) => ({
  width: "40px",
  overflow: "hidden",
  height: "40px",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary[500],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: loading ? "wait" : "auto",
  pointerEvents: loading ? "none" : "auto",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "all .25s ease-in-out",
  },
}));

const ButtonIcon = styled(Button)({
  width: "40px",
  height: "40px",
  borderRadius: "50%",
});

const BookmarkComponent: React.FC<{
  id: string;
  isBookmarked: boolean;
  reload?: any;
}> = ({ id, isBookmarked, reload }) => {
  const [loading, setLoading] = useState(false);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);

  const handleBookmark = () => {
    setLoading(true);
    axios
      .post(
        `people/${tokenData.person_id}/${
          isBookmarked ? "remove" : "add"
        }-bookmarks`,
        {
          items: [id],
        }
      )
      .then((res) => {
        reload(!isBookmarked);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <div>
      <BoxIcon loading={loading} sx={{ ml: "auto" }}>
        <ButtonIcon onClick={() => !loading && handleBookmark()}>
          {isBookmarked ? (
            <FavoriteRoundedIcon sx={{ color: "white !important" }} />
          ) : (
            <FavoriteBorderRoundedIcon sx={{ color: "white !important" }} />
          )}
        </ButtonIcon>
        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ position: "absolute" }}
          >
            <CircularProgress sx={{ color: "white !important" }} size={25} />
          </Box>
        )}
      </BoxIcon>
    </div>
  );
};

export default BookmarkComponent;
