import {
  Box,
  IconButton,
  ListItemText,
  MenuItem,
  Popover,
} from "@mui/material";
import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------
interface LangItem {
  value: string;
  label: string;
  icon: string;
  slug: string;
}

const LANGS: LangItem[] | any = [
  {
    value: "en",
    label: "English",
    icon: "/static/icons/ic_flag_en.svg",
    slug: "EN",
  },
  {
    value: "fr",
    label: "Français",
    icon: "/static/icons/ic_flag_fr.svg",
    slug: "FR",
  },
];
// ----------------------------------------------------------------------

const LanguagePopover: React.FC = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [lang, setLang] = useState(currentLanguage);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectLang = (lang: string, reload?: boolean) => {
    if (lang === currentLanguage) {
      handleClose();
      return;
    }
    localStorage.setItem("lng", lang);
    axios.defaults.headers["content-language"] = lang;
    if (reload) window.location.reload();
    i18n.changeLanguage(lang);
    handleClose();
  };

  useEffect(() => {
    if (i18n.language) setLang(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <Helmet htmlAttributes={{ lang }} />
      <IconButton
        aria-label="lang"
        ref={anchorRef}
        onClick={handleOpen}
        sx={{ width: "25px", height: "25px", fontSize: "14px" }}
      >
        {LANGS.find((el: LangItem) => el.value === currentLanguage)?.slug}
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ py: 1 }}>
          {LANGS.map((option: LangItem) => (
            <MenuItem
              key={option.value}
              selected={
                option.value ===
                LANGS.find((el: LangItem) => el.value === currentLanguage)
                  ?.value
              }
              onClick={() => selectLang(option.value, false)}
            >
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default LanguagePopover;
