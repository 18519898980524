import { Grid, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const VisioList: React.FC<{
  control: any;
}> = ({ control }) => {
  const { t } = useTranslation("common");
  const visios = ["zoom", "microsoft-teams", "Linkedin"];

  const getPropName = (label: string) => {
    switch (label) {
      case "zoom":
        return "zoom";
      case "microsoft-teams":
        return "teams";
      case "Linkedin":
        return "linkedin";
      default:
        return "";
    }
  };

  return (
    <>
      {visios &&
        visios.map((name: string, key: number) => (
          <Grid item key={key} xs={12} md={4}>
            <Controller
              name={getPropName(name)}
              control={control}
              render={({ field, formState: { errors } }) => (
                <TextField
                  sx={{
                    width: { xs: "100%", sm: "100%" },
                    p: 1,
                    mt: 2,
                  }}
                  {...field}
                  label={t(`profilepage.visios.${getPropName(name)}`)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt={`${name} icon`}
                          src={`/static/logo/${name}.svg`}
                          width="25px"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        ))}
    </>
  );
};

export default VisioList;
