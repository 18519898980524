import { Box, styled, Typography } from "@mui/material";
import React from "react";

const SkillSuggestChip: React.FC<{
  skill: any;
  onClick: any;
  onboarding?: boolean;
}> = ({ skill, onClick, onboarding }) => {
  const SkillLabelCtn = styled(Box)(({ theme }: any) => ({
    color: "rgba(82, 82, 82, 1)",
    backgroundColor: skill?.skillCategoryData?.colors?.background,
    borderRadius: "0px 9px 9px 9px",
    padding: theme.spacing(1),
    cursor: "pointer",
    position: "relative",
    margin: theme.spacing(1),
    transition: "all 0.25s ease-in-out",
    "&:hover": {
      transform: "scale(1.025)",
    },
  }));

  const LabelContainer = styled("div")`
    font-size: 12px;
    color: ${skill?.skillCategoryData?.colors?.button};
  `;

  return (
    <SkillLabelCtn onClick={onClick}>
      <LabelContainer>
        {skill?.skillCategoryData?.labels?.toReversed?.()?.join?.(" > ")}
      </LabelContainer>
      <Typography
        sx={{ color: skill?.skillCategoryData?.colors?.font }}
        textAlign="center"
        variant="body1"
        fontWeight="bold"
      >
        {skill?.label}
      </Typography>
    </SkillLabelCtn>
  );
};

export default SkillSuggestChip;
