import React from "react";

const DataIcon: React.FC<{
  active?: boolean;
  white?: boolean;
  size?: number;
}> = ({ active, white, size }) => {
  return (
    <svg
      id="lock_black_24dp_2_"
      data-name="lock_black_24dp(2)"
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? "20"}
      height={size ?? "20"}
      viewBox="0 0 17 17"
    >
      <g id="Groupe_3184" data-name="Groupe 3184">
        <path
          id="Tracé_12895"
          data-name="Tracé 12895"
          d="M0,0H17V17H0Z"
          fill="none"
        />
        <path
          id="Tracé_12896"
          data-name="Tracé 12896"
          d="M0,0H17V17H0Z"
          fill="none"
          opacity="0.87"
        />
      </g>
      <path
        id="Tracé_12897"
        data-name="Tracé 12897"
        d="M13.917,5.958h-.708V4.542a3.542,3.542,0,0,0-7.083,0V5.958H5.417A1.421,1.421,0,0,0,4,7.375v7.083a1.421,1.421,0,0,0,1.417,1.417h8.5a1.421,1.421,0,0,0,1.417-1.417V7.375A1.421,1.421,0,0,0,13.917,5.958ZM7.542,4.542a2.125,2.125,0,0,1,4.25,0V5.958H7.542Zm6.375,9.917h-8.5V7.375h8.5Zm-4.25-2.125A1.417,1.417,0,1,0,8.25,10.917,1.421,1.421,0,0,0,9.667,12.333Z"
        transform="translate(-1.167 -0.292)"
        fill={active ? "#4C6FFF" : white ? "#fff" : "#adb6c7"}
      />
    </svg>
  );
};

export default DataIcon;
