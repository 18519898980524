import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Modal,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { modalStyle } from "../../_utils/modalStyle";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ProfilePictureSignUpModal: React.FC<any> = ({
  close,
  open,
  file,
  upImg,
  setUrl,
  handleBlob,
}) => {
  const { t } = useTranslation("common");

  const [crop, setCrop] = useState<any>({
    unit: "%",
    width: 30,
    aspect: 1 / 1,
  });
  const previewCanvasRef = useRef<any>(null);
  const [completedCrop, setCompletedCrop] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const imgRef = useRef<any>(null);

  const generateDownload = (canvas: any, crop: any) => {
    if (!crop || !canvas) {
      return;
    }
    setLoading(true);
    canvas.toBlob(
      async (blob: any) => {
        const formData = new FormData();
        formData.append("file", blob);
        try {
          setUrl(URL.createObjectURL(blob));
          handleBlob(blob);
          setLoading(false);
          close();
        } catch (err) {
          toast.error(t("errors.error_append"));
          setLoading(false);
          close();
        }
      },
      "image/png",
      1
    );
  };

  const onLoad = useCallback((img: any) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  return (
    <Modal open={open} onClose={close}>
      <Card
        sx={{
          ...modalStyle,
        }}
      >
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ width: "100%", display: "flex" }}>
            <IconButton
              onClick={close}
              sx={{ ml: "auto", mt: 1, mr: -1 }}
              aria-label="close"
            >
              <HighlightOff />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center">
            {file && (
              <>
                <ReactCrop
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c: any) => setCrop(c)}
                  onComplete={(c: any) => setCompletedCrop(c)}
                />
                <canvas
                  hidden
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0),
                  }}
                />
              </>
            )}
          </Box>
        </CardContent>
        <CardActions sx={{ p: 2, display: "flex", justifyContent: "center" }}>
          <Button
            disabled={loading}
            onClick={() =>
              generateDownload(previewCanvasRef.current, completedCrop)
            }
            variant="contained"
          >
            {t("btn.save")}
          </Button>
          <Button onClick={close}>{t("btn.cancel")}</Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ProfilePictureSignUpModal;
