import {
  Button,
  Box,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../components/Page";

const RefuseInvitationPage = () => {
  const [token, setToken] = useState<any>("");
  const [isRefused, setIsRefused] = useState<any>(false);
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  const handleRefuse = async () => {
    try {
      await axios.patch(`tokens/${token}/refuse`, {});
      setIsRefused(true);
    } catch (err) {}
  };

  const handleAccept = () => {
    navigate(`/sign-up?token=${token}`);
  };

  useEffect(() => {
    query.get("token") && setToken(query.get("token"));
  }, [query]);

  return (
    <Page
      title="Invitation"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Container>
        <Paper
          elevation={3}
          sx={{
            borderRadius: "23px",
            p: 4,
          }}
        >
          {isRefused ? (
            <Stack spacing={4}>
              <Typography variant="h5" textAlign="center">
                {t("recruiter.candidate.invitation.refuse.refused_done")}
              </Typography>
              <Typography variant="body1" textAlign="center">
                {t("recruiter.candidate.invitation.refuse.see_you_soon")}
              </Typography>
            </Stack>
          ) : (
            <Stack spacing={6}>
              <Typography variant="subtitle1" textAlign="center">
                {t("recruiter.candidate.invitation.refuse.refuse_text1")}
              </Typography>
              <Typography variant="subtitle1" textAlign="center">
                {t("recruiter.candidate.invitation.refuse.refuse_text2")}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                flexWrap="wrap"
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleRefuse}
                  sx={{ width: "200px", height: "40px", m: 1 }}
                >
                  <Typography variant="body1">
                    {t("recruiter.candidate.invitation.refuse.yes")}
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAccept}
                  sx={{ width: "200px", height: "40px", m: 1 }}
                >
                  <Typography variant="body1">
                    {t("recruiter.candidate.invitation.refuse.tests_access")}
                  </Typography>
                </Button>
              </Box>
            </Stack>
          )}
        </Paper>
      </Container>
    </Page>
  );
};

export default RefuseInvitationPage;
