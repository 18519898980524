import { Create, DeleteForever } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import projectsService from "../../services/projects.service";
import RatingBubblesOnly from "../skills/RatingBubblesOnly";
import StyledButton from "../styled/StyledButton";
import ProjectTrainingModal from "./project_modal/ProjectTrainingModal";
import { useTranslation } from "react-i18next";

const GreyTitle = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.grey[600],
}));

const Root = styled(Paper)(({ theme }: any) => ({
  border: `1px solid ${theme.palette.grey[500_48]}`,
  padding: theme.spacing(2),
  display: "flex",
}));

const ProjectTrainingItem: React.FC<any> = ({ project }) => {
  const { t } = useTranslation("common");

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const deleteItem = () => {
    projectsService.deleteTraining(project.id).subscribe({
      next: () => {
        setLoading(false);
      },
      error: () => {
        setLoading(false);
      },
    });
  };

  return (
    <Root>
      <Box>
        <GreyTitle variant="h6">{project.year}</GreyTitle>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {project.description}
        </Typography>
        <Typography dangerouslySetInnerHTML={{ __html: project.motivation }} />
        <Typography>{t("projects.priority")} :</Typography>
        <RatingBubblesOnly level={project.priority} />
      </Box>

      {!id && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            ml: "auto",
          }}
        >
          <StyledButton
            sx={{ mb: 1 }}
            onClick={() => setOpen(true)}
            width={35}
            height={35}
          >
            {!loading ? (
              <Create color="primary" sx={{ width: 20, height: 20 }} />
            ) : (
              <CircularProgress size={20} sx={{ position: "absolute" }} />
            )}
          </StyledButton>
          <StyledButton
            disabled={loading}
            onClick={deleteItem}
            width={35}
            height={35}
          >
            {!loading ? (
              <DeleteForever color="error" sx={{ width: 20, height: 20 }} />
            ) : (
              <CircularProgress size={20} sx={{ position: "absolute" }} />
            )}
          </StyledButton>
        </Box>
      )}

      {open && (
        <ProjectTrainingModal
          project={project}
          open={open}
          close={() => setOpen(false)}
        />
      )}
    </Root>
  );
};

export default ProjectTrainingItem;
