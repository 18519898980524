import { styled, Tooltip, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getShortDate } from "../../_utils/getShortDate";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/getWindowDimensions";

const Subtitle: any = styled(Typography, {
  shouldForwardProp: (props) => props !== "smallLocation",
})(({ theme, smallLocation }: any) => ({
  color: theme.palette.grey[500],
  display: smallLocation ? "none" : "auto",
  [theme.breakpoints.down("sm")]: {
    display: smallLocation ? "inherit" : "auto",
  },
}));

const SpanLocation = styled("span")(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const ExperienceCardHeader: React.FC<{
  experience: any;
}> = ({ experience }) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const titleRef = useRef(null);
  const [needTooltip, setNeedTooltip] = useState(false);

  const { width } = useWindowDimensions();

  const expTitle = `${
    experience?.type === "training"
      ? experience?.diplomaTitle
      : experience?.label
  } ${experience?.company ? `| ${experience?.company}` : ""}`;

  React.useLayoutEffect(() => {
    if (!titleRef?.current) return;
    const titleElement: any = titleRef.current;
    const rect = titleElement.getBoundingClientRect();
    setNeedTooltip(rect.width < titleElement.scrollWidth);
  }, [titleRef, width]);

  return (
    <>
      <Tooltip title={needTooltip ? expTitle : ""}>
        <Typography
          ref={titleRef}
          sx={{
            overflow: "hidden",
            textOverflow: "elliplis",
            position: "relative",
            width: "100%",
          }}
          noWrap
          variant="subtitle1"
        >
          {expTitle}
        </Typography>
      </Tooltip>

      {!experience.work &&
        (context === "collab" ||
          context === "benef" ||
          context === "candidate") && (
          <Typography variant="body1" color="error">
            {t("experience_section.no_work")}
          </Typography>
        )}
      <Subtitle variant="body2">
        {`${getShortDate(new Date(experience.dateStart))} - ${
          experience.dateEnd
            ? getShortDate(new Date(experience.dateEnd))
            : t("today")
        }
        `}
        {experience.location && experience.location?.label && (
          <SpanLocation>{`- ${
            experience.location && experience.location?.label
          }`}</SpanLocation>
        )}
      </Subtitle>
      <Subtitle smallLocation variant="body2">
        {experience.location && experience.location?.label && (
          <span>{`${experience.location && experience.location?.label}`}</span>
        )}
      </Subtitle>
    </>
  );
};

export default ExperienceCardHeader;
