import { styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";

const Text = styled(Typography)(({ theme }: any) => ({
  maxWidth: "650px",
  color: theme.palette.secondary.light,
  textAlign: "center",
}));

const IncMotivationHeader = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ mt: 8.5 }}>
        <Box display="flex" alignItems="center">
          <img
            style={{ width: "65px", marginRight: "10px" }}
            alt="trophy"
            src="/static/icons/inc/trophy_2.svg"
          />
          <Typography
            variant="h3"
            sx={{
              lineHeight: "30px",
              color: "#F8AF2E",
              textTransform: "uppercase",
            }}
          >
            MATCHING <br />
            {t("inc.matching.sk")}
          </Typography>
        </Box>
      </Box>
      <Text sx={{ mb: 2, mt: 2 }}>{t("inc.result.softskills")}</Text>
    </>
  );
};

export default IncMotivationHeader;
