import { Box, styled } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import LogoLoader from "../components/LogoLoader";
import useFetch from "../hooks/useFetch";
import TestsAPI from "../_api/_testsAPI";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Iframe = styled("iframe")({
  width: "100%",
  height: "100%",
  border: "none",
});

// TODO : Handle onboarding stuff

const IncGamePage: React.FC<any> = ({ isOnboarding, setDone }) => {
  const token = useSelector((state: any) => state.user.token_game);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  const [results, setResults] = useState<any>(null);

  const { data, loading } = useFetch(TestsAPI.getById("inc/result"));

  useEffect(() => {
    data?.results?.results && setResults(true);
  }, [data]);

  const receiveMessage = (message: any) => {
    console.log("MESSAGE", message);
    if (message?.origin?.includes(process.env.REACT_APP_INC_GAME_URL)) {
      if (
        message?.data?.message === "redirect" ||
        message?.data?.message === "end"
      ) {
        if (isOnboarding) {
          setDone();
          return navigate("/onboarding");
        }
        // Set onboarding to 100 in the user state and redirect to /app/home
        console.log("FINISHED, voir commentaire dans le code");
        navigate(`/app/${message?.data?.value ?? "home"}`);
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iframeLoaded = () => {
    setLoaded(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        top: 0,
        position: "fixed",
        zIndex: 100,
      }}
    >
      {!loaded && !loading && <LogoLoader open />}
      <Iframe
        title="Inline Frame Example"
        onLoad={iframeLoaded}
        src={`${process.env.REACT_APP_INC_GAME_URL}/${
          results ? "report" : ""
        }?token=${token}`}
      />
    </Box>
  );
};

export default IncGamePage;
