import { Box, Skeleton, styled, Typography } from "@mui/material";
import React from "react";

const CircleSkeleton: any = styled(Skeleton, {
  shouldForwardProp: (props) => props !== "mt" && props !== "mb",
})(({ theme, mt, mb }: any) => ({
  margin: "auto",
  marginTop: `${mt}px`,
  marginBottom: `${mb}px`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&>*": {
    visibility: "visible",
  },
}));

const InnerCircle: any = styled(Box, {
  shouldForwardProp: (props) => props !== "loading" && props !== "size",
})(({ theme, size }: any) => ({
  backgroundColor: "white",
  width: `${size}px`,
  height: `${size}px`,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Title = styled(Typography)(({ theme }: any) => ({
  background: "#fbfbfb",
  borderRadius: theme.shape.borderRadiusXs,
  padding: "5px",
  paddingLeft: "10px",
  paddingRight: "10px",
}));

const CardTest = styled("div")(({ theme }: any) => ({
  borderRadius: "12px",
  padding: "15px",
  width: "250px",
  paddingBottom: theme.spacing(5),
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  marginBottom: "1em",
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23C6C6C6FF' stroke-width='2' stroke-dasharray='12' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e")`,
}));

const IconContainer = styled("div")(({ theme }: any) => ({
  width: "75px",
  height: "75px",
  borderRadius: "50%",
  backgroundColor: theme.palette.background.neutral,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CustomButton = styled(Skeleton)(({ theme }: any) => ({
  width: "160px",
  height: "37px",
  borderRadius: theme.shape.borderRadius10,
  fontSize: "12px",
}));

const TestPageSkeleton = () => {
  return (
    <>
      <Box display="flex" justifyContent="center">
        <Title textAlign="center" variant="subtitle1">
          <Skeleton width="130px" sx={{ margin: "auto" }} />
        </Title>
      </Box>
      <Typography
        sx={{ mt: 2 }}
        textAlign="center"
        color="primary"
        variant="body1"
        fontWeight="bold"
      >
        <Skeleton width="550px" sx={{ margin: "auto" }} />
      </Typography>

      <CircleSkeleton
        variant="circular"
        height={236}
        width={236}
        mt={30}
        mb={42}
      >
        <InnerCircle size={214}>
          <CircleSkeleton variant="circular" height={194} width={194}>
            <InnerCircle size={172}>
              <CircleSkeleton variant="circular" height={152} width={152}>
                <InnerCircle size={130}>
                  <Typography variant="h3">
                    <Skeleton width={75} sx={{ mt: 2 }} />
                  </Typography>
                </InnerCircle>
              </CircleSkeleton>
            </InnerCircle>
          </CircleSkeleton>
        </InnerCircle>
      </CircleSkeleton>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="space-around"
      >
        {Array.from(Array(3)).map((test, i: number) => (
          <CardTest key={i}>
            <Skeleton
              variant="circular"
              width="21px"
              height="21px"
              sx={{ ml: "auto", mt: "2px", mb: "2px" }}
            />
            <IconContainer>
              <Skeleton variant="circular" width="75px" height="75px" />
            </IconContainer>
            <Typography sx={{ mt: 2, lineHeight: "14px" }} variant="subtitle2">
              <Skeleton width="125px" />
            </Typography>
            <CustomButton
              variant="rectangular"
              width="160px"
              height="37px"
              sx={{ mt: 3 }}
            />
          </CardTest>
        ))}
      </Box>
    </>
  );
};

export default TestPageSkeleton;
