import {
  Box,
  Modal,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modalStyle } from "../../../../_utils/modalStyle";
import { HighlightOff } from "@mui/icons-material";
import AdminCreateWorkCatForm from "./AdminCreateWorkCatForm";
import workCategoryAPI from "../../../../_api/_workCategoryAPI";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const AdminCreateWorkCatModal: React.FC<{
  open: boolean;
  close: () => void;
  reload: () => void;
  skillWork?: any;
}> = ({ open, close, reload, skillWork }) => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    const calls: any = [];
    setIsLoading(true);
    try {
      if (skillWork) {
        await workCategoryAPI.put(skillWork.id, {
          label: values.label,
          code: "",
        });
        values.children?.map(
          (workCat: any) =>
            !workCat?.label &&
            calls.push(
              workCategoryAPI.post({
                label: workCat,
                parent: skillWork["@id"],
                code: "",
              })
            )
        );
        skillWork.children?.map(
          (workCat: any) =>
            values?.children?.filter((el: any) => el.id === workCat.id)
              ?.length === 0 && calls.push(workCategoryAPI.delete(workCat?.id))
        );
        Promise.all(calls)
          .then((res) => {
            toast.success(t("admin.params.works_cat.alert_edited"));
            reload();
            close();
          })
          .catch((err) => {});
      } else {
        const response = await workCategoryAPI.post({
          label: values.label,
          code: "",
        });
        values.children?.map(
          (workCat: any) =>
            !workCat?.label &&
            calls.push(
              workCategoryAPI.post({
                label: workCat,
                parent: response?.data?.["@id"],
                code: "",
              })
            )
        );
        Promise.all(calls)
          .then((res) => {
            toast.success(t("admin.params.works_cat.alert_created"));
            reload();
            close();
          })
          .catch((err) => {});
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(t("errors.error_append"));
      setIsLoading(false);
    }
  };

  const deleteSkillCat = async (values: any) => {
    try {
      await workCategoryAPI.delete(skillWork.id);
      toast.success(t("admin.params.works_cat.alert_deleted"));
      reload();
      close();
      setIsLoading(false);
    } catch (err) {
      toast.error(t("errors.error_append"));
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "80%", md: "40%" },
        }}
      >
        <Box display="flex" alignItems="center" sx={{ p: 1 }}>
          <IconButton aria-label="close" onClick={close} sx={{ ml: "auto" }}>
            <HighlightOff />
          </IconButton>
        </Box>
        <Typography textAlign="center" variant="h5" sx={{ mt: -4 }}>
          {t(`admin.params.works_cat.btn_${skillWork ? "edit" : "create"}`)}
        </Typography>
        <CardContent>
          <AdminCreateWorkCatForm
            loading={isLoading}
            close={close}
            skillWork={skillWork}
            deleteSkillCat={deleteSkillCat}
            submit={handleSubmit}
            form={
              skillWork ?? {
                label: "",
              }
            }
          />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AdminCreateWorkCatModal;
