export const getNumericDate = (date: Date | string) => {
  const parsedData = new Date(date);
  try {
    return new Date(parsedData)
      .toLocaleDateString("default", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
      .toLocaleLowerCase()
      .split("/")
      .map((x) => (x.length === 1 ? `0${x}` : x))
      .join("/");
  } catch (err) {
    return `${date}`;
  }
};
