import { Typography, Stack, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AdminDisplayCollabRoles: React.FC<any> = ({ param, handleChanges }) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Typography variant="h4">{t("admin.params.roles.title")}</Typography>
      <Stack spacing={2}>
        <Typography sx={{ mt: 1 }}>{t("admin.params.roles.text")}</Typography>
        <TextField
          value={param.COLLAB}
          onChange={(event: any) =>
            handleChanges({ ...param, COLLAB: event.target.value })
          }
          label={t("roles.collab")}
        />
        <TextField
          value={param.MANAGER}
          onChange={(event: any) =>
            handleChanges({ ...param, MANAGER: event.target.value })
          }
          label={t("roles.manager")}
        />
        <TextField
          value={param.RH}
          onChange={(event: any) =>
            handleChanges({ ...param, RH: event.target.value })
          }
          label={t("roles.rh")}
        />
      </Stack>
      <Typography sx={{ mt: 2 }} textAlign="right">
        {t("admin.params.reco_text")}
      </Typography>
    </div>
  );
};

export default AdminDisplayCollabRoles;
