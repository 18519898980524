import { styled } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { useTrail, animated as a } from "react-spring";
import Item from "./MoodResultItem";

const Grid = styled("div")`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 15px;
  }
`;
const config = { mass: 5, tension: 2000, friction: 200 };

const MoodResultsList: React.FC<any> = ({ items }) => {
  const [toggle, set] = useState(false);

  useLayoutEffect(() => {
    set(true);
  }, []);

  const trail = useTrail(items.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  return (
    <Grid className="App">
      {trail.map(({ x, height, ...rest }, index) => (
        <a.div
          key={items[index].key}
          className="trails-text"
          style={{
            ...rest,
            transform: x.to((x) => `translate3d(0,${x}px,0)`),
          }}
        >
          <Item index={index + 1} item={items[index]} />
        </a.div>
      ))}
    </Grid>
  );
};

export default MoodResultsList;
