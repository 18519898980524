import { Menu, Box, Typography, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
// import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const NotifMenu: React.FC<any> = ({
  anchorEl,
  isOpen,
  handleMenuClose,
  data,
}: any) => {
  const { t } = useTranslation("common");
  const [notifs, setNotifs] = useState([]);

  const returnNameLabel = () => {
    const regex = /\$(.*?)\$/;
    const newData: any = [];
    data.map((item: any) =>
      newData.push({
        ...item,
        label: item?.label.match(regex)?.[0]
          ? item?.label.replace(
              item?.label.match(regex)?.[0],
              `<${item?.parameters?.personId ? "a" : "span"} ${
                item?.parameters?.personId
                  ? `href="/app/profile/${item?.parameters?.personId}/overview"`
                  : ""
              }>${item?.label.match(regex)?.[1]}</${
                item?.parameters?.personId ? "a" : "span"
              }>`
            )
          : item.label,
      })
    );
    setNotifs(newData);
  };

  useEffect(() => {
    returnNameLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Menu
      sx={{ mt: 5, maxHeight: "350px", overflow: "auto" }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="notif-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      onClose={handleMenuClose}
    >
      <Typography variant="subtitle1" textAlign="center" sx={{ p: 2 }}>
        {t("notif.title")}
      </Typography>
      {notifs?.map((notif: any, index: number) => (
        <Box key={notif.id}>
          <Box display="flex" alignItems="center" sx={{ p: 2 }}>
            {notif.type === "WEL" ? (
              <CheckCircleOutlineOutlinedIcon
                color="success"
                sx={{ color: "green !important" }}
              />
            ) : (
              <InfoOutlinedIcon color="primary" />
            )}
            <Typography
              sx={{ maxWidth: "30ch", ml: 2 }}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: notif?.label }}
            ></Typography>
          </Box>
          {index + 1 < data.length && (
            <Box display="flex" justifyContent="center">
              <Divider sx={{ width: "60%" }}></Divider>
            </Box>
          )}
        </Box>
      ))}
      {data.length === 0 && (
        <Typography variant="body1" textAlign="center" sx={{ p: 2 }}>
          {t("notif.no_result")}
        </Typography>
      )}
      {/* <Box display="flex" justifyContent="center">
        <Button sx={{ fontSize: "12px" }}>{t("notif.btn_all")}</Button>
      </Box> */}
    </Menu>
  );
};

export default NotifMenu;
