import { useTheme } from "@mui/styles";
import React from "react";

const JobSuggestIcon: React.FC<{
  active?: boolean;
  context?: string;
  size?: number;
}> = ({ active, context, size }) => {
  const theme: any = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? "20"}
      height={size ?? "20"}
      viewBox="0 0 16.07 16.07"
    >
      <g
        id="_007-customer"
        data-name="007-customer"
        transform="translate(0 -0.013)"
      >
        <g
          id="Groupe_3071"
          data-name="Groupe 3071"
          transform="translate(0 7.419)"
        >
          <g id="Groupe_3070" data-name="Groupe 3070" transform="translate(0)">
            <path
              id="Tracé_12550"
              data-name="Tracé 12550"
              d="M15.568,236.481a1.412,1.412,0,0,0-1.944.117l-1.384,1.486a1.415,1.415,0,0,0-1.349-.994H8.421c-.187,0-.25-.072-.576-.322a3.3,3.3,0,0,0-4.342.01l-.908.8a1.407,1.407,0,0,0-1.295-.016l-1.041.52a.471.471,0,0,0-.211.632l2.825,5.649a.471.471,0,0,0,.632.211l1.041-.521a1.411,1.411,0,0,0,.78-1.313h5.563a3.311,3.311,0,0,0,2.636-1.318l2.26-3.014A1.408,1.408,0,0,0,15.568,236.481ZM4.126,243.21l-.62.31-2.4-4.807.62-.31a.471.471,0,0,1,.631.211l1.983,3.965A.471.471,0,0,1,4.126,243.21Zm10.908-5.368-2.26,3.014a2.365,2.365,0,0,1-1.883.942H5L3.235,238.27l.891-.784a2.36,2.36,0,0,1,3.11,0,1.666,1.666,0,0,0,1.185.547h2.469a.471.471,0,0,1,0,.942h-2.4a.471.471,0,0,0,0,.942h2.72a1.416,1.416,0,0,0,1.034-.45l2.073-2.225a.471.471,0,0,1,.722.6Z"
              transform="translate(0 -235.959)"
              fill={
                !active &&
                (context === "collab" ||
                  context === "benef" ||
                  context === "candidate")
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
          </g>
        </g>
        <g
          id="Groupe_3073"
          data-name="Groupe 3073"
          transform="translate(5.681 0.013)"
        >
          <g
            id="Groupe_3072"
            data-name="Groupe 3072"
            transform="translate(0 0)"
          >
            <path
              id="Tracé_12551"
              data-name="Tracé 12551"
              d="M185.212,3.226a1.876,1.876,0,0,0,.521-1.3A1.909,1.909,0,0,0,183.85.013a1.94,1.94,0,0,0-1.915,1.915,1.848,1.848,0,0,0,.538,1.3,2.872,2.872,0,0,0-1.48,2.5V6.2a.471.471,0,0,0,.471.471H186.2a.471.471,0,0,0,.471-.471V5.725A2.87,2.87,0,0,0,185.212,3.226ZM183.85.955a.971.971,0,0,1,.942.973.943.943,0,0,1-.942.942.971.971,0,0,1-.973-.942A1,1,0,0,1,183.85.955Zm-1.915,4.771a1.94,1.94,0,0,1,1.915-1.915,1.9,1.9,0,0,1,1.883,1.915Z"
              transform="translate(-180.994 -0.013)"
              fill={
                !active &&
                (context === "collab" ||
                  context === "benef" ||
                  context === "candidate")
                  ? theme.palette.contextColors.collab.font
                  : "#fff"
              }
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default JobSuggestIcon;
