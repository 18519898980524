import palette from "../../theme/palette";

export const returnColor = (trait: string) => {
  switch (trait) {
    case "agreeableness":
      return palette.b5.agreeableness;
    case "conscientiousness":
      return palette.b5.conscientiousness;
    case "extraversion":
      return palette.b5.extraversion;
    case "neuroticism":
      return palette.b5.neuroticism;
    case "openness":
      return palette.b5.openness;
  }
};
