// ----------------------------------------------------------------------

export default function Card(theme: any) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "12px",
        },
      },
    },
  };
}
