import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const BorderLinearProgress = withStyles((theme: any) => ({
  root: {
    [theme.breakpoints.down("sm")]: { width: "100% !important" },
    width: "100%",
    height: 16,
    borderRadius: 10,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  bar: {
    borderRadius: 10,
    background: theme.palette.gradients.cf_linear,
  },
}))(LinearProgress);

const BorderLinearProgressUser = withStyles((theme: any) => ({
  bar: {
    background: "#333539",
  },
}))(BorderLinearProgress);

const CulturefitMatching = ({ personScores, matchScores, noCard }: any) => {
  const { t }: { t: any } = useTranslation("common");
  const [highestScore, setHighestScore] = useState<any>(0);
  const [peopleScores, setPeopleScores] = useState<any>();

  const getPourcentageValue = (value: any): number => {
    return (value / highestScore) * 100;
  };

  useEffect(() => {
    const sortPersonScores = Object.entries(personScores)
      .sort((a: any, b: any) => b[1] - a[1])
      .map((el: any) => el[0]);
    const sortMatchScores = Object.entries(matchScores)
      .sort((a: any, b: any) => b[1] - a[1])
      .map((el: any) => el[0]);
    setPeopleScores(sortPersonScores);
    matchScores &&
      personScores &&
      setHighestScore(
        matchScores[sortMatchScores[0]] > personScores[sortPersonScores[0]]
          ? matchScores[sortMatchScores[0]]
          : personScores[sortPersonScores[0]]
      );
  }, [personScores, matchScores]);

  return (
    <Paper
      elevation={noCard ? 0 : 3}
      sx={{ padding: "20px", borderRadius: "23px" }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src="/static/illustrations/cf_illu.svg" alt="success" />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                background: "#FFF2F6",
                padding: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                width: 36,
                height: 36,
                mr: 1,
              }}
            >
              <img
                style={{ width: "20px" }}
                alt="skills icon"
                src="/static/icons/cf_logo.svg"
              />
            </Box>

            <Typography
              variant="h5"
              sx={{
                mb: 2,
                mt: 2,
                backgroundColor: "#fafdfe",
                padding: "3px",
                borderRadius: "7px",
              }}
            >
              {t("recruiter.campaign.matching_cf")}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop={2}
          sx={{ mb: 2 }}
        >
          <Box
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
            width="500px"
          >
            <Box display="flex" alignItems="center" marginTop={1}>
              <Box
                sx={{
                  width: "50px",
                  height: "20px",
                  background: "#FE7A89",
                  borderRadius: "10px",
                  mr: 1,
                }}
              />
              <Typography sx={{ whiteSpace: "nowrap" }}>
                {t("skills.mastered_level")}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" marginTop={1}>
              <Box
                sx={{
                  width: "50px",
                  height: "20px",
                  background: "#333539",
                  borderRadius: "10px",
                  mr: 1,
                }}
              />
              <Typography sx={{ whiteSpace: "nowrap" }}>
                {t("skills.expected_level")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          {peopleScores?.map((item: any, index: number) => (
            <Box
              key={Object.keys(matchScores)[index]}
              sx={{
                mt: index > 0 ? 2 : 0,
                width: { xs: "100%", sm: "80%" },
              }}
            >
              <Typography variant="subtitle2" sx={{ maxWidth: "300px" }}>
                {t(`culturefit.traits.${item.toLowerCase()}`)}
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={getPourcentageValue(personScores[item])}
              />
              <Box sx={{ mt: 1 }}>
                <BorderLinearProgressUser
                  variant="determinate"
                  value={getPourcentageValue(matchScores[item])}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Paper>
  );
};

export default CulturefitMatching;
