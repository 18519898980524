import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React, { useRef, useState } from "react";
import { peopleType } from "../../@types/people";
import StyledButton from "../styled/StyledButton";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { styled } from "@mui/system";
import { toast } from "react-toastify";

const LinkField = styled(TextField)(({ theme }: any) => ({
  width: "175.72px",
  marginBottom: 8,
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main + " !important",
  },
}));

const Container = styled("div")({
  marginBottom: 8,
  display: "flex",
  justifyContent: "space-around",
});

const NetworkButton = styled(StyledButton)({
  padding: 8,
  marginLeft: 4,
  marginRight: 4,
});

const SocialNetworkLink: React.FC<{
  profileData: peopleType;
}> = ({ profileData }) => {
  const { t } = useTranslation("common");
  const ref = useRef<any>(null);
  const [link, setLink] = useState<"zoom" | "teams" | "linkedin" | "">("");

  const copyLink = () => {
    if (link && profileData.otherData?.visio?.[link]) {
      navigator.clipboard.writeText(profileData.otherData.visio[link]);
      toast.success(t("advisor.folder.link_copy_alert"));
    }
  };

  const handleClick = (value: "zoom" | "teams" | "linkedin") => {
    if (link !== value) {
      setLink(value);
    } else {
      setLink("");
    }
  };

  useOnClickOutside(ref, link !== "", () => {
    setLink("");
  });

  return (
    <Box sx={{ mb: 1 }} ref={ref}>
      <Container>
        <Tooltip
          title={
            !profileData.otherData?.visio?.zoom
              ? t("profilepage.infos.not_specified")
              : ""
          }
        >
          <div>
            <NetworkButton
              onClick={() => handleClick("zoom")}
              disabled={!profileData.otherData?.visio?.zoom}
              bordercolor={link === "zoom" ? "primary" : null}
            >
              <img
                alt="zoom icon"
                src={`/static/logo/zoom.svg`}
                width="16px"
                style={{
                  borderRadius: "50%",
                  opacity: profileData.otherData?.visio?.zoom ? 1 : 0.5,
                }}
              />
            </NetworkButton>
          </div>
        </Tooltip>
        <Tooltip
          title={
            !profileData.otherData?.visio?.teams
              ? t("profilepage.infos.not_specified")
              : ""
          }
        >
          <div>
            <NetworkButton
              onClick={() => handleClick("teams")}
              disabled={!profileData.otherData?.visio?.teams}
              bordercolor={link === "teams" ? "primary" : null}
            >
              <img
                alt="microsoft teams icon"
                src={`/static/logo/microsoft-teams.svg`}
                width="16px"
                style={{
                  opacity: profileData.otherData?.visio?.teams ? 1 : 0.5,
                }}
              />
            </NetworkButton>
          </div>
        </Tooltip>
        <Tooltip
          title={
            !profileData.otherData?.visio?.linkedin
              ? t("profilepage.infos.not_specified")
              : ""
          }
        >
          <div>
            <NetworkButton
              onClick={() => handleClick("linkedin")}
              disabled={!profileData.otherData?.visio?.linkedin}
              bordercolor={link === "linkedin" ? "primary" : null}
            >
              <img
                alt="linkedin icon"
                src={`/static/logo/Linkedin.svg`}
                width="16px"
                style={{
                  opacity: profileData.otherData?.visio?.linkedin ? 1 : 0.5,
                }}
              />
            </NetworkButton>
          </div>
        </Tooltip>
      </Container>
      {link && (
        <LinkField
          id="invitation-link"
          value={link && profileData.otherData?.visio?.[link]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={copyLink} aria-label="copy link">
                  <ContentCopyIcon sx={{ width: "16px", height: "16px" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      )}
    </Box>
  );
};

export default SocialNetworkLink;
