import React from "react";

const TeamIcon: React.FC<{ active?: boolean; size?: number }> = ({
  active,
  size,
}) => {
  return (
    <svg
      id="group_work_black_24dp_1_"
      data-name="group_work_black_24dp(1)"
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? "22"}
      height={size ?? "22"}
      viewBox="0 0 16 16"
    >
      <path
        id="Tracé_12898"
        data-name="Tracé 12898"
        d="M0,0H16V16H0Z"
        fill="none"
      />
      <path
        id="Tracé_12899"
        data-name="Tracé 12899"
        d="M8.667,2a6.667,6.667,0,1,0,6.667,6.667A6.669,6.669,0,0,0,8.667,2Zm0,12A5.333,5.333,0,1,1,14,8.667,5.34,5.34,0,0,1,8.667,14Z"
        transform="translate(-0.667 -0.667)"
        fill="#fff"
      />
      <circle
        id="Ellipse_674"
        data-name="Ellipse 674"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(4 8)"
        fill="#fff"
      />
      <circle
        id="Ellipse_675"
        data-name="Ellipse 675"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(6 4)"
        fill="#fff"
      />
      <circle
        id="Ellipse_676"
        data-name="Ellipse 676"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(9 8)"
        fill="#fff"
      />
    </svg>
  );
};

export default TeamIcon;
