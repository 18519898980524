import { Modal, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { modalStyle } from "../../_utils/modalStyle";
import DispatchInfosForm from "./DispatchInfosForm";
import { shallowEqual, useSelector } from "react-redux";

const DispatchInfosModal: React.FC<any> = () => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state: any) => state.user, shallowEqual);
  const isGom = process.env.REACT_APP_GOM === "true";

  useEffect(() => {
    setOpen(
      Boolean(
        user?.person_id &&
          user?.people &&
          !user?.people?.otherData?.dispatchInfos &&
          isGom
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user?.people?.otherData, user?.people]);

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: {
            xs: "95%",
            sm: "400px",
            md: "400px",
          },
          padding: 0,
        }}
      >
        <CardContent sx={{ pt: 0, textAlign: "center", padding: 0 }}>
          {/* <Box sx={{ width: "100%", display: "flex" }}>
            <IconButton
              onClick={close}
              sx={{ ml: "auto", mt: 1, mr: 1 }}
              aria-label="close"
            >
              <HighlightOff />
            </IconButton>
          </Box> */}

          <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
            Êtes-vous accompagné ?
          </Typography>

          <Typography sx={{ mb: 2 }}>
            Aidez-nous à améliorer votre expérience utilisateur en renseignant
            les informations suivantes :
          </Typography>

          <DispatchInfosForm />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default DispatchInfosModal;
