import { Box, styled, Typography } from "@mui/material";
import React from "react";
import ShowAllButton from "./buttons/ShowAllButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Container: any = styled(Box, {
  shouldForwardProp: (props) => props !== "icon",
})(({ theme, icon }: any) =>
  icon
    ? {
        backgroundColor: "#fbfbfb",
        padding: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: theme.shape.borderRadiusXs,
      }
    : {}
);

const TitleLight: any = styled(Typography, {
  shouldForwardProp: (props) => props !== "light",
})(({ theme, light }: any) => ({
  color: light ? theme.palette.secondary.light : "auto",
}));

const TitleWithShowAll: React.FC<{
  title: string;
  icon?: any;
  iconProps?: any;
  titleProps?: any;
  buttonAction: any;
  buttonText?: string;
  uppercase?: boolean;
  bold?: boolean;
  light?: boolean;
  seeResearch?: boolean;
}> = ({
  title,
  icon,
  iconProps,
  titleProps,
  buttonAction,
  buttonText,
  uppercase,
  bold,
  light,
  seeResearch,
}) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const researchOpen = seeResearch;

   const hasResearch = useSelector(
    (state: any) =>
      state.parameters.find((el: any) => el.type === "GENERAL_PARAMS")
        ?.parameters?.JOB_SUGGEST?.research
   );
  
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        width: "100%",
        textTransform: uppercase ? "uppercase" : "inherit",
      }}
    >
      <Container display="flex" alignItems="center" icon={icon}>
        {icon && (
          <Box
            sx={{ mr: 1, display: "flex", alignItems: "center" }}
            {...iconProps}
          >
            {icon}
          </Box>
        )}

        <TitleLight
          {...titleProps}
          fontWeight={icon && !bold ? "normal" : "bold"}
          component="h6"
          variant="body1"
          light={light}
        >
          {title}
        </TitleLight>
      </Container>
      
      {hasResearch && seeResearch ? (
        <ShowAllButton
          title={t("job_research.see_researchs")}
          onClick={() => {
            navigate("/app/job/suggest", { state: { researchOpen } });
          }}
          //marginLeft={3}
        />
      ) : null}
      <ShowAllButton title={buttonText} onClick={buttonAction} />
    </Box>
  );
};
export default TitleWithShowAll;
