import { Box, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import IncAdaptationHeader from "../headers/IncAdaptationHeader";
import IncProgress from "./IncProgress";

const ChartContainer = styled(Box)(({ theme }: any) => ({
  display: "flex",
  width: "100%",
  marginTop: theme.spacing(3),
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const IncCompareAdaptation: React.FC<any> = ({ results, score }) => {
  const { t } = useTranslation("common");

  const [chartState, setChartState]: any = useState({
    series: [
      {
        name: "Series 1",
        data: [80, 50, 30, 40, 100, 20, 20],
      },
      {
        name: "Series 2",
        data: [20, 30, 40, 80, 20, 80, 20],
      },
      {
        name: "Series 3",
        data: [20, 30, 40, 80, 20, 80, 20],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        toolbar: {
          show: false,
        },
        height: 350,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      stroke: {
        width: 2,
        colors: ["#FFF", "#FFF", "#FFF"],
      },
      fill: {
        opacity: 0.24,
        colors: ["#AFAFAF", "#FF6C40", "#4C6FFF"],
      },
      markers: {
        size: 4,
        colors: ["#151A30", "#FF6C40", "#4C6FFF"],
      },
      xaxis: {
        range: 10,
        categories: [
          t("inc.adaptation.flexibility"),
          t("inc.adaptation.shade"),
          t("inc.adaptation.relativity"),
          t("inc.adaptation.reflection"),
          t("inc.adaptation.personal_opinions"),
          t("inc.adaptation.difficult_situation"),
          t("inc.adaptation.curiosity"),
        ],
        labels: {
          style: {
            fontSize: "14px",
          },
        },
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        max: 10,
      },
      grid: {
        padding: {
          top: -50,
          bottom: -60,
          left: 50,
          right: -30,
        },
      },
    },
  });

  useEffect(() => {
    const state = { ...chartState };
    const all: any = [];
    if (results) {
      const res: any = [...results].filter((el: any) => el.var !== "V165");
      state.series = [
        { name: t("form.work"), data: [] },
        { name: t("inc.adaptation.person_difficult"), data: [] },
        { name: t("inc.adaptation.person_normal"), data: [] },
      ];
      state.options.xaxis.categories = [];
      res.forEach((el: any) => {
        state.series[2].data.push(el.person_value);
        state.series[1].data.push(el.person_value_difficult);
        state.series[0].data.push(el.work_value);
        all.push(el.title);
        // state.options.xaxis.categories.push(el.title);
      });
      state.options = { ...state.options, xaxis: { categories: [...all] } };
    }

    setChartState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  return (
    <div>
      <IncAdaptationHeader />

      <IncProgress value={score} />

      <ChartContainer>
        <Box sx={{ flexGrow: 1, width: { xs: 300, sm: 450 } }}>
          <ReactApexChart
            options={chartState.options}
            series={chartState.series}
            type="radar"
            height={300}
          />
        </Box>

        <Box>
          <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            <Box
              sx={{
                mr: 1,
                minWidth: "34px",
                height: "15px",
                backgroundColor: "#4C6FFF",
                borderRadius: "9px",
              }}
            />
            <Typography>{t("inc.adaptation.normal")}</Typography>
          </Box>
          <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            <Box
              sx={{
                mr: 1,
                minWidth: "34px",
                height: "15px",
                backgroundColor: "#FF6C40",
                borderRadius: "9px",
              }}
            />
            <Typography>{t("inc.adaptation.difficult")}</Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <Box
              sx={{
                mr: 1,
                minWidth: "34px",
                height: "15px",
                backgroundColor: "#AFAFAF",
                borderRadius: "9px",
              }}
            />
            <Typography>{t("inc.adaptation.await")}</Typography>
          </Box>
        </Box>
      </ChartContainer>
    </div>
  );
};

export default IncCompareAdaptation;
