import {
  Box,
  Button,
  Container,
  FormGroup,
  IconButton,
  Typography,
  Stack,
  styled,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import { autocompleteTypes } from "../../../../_utils/autocompleteTypes";
import AutocompleteComponent from "../../../common/AutocompleteComponent";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ControlledQuill from "../../../forms/ControlledQuill";
import ControlledTextField from "../../../forms/ControlledTextField";
import { SubmitHandler, useForm } from "react-hook-form";
import AdminWorkSkills from "./AdminWorkSkills";

interface IFormInput {
  label: string;
  code: string;
  codeReference1: string;
  description: string;
  access: string;
  condition: string;
  worksMobilities: any;
  worksEvolutions: any;
  childrens: any;
}

const AdminWorkInfosForm: React.FC<{
  submit: (e: any) => void;
  loading: boolean;
  form: any;
  openModal?: () => void;
  canDelete?: boolean;
  workSkills?: any[];
}> = ({ loading, submit, form, workSkills, openModal, canDelete }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const { handleSubmit, control, setValue, getValues } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: form,
  });

  const [skills, setSkillsList] = useState(workSkills ?? []);
  const [works, setWorks] = useState(getValues("childrens") ?? []);
  const [mobilities, setMobilities] = useState(
    getValues("worksMobilities") ?? []
  );
  const [evolutions, setEvolutions] = useState(
    getValues("worksEvolutions") ?? []
  );

  const handleChildrens = (work: any) => {
    const values = [...getValues("childrens")];
    if (values?.filter((el: any) => el.id === work.id).length === 0) {
      values.push(work);
      setValue("childrens", values);
      setWorks(values);
    }
  };

  const handleWorksMobilities = (work: any) => {
    const values = [...getValues("worksMobilities")];
    if (values?.filter((el: any) => el.id === work.id).length === 0) {
      values.push(work);
      setValue("worksMobilities", values);
      setMobilities(values);
    }
  };

  const handleWorksEvolutions = (work: any) => {
    const values = [...getValues("worksEvolutions")];
    if (values?.filter((el: any) => el.id === work.id).length === 0) {
      values.push(work);
      setValue("worksEvolutions", values);
      setEvolutions(values);
    }
  };

  const removeChild = (i: number) => {
    const arr = [...getValues("childrens")];
    arr.splice(i, 1);
    setValue("childrens", arr);
  };

  const removeMobilities = (i: number) => {
    const arr = [...getValues("worksMobilities")];
    arr.splice(i, 1);
    setValue("worksMobilities", arr);
    setMobilities(arr);
  };

  const removeEvolutions = (i: number) => {
    const arr = [...getValues("worksEvolutions")];
    arr.splice(i, 1);
    setValue("worksEvolutions", arr);
    setEvolutions(arr);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    submit({ ...data, skills });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Container sx={{ pt: 5, pb: 5 }}>
        <FormGroup>
          <Stack spacing={2}>
            <Block elevation={3}>
              <Stack spacing={2}>
                <ControlledTextField
                  name="label"
                  label={t("admin.params.works.job_title") + "*"}
                  control={control}
                  required
                />
                <ControlledTextField
                  name="code"
                  label={t("admin.params.works.code")}
                  control={control}
                  required
                />
                <ControlledTextField
                  name="codeReference1"
                  label={t("admin.params.works.ref")}
                  control={control}
                />
              </Stack>
            </Block>
            <Block elevation={3}>
              <Typography variant="body1" fontWeight="bold">
                {t("admin.params.works.description")}
              </Typography>
              <ControlledQuill
                toolbar
                name="description"
                placeholder={t("admin.params.works.description")}
                control={control}
              />
            </Block>
            {/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
            <Block elevation={3}>
              <Typography variant="body1" fontWeight="bold">
                {t("admin.params.works.access")}
              </Typography>
              <ControlledQuill
                toolbar
                name="access"
                placeholder={t("admin.params.works.access")}
                control={control}
              />
            </Block>
            {/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
            <Block elevation={3}>
              <Typography variant="body1" fontWeight="bold">
                {t("admin.params.works.condition")}
              </Typography>
              <ControlledQuill
                toolbar
                name="condition"
                placeholder={t("admin.params.works.condition")}
                control={control}
              />
            </Block>
            {/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
            <Block elevation={3}>
              <Typography variant="body1" fontWeight="bold">
                {t("admin.params.works.designations")}
              </Typography>
              <AutocompleteComponent
                clearOnSelect
                companyOnly
                label={t("form.work")}
                type={autocompleteTypes.works}
                sendDataToParent={(e: any) => handleChildrens(e)}
              />
              {works?.map((work: any, index: number) => (
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  key={work?.code}
                >
                  <Typography variant="body1">{work?.label}</Typography>
                  <IconButton
                    sx={{ ml: "auto" }}
                    onClick={() => removeChild(index)}
                  >
                    <Close color="error" />
                  </IconButton>
                </Box>
              ))}
            </Block>
            <Block elevation={3}>
              <Typography variant="body1" fontWeight="bold">
                {t("admin.params.works.mob")}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <AutocompleteComponent
                  clearOnSelect
                  companyOnly
                  label={t("admin.params.works.next_job")}
                  type={autocompleteTypes.works}
                  sendDataToParent={(e: any) => handleWorksMobilities(e)}
                />
                {mobilities?.map((work: any, index: number) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    key={work?.code}
                  >
                    <Typography variant="body1">{work?.label}</Typography>
                    <IconButton
                      sx={{ ml: "auto" }}
                      onClick={() => removeMobilities(index)}
                    >
                      <Close color="error" />
                    </IconButton>
                  </Box>
                ))}
              </Box>

              <AutocompleteComponent
                clearOnSelect
                companyOnly
                label={t("admin.params.works.evol_job")}
                type={autocompleteTypes.works}
                sendDataToParent={(e: any) => handleWorksEvolutions(e)}
              />
              {evolutions?.map((work: any, index: number) => (
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  key={work?.code}
                >
                  <Typography variant="body1">{work?.label}</Typography>
                  <IconButton
                    sx={{ ml: "auto" }}
                    onClick={() => removeEvolutions(index)}
                  >
                    <Close color="error" />
                  </IconButton>
                </Box>
              ))}
            </Block>

            <Block elevation={3}>
              <AdminWorkSkills
                setSkillsList={setSkillsList}
                workSkills={skills}
              />
            </Block>
          </Stack>
          <Box
            display="flex"
            justifyContent="space-evenly"
            width="100%"
            sx={{ mt: 5 }}
          >
            <Button
              type="button"
              color="secondary"
              onClick={() => navigate(`/app/params/works`)}
              variant="outlined"
              sx={{ width: "200px", height: "45px" }}
            >
              <Typography variant="body2">{t("btn.cancel")}</Typography>
            </Button>
            <Button
              type="submit"
              disabled={loading}
              variant="contained"
              sx={{ width: "200px", height: "45px" }}
            >
              <Typography variant="body2">{t("btn.save")}</Typography>
            </Button>
          </Box>
          {canDelete && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                type="button"
                disabled={loading}
                variant="contained"
                onClick={openModal}
                color="error"
                sx={{ width: "200px", height: "45px" }}
              >
                <Typography variant="body2">{t("btn.delete")}</Typography>
              </Button>
            </Box>
          )}
        </FormGroup>
      </Container>
    </form>
  );
};

export default AdminWorkInfosForm;

const Block = styled(Paper)`
  border-radius: 8px;
  padding: 20px;
`;
