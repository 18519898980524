import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
  IconButton,
  Button,
  styled,
} from "@mui/material";
import React from "react";
import { HighlightOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../_utils/modalStyle";

const CardImage = styled("img")({
  position: "relative",
  left: "24%",
  width: "45%",
  marginTop: 1,
});

const CustomButton = styled(Button)({
  height: "55px",
  width: "200px",
});

const DeleteAccountModal: React.FC<{
  open: boolean;
  width?: string;
  handleDelete?: any;
  close: () => void;
}> = ({ open, close, width = "510px", handleDelete }) => {
  const { t } = useTranslation("common");

  return (
    <Modal open={open} onClose={close}>
      <Card
        sx={{
          ...modalStyle,
          width: {
            xs: "95%",
            sm: !width ? "auto" : width,
            md: !width ? "auto" : width,
          },
        }}
      >
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ width: "100%", display: "flex" }}>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{ ml: "auto", mt: 1, mr: -1, mb: 1 }}
            >
              <HighlightOff />
            </IconButton>
          </Box>
          <CardImage
            alt="Logout icon"
            src="/static/illustrations/Logout-illu.svg"
          />
          <Typography
            variant="h5"
            sx={{ textAlign: "center", mt: 3, fontWeight: 600 }}
          >
            {t("account.params.delete_account_text")}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mt: 2,
            mb: 3,
          }}
        >
          <CustomButton variant="outlined" color="secondary" onClick={close}>
            <Typography variant="body1">{t("btn.cancel")}</Typography>
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={handleDelete}
            color="error"
          >
            <Typography variant="body1">{t("btn.delete")}</Typography>
          </CustomButton>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default DeleteAccountModal;
