import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import StringAvatar from "../common/StringAvatar";

const TextLink = styled(Link)(({ theme }: any) => ({
  display: "inline-block",
  position: "relative",
  textDecoration: "none",
  "&:after": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "1px",
    bottom: "0",
    left: "0",
    backgroundColor: theme.palette.primary.main,
    transform: "scaleX(0)",
    transition: "transform 0.25s ease-out",
    transformOrigin: "bottom right",
  },
  "&:hover:after": {
    transform: "scaleX(1)",
    transformOrigin: "bottom left",
  },
}));

const LastActionItem = ({ item }: any) => {
  const context = useSelector((state: any) => state.auth.context);
  return (
    <Box key={item.id} display="flex" alignItems="center" sx={{ mb: 2 }}>
      <StringAvatar
        size={30}
        name={item.person.fullName}
        url={item?.person?.image?.contentUrl}
      />
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {context === "advisor" || context === "recruiter" ? (
          <TextLink
            style={{ marginLeft: "5px" }}
            to={`/app/profile/${item.person.id}/overview`}
          >
            <Typography variant="body2">{item.person.fullName}</Typography>
          </TextLink>
        ) : (
          <>
            <Typography sx={{ ml: 0.75 }} variant="body2" color="primary">
              {item.person.fullName}
            </Typography>
          </>
        )}
        <Typography sx={{ ml: 0.75 }} variant="body2">
          {item.label}
        </Typography>
      </Box>
      <Box sx={{ ml: "auto" }}>
        <img
          src={`/static/icons/action_histories/${item.code}.svg`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/static/icons/action_histories/UPU.svg";
          }}
          style={{ minWidth: "35px" }}
          width="35px"
          alt="illustrations"
        />
      </Box>
    </Box>
  );
};

export default LastActionItem;
